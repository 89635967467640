import { FaMinus, FaPlus } from 'react-icons/fa6';
import { TipoOptions, theme } from '../../../../common';
import { ButtonForm, SelectForm } from '../../../../components';
import { RowButton } from '../../../../styles';

export const columnsForm = (
  handleChangeNombreTable,
  handleChangeTipoTable,
  deleteTableRows,
  addTableRows,
  conceptoState
) => [
  {
    field: 'selectCaracteristica',
    headerName: 'Campo',
    flex: 1,
    align: 'left',
    renderCell: (params) => (
      <>
        <SelectForm
          value={params.value}
          handleChange={(evnt, v) => handleChangeNombreTable(params.row, params.id, v)}
          validate={true}
          options={conceptoState.aCaracteristicaXfuncion.filter(
            (item) => item.selected === false && item.bObligatorio === false
          )}
          placeholder={'Seleccione'}
          dataOption={conceptoState.aCaracteristicaXfuncion}
          isDisabled={!params.row.enabledDelete}
        />
      </>
    ),
  },
  {
    field: 'Campo',
    headerName: 'Key',
    flex: 1,
    align: 'left',
    renderCell: (params) => (
      <>
        <SelectForm
          value={params.row.selectTipo}
          handleChange={(evnt, v) => handleChangeTipoTable(params.row, params.id, v)}
          validate={true}
          options={
            params.row.enabledDelete ? TipoOptions : TipoOptions.filter((r) => r.value != 'Key')
          }
          styleText={params.row.selectTipo[0].label === 'Key' ? theme.colors.secondary : ''}
          disableClearable={true}
        />
      </>
    ),
  },
  {
    field: 'edit',

    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <RowButton>
        <ButtonForm
          action={() => deleteTableRows(params.row, params.id)}
          icon={<FaMinus />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="primary"
          disabled={!params.row.enabledDelete}
        />
      </RowButton>
    ),
    renderHeader: (params) => (
      <RowButton>
        <ButtonForm
          action={addTableRows}
          icon={<FaPlus />}
          isAction={false}
          isOnlyIcon={true}
          noBackground={true}
          typeColor="secondary"
          disabled={
            conceptoState.aCaracteristicaXfuncion.filter(
              (item) => item.selected === false && item.bObligatorio === false
            ).length > 0
              ? false
              : true
          }
        />
      </RowButton>
    ),
  },
];
