import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ButtonForm,
  Input,
  LabelForm,
  LoadingSpinner,
  ModalConfirmation,
  SelectForm,
  SubTitle,
  TableGrid,
  TitlePage,
} from '../../../../components';
import { useFetchAndLoad } from '../../../../common/hooks';
import Swal from 'sweetalert2';
import {
  changeFindAdminCampo,
  changeNameFunction,
  clearAllFieldsFunction,
  clearFieldFormFunction,
  onSelectTypeFunction,
  onSetDeleteAvailableTypeSystemFunction,
  setAvailableTypeSystemFunction,
  setListAvailableFields,
  setListTypeFunction,
  setSelectAmdminCampos,
  setSelectAmdminCamposFinal,
  updateTitle,
  changeRowsChangeServiceName,
  changeRowsSelectAvailableTypeSystemFunction,
  onAddRowtableTypeSystemFunction,
  onDeleteRowAvailableTypeSystemFunction,
} from '../../../../redux/states';
import {
  listarSistemasDisponibles,
  listTypeFuncion,
  obtenerCampos,
  registerUpdateFunction,
} from '../../../../services';
import { createTypeFunctionAdapter } from '../../../../adapters/typeFunction';
import { Drawer } from 'antd';
import {
  ColForm,
  Content,
  ContentForm,
  RowFooterButton,
  RowButton,
  ColFlex,
  Title,
  RowForm,
} from '../../../../styles';

import { v4 as uuidv4 } from 'uuid';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { paths } from '../../../../common';

export const CrearFuncion = () => {
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [openSheetCampos, setOpenSheetCampos] = useState(false);
  const funcionState = useSelector((store) => store.funciones);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const addTableRows = () => {
    var uuid = uuidv4();
    dispatch(
      onAddRowtableTypeSystemFunction({
        newItem: {
          idAvailableTypeSystemFunction: uuid,
          selectTypeSystem: [],
          bValidTypeSystem: null,
          serviceName: '',
          bValidServiceName: null,
          action: 'N',
        },
      })
    );
  };

  const handleChangeSystemType = (id, value) => {
    dispatch(
      changeRowsSelectAvailableTypeSystemFunction({
        index: id,
        selectTypeSystem: value,
      })
    );
  };

  const deleteTableRows = (id) => {
    dispatch(
      onDeleteRowAvailableTypeSystemFunction({
        index: id,
      })
    );
  };
  const handleChangeServiceName = (id, oEvent) => {
    let bValidServiceName = null;
    let serviceName = oEvent.target.value;
    if (serviceName.length === 0) {
      bValidServiceName = false;
    }
    dispatch(
      changeRowsChangeServiceName({
        index: id,
        serviceName: serviceName,
        bValidServiceName: bValidServiceName,
      })
    );
  };
  const columnCampo = [
    {
      field: 'NombreCampoHomo',
      headerName: 'Campo',
      flex: 1,
      filterable: true,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'NombreCampoAlias',
      headerName: 'Descripción',
      flex: 1,
      filterable: true,
      renderCell: (params) => <>{params.value}</>,
    },
  ];

  const columnFuncion = [
    {
      field: 'selectTypeSystem',
      headerName: 'Sistema',
      flex: 1,
      renderCell: (params) => (
        <SelectForm
          isMultiple={true}
          width={'380px'}
          value={params.value}
          handleChange={(e, v) => handleChangeSystemType(params.id, v)}
          options={funcionState.availableTypeSystemFunction}
          placeholder={'Seleccione Tipo'}
        />
      ),
    },
    {
      field: 'serviceName',
      headerName: 'Servicio',
      flex: 1,
      align: 'left',
      renderCell: (params) => (
        <Input
          width={'280px'}
          placeholder="Ingrese..."
          value={params.value}
          onChange={(e) => handleChangeServiceName(params.id, e)}
          validate={params.row.bValidServiceName}
        />
      ),
    },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <RowButton>
          <ButtonForm
            action={() => deleteTableRows(params.id)}
            icon={<FaMinus />}
            isAction={false}
            isOnlyIcon={true}
            typeColor="primary"
          />
        </RowButton>
      ),
      renderHeader: (params) => (
        <RowButton>
          <ButtonForm
            action={addTableRows}
            icon={<FaPlus />}
            isAction={false}
            isOnlyIcon={true}
            noBackground={true}
            typeColor="secondary"
          />
        </RowButton>
      ),
    },
  ];
  function onDismiss() {
    setOpenSheetCampos(false);
    dispatch(setSelectAmdminCamposFinal(funcionState.selectAmdminCamposFinal));
    dispatch(setSelectAmdminCampos(funcionState.selectAmdminCamposFinal));
  }
  const onPressCancelar = () => {
    setShowModalCancelar(true);
    // dispatch(setSelectAmdminCamposFinal(funcionState.selectAmdminCamposFinal))
    // dispatch(setSelectAmdminCampos(funcionState.selectAmdminCamposFinal))
  };
  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };
  const onConfirmFunction = () => {
    setShowModalCancelar(false);
    dispatch(clearAllFieldsFunction());
    navigate(paths.CONFIGURE.FUNCTION.BASE);
  };
  const onPressGrabar = async () => {
    var valid = true;
    var bValidRowTypeSystemService = funcionState.listTypeSystemService.filter(function (
      item,
      index
    ) {
      return item.serviceName.trim() != '' && item.selectTypeSystem.length > 0;
    });
    if (
      bValidRowTypeSystemService.length === 0 ||
      funcionState.name.trim().length === 0 ||
      funcionState.selectTypeFunction.length === 0
    ) {
      valid = false;
    }
    if (!valid) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Completar todos los campos.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    if (
      funcionState.selectTypeFunction[0].value != 1 &&
      funcionState.selectAmdminCamposFinal.length === 0
    ) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Debe seleccionar al menos un campo.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    let oRegisterHeadBoard = {};
    oRegisterHeadBoard.idFunction = null;
    oRegisterHeadBoard.name = funcionState.name;
    oRegisterHeadBoard.selectTypeFunction = funcionState.selectTypeFunction;
    let oRegisterDetailBoard = {};
    // oRegisterDetailBoard.deleteRowAvailableTypeSystemFunction =
    //   funcionState.deleteRowAvailableTypeSystemFunction;
    // oRegisterDetailBoard.newRowAvailableTypeSystemFunction =
    //   funcionState.listTypeSystemService.filter(function (item, index) {
    //     return item.idAvailableTypeSystemFunction === null;
    //   });
    // oRegisterDetailBoard.modifyRowAvailableTypeSystemFunction =
    //   funcionState.listTypeSystemService.filter(function (item, index) {
    //     return item.action === "M";
    //   });
    oRegisterDetailBoard.newRowAvailableTypeSystemFunction = funcionState.listTypeSystemService;
    var oParam = {
      oRegisterHeadBoard: oRegisterHeadBoard,
      oRegisterDetailBoard: oRegisterDetailBoard,
      aAdministrarCampos: funcionState.selectAmdminCamposFinal,
    };
    if (funcionState.selectTypeFunction[0].value === 1) {
      oParam.aAdministrarCampos = [
        ...funcionState.listAvailableFields
          .filter(function (item, index) {
            return item.Obligatorio === 1;
          })
          .map(function (item2, index2) {
            return item2.IDCampoHomo;
          }),
        ...funcionState.selectAmdminCamposFinal,
      ];
    }
    //

    // return;
    const responseRegisterFunction = await callEndpoint(registerUpdateFunction(oParam));
    if (responseRegisterFunction === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseRegisterFunction.data) {
        if (responseRegisterFunction.data.cabecera.statusCode === 200) {
          // setButtonAction(0);
          // setTabProceso(0);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseRegisterFunction.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          // dispatch(loadConceptos());
          limpiarCampos();
          navigate(paths.CONFIGURE.FUNCTION.BASE);
        } else if (responseRegisterFunction.data.cabecera.statusCode === 400) {
          Swal.fire({
            position: 'center',
            icon: 'info',
            title: responseRegisterFunction.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRegisterFunction.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };
  const limpiarCampos = (oEvent) => {
    dispatch(onSetDeleteAvailableTypeSystemFunction());
    dispatch(clearFieldFormFunction());
  };
  const handleChangeTipo = (value) => {
    var oParam = {};
    oParam.selectTypeFunction = [];
    oParam.bValidSelectTypeFunction = null;
    oParam.selectAmdminCampos = [];
    oParam.selectAmdminCamposFinal = [];
    if (value) {
      oParam.selectTypeFunction = [value];
      oParam.bValidSelectTypeFunction = true;
      // loadCamposDisponible();
    }
    dispatch(onSelectTypeFunction(oParam));
  };
  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: 'Crear Función' }));
      loadTypeFunction();
      loadSistemaDisponible();
      loadCamposDisponible();
    })();
  }, []);
  const loadCamposDisponible = async () => {
    const respObtenerCampos = await callEndpoint(obtenerCampos());
    var listAvailableFields = [];
    for (let index = 0; index < respObtenerCampos.data.respuesta.listaCampos.length; index++) {
      let obj = respObtenerCampos.data.respuesta.listaCampos[index];
      listAvailableFields.push(obj);
    }
    dispatch(
      setListAvailableFields({
        listAvailableFields: listAvailableFields,
      })
    );
  };
  const loadSistemaDisponible = async () => {
    const responseListaSistemas = await callEndpoint(listarSistemasDisponibles());
    var availableTypeSystemFunction = [];
    for (
      let index = 0;
      index < responseListaSistemas.data.respuesta.aListaSistemasDisponibles.length;
      index++
    ) {
      const element = responseListaSistemas.data.respuesta.aListaSistemasDisponibles[index];
      var obj = { ...element };
      obj.value = obj.IDSistema; //obj.IDTipoSistema
      obj.label = obj.AliasSistema; //obj.TipoSistema
      availableTypeSystemFunction.push(obj);
    }
    let oParam = {
      availableTypeSystemFunction: availableTypeSystemFunction,
    };
    dispatch(setAvailableTypeSystemFunction(oParam));
  };
  const handleChangeNombre = (oEvent) => {
    var name = oEvent.target.value;
    var validate = null;
    if (name.length > 0) {
      validate = true;
    }
    var oParam = {
      name: name,
      bValidName: validate,
    };
    dispatch(changeNameFunction(oParam));
  };
  const loadTypeFunction = async () => {
    const responseTypeListFuncion = await callEndpoint(listTypeFuncion());
    var listTypeFunction = [];
    for (
      let index = 0;
      index < responseTypeListFuncion.data.respuesta.aListaTipoFunc.length;
      index++
    ) {
      const element = responseTypeListFuncion.data.respuesta.aListaTipoFunc[index];
      var obj = createTypeFunctionAdapter(element);
      obj.value = obj.IDTypeFunction;
      obj.label = obj.TypeFuncion;
      listTypeFunction.push(obj);
    }
    let selectTypeFunction = [listTypeFunction[0]];
    dispatch(
      setListTypeFunction({
        listTypeFunction: listTypeFunction,
        selectTypeFunction: selectTypeFunction,
        bValidSelectTypeFunction: true,
      })
    );
  };
  const onPressShowSheetCampo = () => {
    if (funcionState.selectTypeFunction.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Debe seleccionar el tipo de función.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    setOpenSheetCampos(true);
  };
  const onApplyAllAdminCampos = (oEvent) => {
    let lista =
      funcionState.selectTypeFunction[0].value != 1
        ? funcionState.listAvailableFields
        : funcionState.listAvailableFields.filter((item) => item.Obligatorio === 2);
    let aId = lista.map(function (item, index) {
      return item.IDCampoHomo;
    });
    dispatch(setSelectAmdminCamposFinal(aId));
    dispatch(setSelectAmdminCampos(aId));
    setOpenSheetCampos(false);
  };
  const onApplyAdminCampos = (oEvent) => {
    dispatch(setSelectAmdminCamposFinal(funcionState.selectAmdminCampos));
    setOpenSheetCampos(false);
  };
  const showSubtitle = () => {
    let listaObligatorios = funcionState.listAvailableFields.filter(function (item, index) {
      return item.Obligatorio === 1;
    });
    var msg = 'Campos Obligatorios : ';
    for (let index = 0; index < listaObligatorios.length; index++) {
      const element = listaObligatorios[index];
      if (index === 0) {
        msg = element.NombreCampoAlias;
      } else {
        msg = msg + ' , ' + element.NombreCampoAlias;
      }
    }
    return <SubTitle subtitle={'Campos Obligatorios : ' + msg} />;
  };
  return (
    <Content>
      {loading === true ? <LoadingSpinner /> : <></>}
      <ColFlex>
        <SubTitle subtitle="1. Registrar datos generales de la función." />
        <ContentForm>
          <RowForm>
            <ColForm xs={24} sm={24} md={24} lg={8} xl={8}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Nombre (*):'}></LabelForm>
                <Input
                  placeholder="Ingrese..."
                  value={funcionState.name}
                  onChange={(evnt) => handleChangeNombre(evnt)}
                  validate={funcionState.bValidName}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={24} lg={8} xl={8}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Tipo (*):'}></LabelForm>
                <SelectForm
                  validate={funcionState.bValidSelectTypeFunction}
                  value={funcionState.selectTypeFunction}
                  handleChange={(e, v) => handleChangeTipo(v)}
                  options={funcionState.listTypeFunction}
                  placeholder={'Seleccione tipo'}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={24} lg={8} xl={8}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Campo (*):'}></LabelForm>
                <ButtonForm
                  width={'98%'}
                  action={(e) => onPressShowSheetCampo()}
                  name="Administrar campos"
                  isAction={true}
                  typeColor="primary"
                />
              </RowForm>
            </ColForm>
          </RowForm>
          <LabelForm title={'Listado de servicios :'}></LabelForm>
          <TableGrid
            columns={columnFuncion}
            rows={funcionState.listTypeSystemService}
            pageSize={15}
            getRowId={(row) => row.idAvailableTypeSystemFunction}
          />
          <RowFooterButton>
            <ButtonForm
              action={(e) => onPressCancelar()}
              name="Cancelar"
              isAction={true}
              typeColor="warning"
            />
            <ButtonForm
              action={(e) => limpiarCampos(e)}
              name="Limpiar"
              isAction={true}
              typeColor="primary"
            />
            <ButtonForm
              action={(e) => onPressGrabar(funcionState)}
              name="Grabar"
              isAction={true}
              typeColor="secondary"
            />
          </RowFooterButton>
        </ContentForm>
        <Drawer
          title={<Title>Administrador campos</Title>}
          placement="right"
          width={'33%'}
          closable={false}
          onClose={onDismiss}
          open={openSheetCampos}
          footer={
            <RowFooterButton>
              <ButtonForm
                action={(e) => onDismiss()}
                name="Cancelar"
                isAction={true}
                typeColor="warning"
              />
              <ButtonForm
                action={(e) => onApplyAllAdminCampos()}
                name="Seleccionar Todos"
                isAction={true}
                typeColor="primary"
              />
              <ButtonForm
                action={(e) => onApplyAdminCampos()}
                name="Aplicar"
                isAction={true}
                typeColor="secondary"
              />
            </RowFooterButton>
          }
        >
          <ColFlex>
            {funcionState.selectTypeFunction.length === 0 ? (
              <></>
            ) : funcionState.selectTypeFunction[0].value != 1 ? (
              <></>
            ) : funcionState.listAvailableFields.filter(function (item, index) {
                return item.Obligatorio === 1;
              }).length === 0 ? (
              <></>
            ) : (
              showSubtitle()
            )}

            <TableGrid
              columns={columnCampo}
              rows={
                funcionState.selectTypeFunction.length === 0 ? (
                  <></>
                ) : funcionState.selectTypeFunction[0].value != 1 ? (
                  funcionState.listAvailableFields
                ) : (
                  funcionState.listAvailableFields.filter(function (item, index) {
                    return item.Obligatorio === 2;
                  })
                )
              }
              pageSize={15}
              getRowId={(row) => row.IDCampoHomo}
              checkboxSelection={true}
              onSelectionModelChange={(oEvent) => {
                dispatch(setSelectAmdminCampos(oEvent));
              }}
              selectionModel={funcionState.selectAmdminCampos}
            />
          </ColFlex>
        </Drawer>
      </ColFlex>
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación de la función?"
        action={(e) => onConfirmFunction(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </Content>
  );
};
