import axios from 'axios';
import { buildQueryUrlService, loadAbort } from '../common/helper';

var urlBase = process.env.REACT_APP_API_BASE_URL;
var urlBaseGate = process.env.REACT_APP_API_GATE_URL;

const instance = axios.create({
  baseURL: urlBase,
  headers: {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'X-Requested-With': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    // 'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Authorization',
  },
});

function getAuthorization() {
  const accessToken = window.localStorage.getItem('auth-token');
  return accessToken;
}
function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem('token');
  return accessToken;
}

instance.interceptors.request.use(
  (config) => {
    const Authtoken = getAuthorization();
    const token = getLocalAccessToken();
    if (token) {
      config.headers['x-token'] = token;
      config.headers['Authorization'] = 'Bearer ' + Authtoken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const PruebaCognito = (auth) => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBaseGate + '/getCognito', {
      signal: controller.signal,
      headers: {
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
        'X-Requested-With': '*',
        Authorization: 'Bearer ' + auth,
      },
    }),
    controller,
  };
};

// Alter defaults after instance has been created
export const listarConceptos = () => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBase + '/obtenerConceptos', {
      signal: controller.signal,
    }),
    controller,
  };
};

export const listarConceptosXFiltro = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.post(urlBase + '/obtenerConceptosXFiltro', oParam, {
      signal: controller.signal,
    }),
    controller,
  };
};
export const listarRegistrosF = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.post(urlBase + '/obtenerRegistrosManualesF', oParam, {
      signal: controller.signal,
    }),
    controller,
  };
};
export const listarRegistrosM = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.post(urlBase + '/obtenerRegistrosManualesM', oParam, {
      signal: controller.signal,
    }),
    controller,
  };
};

export const listarRegistrosM_AP = () => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBase + '/obtenerRegistrosManualesXPagar', {
      signal: controller.signal,
    }),
    controller,
  };
};
export const listarRegistrosM_FC = () => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBase + '/obtenerRegistrosManualesXCobrar', {
      signal: controller.signal,
    }),
    controller,
  };
};
export const obtenerPosicionXConcepto = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerPosicionesXConcepto', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const listarCaracteristicasXfuncion = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.get(
      // urlBase +
      //   "/obtenerCaracteristicaXFuncion?IDFConexionAgrupador= " +
      //   oParam[0].value,
      urlBase + '/funcion/obtenerCaracteristicaXFuncion?IDFConexionAgrupador= ' + oParam[0].value,
      {
        signal: controller.signal,
      }
    ),
    controller,
  };
};
export const listarFunciones = (oParam) => {
  const controller = loadAbort();
  return {
    // call: instance.get(urlBase + "/obtenerListadoFuncionesBase", {
    call: instance.get(urlBase + '/funcion/obtenerListadoFuncionesBase', {
      signal: controller.signal,
    }),
    controller,
  };
};
export const eliminarConceptos = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarConceptos', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const registrarConcepto = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/registrarConcepto', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const modificarConcepto = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/modificarConcepto', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerCabDetalleConcepto = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerCabDetalleConcepto', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
//SERVICIOS DE POSICIONES
export const listarPosiciones = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBase + '/obtenerListadoPosiciones', {
      signal: controller.signal,
    }),
    controller,
  };
};
export const obtenerPosicion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerInfoPosicion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const modificarPosicion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/modificarPosicion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarPosiciones = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarPosiciones', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const listaDatosKeyConceptoPosicion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerKeyDetalleConceptoXagrupador?iIDAgrupador= ' + oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const listadoCamposFuncionHomologacion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerListadoCamposFuncion?iIDFConexion=' + oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const listadoCamposKeyXConceptoXFuncion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/listarKeysPosicion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerCaracteristicasXPosicion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerCaracXPos_SinKeys', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerListadoFunciones = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/funcion/obtenerListadoFunciones', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const listadoFuncionesComp = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      //call: instance.get(urlBase + "/obtenerListadoFuncionesComp", {
      call: instance.get(urlBase + '/funcion/obtenerListadoFuncionesComp', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const registrarPosicion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/registrarPosicion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const registrarRegistroM = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/CrearRegistrosManuales', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarRegistroM = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/EliminarRegistrosManuales', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarFunciones = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/funcion/eliminarConfiguracionFuncion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const listarConceptosCrearEstructuraXtipos = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerConceptos', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const listaConceptosXtipoConcepto = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerConceptosXtipo?tipoConcepto=' + oParam.sTipo, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerListadoSubAgrupadorCrearEstructura = (oParam) => {
  const controller = loadAbort();
  let finalUrl = buildQueryUrlService(urlBase + '/obtenerSubAgrupadoresXfiltro', oParam);
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerEstructuras = (oParam) => {
  const controller = loadAbort();
  let finalUrl = buildQueryUrlService(urlBase + '/obtenerEstructuraXfiltro');
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerEstructuraXfiltro = (oParam) => {
  const controller = loadAbort();
  let finalUrl = buildQueryUrlService(urlBase + '/obtenerEstructuraXfiltro', oParam);
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerListaConceptosXtipoConcepto = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerConceptosXtipo?tipoConcepto=' + oParam.sTipo, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const registrarEstructura = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/registrarEstructura', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerEstructurasXfiltro = (oParam) => {
  const controller = loadAbort();
  let finalUrl = buildQueryUrlService(urlBase + '/obtenerEstructuraXfiltro', oParam);
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerJsonEstructura = (oParam) => {
  const controller = loadAbort();
  let finalUrl = buildQueryUrlService(
    urlBase + '/obtenerJsonEstructura',
    // oParam
    null
  );
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const actualizarEstructuraSecundaria = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/modificarEstructuraXTipo';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarEstructuras = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/eliminarEstructura';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
//******************************************SERVICIOS DE FLUJO DE CAJA******************************************
export const obtenerEstructurasBase = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerEstructura';
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerListadoPeriodo = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerListadoPeriodo';
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerListadoMoneda = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerListadoMoneda';
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerSplitRegistrosNew = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerSplitRegistrosNuevo';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerSplitRegistros = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerSplitRegistros2';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarFlujoCajas = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/eliminarFlujoCajaGeneral';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerListadoFlujoCaja = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerFlujoCajaGeneral';
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerVersionesFlujoCaja = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerVersionesFlujoCaja';
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerDetalleVersionFlujoCaja = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerDetalleVersionFlujoCaja';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const crearVersionFlujoCaja = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/crearVersionFlujoCaja';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const updateVerionFlujoCaja = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/UpdateVersionFlujoCaja';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const deleteVersionFlujoCaja = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/DeleteVersionFlujoCaja';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerConceptosxFlujoCaja = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerConceptosxFlujoCaja';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerListadoPosicionesxFlujoCaja = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerListadoPosicionesxFlujoCaja';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerInfoFiltrosFlujoCajaCabecera = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerInfoFiltrosFlujoCajaCabecera';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerInfoFiltrosFlujoCajaCaracteristica = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerInfoFiltrosFlujoCajaCaracteristica';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerInfoFiltrosFlujoCajaValores = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerInfoFiltrosFlujoCajaValores';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
//******************************************SERVICIOS DE REGISTROS MANUALES******************************************

export const obtenerRegistrosManualesUno = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerRegistrosManualesUno';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const modificarRegistrosManuales = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/ModificarRegistrosManuales';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
//******************************************SERVICIOS DE OPTIMIZADOR*****************************************
export const ejecutarOptimizadorScriptNube = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/ejecutarScriptNube';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
//******************************************SERVICIOS DE ETIQUETAS******************************************
export const obtenerListadoEtiquetas = () => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBase + '/obtenerEtiqueta', {
      signal: controller.signal,
    }),
    controller,
  };
};
//eliminarEtiqueta - Post
export const eliminarEtiquetaMasivo = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/EliminarEtiquetaMasivo', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
//registrarEtiqueta - Post
export const registrarEtiqueta = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/CrearEtiqueta', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
//modificarEtiqueta - Post
export const modificarEtiqueta = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/EditarEtiqueta', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerPlantillaExcelRegManual = () => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBase + '/exportPlantilla/generarPlantillaRegManual', {
      signal: controller.signal,
    }),
    controller,
  };
  // return instance.request({
  //   responseType: 'arraybuffer',
  //   url: 'https://t-office-plantillas-carga-masiva.s3.amazonaws.com/masivo_registromanual.xlsx',
  //   method: 'get',
  //   headers: {
  //     'Content-Type': 'blob',
  //   },
  //   signal: controller.signal,
  // });
};
export const ejecutarScriptRegManualMasivo = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/ejecutarScriptRegManualMasivo';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerPlantillaRegManual = () => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBase + '/obtenerPlantillaRegManual', {
      signal: controller.signal,
    }),
    controller,
  };
};
/************** */
export const obtenerListarLineaCredito = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.post(urlBase + '/obtenerSolucion', oParam, {
      signal: controller.signal,
    }),
    controller,
  };
};
export const obtenerSoluciones = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.post(urlBase + '/obtenerSolucion', oParam, {
      signal: controller.signal,
    }),
    controller,
  };
};
export const obtenerInfoSolucion = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.post(urlBase + '/obtenerInfoSolucion', oParam, {
      signal: controller.signal,
    }),
    controller,
  };
};
export const obtenerInfoRegManualAP_FC = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.post(urlBase + '/obtenerInfoRegManualAP_FC', oParam, {
      signal: controller.signal,
    }),
    controller,
  };
};
export const obtenerInfoRegManualFC = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.post(urlBase + '/obtenerInfoRegManualFC', oParam, {
      signal: controller.signal,
    }),
    controller,
  };
};
export const obtenerAgentes = () => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBase + '/agente/obtenerAgentes', {
      signal: controller.signal,
    }),
    controller,
  };
};
export const registrarSolucion = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/registrarSolucion';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const editarSolucion = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/editarSolucion';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarSoluciones = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/eliminarSoluciones';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
//Login
export const inicioSesion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/api/login', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const cambiarPass = (oParam, auth, toke) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/api/login/cambiarPass', oParam, {
        signal: controller.signal,
        headers: {
          Authorization: 'Bearer ' + auth,
          'x-token': toke,
        },
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const cambiarPassUsu = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/api/login/cambiarPass', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const crearUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/api/login/new', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const editarUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/api/login/editarUsuario', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const eliminarUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/api/login/eliminarUsuario', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const reNewToken = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/api/login/renew', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerCaracPosTotal = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerCaracXPos_SinKeys_Total', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const guardarRegistrosFlujoCaja = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/guardarRegistros', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const guardarCambiosFlujoCaja = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/guardarCambios', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerListadoTC = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerListadoTC';
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const saveHeaderAndDetailsFlowsCaja = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/registrarDetalleFlujoCaja';
  try {
    return {
      call: instance.post(finalUrl, oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerDetalleFlujoCajaGeneral = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerDetalleFlujoCajaGeneral', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
/* TIPO DE SISTEMAS */
export const listarTipoSistema = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/sistema/listarTipoSistema', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
/* SISTEMA SERVICIOS */
export const listarSistemas = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(
        urlBase + '/sistema/listar?UUID_Tenant=add61c8e-85fd-4290-9e02-c8c296375cdc',
        {
          signal: controller.signal,
        }
      ),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const listarSistemasDisponibles = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/sistema/obtenerDisponibles', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const listarSistemasXCampoDisponibles = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/sistemaCampo/obtenerDisponibles', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarSistemas = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/sistema/eliminar', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const crearSistema = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/sistema/crear', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerCampoXTipoSistema = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/sistema/obtenerCampoXTipoSistema/?IDTipoSistema=' + oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const editarSistema = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/sistema/editar', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerInfoSistema = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/sistema/obtenerDetalle', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
////----
/* SISTEMA SERVICIOS */

export const listarSociedad = () => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/listarSociedad', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const crearSociedad = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/CrearSociedad', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const editarSocidad = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/EditarSocidad', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const eliminarSociedad = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarSociedad', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const listarCamposMJ = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/campo/obtenerListadoCamposMejorado', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const listarCampos = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/campo', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarCamposFila = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/campo/eliminar', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const crearCampo = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/campo/crear', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const editarCampo = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/campo/editar', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerInfoCampo = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/campo/obtenerDetalle', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const registrarCampo = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/campo/crear', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const listTypeFuncion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/funcion/listar', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const registerUpdateFunction = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/funcion/crearUpdate', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const updateFunction = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/funcion/update', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const getInfoFunction = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/funcion/obtenerInfoFuncion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerCampos = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/campo/obtenerCampos', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerDetalleVersionFiltrosFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/ObtenerDetalleVersionFiltro', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerVersionesFiltrosFCxUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerVersionesFiltro', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const guardarVersionFiltroFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/guardarVersionFiltro', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const modificarVersionFiltroFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/modificarVersionFiltro', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const eliminarVersionFiltroFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarVersionFiltro', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerDetalleVersionSolucionesFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/ObtenerDetalleVersionSolucion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerVersionesSolucionFCxUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerVersionesSolucion', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const guardarVersionSolucionFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/guardarVersionSolucion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const modificarVersionSolucionFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/modificarVersionSolucion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const eliminarVersionSolucionFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarVersionSolucion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerDetalleVersionRestriccionesFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/ObtenerDetalleVersionRegla', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerVersionesRestriccionesFCxUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerVersionesRegla', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const guardarVersionRestriccionesFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/guardarVersionRegla', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const modificarVersionRestriccionesFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/modificarVersionRegla', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarVersionRestriccionesFC = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarVersionRegla', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const validarVersionesRestriccionesFCxUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/ValidarAccesoVersionRegla', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const validarVersionesFiltrosFCxUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/ValidarAccesoVersionFiltro', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const validarVersionesSolucionesFCxUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/ValidarAccesoVersionSolucion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerCamposHomologados = () => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerCamposHomologados', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerRegistrosManualesMultiple = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerRegistrosManualesMultiple', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerCamposHomologadosReglas = () => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerCamposHomologadosReglas', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerInfoFiltrosFlujoCajaValoresReglas = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerInfoFiltrosFlujoCajaValoresReglas', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerValoresPaginado = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerValoresPaginado', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerConceptoPaginado = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerConceptoPaginado', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerPosicionPaginado = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerPosicionPaginado', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerSolucionesTipoCambio = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.post(urlBase + '/obtenerSolucionTipoCambio', oParam, {
      signal: controller.signal,
    }),
    controller,
  };
};
export const obtenerInfoSolucionTipoCambio = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.post(urlBase + '/obtenerInfoSolucionTipoCambio', oParam, {
      signal: controller.signal,
    }),
    controller,
  };
};

//Nuevo Campo

export const listarTipoDato = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBase + '/tipoDato/obtener', {
      signal: controller.signal,
    }),
    controller,
  };
};

export const generarMatrizCamposCabDetalle = (oParam) => {
  const controller = loadAbort();
  return {
    call: instance.get(urlBase + '/campo/generarMatrizCamposCabDetalle', {
      signal: controller.signal,
    }),
    controller,
  };
};

export const crearCampoMejorado = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/campo/crearCampoMejorado', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const crearHomoCampos = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/campo/crearHomoCampos', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const eliminarHomoCampos = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/campo/eliminarHomoCampos', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const editarCampoMejorado = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.put(urlBase + '/campo/editarCampoMejorado', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarCamposMejorado = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/campo/eliminarCamposMejorado', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const obtenerDetalleCampoMejorado = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/campo/obtenerDetalleCampoMejorado?idCampo= ' + oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerCampoHomo = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/campo/obtenerHomoCampos', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerTipoSisDisponiblesXSistema = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/sistema/obtenerTipoSisDisponiblesXSistema', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerListaUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerUsuario', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const listarUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/api/login/listarUsuario', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const cargaFotoUusuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/cargaFotoUusuario', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const cargaLogoSocio = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/cargaLogoSocio', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const cargaArchivosS3 = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/cargaArchivosS3', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerListaRol = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerRol', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerDetalleUsuario = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.put(urlBase + '/obtenerDetalleUsuario', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const crearUsuarioRol = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/CrearUsuarioRol', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerDetalleRol = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/ObtenerDellateRolPermiso', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const crearRol = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/CrearRol', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const editarRol = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/EditarRol', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const generarDatosDashboard = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/GenerarDatosDashboard', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const generarDatosDashboardRed1 = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/GenerarDatosDashboardReducido1', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const generarDatosDashboardRed2 = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/GenerarDatosDashboardReducido2', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const generarDatosDashboardRed3 = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/GenerarDatosDashboardReducido3', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerSocio = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerSocio', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const editarSocio = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/EditarSocio', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const crearSocio = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/CrearSocio', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarSocio = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarSocio', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const cargaMasivaPythonF = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/cargaMasivaPythonF', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const cargaMasivaPythonP = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/cargaMasivaPythonM', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerPersonalizacion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerPersonalizacion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const crearPersonalizacion = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/crearPersonalizacion', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const ObtenerTipoCambio = () => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/ObtenerTipoCambio', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const RegistrarTipoCambio = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/RegistrarTipoCambio', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerJob = () => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerJob', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerDetalleJob = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerDetalleJob', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const guardarJob = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/guardarJob', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const editarJob = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/editarJob', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const eliminarJob = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarJob', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const simularPrestamo = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/simularPrestamo', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerRutasDocu = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerRutasDocu', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerFiltrosRol = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerFiltrosRol', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const guardarFiltrosRol = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/guardarFiltrosRol', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const consultaPosibilidadesPermiso = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/consultaposibilidades', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const cargaMasivaPythonExcel = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/cargaMasivaPythonExcel', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const editarArchivosS3 = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/editarArchivosS3', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const eliminaArchivosS3 = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminaArchivosS3', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const eliminarRol = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarRol', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerMaster = () => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerMaster', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const registrarMaster = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/registrarMaster', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const modificarMaster = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/modificarMaster', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
export const eliminarMaster = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarMaster', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerDerivadas = () => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerDerivadas', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerDetalleDerivada = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/obtenerDetalleDerivada', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const guardarDerivada = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/guardarDerivada', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const modificarDerivada = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/modificarDerivada', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const eliminarDerivada = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/eliminarDerivada', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const obtenerFechas = () => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/obtenerFechas', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const guardarFechas = (oParam) => {
  const controller = loadAbort();
  try {
    return {
      call: instance.post(urlBase + '/guardarFechas', oParam, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

export const listarSociedadUsuario = () => {
  const controller = loadAbort();
  try {
    return {
      call: instance.get(urlBase + '/listarSociedadUsuario', {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};

//******************************************SERVICIOS DE OPTIMIZADOR*****************************************
export const obtenerPaletaColoresXSolucion = (oParam) => {
  const controller = loadAbort();
  let finalUrl = urlBase + '/obtenerPaletaColoresXSolucion';
  try {
    return {
      call: instance.get(finalUrl, {
        signal: controller.signal,
      }),
      controller,
    };
  } catch (error) {
    return error;
  }
};
