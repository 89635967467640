import { FaTrashCan } from 'react-icons/fa6';
import { ButtonForm } from '../../../../../components';
import { RowButton } from '../../../../../styles';

export const columnAction = (onDeleteColumn) => [
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    headerName: '',
    width: 40,
    renderCell: (params) => (
      <RowButton>
        <ButtonForm
          action={(oEvent) => onDeleteColumn(params.row.idDetalleDerivacion)}
          icon={<FaTrashCan />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="primary"
        />
      </RowButton>
    ),
  },
];
