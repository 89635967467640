import {
  ButtonForm,
  Input,
  LabelForm,
  ModalConfirmation,
  PBR,
  PBRFunction,
  SelectForm,
  SubTitle,
} from '../../../../components';
import {
  createConceptoPosicionAdapter,
  createMonedaAbreviaturaAdapter,
  createRegistroManualEtiquetaAdapter,
} from '../../../../adapters';
import {
  setListarConceptosManual,
  setSelectConceptoManual,
  onSetListaPosicionManual,
  setSelectPosicionManual,
  limpiarCamposRegistroM,
  changeNombreRegistroM,
  changeMontoRegistroM,
  changeMonedaRegistroM,
  changeMonedaPagoRegistroM,
  ChangeFechaRegistroM,
  updateTitle,
  setListaEtiquetasManual,
  setSelectedListaEtiquetaManual,
  ChangeFechaVigenciaRegistroM,
  setCurrencyList,
  setDataEditarRegistroM,
  setSelectedAccesoRM,
} from '../../../../redux/states';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  listarConceptos,
  obtenerPosicionXConcepto,
  obtenerCaracteristicasXPosicion,
  registrarRegistroM,
  obtenerListadoEtiquetas,
  registrarEtiqueta,
  obtenerListadoMoneda,
  obtenerRegistrosManualesUno,
} from '../../../../services';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useFetchAndLoad } from '../../../../common/hooks';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckboxStyle,
  ColFlex,
  ColForm,
  Content,
  ContentDouble,
  ContentForm,
  DatePickerForm,
  RowFooterButton,
  RowForm,
} from '../../../../styles';
import { buildPath, numbersWithComa, paths, permisos, theme } from '../../../../common';

export const CrearRegistroPropio = (props) => {
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const { id } = useParams();
  const manualState = useSelector((store) => store.registroManual);
  const authState = useSelector((store) => store.auth);
  const personalizacionState = useSelector((store) => store.personalizacion);
  const [loadingDetalle, setLoadingDetalle] = useState(false);
  const [rowsKeyData, setRowsKeyData] = useState([]);
  const { callEndpoint } = useFetchAndLoad();
  const [value, setValue] = useState(null);
  const [compartirAvailable, setCompartirAvailable] = useState(
    PBRFunction([permisos.COMPARTIR_REGISTRO])
  );

  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Crear registro propio' }));
    (async () => {
      onClickCopiarRegistroM();
      loadEtiquetasM();
      loadConcepto();
      loadMoneda();
    })();
  }, []);

  const loadConcepto = async () => {
    const responseListarConceptos = await callEndpoint(listarConceptos());
    let listaConceptos = [];
    for (
      let index = 0;
      index < responseListarConceptos.data.respuesta.aListaConceptos.length;
      index++
    ) {
      const element = responseListarConceptos.data.respuesta.aListaConceptos[index];
      listaConceptos.push(createConceptoPosicionAdapter(element));
    }
    dispatch(setListarConceptosManual(listaConceptos));
  };

  const loadMoneda = async () => {
    const responseCurrencyList = await callEndpoint(obtenerListadoMoneda());
    let currencyListManual = [];
    if (responseCurrencyList != null) {
      if (responseCurrencyList.data) {
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'texto_moneda'
        )[0].DatoPersonalizacion;
        let { aListaMoneda } = responseCurrencyList.data.respuesta;
        for (let index = 0; index < aListaMoneda.length; index++) {
          const element = aListaMoneda[index];
          currencyListManual.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
        }
      }
    }
    dispatch(setCurrencyList({ currencyListManual: currencyListManual }));
  };

  const loadEtiquetasM = async () => {
    const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());
    var listarEtiquetas = [];
    for (
      let index = 0;
      index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
      index++
    ) {
      const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
      var obj = createRegistroManualEtiquetaAdapter(element);
      listarEtiquetas.push(obj);
    }
    let objEtiqueta = {
      listarEtiquetasManual: listarEtiquetas,
    };
    dispatch(setListaEtiquetasManual(objEtiqueta));
  };

  const onClickCopiarRegistroM = async (row) => {
    if (id) {
      dispatch(updateTitle({ title: 'Copia registro propio' }));
      setLoadingDetalle(true);
      let aLista = [];
      let filtroDetalleCaract = [];
      let filtroCabCaract = [];
      var oParam = {
        RegistroManualConsulta: {
          IDRegistro: id,
        },
      };
      // const { data } = await callEndpoint(
      const responseObtRgManualUno = await callEndpoint(obtenerRegistrosManualesUno(oParam));
      if (responseObtRgManualUno != null) {
        if (responseObtRgManualUno.data) {
          if (responseObtRgManualUno.data.cabecera.statusCode === 200) {
            var oInfoRegManualUno =
              responseObtRgManualUno.data.respuesta.aListaRegistrosAgrupado[0];
            // navigate("/flujo_caja/propio/editar/" + row.IDRegistro);
            const responseListarConceptos = await callEndpoint(listarConceptos());
            let listaConceptos = [];
            for (
              let index = 0;
              index < responseListarConceptos.data.respuesta.aListaConceptos.length;
              index++
            ) {
              const element = responseListarConceptos.data.respuesta.aListaConceptos[index];
              listaConceptos.push(createConceptoPosicionAdapter(element));
            }
            let listaObtenerPosicionxConcepto = [];
            let obtenerPosicionXConceptoResponse = await callEndpoint(
              obtenerPosicionXConcepto({
                oIDConcepto: {
                  IDCon: oInfoRegManualUno.IDConcepto,
                },
              })
            );
            obtenerPosicionXConceptoResponse.data.respuesta.aListaPosiciones.forEach((element) => {
              let oPosicion = {};
              oPosicion.value = element.IdPosicion;
              oPosicion.label = element.NombrePosicion;
              listaObtenerPosicionxConcepto.push(oPosicion);
            });
            // dispatch(onSetListaPosicionManual(listaObtenerPosicionxConcepto));
            oInfoRegManualUno.Items.forEach(function (item, index) {
              let o = { ...item };
              o.NombreCampo = item.Caracteristica;
              o.label = item.Caracteristica;
              o.valor = item.Valor;
              o.validate = null;
              aLista.push(o);
              filtroDetalleCaract = aLista.filter(
                (item) =>
                  // item.Caracteristica !== "Cliente" &&
                  item.Caracteristica !== 'Importe' &&
                  item.Caracteristica !== 'FechaVcto' &&
                  item.Caracteristica !== 'MonedaImporte' &&
                  item.Caracteristica !== 'MonedaPago'
              );
              filtroCabCaract = aLista.filter(
                (item) =>
                  // item.Caracteristica === "Cliente" ||
                  item.Caracteristica === 'Importe' ||
                  item.Caracteristica === 'FechaVcto' ||
                  item.Caracteristica === 'MonedaImporte' ||
                  item.Caracteristica === 'MonedaPago'
              );
            });
            const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());
            var listarEtiquetasManual = [];
            for (
              let index = 0;
              index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
              index++
            ) {
              const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
              var obj = createRegistroManualEtiquetaAdapter(element);
              listarEtiquetasManual.push(obj);
            }
            var oParam = {};
            oParam.iIdRegistro = oInfoRegManualUno.IDRegistro;
            oParam.fCliente = '';
            oParam.fMonto = '';
            oParam.fMoneda = '';
            oParam.fMonedaPago = '';
            oParam.dateRegistroM = '';
            oParam.dateFechaVigenciaRegistroM = oInfoRegManualUno.FechaVigenciaRegistroM; //"";
            oParam.dateSelectedRegistroM = '';
            oParam.bValidNombre = true;
            oParam.bValidMonto = true;
            oParam.bValidMoneda = true;
            oParam.bValidMonedaPago = true;

            oParam.selectAccesoRM = !compartirAvailable
              ? oInfoRegManualUno.IDUsuario === 0
                ? true
                : false
              : false;
            oParam.selectConcepto = [
              {
                value: oInfoRegManualUno.IDConcepto,
                label: oInfoRegManualUno.NombreConcepto,
              },
            ];
            oParam.selectPosicion = [
              {
                value: oInfoRegManualUno.IDPosicion,
                label: oInfoRegManualUno.NombrePosicion,
              },
            ];
            oParam.listaConceptos = listaConceptos;
            // oParam.aDatosKey = [];
            // oParam.aDatosKeyCrearPosicion = [];
            oParam.rowsKeyData = filtroDetalleCaract; //[];
            setRowsKeyData(filtroDetalleCaract);
            // oParam.listaPosiciones = [];
            oParam.listaPosicionManual = listaObtenerPosicionxConcepto;
            filtroCabCaract.forEach((element) => {
              let valor = element.valor;
              let valorLabel = element.ValorLabel;
              // if (element.Caracteristica === "Cliente") {
              //   oParam.fCliente = valor;
              // } else
              if (element.Caracteristica === 'Importe') {
                oParam.fMonto = valor;
              } else if (element.Caracteristica === 'FechaVcto') {
                oParam.dateRegistroM = valor;
              } else if (element.Caracteristica === 'MonedaImporte') {
                oParam.fMoneda = valor;
                oParam.selectMoneda = [{ label: valorLabel, value: valor }];
              } else if (element.Caracteristica === 'MonedaPago') {
                oParam.fMonedaPago = valor;
                oParam.selectMonedaPago = [{ label: valorLabel, value: valor }];
              }
            });
            oParam.listarEtiquetasManual = listarEtiquetasManual;
            oParam.selectListEtiquetaManual = [];
            oInfoRegManualUno.aListaEtiquetas.forEach((element) => {
              var aEncontrado = listarEtiquetasManual.filter((item) => {
                return item.IDEtiqueta === parseInt(element.IDEtiqueta, 10);
              });
              if (aEncontrado.length > 0) {
                oParam.selectListEtiquetaManual.push(aEncontrado[0]);
              }
            });
            dispatch(setDataEditarRegistroM(oParam));
            await obtenerListadoMoneda();
          }
        }
      }
      setLoadingDetalle(false);
    }
  };

  const getPosicionXConcepto = async (oEvent, value) => {
    if (value) {
      dispatch(setSelectConceptoManual([value]));
      let listaObtenerPosicionxConcepto = [];
      let { data } = await callEndpoint(
        obtenerPosicionXConcepto({
          oIDConcepto: {
            IDCon: value.value,
          },
        })
      );
      data.respuesta.aListaPosiciones.forEach((element) => {
        let oPosicion = {};
        oPosicion.value = element.IdPosicion;
        oPosicion.label = element.NombrePosicion;
        listaObtenerPosicionxConcepto.push(oPosicion);
      });
      dispatch(onSetListaPosicionManual(listaObtenerPosicionxConcepto));
    } else {
      dispatch(onSetListaPosicionManual([]));
      dispatch(setSelectConceptoManual([]));
      dispatch(setSelectPosicionManual([]));
      setRowsKeyData([]);
    }
  };

  const changePosicionCaracteristica = async (oEvent, value) => {
    let aLista = [];
    let filtro = [];
    if (value) {
      dispatch(setSelectPosicionManual([value]));
      const responseCaracteristicasXPosicion = await callEndpoint(
        obtenerCaracteristicasXPosicion({
          oIDPosicion: {
            iIDPos: value.value,
          },
        })
      );
      // let filterCompare = [];
      // responseCaracteristicasXPosicion.data.respuesta.instancias[0].Items.forEach(
      //   function (item, index) {
      //     let name = {};
      //     name.NombreCampo = item.NombreCampo;
      //     filterCompare.push(name);
      //   }
      // );
      if (responseCaracteristicasXPosicion === null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (responseCaracteristicasXPosicion.data.cabecera.statusCode === 200) {
          responseCaracteristicasXPosicion.data.respuesta.aListaAgrupadaCaracPos[0].Items.forEach(
            function (item, index) {
              let o = { ...item };
              o.label = item.NombreCampo;
              o.valor = '';
              o.validate = null;
              aLista.push(o);
              filtro = aLista.filter(
                (item) =>
                  // item.NombreCampo !== "Cliente" &&
                  item.NombreCampo !== 'Importe' &&
                  item.NombreCampo !== 'FechaVcto' &&
                  item.NombreCampo !== 'MonedaImporte' &&
                  item.NombreCampo !== 'MonedaPago'
              );
            }
          );
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseCaracteristicasXPosicion.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
      setRowsKeyData(filtro);
    }
  };

  const limpiarCampos = (oEvent) => {
    setRowsKeyData([]);
    dispatch(limpiarCamposRegistroM());
  };

  const handleChangeNombre = (oEvent) => {
    let nombre = oEvent.target.value;
    let validate = null;
    if (nombre.length > 0) {
      validate = true;
    }
    let oParam = {
      fCliente: nombre,
      bValidNombre: validate,
    };
    dispatch(changeNombreRegistroM(oParam));
  };

  const handleChangeMonto = (oEvent) => {
    let monto = oEvent.target.value;
    let validate = null;
    if (monto.length > 0) {
      validate = true;
    }
    let oParam = {
      fMonto: monto,
      bValidMonto: validate,
    };
    dispatch(changeMontoRegistroM(oParam));
  };

  const handleChangeMoneda = (oEvent) => {
    let moneda = oEvent.target.value;
    let validate = null;
    if (moneda.length > 0) {
      validate = true;
    }
    let oParam = {
      fMoneda: moneda,
      bValidMoneda: validate,
    };
    dispatch(changeMonedaRegistroM(oParam));
  };

  const handleChangeSelectMoneda = (oEvent, value) => {
    let selectMoneda = [];
    let validate = null;
    if (value) {
      validate = true;
      selectMoneda.push(value);
    }
    let oParam = {
      selectMoneda: selectMoneda,
      bValidMoneda: validate,
    };
    dispatch(changeMonedaRegistroM(oParam));
  };

  const handleChangeMonedaPago = (oEvent) => {
    let monedaPago = oEvent.target.value;
    let validate = null;
    if (monedaPago.length > 0) {
      validate = true;
    }
    let oParam = {
      fMonedaPago: monedaPago,
      bValidMonedaPago: validate,
    };
    dispatch(changeMonedaPagoRegistroM(oParam));
  };

  const handleChangeSelectMonedaPago = (oEvent, value) => {
    let selectMonedaPago = [];
    let validate = null;
    if (value) {
      validate = true;
      selectMonedaPago.push(value);
    }
    let oParam = {
      selectMonedaPago: selectMonedaPago,
      bValidMonedaPago: validate,
    };
    dispatch(changeMonedaPagoRegistroM(oParam));
  };

  const handleChangeFechaRegistroM = (oEvent) => {
    const formatDate = moment(oEvent[0]).format('DD/MM/YYYY');
    const dateFormat = formatDate.replaceAll('/', '.');
    let oParam = {
      dateRegistroM: dateFormat,
    };
    dispatch(ChangeFechaRegistroM(oParam));
  };

  const handleChangeFechaVigenciaRegistroM = (oEvent) => {
    const formatDate = moment(oEvent[0]).format('DD/MM/YYYY');
    const dateFormat = formatDate.replaceAll('/', '.');
    let oParam = {
      dateFechaVigenciaRegistroM: dateFormat,
    };
    dispatch(ChangeFechaVigenciaRegistroM(oParam));
  };

  const handleChangeMultiKey = (oEvent, value) => {
    // let findEtiqueta = [];
    // for (let index = 0; index < oEvent.target.value.length; index++) {
    //   findEtiqueta.push(
    //     manualState.listarEtiquetasManual.filter((value) => {
    //       return value.value === oEvent.target.value[index];
    //     })[0]
    //   );
    // }
    if (value) {
      setValue(value);
      dispatch(
        setSelectedListaEtiquetaManual({
          selectListEtiquetaManual: value,
        })
      );
    } else {
      setValue([]);
      dispatch(
        setSelectedListaEtiquetaManual({
          selectListEtiquetaManual: [],
        })
      );
    }
  };

  const handleCreate = async (inputValue) => {
    var oParam = {
      RegistroEtiqueta: {
        sNombreEtiqueta: inputValue,
        sDescripcionEtiqueta: inputValue,
      },
    };
    const registrarEtiquetaResponse = await callEndpoint(registrarEtiqueta(oParam));
    let idEtiqueta = registrarEtiquetaResponse.data.respuesta.IDEtiqueta;
    let objEtiqueta = {
      listarEtiquetasManual: [
        ...manualState.listarEtiquetasManual,
        {
          IDEtiqueta: idEtiqueta,
          NombreEtiqueta: inputValue,
          label: inputValue,
          value: idEtiqueta,
        },
      ],
      selectListEtiquetaManual: [
        ...manualState.selectListEtiquetaManual,
        {
          IDEtiqueta: idEtiqueta,
          NombreEtiqueta: inputValue,
          label: inputValue,
          value: idEtiqueta,
        },
      ],
    };
    dispatch(setListaEtiquetasManual(objEtiqueta));
  };

  const handleChangeValor = (index, event) => {
    let sValor = event.target.value;
    let validate = null;
    if (sValor.length > 0) {
      validate = true;
    }
    const rows = [...rowsKeyData];
    rows[index]['valor'] = sValor;
    rows[index]['validate'] = validate;
    setRowsKeyData(rows);
  };

  const validarCaracteristicasKeyVacias = async () => {
    var aEncontradoKeyVacio = rowsKeyData.filter((element) => {
      return element.LD === 'L' && element.valor === '';
    });
    if (aEncontradoKeyVacio.length > 0) {
      Swal.fire({
        title:
          'La característica ' +
          aEncontradoKeyVacio[0].NombreCampo +
          ' se le asignará el valor de la posición seleccionada.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: '¿Desea crear el registro?',
            html: !compartirAvailable
              ? `Compartir el registro :  <input id="compartido" style="width:18px;height:18px" type='checkbox' ${
                  manualState.selectAccesoRM === true ? 'checked' : ''
                }/>`
              : '',
            icon: 'warning',
            showCancelButton: true,
            preConfirm: () => {
              var compartido;
              if (Swal.getPopup().querySelector('#compartido:checked')) {
                compartido = 1;
              } else compartido = 0;

              return { compartido: compartido };
            },
            preDeny: () => {},
            confirmButtonColor: theme.colors.primary,
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
          }).then(async (result) => {
            if (result.isConfirmed) {
              await registrarRegistroManual(result.value.compartido);
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: '¿Desea crear el registro?',
        html: !compartirAvailable
          ? `Compartir el registro :  <input id="compartido" style="width:18px;height:18px" type='checkbox' ${
              manualState.selectAccesoRM === true ? 'checked' : ''
            }/>`
          : '',

        icon: 'warning',
        showCancelButton: true,
        preConfirm: () => {
          var compartido;
          if (Swal.getPopup().querySelector('#compartido:checked')) {
            compartido = 1;
          } else compartido = 0;

          return { compartido: compartido };
        },
        preDeny: () => {},
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await registrarRegistroManual(result.value.compartido);
        }
      });
    }
  };

  const registrarRegistroManual = async (acceso) => {
    let aDatosKey = [];
    rowsKeyData.forEach(function (item, index) {
      let o = {};
      o.Valor = item.valor;
      o.Caracteristica = item.NombreCampo;
      if (item.valor.length === 0 && item.LD === 'L') {
        o.Valor = manualState.selectPosicion[0].label;
      }
      aDatosKey.push(o);
    });

    // let fCliente = {};
    // fCliente.Valor = manualState.fCliente;
    // fCliente.Caracteristica = "Cliente";
    // aDatosKey.push(fCliente);
    let fMonto = {};
    fMonto.Valor = manualState.fMonto;
    fMonto.Caracteristica = 'Importe';
    aDatosKey.push(fMonto);
    let dateRegistroM = {};
    dateRegistroM.Valor = manualState.dateRegistroM;
    dateRegistroM.Caracteristica = 'FechaVcto';
    aDatosKey.push(dateRegistroM);
    let fMoneda = {};
    fMoneda.Valor = manualState.selectMoneda[0].value; //manualState.fMoneda;
    fMoneda.Caracteristica = 'MonedaImporte';
    aDatosKey.push(fMoneda);
    let fMonedaPago = {};
    fMonedaPago.Valor = manualState.selectMonedaPago[0].value; //manualState.fMonedaPago;
    fMonedaPago.Caracteristica = 'MonedaPago';
    aDatosKey.push(fMonedaPago);
    let RegistroManualCreac = {
      IDConcepto: manualState.selectConcepto[0].iIDAgrupador,
      IDPosicion: manualState.selectPosicion[0].value,
      FechaVigenciaRegistroM: manualState.dateFechaVigenciaRegistroM,
      Items: aDatosKey,
      Etiquetas: manualState.selectListEtiquetaManual,
      idusuario: acceso === 0 ? authState.user.uid : 0,
    };
    let oParam = {
      RegistroManualCreac,
    };
    //return;
    const responseRegistrarRegistroM = await callEndpoint(registrarRegistroM(oParam));
    if (responseRegistrarRegistroM === null) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Ocurrió un error de servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseRegistrarRegistroM.data) {
        if (responseRegistrarRegistroM.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseRegistrarRegistroM.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          // props.setButtonAction(0);
          // await props.loadRegistrosM();
          // dispatch(updateTitle({ title: "Lista de registro manuales" }));
          limpiarCampos();
          navigate(buildPath(paths.CASH_FLOW.OWN_RECORD.LIST, { tab: 'Listado_Propio' }));
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRegistrarRegistroM.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error de servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };

  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };

  const onConfirmarConcepto = async () => {
    limpiarCampos();
    setShowModalCancelar(false);
    navigate(buildPath(paths.CASH_FLOW.OWN_RECORD.LIST, { tab: 'Listado_Propio' }));
  };

  const onPressGrabar = async (props) => {
    if (
      // manualState.fCliente !== "" &&
      manualState.fMonto !== '' &&
      manualState.dateRegistroM !== '' &&
      manualState.dateFechaVigenciaRegistroM !== '' &&
      // &&
      // manualState.selectListEtiquetaManual.length > 0
      manualState.selectMoneda.length > 0 &&
      manualState.selectMonedaPago.length > 0
    ) {
      if (manualState.selectListEtiquetaManual.length === 0) {
        Swal.fire({
          title: `No se ha seleccionado ninguna etiqueta. Se le asignará al grupo "Sin Etiquetas" `,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: theme.colors.primary,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await validarCaracteristicasKeyVacias();
          }
        });
      } else {
        await validarCaracteristicasKeyVacias();
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Registrar los campos obligatorios.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleChangeCompartirRM = (v) => {
    dispatch(setSelectedAccesoRM({ selectAccesoRM: !manualState.selectAccesoRM }));
  };

  return (
    <Content style={{ flexDirection: 'row' }}>
      <ContentForm>
        <SubTitle subtitle="1. Registrar datos generales del registro propio." />
        <RowForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Concepto (*):" obligatorio={true}></LabelForm>
              <SelectForm
                placeholder={'Seleccione Concepto'}
                value={manualState.selectConcepto}
                options={manualState.listaConceptos}
                handleChange={(e, v) => getPosicionXConcepto(e, v)}
              />
            </RowForm>
          </ColForm>

          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Posición (*):" obligatorio={true}></LabelForm>
              <SelectForm
                placeholder={'Seleccione Posición'}
                value={manualState.selectPosicion}
                options={manualState.listaPosicionManual}
                isDisabled={manualState.selectConcepto.length === 0 ? true : false}
                handleChange={(e, v) => changePosicionCaracteristica(e, v)}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Fecha de Vigencia (*):" obligatorio={true}></LabelForm>
              <DatePickerForm
                dateFormat="dd/MM/yyyy"
                selectsRange={true}
                isClearable={true}
                placeholderText="Ingrese"
                locale="es"
                showMonthDropdown
                showYearDropdown
                portalId="root-portal"
                value={manualState.dateFechaVigenciaRegistroM}
                onChange={(evnt) => handleChangeFechaVigenciaRegistroM(evnt)}
                minDate={new Date()}
                alignText={'center'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Monto (*):" obligatorio={true}></LabelForm>
              <Input
                placeholder="Ingrese..."
                value={manualState.fMonto}
                onKeyPress={(evnt) => numbersWithComa(evnt)}
                onChange={(evnt) => handleChangeMonto(evnt)}
                validate={manualState.bValidMonto}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Moneda (*):" obligatorio={true}></LabelForm>
              <SelectForm
                placeholder={'Seleccione Moneda'}
                value={manualState.selectMoneda}
                options={manualState.currencyListManual}
                handleChange={(e, v) => handleChangeSelectMoneda(e, v)}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Moneda de pago (*):" obligatorio={true}></LabelForm>
              <SelectForm
                placeholder={'Seleccione Moneda Pago'}
                value={manualState.selectMonedaPago}
                options={manualState.currencyListManual}
                handleChange={(e, v) => handleChangeSelectMonedaPago(e, v)}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Fecha (*):" obligatorio={true}></LabelForm>
              <DatePickerForm
                dateFormat="dd/MM/yyyy"
                selectsRange={true}
                isClearable={true}
                placeholderText="Ingrese"
                locale="es"
                showMonthDropdown
                showYearDropdown
                portalId="root-portal"
                value={manualState.dateRegistroM}
                onChange={(evnt) => handleChangeFechaRegistroM(evnt)}
                alignText={'center'}
              />
            </RowForm>
          </ColForm>

          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Etiquetas:"></LabelForm>
              <SelectForm
                isMultiple={true}
                value={manualState.selectListEtiquetaManual}
                handleChange={(e, v) => handleChangeMultiKey(e, v)}
                options={manualState.listarEtiquetasManual}
                placeholder={'Etiquetas'}
              />
            </RowForm>
          </ColForm>
          <PBR permiso={[permisos.COMPARTIR_REGISTRO]}>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title="Compartir registro:"></LabelForm>
                <CheckboxStyle
                  checked={manualState.selectAccesoRM}
                  onChange={(v) => handleChangeCompartirRM(v)}
                  size="small"
                />
              </RowForm>
            </ColForm>
          </PBR>
        </RowForm>
        <SubTitle subtitle="* Registrar campos obligatorios." obligatorio={true} />
        <RowFooterButton>
          <ButtonForm
            action={(e) => onPressCancelar()}
            name="Cancelar"
            isAction={true}
            typeColor="warning"
          />
          <ButtonForm
            action={(e) => limpiarCampos(e)}
            name="Limpiar"
            isAction={true}
            typeColor="primary"
          />
          <ButtonForm
            action={(e) => onPressGrabar()}
            name="Guardar"
            isAction={true}
            typeColor="secondary"
          />
        </RowFooterButton>
      </ContentForm>
      <ContentDouble>
        {rowsKeyData.length > 0 ? (
          <ColFlex
            style={{ marginTop: '5px', rowGap: '5px', marginBottom: '5px', padding: '15px 35px' }}
          >
            <SubTitle subtitle="Caracteristica:" obligatorio={true} />
            <RowForm>
              {rowsKeyData.map((data, index) => {
                const { label, valor, validate, KeyDet, LD } = data;
                return (
                  <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                    <RowForm>
                      {LD === 'L' ? (
                        <LabelForm title={label + ' (*):'} obligatorio={true}></LabelForm>
                      ) : (
                        <LabelForm title={label + ':'}></LabelForm>
                      )}
                      {KeyDet === 'KEY' ? (
                        <Input
                          placeholder="Ingrese..."
                          value={valor}
                          onChange={(evnt) => handleChangeValor(index, evnt)}
                          validate={validate}
                          disabled={true}
                        />
                      ) : (
                        <Input
                          placeholder="Ingrese..."
                          value={valor}
                          onChange={(evnt) => handleChangeValor(index, evnt)}
                          validate={validate}
                          disabled={false}
                        />
                      )}
                    </RowForm>
                  </ColForm>
                );
              })}
            </RowForm>
          </ColFlex>
        ) : (
          <></>
        )}
      </ContentDouble>
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación del registro propio?"
        action={(e) => onConfirmarConcepto(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </Content>
  );
};
