import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const registroManualInitialState = {
  valueFileUploader: '',
  iIdRegistro: null,
  fCliente: '',
  fMonto: '',
  selectMoneda: [],
  fMoneda: '',
  selectMonedaPago: [],
  fMonedaPago: '',
  dateRegistroM: '',
  dateFechaVigenciaRegistroM: '',
  dateSelectedRegistroM: '',
  bValidNombre: null,
  bValidMonto: null,
  bValidMoneda: null,
  bValidMonedaPago: null,
  selectConcepto: [],
  selectPosicion: [],
  listaConceptos: [],
  aDatosKey: [],
  aDatosKeyCrearPosicion: [],
  rowsKeyData: [],
  listaPosiciones: [],
  listaPosicionManual: [],
  currencyListManual: [],
  listarEtiquetas: [],
  listarEtiquetasEliminadas: [],
  modalOpenEliminarEtiqueta: false,
  modalOpenGuardarEtiqueta: false,
  modalOpenGuardarMultiEtiqueta: false,
  bValidListEtiquetaManual: null,
  selectEtiqueta: null,
  listarEtiquetasManual: [],
  selectListEtiquetaManual: [],
  filterListarRegistrosM: null,
  selectAccesoRM: false,
  listaRegistrosM: [],
  listaRegistrosMID: [],
  searchBool: false,
  searchText: '',
  searchArray: [],
  pageSize: 15,
  page: 0,
  rowCount: 0,
  fechaRegistro: '',
};
export const registroManualSlice = createSlice({
  name: 'registroManual',
  initialState: registroManualInitialState,
  reducers: {
    setCurrencyList: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearListaRM: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaRegistrosM: [],
        searchBool: false,
        listaRegistrosMID: [],
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setListarRegistrosM: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaRegistrosM: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setListarConceptosManual: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaConceptos: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setSelectConceptoManual: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        selectConcepto: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    onSetListaPosicionManual: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaPosicionManual: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setSelectPosicionManual: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        selectPosicion: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setFilterListarRegistrosM: (state, action) => {
      return { ...state, ...action.payload };
      // let estadoInspeccionado = current(state);
      // const filterListarRegistrosM = estadoInspeccionado.listaRegistrosM.filter(
      //   function (item) {
      //     return (
      //       item.Cliente
      //         .toLowerCase()
      //         .includes(action.payload.toLowerCase()) ||
      //       item.NombreConcepto
      //         .toLowerCase()
      //         .includes(action.payload.toLowerCase()) ||
      //       item.NombrePosicion
      //         .toLowerCase()
      //         .includes(action.payload.toLowerCase()) ||
      //       item.Importe
      //         .toLowerCase()
      //         .includes(action.payload.toLowerCase()) ||
      //       item.MonedaImporte
      //         .toLowerCase()
      //         .includes(action.payload.toLowerCase()) ||
      //       item.FechaVcto
      //         .toLowerCase()
      //         .includes(action.payload.toLowerCase())
      //     );
      //   }
      // );
      // var obj = {
      //   filterListarRegistrosM: filterListarRegistrosM,
      //   searchtext: action.payload,
      // };
      // return { ...estadoInspeccionado, ...obj };
    },
    filterListarEtiquetasM: (state, action) => {
      let estadoInspeccionado = current(state);
      function partition(arr, filter) {
        var fail = [];
        var pass = arr.filter((e, i, a) => {
          if (filter(e, i, a)) return true;
          fail.push(e);
        });
        return [pass, fail];
      }
      function filter(product) {
        return !product.containsNuts && !product.ingredients.contains('mushrooms');
      }
      var obj = {
        filterListarEtiquetasM: filterListarEtiquetasM,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    changeNombreRegistroM: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeMontoRegistroM: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeMonedaRegistroM: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeMonedaPagoRegistroM: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    ChangeFechaRegistroM: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    ChangeFechaVigenciaRegistroM: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    limpiarCamposRegistroM: (state, action) => {
      let estadoInspeccionado = current(state);
      return {
        ...estadoInspeccionado,
        ...{
          iIdRegistro: '',
          searchtext: '',
          selectConcepto: [],
          selectPosicion: [],
          aDatosKey: [],
          aDatosKeyCrearPosicion: [],
          rowsKeyData: [],
          listaPosiciones: [],
          listaPosicionManual: [],
          fCliente: '',
          fMonto: '',
          fMoneda: '',
          fMonedaPago: '',
          dateRegistroM: '',
          dateFechaVigenciaRegistroM: '',
          dateSelectedRegistroM: '',
          selectListEtiquetaManual: [],
          bValidPosicion: null,
          bValidMonto: null,
          bValidMoneda: null,
          selectMoneda: [],
          bValidMonedaPago: null,
          selectMonedaPago: [],
          bValidListEtiquetaManual: null,
          selectAccesoRM: false,
        },
      };
    },
    changeSearchTextManual: (state, action) => {
      return { ...state, ...action.payload };
    },
    setDataEditarRegistroM: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setRowsKeyDataRegistroM: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeRowsKeyValueDataRegistroM: (state, action) => {
      state.rowsKeyData.map((item, index) => {
        if (index === action.payload.index) {
          item.valor = action.payload.valor;
          item.validate = action.payload.validate;
        }
        return { ...item };
      });
    },
    setListaEtiquetas: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaEtiquetasManual: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeRowsKeyValueDataEtiquetas: (state, action) => {
      state.listarEtiquetas.map((item, index) => {
        if (item.IDEtiqueta === action.payload) {
          item.isDisabled = !item.isDisabled;
        }
        return { ...item };
      });
    },
    changeRowsKeyValueInputNombreDataEtiquetas: (state, action) => {
      state.listarEtiquetas.map((item, index) => {
        if (item.IDEtiqueta === action.payload.id) {
          item.NombreEtiqueta = action.payload.NombreEtiqueta;
        }
        return { ...item };
      });
    },
    changeRowsKeyValueInputDescripcionDataEtiquetas: (state, action) => {
      state.listarEtiquetas.map((item, index) => {
        if (item.IDEtiqueta === action.payload.id) {
          item.DescripcionEtiqueta = action.payload.DescripcionEtiqueta;
        }
        return { ...item };
      });
    },
    onAddRowtableEtiquetas: (state, action) => {
      return {
        ...state,
        listarEtiquetas: state.listarEtiquetas.concat(action.payload.newItem),
      };
    },
    onDeleteRowTableEtiquetas: (state, action) => {
      var oState = current(state);
      const aListaEtiquetasFinal = [...oState.listarEtiquetas];
      let objFilter = aListaEtiquetasFinal.filter((r) => r.IDEtiqueta != action.payload);
      let objEliminado = [
        ...state.listarEtiquetasEliminadas,
        ...aListaEtiquetasFinal.filter((r) => r.IDEtiqueta === action.payload),
      ];
      var obj = {
        listarEtiquetas: objFilter,
        listarEtiquetasEliminadas: objEliminado,
      };
      return { ...state, ...obj };
    },
    setModalOpenEliminarEtiqueta: (state, action) => {
      return { ...state, ...action.payload };
    },
    setModalOpenGuardarEtiqueta: (state, action) => {
      return { ...state, ...action.payload };
    },
    setModalOpenGuardarMultiEtiqueta: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSelectedEtiqueta: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSelectedListaEtiquetaManual: (state, action) => {
      return { ...state, ...action.payload };
    },
    setValueFileUploaderManual: (state, action) => {
      return { ...state, ...action.payload };
    },
    setLimpiarListarEtiquetasEliminadasManual: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSelectedAccesoRM: (state, action) => {
      return { ...state, ...action.payload };
    },
    setFiltroListaRM: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaIDRM: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        rowCount: action.payload.rowCount,
        listaRegistrosMID: action.payload.listaRegistrosMID,
      };
      return { ...estadoInspeccionado, ...obj };
    },
  },
});
export const {
  setCurrencyList,
  changeSearchTextManual,
  setListarRegistrosM,
  setFilterListarRegistrosM,
  filterListarEtiquetasM,
  setListarConceptosManual,
  setSelectConceptoManual,
  onSetListaPosicionManual,
  setSelectPosicionManual,
  limpiarCamposRegistroM,
  changeNombreRegistroM,
  changeMontoRegistroM,
  changeMonedaRegistroM,
  changeMonedaPagoRegistroM,
  ChangeFechaRegistroM,
  ChangeFechaVigenciaRegistroM,
  setDataEditarRegistroM,
  setRowsKeyDataRegistroM,
  changeRowsKeyValueDataRegistroM,
  setListaEtiquetas,
  setListaEtiquetasManual,
  changeRowsKeyValueDataEtiquetas,
  onDeleteRowTableEtiquetas,
  setModalOpenEliminarEtiqueta,
  setModalOpenGuardarEtiqueta,
  setModalOpenGuardarMultiEtiqueta,
  changeRowsKeyValueInputNombreDataEtiquetas,
  changeRowsKeyValueInputDescripcionDataEtiquetas,
  setSelectedEtiqueta,
  onAddRowtableEtiquetas,
  setSelectedListaEtiquetaManual,
  setValueFileUploaderManual,
  setLimpiarListarEtiquetasEliminadasManual,
  setSelectedAccesoRM,
  setFiltroListaRM,
  setListaIDRM,
  clearListaRM,
} = registroManualSlice.actions;
export default registroManualSlice.reducer;
