import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../common';
const InputTextAutocomplete = ({
  label,
  pholder,
  data,
  onSelected,
  onChange,
  value,
  index,
  campo,
  dataRow,
  indexLis,
  indexHomo,
}) => {
  const [suggestions, setSugesstions] = useState([]);
  const [isHideSuggs, setIsHideSuggs] = useState(false);
  const [selectedVal, setSelectedVal] = useState('');
  const handler = (e) => {
    setSugesstions(data.filter((i) => i.toLowerCase().includes(e.target.value.toLowerCase())));
  };
  const handleChange = (e) => {
    const input = e.target.value;
    setIsHideSuggs(false);
    setSelectedVal(input);
    onChange(input, indexHomo, indexLis, dataRow);
    if (input.length === 0) {
      setIsHideSuggs(true);
    }
  };
  const hideSuggs = (value) => {
    onSelected(value);
    setSelectedVal(value);
    setIsHideSuggs(true);
  };
  return (
    <ContentSuggestionAuto>
      <formControlAuto>
        <Input
          placeholder={pholder}
          type="search"
          value={value}
          onChange={handleChange}
          onKeyUp={handler}
        />
      </formControlAuto>
      {suggestions.length === 0 ? (
        <></>
      ) : (
        <ContentSuggestions
          className="suggestions"
          isHideSuggs={isHideSuggs}
          // style={{ display: isHideSuggs ? "none" : "block" }}
        >
          {selectedVal.length === 0 ? (
            <></>
          ) : (
            suggestions.map((item, idx) => (
              <div
                key={'' + item + idx}
                onClick={() => {
                  hideSuggs(item);
                }}
              >
                {item}
              </div>
            ))
          )}
        </ContentSuggestions>
      )}
    </ContentSuggestionAuto>
  );
};
const ContentSuggestionAuto = styled.div`
  /* display: block; */
  border-radius: 7px;
  display: flex;
  flex-direction: column;
`;
const formControlAuto = styled.div`
  background-color: #1e1f26 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;
const ContentSuggestions = styled.div`
  div {
    padding: 15px 25px;
  }
  margin-top: 40px;
  width: 350px;
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  /* background: #23252C; */
  background: white;
  /* height: fit-content; */
  border-radius: 20px 20px 10px 10px !important;
  display: flex;
  flex-direction: column;
  text-align: center;
  /* margin: 20px 0; */
  position: absolute;
  z-index: 1;
  padding: 2px 2px 2px 2px;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 0 solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.75rem !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  div:hover {
    /* background-color: #dbdbdb !important;
        color: #000000; */
    color: black;
    background-color: rgba(0, 0, 0, 0.03);
  }
  position: absolute;
  margin-top: 50px;
  z-index: 99999 !important;
  /* width : auto; */
  overflow: ${({ isHideSuggs }) => (isHideSuggs ? '' : 'scroll')};
  max-height: ${({ isHideSuggs }) => (isHideSuggs ? '0px' : '300px')};
  display: ${({ isHideSuggs }) => (isHideSuggs ? 'none' : 'block')};
`;
const Input = styled.input`
  border: 1px solid white;
  transition: width 0.5s;
  height: 40px;
  font-family: ${theme.font.family};
  font-size: 12px;
  margin-left: 0px;
  padding: ${({ active }) => (true ? '0 10px' : '0px')};
  opacity: ${({ active }) => (true ? '1' : '0')};
  width: ${({ width }) => (width ? width : '320px')};
  /* background-color: #f6f6f6; */
  /* border-radius: 8px; */
  &:focus {
    outline: none !important;
    border-color: ${({ validate }) =>
      !validate ? 'rgba(0, 0, 0, 0.23)' : validate === true ? 'rgba(0, 0, 0, 0.23)' : 'red'};
    box-shadow: 0 0 5px rgb(3, 201, 215);
  }
  background: white;
  text-align: center;
  /* border-radius: 8px; */
  border-color: ${({ validate }) =>
    !validate ? 'rgba(0, 0, 0, 0.23)' : validate === true ? 'rgba(0, 0, 0, 0.23)' : 'red'};
  transition: ${({ validate }) =>
    !validate
      ? 'rgba(0, 0, 0, 0.23)'
      : validate === true
      ? 'rgba(0, 0, 0, 0.23)'
      : 'border-color 0.5s ease-out'};
`;
export default InputTextAutocomplete;
