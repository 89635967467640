import { Workbook } from 'exceljs';
import { monthToString } from './formatFechas';

export function arrayToJson(jsonData) {
  let { data, value, arrData, arrValue } = jsonData;

  let arrJson = [];
  arrJson.push(data[value]);
  data[arrData].map((col) => {
    arrJson.push(col[arrValue]);
  });
  return arrJson;
}

function addText(jsonData, worksheet) {
  let { row, arrValue } = jsonData;
  arrValue.map((f) => {
    worksheet.getRow(row).getCell(f.cell).value = f.value;
  });
  return worksheet;
}

export function arrayDataToJson(jsonData) {
  let { data, value, arrData, arrValue, needId, idValue } = jsonData;
  let arrJson = [];
  data.map((col) => {
    let arr = [];
    arr.push(col[value]);
    if (arrData != '') {
      col[arrData].map((col1) => {
        if (col1[arrValue] != 0) {
          arr.push(col1[arrValue]);
        } else {
          arr.push('');
        }
      });
    }
    if (needId) {
      arr.push(col[idValue]);
    }
    arrJson.push(arr);
  });

  return arrJson;
}
export function headerFCExcel(dataHead) {
  let { data, value, validar, primerDato } = dataHead;
  let head = [];
  if (primerDato != '') {
    head.push(primerDato);
  }
  data.map((col) => {
    if (validar) {
      let cabeceraSplit, data;
      switch (validar) {
        case 'SEMANAL':
          data = 'Sem ' + col.Semana + '/' + col.Anio;
          head.push(data);
          break;
        case 'MENSUAL':
          data = monthToString(col.Mes) + ' ' + col.Anio;
          head.push(data);
          break;
        default:
          head.push(col[value]);
          break;
      }
    } else head.push(col[value]);
  });
  return head;
}
export function JsoToExcelJsFC(nombreArchivo, sheetName, insertBlankToTop, data) {
  const wb = new Workbook();
  wb.creator = 'zCFlow';
  let worksheet = wb.addWorksheet(sheetName);

  for (let i = 0; i < insertBlankToTop; i++) {
    worksheet.addRow('');
  }

  let head = headerFCExcel(data[0]);

  worksheet.addRow(head);
  let lastFill;
  for (let i = 1; i < data.length; i++) {
    let dataJson;
    switch (data[i].dataCase) {
      case 1:
        dataJson = arrayDataToJson(data[i]);
        worksheet.addRows(dataJson);
        if (data[i].needId) {
          worksheet = collapseExcelJs(data[i], worksheet);
          lastFill = dataJson.length;
        }
        break;
      case 2:
        dataJson = arrayToJson(data[i]);
        worksheet.addRow(dataJson);
        break;
      case 3:
        worksheet = addText(data[i], worksheet);
      default:
        break;
    }
  }
  let lasCell = worksheet.getRow(insertBlankToTop + 2).values.length - 1;
  worksheet.getColumn(lasCell).eachCell(function (cell, rowNumber) {
    cell.value = '';
  });

  for (var i = 2; i < lasCell; i++) {
    worksheet.getColumn(i).width = 9;
  }
  // ('Posición / Periodo');
  worksheet.properties.outlineProperties = {
    summaryBelow: false,
    summaryRight: false,
  };

  worksheet = sheetStyling(worksheet, lasCell, lastFill, insertBlankToTop);

  exportExcel(wb, nombreArchivo);
}

export const sheetStyling = (sheet, indexle, lasFill, insertBlankToTop) => {
  const firstRow = sheet.getRow(insertBlankToTop);
  firstRow.alignment = { horizontal: 'center', vertical: 'middle' };
  sheet.eachRow((row) => {
    if (row.number >= insertBlankToTop + 1) {
      row.font = { name: 'Arial' };
      for (let o = 1; o <= indexle - 1; o++) {
        const cell = row.getCell(o);
        if (cell.row === insertBlankToTop + 1) {
          cell.fill = {
            type: 'pattern',
            pattern: 'darkVertical',
            fgColor: { argb: 'FFADD8E6' },
            bgColor: { argb: 'FFADD8E6' },
          };
        } else {
          if (cell.col === '1' && cell.row > insertBlankToTop + 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'darkVertical',
              fgColor: { argb: 'fff4f1e6' },
              bgColor: { argb: 'fff4f1e6' },
            };
            cell.font = {
              bold: true,
            };
          }
          if (
            cell.row > lasFill + 2 ||
            cell.value === 'Saldo' ||
            cell.value === 'Ingreso' ||
            cell.value === 'Egreso'
          ) {
            if (cell.col === '1') {
              cell.font = {
                color: { argb: 'ff2e6b6a' },
                bold: true,
              };
              cell.fill = {
                type: 'pattern',
                pattern: 'darkVertical',
                fgColor: { argb: 'ffddf2eb' },
                bgColor: { argb: 'ffddf2eb' },
              };
            } else if (cell.col > 1) {
              if (cell.value < 0) {
                cell.font = {
                  color: { argb: 'fffc0000' },
                  bold: true,
                };
              } else {
                cell.font = {
                  color: { argb: 'ff32c307' },
                  bold: true,
                };
              }
            }
          }
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    }
  });
  return sheet;
};

const outlineRevision = (array, colum, sheet, data) => {
  let Col = [];
  let outline = 0;
  let existCol = array.filter((r) => colum.includes(r.valor));
  let rev = [];
  if (existCol.length != 0) {
    rev = data.filter((v) => v.Id.includes(colum));
    outline = existCol[existCol.length - 1].id + 1;
  }
  if (rev.length > 0) {
    sheet.eachRow(function (row, rowNumber) {
      if (row.values[row.values.length - 1].includes(colum)) {
        sheet.getRow(rowNumber).outlineLevel = outline;
        if (outline === 2) {
          sheet.getRow(rowNumber).hidden = true;
        }
        if (outline > 0) {
          sheet.getRow(rowNumber).getCell(1).value =
            '  ' + sheet.getRow(rowNumber).getCell(1).value;
        }
      }
    });
  }
  Col = [...array, { id: outline, valor: colum }];
  return [Col, sheet];
};
export const collapseExcelJs = (jsonData, sheet) => {
  let { data } = jsonData;
  let colS = [];
  let colI = [];
  let colE = [];
  data.map((col) => {
    let colum = col.Id;
    if (colum.includes('S')) {
      [colS, sheet] = outlineRevision(colS, colum, sheet, data);
    } else if (colum.includes('I')) {
      [colI, sheet] = outlineRevision(colI, colum, sheet, data);
    } else if (colum.includes('E')) {
      [colE, sheet] = outlineRevision(colE, colum, sheet, data);
    }
  });
  return sheet;
};

export const exportExcel = async (workbook, nameFile) => {
  const uint8Array = await workbook.xlsx.writeBuffer();
  const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${nameFile}.xlsx`;
  a.click();
  a.remove();
};
