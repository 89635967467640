import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { theme } from '../../common';
import { FaMagnifyingGlass } from 'react-icons/fa6';
// export const SearchInputText = ({filter,handleSubAgrupador}) => {
export const SearchInputText = (props) => {
  return (
    <Content width={props.width} style={props.style}>
      {/* <SearchInput placeholder="Buscar Posición..." value={filter || ''}  onChange={(oEvent) => handleSubAgrupador(oEvent)}/> */}
      <SearchInput
        width={props.width}
        placeholder={props.placeholder}
        value={props.filter || ''}
        onChange={props.onChange}
        prefix={<FaMagnifyingGlass />}
      />
    </Content>
  );
};
const Content = styled.div`
  margin-top: 1px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  svg {
    color: white;
    cursor: pointer;
  }
  margin-left: ${({ active }) => (true ? '5px' : '0px')};
  width: ${({ width }) => (width ? width : '250px')};
  &:focus {
    color: #1e2022;
    outline: 0;
    border-color: ${theme.colors.primary};
  }
`;

const SearchInput = styled(Input)`
  font-size: 13px;
  font-weight: 400;
  font-family: ${theme.font.family};
  width: 100%;
  padding-bottom: 2px;
  outline: none;
  border: none;
  border-radius: 0px;
  height: 2em;
  margin-bottom: 1px;
  border-bottom: 2px solid ${theme.colors.line} !important;
  color: ${theme.colors.text};
  background: none;
  input {
    background: none;
    ::placeholder {
      color: ${theme.colors.text};
      opacity: 0.7;
    }
  }
  svg {
    color: ${theme.colors.text} !important;
    opacity: 0.7;
  }
  &:focus {
    color: #1e2022;
    outline: 0;
    border-bottom: 2px solid ${theme.colors.line} !important;
    border-color: ${theme.colors.line};
    box-shadow: none;
  }
  &:hover {
    color: #1e2022;
    outline: 0;
    border-bottom: 2px solid ${theme.colors.primary} !important;
    box-shadow: none;
  }
  &:not(:hover) {
    color: #1e2022;
    outline: 0;
    border-bottom: 2px solid ${theme.colors.line} !important;
    box-shadow: none;
  }
`;
