import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const factoringInitialState = {
  //TablaBuscador
  searchtext: '',
  //tabla
  listaFactoring: [],
  filterListaFactoring: [],
  //formulario
  idSolucion: null,
  descripcion: '',
  bValidDescripcion: null,
  selectAgente: [],
  bValidAgente: null,
  listaAgente: [],
  dDateRange: [null, null],
  bValidFecha: null,
  selectTipoFormulario: [],
  bValidselectTipoFormulario: null,
  listaTipoFommulario: [],
  selectMoneda: [],
  bValidMoneda: null,
  listaMoneda: [],
  montoMaximo: '',
  bValidMontoMaximo: null,
  costoFijo: '',
  bValidCostoFijo: null,
  costoPorcentual: '',
  bValidCostoPorcentual: null,
  periodoMaximo: '',
  bValidPeriodoMaximo: null,
  selectTiempoPeriodoMaximo: [],
  bValidSelectTiempoPeriodoMaximo: null,
  periodoMinimo: '',
  bValidPeriodoMinimo: null,
  selectTiempoPeriodoMinimo: [],
  bValidSelectTiempoPeriodoMinimo: null,
  tea: '',
  bValidTea: null,
  moraVariableRetraso: '',
  bValidMoraVariableRetraso: null,
  periodoEntreMora: '',
  bValidPeriodoEntreMora: null,
  selectTiempoPeriodoEntreMora: [],
  bValidSelectTiempoPeriodoEntreMora: null,
  moraPorcentajeRetraso: '',
  bValidMoraPorcentajeRetraso: null,
  apariciones: '',
  bValidApariciones: null,
  selectEstado: [],
  bValidEstado: null,
  listaEstado: [],
  //
  valueFileUploader: '',
  modalOpenUploadDataExcel: false,
  uploadDataExcel: [],
  regManualesFC: [],
  selectRegManualesFC: [],
  listaColumnasDinamicaTablaFC: [],
  selectColumnasDinamicaTablaFC: [],
  listaTipoOperadorMultiplestableFC: [
    {
      value: 1,
      label: 'Contiene',
      idTipo: 1,
    },
    {
      value: 2,
      label: 'Igual',
      idTipo: 1,
    },
    {
      value: 3,
      label: 'Múltiple',
      idTipo: 1,
    },
    //Casos Number
    {
      value: 4,
      label: '=',
      idTipo: 2,
    },
    {
      value: 5,
      label: '>=',
      idTipo: 2,
    },
    {
      value: 6,
      label: '<=',
      idTipo: 2,
    },
    {
      value: 7,
      label: '>',
      idTipo: 2,
    },
    {
      value: 8,
      label: '<',
      idTipo: 2,
    },
    {
      value: 9,
      label: '<>',
      idTipo: 2,
    },
    {
      value: 10,
      label: 'Rango',
      idTipo: 2,
    },
    {
      value: 3,
      label: 'Múltiple',
      idTipo: 2,
    },
    //Casos Date
    {
      value: 4,
      label: '=',
      idTipo: 3,
    },
    {
      value: 7,
      label: '>',
      idTipo: 3,
    },
    {
      value: 8,
      label: '<',
      idTipo: 3,
    },
    {
      value: 9,
      label: '<>',
      idTipo: 3,
    },
    {
      value: 10,
      label: 'Rango',
      idTipo: 3,
    },
    {
      value: 3,
      label: 'Multiple',
      idTipo: 3,
    },
  ],
  listaMonedaSolucionDisponiblesTableFC: [],
  listMonedaSolucionTeaFC: [],

  listaColumnasMultiplestableFC: [],
  listaColumnasMultiplesGeneraltableFC: [],

  listaFiltrosMultiplesAppliedTableFC: [],
  listaFiltrosMultiplesTableFC: [],
};
export const factoringSlice = createSlice({
  name: 'factoring',
  initialState: factoringInitialState,
  reducers: {
    agregarFactoring: (state, action) => {
      return { ...state, ...action.payload };
    },
    editarFactoring: (state, action) => {
      return { ...state, ...action.payload };
    },
    eliminarFactorings: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTipoFomularioFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeCantCuotaFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaFactoring: (state, action) => {
      return { ...state, ...action.payload };
    },
    //Metodos del Formuario de Creacion
    changeDescripcionFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMontoMaximoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMoraFijoRetrasoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMoraVariableRetrasoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePeriodoEntreMoraFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoPeriodoEntreMoraFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePeriodoMaximoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePeriodoMinimoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoPeriodoMaximoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoPeriodoMinimoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMoraPorcentajeRetrasoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeTeaFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMontoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeAparicionesFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedAgenteFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaAgenteFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeVigenciaFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedMonedaFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setMonedaFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaMonedaFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedEstadoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaEstadoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    filterListarFactoring: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaFactoring = estadoInspeccionado.listaFactoring.filter(function (
        item,
        index
      ) {
        return (
          item.codigo.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.nombre.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.descripcion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.estado.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaFactoring: filterListaFactoring,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setDataInfoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaTiempoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarCamposFC: (state, action) => {
      return {
        ...state,
        ...{
          //TablaBuscador
          searchtext: '',
          //formulario
          selectTipoFormulario: [],
          bValidselectTipoFormulario: null,
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          selectMoneda: [],
          bValidMoneda: null,
          montoMaximo: '',
          bValidMontoMaximo: null,
          tea: '',
          bValidTea: null,
          periodoMaximo: '',
          bValidPeriodoMaximo: null,
          bValidSelectTiempoPeriodoMaximo: null,
          selectTiempoPeriodoMaximo: [],
          selectEstado: [],
          bValidEstado: null,
          apariciones: '',
          bValidApariciones: null,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      };
    },
    limpiarEditCamposFC: (state, action) => {
      return {
        ...state,
        ...{
          idSolucion: null,
          //TablaBuscador
          searchtext: '',
          //formulario
          selectTipoFormulario: [],
          bValidselectTipoFormulario: null,
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          selectMoneda: [],
          bValidMoneda: null,
          montoMaximo: '',
          bValidMontoMaximo: null,
          tea: '',
          bValidTea: null,
          periodoMaximo: '',
          bValidPeriodoMaximo: null,
          bValidSelectTiempoPeriodoMaximo: null,
          selectTiempoPeriodoMaximo: [],
          selectEstado: [],
          bValidEstado: null,
          apariciones: '',
          bValidApariciones: null,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      };
    },
    setValidCamposFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setRegManualesFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    addSelectRegManualesFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSelectRegManualesFC: (state, action) => {
      let oParam = {
        selectRegManualesFC: action.payload,
      };
      return { ...state, ...oParam };
    },
    //Acciones de Modal Filtros
    setColumnsFiltroMultiplesTableFC: (state, action) => {
      // state.listaColumnasMultiplestableFC = [...state.listaFiltrosMultiplesTableFC, action.payload];
      return { ...state, ...action.payload };
      // listaColumnasMultiplestableFC
    },
    setSelectColumnasDinamicaTablaFC: (state, action) => {
      // state.listaColumnasMultiplestableFC = [...state.listaFiltrosMultiplesTableFC, action.payload];
      return { ...state, ...action.payload };
      // listaColumnasMultiplestableFC
    },
    onChangeColumnsFiltroMultiplesTableFC: (state, action) => {
      let aEncontrado;

      if (action.payload.action === 'ADD') {
        aEncontrado = state.listaColumnasMultiplestableFC.filter(function (item, index) {
          return item.value === action.payload.oSelectColumna.value;
        });
        if (aEncontrado.length > 0) {
          aEncontrado[0].seleccionada = 1;
        }
      } else if (action.payload.action === 'REMOVE') {
        aEncontrado = state.listaColumnasMultiplestableFC.filter(function (item, index) {
          return item.value === action.payload.oSelectColumna.value;
        });
        if (aEncontrado.length > 0) {
          aEncontrado[0].seleccionada = 0;
        }
      }

      // listaColumnasMultiplestableFC
    },
    onChangeRowFiltroMultipleColumnaTableFC: (state, action) => {
      let oSelectColumnaPrev;
      let oSelectColumnaActual;
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        if (index === action.payload.index) {
          if (action.payload.oSelectColumna) {
            item.selectColumna = [action.payload.oSelectColumna];
            // oSelectColumnaActual = { ...item.selectColumna };
          } else {
            item.selectColumna = [];
            // oSelectColumnaPrev = null;
          }
          item.selectOperador = [];

          item.valueFields = [];
          item.newValue = '';
          item.aListaValores = [];
          item.CantidadReg = null;
          item.pagina = 1;
          item.paginaciones = null;
          item.paginacion = null;
        }
        return item;
      });
    },

    onChangeRowFiltroMultipleOperadorTableFC: (state, action) => {
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        if (index === action.payload.index) {
          item.valueFields = [];
          item.newValue = '';
          if (action.payload.oSelectOperador) {
            item.selectOperador = [action.payload.oSelectOperador];
          } else {
            item.selectOperador = [];
          }
        }
        return item;
      });
    },

    onChangeRowFiltroMultipleValorTableFC: (state, action) => {
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        if (index === action.payload.index) {
          item.valueFields = action.payload.valueFields;

          item.aListaValores = action.payload.aListaValores;
          item.CantidadReg = action.payload.CantidadReg;
          item.pagina = action.payload.pagina;
          item.paginaciones = action.payload.paginaciones;
          item.paginacion = action.payload.paginacion;
        }
        return item;
      });
    },

    onChangeRowFiltroMultipleValorTableFC: (state, action) => {
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        if (index === action.payload.index) {
          item.valueFields = action.payload.valueFields;

          item.aListaValores = action.payload.aListaValores;
          item.CantidadReg = action.payload.CantidadReg;
          item.pagina = action.payload.pagina;
          item.paginaciones = action.payload.paginaciones;
          item.paginacion = action.payload.paginacion;
        }
        return item;
      });
    },

    onChangeRowFiltroMultipleFieldValorTableFC: (state, action) => {
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        if (index === action.payload.index) {
          //item.valueFields = action.payload.valueFields;

          item.aListaValores = action.payload.aListaValores;
          item.CantidadReg = action.payload.CantidadReg;
          item.pagina = action.payload.pagina;
          item.paginaciones = action.payload.paginaciones;
          item.paginacion = action.payload.paginacion;
        }
        return item;
      });
    },

    onChangeRowMultiInputValueSingleEntryMultipleOperadorTableFC: (state, action) => {
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        if (index === action.payload.index) {
          item.newValue = action.payload.newValue;
        }
        return item;
      });
    },

    addNewEntryRowMultiInputValueNultriEntryMultipleOperadorTableFC: (state, action) => {
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        if (index === action.payload.index) {
          // item.valueFields = [...item.valueFields, action.payload.newValue];
          item.valueFields = [...new Set([...item.valueFields, action.payload.newValue])];

          item.newValue = '';

          item.aListaValores = action.payload.aListaValores;
          item.CantidadReg = action.payload.CantidadReg;
          item.pagina = action.payload.pagina;
          item.paginaciones = action.payload.paginaciones;
          item.paginacion = action.payload.paginacion;
        }
        return item;
      });
    },

    onSetRowMultiInputValueNultriEntryMultipleOperadorTableFC: (state, action) => {
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        if (index === action.payload.index) {
          item.valueFields = action.payload.valueFields; //action.payload.newValue;
          // item.newValue = '';
        }
        return item;
      });
    },

    onAddRowMonedaSolucionTableFC: (state, action) => {
      return {
        ...state,
        listMonedaSolucionTeaFC: state.listMonedaSolucionTeaFC.concat(action.payload.newItem),
      };
    },
    onChangeRowInputTeaSolucionTableFC: (state, action) => {
      state.listMonedaSolucionTeaFC.map((item, index) => {
        if (item.idRow === action.payload.id) {
          item.tea = action.payload.tea; //action.payload.newValue;
          item.bValidTea = action.payload.bValidTea;
          // item.newValue = '';
        }
        return item;
      });
    },
    onChangeRowMonedaSolucionTableFC: (state, action) => {
      state.listMonedaSolucionTeaFC.map((item, index) => {
        if (item.idRow === action.payload.id) {
          item.selectMonedaSolucion = action.payload.selectMonedaSolucion; //action.payload.newValue;
          // item.newValue = '';
        }
        return item;
      });
    },
    onDeleteRowMultiMonedaMultiTableFC: (state, action) => {
      let listMonedaSolucionTeaFC = state.listMonedaSolucionTeaFC.filter(function (item, index) {
        return item.idRow !== action.payload.id;
      });

      return {
        ...state,
        listMonedaSolucionTeaFC: listMonedaSolucionTeaFC,
      };
    },
    onSetMonedaSolucionTableFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaFiltrosMultiplesTableAppliedFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetMonedaSolucionDisponiblesTableFC: (state, action) => {
      return { ...state, ...action.payload };
    },

    onSetRowMultiInputValueNultriEntryMultipleOperadorTableFC: (state, action) => {
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        if (index === action.payload.index) {
          item.valueFields = action.payload.valueFields; //action.payload.newValue;
          // item.newValue = '';
        }
        return item;
      });
    },
    onChangeRowCondicionoMultipleOperadorTableFC: (state, action) => {
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        if (index === action.payload.index) {
          if (action.payload.oSelectCondicion) {
            item.selectCondicion = [action.payload.oSelectCondicion];
          } else {
            item.selectCondicion = [];
          }
        }
        return item;
      });
    },

    addFiltroMultiplesTableFC: (state, action) => {
      state.listaFiltrosMultiplesTableFC = [...state.listaFiltrosMultiplesTableFC, action.payload];
    },
    onSetFiltroMultiplesTableFC: (state, action) => {
      let listaColumnasMultiplestableFC = [];
      state.listaColumnasMultiplestableFC.forEach((element) => {
        let obj = { ...element };
        let aEncontrado = action.payload.aEncontradoNoAplicados.filter(function (item, index) {
          return element.value === item.selectColumna[0].value;
        });
        if (aEncontrado.length > 0) {
          obj.seleccionada = 0;
        }
        listaColumnasMultiplestableFC.push(obj);
      });

      return {
        ...state,
        ...{
          listaFiltrosMultiplesTableFC: action.payload.listaFiltrosMultiplesTableFC,
          listaColumnasMultiplestableFC: listaColumnasMultiplestableFC,
        },
      };
    },
    clearFiltroMultiplesTableFC: (state, action) => {
      return { ...state, ...{ listaFiltrosMultiplesTableFC: [] } };
    },
    deleteFiltroMultiplesTableFC: (state, action) => {
      let newList = [];
      state.listaFiltrosMultiplesTableFC.map((item, index) => {
        let obj = { ...item };
        if (index != action.payload.index) {
          newList.push(obj);
        } else {
          obj.status = 'E';
        }
      });

      let listaColumnasMultiplestableFC = [];

      if (action.payload.oSelectColumna.length > 0) {
        for (let index = 0; index < state.listaColumnasMultiplestableFC.length; index++) {
          let element = { ...state.listaColumnasMultiplestableFC[index] };
          if (element.value === action.payload.oSelectColumna[0].value) {
            element.seleccionada = 0;
          }
          listaColumnasMultiplestableFC.push(element);
        }
      } else {
        listaColumnasMultiplestableFC = [...state.listaColumnasMultiplestableFC];
      }

      return {
        ...state,
        ...{
          listaFiltrosMultiplesTableFC: newList,
          listaColumnasMultiplestableFC: listaColumnasMultiplestableFC,
        },
      };
    },
    deleteAllFiltroMultiplesTableFC: (state, action) => {
      let newList = [];
      state.listaColumnasMultiplestableFC.map((item, index) => {
        let obj = { ...item };
        obj.seleccionada = 0;
        newList.push(obj);
      });

      return {
        ...state,
        ...{
          listaFiltrosMultiplesTableFC: [],
          listaFiltrosMultiplesAppliedTableFC: [],
          listaColumnasMultiplestableFC: newList,
        },
      };
    },

    aplicarFiltrosMultiplesTableFC: (state, action) => {
      let listaColumnasMultiplestableFC = [];

      for (let index = 0; index < state.listaColumnasMultiplestableFC.length; index++) {
        let element = { ...state.listaColumnasMultiplestableFC[index] };
        listaColumnasMultiplestableFC.push(element);
      }

      action.payload.deleteNewList.forEach((element) => {
        if (element.selectColumna.length > 0) {
          let encontrado = listaColumnasMultiplestableFC.filter(function (item, index) {
            return item.value === element.selectColumna[0].value;
          });
          if (encontrado.length > 0) {
            encontrado[0].seleccionada = 0;
          }
        }
      });

      return {
        ...state,
        ...{
          listaFiltrosMultiplesTableFC: action.payload.listaFiltrosMultiplesTableFC,
          listaColumnasMultiplestableFC: listaColumnasMultiplestableFC,
        },
      };
    },

    onChangeAplicarAllRegFC: (state, action) => {
      return { ...state, ...{ aplicarAllReg: !state.aplicarAllReg } };
    },
  },
});
export const {
  agregarFactoring,
  editarFactoring,
  eliminarFactorings,
  selectedTipoFomularioFC,
  setListaFactoring,
  changeDescripcionFC,
  changeMontoMaximoFC,
  changeMoraFijoRetrasoFC,
  changeMoraVariableRetrasoFC,
  changePeriodoEntreMoraFC,
  selectedTiempoPeriodoEntreMoraFC,
  changePeriodoMaximoFC,
  selectedTiempoPeriodoMaximoFC,
  changeTeaFC,
  changeMoraPorcentajeRetrasoFC,
  changeMontoFC,
  changeAparicionesFC,
  selectedEstadoFC,
  setListaEstadoFC,
  filterListarFactoring,
  setValidCamposFC,
  limpiarCamposFC,
  limpiarEditCamposFC,
  setDataInfoFC,
  setListaTiempoFC,
  selectedAgenteFC,
  setListaAgenteFC,
  changeVigenciaFC,
  selectedMonedaFC,
  setListaMonedaFC,
  setRegManualesFC,
  setSelectRegManualesFC,
  setColumnsFiltroMultiplesTableFC,
  setSelectColumnasDinamicaTablaFC,
  onChangeColumnsFiltroMultiplesTableFC,
  onChangeRowFiltroMultipleColumnaTableFC,
  onChangeRowFiltroMultipleOperadorTableFC,
  onChangeRowFiltroMultipleValorTableFC,
  onChangeRowFiltroMultipleFieldValorTableFC,
  onChangeRowMultiInputValueSingleEntryMultipleOperadorTableFC,
  addNewEntryRowMultiInputValueNultriEntryMultipleOperadorTableFC,
  onAddRowMonedaSolucionTableFC,
  onChangeRowInputTeaSolucionTableFC,
  onChangeRowMonedaSolucionTableFC,
  onDeleteRowMultiMonedaMultiTableFC,
  onSetMonedaSolucionTableFC,
  onSetListaFiltrosMultiplesTableAppliedFC,
  onSetMonedaSolucionDisponiblesTableFC,
  onSetRowMultiInputValueNultriEntryMultipleOperadorTableFC,
  onChangeRowCondicionoMultipleOperadorTableFC,
  addFiltroMultiplesTableFC,
  onSetFiltroMultiplesTableFC,
  clearFiltroMultiplesTableFC,
  deleteFiltroMultiplesTableFC,
  deleteAllFiltroMultiplesTableFC,
  aplicarFiltrosMultiplesTableFC,
  onChangeAplicarAllRegFC,
  changePeriodoMinimoFC,
  selectedTiempoPeriodoMinimoFC,
} = factoringSlice.actions;
export default factoringSlice.reducer;
