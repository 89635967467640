import React from 'react';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { GlobalStyle } from './styles';
import { store } from './redux/store';
import { ConfigProvider } from 'antd';
import { AppRouter } from './routes';
import './index.css';
import './i18n';
import { theme } from './common';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.colors.primary,
          colorLink: theme.colors.primary,
          colorSuccess: theme.colors.success,
          colorWarning: theme.colors.warning,
          colorError: theme.colors.error,
          colorSecundary: theme.colors.secondary,
          colorSecundaryHover: theme.colors.secondary,
          fontFamily: theme.font.family,
        },
      }}
    >
      <GlobalStyle />
      <AppRouter />
    </ConfigProvider>
  </Provider>
);
reportWebVitals();
serviceWorker.unregister();
