import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  changeDataChangePassword,
  limpiarCamposLogin,
  onLogin,
  onLogout,
  onTitle,
} from '../../redux/states';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useFetchAndLoad } from './useFetchAndLoad';
import { jwtDecode } from 'jwt-decode';
import { inicioSesion, reNewToken } from '../../services';
import { paths } from '../../common';

// Crear el contexto
const AuthContext = createContext();
const broadcast = new BroadcastChannel('auth');
// Hook personalizado para usar el contexto
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const authState = useSelector((store) => store.auth);
  const [loading, setLoading] = useState(true);
  const { callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  // Simular la autenticación del usuario (se podría hacer una llamada a una API aquí)
  useEffect(() => {
    (async () => {
      let respuesta = await refreshTokensIfNeeded();
      if (respuesta) {
        if (respuesta.data.ok) {
          let respuestaJWT = jwtDecode(respuesta.data.token);
          localStorage.setItem('token', respuesta.data.token);
          localStorage.setItem('id-token', respuesta.data.IdToken);
          localStorage.setItem('auth-token', respuesta.data.AccessToken);
          localStorage.setItem('auth-token', respuesta.data.AccessToken);
          if (respuestaJWT) {
            dispatch(
              onLogin({
                name: respuestaJWT.usuario,
                uid: respuestaJWT.uid,
                isSuperAdmin: respuestaJWT.Admin,
                permisos: respuestaJWT.permisos,
                foto: respuestaJWT.foto,
                tenant: respuestaJWT.tenant,
              })
            );
          }
        }
      }
      setLoading(false);
      // Escuchar los mensajes de broadcast (evento de "logout" desde otras pestañas)
      broadcast.onmessage = (event) => {
        if (event.data === 'logout') {
          dispatch(onLogout(null));
        }
      };

      return () => {
        broadcast.close(); // Cerrar el canal al desmontar el componente
      };
    })();
  }, []);

  const isRefreshTokenExpired = () => {
    const expiration = localStorage.getItem('refresh-token-expiration');
    return new Date() > new Date(expiration);
  };

  const refreshTokensIfNeeded = async () => {
    if (isRefreshTokenExpired()) {
      console.warn('Refresh Token expirado. Redirigiendo al login.');
      logout(); // O redirigir al login
      return;
    }

    const refreshToken = localStorage.getItem('refresh-token');
    const responseRefresh = await callEndpoint(reNewToken({ RefreshToken: refreshToken }));
    return responseRefresh;
  };

  const login = async (userData) => {
    try {
      const encodedPassword = btoa(userData.password);
      const responseLogin = await callEndpoint(
        inicioSesion({
          email: userData.email,
          tenant: userData.tenant,
          password: encodedPassword,
        })
      );

      if (responseLogin.status === 200) {
        if (responseLogin.data.token) {
          localStorage.setItem('token', responseLogin.data.token);
          dispatch(limpiarCamposLogin());
          const today = new Date();
          const expirationDate = new Date(today);
          expirationDate.setDate(today.getDate() + 30);
          let respuestaJWT = jwtDecode(responseLogin.data.token);
          localStorage.setItem('auth-token', responseLogin.data.AccessToken);
          localStorage.setItem('id-token', responseLogin.data.IdToken);
          localStorage.setItem('refresh-token', responseLogin.data.RefreshToken);
          localStorage.setItem('refresh-token-expiration', expirationDate);
          dispatch(
            onLogin({
              name: respuestaJWT.usuario,
              uid: respuestaJWT.uid,
              isSuperAdmin: respuestaJWT.Admin,
              permisos: respuestaJWT.permisos,
              foto: respuestaJWT.foto,
              tenant: userData.tenant,
            })
          );
          setLoading(false);
          return paths.HOME.BASE;
        } else {
          dispatch(changeDataChangePassword({ sessionCP: responseLogin.data.result }));
          return paths.LOGIN.CHANGE_PASSWORD;
        }
      } else if (responseLogin.data) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Ocurrió un error en el servicio.',
          text: responseLogin.data.msg,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Ocurrió un error en el servicio.',
          text: responseLogin,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (e) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Ocurrió un error en el servicio.',
        text: e.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const updateTitle = (title) => {
    if (title) {
      dispatch(onTitle(title));
    }
  };

  const logout = () => {
    dispatch(onLogout(null));
    localStorage.removeItem('token');
    localStorage.removeItem('auth-token');
    localStorage.removeItem('id-token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('refresh-token-expiration');
    broadcast.postMessage('logout');
  };

  return (
    <AuthContext.Provider
      value={{
        user: authState.user,
        title: authState.layoutTitle,
        login,
        logout,
        updateTitle,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
