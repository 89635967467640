import React, { useState, useEffect } from 'react';
import { ButtonForm, SearchInputText, LoadingSpinner, TableGrid } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { obtenerListadoFlujoCaja } from '../../../../services';
import { Selected, useFetchAndLoad } from '../../../../common';
import {
  setFilterListarRegistrosM,
  onSetListaFlujoCajaGestion,
  updateTitle,
} from '../../../../redux/states';
import { ColFlex, ContentOption, RowFlex } from '../../../../styles';
import { createFlujoCajaAdapter } from '../../../../adapters/flujoCaja.adapter';
import { ModalDetalleCombFlujoCaja } from './components/ModalDetalleCombFlujoCaja';
import { FaTableCells } from 'react-icons/fa6';

export const ListarGestion = (props) => {
  // 0 Sin acción - 1 Agregar - 2 Editar
  const [loadingData, setLoadingData] = useState(false);
  const [showModalDetalleCombFlujoCaja, setShowModalDetalleCombFlujoCaja] = useState(false);
  const [selectedRegistroM, setSelectedRegistroM] = useState([]);
  const [selectEtiquetaM, setSelectEtiquetaM] = useState([]);
  const [selectColumn, setSelectColumn] = useState([]);
  const gestionState = useSelector((store) => store.gestion);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [searchText, setSearchtext] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: 'Listar gestión' }));
      loadFlujoCaja();
    })();
  }, []);

  const consultarDetalleCombSolucion = async () => {
    setShowModalDetalleCombFlujoCaja(true);
  };

  const columns = [
    {
      field: 'CodigoFlujoCaja',
      headerName: 'Código',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <>{params.value}</>,
      seleccionado: Selected.TRUE,
      columna: 'CodigoFlujoCaja',
      parsed: 'Código',
      restricted: Selected.TRUE,
    },
    {
      field: 'DetalleFlujoGeneral',
      headerName: 'Flujo Caja',
      flex: 1,
      minWidth: 220,
      renderCell: (params) => <>{params.value}</>,
      seleccionado: Selected.TRUE,
      columna: 'DetalleFlujoGeneral',
      parsed: 'Flujo Caja',
    },
    {
      field: 'aListaSoluciones',
      headerName: 'Combinacion de Soluciones',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <ButtonForm
            action={(oEvent) =>
              consultarDetalleCombSolucion({
                oEvent: oEvent,
                row: params.row,
              })
            }
            icon={<FaTableCells />}
            isAction={false}
            isOnlyIcon={true}
            typeColor="primary"
          />
        );
      },
      seleccionado: Selected.TRUE,
      columna: 'aListaSoluciones',
      parsed: 'Combinacion de Soluciones',
    },
  ];

  const loadFlujoCaja = async () => {
    const responseListaFlujoCajas = await callEndpoint(obtenerListadoFlujoCaja());
    var aListaFlujo = [];
    if (responseListaFlujoCajas != null) {
      if (responseListaFlujoCajas.data) {
        if (responseListaFlujoCajas.data.cabecera.statusCode === 200) {
          for (
            let index = 0;
            index < responseListaFlujoCajas.data.respuesta.aListaFlujo.length;
            index++
          ) {
            const element = responseListaFlujoCajas.data.respuesta.aListaFlujo[index];
            var obj = createFlujoCajaAdapter(element);
            aListaFlujo.push(obj);
          }
        }
      }
    }

    dispatch(onSetListaFlujoCajaGestion({ listaFlujoCajaSol: aListaFlujo }));
  };

  useEffect(() => {
    (async () => {
      let aLista = [];
      var searchtextF = '';
      if (searchText) {
        searchtextF = searchText.toLowerCase();
      }
      if (searchText.length > 0) {
        aLista = gestionState.listaFlujoCajaSol.filter(function (item) {
          return (
            item.Nombre?.toLowerCase().includes(searchtextF) ||
            item.NombreConcepto?.toLowerCase().includes(searchtextF) ||
            item.NombrePosicion?.toLowerCase().includes(searchtextF) ||
            item.Importe?.toLowerCase().includes(searchtextF) ||
            item.MonedaImporte?.toLowerCase().includes(searchtextF) ||
            item.FechaVcto?.toLowerCase().includes(searchtextF) ||
            item.Items.some((r) => r.Valor?.toLowerCase().includes(searchtextF))
          );
        });
      } else {
        aLista = gestionState.listaFlujoCajaSol;
      }

      let oParam = { filterListaFlujoCajaSol: aLista };
      dispatch(setFilterListarRegistrosM(oParam));
    })();
  }, [searchText]);

  const closeModalDetalleCombFlujoCaja = (e) => {
    setShowModalDetalleCombFlujoCaja(false);
  };

  return (
    <ColFlex>
      <ContentOption>
        <RowFlex>
          <SearchInputText
            width="300px"
            placeholder={'Buscar Flujos de caja...'}
            onChange={(e) => console.log(e)}
            filter={searchText}
            style={{ marginRight: '9px' }}
          />
        </RowFlex>
      </ContentOption>
      {
        <TableGrid
          columns={
            selectColumn.length > 0
              ? [...selectColumn.filter((r) => r.seleccionado === Selected.TRUE)]
              : [...columns.filter((r) => r.seleccionado === Selected.TRUE)]
          }
          rows={
            searchText.length === 0 && selectEtiquetaM.length === 0
              ? gestionState.listaFlujoCajaSol
              : gestionState.filterListaFlujoCajaSol
          }
          pageSize={15}
          getRowId={(row) => row.IDFlujoGeneral}
          checkboxSelection={true}
          onSelectionModelChange={(oEvent) => {
            setSelectedRegistroM(oEvent);
          }}
          selectionModel={selectedRegistroM}
        />
      }
      <ModalDetalleCombFlujoCaja
        isOpen={showModalDetalleCombFlujoCaja}
        loading={false}
        procesarSoluciones={(e) => console.log(e)}
        handleClose={closeModalDetalleCombFlujoCaja}
      />

      {loading === true || loadingData ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
