import React, { useState } from 'react';
import { LoadingSpinner, TableGrid } from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { Selected, useFetchAndLoad } from '../../../../../common';
import { useNavigate } from 'react-router-dom';
import { ColFlex } from '../../../../../styles';

export const DetalleTablaCombFlujoCaja = (props) => {
  // 0 Sin acción - 1 Agregar - 2 Editar
  const [loadingData, setLoadingData] = useState(false);
  const [selectedCombFlujoCaja, setSelectedCombFlujoCaja] = useState([]);
  const [selectColumn, setSelectColumn] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();

  const columns = [
    {
      field: 'Nombre',
      headerName: 'Nombre',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <>{params.value}</>,
      seleccionado: Selected.TRUE,
      columna: 'Nombre',
      parsed: 'Nombre',
      restricted: Selected.TRUE,
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      minWidth: 220,
      renderCell: (params) => <>{params.value}</>,
      seleccionado: Selected.TRUE,
      columna: 'Status',
      parsed: 'Status',
    },
  ];

  return (
    <ColFlex>
      {
        <TableGrid
          columns={
            selectColumn.length > 0
              ? [...selectColumn.filter((r) => r.seleccionado === Selected.TRUE)]
              : [...columns.filter((r) => r.seleccionado === Selected.TRUE)]
          }
          rows={[]}
          pageSize={15}
          getRowId={(row) => row.IDSolucionComb}
          checkboxSelection={true}
          onSelectionModelChange={(oEvent) => {
            setSelectedCombFlujoCaja(oEvent);
          }}
          selectionModel={selectedCombFlujoCaja}
        />
      }
      {loading === true || loadingData ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
