import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonForm,
  Input,
  LabelForm,
  ModalConfirmation,
  SelectForm,
} from '../../../components';
import {
  ColForm,
  ContentForm,
  DatePickerForm,
  RowDoubleInput,
  RowFooterButton,
  RowForm,
  TimePickerForm,
} from '../../../styles';
import {
  formatDateMMDDYYYY,
  formatStringDDMMYY,
  listaEstadoAutomatizacion,
  listaFrecuenciaAutomatizacion,
  listaModalidadAutomatizacion,
  listaTipoAutomatizacion,
  useFetchAndLoad,
  paths,
} from '../../../common';
import {
  setDatosCrearAutomatizacion,
  setLimpiarAutomatizacion,
  setListaModalidadDatos,
  setListarAutomatizacion,
  updateTitle,
} from '../../../redux/states';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import {
  guardarJob,
  listarPosiciones,
  obtenerEstructurasXfiltro,
  obtenerJob,
} from '../../../services';
import { listaPosicionAdapter } from '../../../adapters/posicion.adapter';
import { listaEstructuraAdapter } from '../../../adapters/estructura.adapter';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { listaValueAutomatizacionAdapter } from '../../../adapters';

export const CrearAutomatizacion = (props) => {
  const automatizacionState = useSelector((store) => store.automatizacion);
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Crear Automatizacion ' }));
    (async () => {
      dispatch(setLimpiarAutomatizacion());

      await loadAutomatizacion();
    })();
  }, []);

  const loadAutomatizacion = async () => {
    const responseListarAutomatizacion = await callEndpoint(obtenerJob());
    var listaAutomatizacion = [];
    for (let i = 0; i < responseListarAutomatizacion.data.respuesta.aDetalleJob.length; i++) {
      const ele = responseListarAutomatizacion.data.respuesta.aDetalleJob[i];
      listaAutomatizacion.push(listaValueAutomatizacionAdapter(ele));
    }
    dispatch(setListarAutomatizacion({ listaAutomatizaciones: listaAutomatizacion }));
  };

  const loadPosicion = async () => {
    const responseListaPosiciones = await callEndpoint(listarPosiciones());
    var listaPosiciones = [];
    for (
      let index = 0;
      index < responseListaPosiciones.data.respuesta.aListaPosiciones.length;
      index++
    ) {
      const element = responseListaPosiciones.data.respuesta.aListaPosiciones[index];
      var obj = listaPosicionAdapter(element);
      listaPosiciones.push(obj);
    }
    dispatch(setListaModalidadDatos(listaPosiciones));
  };
  const loadEstructura = async () => {
    var { data } = await callEndpoint(obtenerEstructurasXfiltro());

    var listaEstructura = [];
    for (let index = 0; index < data.respuesta.aListaEstructuras.length; index++) {
      const element = data.respuesta.aListaEstructuras[index];
      var obj = listaEstructuraAdapter(element);
      listaEstructura.push(obj);
    }

    dispatch(setListaModalidadDatos(listaEstructura));
  };

  const handleChangeTipo = (v) => {
    var oParam = {};
    oParam.bValidTipo = null;
    oParam.selectTipo = [];
    if (v) {
      oParam.selectTipo = [v];
      oParam.bValidTipo = true;
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeEstado = (v) => {
    var oParam = {};
    oParam.bValidEstado = null;
    oParam.selectEstado = [];
    if (v) {
      oParam.selectEstado = [v];
      oParam.bValidEstado = true;
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeNombre = (v) => {
    var oParam = {};
    oParam.bValidNombre = null;
    oParam.sNombre = '';
    if (v) {
      oParam.sNombre = v.target.value;
      oParam.bValidNombre = true;
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeDetalle = (v) => {
    var oParam = {};
    oParam.bValidDetalle = null;
    oParam.sDetalle = '';
    if (v) {
      oParam.sDetalle = v.target.value;
      oParam.bValidDetalle = true;
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeModalidad = (v) => {
    var oParam = {};
    oParam.bValidModalidad = null;
    oParam.selectModalidad = [];
    oParam.bValidModalidadDatos = null;
    oParam.selectModalidadDatos = [];
    if (v) {
      oParam.selectModalidad = [v];
      oParam.bValidModalidad = true;
      switch (v.value) {
        case 1:
          loadPosicion();
          break;
        case 2:
          loadPosicion();
          break;
        case 3:
          loadEstructura();
          break;
        default:
          break;
      }
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeModalidadDatos = (v) => {
    var oParam = {};
    oParam.bValidModalidadDatos = null;
    oParam.selectModalidadDatos = [];
    if (automatizacionState.selectModalidad[0].value != 2) {
      if (v) {
        oParam.selectModalidadDatos = [v];
        oParam.bValidModalidadDatos = true;
      }
    } else {
      oParam.selectModalidadDatos = v;
      oParam.bValidModalidadDatos = true;
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeFrecuencia = (v) => {
    var oParam = {};
    oParam.bValidFrecuencia = null;
    oParam.selectFrecuencia = [];
    if (v) {
      oParam.selectFrecuencia = [v];
      oParam.bValidFrecuencia = true;
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeFrecuenciaNumero = (v) => {
    var oParam = {};
    oParam.bValidFrecuenciaNumero = null;
    oParam.sFrecuenciaNumero = '';
    if (v) {
      oParam.sFrecuenciaNumero = v.target.value;
      oParam.bValidFrecuenciaNumero = true;
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeFechaInicio = (oEvent) => {
    var oParam = {
      dFechaInicio: oEvent,
    };
    var sFechaInicio = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';

    if (oParam.dFechaInicio != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaInicio.getDate();
      let iMes = oParam.dFechaInicio.getMonth() + 1;
      let iAnio = oParam.dFechaInicio.getFullYear();
      if (oParam.dFechaInicio.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
      oParam.dFechaInicio = sFechaInicio;
    }
    if (oParam.dFechaInicio != null) {
      oParam.bValidFechaInicio = true;
      oParam.dFechaFin = null;
      oParam.bValidFechaFin = null;
      oParam.dDateTimeFin = null;
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeTimeInicio = (oEvent) => {
    var oParam = {};
    let fecha = null;
    if (oEvent) {
      fecha = dayjs(oEvent);
    }

    oParam.dDateTimeInicio = fecha;
    oParam.dDateTimeFin = null;
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeFechaFin = (oEvent) => {
    var oParam = {
      dFechaFin: oEvent,
    };
    var sFechaFinal = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';

    if (oParam.dFechaFin != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaFin.getDate();
      let iMes = oParam.dFechaFin.getMonth() + 1;
      let iAnio = oParam.dFechaFin.getFullYear();
      if (oParam.dFechaFin.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaFinal = sDia + '/' + sMes + '/' + sAnio;
      oParam.dFechaFin = sFechaFinal;
      oParam.dDateTimeFin = null;
    }
    if (oParam.dFechaFin != null) {
      oParam.bValidFechaFin = true;
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const handleChangeTimeFin = (oEvent) => {
    let fecha = null;

    if (oEvent) {
      fecha = dayjs(oEvent);

      if (automatizacionState.dFechaInicio === automatizacionState.dFechaFin) {
        if (
          (automatizacionState.dDateTimeInicio.$d.getHours() === fecha.$d.getHours() &&
            automatizacionState.dDateTimeInicio.$d.getMinutes() != fecha.$d.getMinutes()) ||
          automatizacionState.dDateTimeInicio.$d.getHours() < fecha.$d.getHours()
        ) {
          var oParam = {};
          oParam.dDateTimeFin = fecha;
          dispatch(setDatosCrearAutomatizacion(oParam));
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'No puede poner la misma hora y fecha',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else if (automatizacionState.dFechaInicio != automatizacionState.dFechaFin) {
        var oParam = {};
        oParam.dDateTimeFin = fecha;
        dispatch(setDatosCrearAutomatizacion(oParam));
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No puede poner la misma hora y fecha',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      var oParam = {};
      oParam.dDateTimeFin = fecha;
      dispatch(setDatosCrearAutomatizacion(oParam));
    }
  };

  const handleChangeDependencia = (v) => {
    var oParam = {};
    oParam.bValidDependencias = null;
    oParam.selectDependencias = [];
    if (v) {
      oParam.selectDependencias = [v];
      oParam.bValidDependencias = true;
    }
    dispatch(setDatosCrearAutomatizacion(oParam));
  };

  const onLimpiarCampos = () => {
    dispatch(setLimpiarAutomatizacion());
  };
  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };

  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };

  const onCloseAutomatizacion = () => {
    setShowModalCancelar(false);
    onLimpiarCampos();
    navigate(paths.AUTOMATION.BASE);
  };

  const onValidarCampos = () => {
    let validado = false;
    if (automatizacionState.bValidTipo === true) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }
    if (automatizacionState.bValidEstado === true) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }
    if (automatizacionState.bValidNombre === true) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }
    if (automatizacionState.bValidDetalle === true) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }
    if (automatizacionState.bValidModalidad === true) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }
    if (automatizacionState.bValidModalidadDatos === true) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }
    if (automatizacionState.bValidFrecuencia === true) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }
    if (automatizacionState.bValidFrecuenciaNumero === true) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }
    if (automatizacionState.bValidFechaInicio === true) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }
    if (automatizacionState.bValidFechaFin === true) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }

    return validado;
  };

  const onPressGrabar = async () => {
    let validado = onValidarCampos();
    if (validado) {
      let oParam = {};
      let modalidadDatos = [];
      oParam.TipoAutomatizacion = automatizacionState.selectTipo[0].value;
      oParam.nombre = automatizacionState.sNombre;
      oParam.descripcion = automatizacionState.sDetalle;
      oParam.modalidad = automatizacionState.selectModalidad[0].value;
      automatizacionState.selectModalidadDatos.map((r) => {
        modalidadDatos.push(r.value);
      });
      oParam.modalidadDatos = `[${modalidadDatos}]`;
      oParam.frecuencia = automatizacionState.selectFrecuencia[0].value;
      oParam.numeroFrecuencia = automatizacionState.sFrecuenciaNumero;
      oParam.Estado = automatizacionState.selectEstado[0].value;

      let hourInicio = automatizacionState.dDateTimeInicio.$d.getHours();
      let minuteInicio = automatizacionState.dDateTimeInicio.$d.getMinutes();
      oParam.fechaInicio =
        formatStringDDMMYY(automatizacionState.dFechaInicio) +
        ' ' +
        hourInicio.toString().padStart(2, '0') +
        ':' +
        minuteInicio.toString().padStart(2, '0');

      let hourFin = automatizacionState.dDateTimeFin.$d.getHours();
      let minuteFin = automatizacionState.dDateTimeFin.$d.getMinutes();
      oParam.fechaFin =
        formatStringDDMMYY(automatizacionState.dFechaFin) +
        ' ' +
        hourFin.toString().padStart(2, '0') +
        ':' +
        minuteFin.toString().padStart(2, '0');

      if (automatizacionState.selectDependencias.length > 0) {
        oParam.dependencias = automatizacionState.selectDependencias[0].value;
      }

      var respuesta = await callEndpoint(guardarJob({ DetalleJob: oParam }));

      if (respuesta.data) {
        var { data } = respuesta;
        if (data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: respuesta.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          onLimpiarCampos();
          navigate(paths.AUTOMATION.BASE);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: respuesta.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se han llenado campos obligatorios.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <>
      <ContentForm>
        <RowForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Tipo (*):'} />
              <SelectForm
                validate={automatizacionState.bValidTipo}
                value={automatizacionState.selectTipo}
                handleChange={(e, v) => handleChangeTipo(v)}
                options={listaTipoAutomatizacion}
                placeholder={'Seleccione tipo'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Estado (*):'} />
              <SelectForm
                validate={automatizacionState.bValidEstado}
                value={automatizacionState.selectEstado}
                handleChange={(e, v) => handleChangeEstado(v)}
                options={listaEstadoAutomatizacion}
                placeholder={'Seleccione Estado'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Nombre (*):'} />
              <Input
                placeholder="Ingrese..."
                value={automatizacionState.sNombre}
                onChange={(evnt) => handleChangeNombre(evnt)}
                validate={automatizacionState.bValidNombre}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Detalle (*):'} />
              <Input
                placeholder="Ingrese..."
                value={automatizacionState.sDetalle}
                onChange={(evnt) => handleChangeDetalle(evnt)}
                validate={automatizacionState.bValidDetalle}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Modalidad (*):'} />
              <SelectForm
                validate={automatizacionState.bValidModalidad}
                value={automatizacionState.selectModalidad}
                handleChange={(e, v) => handleChangeModalidad(v)}
                options={listaModalidadAutomatizacion}
                placeholder={'Seleccione Modalidad'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Modalidad Datos (*):'} />
              <SelectForm
                validate={automatizacionState.bValidModalidadDatos}
                value={automatizacionState.selectModalidadDatos}
                handleChange={(e, v) => handleChangeModalidadDatos(v)}
                options={automatizacionState.listaModalidadDatos}
                isDisabled={automatizacionState.selectModalidad.length > 0 ? false : true}
                isMultiple={
                  automatizacionState.selectModalidad.length > 0
                    ? automatizacionState.selectModalidad[0].value != 2
                      ? false
                      : true
                    : false
                }
                placeholder={'Seleccione Datos'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Frecuencia (*):'} />
              <SelectForm
                validate={automatizacionState.bValidFrecuencia}
                value={automatizacionState.selectFrecuencia}
                handleChange={(e, v) => handleChangeFrecuencia(v)}
                options={listaFrecuenciaAutomatizacion}
                placeholder={'Seleccione Frecuencia'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Numero Frecuencia (*):'} />
              <Input
                placeholder="Ingrese..."
                value={automatizacionState.sFrecuenciaNumero}
                onChange={(evnt) => handleChangeFrecuenciaNumero(evnt)}
                validate={automatizacionState.bValidFrecuenciaNumero}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Fecha inicio (*):'}></LabelForm>
              <RowDoubleInput>
                <DatePickerForm
                  width={'100%'}
                  dateFormat="dd/MM/yyyy"
                  selectsRange={false}
                  value={automatizacionState.dFechaInicio}
                  onChange={(evnt) => handleChangeFechaInicio(evnt)}
                  isClearable={true}
                  placeholderText="Ingrese"
                  locale="es"
                  showMonthDropdown
                  showYearDropdown
                  portalId="root-portal"
                  wrapperClassName={'date-wrapper-60'}
                  validate={automatizacionState.bValidFechaInicio}
                />
                <TimePickerForm
                  format={'HH:mm'}
                  value={automatizacionState.dDateTimeInicio}
                  onChange={(event) => handleChangeTimeInicio(event)}
                  width={'37%'}
                />
              </RowDoubleInput>
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Fecha fin (*):'}></LabelForm>
              <RowDoubleInput>
                <DatePickerForm
                  width={'100%'}
                  dateFormat="dd/MM/yyyy"
                  selectsRange={false}
                  value={automatizacionState.dFechaFin}
                  onChange={(evnt) => handleChangeFechaFin(evnt)}
                  isClearable={true}
                  placeholderText="Ingrese"
                  locale="es"
                  showMonthDropdown
                  showYearDropdown
                  portalId="root-portal"
                  wrapperClassName={'date-wrapper-60'}
                  validate={automatizacionState.bValidFechaFin}
                  disabled={automatizacionState.dFechaInicio.length > 0 ? false : true}
                  minDate={new Date(formatDateMMDDYYYY(automatizacionState.dFechaInicio))}
                />
                <TimePickerForm
                  format={'HH:mm'}
                  value={automatizacionState.dDateTimeFin}
                  onChange={(event) => handleChangeTimeFin(event)}
                  width={'37%'}
                />
              </RowDoubleInput>
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Dependencias'} />
              <SelectForm
                validate={automatizacionState.bValidDependencias}
                value={automatizacionState.selectDependencias}
                handleChange={(e, v) => handleChangeDependencia(v)}
                options={automatizacionState.listaAutomatizaciones}
                placeholder={'Seleccione Dependencias'}
              />
            </RowForm>
          </ColForm>
        </RowForm>
        <RowFooterButton>
          <ButtonForm
            action={(e) => onPressCancelar()}
            name="Cancelar"
            isAction={true}
            typeColor="warning"
          />
          <ButtonForm
            action={(e) => onLimpiarCampos(e)}
            name="Limpiar"
            isAction={true}
            typeColor="primary"
          />
          <ButtonForm
            action={(e) => onPressGrabar(e)}
            name="Grabar"
            isAction={true}
            typeColor="secondary"
          />
        </RowFooterButton>
      </ContentForm>
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación de la automatización?"
        action={(e) => onCloseAutomatizacion(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </>
  );
};
