import React from 'react';
import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';
import { theme } from '../../common';
export const InputNumberFormat = (props) => {
  return (
    <InputNumber
      disabled={props.disabled}
      width={props.width}
      placeholder="Ingrese..."
      value={props.value}
      allowNegative={props.allowNegative}
      thousandSeparator={','}
      decimalSeparator={'.'}
      valueIsNumericString={true}
      fixedDecimalScale={props.fixedDecimalScale}
      decimalScale={props.decimalScale}
      inputmode="numeric"
      validate={props.validate}
      isAllowed={(values) => {
        const { max, min } = props;
        const { formattedValue, floatValue } = values;
        if (max) {
          if (floatValue === null) {
            return formattedValue === '';
          } else {
            return max >= floatValue;
          }
        } else if (min) {
          if (floatValue === null) {
            return formattedValue === '';
          } else {
            return min <= floatValue;
          }
        } else {
          if (floatValue === null) {
            return formattedValue === '';
          } else {
            return formattedValue;
          }
        }
      }}
      onValueChange={props.onValueChange}
    />
  );
};
const InputNumber = styled(NumericFormat)`
  border-radius: 2px;
  border: 2px solid ${theme.colors.line};
  transition: width 0.5s;
  height: 32px;
  font-family: ${theme.font.family};
  font-size: 12px;
  margin-left: 0px;
  padding: ${({ active }) => (true ? '0 10px' : '0px')};
  opacity: ${({ active }) => (true ? '1' : '0')};
  /* width: ${({ width }) => (width ? width : '320px')}; */
  width: ${({ width }) => (width ? width : '98%')};
  background-color: ${theme.colors.white};
  &:focus {
    outline: none !important;
    border-color: ${({ validate }) =>
      !validate ? theme.colors.line : validate === true ? theme.colors.line : 'red'};
  }
  border-color: ${({ validate }) =>
    !validate ? theme.colors.line : validate === true ? theme.colors.line : 'red'};
  transition: ${({ validate }) =>
    !validate ? 'none' : validate === true ? 'none' : 'border-color 0.5s ease-out'};
`;
