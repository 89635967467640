import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const campoInitialState = {
  searchtext: '',
  searchtextHomologacion: '',
  idCampo: null,
  nombre: '',
  bValidNombre: null,
  modalOpenEliminarServicioCampo: false,
  selectRowServicio: [],
  listaSistemas: [],
  listaHomologaciones: [],
  filterListaHomologaciones: [],
  filteredListaSistemas: [],
  filteredListaServicios: [],
  listaTipoCampos: [],
  cabeceraListaCampos: [],
  listaCamposXSistema: [],
  listaCampos: [],
  filterListaCampos: [],
  valueFileUploader: '',
  modalOpenUploadDataExcel: false,
  uploadDataExcel: [],
  idCampoFrm: null,
  nombreCampo: '',
  bValidNombreCampo: null,
  bValidTipoDato: null,
  nombreTipoCampo: {},
  selectTipoDato: [],
  listaTipoDato: [],
  listaTipoHomologados: [],

  idCampoHomo: null,
  campoHomo: '',
  idDatoHomo: 0,
  valorOriginalHomo: '',
  bValidValorOriginalHomo: null,
  sistemaHomo: [],
  bValidSistemaHomo: null,
  valorCambioHomo: '',
  bValidValorCambioHomo: null,
  listaSistemasHomo: [],
  listaSisHomo: [],
  listaCampoHomo: [],
};
export const campoSlice = createSlice({
  name: 'campo',
  initialState: campoInitialState,
  reducers: {
    agregarCampo: (state, action) => {
      return { ...state, ...action.payload };
    },
    editarCampo: (state, action) => {
      return { ...state, ...action.payload };
    },
    eliminarCampos: (state, action) => {
      return { ...state, ...action.payload };
    },

    setCleanModalCampos: (state, action) => {
      var obj = {
        idCampoFrm: null,
        nombreCampo: '',
        bValidNombreCampo: null,
        bValidTipoDato: null,
        nombreTipoCampo: {},
        selectTipoDato: [],
        listaTipoDato: [],
        listaTipoHomologados: [],
      };
      return { ...state, ...obj };
    },
    setDetalleCampo: (state, action) => {
      return { ...state, ...action.payload };
    },
    setTipoDato: (state, action) => {
      var obj = {
        listaTipoDato: action.payload,
      };
      return { ...state, ...obj };
    },
    setSelectTipoDato: (state, action) => {
      var obj = {
        selectTipoDato: action.payload.selectTipoDato,
        bValidTipoDato: action.payload.bValidTipoDato,
      };

      return { ...state, ...obj };
    },
    setNombreCampo: (state, action) => {
      var obj = {
        nombreCampo: action.payload.nombreCampo,
        bValidNombreCampo: action.payload.bValidNombreCampo,
      };

      return { ...state, ...obj };
    },
    onAddRowtableTipoHomologado: (state, action) => {
      return {
        ...state,
        listaTipoHomologados: state.listaTipoHomologados.concat(action.payload.newItem),
      };
    },
    onDeleteRowtableTipoHomologado: (state, action) => {
      // state.listTypeSystemService.splice(action.payload.index, 1);
      let listaTipoHomologados = [];
      state.listaTipoHomologados.map(function (item, index) {
        if (item.idDetalle != action.payload.index) {
          listaTipoHomologados.push(item);
        }
      });
      return {
        ...state,
        listaTipoHomologados: listaTipoHomologados,
      };
    },
    changeRowsChangeValor: (state, action) => {
      state.listaTipoHomologados.map((item, index) => {
        if (item.idDetalle === action.payload.index) {
          item.valor = action.payload.valor;
          item.bValidValor = action.payload.bValidValor;
          if (item.idTipoHomologado) {
            item.action = 'M';
          }
        }
        return { ...item };
      });
    },
    changeRowsSelectTipoSistema: (state, action) => {
      state.listaTipoHomologados.map((item, index) => {
        if (item.idDetalle === action.payload.index) {
          item.selectTipoSistema = action.payload.selectTipoSistema;
          if (item.idTipoHomologado) {
            item.action = 'M';
          }
        }
        return { ...item };
      });
    },
    setListarCampos: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaCampos: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setCabeceraListaCampos: (state, action) => {
      return { ...state, ...action.payload };
    },
    setTipoSistemaDisponiblesCampos: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSistemaXCampoDisponiblesCampos: (state, action) => {
      return { ...state, ...action.payload };
    },
    setFilteredListaSistemas: (state, action) => {
      return { ...state, ...action.payload };
    },
    setClearSeachCampo: (state, action) => {
      return { ...state, ...action.payload };
    },
    setEliminarRowCampoHomologacion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setFilterListarCampos: (state, action) => {
      var obj = {
        filterListaCampos: action.payload.filterListaCampos,
      };
      return { ...state, ...obj };
    },
    filterListarCampos: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaCampos = estadoInspeccionado.listaCampos.filter(function (item, index) {
        return (
          item.CodigoCampo.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.Nombre.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.SistemaAsociado.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.ServicioAsociado.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaCampos: filterListaCampos,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    changeSearchTextHomologacionCampo: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaCampos = estadoInspeccionado.listaCampos.filter(function (item, index) {
        return (
          item.nombreCampo.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.tipoDato.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaCampos: filterListaCampos,
        searchtextHomologacion: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    filterListarHomologacionesCampos: (state, action) => {
      //       let estadoInspeccionado = current(state);
      const listaHomologaciones = [...state.listaHomologaciones];
      var filterListaHomologacion = listaHomologaciones.filter(function (item, index) {
        let lista = item['lista'];
        return lista[0].value.toLowerCase().includes(state.searchtextHomolgacion.toLowerCase());
        // || lista[0].value.length === ""
      });
      var obj = {
        filterListaHomologacion: filterListaHomologacion,
        // searchtextHomolgacion: action.payload,
      };
      return { ...state, ...obj };
    },
    changeConexionServiciosCampo: (state, action) => {
      state.listaServicios.map((item, index) => {
        if (index === action.payload.index) {
          item.conexion = action.payload.conexion;
        }
        return { ...item };
      });
    },
    changeSistemaServiciosCampo: (state, action) => {
      state.listaServicios.map((item, index) => {
        if (index === action.payload.index) {
          item.selectSistema = action.payload.selectSistema;
          item.bValidSistema = action.payload.bValidSistema;
          item.selectServicio =
            action.payload.selectSistema.length === 0 ? [] : action.payload.selectServicio;
        }
        if (index !== action.payload.index) {
          if (action.payload.selectSistema.length === 0) {
            if (action.payload.selectServicio.length > 0) {
              if (item.selectSistema.length > 0) {
                if (
                  item.selectSistema[0].IDSistema === action.payload.selectServicio[0].IDSistema
                ) {
                  item.listaServicio = [...item.listaServicio, ...action.payload.selectServicio];
                }
              }
            }
          }
        }
        return { ...item };
      });
    },
    changeServicioServiciosCampo: (state, action) => {
      state.listaServicios.map((item, index) => {
        if (index === action.payload.index) {
          item.selectServicio = action.payload.selectServicio;
          item.bValidServicio = action.payload.bValidServicio;
        }
        return { ...item };
      });
    },
    updateListaServicioCampo: (state, action) => {
      state.listaServicios.map((item, index) => {
        if (item.selectSistema.length > 0) {
          let aListaServicios = action.payload.filteredListaServicios.filter(function (
            item2,
            index
          ) {
            return item2.IDSistema === item.selectSistema[0].IDSistema;
          });
          if (item.selectServicio.length > 0) {
            aListaServicios = [...aListaServicios, ...item.selectServicio];
          }
          item.listaServicio = aListaServicios;
        }
        return { ...item };
      });
    },
    addListaServicioCampo: (state, action) => {
      state.listaServicios.map((item, index) => {
        if (item.selectSistema.length > 0) {
          if (action.payload.selectServicio.length > 0) {
            // if (item.selectSistema[0].length > 0) {
            if (item.selectSistema[0].IDSistema === action.payload.selectServicio[0].IDSistema) {
              item.listaServicio = [...item.listaServicio, ...action.payload.selectServicio];
            }
            // }
          }
        }
        return { ...item };
      });
    },
    setModalOpenEliminarServicioCampo: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSelectRowServicio: (state, action) => {
      return { ...state, ...action.payload };
    },
    onDeleteRowTableHomologacionCampo: (state, action) => {
      // state.listaHomologaciones.splice(action.payload.index, 1);
      let listaHomologaciones = [];
      state.listaHomologaciones.map((item, index) => {
        if (item.uuid !== action.payload.uuid) {
          listaHomologaciones.push({ ...item });
        }
      });
      var obj = {
        listaHomologaciones: listaHomologaciones,
        searchtextHomolgacion: action.payload.searchtextHomolgacion,
      };
      return { ...state, ...obj };
    },
    changeNombreCampo: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeAliasCampo: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeTipoCampoCampo: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaTipoCampos: (state, action) => {
      return { ...state, ...action.payload };
    },
    loadEditarCampo: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setUploadDataExcelCampo: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    onOpenModalUploadDataExcelCampo: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    limpiarCamposCampo: (state, action) => {
      let oLimpiar = {
        // idCampo: null,
        listaServicios: [],
        listaHomologaciones: [],
      };
      return { ...state, ...oLimpiar };
    },
    limpiarCamposFormCampo: (state, action) => {
      var listaF = [];
      const listaHomologaciones = [...state.listaHomologaciones];
      let aFiltrado = listaHomologaciones.filter(function (item, index) {
        return item.Obligatorio === 1;
      });
      for (let index = 0; index < aFiltrado.length; index++) {
        const item = aFiltrado[index];
        var obj = { ...item };
        var lista = [];
        for (let index2 = 0; index2 < item.lista.length; index2++) {
          const element2 = item.lista[index2];
          let obj2 = { ...element2 };
          if (index2 > 0) {
            obj2.value = '';
            obj2.valid = null;
            lista.push(obj2);
          } else {
            lista.push(obj2);
          }
        }
        obj.Obligatorio = 1;
        obj.lista = lista;
        // return item;
        listaF.push(obj);
      }
      // state.listaHomologaciones.map(function(item,index){
      //
      //   if(item.Obligatorio==1){
      //     listaHomologaciones.push(item);
      //   }
      // })
      let oLimpiar = {
        listaHomologaciones: listaF,
      };
      return { ...state, ...oLimpiar };
    },
    onAddServicioCampo: (state, action) => {
      return {
        ...state,
        listaServicios: state.listaServicios.concat(action.payload.newItem),
      };
    },
    onAddHomologacionCampo: (state, action) => {
      return {
        ...state,
        listaHomologaciones: state.listaHomologaciones.concat(action.payload.newItem),
      };
    },
    onSetListaHomologacionesCampo: (state, action) => {
      return {
        ...state,
        listaHomologaciones: action.payload.listaHomologaciones,
      };
    },
    changeNombreHomologacionCampo: (state, action) => {
      state.listaHomologaciones.map((item, indexHomo) => {
        if (indexHomo === action.payload.indexHomo) {
          item.lista.forEach((element, indexLis) => {
            if (indexLis === action.payload.indexLis) {
              element.value = action.payload.value;
              if (action.payload.value.length === '' && element.IDTipoSistema != null) {
                element.valid = false;
              } else {
                element.valid = true;
              }
            }
          });
        }
        return { ...item };
      });
    },
    setSheetHomoCampo: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCleanSheetHomoCampo: (state, action) => {
      let oParam = {
        idDatoHomo: 0,
        valorOriginalHomo: '',
        bValidValorOriginalHomo: null,
        sistemaHomo: [],
        bValidSistemaHomo: null,
        valorCambioHomo: '',
        bValidValorCambioHomo: null,
        listaSistemasHomo: [],
      };

      return { ...state, ...oParam };
    },
  },
});
export const {
  agregarCampo,
  editarCampo,
  eliminarCampos,
  setListarCampos,
  setCabeceraListaCampos,
  changeSearchTextHomologacionCampo,
  filterListarHomologacionesCampos,
  setFilterListarCampos,
  filterListarCampos,
  changeAliasCampo,
  changeTipoCampoCampo,
  setListaTipoCampos,
  loadEditarCampo,
  setUploadDataExcelCampo,
  onOpenModalUploadDataExcelCampo,
  limpiarCamposCampo,
  limpiarCamposFormCampo,
  limpiarCamposPostSelcTipoCampo,
  setTipoSistemaDisponiblesCampos,
  setSistemaXCampoDisponiblesCampos,
  setFilteredListaSistemas,
  onAddServicioCampo,
  changeConexionServiciosCampo,
  changeSistemaServiciosCampo,
  changeServicioServiciosCampo,
  updateListaServicioCampo,
  setModalOpenEliminarServicioCampo,
  setSelectRowServicio,
  onDeleteRowTableHomologacionCampo,
  addListaServicioCampo,
  changeNombreCampo,
  setClearSeachCampo,
  onAddHomologacionCampo,
  onSetListaHomologacionesCampo,
  changeNombreHomologacionCampo,
  setTipoDato,
  setNombreCampo,
  setSelectTipoDato,
  onAddRowtableTipoHomologado,
  onDeleteRowtableTipoHomologado,
  changeRowsChangeValor,
  changeRowsSelectTipoSistema,
  setDetalleCampo,
  setCleanModalCampos,
  setSheetHomoCampo,
  setCleanSheetHomoCampo,
} = campoSlice.actions;
export default campoSlice.reducer;
