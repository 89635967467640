import React, { useEffect, useState } from 'react';
import {
  Input,
  LabelForm,
  TabBarCreacion,
  SubTitle,
  LoadingSpinner,
  ModalConfirmation,
  Button,
  TableGrid,
  SelectForm,
  ButtonForm,
} from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListarConceptosPosicion,
  changeNamePosicion,
  changeConceptoPosicion,
  setListarPosiciones,
  setListarSecundariaPosicion,
  setListarPrincipalPosicion,
  changeNombrePosicion,
  limpiarCamposPosicion,
  updateTitle,
  setCabeceraEditar,
  setListarCabeceraAgrupado,
  setListaFuncionPosicion,
  setListarCaracteristicas,
  changePosicionMaster,
} from '../../../../redux/states';
import { useFetchAndLoad } from '../../../../common/hooks';
import {
  listaDatosKeyConceptoPosicion,
  listadoCamposFuncionHomologacion,
  listadoCamposKeyXConceptoXFuncion,
  listarConceptos,
  listarPosiciones,
  modificarPosicion,
  obtenerCabDetalleConcepto,
  obtenerDerivadas,
  obtenerMaster,
  obtenerPosicion,
} from '../../../../services';
import {
  createConceptoPosicionAdapter,
  createPosicionAdapter,
  createPosicionMasterAdapter,
} from '../../../../adapters';
import Swal from 'sweetalert2';
import { createCampoClavePrincipalAdapter } from '../../../../adapters/campoClavePrincipal.adapter';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import {
  ColFlex,
  ColForm,
  Column,
  Content,
  ContentForm,
  RowButton,
  RowFooterButton,
  RowForm,
} from '../../../../styles';
import { v4 as uuidv4 } from 'uuid';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { paths } from '../../../../common';
export const EditarPosicion = (props) => {
  const [rowsData, setRowsData] = useState([]);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const posicionState = useSelector((store) => store.posicion);
  const [rowsKeyData, setRowsKeyData] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [tabProceso, setTabProceso] = useState(0);
  const [loadingDetalle, setLoadingDetalle] = useState(true);
  const [showModalCancelar, setShowModalCancelar] = useState(false);

  useEffect(() => {
    dispatch(updateTitle({ title: 'Editar Posición' }));

    onClickEditarConcepto();
  }, []);

  const columns = [
    {
      field: 'sFuncion',
      headerName: 'Funcion',
      flex: 1,
      renderCell: (params) => (
        <>
          <SelectForm
            options={params.row.aListaFuncion}
            handleChange={(evnt, v) => handleChangeFuncion(params.id, v)}
            value={
              params.row.iIdFuncion === null
                ? []
                : [{ value: params.row.iIdFuncion, label: params.row.sFuncion }]
            }
            placeholder="Seleccione"
          />
        </>
      ),
    },
    {
      field: 'sNombreBase',
      headerName: 'Campo',
      flex: 1,
      align: 'left',
      renderCell: (params) => (
        <>
          <SelectForm
            value={
              params.row.iIdCampo === null
                ? []
                : [{ value: params.row.iIdCampo, label: params.row.sCampo }]
            }
            handleChange={(evnt, v) => handleChangeCampo(params.id, v)}
            options={params.row.aListaCampos ? params.row.aListaCampos : []}
            placeholder="Seleccione"
          />
        </>
      ),
    },
    {
      field: 'Campo',
      headerName: 'Key',
      flex: 1,
      align: 'left',
      renderCell: (params) => (
        <>
          <SelectForm
            width={'385px'}
            placeholder="Seleccione"
            options={params.row.aListakey ? params.row.aListakey : []}
            handleChange={(evnt, v) => handleChangeMultiKey(params.id, v)}
            value={params.row.iIdCampo === null ? [] : params.row.aSeleccionKey}
            isMultiple={true}
          />
        </>
      ),
    },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <RowButton>
          <ButtonForm
            action={() => deleteTableRows(params.row, params.id)}
            icon={<FaMinus />}
            isAction={false}
            isOnlyIcon={true}
            typeColor="primary"
          />
        </RowButton>
      ),
      renderHeader: (params) => (
        <RowButton>
          <ButtonForm
            action={addTableRows}
            icon={<FaPlus />}
            isAction={false}
            isOnlyIcon={true}
            noBackground={true}
            typeColor="secondary"
          />
        </RowButton>
      ),
    },
  ];

  const addTableRows = () => {
    let uid = uuidv4();
    let aListaFuncion = posicionState.aListadoFuncionesComp;
    const rowsInput = {
      IdRowsInput: uid,
      sNombre: '',
      iIdFuncion: null,
      sFuncion: '',
      iIdCampo: null,
      sCampo: '',
      iIdKey: null,
      sKey: '',
      bEnabledCampo: false,
      bEnabledKey: false,
      aListaFuncion: aListaFuncion,
      aListaCampos: [],
      aListakey: [],
      aSeleccionKey: [],
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };
  const handleChangeFuncion = async (id, value) => {
    const index = rowsData.findIndex((val) => val.IdRowsInput === id);

    let rowsDataPosicion = [];
    rowsData.forEach((element) => {
      var obj = { ...element };
      rowsDataPosicion.push(obj);
    });
    const rowsInput = [...rowsDataPosicion];
    rowsInput[index]['iIdCampo'] = null;
    rowsInput[index]['sCampo'] = '';
    rowsInput[index]['aListaCampos'] = [];
    rowsInput[index]['aListaKey'] = [];
    if (value != null) {
      var result = await callEndpoint(listadoCamposFuncionHomologacion(value.value));
      var funcionOptions = [];
      result.data.respuesta.aListadoCamposFuncion.forEach(function (item, index) {
        var o = {};
        o.value = item.iIDConceptoAG;
        o.label = item.sNombreCampo;
        o.isDisabled = false;
        o.iIDConceptoAG = item.iIDConceptoAG;
        o.iIDFConexion = item.iIDFConexion;
        o.iIDCabecera = item.iIDCabecera;
        o.sNombreCampo = item.sNombreCampo;
        o.iHabilitado = item.iHabilitado;
        funcionOptions.push(o);
      });
      rowsInput[index]['iIdFuncion'] = value.value;
      rowsInput[index]['sFuncion'] = value.label;
      rowsInput[index]['aListaCampos'] = funcionOptions; //result;
    } else {
      rowsInput[index]['iIdFuncion'] = null;
      rowsInput[index]['sFuncion'] = '';
      rowsInput[index]['aListaKey'] = [];
      rowsInput[index]['aSeleccionKey'] = [];
    }
    setRowsData(rowsInput);
  };

  const handleChangeCampo = async (id, value) => {
    const index = rowsData.findIndex((val) => val.IdRowsInput === id);
    const rowsInput = [...rowsData];
    rowsInput[index]['iIdKey'] = null;
    rowsInput[index]['sKey'] = '';
    if (value != null) {
      var oParam = {
        oDatosCaracteristicas: {
          iIdCabecera: posicionState.selectConcepto[0].value, //oPosicionAgregar.iIdConcepto,
          iIdFuncion: rowsInput[index].iIdFuncion,
          sNombreCampo: value.sNombreCampo,
        },
      };
      var result = await callEndpoint(listadoCamposKeyXConceptoXFuncion(oParam));
      var camposKeyXConceptoXFuncionOptions = [];
      result.data.respuesta.aListaCampos.forEach(function (item, index) {
        var o = {};
        o.value = item.sNombreCampoFinal;
        o.label = item.sNombreCampoFinal;
        o.isDisabled = false;
        o.iIDHomologacion = item.sNombreCampo;
        o.iIDFConexion = item.iIdCabecera;
        o.iIDAgrupador = item.iIdFuncion;
        o.sNombreCampoFinal = item.sNombreCampoFinal;
        camposKeyXConceptoXFuncionOptions.push(o);
      });
      rowsInput[index]['iIdCampo'] = value.value;
      rowsInput[index]['sCampo'] = value.label;
      rowsInput[index]['aListaKeysXConceptoXfuncion'] = camposKeyXConceptoXFuncionOptions;
      rowsInput[index]['aListakey'] = camposKeyXConceptoXFuncionOptions;
    } else {
      rowsInput[index]['iIdCampo'] = null;
      rowsInput[index]['sCampo'] = '';
      rowsInput[index]['aListaKey'] = [];
      rowsInput[index]['aSeleccionKey'] = [];
    }
    setRowsData(rowsInput);
  };

  const handleChangeMultiKey = (id, value) => {
    let findMultiKey = [];
    const index = rowsData.findIndex((val) => val.IdRowsInput === id);

    let rowsDataPosicion = [];
    rowsData.forEach((element) => {
      var obj = { ...element };
      rowsDataPosicion.push(obj);
    });
    const rowsInput = [...rowsDataPosicion];

    rowsInput[index]['aSeleccionKey'] = value;
    setRowsData(rowsInput);
  };

  const onClickEditarConcepto = async (row) => {
    dispatch(updateTitle({ title: 'Editar Posición' }));
    // const { data } = await callEndpoint(
    const responseObtenerPosicion = await callEndpoint(
      obtenerPosicion({
        oIDPosicion: {
          IDPos: id,
        },
      })
    );
    if (responseObtenerPosicion === null) {
      // Swal.fire({
      //   position: "center",
      //   icon: "error",
      //   title: "Ocurrió un error en el servicio.",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
    } else {
      if (responseObtenerPosicion.data) {
        if (responseObtenerPosicion.data.cabecera.statusCode === 200) {
          let respuesta = responseObtenerPosicion.data.respuesta;
          let objPos = {
            sPosicion: respuesta.aListaCabeceraAgrupado[0].NombrePosicion,
            bValidPosicion: true,
          };
          let selectConcepto = [];
          selectConcepto.push({
            value: respuesta.aListaCabeceraAgrupado[0].IDConcepto,
            label: respuesta.aListaCabeceraAgrupado[0].NombreConcepto,
          });
          objPos.selectConcepto = selectConcepto;
          objPos.bValidConcepto = true;
          dispatch(setCabeceraEditar(objPos));
          dispatch(
            setListarCabeceraAgrupado({
              aListaCabeceraAgrupado: respuesta.aListaCabeceraAgrupado[0],
            })
          );
          dispatch(
            setListaFuncionPosicion({ aListadoFuncionesComp: respuesta.aListadoFuncionesComp })
          );
          dispatch(
            setListarCaracteristicas({ rowsInputPosicionCarac: respuesta.rowsInputPosicionCarac })
          );
          let listaPrincipalPosicion = [];
          let listaSecundariaPosicion = [];
          dispatch(
            setListarPrincipalPosicion({
              selectPrincipalPosicion: respuesta.aListaCabeceraAgrupado[0].oLlave,
              bValidPrincipalPosicion: true,
              isDisabledPrincipalPosicion: false,
              listaPrincipalPosicion: respuesta.aListaPrincipal,
            })
          );
          dispatch(
            setListarSecundariaPosicion({
              selectSecundariaPosicion: respuesta.aListaCabeceraAgrupado[0].oDesdoble,
              bValidSecundariaPosicion: true,
              isDisabledSecundariaPosicion: false,
              listaSecundariaPosicion: respuesta.aListaSecundaria,
            })
          );
          const responseListarConceptos = await callEndpoint(listarConceptos());
          var listaConceptos = [];
          for (
            let index = 0;
            index < responseListarConceptos.data.respuesta.aListaConceptos.length;
            index++
          ) {
            const element = responseListarConceptos.data.respuesta.aListaConceptos[index];
            listaConceptos.push(createConceptoPosicionAdapter(element));
          }

          dispatch(setListarConceptosPosicion(listaConceptos));

          let oParamPM = {};
          const responseListaPosicionesM = await callEndpoint(obtenerMaster());
          var listaPosicionesM = [];
          for (
            let index = 0;
            index < responseListaPosicionesM.data.respuesta.aListaMaster.length;
            index++
          ) {
            const element = responseListaPosicionesM.data.respuesta.aListaMaster[index];
            var obj = createPosicionMasterAdapter(element);
            listaPosicionesM.push(obj);
          }

          oParamPM.listaPosicionMaster = listaPosicionesM;
          oParamPM.bValidPosicionMaster = null;
          oParamPM.selectPosicionMaster = [];
          if (respuesta.aListaCabeceraAgrupado[0].NombreMaster) {
            oParamPM.bValidPosicionMaster = true;
            oParamPM.selectPosicionMaster = listaPosicionesM.filter(
              (r) => r.label === respuesta.aListaCabeceraAgrupado[0].NombreMaster
            );
          }

          const responseListarDerivacion = await callEndpoint(obtenerDerivadas());
          var listaDerivaciones = [];
          for (let i = 0; i < responseListarDerivacion.data.respuesta.aListaVersiones.length; i++) {
            const ele = responseListarDerivacion.data.respuesta.aListaVersiones[i];
            ele.value = ele.IDDerivada;
            ele.label = ele.NombreDerivada;
            listaDerivaciones.push(ele);
          }
          dispatch(changePosicionMaster({ listaDerivada: listaDerivaciones }));

          oParamPM.bValidDerivada = null;
          oParamPM.selectDerivada = [];

          if (respuesta.aListaCabeceraAgrupado[0].IDDerivadas) {
            oParamPM.bValidDerivada = true;
            oParamPM.selectDerivada = listaDerivaciones.filter((r) =>
              respuesta.aListaCabeceraAgrupado[0].IDDerivadas.includes(r.value)
            );
          }

          dispatch(changePosicionMaster(oParamPM));

          // setRowsData(posicionState.rowsInputPosicionCarac);
          // setRowsKeyData(posicionState.aListaCabeceraAgrupado.Items);
          setRowsData(respuesta.rowsInputPosicionCarac);
          setRowsKeyData(respuesta.aListaCabeceraAgrupado[0].Items);
          //   setButtonAction(2);
          //   setTabProceso(0);
        } else {
          // Swal.fire({
          //   position: "center",
          //   icon: "warning",
          //   title: responseObtenerPosicion.data.cabecera.descripcion,
          //   showConfirmButton: false,
          //   timer: 2000,
          // });
        }
      } else {
        // Swal.fire({
        //   position: "center",
        //   icon: "error",
        //   title: "Ocurrió un error en el servicio.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      }
    }
    setLoadingDetalle(false);
  };

  const onTabProcesoGeneral = () => {
    setTabProceso(0);
  };
  const obTabCaracteristicas = () => {
    onPressSiguiente();
  };
  const onPressSiguiente = () => {
    if (posicionState.sPosicion === '') {
      dispatch(changeNombrePosicion({ bValidPosicion: false }));
    }
    if (posicionState.selectConcepto.length === 0) {
      dispatch(changeConceptoPosicion({ bValidConcepto: false }));
    }
    // if (posicionState.selectPosicionMaster.length === 0) {
    //   dispatch(changePosicionMaster({ bValidPosicionMaster: false }));
    // }
    var rows = [];
    rowsKeyData.forEach(function (item, index) {
      var oItem = { ...item };
      if (oItem.valor === '') {
        oItem.validate = false;
      }
      rows.push(oItem);
    });
    var aEncontradosError = rows.filter(function (item) {
      return item.valor === '' || item.validate === false;
    });
    setRowsKeyData(rows);
    if (posicionState.selectPrincipalPosicion.length === 0) {
      dispatch(setListarPrincipalPosicion({ bValidPrincipalPosicion: false }));
    }
    if (posicionState.selectSecundariaPosicion.length === 0) {
      dispatch(setListarSecundariaPosicion({ bValidSecundariaPosicion: false }));
    }
    if (
      posicionState.sPosicion !== '' &&
      aEncontradosError.length === 0 &&
      posicionState.selectConcepto.length > 0 &&
      posicionState.selectPrincipalPosicion.length > 0 &&
      posicionState.selectSecundariaPosicion.length > 0
    ) {
      setTabProceso(1);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Por favor, completar todos los campos del formulario.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
    // setTabProceso(1);
    return;
  };
  const limpiarCampos = (oEvent) => {
    setRowsKeyData([]);
    setRowsData([]);
    dispatch(limpiarCamposPosicion());
  };
  const loadPosicionesMaster = async () => {
    let oParam = {};
    const responseListaPosicionesM = await callEndpoint(obtenerMaster());
    var listaPosiciones = [];
    for (
      let index = 0;
      index < responseListaPosicionesM.data.respuesta.aListaMaster.length;
      index++
    ) {
      const element = responseListaPosicionesM.data.respuesta.aListaMaster[index];
      var obj = createPosicionMasterAdapter(element);
      listaPosiciones.push(obj);
    }
    oParam.listaPosicionMaster = listaPosiciones;
    dispatch(changePosicionMaster(oParam));
  };

  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };
  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };
  const onConfirmarConcepto = async () => {
    limpiarCampos();
    setShowModalCancelar(false);
    navigate(paths.CONFIGURE.POSITION.BASE);
  };
  const onPressGrabar = async (props) => {
    let valid = true;
    for (let index = 0; index < rowsData.length; index++) {
      const element = rowsData[index];
      if (element.aSeleccionKey.length === 0) {
        valid = false;
        break;
      }
    }
    if (!valid) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Debe llenar todos los campos de la fila.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    let listaIdDerivada = [];
    posicionState.selectDerivada.map((r) => listaIdDerivada.push(r.value));
    let oRegistrarGeneral = {
      iIdConcepto: posicionState.selectConcepto[0].value,
      iIdPosic: posicionState.aListaCabeceraAgrupado.IDPosicion,
      IDMaster:
        posicionState.selectPosicionMaster.length > 0
          ? posicionState.selectPosicionMaster[0].value
          : 0,
      IDDerivadas: listaIdDerivada,
      sConcepto: posicionState.selectConcepto[0].label,
      sPosicion: posicionState.sPosicion,
      oLlave: {
        IDCaracteristica: posicionState.selectPrincipalPosicion[0].IDCaracteristica,
        Nombre: posicionState.selectPrincipalPosicion[0].Nombre,
      },
      oDesdoble: {
        IDCaracteristica: posicionState.selectSecundariaPosicion[0].IDCaracteristica,
        Nombre: posicionState.selectSecundariaPosicion[0].Nombre,
      },
    };
    let aDatosKey = [];
    rowsKeyData.forEach(function (item, index) {
      var o = {};
      o.sValor = item.valor;
      o.sNombreCampo = item.label;
      aDatosKey.push(o);
    });
    let aRegistrarCaracteristica = [];
    rowsData.forEach(function (item, index) {
      var oRegistrarCaracteristica = {};
      oRegistrarCaracteristica.sNombreCaracteristica = item.sNombre;
      oRegistrarCaracteristica.iIdFuncion = item.iIdFuncion;
      oRegistrarCaracteristica.sFuncion = item.sFuncion;
      oRegistrarCaracteristica.iIdCampo = item.iIdCampo;
      oRegistrarCaracteristica.sCampo = item.sCampo;
      oRegistrarCaracteristica.aCaracteristicasKeys = [];
      item.aSeleccionKey.forEach(function (item2, index2) {
        var oSeleccionKey = {};
        oSeleccionKey.sKey = item2.value;
        oRegistrarCaracteristica.aCaracteristicasKeys.push(oSeleccionKey);
      });
      aRegistrarCaracteristica.push(oRegistrarCaracteristica);
    });
    var oParam = {
      oRegistrarGeneral: oRegistrarGeneral,
      aDatosKey: aDatosKey,
      aRegistrarCaracteristica: aRegistrarCaracteristica,
    };
    const responseEditarPosicion = await callEndpoint(modificarPosicion(oParam));
    if (responseEditarPosicion === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseEditarPosicion.data) {
        if (responseEditarPosicion.data.cabecera.statusCode === 200) {
          // setButtonAction(0);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseEditarPosicion.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          // dispatch(loadPosiciones());
          navigate(paths.CONFIGURE.POSITION.BASE);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseEditarPosicion.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };
  const handleChangePosicion = (oEvent) => {
    var NombrePosicion = oEvent.target.value;
    var oParam = {
      sPosicion: NombrePosicion,
    };
    dispatch(changeNamePosicion(oParam));
  };
  const handleChangeValor = (index, event) => {
    var sValor = event.target.value;
    var validate = null;
    if (sValor.length > 0) {
      validate = true;
    }
    let rows = [];
    rowsKeyData.forEach((element) => {
      var obj = { ...element };
      rows.push(obj);
    });
    rows[index]['valor'] = sValor;
    rows[index]['validate'] = validate;
    setRowsKeyData(rows);
  };
  const handleChangeConceptoPosicion = async (value) => {
    var aLista = [];
    var oParam = {};
    oParam.selectConcepto = [];
    oParam.bValidConcepto = null;
    if (value) {
      dispatch(
        setListarPrincipalPosicion({
          selectPrincipalPosicion: [],
          bValidPrincipalPosicion: null,
        })
      );
      dispatch(
        setListarSecundariaPosicion({
          selectSecundariaPosicion: [],
          bValidSecundariaPosicion: null,
        })
      );

      oParam.selectConcepto.push({
        iIdConcepto: value.value,
        sConcepto: value.label,
        value: value.value,
        label: value.label,
      });
      oParam.bValidConcepto = true;
      dispatch(changeConceptoPosicion(oParam));
      const responseListaDatosKeyConceptoPosicion = await callEndpoint(
        listaDatosKeyConceptoPosicion(oParam.selectConcepto[0].iIdConcepto)
      );
      responseListaDatosKeyConceptoPosicion.data.respuesta.aDetalleConceptoXagrupador.forEach(
        function (item, index) {
          var o = { ...item };
          o.label = item.sNombreCampo;
          o.valor = '';
          o.validate = null;
          aLista.push(o);
        }
      );
      setRowsKeyData(aLista);
      const responseObtenerCabDetalleConcepto = await callEndpoint(
        obtenerCabDetalleConcepto({
          iId: oParam.selectConcepto[0].iIdConcepto,
        })
      );
      const { aDetalle } = responseObtenerCabDetalleConcepto.data.respuesta;
      let listaPrincipalPosicion = [];
      aDetalle.forEach((element) => {
        listaPrincipalPosicion.push(createCampoClavePrincipalAdapter(element));
      });
      dispatch(
        setListarPrincipalPosicion({
          selectPrincipalPosicion: [],
          bValidPrincipalPosicion: true,
          isDisabledPrincipalPosicion: false,
          listaPrincipalPosicion: listaPrincipalPosicion,
        })
      );
    } else {
      dispatch(changeConceptoPosicion(oParam));
      setRowsKeyData([]);
      dispatch(
        setListarPrincipalPosicion({
          selectPrincipalPosicion: [],
          bValidPrincipalPosicion: null,
          isDisabledPrincipalPosicion: true,
          listaPrincipalPosicion: [],
        })
      );
      dispatch(
        setListarSecundariaPosicion({
          selectSecundariaPosicion: [],
          bValidSecundariaPosicion: null,
          isDisabledSecundariaPosicion: true,
          listaSecundariaPosicion: [],
        })
      );
    }
  };

  const onChangePosicionMasterPosicion = async (value) => {
    var oParam = {};
    oParam.selectPosicionMaster = [];
    oParam.bValidPosicionMaster = null;
    if (value) {
      oParam.selectPosicionMaster.push(value);
      oParam.bValidPosicionMaster = true;
      dispatch(changePosicionMaster(oParam));
    } else {
      dispatch(changePosicionMaster(oParam));
    }
  };

  const onChangeDerivadaPosicion = async (value) => {
    var oParam = {};
    oParam.selectDerivada = [];
    oParam.bValidDerivada = null;
    if (value) {
      oParam.selectDerivada = value;
      oParam.bValidDerivada = true;
    }
    dispatch(changePosicionMaster(oParam));
  };

  const handleChangePosicionPrincipal = async (value) => {
    var aLista = [];
    var oParam = {};
    oParam.selectPrincipalPosicion = [];
    oParam.bValidPrincipalPosicion = null;
    if (value) {
      oParam.bValidPrincipalPosicion = true;
      dispatch(
        setListarPrincipalPosicion({
          selectPrincipalPosicion: [value],
        })
      );
      // var listaSecundariaPosicion = posicionState.listaPrincipalPosicion.filter(function (item) {
      //   return item.value !== oEvent.value
      // });
      let selectSecundariaPosicion = [];
      if (posicionState.selectSecundariaPosicion.length > 0) {
        selectSecundariaPosicion = posicionState.selectSecundariaPosicion;
        if (posicionState.selectSecundariaPosicion[0].value === value.value) {
          selectSecundariaPosicion = [];
        }
      }
      dispatch(
        setListarSecundariaPosicion({
          selectSecundariaPosicion: selectSecundariaPosicion,
          bValidSecundariaPosicion: true,
          isDisabledSecundariaPosicion: false,
          // "listaSecundariaPosicion" :listaSecundariaPosicion
        })
      );
    } else {
      dispatch(
        setListarPrincipalPosicion({
          selectPrincipalPosicion: [],
          bValidPrincipalPosicion: null,
        })
      );
      // dispatch(setListarSecundariaPosicion({
      //   "selectSecundariaPosicion" : [],
      //   "bValidSecundariaPosicion" : null,
      //   "isDisabledSecundariaPosicion" : true,
      //   "listaSecundariaPosicion" :[]
      // }))
    }
  };
  const handleChangePosicionSecundario = async (value) => {
    if (value) {
      dispatch(
        setListarSecundariaPosicion({
          selectSecundariaPosicion: [value],
          bValidSecundariaPosicion: true,
        })
      );
    } else {
      dispatch(
        setListarSecundariaPosicion({
          selectSecundariaPosicion: [],
          bValidSecundariaPosicion: null,
        })
      );
    }
  };
  return loadingDetalle === true ? (
    <LoadingSpinner />
  ) : posicionState.aListaCabeceraAgrupado === null ? (
    <Content>No se encontró información</Content>
  ) : (
    <Content>
      <TabBarCreacion
        onClickTab1={onTabProcesoGeneral}
        tabProceso={tabProceso}
        labelTab1={'General'}
        onClickTab2={obTabCaracteristicas}
        labelTab2={'Datos adicionales'}
      />
      {tabProceso === 0 ? (
        <ColFlex>
          <SubTitle subtitle="1. Editar datos generales de la posición." />
          <ContentForm>
            <RowForm style={{ columnGap: '5px' }}>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Posición (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={posicionState.sPosicion}
                    onChange={(evnt) => handleChangePosicion(evnt)}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Concepto (*):'}></LabelForm>
                  <SelectForm
                    validate={posicionState.bValidConcepto}
                    value={posicionState.selectConcepto}
                    handleChange={(e, v) => handleChangeConceptoPosicion(v)}
                    options={posicionState.listaConceptos}
                    placeholder={'Seleccione Concepto'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm title={'Posición maestra :'}></LabelForm>
                  <SelectForm
                    validate={posicionState.bValidPosicionMaster}
                    value={posicionState.selectPosicionMaster}
                    handleChange={(e, v) => onChangePosicionMasterPosicion(v)}
                    options={posicionState.listaPosicionMaster}
                    placeholder={'Seleccione Posición maestra'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm title={'Derivadas :'}></LabelForm>
                  <SelectForm
                    validate={posicionState.bValidDerivada}
                    value={posicionState.selectDerivada}
                    handleChange={(e, v) => onChangeDerivadaPosicion(v)}
                    options={posicionState.listaDerivada}
                    isMultiple={true}
                    placeholder={'Seleccione derivaciones'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <SubTitle subtitle="Campos Clave:" weight="bold" />
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Id Columna (*):'}></LabelForm>
                  <SelectForm
                    validate={posicionState.bValidPrincipalPosicion}
                    value={posicionState.selectPrincipalPosicion}
                    handleChange={(e, v) => handleChangePosicionPrincipal(v)}
                    options={posicionState.listaPrincipalPosicion.filter(function (item) {
                      if (posicionState.selectSecundariaPosicion.length > 0) {
                        return item.value !== posicionState.selectSecundariaPosicion[0].value;
                      } else {
                        return item;
                      }
                    })}
                    placeholder={'Seleccione Campo'}
                    isDisabled={posicionState.isDisabledPrincipalPosicion}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Desdoble (*):'}></LabelForm>
                  <SelectForm
                    validate={posicionState.bValidSecundariaPosicion}
                    value={posicionState.selectSecundariaPosicion}
                    handleChange={(e, v) => handleChangePosicionSecundario(v)}
                    options={posicionState.listaPrincipalPosicion.filter(function (item) {
                      if (posicionState.selectPrincipalPosicion.length > 0) {
                        return item.value !== posicionState.selectPrincipalPosicion[0].value;
                      } else {
                        return item;
                      }
                    })}
                    placeholder={'Seleccione Campo'}
                    isDisabled={posicionState.isDisabledSecundariaPosicion}
                  />
                </RowForm>
              </ColForm>
              {rowsKeyData.length > 0 ? (
                <>
                  <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                    <SubTitle subtitle="Datos Llave:" weight="bold" />
                  </ColForm>
                  {rowsKeyData.map((data, index) => {
                    const { label, validate, valor } = data;
                    return (
                      <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                        <RowForm>
                          <LabelForm title={label + ':'}></LabelForm>
                          <Input
                            placeholder="Ingrese..."
                            value={valor}
                            validate={validate}
                            onChange={(evnt) => handleChangeValor(index, evnt)}
                          />
                        </RowForm>
                      </ColForm>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
              <RowFooterButton>
                <ButtonForm
                  action={(e) => onPressCancelar()}
                  name="Cancelar"
                  isAction={true}
                  typeColor="warning"
                />
                <ButtonForm
                  action={(e) => limpiarCampos(e)}
                  name="Limpiar"
                  isAction={true}
                  typeColor="primary"
                />
                <ButtonForm
                  action={(e) => onPressSiguiente()}
                  name="Siguiente"
                  isAction={true}
                  typeColor="secondary"
                />
              </RowFooterButton>
            </RowForm>
          </ContentForm>
        </ColFlex>
      ) : (
        <ColFlex>
          <SubTitle subtitle="2. Editar características de la posición" />

          <ContentForm>
            <TableGrid
              columns={columns}
              rows={rowsData}
              pageSize={15}
              getRowId={(row) => row.IdRowsInput}
            />
            <RowFooterButton>
              <ButtonForm
                action={(e) => onPressCancelar()}
                name="Cancelar"
                isAction={true}
                typeColor="warning"
              />
              <ButtonForm
                action={(e) => limpiarCampos(e)}
                name="Limpiar"
                isAction={true}
                typeColor="primary"
              />
              <ButtonForm
                action={(e) => onPressGrabar(props)}
                name="Grabar"
                isAction={true}
                typeColor="secondary"
              />
            </RowFooterButton>
          </ContentForm>
        </ColFlex>
      )}
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la edición de la posición?"
        action={(e) => onConfirmarConcepto(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </Content>
  );
};
