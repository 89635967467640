export const paths = {
  LOGIN: {
    BASE: '/login',
    TENANT: '/login/:tenantUrl',
    RECOVERY_PASSWORD: '/recovery_password',
    CHANGE_PASSWORD: '/change_password',
  },
  HOME: {
    BASE: '/home',
    DASHBOARD: '/dashboard',
  },
  SECURITY: {
    BASE: '/seguridad',
    USER: '/seguridad/usuario',
    ROLE: '/seguridad/rol',
    PROFILE: '/seguridad/perfil',
    AUDIT: '/seguridad/auditoria',
    PAYMENT: '/seguridad/pagos',
  },
  AUTOMATION: {
    BASE: '/automatizacion',
    CREATE: '/automatizacion/crear',
    EDIT: '/automatizacion/editar/:id',
  },
  PERSONALIZATION: '/personalizacion',
  CONFIGURE: {
    BASE: '/configuracion',
    SOCIETY: {
      BASE: '/configuracion/sociedad',
    },
    CALENDAR: {
      BASE: '/configuracion/calendario',
    },
    SYSTEM: {
      BASE: '/configuracion/sistema',
      CREATE: '/configuracion/sistema/crear',
      EDIT: '/configuracion/sistema/editar/:id',
    },
    FIELD: '/configuracion/campo',
    FUNCTION: {
      BASE: '/configuracion/funcion',
      CREATE: '/configuracion/funcion/crear',
      EDIT: '/configuracion/funcion/editar/:id',
    },
    CONCEPT: {
      BASE: '/configuracion/concepto',
      CREATE: '/configuracion/concepto/crear',
      EDIT: '/configuracion/concepto/editar/:id',
    },

    POSITION: {
      BASE: '/configuracion/posicion',
      MASTER: '/configuracion/posicion/master',
      CREATE: '/configuracion/posicion/crear',
      CREATE_BY_ID: '/configuracion/posicion/crear/:id',
      EDIT: '/configuracion/posicion/editar/:id',
    },
    STRUCTURE: {
      BASE: '/configuracion/estructura',
      CREATE: '/configuracion/estructura/crear',
      EDIT: '/configuracion/estructura/editar/:id',
    },
    EXCHANGE_RATE: '/configuracion/tipo_cambio',
    DERIVATION: {
      BASE: '/configuracion/derivacion',
      CREATE: '/configuracion/derivacion/crear',
      EDIT: '/configuracion/derivacion/editar/:id',
    },
  },
  BUSINESS_PARTNER: {
    BASE: '/socio_negocio',
    PARTNERS: '/socio_negocio/socio',
    DOCUMENTS: '/socio_negocio/documento',
  },
  SOLUTION: {
    BASE: '/solucion',
    CREDIT_LINE: {
      BASE: '/solucion/linea_credito',
      CREATE: '/solucion/linea_credito/crear',
      EDIT: '/solucion/linea_credito/editar/:id',
    },
    LOAN: {
      BASE: '/solucion/prestamo',
      CREATE: '/solucion/prestamo/crear',
      EDIT: '/solucion/prestamo/editar/:id',
    },
    DELAY: {
      BASE: '/solucion/aplazamiento',
      CREATE: '/solucion/aplazamiento/crear',
      EDIT: '/solucion/aplazamiento/editar/:id',
    },
    ADVANCE: '/solucion/adelanto',
    FACTORING: {
      BASE: '/solucion/factoring',
      CREATE: '/solucion/factoring/crear',
      EDIT: '/solucion/factoring/editar/:id',
    },
    DEPOSIT: {
      BASE: '/solucion/deposito',
      CREATE: '/solucion/deposito/crear',
      EDIT: '/solucion/deposito/editar/:id',
    },
    EXCHANGE_RATE: {
      BASE: '/solucion/tipo_cambio',
      CREATE: '/solucion/tipo_cambio/crear',
      EDIT: '/solucion/tipo_cambio/editar/:id',
    },
  },
  CASH_FLOW: {
    BASE: '/flujo_caja',
    SOURCE_RECORD: {
      BASE: '/flujo_caja/fuente',
      EDIT: '/flujo_caja/fuente/editar/:id',
    },

    OWN_RECORD: {
      BASE: '/flujo_caja/propio',
      LIST: '/flujo_caja/propio/:tab',
      CREATE: '/flujo_caja/propio/crear',
      CREATE_BY_ID: '/flujo_caja/propio/crear/:id',
      EDIT: '/flujo_caja/propio/editar/:id',
    },
    REPORT: {
      BASE: '/flujo_caja/reporte',
      CREATE: '/flujo_caja/reporte/crear',
      CREATEF: '/flujo_caja/reporte/crearf',
      VIEW: '/flujo_caja/reporte/visualizar/:id',
    },
  },
  MANAGEMENT: '/gestion',
  NOTIFICATION: '/notificacion',
};
