import React from 'react';
import Chart from 'react-apexcharts';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { LoadingForm, SelectForm } from '../../../components';
import { theme } from '../../../common';
import { CardDash, RowForm, Title } from '../../../styles';
// import { IconArrowDownRight, IconCurrencyDollar } from '@tabler/icons';

export const Market = (props) => {
  // chart color

  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [labelData, setLabelData] = React.useState([]);
  const [selectedPosicion, setSelectedPosicion] = React.useState([
    {
      value: 1,
      label: 'AAPL',
    },
  ]);
  const [listaPosicion, setListaPosicion] = React.useState([
    {
      value: 1,
      label: 'AAPL',
    },
  ]);

  useEffect(() => {
    if (selectedPosicion.length > 0) {
      obtenerAcumulado();
    }
  }, [selectedPosicion]);

  const obtenerAcumulado = () => {
    let listaDataTrans = [],
      listaData = [];
    let valuePosicion = '';

    props.data.map((a) => {
      if (a.Posicion === selectedPosicion[0].value) {
        let dato = a.Items.filter((a) => a.Caracteristica === 'Importe');
        let dato1 = a.Items.filter((a) => a.Caracteristica === 'FechaVcto');
        listaDataTrans.push({
          posicion: a.Posicion,
          concepto: a.Concepto,
          fecha: new Date(
            dato1[0].Valor.substring(6, 10),
            dato1[0].Valor.substring(3, 5) - 1,
            dato1[0].Valor.substring(0, 2)
          ).toLocaleDateString('en-GB'),
          importe: parseInt(dato[0].Valor),
        });
      }
    });

    listaDataTrans.sort(function (a, b) {
      a = a.fecha.split('/').reverse().join('');
      b = b.fecha.split('/').reverse().join('');
      return a > b ? 1 : a < b ? -1 : 0;
    });

    listaData = listaDataTrans.reduce((acumulador, valorActual) => {
      const elementoYaExiste = acumulador.find((elemento) => elemento.fecha === valorActual.fecha);
      if (elementoYaExiste) {
        return acumulador.map((elemento) => {
          if (elemento.fecha === valorActual.fecha) {
            return {
              ...elemento,
              importe: elemento.importe + valorActual.importe,
            };
          }

          return elemento;
        });
      }

      return [...acumulador, valorActual];
    }, []);

    const listaDato = [],
      listaLabel = [];
    const suma = listaData.reduce((valorAnterior, valorActual) => {
      return valorAnterior + parseInt(valorActual.importe);
    }, 0);

    listaData.map((b) => {
      if (b.fecha != 'Invalid Date') {
        listaDato.push(b.importe);
        listaLabel.push(b.fecha);
      }
    });
    setTotal('183.74');
    setData([
      182.88, 182.09, 181.9, 182.07, 182.29, 182.25, 182.35, 182.16, 182.33, 182.66, 182.68, 182.79,
      182.89, 182.74, 182.4, 182.41, 182.74, 182.57, 182.55, 182.64, 182.58, 182.46, 182.61, 183.74,
    ]);
    setLabelData([
      '08/05/24 09:30',
      '08/05/24 10:00',
      '08/05/24 10:30',
      '08/05/24 11:00',
      '08/05/24 11:30',
      '08/05/24 12:00',
      '08/05/24 12:30',
      '08/05/24 01:00',
      '08/05/24 01:30',
      '08/05/24 02:00',
      '08/05/24 02:30',
      '08/05/24 03:00',
      '08/05/24 03:30',
      '08/05/24 04:00',
      '08/05/24 04:30',
      '08/05/24 05:00',
      '08/05/24 05:30',
      '08/05/24 06:00',
      '08/05/24 06:30',
      '08/05/24 07:00',
      '08/05/24 07:30',
      '08/05/24 08:00',
      '09/05/24 09:30',
      '09/05/24 10:00',
    ]);
  };

  // chart
  const optionscolumnchart = {
    chart: {
      type: 'area',
      foreColor: '#adb0bb',
      fontFamily: theme.font.family,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      group: 'sparklines',
    },
    colors: theme.colorsDashboard,
    labels: labelData,
    xaxis: {
      categories: labelData,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    markers: {
      size: 0,
    },
    yaxis: {
      show: false,
      labels: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
    },
  };
  const seriescolumnchart = [
    {
      name: 'Serie',
      data: data,
    },
  ];

  const handleChangeSelect = (v) => {
    setSelectedPosicion([v]);
  };

  return (
    <CardDash
      size={'medium'}
      title={
        <RowForm>
          <Title>{props.titulo}</Title>
          <SelectForm
            style={{ marginLeft: 'auto' }}
            width={'54%'}
            value={selectedPosicion}
            handleChange={(evnt, v) => handleChangeSelect(v)}
            validate={true}
            options={listaPosicion}
            placeholder={'Seleccione'}
            disableClearable={true}
          />
        </RowForm>
      }
    >
      {props.loading ? (
        <LoadingForm />
      ) : (
        <>
          <Typography
            variant="h3"
            fontWeight="700"
            style={{
              overflowX: 'auto',
              height: '75px',
              fontSize: '2rem',
              fontFamily: theme.font.family,
            }}
          >
            {new Intl.NumberFormat('en-US').format(total)}&nbsp;USD
          </Typography>
          <Chart options={optionscolumnchart} series={seriescolumnchart} type="area" height={30} />
        </>
      )}
    </CardDash>
  );
};
