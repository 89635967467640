import React from 'react';
import Chart from 'react-apexcharts';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { LoadingForm, SelectForm } from '../../../components';
import { theme, useFetchAndLoad } from '../../../common';
import { CardDash, RowForm, Title } from '../../../styles';
import { ObtenerTipoCambio } from '../../../services';
import { listaTipoCambioAdapter } from '../../../adapters';
// import { IconArrowDownRight, IconCurrencyDollar } from '@tabler/icons';

export const TipoCambio = (props) => {
  // chart color
  const { loading, callEndpoint } = useFetchAndLoad();

  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [labelData, setLabelData] = React.useState([]);
  const [selectedMoneda, setSelectedMoneda] = React.useState([]);
  const [listaMoneda, setListaMoneda] = React.useState([]);
  const [listaTipoCambio, setListaTipoCambio] = React.useState([]);

  useEffect(() => {
    loadTipoCambio();
  }, []);

  useEffect(() => {
    if (selectedMoneda.length > 0) {
      // obtenerAcumulado();
    }
  }, [selectedMoneda]);

  const loadTipoCambio = async () => {
    const responselistaTipoCambio = await callEndpoint(ObtenerTipoCambio());
    var listaTipoCambio = [];
    var listaMonedas = [];
    for (let index = 0; index < responselistaTipoCambio.data.respuesta.aListadoTC.length; index++) {
      const element = responselistaTipoCambio.data.respuesta.aListadoTC[index];
      const elemento = listaTipoCambioAdapter(element);
      listaTipoCambio.push(elemento);
      if (elemento.MonedaBase != elemento.NombreMoneda) {
        listaMonedas.push({
          value: elemento.idTipoCambio,
          label: elemento.MonedaBase + '-' + elemento.NombreMoneda,
          MonedaBase: elemento.MonedaBase,
          NombreMoneda: elemento.NombreMoneda,
          Factor: elemento.Factor,
          Importe: elemento.Importe,
        });
      }
    }
    listaTipoCambio.sort((a, b) => a.Habilitado - b.Habilitado);
    listaTipoCambio.reverse();
    setListaTipoCambio(listaTipoCambio);
    setListaMoneda(listaMonedas);
    setSelectedMoneda([listaMonedas[0]]);
  };

  function quitarDuplicados(array) {
    // Objeto para rastrear objetos únicos
    let uniqueObjects = {};

    // Nuevo array para almacenar objetos únicos
    let uniqueArray = [];

    // Itera sobre cada objeto en el array original
    array.forEach((obj) => {
      // Convierte cada objeto a una cadena para usarlo como clave
      let key = JSON.stringify(obj);

      // Verifica si el objeto ya está en el objeto de rastreo de objetos únicos
      if (!uniqueObjects[key]) {
        // Agrega el objeto al objeto de rastreo y al nuevo array
        uniqueObjects[key] = true;
        uniqueArray.push(obj);
      }
    });

    return uniqueArray;
  }

  const obtenerAcumulado = () => {
    let listaDataTrans = [],
      listaData = [];
    let valuePosicion = '';

    props.data.map((a) => {
      if (a.Posicion === selectedMoneda[0].value) {
        let dato = a.Items.filter((a) => a.Caracteristica === 'Importe');
        let dato1 = a.Items.filter((a) => a.Caracteristica === 'FechaVcto');
        listaDataTrans.push({
          posicion: a.Posicion,
          concepto: a.Concepto,
          fecha: new Date(
            dato1[0].Valor.substring(6, 10),
            dato1[0].Valor.substring(3, 5) - 1,
            dato1[0].Valor.substring(0, 2)
          ).toLocaleDateString('en-GB'),
          importe: parseInt(dato[0].Valor),
        });
      }
    });

    listaDataTrans.sort(function (a, b) {
      a = a.fecha.split('/').reverse().join('');
      b = b.fecha.split('/').reverse().join('');
      return a > b ? 1 : a < b ? -1 : 0;
    });

    listaData = listaDataTrans.reduce((acumulador, valorActual) => {
      const elementoYaExiste = acumulador.find((elemento) => elemento.fecha === valorActual.fecha);
      if (elementoYaExiste) {
        return acumulador.map((elemento) => {
          if (elemento.fecha === valorActual.fecha) {
            return {
              ...elemento,
              importe: elemento.importe + valorActual.importe,
            };
          }

          return elemento;
        });
      }

      return [...acumulador, valorActual];
    }, []);

    const listaDato = [],
      listaLabel = [];
    const suma = listaData.reduce((valorAnterior, valorActual) => {
      return valorAnterior + parseInt(valorActual.importe);
    }, 0);

    listaData.map((b) => {
      if (b.fecha != 'Invalid Date') {
        listaDato.push(b.importe);
        listaLabel.push(b.fecha);
      }
    });
    setTotal(suma);
    setData(listaDato);
    setLabelData(listaLabel);
  };

  // chart
  const optionscolumnchart = {
    chart: {
      type: 'area',
      foreColor: '#adb0bb',
      fontFamily: theme.font.family,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      group: 'sparklines',
    },
    colors: theme.colorsDashboard,
    labels: labelData,
    xaxis: {
      categories: labelData,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    markers: {
      size: 0,
    },
    yaxis: {
      show: false,
      labels: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
    },
  };
  const seriescolumnchart = [
    {
      name: 'Serie',
      data: data,
    },
  ];

  const handleChangeSelect = (v) => {
    setSelectedMoneda([v]);
  };

  return (
    <CardDash
      size={'medium'}
      title={
        <RowForm>
          <Title>{props.titulo}</Title>
          <SelectForm
            style={{ marginLeft: 'auto' }}
            width={'48%'}
            value={selectedMoneda}
            handleChange={(evnt, v) => handleChangeSelect(v)}
            validate={true}
            options={listaMoneda}
            placeholder={'Seleccione'}
            disableClearable={true}
          />
        </RowForm>
      }
    >
      {props.loading ? (
        <LoadingForm />
      ) : (
        <Typography
          variant="h3"
          fontWeight="700"
          style={{
            overflowX: 'auto',
            height: '75px',
            fontSize: '2rem',
            fontFamily: theme.font.family,
          }}
        >
          1&nbsp;{selectedMoneda[0].MonedaBase}&nbsp;=&nbsp;
          {selectedMoneda[0].Factor * selectedMoneda[0].Importe}&nbsp;
          {selectedMoneda[0].NombreMoneda}
        </Typography>
      )}
    </CardDash>
  );
};
