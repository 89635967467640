import React from 'react';
import { useEffect } from 'react';
import { LoadingForm } from '../../../components';
import { GroupDatesByMonth, GroupDatesByWeek, theme, truncar } from '../../../common';
import { CardDash, Title } from '../../../styles';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const Expenses = (props) => {
  const dashboardState = useSelector((store) => store.dashboard);
  const [data, setData] = React.useState([]);
  const [serie, setSerie] = React.useState([]);
  const [categorie, setCategorie] = React.useState([]);
  let { t } = useTranslation();

  useEffect(() => {
    let listaData = [],
      listaFecha = [],
      listaPosicion = [],
      listaDeObj = [],
      datosFinal = [];

    if (props.data) {
      props.data.map((a) => {
        if (a.TipoConcepto === 'Egreso') {
          let importe = a.Items.filter((a) => a.Caracteristica === 'Importe');
          let fecha = a.Items.filter((a) => a.Caracteristica === 'FechaVcto');

          listaData.push({
            name: a.Posicion,
            fecha: new Date(
              fecha[0].Valor.substring(6, 10),
              fecha[0].Valor.substring(3, 5) - 1,
              fecha[0].Valor.substring(0, 2)
            ).toLocaleDateString('en-GB'),
            importe: truncar(parseFloat(importe[0].Valor), 2),
          });
          listaPosicion.push(a.Posicion);
          listaFecha.push(
            new Date(
              fecha[0].Valor.substring(6, 10),
              fecha[0].Valor.substring(3, 5) - 1,
              fecha[0].Valor.substring(0, 2)
            ).toLocaleDateString('en-GB')
          );
        }
      });
    }

    /* Posicion */
    listaPosicion = [...new Set(listaPosicion)];
    /* Posicion */
    /* Fecha */
    listaFecha.sort(function (a, b) {
      a = a.split('/').reverse().join('');
      b = b.split('/').reverse().join('');
      return a > b ? 1 : a < b ? -1 : 0;
    });
    listaFecha = [...new Set(listaFecha)];
    let datosSemana = GroupDatesByWeek(listaFecha);
    let listaSemana = Object.keys(datosSemana);
    let datosMes = GroupDatesByMonth(listaFecha, t);
    let listaMes = Object.keys(datosMes);
    /* Fecha */

    /* Diario */
    // const dataSinDuplicados = listaData.reduce((acumulador, valorActual) => {
    //   const elementoYaExiste = acumulador.find(
    //     (elemento) => elemento.name === valorActual.name && elemento.fecha === valorActual.fecha
    //   );
    //   if (elementoYaExiste) {
    //     return acumulador.map((elemento) => {
    //       if (elemento.name === valorActual.name && elemento.fecha === valorActual.fecha) {
    //         return {
    //           ...elemento,
    //           importe: elemento.importe + valorActual.importe,
    //         };
    //       }
    //       return elemento;
    //     });
    //   }

    //   return [...acumulador, valorActual];
    // }, []);
    /* Diario */
    /* Semanal */
    const dataSinDuplicados = listaData.reduce((acumulador, valorActual) => {
      let findSemana = '';
      let keys = Object.keys(listaMes);
      listaMes.map((key) => {
        let sem = datosMes[key].includes(valorActual.fecha);
        if (sem) {
          findSemana = key;
        }
      });
      const elementoYaExiste = acumulador.find(
        (elemento) => elemento.name === valorActual.name && elemento.fecha === findSemana
      );
      if (elementoYaExiste) {
        return acumulador.map((elemento) => {
          if (elemento.name === valorActual.name && elemento.fecha === findSemana) {
            let suma = elemento.importe + valorActual.importe;
            let resultadoRedondeado = Math.round(suma * 100) / 100;
            return {
              ...elemento,
              importe: resultadoRedondeado,
            };
          }
          return elemento;
        });
      }

      return [...acumulador, { ...valorActual, fecha: findSemana }];
    }, []);
    /* Semanal */
    /* Diario */
    //
    // listaPosicion.map((v) => {
    //     let listaValores = [];
    //   listaFecha.map((f) => {
    //     let findValor = dataSinDuplicados.filter((va) => va.name === v && va.fecha === f);
    //     if (findValor.length > 0) {
    //         listaValores.push(findValor[0].importe);
    //     } else {
    //       listaValores.push(null);
    //     }
    //   });
    //   listaDeObj.push({
    //     name: v,
    //     data: listaValores,
    //   });
    // });
    /* Semanal */

    listaPosicion.map((v) => {
      let listaValores = [];
      listaMes.map((f) => {
        let findValor = dataSinDuplicados.filter((va) => va.name === v && va.fecha === f);
        if (findValor.length > 0) {
          listaValores.push(findValor[0].importe * -1);
        } else {
          listaValores.push(null);
        }
      });
      listaDeObj.push({
        name: v,
        data: listaValores,
      });
    });
    /* Semanal */

    dataSinDuplicados.map((b) => {
      datosFinal.push({ x: b.name, y: b.importe });
    });

    listaMes = listaMes.map((v) => {
      let fechaSplit = v.split(' de ');
      let mes = '';
      switch (fechaSplit[0]) {
        case 'enero':
          mes = '01';
          break;
        case 'febrero':
          mes = '02';
          break;
        case 'marzo':
          mes = '03';
          break;
        case 'abril':
          mes = '04';
          break;
        case 'mayo':
          mes = '05';
          break;
        case 'junio':
          mes = '06';
          break;
        case 'julio':
          mes = '07';
          break;
        case 'agosto':
          mes = '08';
          break;
        case 'septiembre':
          mes = '09';
          break;
        case 'octubre':
          mes = '10';
          break;
        case 'noviembre':
          mes = '11';
          break;
        case 'diciembre':
          mes = '12';
          break;
        default:
          mes = '';
      }
      return mes + '/' + fechaSplit[1].substring(2, 4);
    });

    setData(datosFinal);
    setSerie(listaDeObj);
    setCategorie(listaMes);
  }, [props.data]);

  // // chart
  const options = {
    chart: {
      type: 'treemap',
      foreColor: '#adb0bb',
      fontFamily: theme.font.family,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    colors: theme.colorsDashboard,
    tooltip: {
      y: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
      style: {
        fontFamily: theme.font.family,
      },
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  };

  const options2 = {
    chart: {
      type: 'bar',
      stacked: true,
      foreColor: '#adb0bb',
      fontFamily: theme.font.family,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    colors: theme.colorsDashboard,
    dataLabels: {
      enabled: false,
      style: {
        fontSize: '44px',
      },
    },

    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: 'category',
      categories: categorie,
      title: {
        text: 'Mensual',
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
      title: {
        text: dashboardState.moneda,
        offsetX: 0,
        offsetY: 0,
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <CardDash title={<Title>{props.titulo}</Title>}>
      {props.loading ? (
        <LoadingForm />
      ) : (
        <>
          {/* <Chart options={options} series={[{ data: data }]} type="treemap" height={300} /> */}
          <Chart options={options2} series={serie} type="bar" height={300} />
        </>
      )}
    </CardDash>
  );
};
