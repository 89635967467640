import { FaPenToSquare } from 'react-icons/fa6';
import { Selected } from '../../../../../../../common';
import { BasicPopover, ButtonForm } from '../../../../../../../components';

export const columns = [
  {
    field: 'Nombre',
    headerName: 'Id Columna',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => <>{params.value}</>,
    seleccionado: Selected.TRUE,
    columna: 'Nombre',
    parsed: 'Id Columna',
    restricted: Selected.TRUE,
  },
  {
    field: 'NombreConcepto',
    headerName: 'Concepto',
    flex: 1,
    minWidth: 220,
    renderCell: (params) => <>{params.value}</>,
    seleccionado: Selected.TRUE,
    columna: 'NombreConcepto',
    parsed: 'Concepto',
  },
  {
    field: 'NombrePosicion',
    headerName: 'Posición',
    flex: 1,
    minWidth: 220,
    renderCell: (params) => <>{params.value}</>,
    seleccionado: Selected.TRUE,
    columna: 'NombrePosicion',
    parsed: 'Posición',
    restricted: Selected.TRUE,
  },
  {
    field: 'Importe',
    headerName: 'Monto',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => <>{params.value}</>,
    seleccionado: Selected.TRUE,
    columna: 'Importe',
    parsed: 'Monto',
  },
  {
    field: 'MonedaImporte',
    headerName: 'Moneda',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => <>{params.value}</>,
    seleccionado: Selected.TRUE,
    columna: 'MonedaImporte',
    parsed: 'Moneda',
  },
  {
    field: 'FechaVcto',
    headerName: 'Fecha',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => <>{params.value}</>,
    seleccionado: Selected.TRUE,
    columna: 'FechaVcto',
    parsed: 'Fecha',
  },
  {
    field: 'FechaRegistro',
    headerName: 'Fecha Registro',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => <>{params.value}</>,
    seleccionado: Selected.TRUE,
    columna: 'FechaRegistro',
    parsed: 'Fecha Registro',
  },
  {
    field: 'aListaEtiquetas',
    headerName: 'Etiquetas',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return <BasicPopover aServicios={params.row.aListaEtiquetas} />;
    },
    seleccionado: Selected.TRUE,
    columna: 'aListaEtiquetas',
    parsed: 'Etiquetas',
  },
];

export const AccionColumna = (onClickEditarRegistroM) => [
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <>
        {
          <ButtonForm
            action={(oEvent) => onClickEditarRegistroM(params.row)}
            icon={<FaPenToSquare />}
            isAction={false}
            isOnlyIcon={true}
            typeColor="primary"
          />
        }
      </>
    ),
    seleccionado: Selected.TRUE,
    columna: 'edit',
    parsed: 'Acciones',
    restricted: Selected.TRUE,
  },
];
