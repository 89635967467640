import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { MenuLayout, SideImage } from '../../styles';
import logoMenu from '../../assets/images/logo-login.png';
import logoMenuClose from '../../assets/images/icoZCF.png';
import { useLocation, useNavigate } from 'react-router-dom';
import MainHeader from './components/MainHeader';
import { useAuth, useFetchAndLoad } from '../../common/hooks';
import { obtenerPersonalizacion } from '../../services';
import i18next from 'i18next';
import { setPersonalizacion } from '../../redux/states';
import { useDispatch } from 'react-redux';
import { menuItems } from '../../common';
const { Content, Sider } = Layout;

const MainLayout = (props) => {
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [collapsedWidth, setCollapsedWidth] = useState(70);
  const [broken, setBroken] = useState(false);
  const { updateTitle } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { callEndpoint } = useFetchAndLoad();
  const { user } = useAuth();
  useEffect(() => {
    (async () => {
      await loadPersonalizacion(user.uid);
    })();
  }, []);

  const loadPersonalizacion = async (id) => {
    const responsePersonalizacion = await callEndpoint(obtenerPersonalizacion({ IDUsuario: id }));
    var listaPersonalizacion = [];
    for (
      let index = 0;
      index < responsePersonalizacion.data.respuesta.aListadoPer.length;
      index++
    ) {
      const element = responsePersonalizacion.data.respuesta.aListadoPer[index];
      listaPersonalizacion.push(element);
    }
    if (listaPersonalizacion.length === 0) {
      listaPersonalizacion.push({ DescPersonalizacion: 'Moneda', DatoPersonalizacion: 1 });
      listaPersonalizacion.push({ DescPersonalizacion: 'Periodo', DatoPersonalizacion: 1 });
      listaPersonalizacion.push({ DescPersonalizacion: 'Formato', DatoPersonalizacion: 1 });
      listaPersonalizacion.push({
        DescPersonalizacion: 'texto_moneda',
        DatoPersonalizacion: 'nombre',
      });
    }
    if (listaPersonalizacion.filter((v) => v.DescPersonalizacion === 'Lenguaje').length > 0) {
      i18next.changeLanguage(
        listaPersonalizacion.filter((v) => v.DescPersonalizacion === 'Lenguaje')[0]
          .DatoPersonalizacion
      );
    }
    dispatch(setPersonalizacion(listaPersonalizacion));
  };

  const isSelected = (pathname, basepath) => {
    return pathname.includes(basepath);
  };

  const getSelectedKeys = (items, pathname) => {
    let selectedKeys = [];
    items.map((item) => {
      if (item.children) {
        if (isSelected(pathname, item.key)) {
          selectedKeys.push(item.key);
        }
        const itemKeys = getSelectedKeys(item.children, pathname);
        selectedKeys.push(...itemKeys);
      } else {
        if (item.key === pathname) {
          selectedKeys.push(item.key);
          updateTitle(item.title);
        }
      }
    });
    return selectedKeys;
  };
  const onClickMenu = (e) => {
    navigate(e);
  };
  const onContextMenu = (e) => {
    window.open(e, '_blank', 'noopener,noreferrer');
  };

  const tienePermiso = (item) => {
    if (!item.permiso || item.permiso.length === 0) return true; // Si no tiene restricciones de permiso, se muestra
    return item.permiso.some((p) => user.permisos.includes(p));
  };

  // Función recursiva para filtrar ítems
  const filtrarItems = (items) => {
    return items
      .filter(tienePermiso)
      .map((item) => {
        if (item.children) {
          const childrenFiltrados = filtrarItems(item.children);
          return childrenFiltrados.length > 0 ? { ...item, children: childrenFiltrados } : null;
        }
        return item;
      })
      .filter(Boolean); // Elimina elementos nulos
  };

  const filteredMenuItems = filtrarItems(menuItems(onClickMenu, onContextMenu));

  const selectedOrOpenKeys = getSelectedKeys(filteredMenuItems, pathname);

  const onSiderBreakpoint = (value) => {
    if (value) {
      setCollapsed(true);
      setCollapsedWidth(0);
    } else {
      setCollapsed(false);
      setCollapsedWidth(70);
    }
    setBroken(value);
  };
  const toogleSidebar = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        collapsedWidth={collapsedWidth}
        onBreakpoint={onSiderBreakpoint}
        className={broken ? 'fixed-sidebar' : ''}
        defaultCollapsed={true}
        width={256}
      >
        <SideImage>
          <img
            src={!collapsed ? logoMenu : logoMenuClose}
            alt="Logo zCFlow"
            style={{ height: '100%' }}
          ></img>
        </SideImage>
        <div style={{ height: '100vh', overflow: 'hidden' }}>
          <MenuLayout
            theme="light"
            mode="inline"
            defaultOpenKeys={selectedOrOpenKeys}
            defaultSelectedKeys={selectedOrOpenKeys}
            selectedKeys={selectedOrOpenKeys}
            items={filteredMenuItems}
          />
          {/* </MenuLayout> */}
        </div>
      </Sider>

      {broken && !collapsed && <div className="sidebar-content-closer" onClick={toogleSidebar} />}
      <Layout style={{ height: '100vh' }}>
        <MainHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: 'white',
            borderRadius: '6px',
            height: '100vh ',
            overflow: 'auto',
            flexGrow: 1,
          }}
        >
          {props.element}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
