import React from 'react';
import { Modal } from 'antd';
import { ButtonForm, Input, LabelForm } from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { setModalSociedad } from '../../../../../redux/states';
import { ColForm, RowForm, Title } from '../../../../../styles';

export const ModalSociedadCE = (props) => {
  const sociedadState = useSelector((store) => store.sociedad);
  const dispatch = useDispatch();

  const handleModalSociedad = (oEvent, tipo) => {
    let valor = oEvent.target.value;
    let obj = {};

    obj[tipo] = valor;
    dispatch(setModalSociedad(obj));
  };

  return (
    <>
      <Modal
        open={props.open}
        title={<Title>{sociedadState.idSociedad ? 'Guardar Sociedad' : 'Crear Sociedad'}</Title>}
        closable={false}
        footer={[
          <ButtonForm
            action={(oEvent) => props.handleClose(oEvent)}
            name={'Cerrar'}
            isAction={true}
            typeColor="warning"
          />,
          <ButtonForm
            style={{ marginLeft: '5px' }}
            action={(oEvent) => props.handleGuardar(oEvent)}
            name={'Guardar'}
            isAction={true}
            typeColor="secondary"
          />,
        ]}
      >
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Nombre de sociedad (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={sociedadState.sociedad}
              onChange={(evnt) => handleModalSociedad(evnt, 'sociedad')}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'NIF (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={sociedadState.idTax}
              onChange={(evnt) => handleModalSociedad(evnt, 'idTax')}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Nombre comercial (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={sociedadState.nombreComercial}
              onChange={(evnt) => handleModalSociedad(evnt, 'nombreComercial')}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Pais (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={sociedadState.pais}
              onChange={(evnt) => handleModalSociedad(evnt, 'pais')}
            />
          </RowForm>
        </ColForm>
      </Modal>
    </>
  );
};
