import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterListarEstructuras,
  onChangeTipoEstructuraCrearEstructura,
  onLimpiarCamposCrearEstructura,
  onLimpiarRespuestaServicioEstructura,
  onSetDetalleCrearCopiaEstructura,
  onSetDetalleEditarEstructura,
  onSetEstructuraPrincipal,
  onSetEstructuras,
} from '../../../../redux/states';
import {
  Button,
  ButtonAccion,
  ButtonForm,
  LoadingSpinner,
  ModalConfirmation,
  PBR,
  SearchInputText,
  TableGrid,
} from '../../../../components';
import { useFetchAndLoad } from '../../../../common/hooks';
import {
  eliminarEstructuras,
  obtenerEstructurasXfiltro,
  obtenerEstructuraXfiltro,
  obtenerJsonEstructura,
} from '../../../../services';
import { updateTitle } from '../../../../redux/states';
import { buildPath, exportToExcel, paths } from '../../../../common';
import { useNavigate } from 'react-router-dom';

import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  RowAccion,
  RowFlex,
} from '../../../../styles';
import { Dropdown } from 'antd';
import { permisos } from '../../../../common';
import { items } from './misc';
import { FaPenToSquare } from 'react-icons/fa6';

export const ListarEstructura = (props) => {
  const [showModalCrearCopia, setShowModalCrearCopia] = useState(false);
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const [selectedEstructura, setSelectedEstructura] = useState([]);
  const estructuraState = useSelector((store) => store.estructura);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const titleExport = 'EstructurasData';
  const mapColumnsDownloadExcel = {
    sNombreEstructura: 'Nombre',
    sDetalleEstructura: 'Detalle',
    sEsPrincipal: 'Tipo Estructura',
  };

  useEffect(() => {
    dispatch(updateTitle({ title: 'Lista de Estructuras' }));
    (async () => {
      loadEstructuras();
    })();
  }, []);

  useEffect(() => {
    if (estructuraState.oModalRespuestaServicioEstructura.modalOpen) {
      Swal.fire({
        title: estructuraState.oModalRespuestaServicioEstructura.title,
        text: estructuraState.oModalRespuestaServicioEstructura.subtitle,
        icon: estructuraState.oModalRespuestaServicioEstructura.type,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(onLimpiarRespuestaServicioEstructura());
        }
      });
    }
    return () => {};
  }, [estructuraState.oModalRespuestaServicioEstructura]);

  const loadEstructuras = async () => {
    var { data } = await callEndpoint(obtenerEstructurasXfiltro());
    dispatch(onSetEstructuras(data.respuesta.aListaEstructuras));
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <CheckboxStyle
              size="small"
              checked={findConcepto(params.row)}
              onChange={() => handleChange(params.row)}
            />
          }
        </>
      ),
    },
    {
      field: 'sNombreEstructura',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'sDetalleEstructura',
      headerName: 'Detalle',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },

    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <PBR permiso={[permisos.EDITAR_ESTRUCTURA]}>
          <ButtonForm
            action={(e) => onClickEditarConcepto(params.row)}
            icon={<FaPenToSquare />}
            isAction={false}
            isOnlyIcon={true}
            typeColor="primary"
          />
        </PBR>
      ),
    },
  ];

  const findConcepto = (person) => {
    return !!selectedEstructura.find((p) => p.iIDEstructura === person.iIDEstructura);
  };

  const filterEstructura = (person) => {
    return selectedEstructura.filter((p) => p.iIDEstructura !== person.iIDEstructura);
  };

  const handleChange = (person) => {
    const filteredPeople = findConcepto(person)
      ? filterEstructura(person)
      : [...selectedEstructura, person];
    setSelectedEstructura(filteredPeople);
  };

  const onClickEditarConcepto = async (row) => {
    const responseObtenerJsonEstructura = await callEndpoint(
      obtenerJsonEstructura({
        oIDEstructura: {
          IDEst: row.iIDEstructura,
        },
      })
    );
    if (responseObtenerJsonEstructura.data) {
      if (responseObtenerJsonEstructura.data.cabecera.statusCode === 200) {
        let sNombreEstructura =
          responseObtenerJsonEstructura.data.respuesta.oCabeceraEstructura.sNombre;
        // dispatch(updateTitle({ title: "Editar Estructura " + sNombreEstructura }));
        dispatch(updateTitle({ title: 'Editar Estructura ' }));
        // alert(JSON.stringify(row));
        dispatch(
          onSetDetalleEditarEstructura({
            oConceptosCrearEstructura: {
              iIDEstructura: row.iIDEstructura,
              bValidCodigo: true,
              sNombre: row.sNombreEstructura,
              bValidNombre: true,
              sDetalle: row.sDetalleEstructura,
              bValidDetalle: true,
              oSelectTipoEstructura: [],
              bValidTipoEstructura: true,
              bPrincipal: row.iEsPrincipal === 1 ? true : false,
            },
            oSaldo: {
              treeData: responseObtenerJsonEstructura.data.respuesta.aDetalleSaldos,
              searchString: '',
              searchFocusIndex: -1,
              searchFoundCount: 0,
            },
            oIngreso: {
              treeData: responseObtenerJsonEstructura.data.respuesta.aDetalleIngreso,
              searchString: '',
              searchFocusIndex: -1,
              searchFoundCount: 0,
            },
            oEgreso: {
              treeData: responseObtenerJsonEstructura.data.respuesta.aDetalleEgreso,
              searchString: '',
              searchFocusIndex: -1,
              searchFoundCount: 0,
            },
            selectPosicionAsignada:
              responseObtenerJsonEstructura.data.respuesta.aPosicionesAsignadas,
          })
        );
        navigate(buildPath(paths.CONFIGURE.STRUCTURE.EDIT, { id: row.iIDEstructura }));
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No se encontró información',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const onAgregarConcepto = async () => {
    // setButtonAction(1);
    dispatch(onLimpiarCamposCrearEstructura());
    // dispatch(updateTitle({ title: "Crear Estructura" }));
    const responseListaEstructuras = await callEndpoint(
      obtenerEstructuraXfiltro({
        iEsPrincipal: 1,
      })
    );
    if (responseListaEstructuras.data) {
      if (responseListaEstructuras.data.respuesta.aListaEstructuras.length > 0) {
        dispatch(
          onSetEstructuraPrincipal(responseListaEstructuras.data.respuesta.aListaEstructuras[0])
        );
        dispatch(onChangeTipoEstructuraCrearEstructura(false));
      } else {
        dispatch(onChangeTipoEstructuraCrearEstructura(true));
      }
    } else {
      dispatch(onChangeTipoEstructuraCrearEstructura(false));
    }
    navigate(paths.CONFIGURE.STRUCTURE.CREATE);
  };
  const onClickEliminarConcepto = () => {
    if (selectedEstructura.length > 0) {
      setShowModalEliminar(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onClickCrearCopia = async () => {
    if (selectedEstructura.length === 1) {
      setShowModalCrearCopia(true);
    } else if (selectedEstructura.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Para realizar la copia debe seleccionar un registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (selectedEstructura.length > 1) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Para realizar la copia debe seleccionar solo un registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onAgregarNombreCopia = async () => {};

  const onConfirmarCrearCopia = async () => {
    onCloseModalCrearCopia();
    var row = selectedEstructura[0];
    const responseObtenerJsonEstructura = await callEndpoint(
      obtenerJsonEstructura({
        oIDEstructura: {
          IDEst: row.iIDEstructura,
        },
      })
    );
    if (responseObtenerJsonEstructura.data) {
      if (responseObtenerJsonEstructura.data.cabecera.statusCode === 200) {
        dispatch(updateTitle({ title: 'Crear Estructura ' }));
        // alert(JSON.stringify(row));
        dispatch(
          onSetDetalleCrearCopiaEstructura({
            oConceptosCrearEstructura: {
              iIDEstructura: null, //row.iIDEstructura,
              bValidCodigo: true,
              sNombre: '',
              bValidNombre: true,
              sDetalle: row.sDetalleEstructura,
              bValidDetalle: true,
              oSelectTipoEstructura: [],
              bValidTipoEstructura: true,
              bPrincipal: false,
            },
            oSaldo: {
              treeData: responseObtenerJsonEstructura.data.respuesta.aDetalleSaldos,
              searchString: '',
              searchFocusIndex: -1,
              searchFoundCount: 0,
            },
            oIngreso: {
              treeData: responseObtenerJsonEstructura.data.respuesta.aDetalleIngreso,
              searchString: '',
              searchFocusIndex: -1,
              searchFoundCount: 0,
            },
            oEgreso: {
              treeData: responseObtenerJsonEstructura.data.respuesta.aDetalleEgreso,
              searchString: '',
              searchFocusIndex: -1,
              searchFoundCount: 0,
            },
            selectPosicionAsignada:
              responseObtenerJsonEstructura.data.respuesta.aPosicionesAsignadas,
          })
        );
        // setButtonAction(1);
        navigate(paths.CONFIGURE.STRUCTURE.CREATE);
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No se encontró información',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error de servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarEstructuras(oEvent.target.value));
    } else {
      dispatch(filterListarEstructuras(''));
    }
  };
  const onCloseModalEliminar = () => {
    setShowModalEliminar(false);
  };
  const onCloseModalCrearCopia = () => {
    setShowModalCrearCopia(false);
  };
  const onConfirmarEliminar = async () => {
    var aListaEstructurasEliminar = [];
    selectedEstructura.forEach((element) => {
      var o = {};
      o.iIDEstructura = element.iIDEstructura;
      aListaEstructurasEliminar.push(o);
    });
    const responseListaEstructuras = await callEndpoint(
      eliminarEstructuras({
        aListaEstructurasEliminar: aListaEstructurasEliminar,
      })
    );
    setShowModalEliminar(false);
    if (responseListaEstructuras === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseListaEstructuras.data.cabecera.statusCode === 200) {
        loadEstructuras();
        setSelectedEstructura([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseListaEstructuras.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseListaEstructuras.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={'Buscar estructuras...'}
              filter={estructuraState.searchtext}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: items(
                      onClickCrearCopia,
                      onClickEliminarConcepto,
                      exportToExcel,
                      estructuraState.searchtext.length === 0
                        ? estructuraState.listaEstructuras
                        : estructuraState.filterListaEstructuras,
                      titleExport,
                      mapColumnsDownloadExcel
                    ),
                  }}
                >
                  <ButtonAccion />
                </Dropdown>
                <PBR permiso={[permisos.CREAR_ESTRUCTURA]}>
                  <ButtonForm
                    action={onAgregarConcepto}
                    name="Agregar"
                    isAction={true}
                    typeColor="primary"
                  />
                </PBR>
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        <TableGrid
          columns={columns}
          rows={
            estructuraState.searchtext.length === 0
              ? estructuraState.listaEstructuras
              : estructuraState.filterListaEstructuras
          }
          pageSize={15}
          getRowId={(row) => row.iIDEstructura}
        />
      </ColFlex>
      <ModalConfirmation
        showModal={showModalEliminar}
        closeModal={(e) => onCloseModalEliminar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {selectedEstructura.length > 0 ? (
        <ModalConfirmation
          showModal={showModalCrearCopia}
          closeModal={(e) => onCloseModalCrearCopia()}
          title="¿Confirmación?"
          subtitle={
            '¿Esta seguro que desea realizar una copia de la estructura ' +
            selectedEstructura[0].sNombreEstructura +
            '?'
          }
          action={(e) => onConfirmarCrearCopia(e)}
          labelButtonCancel="Cancelar"
          labelButtonConfirm="Aceptar"
        />
      ) : (
        <></>
      )}
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
