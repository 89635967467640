import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { theme } from '../../common';
export const SimpleSelect = (props) => {
  return (
    <ContainerSelect width={props.width} isMulti={props.isMulti}>
      <CustomSelect
        styles={customStyleSelect(props.bValid)}
        placeholder={props.placeholder}
        className="basic-single"
        classNamePrefix="select"
        // isClearable=  {true}
        isClearable={
          props.isNoClearable === undefined || props.isNoClearable === null
            ? true
            : !props.isNoClearable
        }
        defaultValue={props.defaultValue || 'Select'}
        isSearchable={true}
        menuPosition={'fixed'}
        name="tipo"
        options={props.options}
        onChange={props.onChange}
        value={props.oSelect}
        isDisabled={props.isDisabled}
        isMulti={props.isMulti}
        theme={(theme) => ({
          ...theme,
          borderRadius: '5px',
          colors: {
            ...theme.colors,
            primary: 'rgba(3, 201, 215, 0.08)',
            primary25: 'rgba(0, 0, 0, 0.03)',
          },
        })}
      />
    </ContainerSelect>
  );
};
const customStyleSelect = (oParam) => {
  const customStyles =
    oParam === null || oParam === true
      ? {
          control: (provided, state) => ({
            ...provided,
            backgroundColor: '#FFFFFF',
            boxShadow: state.isFocused ? 'rgb(0 0 0 / 8%) 1px 2px 10px' : 0,
            borderColor: state.isFocused ? '#D0EAE2' : '#CED4DA',
            '&:hover': {
              borderColor: state.isFocused ? '#D0EAE2' : '#CED4DA',
              boxShadow: state.isFocused ? `0 0 5px ${theme.colors.primary}` : 0,
            },
            borderRadius: '5px',
            fontFamily: theme.font.family,
            fontSize: '12px',
            alignContent: 'center',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            fontFamily: theme.font.family,
            fontSize: '12px',
          }),
          multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: '#FFFFFF',
            borderRadius: '2px',
            border: '1px solid gray',
          }),
          menu: (provided, state) => ({
            ...provided,
            fontFamily: theme.font.family,
            fontSize: '12px',
            zIndex: 999999,
          }),
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            fontSize: '12px',
          }),
          indicators: (provided) => ({ ...provided, height: '32px !important' }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'rgba(3, 201, 215, 0.08)' : 'white',
            color: 'black !important',
            '&:hover': {
              backgroundColor: state.isSelected ? 'rgba(3, 201, 215, 0.08)' : 'rgba(0, 0, 0, 0.03)',
            },
          }),
        }
      : {
          control: (provided, state) => ({
            ...provided,
            backgroundColor: '#FFFFFF',
            borderRadius: '2px',
            boxShadow: state.isFocused ? 'rgb(0 0 0 / 8%) 1px 2px 10px' : 0,
            borderColor: '#FF2F00',
            '&:hover': {
              borderColor: state.isFocused ? '#D0EAE2' : '#CED4DA',
              boxShadow: state.isFocused ? `0 0 5px ${theme.colors.primary}` : 0,
            },
            borderRadius: '5px',
            fontFamily: theme.font.family,
            fontSize: '12px',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            height: '32px',
            overflow: 'auto',
            fontFamily: theme.font.family,
            fontSize: '12px',
          }),
          menu: (provided, state) => ({
            ...provided,
            fontFamily: theme.font.family,
            fontSize: '12px',
            zIndex: 999999,
          }),
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          indicators: (provided) => ({ ...provided, height: '32px !important' }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'rgba(3, 201, 215, 0.08)' : 'white',
            color: 'black !important',
            '&:hover': {
              backgroundColor: state.isSelected ? 'rgba(3, 201, 215, 0.08)' : 'rgba(0, 0, 0, 0.03)',
            },
          }),
        };
  return customStyles;
};
export const ContainerSelect = styled.div`
  min-height: 32px !important;
  height: ${({ isMulti }) => (isMulti ? '32px' : '32px !important')};
  width: ${({ width }) => (width ? width : '200px')};
  overflow: 'hidden';
  align-content: 'flex-start';
  /* display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%; */
`;
const CustomSelect = styled(Select)`
  .select__control {
    min-height: 32px !important;
    height: ${({ isMulti }) => (isMulti ? '32px' : '32px !important')};
    overflow: ${({ isMulti }) => (isMulti ? 'hidden' : 'initial')};
    align-content: ${({ isMulti }) => (isMulti ? 'flex-start' : 'center')};
  }
  .select__control .select__value-container {
    height: ${({ isMulti }) => (isMulti ? '32px' : 'auto')};
    overflow: ${({ isMulti }) => (isMulti ? 'auto' : 'initial')};
  }
  .select__control .select__indicators {
    height: 32px;
  }
  .select__menu .css-26l3qy-menu {
    z-index: 99;
  }
`;
SimpleSelect.propTypes = {
  bValid: PropTypes.element.isRequired,
  placeholder: PropTypes.element.isRequired,
  options: PropTypes.element.isRequired,
  onChange: PropTypes.element.isRequired,
  oSelect: PropTypes.element.isRequired,
  width: PropTypes.number,
};
