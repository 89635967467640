import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  onAddFiltroDetalleFlujoCaja,
  onChangeRowFCaracteristicailtroDetalleFlujoCaja,
  onChangeRowFPosicionFiltroDetalleFlujoCaja,
  onChangeRowFSetListaCaracteristicasFiltroDetalleFlujoCaja,
  onChangeRowFSetListaValoresFiltroDetalleFlujoCaja,
  onChangeRowFValoresFiltroDetalleFlujoCaja,
  onDeleteFiltroDetalleFlujoCaja,
  onLimpiarFiltroDetalleFlujoCaja,
  onOpenModalCancelarCrearFlujoCaja,
  onOpenModalFiltrosDetalleFlujoCaja,
  onSetFiltroDetalleFlujoCaja,
} from '../../../../../../redux/states';
import {
  Button,
  ButtonForm,
  LabelForm,
  MultiSelectCheckboxes,
  SelectForm,
} from '../../../../../../components';
import { useFetchAndLoad } from '../../../../../../common/hooks';
import {
  obtenerInfoFiltrosFlujoCajaCaracteristica,
  obtenerInfoFiltrosFlujoCajaValores,
} from '../../../../../../services';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
// import Select  from "@mui/material/Select";
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import Swal from 'sweetalert2';
import collect from 'collect.js';
export const ModalFiltros = (props) => {
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const { aFiltrosDetalleFlujoCaja } = flujoCajaState;
  const onConfirmarEliminar = async (e) => {
    dispatch(onOpenModalCancelarCrearFlujoCaja(false));
  };
  const onAplicarFiltros = async (e, props) => {
    // dispatch(onOpenModalCancelarCrearFlujoCaja(false));
    const selectedFilters = {
      posiciones: [],
      caracteristicas: [],
      valores: [],
    };
    props.selectionFiltrosModel.forEach((element) => {
      element.selectPosicion.forEach((element2) => {
        selectedFilters.posiciones.push(element2.IdPosicion);
      });
      // element.selectCaracteristica.forEach(element2 => {
      selectedFilters.caracteristicas.push(element.selectCaracteristica);
      //  });
      element.selectValor.forEach((element2) => {
        selectedFilters.valores.push(element2);
      });
    });
    const { posiciones, valores, caracteristicas } = selectedFilters;
    if (
      (posiciones.length === 0 || valores.length === 0 || caracteristicas.length === 0) &&
      props.selectionFiltrosModel.length > 0
    ) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        // title: "No se encuentran Filtros aplicados.",
        title: 'Se debe llenar todos los filtros.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    } else {
      dispatch(onOpenModalFiltrosDetalleFlujoCaja(false));
      dispatch(
        onSetFiltroDetalleFlujoCaja({ aFiltrosDetalleFlujoCaja: props.selectionFiltrosModel })
      );
      return;
      const finalArraySinFiltro = [...props.finalArraySinFiltro];
      var colectData = collect;
      var lista = [];
      finalArraySinFiltro.forEach((element) => {
        if (element.IdentificadorCPR === 'C') {
          //
          element.Items.forEach((element2) => {
            element2.Valor = 0;
          });
          lista.push(element);
          // return;
        } else if (element.IdentificadorCPR === 'P') {
          // if(posiciones.includes(element.Id_Partida2)){
          //   element.Items.forEach(element2 => {
          //     element2.Valor = 0;
          //   });
          //   lista.push(element)
          // }
          lista.push(element);
        } else if (element.IdentificadorCPR === 'D') {
          // if(posiciones.includes(element.Id_Partida2)){
          //   element.Items.forEach(element2 => {
          //     element2.Valor = 0;
          //   });
          //   lista.push(element)
          // }
          lista.push(element);
        } else if (element.IdentificadorCPR === 'R') {
          let datos_reg = [];
          //
          if (posiciones.includes(element.Id_Partida2)) {
            // var test = colectData( element.datos_reg).whereIn('price', caracteristicas);
            var Id_Partida2 = element.Id_Partida2;
            let datos_reg = element.datos_reg;
            var no_error = false;
            //
            for (let index = 0; index < datos_reg.length; index++) {
              const element = datos_reg[index];
              var aEncontrado = selectedFilters.valores.filter(
                (o) => o.NombreInstancia === element.sDatoPosicion && Id_Partida2 === o.IdPosicion
              );
              if (aEncontrado.length > 0) {
                no_error = true;
                break;
              }
            }
            if (no_error) {
              lista.push(element);
            }
          } else {
            lista.push(element);
          }
        }
      });
      //asdasdasd
      const rowsInput = [...lista];
      const rowsInputSaldo = [...lista].filter((element) => {
        return element.Id.includes('S');
      });
      const rowsInputIngreso = [...lista].filter((element) => {
        return element.Id.includes('I');
      });
      const rowsInputEgreso = [...lista].filter((element) => {
        return element.Id.includes('E');
      });
      props.realizarRecalculo(rowsInputSaldo, rowsInput);
      props.realizarRecalculo(rowsInputIngreso, rowsInput);
      props.realizarRecalculo(rowsInputEgreso, rowsInput);
      props.setFinalArray(lista);
      //
      // var selectedDesdobles=props.selectedDesdobles
      // props.setSelectedDesdobles([])
      // props.setSelectedDesdobles(selectedDesdobles)
      props.onRealizarDesdoblesAutomatico(lista);
      props.realizarRecalculoSubTotal_Total(lista);
    }
    // return;
    // props.onRealizarDesdoblesAutomatico(lista);
    // var test = colectData(finalArraySinFiltro).map(
    //                                               function(item){
    //                                                     item.arrId_Partida2=colectData(finalArraySinFiltro).filter((value) => value.Id.includes(item.Id  ) && value.Id!==item.Id && value.Id_Partida2 != 0).map
    //                                                                         (
    //                                                                             function(item2){
    //                                                                               return item2.Id_Partida2
    //                                                                             }
    //                                                                         ).unique().all();
    //
    //                                                                         item.arrDatos_reg=colectData(finalArraySinFiltro).filter((value) => value.Id.includes(item.Id  ) && value.Id!==item.Id && value.Id_Partida2 != 0 ).map
    //                                                                         (
    //                                                                             function(item2){
    //                                                                               //
    //                                                                               if(item2.datos_reg.length>0){
    //                                                                                 return item2.datos_reg
    //                                                                               }
    //                                                                             }
    //                                                                         ).unique().all();
    //                                                             return item;
    //                                                         }
    //                                             );
    // props.realizarRecalculoSubTotal_Total(lista)
    //
    //   var detalleFlujoCajaCabeceras =
    //   flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
    // var objecto = {};
    // objecto.EsRaiz = 1;
    // objecto.Id = "Total Acumulado";
    // objecto.sNombre = "Total Acumulado";
    // objecto.Items = [];
    // var objectoParcial = {};
    // objectoParcial.EsRaiz = 1;
    // objectoParcial.Id = "Total Periodo";
    // objectoParcial.sNombre = "Total Periodo";
    // objectoParcial.Items = [];
    // var aEncontradoGruposRaiz = lista.filter((element) => {
    //   return element.EsGrupo === 1 && element.EsRaiz === 1;
    // });
    // for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
    //   var fAcumulado = 0;
    //   var item = {
    //     Id: objecto.Id + "-" + index,
    //     Id_Movimiento: null,
    //     EsRaiz: 1,
    //     EsGrupo: 1,
    //     Valor: 0,
    //   };
    //   for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
    //     const element = aEncontradoGruposRaiz[w];
    //     if (element.Posicion === "Egreso") {
    //       fAcumulado = fAcumulado - element.Items[index].Valor;
    //     } else {
    //       fAcumulado = fAcumulado + element.Items[index].Valor;
    //     }
    //   }
    //   item.Valor = fAcumulado;
    //   objecto.Items.push(item);
    //   objectoParcial.Items.push({ ...item });
    // }
    // props.setFinalArrayTotalParciales(objectoParcial);
    // for (let index = 0; index < objecto.Items.length; index++) {
    //   const element = objecto.Items[index - 1];
    //   if (!element) {
    //     objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
    //   } else {
    //     objecto.Items[index].Valor =
    //       objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
    //   }
    // }
    // props.setFinalArrayTotal(objecto);
  };
  const onCloseModal = (e) => {
    dispatch(onOpenModalFiltrosDetalleFlujoCaja(false));
  };
  const eliminarFiltro = (oEvent, item, index) => {
    var oParam = { item: item, index: index };
    let selectionFiltrosModelRows = [...props.selectionFiltrosModel];
    selectionFiltrosModelRows.splice(index, 1);
    props.setSelectionFiltrosModel(selectionFiltrosModelRows);
    // dispatch(onDeleteFiltroDetalleFlujoCaja(oParam));
  };
  const onLimpiarFiltros = (oEvent) => {
    // props.onRealizarDesdoblesAutomatico([...props.finalArraySinFiltro]);
    // props.realizarRecalculoSubTotal_Total([...props.finalArraySinFiltro])
    props.setSelectionFiltrosModel([]);
    dispatch(onLimpiarFiltroDetalleFlujoCaja());
  };
  const agregarFiltro = (oEvent) => {
    var oParam = {
      // iIdPosicion: 0,
      // sPosicion: "",
      selectPosicion: [],
      listaPosicion: flujoCajaState.listaPosicionesFiltros,
      // iIdCaracteristica: 0,
      // sCaracteristica: "",
      selectCaracteristica: [],
      listaCaracteristica: [],
      // listaValor: [
      //   {
      //     iIdValor: 0,
      //     sValor: "",
      //   },
      // ],
      selectValor: [],
      listaValor: [],
      listaValorPlano: [],
    };
    // dispatch(onAddFiltroDetalleFlujoCaja(oParam));
    let selectionFiltrosModelRows = [...props.selectionFiltrosModel];
    selectionFiltrosModelRows = [...selectionFiltrosModelRows, oParam];
    props.setSelectionFiltrosModel(selectionFiltrosModelRows);
  };
  const handleChangePosicionMultiCheckbox = async (value, oEvent, index) => {
    return;
    let selectPosicion = [];
    let aIDPosicion = [];
    let options = props.selectionFiltrosModel[index].listaPosicion;
    const selectionFiltrosModel = [...props.selectionFiltrosModel];
    if (value != null) {
      selectPosicion = value; //result;
    }
    var oParam = { selectPosicion: selectPosicion, index: index };
    if (oEvent.action === 'select-option' && oEvent.option.value === '*') {
      oParam.selectPosicion = [initiallAllPosition, ...options];
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option' && oEvent.option.value === '*') {
      oParam.selectPosicion = [];
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option') {
      oParam.selectPosicion = selectPosicion.filter((o) => o.value !== '*');
      //  dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else if (value.length === options.length - 1) {
      oParam.selectPosicion = options;
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else {
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    }
    selectionFiltrosModel.map((item, posi) => {
      if (posi === index) {
        item.selectPosicion = oParam.selectPosicion;
      }
      return { ...item };
    });
    if (oParam.selectPosicion.length > 0) {
      oParam.selectPosicion
        .filter((o) => o.value !== '*')
        .forEach((element) => {
          aIDPosicion.push(element.IdPosicion);
        });
      var responseObtenerInfoFiltrosFlujoCajaCaracteristica = await callEndpoint(
        obtenerInfoFiltrosFlujoCajaCaracteristica({
          oIDEstructura: {
            IDEst: flujoCajaState.iIdEstructura,
          },
          aIDPosicion: aIDPosicion,
        })
      );
      if (!responseObtenerInfoFiltrosFlujoCajaCaracteristica) {
        return;
      }
      if (responseObtenerInfoFiltrosFlujoCajaCaracteristica.data.cabecera.statusCode != 200) {
      } else {
        const opt = [];
        //responseObtenerInfoFiltrosFlujoCajaCaracteristica.data.respuesta.aListaAgrupadaCaracPos.forEach(element => {
        //    var oPosiCabecera = {...element};
        //   oPosiCabecera.label = element.NombrePosicion
        //   oPosiCabecera.options = [];
        //   oPosiCabecera.Items.forEach(element2 => {
        responseObtenerInfoFiltrosFlujoCajaCaracteristica.data.respuesta.aListaAgrupadaCaracPos.forEach(
          (element2) => {
            var optHijo = { ...element2 };
            optHijo.label = element2.NombreCampo;
            optHijo.value = element2.NombreCampo;
            //oPosiCabecera.options.push(optHijo);
            opt.push(optHijo);
          }
        );
        // opt.push(oPosiCabecera)
        // });
        var oParam2 = { listaCaracteristica: opt, index: index };
        // dispatch(onChangeRowFSetListaCaracteristicasFiltroDetalleFlujoCaja(oParam2))
        selectionFiltrosModel.map((item, index) => {
          if (index === oParam2.index) {
            item.listaCaracteristica = oParam2.listaCaracteristica;
          }
          return { ...item };
        });
        props.setSelectionFiltrosModel(selectionFiltrosModel);
        const options = [
          {
            label: 'Group 1',
            options: [
              { label: 'Group 1, option 1', value: 'value_1' },
              { label: 'Group 1, option 2', value: 'value_2' },
            ],
          },
          { label: 'A root option', value: 'value_3' },
          { label: 'Another root option', value: 'value_4' },
        ];
      }
    } else {
      var oParamCaract = { selectCaracteristica: [], index: index };
      var oParamValores = { selectValor: [], index: index };
      // dispatch(onChangeRowFCaracteristicailtroDetalleFlujoCaja(oParamCaract))
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParamValores))
      selectionFiltrosModel.map((item, posi) => {
        if (posi === oParamCaract.index) {
          item.selectCaracteristica = oParamCaract.selectCaracteristica;
        }
        return { ...item };
      });
      selectionFiltrosModel.map((item, index) => {
        if (index === oParamValores.index) {
          item.selectValor = oParamValores.selectValor;
        }
        return { ...item };
      });
      props.setSelectionFiltrosModel(selectionFiltrosModel);
    }
  };
  const handleChangeCaracteristica = async (index, oEvent) => {
    let selectCaracteristica = [];
    let aNombreCarac = [];
    let aIDPosicion = [];
    const selectionFiltrosModel = [...props.selectionFiltrosModel];
    if (oEvent != null) {
      selectCaracteristica = oEvent; //result;
    }
    var oParam = { selectCaracteristica: selectCaracteristica, index: index };
    // dispatch(onChangeRowFCaracteristicailtroDetalleFlujoCaja(oParam))
    selectionFiltrosModel.map((item, index) => {
      if (index === oParam.index) {
        item.selectCaracteristica = oParam.selectCaracteristica;
      }
      return { ...item };
    });
    // props.setSelectionFiltrosModel(props.selectionFiltrosModel);
    //if(selectCaracteristica.length>0){
    if (selectCaracteristica) {
      //selectCaracteristica.forEach(element => {
      aNombreCarac.push(selectCaracteristica.NombreCampo);
      // });
      props.selectionFiltrosModel[index].selectPosicion.forEach((element) => {
        aIDPosicion.push(element.IdPosicion);
      });
      var responseObtenerInfoFiltrosFlujoCajaCaracteristica = await callEndpoint(
        obtenerInfoFiltrosFlujoCajaValores({
          oIDEstructura: {
            IDEst: flujoCajaState.iIdEstructura,
          },
          aIDPosicion: aIDPosicion,
          aNombreCarac: aNombreCarac,
        })
      );
      if (!responseObtenerInfoFiltrosFlujoCajaCaracteristica) {
        return;
      }
      if (responseObtenerInfoFiltrosFlujoCajaCaracteristica.data.cabecera.statusCode != 200) {
      } else {
        const opt = [];
        let listaValorPlano = [];
        responseObtenerInfoFiltrosFlujoCajaCaracteristica.data.respuesta.aListaAgrupadaCaracPos.forEach(
          (element) => {
            var oCaracCabecera = { ...element };
            oCaracCabecera.label = element.NombrePosicion;
            oCaracCabecera.options = [];
            oCaracCabecera.Items.forEach((element2) => {
              var optHijo = { ...element2 };
              optHijo.label = element2.NombreInstancia;
              optHijo.value = element.NombrePosicion + '_' + element2.NombreInstancia;
              oCaracCabecera.options.push(optHijo);
              listaValorPlano.push(optHijo);
            });
            opt.push(oCaracCabecera);
          }
        );
        var oParam3 = { listaValor: opt, index: index, listaValorPlano: listaValorPlano };
        //  dispatch(onChangeRowFSetListaValoresFiltroDetalleFlujoCaja(oParam3))
        selectionFiltrosModel.map((item, index) => {
          if (index === oParam3.index) {
            item.listaValor = oParam3.listaValor;
            item.listaValorPlano = oParam3.listaValorPlano;
          }
          return { ...item };
        });
        props.setSelectionFiltrosModel(selectionFiltrosModel);
      }
    } else {
      var oParamValores = { selectValor: [], index: index, listaValorPlano: [] };
      var oParamListaValores = { listaValor: [], index: index };
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParamValores))
      // dispatch(onChangeRowFSetListaValoresFiltroDetalleFlujoCaja(oParamListaValores))
      selectionFiltrosModel.map((item, index) => {
        if (index === oParamValores.index) {
          item.selectValor = oParamValores.selectValor;
        }
        return { ...item };
      });
      selectionFiltrosModel.map((item, index) => {
        if (index === oParamListaValores.index) {
          item.listaValor = oParamListaValores.listaValor;
          item.listaValorPlano = oParamListaValores.listaValorPlano;
        }
        return { ...item };
      });
      props.setSelectionFiltrosModel(props.selectionFiltrosModel);
    }
  };
  const handleChangeValorMultiCheckbox = async (value, oEvent, index) => {
    return;
    let selectValor = [];
    let aIDPosicion = [];
    let options = props.selectionFiltrosModel[index].listaValorPlano; //props.selectionFiltrosModel[index].listaValor;
    const selectionFiltrosModel = [...props.selectionFiltrosModel];
    if (value != null) {
      selectValor = value; //result;
    }
    var oParam = { selectValor: selectValor, index: index };
    if (oEvent.action === 'select-option' && oEvent.option.value === '*') {
      // this.setState(this.options);
      oParam.selectValor = [initiallAllPosition, ...options];
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option' && oEvent.option.value === '*') {
      // this.setState([]);
      oParam.selectValor = [];
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option') {
      oParam.selectValor = selectValor.filter((o) => o.value !== '*');
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (value.length === options.length - 1) {
      oParam.selectValor = value; //options
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else {
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    }
    //
    selectionFiltrosModel.map((item, index) => {
      if (index === oParam.index) {
        item.selectValor = oParam.selectValor;
      }
      return { ...item };
    });
    props.setSelectionFiltrosModel(selectionFiltrosModel);
  };
  // useEffect(() => {
  //   setSelectedOptions([{ label: "Todos", value: "*" }, ...options]);
  // }, []);
  const initiallAllPosition = {
    IdPosicion: null,
    value: '*',
    CodigoPosicion: '',
    NombrePosicion: '',
    label: 'Todos',
    IDConcepto: null,
    NombreConcepto: '',
    TipoConcepto: '',
  };
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === '*')) {
      return `${placeholderButtonLabel}: Todos`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }
  return (
    <ModalComponent
      backdrop="static"
      keyboard={false}
      show={flujoCajaState.modalOpenFiltrosDetalleFlujoCaja}
      onHide={onCloseModal}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Filtros</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        {props.selectionFiltrosModel.length === 0 ? (
          <RowFiltros>
            <LabelForm width={'200px'} title={'No se han agregado Filtros'}>
              No se han agregado Filtros
            </LabelForm>{' '}
          </RowFiltros>
        ) : (
          props.selectionFiltrosModel.map((item, index) => (
            <RowFiltros>
              <MultiSelectCheckboxes
                options={[initiallAllPosition, ...item.listaPosicion]}
                // placeholderButtonLabel="Seleccione Posición"
                getDropdownButtonLabel={getDropdownButtonLabel}
                selectedOptions={item.selectPosicion}
                onChange={(value, oEvent) =>
                  handleChangePosicionMultiCheckbox(value, oEvent, index)
                }
                placeholderButtonLabel={'Posiciones'}
                width={'250px'}
                disabled={true}
              />
              <LabelForm width={'60px'} title={'Característica:'}></LabelForm>
              <SelectForm
                placeholder="Seleccione Característica"
                isClearable={true}
                isSearchable={true}
                bValid={true}
                name="sFuncion"
                options={item.listaCaracteristica}
                handleChange={(evnt, v) => handleChangeCaracteristica(index, v)}
                value={item.selectCaracteristica}
                isDisabled={true}
                isMulti={false}
                width={'300px'}
              />
              <MultiSelectCheckboxes
                options={[initiallAllPosition, ...item.listaValor]}
                getDropdownButtonLabel={getDropdownButtonLabel}
                selectedOptions={item.selectValor}
                onChange={(value, oEvent) => handleChangeValorMultiCheckbox(value, oEvent, index)}
                placeholderButtonLabel={'Valores'}
                width={'250px'}
                disabled={true}
              />
            </RowFiltros>
          ))
        )}
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <ButtonForm action={onCloseModal} name="Cancelar" isAction={false} typeColor="warning" />
      </ModalComponent.Footer>
    </ModalComponent>
  );
};
const RowFiltros = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 45px;
  margin: 12px 0px 12px 0px;
  align-items: center;
  justify-content: center;
`;
const RowFiltrosTitulos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 45px;
  margin: 12px 0px 12px 0px;
  align-items: center;
  justify-content: center;
`;
const ModalComponent = styled(Modal)`
  .modal-dialog {
    max-width: 1200px !important;
  }
`;
const RowCabecera = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
