import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../../common/hooks';
import { useEffect } from 'react';
import {
  changeSearchRol,
  onSetDrawerAcceso,
  onSetDrawerPermiso,
  setCleanDrawerAcceso,
  setCleanDrawerPermiso,
  setListarRol,
  updateTitle,
} from '../../../redux/states';
import {
  crearRol,
  editarRol,
  eliminarRol,
  guardarFiltrosRol,
  listarCamposMJ,
  obtenerDetalleRol,
  obtenerFiltrosRol,
  obtenerListaRol,
} from '../../../services';
import Swal from 'sweetalert2';
import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  RowAccion,
  RowButton,
  RowFlex,
} from '../../../styles';
import { ButtonForm, LoadingSpinner, SearchInputText, TableGrid } from '../../../components';
import { listaAcceso } from '../../../common';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { columns } from './misc';
import { DrawerAcceso, DrawerPermiso } from './components';
import { FaTrashCan } from 'react-icons/fa6';

export const SeguridadRol = () => {
  const [openDrawerPermiso, setOpenDrawerPermiso] = React.useState(false);
  const [openDrawerAcceso, setOpenDrawerAcceso] = React.useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const rolState = useSelector((store) => store.rol);
  let dispatch = useDispatch();
  let { t } = useTranslation();

  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: t('rol.titulo') }));
      loadRol();
    })();
  }, []);

  const loadRol = async () => {
    const responseListaRol = await callEndpoint(obtenerListaRol());
    var listaRol = [];
    for (let index = 0; index < responseListaRol.data.respuesta.aLista.length; index++) {
      const element = responseListaRol.data.respuesta.aLista[index];
      listaRol.push(element);
    }
    dispatch(setListarRol(listaRol));
  };

  const loadDetalleRol = async (id) => {
    const responseListaRol = await callEndpoint(obtenerDetalleRol({ rolID: id }));
    var listaRol = [];
    for (let index = 0; index < responseListaRol.data.respuesta.listaPermiso.length; index++) {
      const element = responseListaRol.data.respuesta.listaPermiso[index].codigo;
      listaRol.push(element);
    }

    let find = listaAcceso.filter((r) => {
      return listaRol.includes(r.codigo);
    });

    if (find.length === listaAcceso.length) {
      // setrolState.isSelectedAllAcceso(true);
      dispatch(onSetDrawerAcceso({ isSelectedAllAcceso: true, selectedAcceso: listaRol }));
    } else {
      // setrolState.isSelectedAllAcceso(false);
      dispatch(onSetDrawerAcceso({ isSelectedAllAcceso: false, selectedAcceso: listaRol }));
    }

    // setSelectedAcceso(listaRol);
  };

  const loadCampos = async () => {
    const responseListaCampos = await callEndpoint(listarCamposMJ());
    var listaCampos = [];
    for (let index = 0; index < responseListaCampos.data.respuesta.aListadoCampos.length; index++) {
      const element = responseListaCampos.data.respuesta.aListadoCampos[index];
      if (element.idTipoCampo === 2) {
        element.value = element.nombreCampo;
        element.label = element.nombreCampo;
        listaCampos.push(element);
      }
    }
    dispatch(onSetDrawerPermiso({ listaVariablePermiso: listaCampos }));

    // setListaVariablePermisoData(listaCampos);
  };

  const loadFiltroRol = async (id) => {
    const responseListaFiltroRol = await callEndpoint(obtenerFiltrosRol({ IDRol: id }));

    var listaFiltroRol = [];
    for (let index = 0; index < responseListaFiltroRol.data.respuesta.aFiltros.length; index++) {
      const element = responseListaFiltroRol.data.respuesta.aFiltros[index];
      let uid = uuidv4();
      element.id = uid;
      listaFiltroRol.push(element);
    }
    dispatch(onSetDrawerPermiso({ listaPermiso: listaFiltroRol }));
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(changeSearchRol(oEvent.target.value));
    } else {
      dispatch(changeSearchRol(''));
    }
  };

  const onOpenDrawerAcceso = async (e, data) => {
    if (data) {
      dispatch(onSetDrawerAcceso({ rolAccesoId: data.RolID, rolAccesoNombre: data.Descripcion }));
      await loadDetalleRol(data.RolID);
    }
    setOpenDrawerAcceso(true);
  };

  const onCloseDrawerAcceso = () => {
    setOpenDrawerAcceso(false);
    dispatch(setCleanDrawerAcceso());
  };

  const validarModal = () => {
    let validarDatos = false;
    if (rolState.rolAccesoNombre) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }

    if (rolState.selectedAcceso.filter((v) => !v.includes('G.')).length > 0) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    return validarDatos;
  };

  const findAcceso = (row) => {
    return !!rolState.selectedAcceso.find((p) => p === row.codigo);
  };

  const filterAcceso = (row) => {
    return rolState.selectedAcceso.filter((p) => p !== row.codigo);
  };

  const handleSelectRow = (row) => {
    const filteredPeople = findAcceso(row)
      ? filterAcceso(row)
      : [...rolState.selectedAcceso, row.codigo];

    let find = listaAcceso.filter((r) => {
      return filteredPeople.includes(r.codigo);
    });
    if (find.length === listaAcceso.length) {
      // setrolState.isSelectedAllAcceso(true);
      dispatch(onSetDrawerAcceso({ isSelectedAllAcceso: true, selectedAcceso: filteredPeople }));
    } else {
      // setrolState.isSelectedAllAcceso(false);
      dispatch(onSetDrawerAcceso({ isSelectedAllAcceso: false, selectedAcceso: filteredPeople }));
    }

    // setSelectedAcceso(filteredPeople);
  };

  const handleSelectRowTree = (row) => {
    dispatch(onSetDrawerAcceso({ isSelectedAllAcceso: false, selectedAcceso: row }));

    // setSelectedAcceso(filteredPeople);
  };

  const onApplyAcceso = async () => {
    if (rolState.rolAccesoId) {
      let validar = await validarModal();
      if (validar) {
        let listaAccesos = rolState.selectedAcceso.filter((v) => !v.includes('G.'));
        let obj = {
          rolID: rolState.rolAccesoId,
          Descripcion: rolState.rolAccesoNombre,
          listaPermiso: listaAccesos,
        };
        const responsePermiso = await callEndpoint(editarRol(obj));

        if (responsePermiso.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responsePermiso.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          loadRol();
          onCloseDrawerAcceso();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responsePermiso.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Se tienen que llenar todos los campos',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      let validar = await validarModal();
      if (validar) {
        let listaAccesos = rolState.selectedAcceso.filter((v) => !v.includes('G.'));

        const filterNombre = rolState.listaRol.filter(
          (a) => a.Descripcion === rolState.rolAccesoNombre
        );
        if (filterNombre.length === 0) {
          let obj = {
            Descripcion: rolState.rolAccesoNombre,
            listaPermiso: listaAccesos,
          };
          const responsePermiso = await callEndpoint(crearRol(obj));
          setOpenDrawerAcceso(false);

          if (responsePermiso.data.cabecera.statusCode === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: responsePermiso.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            loadRol();
            onCloseDrawerAcceso();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responsePermiso.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'El nombre del rol ya existe',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Se tienen que llenar todos los campos',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onDeleteRol = (e, data) => {
    Swal.fire({
      title: `Seguro que desea eliminar a ${data.Descripcion}`,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: true,
      allowEscapeKey: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseRolDelete = await callEndpoint(eliminarRol({ IDRol: data.RolID }));
        if (responseRolDelete.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseRolDelete.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          loadRol();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRolDelete.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const columsPermiso = [
    {
      field: 'edit',
      headerName: '',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 70,
      renderCell: (params) => (
        <CheckboxStyle
          checked={findAcceso(params.row)}
          onChange={() => handleSelectRow(params.row)}
          size="small"
        />
      ),
    },
    {
      field: 'descripcion',
      headerName: 'Descripcion',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
  ];

  const onOpenDrawerPermiso = (e, r) => {
    const id = r.RolID;
    loadCampos();
    loadFiltroRol(id);
    dispatch(onSetDrawerPermiso({ rolPermisoId: id }));

    setOpenDrawerPermiso(true);
  };

  const columsPermisoData = [
    {
      field: 'NombreVariable',
      headerName: 'Caracteristica',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'ValorVariable',
      headerName: 'Valor',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },

    {
      field: 'edite',
      headerName: '',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      width: 75,
      renderCell: (params) => (
        <RowButton>
          <ButtonForm
            action={(oEvent) => handleDeletePermiso(oEvent, params.row)}
            icon={<FaTrashCan />}
            isAction={false}
            isOnlyIcon={true}
            typeColor="primary"
          />
        </RowButton>
      ),
    },
  ];

  const handleDeletePermiso = (e, r) => {
    const filterPermiso = rolState.listaPermiso.filter(function (item, index) {
      return !item.id.includes(r.id);
    });
    dispatch(onSetDrawerPermiso({ listaPermiso: filterPermiso }));
  };

  const oncloseDrawerPermiso = () => {
    setOpenDrawerPermiso(false);
    dispatch(setCleanDrawerPermiso());
  };

  const onAppliedPermiso = async () => {
    if (rolState.rolPermisoId) {
      if (rolState.listaPermiso.length > 0) {
        let obj = {
          IDRol: rolState.rolPermisoId,
          aFiltros: rolState.listaPermiso,
        };
        const responsePermisoData = await callEndpoint(guardarFiltrosRol(obj));
        setOpenDrawerAcceso(false);

        if (responsePermisoData.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responsePermisoData.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          loadRol();
          oncloseDrawerPermiso();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responsePermisoData.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No tiene permisos para adicionar',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No tiene un rol para adicionar',
        showConfirmButton: false,
        timer: 2000,
      });
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={t('rol.buscadorPl')}
              filter={rolState.searchtextHomologacion}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <ButtonForm
                  action={(e) => onOpenDrawerAcceso(e, {})}
                  name={t('rol.botonAgregar')}
                  isAction={true}
                  typeColor="primary"
                />
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        <TableGrid
          columns={columns(onOpenDrawerPermiso, onOpenDrawerAcceso, onDeleteRol, t)}
          rows={
            rolState.searchtextHomologacion.length === 0
              ? rolState.listaRol
              : rolState.filterListaRol
          }
          pageSize={15}
          getRowId={(row) => row.RolID}
        />
        <DrawerAcceso
          openDrawerAcceso={openDrawerAcceso}
          onCloseDrawerAcceso={onCloseDrawerAcceso}
          onApplyAcceso={onApplyAcceso}
          columsPermiso={columsPermiso}
          handleSelectRow={handleSelectRowTree}
        />
        <DrawerPermiso
          openDrawerPermiso={openDrawerPermiso}
          oncloseDrawerPermiso={oncloseDrawerPermiso}
          onAppliedPermiso={onAppliedPermiso}
          columsPermisoData={columsPermisoData}
        />
      </ColFlex>
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
