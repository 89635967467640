import { createSlice, current } from '@reduxjs/toolkit';
export const derivacionInitialState = {
  searchtext: '',
  listaDerivaciones: [],
  filterListaDerivaciones: [],
  listaDerivacionDetalle: [],
  listaDerivacionDetalleGrid: [],
  listaCaracteristicasDerivacion: [],
  listaCaracteristicasDerivacionCampo: [],
  nombreDerivacion: '',
  bValidNombreDerivacion: null,
  selectLlavesDerivacion: [],
  bValidLlavesDerivacion: null,
  selectCamposDerivacion: [],
  bValidCamposDerivacion: null,
  columnsListaDetalle: [],

  //Formulario
  idJob: '',
  bValidTipo: null,
  selectTipo: [],
  bValidEstado: null,
  selectEstado: [],
  sNombre: '',
  bValidNombre: null,
  sDetalle: '',
  bValidDetalle: null,
  bValidModalidad: null,
  selectModalidad: [],
  listaModalidadDatos: [],
  bValidModalidadDatos: null,
  selectModalidadDatos: [],
  bValidFrecuencia: null,
  selectFrecuencia: [],
  sFrecuenciaNumero: '',
  bValidFrecuenciaNumero: null,
  dFechaInicio: [],
  bValidFechaInicio: null,
  dDateTimeInicio: '',
  dFechaFin: [],
  bValidFechaFin: null,
  dDateTimeFin: '',
  bValidDependencias: null,
  selectDependencias: [],
};
export const derivacionSlice = createSlice({
  name: 'derivacion',
  initialState: derivacionInitialState,
  reducers: {
    filterListarDerivaciones: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaDerivaciones = estadoInspeccionado.listaDerivaciones.filter(function (
        item,
        index
      ) {
        return (
          item.sNombreDerivacion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.sDetalleDerivacion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.sEsPrincipal.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaDerivaciones: filterListaDerivaciones,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setListarDerivacion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaCaracteristicasDerivacion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setDatosDetalleDerivacion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setLimpiarDerivacion: (state, action) => {
      return { ...state, ...derivacionInitialState };
    },
    setColumnsListaDetalleDerivacion: (state, action) => {
      let objDerivada = {
        columnsListaDetalle: action.payload,
      };
      return { ...state, ...objDerivada };
    },
    limpiarFormularioDerivacion: (state, action) => {
      let objDerivada = {
        listaDerivacionDetalle: [],
        listaDerivacionDetalleGrid: [],
        objDetalleDerivacion: {
          Llaves: [],
          Resultados: [],
        },
        listaCaracteristicasDerivacionCampo: [],
        nombreDerivacion: '',
        bValidNombreDerivacion: null,
        selectLlavesDerivacion: [],
        bValidLlavesDerivacion: null,
        selectCamposDerivacion: [],
        bValidCamposDerivacion: null,
        columnsListaDetalle: [],
      };
      return { ...state, ...objDerivada };
    },
    limpiarListaDetalleDerivacion: (state, action) => {
      let objDerivada = {
        listaDerivacionDetalle: [],
        listaDerivacionDetalleGrid: [],
      };
      return { ...state, ...objDerivada };
    },
    changeValorFilaDetalleDerivacion: (state, action) => {
      let estadoInspeccionado = current(state);

      let oDerivadaLista = [...estadoInspeccionado.listaDerivacionDetalle]; // Copia superficial del array
      let oDerivadaGrid = [...estadoInspeccionado.listaDerivacionDetalleGrid]; // Copia superficial del array

      let findGrid = oDerivadaGrid.findIndex((r) => r.idDetalleDerivacion === action.payload.id);
      let findLista = oDerivadaLista.findIndex((r) => r.idDetalleDerivacion === action.payload.id);

      // Asegúrate de que no estás mutando directamente
      oDerivadaGrid[findGrid] = {
        ...oDerivadaGrid[findGrid],
        [action.payload.label]: action.payload.value,
      };

      let findObjeto = oDerivadaLista[findLista][action.payload.tipoDato].findIndex(
        (r) => r.Variable === action.payload.label
      );
      oDerivadaLista[findLista] = {
        ...oDerivadaLista[findLista],
        [action.payload.tipoDato]: oDerivadaLista[findLista][action.payload.tipoDato].map(
          (item, index) => (index === findObjeto ? { ...item, Valor: action.payload.value } : item)
        ),
      };
      let obj = {
        listaDerivacionDetalle: oDerivadaLista,
        listaDerivacionDetalleGrid: oDerivadaGrid,
      };
      return { ...estadoInspeccionado, ...obj };
    },

    deleteFilaDetalleDerivacion: (state, action) => {
      let estadoInspeccionado = current(state);

      let oDerivadaLista = estadoInspeccionado.listaDerivacionDetalle.filter(
        (r) => r.idDetalleDerivacion != action.payload.id
      );
      let oDerivadaGrid = estadoInspeccionado.listaDerivacionDetalleGrid.filter(
        (r) => r.idDetalleDerivacion != action.payload.id
      );

      let obj = {
        listaDerivacionDetalle: oDerivadaLista,
        listaDerivacionDetalleGrid: oDerivadaGrid,
      };
      return { ...estadoInspeccionado, ...obj };
    },
  },
});
export const {
  filterListarDerivaciones,
  setListarDerivacion,
  setDatosDetalleDerivacion,
  setLimpiarDerivacion,
  setListaCaracteristicasDerivacion,
  setColumnsListaDetalleDerivacion,
  limpiarFormularioDerivacion,
  limpiarListaDetalleDerivacion,
  changeValorFilaDetalleDerivacion,
  deleteFilaDetalleDerivacion,
} = derivacionSlice.actions;
export default derivacionSlice.reducer;
