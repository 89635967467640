import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  onChangeVersionFiltroDetalleFlujoCaja,
  onLimpiarCombinacionSolucuionesFlujoCaja,
  onLimpiarFiltroDetalleFlujoCaja,
  onOpenModalCancelarCrearFlujoCaja,
  onOpenModalFiltrosDetalleFlujoCaja,
  onSetFiltroDetalleFlujoCaja,
  onSetOptimizador,
  onSetTabProceso,
  updateIdSelectListaVersionFlujoCaja,
} from '../../../../../../redux/states';
import {
  Button,
  LabelForm,
  MultiSelectCheckboxes,
  InputTextAutocompleteNewUpdDel,
  LoadingSpinner,
  SelectForm,
  ButtonForm,
} from '../../../../../../components';
import { useFetchAndLoad } from '../../../../../../common/hooks';
import {
  eliminarVersionFiltroFC,
  guardarVersionFiltroFC,
  modificarVersionFiltroFC,
  obtenerDetalleVersionFiltrosFC,
  obtenerInfoFiltrosFlujoCajaCaracteristica,
  obtenerInfoFiltrosFlujoCajaValores,
  obtenerVersionesFiltrosFCxUsuario,
  validarVersionesFiltrosFCxUsuario,
} from '../../../../../../services';
import Swal from 'sweetalert2';
import { compareJson, theme } from '../../../../../../common';
import { ColForm, RowForm } from '../../../../../../styles';
import { Divider } from 'antd';
import { filtroCaracReporteAdapter, filtroReporteAdapter } from '../../../../../../adapters';
import { forEach } from 'lodash';

export const ModalFiltros = (props) => {
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const flujoCajaState = useSelector((store) => store.flujoCaja);

  const onConfirmarEliminar = async (e) => {
    dispatch(onOpenModalCancelarCrearFlujoCaja(false));
  };

  const applyFilters = (limpiarOptimizacion) => {
    const selectedFilters = {
      posiciones: [],
      caracteristicas: [],
      valores: [],
    };
    let validPosicion = true;
    let validCaracteristica = true;
    let validValores = true;

    props.selectionFiltrosModel.forEach((element) => {
      element.selectPosicion.forEach((element2) => {
        if (element2.value !== '*') {
          selectedFilters.posiciones.push(element2.IdPosicion);
        }
      });
      if (element.selectPosicion.length === 0) {
        validPosicion = false;
      }
      element.selectCaracteristica.forEach((element2) => {
        selectedFilters.caracteristicas.push(element2);
      });
      if (element.selectCaracteristica.length === 0) {
        validCaracteristica = false;
      }
      element.selectValor.forEach((element2) => {
        if (element2.value !== '*') {
          selectedFilters.valores.push(element2);
        }
      });
      if (element.selectValor.length === 0) {
        validValores = false;
      }
    });
    const { posiciones, valores, caracteristicas } = selectedFilters;
    if (
      !(validPosicion || validCaracteristica || validValores) &&
      props.selectionFiltrosModel.length > 0
    ) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        // title: "No se encuentran Filtros aplicados.",
        title: 'Se debe llenar todos los filtros.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    } else {
      if (limpiarOptimizacion) {
        dispatch(onSetOptimizador({ idOptimiacion: null }));
        dispatch(onSetTabProceso({ tabProceso: 0 }));
        dispatch(
          onLimpiarCombinacionSolucuionesFlujoCaja({
            selectListaSoluciones: [],
            selectListaAgrupadaXSoluciones: [],
          })
        );
      }
      dispatch(onOpenModalFiltrosDetalleFlujoCaja(false));
      dispatch(
        onSetFiltroDetalleFlujoCaja({ aFiltrosDetalleFlujoCaja: props.selectionFiltrosModel })
      );
    }
  };

  const validFiltrosRows = () => {
    let validFiltros = true;
    if (props.selectionFiltrosModel.length === 0) {
      validFiltros = false;
    }
    for (let index = 0; index < props.selectionFiltrosModel.length; index++) {
      const element = props.selectionFiltrosModel[index];
      if (
        element.selectPosicion.length === 0 ||
        element.selectCaracteristica.length === 0 ||
        element.selectValor.length === 0
      ) {
        validFiltros = false;
        break;
      }
    }
    return validFiltros;
  };

  const onAplicarFiltros = async (e, props) => {
    // dispatch(onOpenModalCancelarCrearFlujoCaja(false));

    if (!validFiltrosRows()) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Se debe llenar todos los filtros.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }

    let cambios = false;
    if (props.selectionFiltrosModel.length != flujoCajaState.aFiltrosDetalleFlujoCaja.length) {
      cambios = true;
    } else {
      for (let index = 0; index < flujoCajaState.aFiltrosDetalleFlujoCaja.length; index++) {
        const compareElement1 = props.selectionFiltrosModel[index];
        const compareElement2 = flujoCajaState.aFiltrosDetalleFlujoCaja[index];
        let igualdad = compareJson(compareElement1, compareElement2);
        if (!igualdad) {
          cambios = true;
          break;
        }
      }
    }
    if (
      cambios &&
      flujoCajaState.idOptimiacion != null &&
      flujoCajaState.aListaSoluciones.length > 0
    ) {
      Swal.fire({
        title:
          'Se han realizado cambios , deberá volver a ejecutar el optimizador. ¿Está seguro de aplicarlos?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          applyFilters(true);
        }
      });
    } else {
      applyFilters(false);
    }
  };

  const onCloseModal = (e) => {
    dispatch(onOpenModalFiltrosDetalleFlujoCaja(false));
  };

  const eliminarFiltro = (oEvent, item, index) => {
    var oParam = { item: item, index: index };
    let selectionFiltrosModelRows = [...props.selectionFiltrosModel];
    selectionFiltrosModelRows.splice(index, 1);
    props.setSelectionFiltrosModel(selectionFiltrosModelRows);
    // dispatch(onDeleteFiltroDetalleFlujoCaja(oParam));
  };

  const onLimpiarFiltros = (oEvent) => {
    // props.onRealizarDesdoblesAutomatico([...props.finalArraySinFiltro]);
    // props.realizarRecalculoSubTotal_Total([...props.finalArraySinFiltro])
    if (flujoCajaState.idOptimiacion != null && flujoCajaState.aListaSoluciones.length > 0) {
      Swal.fire({
        title:
          'Se han realizado cambios , deberá volver a ejecutar el optimizador. ¿Está seguro de limpiar todos los filtros?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          applyClearFilter(true);
        }
      });
    } else {
      applyClearFilter(false);
    }
  };
  const applyClearFilter = (limpiarOptimizacion) => {
    if (limpiarOptimizacion) {
      dispatch(onSetOptimizador({ idOptimiacion: null }));
      dispatch(onSetTabProceso({ tabProceso: 0 }));
      dispatch(
        onLimpiarCombinacionSolucuionesFlujoCaja({
          selectListaSoluciones: [],
          selectListaAgrupadaXSoluciones: [],
        })
      );
    }
    props.setSelectionFiltrosModel([]);
    dispatch(onLimpiarFiltroDetalleFlujoCaja());
    dispatch(onOpenModalFiltrosDetalleFlujoCaja(false));
  };

  const agregarFiltro = (oEvent) => {
    let listaPosicion = [];
    flujoCajaState.listFiltroCaract.map((r) => {
      listaPosicion.push(filtroReporteAdapter(r));
    });
    var oParam = {
      // iIdPosicion: 0,
      // sPosicion: "",
      selectPosicion: [],
      listaPosicion: listaPosicion,
      // listaPosicion1: listaPosicion1,
      // iIdCaracteristica: 0,
      // sCaracteristica: "",
      selectCaracteristica: [],
      listaCaracteristica: [],
      // listaValor: [
      //   {
      //     iIdValor: 0,
      //     sValor: "",
      //   },
      // ],
      selectValor: [],
      listaValor: [],
      personalizada: 0,

      // listaValorPlano: [],
    };
    // dispatch(onAddFiltroDetalleFlujoCaja(oParam));
    let selectionFiltrosModelRows = [...props.selectionFiltrosModel];
    selectionFiltrosModelRows = [...selectionFiltrosModelRows, oParam];
    props.setSelectionFiltrosModel(selectionFiltrosModelRows);
  };

  const handleChangePosicionMultiCheckbox = async (value, oEvent, index) => {
    let indexRow = index;
    let selectPosicion = [];
    let aIDPosicion = [];
    let options = props.selectionFiltrosModel[index].listaPosicion;
    const selectionFiltrosModel = [...props.selectionFiltrosModel];
    if (value != null) {
      selectPosicion = value; //result;
    }

    var oParam = { selectPosicion: selectPosicion, index: index };
    if (oEvent.action === 'select-option' && oEvent.option.value === '*') {
      oParam.selectPosicion = [initiallAllPosition, ...options];
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option' && oEvent.option.value === '*') {
      oParam.selectPosicion = [];
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option') {
      oParam.selectPosicion = selectPosicion.filter((o) => o.value !== '*');
      //  dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else if (value.length === options.length - 1) {
      oParam.selectPosicion = options;
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else {
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    }
    if (oParam.selectPosicion.length > 0) {
      selectionFiltrosModel.map((item, posi) => {
        if (posi === index) {
          item.selectPosicion = oParam.selectPosicion;
        }
        return { ...item };
      });
      oParam.selectPosicion
        .filter((o) => o.value !== '*')
        .forEach((element) => {
          aIDPosicion.push(element.value);
        });

      let datosCarac = [];
      let datoAdap = [];
      for (let index = 0; index < aIDPosicion.length; index++) {
        flujoCajaState.listFiltroCaract.map((r) => {
          if (r.IDPosicion === aIDPosicion[index]) {
            datosCarac = [...datosCarac, ...r.Items];
          }
        });
      }
      if (aIDPosicion.length > 1) {
        let datoAdapter = [];
        datosCarac.forEach((r) => {
          datoAdapter.push(filtroCaracReporteAdapter(r));
        });

        const busqueda = datoAdapter.reduce((acc, r) => {
          acc[r.NombreCampo] = ++acc[r.NombreCampo] || 0;
          return acc;
        }, {});

        const duplicados = datoAdapter.filter((r) => {
          return busqueda[r.NombreCampo];
        });

        duplicados.forEach((r) => {
          if (
            datoAdap.filter((da) => da.NombreCampo === r.NombreCampo).length < 1 &&
            duplicados.filter((du) => du.NombreCampo === r.NombreCampo).length ===
              aIDPosicion.length
          ) {
            datoAdap.push(r);
          }
        });
      } else {
        datosCarac.map((r) => {
          datoAdap.push(filtroCaracReporteAdapter(r));
        });
      }

      selectionFiltrosModel.map((item, index) => {
        if (index === indexRow) {
          item.listaCaracteristica = [...datoAdap];
          item.listaValor = [];
          item.selectValor = [];
          item.selectCaracteristica = [];
        }
        return { ...item };
      });
      props.setSelectionFiltrosModel(selectionFiltrosModel);
    } else {
      selectionFiltrosModel.map((item, index) => {
        if (index === indexRow) {
          item.listaValor = [];
          item.selectValor = [];
          item.selectCaracteristica = [];
          item.selectPosicion = [];
        }
        return { ...item };
      });
      props.setSelectionFiltrosModel(selectionFiltrosModel);
    }
  };

  const handleChangeCaracteristica = async (index, oEvent) => {
    let selectCaracteristica = null;
    let aNombreCarac = [];
    let indexRow = index;
    const selectionFiltrosModel = [...props.selectionFiltrosModel];
    if (oEvent != null) {
      selectCaracteristica = oEvent;
    }
    if (selectCaracteristica) {
      var oParam = { selectCaracteristica: [selectCaracteristica], index: index };
      selectionFiltrosModel.map((item, index) => {
        if (index === oParam.index) {
          item.selectCaracteristica = oParam.selectCaracteristica;
        }
        return { ...item };
      });

      aNombreCarac.push(selectCaracteristica.NombreCampo);

      let listaCaracValores = [];
      props.selectionFiltrosModel[index].selectPosicion
        .filter((o) => o.value !== '*')
        .forEach((element) => {
          element.Items.map((r) => {
            if (r.sNombreCampo === selectCaracteristica.NombreCampo) {
              listaCaracValores.push(r);
            }
          });
        });

      const opt = [];
      listaCaracValores.forEach((element) => {
        element.Items.forEach((el) => {
          if (opt.filter((r) => r.value === el.sValor).length < 1) {
            opt.push({
              label: el.sValor,
              value: el.sValor,
              aIdPosicion: [element.IDPosicion],
            });
          } else {
            opt.map((op) => {
              if (op.value === el.sValor) {
                op.aIdPosicion = [...op.aIdPosicion, element.IDPosicion];
              }
            });
          }
        });
      });

      var oParam3 = { listaValor: opt, index: index };
      selectionFiltrosModel.map((item, index) => {
        if (index === oParam3.index) {
          item.listaValor = oParam3.listaValor;
          item.selectValor = [];
        }
        return { ...item };
      });

      props.setSelectionFiltrosModel(selectionFiltrosModel);
    } else {
      selectionFiltrosModel.map((item, index) => {
        if (index === indexRow) {
          item.listaValor = [];
          item.selectValor = [];
          item.selectCaracteristica = [];
        }
        return { ...item };
      });
      props.setSelectionFiltrosModel(selectionFiltrosModel);
    }
  };
  const handleChangeValorMultiCheckbox = async (value, oEvent, index) => {
    let selectValor = [];
    let aIDPosicion = [];

    let options = props.selectionFiltrosModel[index].listaValor;
    const selectionFiltrosModel = [...props.selectionFiltrosModel];
    if (value != null) {
      selectValor = value; //result;
    }
    var oParam = { selectValor: selectValor, index: index };
    if (oEvent.action === 'select-option' && oEvent.option.value === '*') {
      // this.setState(this.options);
      oParam.selectValor = [initiallAllPosition, ...options];
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option' && oEvent.option.value === '*') {
      // this.setState([]);
      oParam.selectValor = [];
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option') {
      oParam.selectValor = selectValor.filter((o) => o.value !== '*');
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (value.length === options.length - 1) {
      oParam.selectValor = value; //options
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else {
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    }
    //
    selectionFiltrosModel.map((item, index) => {
      if (index === oParam.index) {
        item.selectValor = oParam.selectValor;
      }
      return { ...item };
    });
    props.setSelectionFiltrosModel(selectionFiltrosModel);

    if (props.selectionFiltrosModel[index].personalizada === 1) {
      props.setSelectValorPersonaliza1(oParam.selectValor);
    }
    if (props.selectionFiltrosModel[index].personalizada === 2) {
      props.setSelectValorPersonaliza2(oParam.selectValor);
    }
  };

  const initiallAllPosition = {
    IdPosicion: null,
    value: '*',
    CodigoPosicion: '',
    NombrePosicion: '',
    label: 'Todos',
    IDConcepto: null,
    NombreConcepto: '',
    TipoConcepto: '',
  };

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === '*')) {
      return `${placeholderButtonLabel}: Todos`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  const onChangeInputVersion = (oEvent) => {
    let oParam = {
      selectVersionFiltroDetalleFlujoCaja: [],
    };
    if (oEvent) {
      oParam.selectVersionFiltroDetalleFlujoCaja = [
        {
          id: null,
          nombre: oEvent,
        },
      ];
    }

    dispatch(onChangeVersionFiltroDetalleFlujoCaja(oParam));
  };

  const onSelectVersionFlujoCaja = async (oSelect) => {
    let oParam = {
      selectVersionFiltroDetalleFlujoCaja: [],
    };

    if (oSelect) {
      oParam.selectVersionFiltroDetalleFlujoCaja = [oSelect];
    }
    if (oSelect.id != null) {
      //consultar Servicio
      const responseListarEtiquetas = await callEndpoint(
        obtenerDetalleVersionFiltrosFC({
          oVersionFiltro: {
            ID: oSelect.id,
          },
        })
      );

      if (responseListarEtiquetas.data) {
        // oParam.selectVersionFiltroDetalleFlujoCaja = responseListarEtiquetas.data.aFiltrosDetalleFlujoCaja;
        props.setSelectionFiltrosModel(
          responseListarEtiquetas.data.respuesta.aFiltrosDetalleFlujoCaja
        );
      } else {
        // props.setSelectionFiltrosModel([]);
      }
    } else {
      // props.setSelectionFiltrosModel([]);
    }

    dispatch(onChangeVersionFiltroDetalleFlujoCaja(oParam));
  };

  const validarCampos = () => {
    let valid = true;
    if (
      flujoCajaState.selectVersionFiltroDetalleFlujoCaja.length === 0 ||
      props.selectionFiltrosModel.length === 0
    ) {
      valid = false;
    }
    return valid;
  };

  const onOpenGuardarVersion = async (oEvent, finalArrayEliminar) => {
    var bValidForm = validFiltrosRows(); //validarCampos();
    if (bValidForm) {
      var updateVersion = true;
      var validado = true;
      if (flujoCajaState.selectVersionFiltroDetalleFlujoCaja.length === 0) {
        updateVersion = false;
      } else {
        if (flujoCajaState.selectVersionFiltroDetalleFlujoCaja[0].id === null) {
          updateVersion = false;
        } else {
          validado = await onValidarDeleteVersion(
            flujoCajaState.selectVersionFiltroDetalleFlujoCaja[0]
          );
        }
      }

      if (validado) {
        Swal.fire({
          title: !updateVersion ? '¿Desea guardar la versión?' : '¿Desea sobreescribir la versión?',
          html: `Desea compartir la versión?:  <input id="compartido"  style="width:18px;height:18px" type='checkbox' ${
            flujoCajaState.selectVersionFiltroDetalleFlujoCaja[0].compartido === 1 ? 'checked' : ''
          }/>`,
          preConfirm: () => {
            var compartido;
            if (Swal.getPopup().querySelector('#compartido:checked')) {
              compartido = 1;
            } else compartido = 0;

            return { compartido: compartido };
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: theme.colors.primary,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (!updateVersion) {
              // return;

              var oParam = {
                Compartido: result.value.compartido,
                NombreVersion: flujoCajaState.selectVersionFiltroDetalleFlujoCaja[0].nombre,
                aFiltrosDetalleFlujoCaja: props.selectionFiltrosModel,
              };

              var responseCrearVersionFiltroFlujoCaja = await callEndpoint(
                guardarVersionFiltroFC(oParam)
              );
              if (responseCrearVersionFiltroFlujoCaja === null) {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Ocurrió un error en el servicio.',
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                if (responseCrearVersionFiltroFlujoCaja.data.cabecera.statusCode === 200) {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: responseCrearVersionFiltroFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  dispatch(
                    updateIdSelectListaVersionFlujoCaja({
                      id: responseCrearVersionFiltroFlujoCaja.data.respuesta.ID,
                    })
                  );
                  await props.loadFiltrosVersionFCXUsuario();
                } else {
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: responseCrearVersionFiltroFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                }
              }
            } else {
              var oParamUpdateVersionFiltroFlujoCaja = {
                //IDVersion: flujoCajaState.selectVersionFiltroDetalleFlujoCaja[0].id,
                Compartido: result.value.compartido,
                oVersionFiltro: {
                  ID: flujoCajaState.selectVersionFiltroDetalleFlujoCaja[0].id,
                },
                aFiltrosDetalleFlujoCaja: props.selectionFiltrosModel,
              };
              var responseUpdateVersionFlujoCaja = await callEndpoint(
                modificarVersionFiltroFC(oParamUpdateVersionFiltroFlujoCaja)
              );
              if (responseUpdateVersionFlujoCaja === null) {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Ocurrió un error en el servicio.',
                  showConfirmButton: false,
                  timer: 2000,
                });
                await props.loadFiltrosVersionFCXUsuario();
              } else {
                if (responseUpdateVersionFlujoCaja.data.cabecera.statusCode === 200) {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: responseUpdateVersionFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  await props.loadFiltrosVersionFCXUsuario();
                } else {
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: responseUpdateVersionFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                }
              }
            }
          }
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Esta version no se puede editar',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Por favor, debe llenar los filtros y asignar un nombre.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const onConfirmDeleteVersion = async (oEliminar) => {
    var respEliminarVersionFiltroFCXUsuario = await callEndpoint(
      eliminarVersionFiltroFC({
        IDVersion: oEliminar.id,
      })
    );
    props.setSelectionFiltrosModel([]);
    await props.loadFiltrosVersionFCXUsuario();
  };

  const onValidarDeleteVersion = async (oEliminar) => {
    var validacion = await callEndpoint(
      validarVersionesFiltrosFCxUsuario({
        oVersionFiltro: {
          ID: oEliminar.id,
        },
      })
    );
    return validacion.data.respuesta.validador;
  };

  const onOpenDeleteVersion = async (item) => {
    let oEliminar = item;
    const validado = await onValidarDeleteVersion(oEliminar);
    if (validado) {
      Swal.fire({
        title: 'Desea eliminar la version seleccionada',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await onConfirmDeleteVersion(oEliminar);
        }
      });
    } else {
      Swal.fire({
        title: 'No se puede eliminar una versión que es compartida contigo',
        icon: 'warning',
        confirmButtonColor: theme.colors.primary,
        confirmButtonText: 'Confirmar',
      });
    }
  };

  return (
    <ModalComponent
      backdrop="static"
      keyboard={false}
      show={flujoCajaState.modalOpenFiltrosDetalleFlujoCaja}
      onHide={onCloseModal}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Filtros</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <RowForm>
          <LabelForm width={'80px'} title={'Versión :'}></LabelForm>
          <InputTextAutocompleteNewUpdDel
            width={'200px'}
            data={flujoCajaState.listaVersionFiltroDetalleFlujoCaja}
            label="languages"
            pholder="Buscar Versión..."
            onSelected={(evnt) => onSelectVersionFlujoCaja(evnt)}
            value={
              flujoCajaState.selectVersionFiltroDetalleFlujoCaja.length === 0
                ? ''
                : flujoCajaState.selectVersionFiltroDetalleFlujoCaja[0].nombre
            }
            oValue={flujoCajaState.selectVersionFiltroDetalleFlujoCaja}
            text_align={'start'}
            onChange={(evnt) => onChangeInputVersion(evnt)}
            onDelete={onOpenDeleteVersion}
          />
          <ButtonForm
            style={{ marginLeft: '2px' }}
            action={(e) => onOpenGuardarVersion(e, props)}
            name="Guardar versión"
            isAction={true}
            typeColor="primary"
          />
          <ButtonForm
            style={{ marginLeft: 'auto' }}
            action={agregarFiltro}
            name="Agregar"
            isAction={true}
            typeColor="primary"
          />
        </RowForm>
        <Divider className="my-2" />
        {props.selectionFiltrosModel.length === 0 ? (
          <RowForm>
            <LabelForm width={'200px'} title={'No se han agregado Filtros'}>
              No se han agregado Filtros
            </LabelForm>{' '}
          </RowForm>
        ) : (
          props.selectionFiltrosModel.map((item, index) => (
            <>
              <RowForm>
                <ColForm xs={24} sm={12} md={12} lg={7} xl={7}>
                  <RowForm>
                    <LabelForm width={'60px'} title={'Posiciones:'}></LabelForm>
                    <MultiSelectCheckboxes
                      options={[initiallAllPosition, ...item.listaPosicion]}
                      getDropdownButtonLabel={getDropdownButtonLabel}
                      selectedOptions={item.selectPosicion}
                      onChange={(value, oEvent) =>
                        handleChangePosicionMultiCheckbox(value, oEvent, index)
                      }
                      placeholderButtonLabel={'Posiciones'}
                      width={'250px'}
                      disabled={item.personalizada > 0}
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={12} md={12} lg={7} xl={7}>
                  <RowForm>
                    <LabelForm width={'80px'} title={'Característica:'}></LabelForm>
                    <SelectForm
                      placeholder="Seleccione Característica"
                      isClearable={true}
                      isSearchable={true}
                      bValid={true}
                      name="sFuncion"
                      options={item.listaCaracteristica}
                      handleChange={(evnt, v) => handleChangeCaracteristica(index, v)}
                      value={item.selectCaracteristica}
                      isDisabled={item.selectPosicion.length === 0 || item.personalizada > 0}
                      isMulti={false}
                      width={'240px'}
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={12} md={12} lg={7} xl={7}>
                  <RowForm>
                    <MultiSelectCheckboxes
                      options={
                        item.listaValor.length === 0
                          ? []
                          : [initiallAllPosition, ...item.listaValor]
                      }
                      getDropdownButtonLabel={getDropdownButtonLabel}
                      selectedOptions={item.selectValor}
                      onChange={(value, oEvent) =>
                        handleChangeValorMultiCheckbox(value, oEvent, index)
                      }
                      placeholderButtonLabel={'Valores'}
                      width={'250px'}
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={12} md={12} lg={3} xl={3}>
                  <RowForm>
                    <ButtonForm
                      action={(oEvent) => eliminarFiltro(oEvent, item, index)}
                      name="Eliminar"
                      isAction={false}
                      typeColor="warning"
                      disabled={item.personalizada > 0}
                    />
                  </RowForm>
                </ColForm>
              </RowForm>
            </>
          ))
        )}
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <ButtonForm action={onCloseModal} name="Cancelar" isAction={true} typeColor="warning" />
        <ButtonForm action={onLimpiarFiltros} name="Limpiar" isAction={true} typeColor="primary" />
        <ButtonForm
          action={(e) => onAplicarFiltros(e, props)}
          name="Aplicar"
          isAction={true}
          typeColor="secondary"
        />
      </ModalComponent.Footer>
      {loading === true ? <LoadingSpinner /> : <></>}
    </ModalComponent>
  );
};
const ModalComponent = styled(Modal)`
  .modal-dialog {
    max-width: 1200px !important;
  }
`;
