import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonForm,
  Input,
  LabelForm,
  ModalConfirmation,
  SelectForm,
  TableGrid,
} from '../../../../components';
import {
  changeValorFilaDetalleDerivacion,
  deleteFilaDetalleDerivacion,
  limpiarFormularioDerivacion,
  limpiarListaDetalleDerivacion,
  setColumnsListaDetalleDerivacion,
  setDatosDetalleDerivacion,
  setListaCaracteristicasDerivacion,
  updateTitle,
} from '../../../../redux/states';
import { ColForm, ContentForm, RowFlex, RowFooterButton, RowForm } from '../../../../styles';
import { guardarDerivada, listarCamposMJ } from '../../../../services';
import { exportToExcelDerivacion, useFetchAndLoad, paths } from '../../../../common';
import { adapterTableDerivacionDetalle } from '../../../../adapters';
import { useNavigate } from 'react-router-dom';
import { columnAction } from './misc/columns';
import { ModalImport } from './components';
import { v4 as uuidv4 } from 'uuid';
import { read, utils } from 'xlsx';

export const CrearDerivacion = (props) => {
  const derivacionState = useSelector((store) => store.derivacion);
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [openModalImport, setOpenModalImport] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Crear derivación' }));
    onLimpiarDerivacion();
    (async () => {
      await loadCaracteristicas();
    })();
  }, []);

  const loadCaracteristicas = async () => {
    const responseListaCaracteristicas = await callEndpoint(listarCamposMJ());
    var listaCaracteristicas = [];
    for (
      let index = 0;
      index < responseListaCaracteristicas.data.respuesta.aListadoCampos.length;
      index++
    ) {
      const element = responseListaCaracteristicas.data.respuesta.aListadoCampos[index];
      element.value = element.nombreCampo;
      element.label = element.nombreCampo;
      listaCaracteristicas.push(element);
    }
    dispatch(
      setListaCaracteristicasDerivacion({ listaCaracteristicasDerivacion: listaCaracteristicas })
    );
  };

  const onChangeNombreDerivacion = (e) => {
    let oParam = {};
    oParam.bValidNombreDerivacion = null;
    oParam.nombreDerivacion = '';
    if (e) {
      oParam.nombreDerivacion = e.target.value;
      oParam.bValidNombreDerivacion = true;
    }
    dispatch(setDatosDetalleDerivacion(oParam));
  };

  const onChangeLlaveDerivacion = (v) => {
    let oParam = {};
    oParam.bValidLlavesDerivacion = null;
    oParam.selectLlavesDerivacion = [];
    oParam.bValidCamposDerivacion = null;
    oParam.selectCamposDerivacion = [];
    oParam.listaCaracteristicasDerivacionCampo = derivacionState.listaCaracteristicasDerivacion;
    if (v) {
      oParam.bValidLlavesDerivacion = true;
      oParam.selectLlavesDerivacion = v;
      oParam.listaCaracteristicasDerivacionCampo =
        derivacionState.listaCaracteristicasDerivacion.filter(
          (item1) => !v.some((item2) => item1.idCampo === item2.idCampo)
        );
      let columna = [];
      v.map((r) => {
        columna.push(adapterTableDerivacionDetalle(onChangeValorFila, r, 'Llaves'));
      });
      dispatch(setColumnsListaDetalleDerivacion(columna));
    }
    dispatch(setDatosDetalleDerivacion(oParam));
  };

  const onChangeCampoDerivacion = (v) => {
    let oParam = {};
    oParam.bValidCamposDerivacion = null;
    oParam.selectCamposDerivacion = [];
    if (v) {
      oParam.bValidCamposDerivacion = true;
      oParam.selectCamposDerivacion = v;
      let columna = [];
      derivacionState.selectLlavesDerivacion.map((r) => {
        columna.push(adapterTableDerivacionDetalle(onChangeValorFila, r, 'Llaves'));
      });
      v.map((r) => {
        columna.push(adapterTableDerivacionDetalle(onChangeValorFila, r, 'Resultados'));
      });
      dispatch(setColumnsListaDetalleDerivacion(columna));
    }
    dispatch(setDatosDetalleDerivacion(oParam));
  };

  const onAgregarFilaTabla = () => {
    let oParam = {},
      oDerivadaGrid = {},
      oDerivadaLista = {};
    var uuid = uuidv4();

    oDerivadaLista.idDetalleDerivacion = uuid;
    oDerivadaLista.Llaves = [];
    oDerivadaLista.Resultados = [];

    derivacionState.columnsListaDetalle.map((r) =>
      oDerivadaLista[r.tipoDato].push({ Variable: r.field, Valor: '' })
    );

    let listaDetalle = derivacionState.listaDerivacionDetalle;
    oParam.listaDerivacionDetalle = [...listaDetalle, ...[oDerivadaLista]];

    let listaDetalleG = derivacionState.listaDerivacionDetalleGrid;
    oDerivadaGrid.idDetalleDerivacion = uuid;
    oDerivadaLista.Llaves.map((r) => (oDerivadaGrid[r.Variable] = r.Valor));
    oDerivadaLista.Resultados.map((r) => (oDerivadaGrid[r.Variable] = r.Valor));
    oParam.listaDerivacionDetalleGrid = [...listaDetalleG, ...[oDerivadaGrid]];
    dispatch(setDatosDetalleDerivacion(oParam));
  };

  const onChangeValorFila = (e, id, label, tipoDato) => {
    let oParam = {
      value: e.target.value,
      id: id,
      label: label,
      tipoDato: tipoDato,
    };
    dispatch(changeValorFilaDetalleDerivacion(oParam));
  };

  const onLimpiarListaDetalleDerivacion = () => {
    dispatch(limpiarListaDetalleDerivacion());
  };

  const onDeleteColumn = (id) => {
    dispatch(deleteFilaDetalleDerivacion({ id: id }));
  };

  const onOpenModalImport = () => {
    setOpenModalImport(true);
  };

  const onConfirmarImport = (file) => {
    const files = file;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        var wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows3 = utils.sheet_to_json(wb.Sheets['Derivacion'], { raw: true });
          let listaDetalle = [],
            listaDetalleG = [];
          rows3.map((r) => {
            let element = r;
            var uuid = uuidv4();

            element.idDetalleDerivacion = uuid;
            listaDetalleG.push(element);

            let oDerivadaLista = {};
            oDerivadaLista.idDetalleDerivacion = uuid;
            oDerivadaLista.Llaves = [];
            oDerivadaLista.Resultados = [];
            derivacionState.selectLlavesDerivacion.map((rld) => {
              if (r[rld.label]) {
                oDerivadaLista.Llaves.push({ Variable: rld.label, Valor: r[rld.label] });
              }
            });
            derivacionState.selectCamposDerivacion.map((rld) => {
              if (r[rld.label]) {
                oDerivadaLista.Resultados.push({ Variable: rld.label, Valor: r[rld.label] });
              }
            });
            listaDetalle.push(oDerivadaLista);
          });
          let oParam = {
            listaDerivacionDetalle: [...derivacionState.listaDerivacionDetalle, ...listaDetalle],
            listaDerivacionDetalleGrid: [
              ...derivacionState.listaDerivacionDetalleGrid,
              ...listaDetalleG,
            ],
          };
          dispatch(setDatosDetalleDerivacion(oParam));
          onCloseModalImport();
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const onCloseModalImport = () => {
    setOpenModalImport(false);
  };

  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };

  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };

  const onLimpiarDerivacion = () => {
    dispatch(limpiarFormularioDerivacion());
  };

  const onGrabar = async () => {
    let oParam = {};
    oParam.NombreDerivada = derivacionState.nombreDerivacion;
    oParam.Derivacion = derivacionState.listaDerivacionDetalle;
    let responseGuardarDerivada = await callEndpoint(guardarDerivada(oParam));
    onConfirmarModalCancelar();
  };

  const onConfirmarModalCancelar = () => {
    onLimpiarListaDetalleDerivacion();
    onCloseModalCancelar();
    navigate(paths.CONFIGURE.DERIVATION.BASE);
  };

  return (
    <>
      <ContentForm>
        <RowForm>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Nombre (*):'} />
              <Input
                placeholder="Ingrese..."
                value={derivacionState.nombreDerivacion}
                onChange={(evnt) => onChangeNombreDerivacion(evnt)}
                validate={derivacionState.bValidNombreDerivacion}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Llaves (*):'} />
              <SelectForm
                validate={derivacionState.bValidLlavesDerivacion}
                value={derivacionState.selectLlavesDerivacion}
                handleChange={(e, v) => onChangeLlaveDerivacion(v)}
                options={derivacionState.listaCaracteristicasDerivacion}
                isMultiple={true}
                placeholder={'Seleccione Llaves'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Campos (*):'} />
              <SelectForm
                validate={derivacionState.bValidCamposDerivacion}
                value={derivacionState.selectCamposDerivacion}
                handleChange={(e, v) => onChangeCampoDerivacion(v)}
                options={derivacionState.listaCaracteristicasDerivacionCampo}
                isMultiple={true}
                placeholder={'Seleccione Campos'}
                isDisabled={derivacionState.selectLlavesDerivacion.length === 0}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowFlex
              style={{
                alignItems: 'end',
                justifyContent: 'end',
                columnGap: 10,
                marginBottom: 10,
              }}
            >
              <ButtonForm
                action={(e) => onOpenModalImport()}
                name="Importar"
                isAction={true}
                typeColor="primary"
              />

              <ButtonForm
                action={(e) => onAgregarFilaTabla()}
                name="Agregar"
                isAction={true}
                typeColor="primary"
              />
              <ButtonForm
                action={(e) => onLimpiarListaDetalleDerivacion()}
                name="Limpiar Tabla"
                isAction={false}
                typeColor="warning"
              />
            </RowFlex>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowForm>
              <TableGrid
                columns={[...columnAction(onDeleteColumn), ...derivacionState.columnsListaDetalle]}
                rows={derivacionState.listaDerivacionDetalleGrid}
                getRowId={(row) => row.idDetalleDerivacion}
              />
            </RowForm>
          </ColForm>
        </RowForm>
        <RowFooterButton>
          <ButtonForm
            action={(e) => onPressCancelar()}
            name="Cancelar"
            isAction={true}
            typeColor="warning"
          />
          <ButtonForm
            action={(e) => onLimpiarDerivacion(e)}
            name="Limpiar"
            isAction={true}
            typeColor="primary"
          />
          <ButtonForm
            action={(e) => onGrabar(e)}
            name="Grabar"
            isAction={true}
            typeColor="secondary"
          />
        </RowFooterButton>
      </ContentForm>
      <ModalImport
        isOpen={openModalImport}
        loading={false}
        descargarPlantilla={(e) =>
          exportToExcelDerivacion('Derivacion', derivacionState.columnsListaDetalle)
        }
        handleClose={onCloseModalImport}
        handleOk={onConfirmarImport}
      />
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación de la Derivación?"
        action={(e) => onConfirmarModalCancelar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </>
  );
};
