import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  menuSlice,
  subMenuSlice,
  conceptoSlice,
  posicionSlice,
  estructuraSlice,
  flujoCajaSlice,
  navBarSlice,
  funcionesSlice,
  tablaFlujoCajaSlice,
  institucionSlice,
  lineaCreditoSlice,
  authSlice,
  aplazamientoSlice,
  loginSlice,
  usuarioSlice,
  prestamoSlice,
  sistemaSlice,
  inversionSlice,
  solTipoCambioSlice,
  factoringSlice,
  dashboardSlice,
  socioSlice,
  gestionSlice,
  personalizacionSlice,
  tipoCambioSlice,
  automatizacionSlice,
  derivacionSlice,
  perfilSlice,
  registroManualSlice,
  registroFuenteSlice,
  posicionMasterSlice,
  campoSlice,
  rolSlice,
  sociedadSlice,
} from './states';

// const appReducer = combineReducers({
//   auth: authSlice.reducer,
//   perfil: perfilSlice.reducer,
//   menu: menuSlice.reducer,
//   subMenuSlice: subMenuSlice.reducer,
//   campo: campoSlice.reducer,
//   sistema: sistemaSlice.reducer,
//   concepto: conceptoSlice.reducer,
//   posicion: posicionSlice.reducer,
//   estructura: estructuraSlice.reducer,
//   flujoCaja: flujoCajaSlice.reducer,
//   navBar: navBarSlice.reducer,
//   funciones: funcionesSlice.reducer,
//   tablaFlujoCaja: tablaFlujoCajaSlice.reducer,
//   institucion: institucionSlice.reducer,
//   lineaCredito: lineaCreditoSlice.reducer,
//   factoring: factoringSlice.reducer,
//   aplazamiento: aplazamientoSlice.reducer,
//   login: loginSlice.reducer,
//   usuario: usuarioSlice.reducer,
//   prestamo: prestamoSlice.reducer,
//   inversion: inversionSlice.reducer,
//   solTipoCambio: solTipoCambioSlice.reducer,
//   rol: rolSlice.reducer,
//   dashboard: dashboardSlice.reducer,
//   socio: socioSlice.reducer,
//   gestion: gestionSlice.reducer,
//   personalizacion: personalizacionSlice.reducer,
//   tipoCambio: tipoCambioSlice.reducer,
//   automatizacion: automatizacionSlice.reducer,
//   derivacion: derivacionSlice.reducer,
//   registroManual: registroManualSlice.reducer,
//   registroFuente: registroFuenteSlice.reducer,
//   posicionMaster: posicionMasterSlice.reducer,
// });

// const rootReducer = (state, action) => {
//
//   if (action.type === 'auth/onLogout') {
//     state = undefined; // Resetea todo el estado del store al cerrar sesión
//   }
//   return appReducer(state, action);
// };

export const store = configureStore({
  // reducer: rootReducer,
  reducer: {
    auth: authSlice.reducer,
    perfil: perfilSlice.reducer,
    menu: menuSlice.reducer,
    subMenuSlice: subMenuSlice.reducer,
    campo: campoSlice.reducer,
    sistema: sistemaSlice.reducer,
    concepto: conceptoSlice.reducer,
    posicion: posicionSlice.reducer,
    estructura: estructuraSlice.reducer,
    flujoCaja: flujoCajaSlice.reducer,
    navBar: navBarSlice.reducer,
    funciones: funcionesSlice.reducer,
    tablaFlujoCaja: tablaFlujoCajaSlice.reducer,
    institucion: institucionSlice.reducer,
    lineaCredito: lineaCreditoSlice.reducer,
    factoring: factoringSlice.reducer,
    aplazamiento: aplazamientoSlice.reducer,
    login: loginSlice.reducer,
    usuario: usuarioSlice.reducer,
    prestamo: prestamoSlice.reducer,
    inversion: inversionSlice.reducer,
    solTipoCambio: solTipoCambioSlice.reducer,
    rol: rolSlice.reducer,
    dashboard: dashboardSlice.reducer,
    socio: socioSlice.reducer,
    gestion: gestionSlice.reducer,
    personalizacion: personalizacionSlice.reducer,
    tipoCambio: tipoCambioSlice.reducer,
    automatizacion: automatizacionSlice.reducer,
    derivacion: derivacionSlice.reducer,
    registroManual: registroManualSlice.reducer,
    registroFuente: registroFuenteSlice.reducer,
    posicionMaster: posicionMasterSlice.reducer,
    sociedad: sociedadSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
