import React, { useEffect, useState } from 'react';
import {
  SearchInputText,
  ModalConfirmation,
  LoadingSpinner,
  TableGrid,
  ButtonAccion,
  ButtonForm,
} from '../../../../components';
import {
  obtenerSoluciones,
  eliminarSoluciones,
  obtenerInfoSolucion,
  obtenerAgentes,
  obtenerListadoMoneda,
} from '../../../../services';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListaAplazamiento,
  filterListarAplazamiento,
  limpiarEditCamposAP,
  limpiarCamposAP,
} from '../../../../redux/states';
import { updateTitle } from '../../../../redux/states';
import {
  createAgenteSelectAdapter,
  createEstadoSolucionSelectAdapter,
  createMonedaAbreviaturaAdapter,
  createTiempoPeriodoAdapter,
} from '../../../../adapters';
import Swal from 'sweetalert2';
import { buildPath, EstadoSolucionesData, paths } from '../../../../common';
import { TiempoPeriodosData } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import { createAplazamientoAdapter } from '../../../../adapters/createAplazamiento.adapter';
import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  RowAccion,
  RowFlex,
} from '../../../../styles';
import { Dropdown } from 'antd';
import { items } from './misc';
import { FaPenToSquare } from 'react-icons/fa6';

export const ListarAplazamiento = () => {
  const personalizacionState = useSelector((store) => store.personalizacion);
  const aplazamientoState = useSelector((store) => store.aplazamiento);
  const [selectedAplazamiento, setSelectedAplazamiento] = useState([]);
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Listar Aplazamientos' }));
  }, []);

  const loadAplazamiento = async () => {
    const responseListaAplazamiento = await callEndpoint(
      obtenerSoluciones({
        oTipoSolucion: ['AP'],
      })
    );
    var listaAplazamiento = [];
    if (responseListaAplazamiento != null) {
      if (responseListaAplazamiento.data) {
        if (responseListaAplazamiento.data.cabecera.statusCode === 200) {
          for (
            let index = 0;
            index < responseListaAplazamiento.data.respuesta.aListaSolucion.length;
            index++
          ) {
            const element = responseListaAplazamiento.data.respuesta.aListaSolucion[index];
            var obj = createAplazamientoAdapter(element);
            listaAplazamiento.push(obj);
          }
        }
      }
    }
    dispatch(setListaAplazamiento({ listaAplazamiento: listaAplazamiento }));
  };
  useEffect(() => {
    (async () => {
      loadAplazamiento();
    })();
  }, []);
  const findAplazamiento = (person) => {
    return !!selectedAplazamiento.find((p) => p.idAplazamiento === person.idAplazamiento);
  };
  const filterConcepto = (person) => {
    return selectedAplazamiento.filter((p) => p.idAplazamiento !== person.idAplazamiento);
  };
  const handleChange = (person) => {
    const filteredPeople = findAplazamiento(person)
      ? filterConcepto(person)
      : [...selectedAplazamiento, person];
    setSelectedAplazamiento(filteredPeople);
  };
  const onClickEditarConcepto = async (row) => {
    dispatch(limpiarEditCamposAP());
    const responseObtAgentes = await callEndpoint(obtenerAgentes());
    let aAgentes = [];
    if (responseObtAgentes != null) {
      if (responseObtAgentes.data) {
        let { aListaAgentes } = responseObtAgentes.data.respuesta;
        aListaAgentes.forEach((element) => {
          aAgentes.push(createAgenteSelectAdapter(element));
        });
      }
    }
    const responseObtMonedas = await callEndpoint(obtenerListadoMoneda());
    let aMonedas = [];
    if (responseObtMonedas != null) {
      if (responseObtMonedas.data) {
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'texto_moneda'
        )[0].DatoPersonalizacion;
        let { aListaMoneda } = responseObtMonedas.data.respuesta;
        aListaMoneda.forEach((element) => {
          aMonedas.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
        });
      }
    }
    let aEstadoSol = [];
    EstadoSolucionesData.forEach((element) => {
      aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
    });
    let aTiempoPeriodo = [];
    TiempoPeriodosData.forEach((element) => {
      aTiempoPeriodo.push(createTiempoPeriodoAdapter(element));
    });
    const responseInfoSolucion = await callEndpoint(
      obtenerInfoSolucion({
        oSolucion: {
          IDSolucionBase: row.idAplazamiento,
        },
      })
    );

    if (responseInfoSolucion != null) {
      if (responseInfoSolucion.data) {
        var oSolucion = responseInfoSolucion.data.respuesta.aInfoSolucion[0];
        // var oParam = {
        //   idSolucion: row.idAplazamiento,
        //   descripcion: oSolucion.SOL_NOMBRESOLUCION,
        //   bValidDescripcion: true,
        //   selectAgente: [
        //     {
        //       value: oSolucion.SOL_IDAGENTE,
        //       label: oSolucion.SOL_AGENTE,
        //     },
        //   ],
        //   bValidAgente: true,
        //   listaAgente: aAgentes,
        //   dDateRange: [
        //     stringFechaToDate(oSolucion.SOL_PERIODOINICIOP, '.', '-'),
        //     stringFechaToDate(oSolucion.SOL_PERIODOFINP, '.', '-'),
        //   ],
        //   bValidFecha: true,
        //   monto: oSolucion.SOL_IMPORTEINICIAL.toString(),
        //   bValidMonto: true,
        //   cantCuota: oSolucion.SOL_CANTIDADCUOTASP.toString(),
        //   bValidCantCuota: true,
        //   montoDes: oSolucion.SOL_PAGODESEMBOLSO.toString(),
        //   bValidMontoDes: true,
        //   selectMoneda: [
        //     {
        //       value: oSolucion.SOL_MONEDA,
        //       label: oSolucion.SOL_NOMBRE_MONEDA,
        //     },
        //   ],
        //   bValidMoneda: true,
        //   listaMoneda: aMonedas,
        //   peGracia: oSolucion.SOL_DESFASECUOTAP,
        //   bValidPeGracia: true,
        //   bValidTiempoPeGracia: true,
        //   selectTiempoPeGracia: [
        //     {
        //       value: oSolucion.SOL_DESFASECUOTAP_PERIODO,
        //       label: '',
        //     },
        //   ],
        //   peCuotas: oSolucion.SOL_PERIODOSENTREPAGO,
        //   bValidCuotas: true,
        //   bValidTiempoPeCuotas: true,
        //   selectTiempoPeCuotas: [
        //     {
        //       value: oSolucion.SOL_PERIODOSENTREPAGO_PERIODO,
        //       label: '',
        //     },
        //   ],
        //   tea: oSolucion.SOL_TEAP.toString(),
        //   bValidTea: true,
        //   frecMax: oSolucion.SOL_FRECUENCIA.toString(),
        //   bValidFrecMax: true,
        //   bValidTiempoFrecMax: true,
        //   selectTiempoFrecMax: [
        //     {
        //       value: oSolucion.SOL_FRECUENCIA_PERIODO,
        //       label: '',
        //     },
        //   ],
        //   apariciones: oSolucion.SOL_APARICIONES.toString(),
        //   bValidApariciones: true,
        //   minimo: oSolucion.SOL_IMPORTEINICIALMIN.toString(),
        //   bValidMinimo: true,
        //   // maximo : "",
        //   // bValidMaximo : null,
        //   selectEstado: [
        //     {
        //       value: oSolucion.IDESTADO,
        //       label: oSolucion.DESCESTADO,
        //     },
        //   ],
        //   bValidEstado: true,
        //   listaEstado: aEstadoSol,
        //   listaTiempo: aTiempoPeriodo,
        // }
        // if (oParam.selectTiempoPeGracia.length > 0) {
        //   let valor = oParam.selectTiempoPeGracia[0].value
        //   if (valor === 'D') {
        //     oParam.selectTiempoPeGracia[0].label = 'Días'
        //   } else if (valor === 'S') {
        //     oParam.selectTiempoPeGracia[0].label = 'Semana'
        //   } else if (valor === 'M') {
        //     oParam.selectTiempoPeGracia[0].label = 'Mensual'
        //   }
        // }
        // if (oParam.selectTiempoPeCuotas.length > 0) {
        //   let valor = oParam.selectTiempoPeCuotas[0].value
        //   if (valor === 'D') {
        //     oParam.selectTiempoPeCuotas[0].label = 'Días'
        //   } else if (valor === 'S') {
        //     oParam.selectTiempoPeCuotas[0].label = 'Semana'
        //   } else if (valor === 'M') {
        //     oParam.selectTiempoPeCuotas[0].label = 'Mensual'
        //   }
        // }
        // if (oParam.selectTiempoFrecMax.length > 0) {
        //   let valor = oParam.selectTiempoFrecMax[0].value
        //   if (valor === 'D') {
        //     oParam.selectTiempoFrecMax[0].label = 'Días'
        //   } else if (valor === 'S') {
        //     oParam.selectTiempoFrecMax[0].label = 'Semana'
        //   } else if (valor === 'M') {
        //     oParam.selectTiempoFrecMax[0].label = 'Mensual'
        //   }
        // }
        // dispatch(setDataInfoPR(oParam))
        navigate(buildPath(paths.SOLUTION.DELAY.EDIT, { id: row.idAplazamiento }));
      } else {
      }
    }
  };
  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <CheckboxStyle
              size="small"
              checked={findAplazamiento(params.row)}
              onChange={() => handleChange(params.row)}
            />
          }
        </>
      ),
    },
    {
      field: 'codigo',
      headerName: 'Código',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    // {
    //   field: "nombre",
    //   headerName: "Nombre",
    //   flex: 1,
    //   renderCell: (params) => <>{params.value}</>,
    // },
    {
      field: 'descripcion',
      headerName: 'Descripción',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'fechaVigencia',
      headerName: 'Fecha de Vigencia',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            // <ButtonEdit
            //   title="Editar"
            //   tabindex="0"
            //   aria-label="Edit"
            //   onClick={(e) => onClickEditarConcepto(params.row)}
            // >
            //   <svg
            //     width="18"
            //     height="24"
            //     viewBox="0 0 24 24"
            //     fill="none"
            //     stroke="currentColor"
            //     stroke-width="2"
            //     stroke-linecap="round"
            //     stroke-linejoin="round"
            //     class="feather feather-edit-3 "
            //   >
            //     <g>
            //       <polygon points="14 2 18 6 7 17 3 17 3 13 14 2"></polygon>
            //       <line x1="3" y1="22" x2="21" y2="22"></line>
            //     </g>
            //   </svg>
            // </ButtonEdit>
            <ButtonForm
              action={(oEvent) => onClickEditarConcepto(params.row)}
              icon={<FaPenToSquare />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
            />
          }
        </>
      ),
    },
  ];
  const mapColumnsDownloadExcel = {
    sNombreAgrupador: 'Nombre',
    sTipoConcepto: 'Tipo',
    sReal_Proyectado: 'Real/Proyectado',
  };
  const onAgregarConcepto = () => {
    dispatch(limpiarCamposAP());
    navigate(paths.SOLUTION.DELAY.CREATE);
    dispatch(updateTitle({ title: 'Crear Aplazamiento' }));
  };
  const onClickEliminarConcepto = () => {
    if (selectedAplazamiento.length > 0) {
      setShowModalEliminar(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarAplazamiento(oEvent.target.value));
    } else {
      dispatch(filterListarAplazamiento(''));
    }
  };
  const onCloseModalEliminar = () => {
    setShowModalEliminar(false);
  };
  const onConfirmarEliminar = async () => {
    var aListaSolucionEliminar = [];
    selectedAplazamiento.forEach((element) => {
      var o = {};
      o.idSolucion = element.idAplazamiento;
      aListaSolucionEliminar.push(o);
    });
    const responseListaAplazamiento = await callEndpoint(
      eliminarSoluciones({ aListaSolucionEliminar: aListaSolucionEliminar })
    );
    setShowModalEliminar(false);
    if (responseListaAplazamiento === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseListaAplazamiento.data.cabecera.statusCode === 200) {
        loadAplazamiento();
        setSelectedAplazamiento([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseListaAplazamiento.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseListaAplazamiento.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return (
    <ColFlex>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              placeholder={'Buscar aplazamiento...'}
              filter={aplazamientoState.searchtext}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Dropdown trigger={['click']} menu={{ items: items(onClickEliminarConcepto) }}>
                  <ButtonAccion />
                </Dropdown>

                <ButtonForm
                  action={onAgregarConcepto}
                  name="Agregar"
                  isAction={true}
                  typeColor="primary"
                />
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>

        <TableGrid
          columns={columns}
          rows={
            aplazamientoState.searchtext.length === 0
              ? aplazamientoState.listaAplazamiento
              : aplazamientoState.filterListaAplazamiento
          }
          pageSize={15}
          getRowId={(row) => row.idAplazamiento}
        />
      </ColFlex>

      <ModalConfirmation
        showModal={showModalEliminar}
        closeModal={(e) => onCloseModalEliminar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
