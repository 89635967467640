import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  ButtonForm,
  ButtonAccion,
  LoadingSpinner,
  ModalConfirmation,
  SearchInputText,
  TableGrid,
} from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../../../common/hooks';
import {
  filterListarFlujoCajas,
  onLimpiarCamposCrearFlujoCaja,
  onLimpiarCamposGeneralesFlujoCaja,
  onSetFlujoCajas,
  updateTitle,
} from '../../../../redux/states';
import { createFlujoCajaAdapter } from '../../../../adapters/flujoCaja.adapter';
import { eliminarFlujoCajas, obtenerListadoFlujoCaja } from '../../../../services';

import { useNavigate } from 'react-router-dom';
import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  MenuAccion,
  RowAccion,
  RowFlex,
} from '../../../../styles';
import { Dropdown } from 'antd';
import { items } from './misc';
import { buildPath, paths } from '../../../../common';
import { FaEye } from 'react-icons/fa6';
//prueba

export const ListarFlujoCaja = (props) => {
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const [selectedFlujoCaja, setSelectedFlujoCaja] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Lista de Flujo de Caja' }));
    (async () => {
      await loadFlujoCaja();
    })();
  }, []);

  const loadFlujoCaja = async () => {
    const responseListaFlujoCajas = await callEndpoint(obtenerListadoFlujoCaja());
    var aListaFlujo = [];
    if (responseListaFlujoCajas != null) {
      if (responseListaFlujoCajas.data) {
        if (responseListaFlujoCajas.data.cabecera.statusCode === 200) {
          for (
            let index = 0;
            index < responseListaFlujoCajas.data.respuesta.aListaFlujo.length;
            index++
          ) {
            const element = responseListaFlujoCajas.data.respuesta.aListaFlujo[index];
            var obj = createFlujoCajaAdapter(element);
            aListaFlujo.push(obj);
          }
        }
      }
    }
    dispatch(onSetFlujoCajas(aListaFlujo));
  };

  const findFlujoCaja = (person) => {
    return !!selectedFlujoCaja.find((p) => p.IDFlujoGeneral === person.IDFlujoGeneral);
  };

  const filterFlujoCaja = (person) => {
    return selectedFlujoCaja.filter((p) => p.IDFlujoGeneral !== person.IDFlujoGeneral);
  };

  const handleChange = (person) => {
    const filteredPeople = findFlujoCaja(person)
      ? filterFlujoCaja(person)
      : [...selectedFlujoCaja, person];
    setSelectedFlujoCaja(filteredPeople);
  };

  const onClickEditarFlujoCaja = async (e, row) => {
    dispatch(onLimpiarCamposGeneralesFlujoCaja());
    navigate(buildPath(paths.CASH_FLOW.REPORT.VIEW, { id: row.IDFlujoGeneral }));
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <CheckboxStyle
          size="small"
          checked={findFlujoCaja(params.row)}
          onChange={() => handleChange(params.row)}
        />
      ),
    },
    {
      field: 'CodigoFlujoCaja',
      headerName: 'Código',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'NombreFlujoGeneral',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'DetalleFlujoGeneral',
      headerName: 'Detalle',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'FechaOptimizacion',
      headerName: 'Fecha OPT',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'NombreVersion',
      headerName: 'Versión',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'NombreEstructura',
      headerName: 'Estructura',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <ButtonForm
          action={(oEvent) => onClickEditarFlujoCaja(oEvent, params.row)}
          icon={<FaEye />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="primary"
        />
      ),
    },
  ];

  const onAgregarFlujoCaja = () => {
    dispatch(onLimpiarCamposCrearFlujoCaja());
    dispatch(onLimpiarCamposGeneralesFlujoCaja());

    navigate(paths.CASH_FLOW.REPORT.CREATE);
  };

  const onClickEliminarFlujoCaja = () => {
    if (selectedFlujoCaja.length > 0) {
      setShowModalEliminar(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarFlujoCajas(oEvent.target.value));
    } else {
      dispatch(filterListarFlujoCajas(''));
    }
  };

  const onCloseModalEliminar = () => {
    setShowModalEliminar(false);
  };

  const onConfirmarEliminar = async () => {
    var aListaFlujoCajasEliminar = [];
    selectedFlujoCaja.forEach((element) => {
      var o = {};
      o.IDFlujoCajaGeneral = element.IDFlujoGeneral;
      aListaFlujoCajasEliminar.push(o);
    });
    const responseListaConceptos = await callEndpoint(
      eliminarFlujoCajas({ aListaFlujoCajasEliminar: aListaFlujoCajasEliminar })
    );
    setShowModalEliminar(false);
    if (responseListaConceptos === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseListaConceptos.data.cabecera.statusCode === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseListaConceptos.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
        await loadFlujoCaja();
        setSelectedFlujoCaja([]);
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseListaConceptos.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return (
    <ColFlex>
      <ContentOption>
        <RowFlex>
          <SearchInputText
            placeholder={'Buscar flujos de cajas...'}
            filter={flujoCajaState.searchtext}
            onChange={(e) => onChangeSearch(e)}
          />
          <ContentButton>
            <RowAccion>
              <ColFlex>
                <Dropdown trigger={['click']} menu={{ items: items(onClickEliminarFlujoCaja) }}>
                  <ButtonAccion />
                </Dropdown>
              </ColFlex>
              <ButtonForm
                action={onAgregarFlujoCaja}
                name="Agregar"
                isAction={true}
                typeColor="primary"
              />
            </RowAccion>
          </ContentButton>
        </RowFlex>
      </ContentOption>
      {
        <TableGrid
          columns={columns}
          rows={
            flujoCajaState.searchtext.length === 0
              ? flujoCajaState.listaFlujoCajas
              : flujoCajaState.filterListaFlujoCajas
          }
          pageSize={15}
          getRowId={(row) => row.IDFlujoGeneral}
        />
      }
      <ModalConfirmation
        showModal={showModalEliminar}
        closeModal={(e) => onCloseModalEliminar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
