import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const usuarioInitialState = {
  idUsuario: 0,
  nombres: '',
  apellidos: '',
  email: '',
  bValidEmail: null,
  listaSociedad: [],
  sociedad: [],
  username: '',
  bValidUsername: null,
  bValidPasswordChange: true,
  oldPassword: '',
  bValidOldPassword: null,
  password: '',
  bValidPassword: null,
  passwordConfirm: '',
  bValidPasswordMust: [null, null, null, null, null],
  bValidPasswordConfirm: null,
  foto: [],
  editFoto: 0,

  listaUsuario: [],
  searchtextHomologacion: '',
  filterListaUsuario: [],
  usuarioRolId: null,
  usuarioRolNombre: '',
  bValidRol: null,
  selectRol: [],
  listaUsuarioRol: [],
  listaUsuarioRolDel: [],
};
export const usuarioSlice = createSlice({
  name: 'usuario',
  initialState: usuarioInitialState,
  reducers: {
    changeSearchUsuario: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaUsuario = estadoInspeccionado.listaUsuario.filter(function (item, index) {
        return (
          item.Usuario.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.Correo.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaUsuario: filterListaUsuario,
        searchtextHomologacion: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setListarUsuarioRol: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaUsuarioRol: action.payload,
      };

      return { ...estadoInspeccionado, ...obj };
    },

    setListarUsuario: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaUsuario: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setCleanModalUsuario: (state, action) => {
      var obj = {
        idUsuario: 0,
        email: '',
        bValidEmail: null,
        username: '',
        bValidUsername: null,
        bValidPasswordChange: true,
        oldPassword: '',
        bValidOldPassword: null,
        password: '',
        bValidPassword: null,
        passwordConfirm: '',
        bValidPasswordConfirm: null,
        foto: [],
        editFoto: 0,
      };
      return { ...state, ...obj };
    },
    setCleanModalRol: (state, action) => {
      var obj = {
        usuarioRolId: null,
        usuarioRolNombre: '',
        bValidRol: null,
        selectRol: [],
        listaRol: [],
        listaUsuarioRol: [],
        listaUsuarioRolDel: [],
      };
      return { ...state, ...obj };
    },
    setSelectRol: (state, action) => {
      var obj = {
        selectRol: action.payload.selectRol,
        bValidRol: action.payload.bValidRol,
      };

      return { ...state, ...obj };
    },
    onAddRowRolUsuario: (state, action) => {
      return {
        ...state,
        listaUsuarioRol: state.listaUsuarioRol.concat(action.payload.newItem),
        selectRol: [],
      };
    },
    onDeleteRowUsuario: (state, action) => {
      // state.listTypeSystemService.splice(action.payload.index, 1);
      let listaTipoHomologados = [];
      let listaDel = [];
      state.listaUsuarioRol.map(function (item, index) {
        if (item.usuarioRolId !== action.payload.index) {
          listaTipoHomologados.push(item);
        } else {
          listaDel.push(item);
        }
      });
      return {
        ...state,
        listaUsuarioRol: listaTipoHomologados,
        listaUsuarioRolDel: listaDel,
      };
    },
    onSetModalDatos: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetModalUsuario: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const {
  changeSearchUsuario,
  setListarUsuario,
  setListarUsuarioRol,
  setCleanModalRol,
  setCleanModalUsuario,
  setSelectRol,
  onAddRowRolUsuario,
  onDeleteRowUsuario,
  onSetModalDatos,
  onSetModalUsuario,
} = usuarioSlice.actions;
export default usuarioSlice.reducer;
