export const theme = {
  font: {
    size: '12px',
    family: 'PilcrowRounded',
  },
  colors: {
    primary: '#0B0841',
    secondary: '#a700ff',
    tertiary: '#062cff',
    quaternary: '#90A9D5',
    quinary: '#071381',
    success: '#52c41a',
    caution: '#ff9a47',
    warning: '#e94210',
    error: '#f5222d',
    green: '#0f803e',
    text: '#2a2a2b',
    textDisabled: '#7e7e80ff',
    line: '#A8A8A8',
    white: '#FFFFFF',
    black: '#000000',
    background: '#f5f5f5',
    backgroundTot: '#DDD8D5',
    backgroundAll: '#f5f9ff',
  },
  colorsDashboard: [
    '#4B25B3',
    '#9095dd',
    '#32337b',
    '#964482',
    '#d988d5',
    '#BA1BF9',
    '#4B51F9',
    '#29214f',
    '#605787',
    '#7F8BFA',
    '#060609',
    '#0c2f43',
    '#1d6286',
    '#557DF5',
    '#962bc8',
  ],
  colorsDashboardRGBA: [
    'rgba(75, 37, 179, 0.5)',
    'rgba(144, 149, 221, 0.5)',
    'rgba(50, 51, 123, 0.5)',
    'rgba(150, 68, 130, 0.5)',
    'rgba(217, 136, 213, 0.5)',
    'rgba(186, 27, 249, 0.5)',
    'rgba(75, 81, 249, 0.5)',
    'rgba(41, 33, 79, 0.5)',
    'rgba(96, 87, 135, 0.5)',
    'rgba(127, 139, 250, 0.5)',
    'rgba(6, 6, 9, 0.5)',
    'rgba(12, 47, 67, 0.5)',
    'rgba(29, 98, 134, 0.5)',
    'rgba(85, 125, 245, 0.5)',
    'rgba(150, 43, 200, 0.5)',
  ],
};
