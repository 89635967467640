import React, { useEffect, useState } from 'react';
import {
  SearchInputText,
  Button,
  ModalConfirmation,
  TableGrid,
  PBR,
  ButtonAccion,
  ButtonForm,
} from '../../../../components';
import { listarPosiciones, eliminarPosiciones, obtenerPosicion } from '../../../../services';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterListarPosiciones,
  setListarPosiciones,
  limpiarCamposPosicion,
  setListaFuncionPosicion,
  setListarCaracteristicas,
  setListarCabeceraAgrupado,
  setCabeceraEditar,
  setListarPrincipalPosicion,
  setListarSecundariaPosicion,
} from '../../../../redux/states';
import { createPosicionAdapter } from '../../../../adapters';
import { LoadingSpinner } from '../../../../components';
import { updateTitle } from '../../../../redux/states';
import Swal from 'sweetalert2';
import { buildPath, exportToExcel, paths } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  RowAccion,
  RowFlex,
  MenuAccion,
} from '../../../../styles';
import { Divider, Dropdown } from 'antd';
import { permisos } from '../../../../common';
import { items } from './misc';
import { FaPenToSquare } from 'react-icons/fa6';
export const ListarPosicion = () => {
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const posicionState = useSelector((store) => store.posicion);
  const [selectedPosicion, setSelectedPosicion] = useState([]);
  // 0 Sin acción - 1 Agregar - 2 Editar
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: 'Lista de Posiciones' }));
      loadPosiciones();
    })();
  }, []);

  const loadPosiciones = async () => {
    const responseListaPosiciones = await callEndpoint(listarPosiciones());
    var listaPosiciones = [];
    for (
      let index = 0;
      index < responseListaPosiciones.data.respuesta.aListaPosiciones.length;
      index++
    ) {
      const element = responseListaPosiciones.data.respuesta.aListaPosiciones[index];
      var obj = createPosicionAdapter(element);
      listaPosiciones.push(obj);
    }
    dispatch(setListarPosiciones(listaPosiciones));
  };

  const findConcepto = (person) => {
    return !!selectedPosicion.find((p) => p.iIdPosicion === person.iIdPosicion);
  };
  const filterConcepto = (person) => {
    return selectedPosicion.filter((p) => p.iIdPosicion !== person.iIdPosicion);
  };
  const handleChange = (person) => {
    const filteredPeople = findConcepto(person)
      ? filterConcepto(person)
      : [...selectedPosicion, person];
    setSelectedPosicion(filteredPeople);
  };
  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <CheckboxStyle
              size="small"
              checked={findConcepto(params.row)}
              onChange={() => handleChange(params.row)}
            />
          }
        </>
      ),
    },
    {
      field: 'sNombrePosicion',
      headerName: 'Posición',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'sNombreConcepto',
      headerName: 'Concepto',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'TipoConcepto',
      headerName: 'Tipo Concepto',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Real_Proyectado',
      headerName: 'R/P',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <PBR permiso={[permisos.EDITAR_POSICION]}>
          <ButtonForm
            action={(e) => onClickEditarPosicion(params.row)}
            icon={<FaPenToSquare />}
            isAction={false}
            isOnlyIcon={true}
            typeColor="primary"
          />
        </PBR>
      ),
    },
  ];
  const onAgregarPosicion = () => {
    // setButtonAction(1);
    // setRowsKeyData([]);
    dispatch(limpiarCamposPosicion());
    // dispatch(updateTitle({ title: "Crear Posición" }));
    navigate(paths.CONFIGURE.POSITION.CREATE);
  };
  const onClickEditarPosicion = async (row) => {
    // dispatch(updateTitle({ title: "Editar Posición" }));
    // const { data } = await callEndpoint(
    const responseObtenerPosicion = await callEndpoint(
      obtenerPosicion({
        oIDPosicion: {
          IDPos: row.iIdPosicion,
        },
      })
    );
    if (responseObtenerPosicion === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseObtenerPosicion.data) {
        if (responseObtenerPosicion.data.cabecera.statusCode === 200) {
          let respuesta = responseObtenerPosicion.data.respuesta;
          let objPos = {
            sPosicion: respuesta.aListaCabeceraAgrupado[0].NombrePosicion,
            bValidPosicion: true,
          };
          let selectConcepto = [];
          selectConcepto.push({
            value: respuesta.aListaCabeceraAgrupado[0].IDConcepto,
            label: respuesta.aListaCabeceraAgrupado[0].NombreConcepto,
          });
          objPos.selectConcepto = selectConcepto;
          objPos.bValidConcepto = true;
          dispatch(setCabeceraEditar(objPos));
          dispatch(
            setListarCabeceraAgrupado({
              aListaCabeceraAgrupado: respuesta.aListaCabeceraAgrupado[0],
            })
          );
          dispatch(
            setListaFuncionPosicion({ aListadoFuncionesComp: respuesta.aListadoFuncionesComp })
          );
          dispatch(
            setListarCaracteristicas({ rowsInputPosicionCarac: respuesta.rowsInputPosicionCarac })
          );
          let listaPrincipalPosicion = [];
          let listaSecundariaPosicion = [];
          dispatch(
            setListarPrincipalPosicion({
              selectPrincipalPosicion: respuesta.aListaCabeceraAgrupado[0].oLlave,
              bValidPrincipalPosicion: true,
              isDisabledPrincipalPosicion: false,
              listaPrincipalPosicion: respuesta.aListaPrincipal,
            })
          );
          dispatch(
            setListarSecundariaPosicion({
              selectSecundariaPosicion: respuesta.aListaCabeceraAgrupado[0].oDesdoble,
              bValidSecundariaPosicion: true,
              isDisabledSecundariaPosicion: false,
              listaSecundariaPosicion: respuesta.aListaSecundaria,
            })
          );
          navigate(buildPath(paths.CONFIGURE.POSITION.EDIT, { id: row.iIdPosicion }));
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseObtenerPosicion.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };
  const onClickEliminarConcepto = () => {
    if (selectedPosicion.length > 0) {
      setShowModalEliminar(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarPosiciones(oEvent.target.value));
    } else {
      dispatch(filterListarPosiciones(''));
    }
  };
  const onCloseModalEliminar = () => {
    setShowModalEliminar(false);
  };
  const onConfirmarEliminar = async () => {
    var aListaPosicionesEliminar = [];
    selectedPosicion.forEach((element) => {
      var o = {};
      o.iIDConceptoSUB = element.iIdPosicion;
      aListaPosicionesEliminar.push(o);
    });
    const responseListaPosiciones = await callEndpoint(
      eliminarPosiciones({ aListaPosicionesEliminar: aListaPosicionesEliminar })
    );
    setShowModalEliminar(false);
    if (responseListaPosiciones === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseListaPosiciones.data.cabecera.statusCode === 200) {
        loadPosiciones();
        setSelectedPosicion([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseListaPosiciones.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseListaPosiciones.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };
  const titleExport = 'PosicionesData';
  const mapColumnsDownloadExcel = {
    sNombrePosicion: 'Posición',
    sNombreConcepto: 'Concepto',
    TipoConcepto: 'Tipo Concepto',
    Real_Proyectado: 'R/P',
  };

  const copiarPosicion = () => {
    if (selectedPosicion.length === 1) {
      navigate(
        buildPath(paths.CONFIGURE.POSITION.CREATE_BY_ID, { id: selectedPosicion[0].iIdPosicion })
      );
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Seleccione sola una fila.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={'Buscar posiciones...'}
              filter={posicionState.searchtext}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: items(
                      onClickEliminarConcepto,
                      exportToExcel,
                      posicionState.searchtext.length === 0
                        ? posicionState.listaPosiciones
                        : posicionState.filterListaPosiciones,
                      titleExport,
                      mapColumnsDownloadExcel,
                      copiarPosicion
                    ),
                  }}
                >
                  <ButtonAccion />
                </Dropdown>
                <PBR permiso={[permisos.CREAR_POSICION]}>
                  <ButtonForm
                    action={onAgregarPosicion}
                    name="Agregar"
                    isAction={true}
                    typeColor="primary"
                  />
                </PBR>
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        {
          <TableGrid
            columns={columns}
            rows={
              posicionState.searchtext.length === 0
                ? posicionState.listaPosiciones
                : posicionState.filterListaPosiciones
            }
            pageSize={15}
            getRowId={(row) => row.iIdPosicion}
          />
        }
      </ColFlex>
      <ModalConfirmation
        showModal={showModalEliminar}
        closeModal={(e) => onCloseModalEliminar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
