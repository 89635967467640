import { createSlice, current } from '@reduxjs/toolkit';
export const sociedadInitialState = {
  searchtext: '',
  listaSociedad: [],
  filterListaSociedad: [],
  idSociedad: null,
  sociedad: '',
  idTax: '',
  nombreComercial: '',
  pais: '',
  departamento: '',
  distrito: '',
  urbanizacion: '',
  direccionLegal: '',
  selectSociedad: [],
};
export const sociedadSlice = createSlice({
  name: 'sociedad',
  initialState: sociedadInitialState,
  reducers: {
    setListarSociedad: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaSociedad: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setSearchTextSociedad: (state, action) => {
      let obj = {
        searchtext: action.payload,
      };
      return { ...state, ...obj };
    },
    setModalSociedad: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCleanModalSociedad: (state) => {
      let obj = {
        idSociedad: null,
        sociedad: '',
        idTax: '',
        nombreComercial: '',
        pais: '',
        departamento: '',
        distrito: '',
        urbanizacion: '',
        direccionLegal: '',
      };
      return { ...state, ...obj };
    },
    setSelectedSociedad: (state, action) => {
      let obj = {
        selectSociedad: action.payload,
      };
      return { ...state, ...obj };
    },
  },
});
export const {
  setListarSociedad,
  setSearchTextSociedad,
  setModalSociedad,
  setCleanModalSociedad,
  setSelectedSociedad,
} = sociedadSlice.actions;
export default sociedadSlice.reducer;
