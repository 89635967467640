import { FaPenToSquare, FaTrashCan } from 'react-icons/fa6';
import { ButtonForm } from '../../../../components';

export const columns = (onEditar, onDelete) => [
  {
    field: 'Nombre',
    headerName: 'Nombre',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'Descripcion',
    headerName: 'Descripcion',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerName: '',
    width: 75,
    renderCell: (params) => (
      <>
        <ButtonForm
          action={(e) => onEditar(params.row)}
          icon={<FaPenToSquare />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="primary"
        />
        <ButtonForm
          action={(e) => onDelete(params.row)}
          icon={<FaTrashCan />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="primary"
        />
      </>
    ),
  },
];
