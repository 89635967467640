export const buildPath = (path, params) => {
  return path.replace(/:([a-zA-Z0-9_]+)/g, (_, key) => {
    if (params[key] !== undefined) {
      return params[key];
    }
    throw new Error(
      `El parámetro '${key}' no se encuentra en los valores proporcionados`
    );
  });
};
