import React, { useState } from 'react';
import { Table } from '../../../../../../components/TableData';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  onOpenModalRestriccionesDetalleFlujoCaja,
  onSetRestriccionesDetalleFlujoCaja,
  onChangeTipoRestriccionDetalleFLujoCaja,
  onChangeTipoObligatorio,
  onChangeTipoMonto,
  onChangeTipoMoneda,
  onChangeListaRegla,
  onLimpiarListaRegla,
  onLimpiarParamRegla,
  onChangeListaReglaS,
  onChangeVersionRestriccionesDetalleFlujoCaja,
  updateIdSelectListaVersionRestriccionesFlujoCaja,
  onChangeCaracteristica,
  onSelectValorRegla,
  onChangeValorRegla,
  onChangeIdRegla,
  onChangeEditListaRegla,
  onChangeListaPriorizacionMoneda,
  onChangeListaPriorizacionMonedaOr,
  onChangeSponsor,
  onChangeAgente,
  onChangeAgenteOr,
  onChangeSolucionRegla,
  onLimpiarMonedaReglaPriorizacion,
} from '../../../../../../redux/states';
import {
  obtenerDetalleVersionRestriccionesFC,
  guardarVersionRestriccionesFC,
  modificarVersionRestriccionesFC,
  eliminarVersionRestriccionesFC,
  validarVersionesRestriccionesFCxUsuario,
  obtenerInfoFiltrosFlujoCajaValoresReglas,
} from '../../../../../../services';
import {
  ButtonForm,
  LabelForm,
  SubTitle,
  InputTextAutocompleteNewUpdDel,
  MultiSelectCheckboxes,
  TableGrid,
  SelectForm,
} from '../../../../../../components';
import { useFetchAndLoad } from '../../../../../../common/hooks';
import Swal from 'sweetalert2';
import { NumericFormat } from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';
import { arrayMove } from '@dnd-kit/sortable';
import { ColFlex, ColForm, RowFlex, RowForm } from '../../../../../../styles';
import { theme } from '../../../../../../common';
import { FaPenToSquare, FaPlus, FaTrashCan } from 'react-icons/fa6';

export const ModalRestricciones = (props) => {
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const flujoCajaState = useSelector((store) => store.flujoCaja);

  const columns = [
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'detalle',
      headerName: 'Detalle',
      flex: 1,
      renderCell: (params) => {
        <>{params.value}</>;
      },
    },
    {
      field: 'editar',
      type: 'editar',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <ButtonForm
              action={(e) => onClickEditRegla(params.row)}
              icon={<FaPenToSquare />}
              isOnlyIcon={true}
              isAction={false}
              typeColor="secondary"
            />
          }
        </>
      ),
    },
    {
      field: 'delete',
      type: 'delete',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <ButtonForm
              action={(e) => onClickDeleteRegla(params.row)}
              icon={<FaTrashCan />}
              isOnlyIcon={true}
              isAction={false}
              typeColor="secondary"
            />
          }
        </>
      ),
    },
  ];

  const columnsPM = [
    {
      Header: '',
      accessor: 'ide',
    },
    {
      Header: 'Sponsor',
      accessor: 'sponsor',
    },
    {
      Header: 'Necesidad',
      accessor: 'necesidad',
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ value, row }) => (
        <ButtonForm
          action={(e) => onClickDeletePriorizacionMoneda(row)}
          icon={<FaTrashCan />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="secondary"
        />
      ),
    },
  ];

  const columnsPA = [
    {
      Header: '',
      accessor: 'ide',
    },
    {
      Header: 'Agente',
      accessor: 'agente',
    },
  ];

  const initiallAllPosition = {
    IdPosicion: null,
    value: '*',
    CodigoPosicion: '',
    NombrePosicion: '',
    label: 'Todos',
    IDConcepto: null,
    NombreConcepto: '',
    TipoConcepto: '',
  };

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === '*')) {
      return `${placeholderButtonLabel}: Todos`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  const onAplicarRestricciones = async (e, props) => {
    const lista = flujoCajaState.aListaRegla.map((item) => {
      if (item.estado === 0) {
        return { ...item, estado: 1 };
      }
      return item;
    });
    dispatch(onChangeListaReglaS(lista));
    dispatch(onOpenModalRestriccionesDetalleFlujoCaja(false));
    dispatch(
      onSetRestriccionesDetalleFlujoCaja({
        aRestriccionesDetalleFlujoCaja: props.selectionRestriccionesModel,
      })
    );
    onLimpiarParam();
  };

  const onCloseModal = (e) => {
    const lista = flujoCajaState.aListaRegla.filter((item) => item.estado === 1);
    if (lista.length < flujoCajaState.aListaRegla.length) {
      Swal.fire({
        title: 'Tiene algunas reglas no aplicadas, estas se van a eliminar ¿esta seguro de cerrar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then((result) => {
        if (result.isConfirmed) {
          onLimpiarParam();
          dispatch(onChangeListaReglaS(lista));
          dispatch(onOpenModalRestriccionesDetalleFlujoCaja(false));
        }
      });
    } else {
      onLimpiarParam();
      dispatch(onChangeListaReglaS(lista));
      dispatch(onOpenModalRestriccionesDetalleFlujoCaja(false));
    }
  };

  const onLimpiarReglas = (oEvent) => {
    dispatch(onLimpiarListaRegla());
  };

  const validarRegla = () => {
    var valido = false;
    switch (flujoCajaState.selectTipoRegla[0].value) {
      case 'Monto mínimo':
        if (flujoCajaState.aMonto > 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
        if (flujoCajaState.selectMoneda) {
          valido = true;
          if (flujoCajaState.selectMoneda.length === 0) {
            valido = false;
            return valido;
            break;
          }
        } else {
          valido = false;
          return valido;
          break;
        }
        return valido;
        break;
      case 'Aplazar':
        if (flujoCajaState.selectObligatorio.length > 0) {
          valido = true;
        } else valido = false;
        return valido;
        break;
      case 'Aplazar con Moneda':
        if (flujoCajaState.selectObligatorio.length > 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
        if (flujoCajaState.selectMoneda) {
          valido = true;
          if (flujoCajaState.selectMoneda.length === 0) {
            valido = false;
            return valido;
            break;
          }
        } else {
          valido = false;
          return valido;
          break;
        }
        return valido;
        break;
      case 'No aplazar a':
        if (flujoCajaState.selectCaracteristica) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
        if (flujoCajaState.selectValor.length > 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
        return valido;
        break;
      case 'No Solucionar con':
        if (flujoCajaState.selectSolucionRegla) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
        return valido;
        break;
      case 'Priorizacion Moneda':
        if (flujoCajaState.aListaPriorizacionMoneda.length > 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
        return valido;
        break;
      default:
        return false;
    }
  };

  const validarMoneda = () => {
    var valido = true;
    switch (flujoCajaState.selectTipoRegla[0].value) {
      case 'Monto mínimo':
        var encontrado = flujoCajaState.aListaRegla.filter(function (item, index) {
          if (item.regla === flujoCajaState.selectTipoRegla[0].value) {
            return item.moneda[0].value === flujoCajaState.selectMoneda[0].value;
          }
        });
        if (encontrado.length === 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
      case 'Aplazar':
        var encontrado = flujoCajaState.aListaRegla.filter(function (item, index) {
          if (item.regla === flujoCajaState.selectTipoRegla[0].value) {
            return item.regla === 'Aplazar';
          }
        });
        if (encontrado.length === 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
      case 'Aplazar con Moneda':
        var encontrado = flujoCajaState.aListaRegla.filter(function (item, index) {
          if (item.regla === flujoCajaState.selectTipoRegla[0].value) {
            return item.moneda[0].value === flujoCajaState.selectMoneda[0].value;
          }
        });
        if (encontrado.length === 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
      case 'No aplazar a':
        return true;
        break;
      case 'No Solucionar con':
        var encontrado = flujoCajaState.aListaRegla.filter(function (item, index) {
          if (item.regla === flujoCajaState.selectTipoRegla[0].value) {
            return item.valor === flujoCajaState.selectSolucionRegla[0].value;
          }
        });
        if (encontrado.length === 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
        return true;
        break;
      case 'Priorizacion Moneda':
        var encontrado = flujoCajaState.aListaRegla.filter(function (item, index) {
          if (item.regla === flujoCajaState.selectTipoRegla[0].value) {
            return item;
          }
        });
        if (encontrado.length === 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
        return true;
        break;
      default:
        return true;
    }
  };

  const validarMonedaEdit = () => {
    var valido = true;
    switch (flujoCajaState.selectTipoRegla[0].value) {
      case 'Monto mínimo':
        var encontrado = flujoCajaState.aListaRegla.filter(function (item, index) {
          if (
            item.regla === flujoCajaState.selectTipoRegla[0].value &&
            item.id != flujoCajaState.selectIdRegla
          ) {
            return item.moneda.value === flujoCajaState.selectMoneda.value;
          }
        });
        if (encontrado.length === 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
      case 'Aplazar':
        var encontrado = flujoCajaState.aListaRegla.filter(function (item, index) {
          if (
            item.regla === flujoCajaState.selectTipoRegla[0].value &&
            item.id != flujoCajaState.selectIdRegla
          ) {
            return item.regla === 'Aplazar';
          }
        });
        if (encontrado.length === 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
      case 'Aplazar con Moneda':
        var encontrado = flujoCajaState.aListaRegla.filter(function (item, index) {
          if (
            item.regla === flujoCajaState.selectTipoRegla[0].value &&
            item.id != flujoCajaState.selectIdRegla
          ) {
            return item.moneda.value === flujoCajaState.selectMoneda.value;
          }
        });
        if (encontrado.length === 0) {
          valido = true;
        } else {
          valido = false;
          return valido;
          break;
        }
      case 'No aplazar a':
        return true;
        break;
      default:
        return true;
    }
  };

  const onClickEditRegla = async (row) => {
    dispatch(onChangeIdRegla({ selectIdRegla: row.id }));
    dispatch(
      onChangeTipoRestriccionDetalleFLujoCaja({
        selectTipoRegla: [{ value: row.regla, label: row.regla }],
      })
    );
    switch (row.regla) {
      case 'Monto mínimo':
        dispatch(onChangeTipoMonto({ aMonto: row.valor }));
        dispatch(onChangeTipoMoneda({ selectMoneda: row.moneda }));
        break;
      case 'Aplazar':
        var obligatorio = [{ value: row.valor, label: row.valor }];
        dispatch(onChangeTipoObligatorio({ selectObligatorio: obligatorio }));
        break;
      case 'Aplazar con Moneda':
        var obligatorio = [{ value: row.valor, label: row.valor }];
        dispatch(onChangeTipoObligatorio({ selectObligatorio: obligatorio }));
        dispatch(onChangeTipoMoneda({ selectMoneda: row.moneda }));
        break;
      case 'No aplazar a':
        var caracteristica = { value: row.valor, label: row.valor };
        var valores = [];
        row.valores.forEach((ele) => {
          valores.push({ NombreInstancia: ele, label: ele, value: ele });
        });

        dispatch(onChangeCaracteristica(caracteristica));
        await obtenerValores(caracteristica);
        dispatch(onChangeValorRegla({ selectValor: valores }));
        break;
      case 'No Solucionar con':
        var solucion = { value: row.valor, label: row.valor };
        dispatch(onChangeSolucionRegla({ selectSolucionRegla: solucion }));
        break;
      case 'Priorizacion Moneda':
        var oParam = { aListaPriorizacionMoneda: row.valores };
        dispatch(onChangeListaPriorizacionMonedaOr(oParam));
        break;
      default:
        onLimpiarParam();
    }
  };

  const onClickDeleteRegla = async (row) => {
    const lista = flujoCajaState.aListaRegla.filter((item) => item.id != row.id);
    dispatch(onChangeListaReglaS(lista));
  };

  const agregarRestriccion = (oEvent) => {
    if (flujoCajaState.selectTipoRegla[0]) {
      let valido = false;
      valido = validarRegla();
      if (valido === true) {
        if (!flujoCajaState.selectIdRegla) {
          let validaMoneda = validarMoneda();
          if (validaMoneda === true) {
            var oObject = objectRegla();
            var oParam = {
              selectTipoRegla: [],
              aListaTipoReglas: flujoCajaState.aListaTipoReglas,
              valor: '',
              selectMoneda: [],
              aListaMonedasReglas: flujoCajaState.aMonedasCrearFlujoCaja,
              aListaRegla: oObject,
            };

            var oParamen = {};
            oParamen.oRegla = { ...oObject };
            let selectionRestriccionesModelRows = [...props.selectionRestriccionesModel];
            selectionRestriccionesModelRows = [...selectionRestriccionesModelRows, oParam];
            props.setSelectionRestriccionesModel(selectionRestriccionesModelRows);
            dispatch(onChangeListaRegla(oParamen));
            onLimpiarParam();
          } else {
            mensajeMoneda();
          }
        } else {
          let validaMoneda = validarMonedaEdit();
          if (validaMoneda === true) {
            var oObject = objectRegla();
            var index = flujoCajaState.aListaRegla.findIndex((obj) => {
              if (obj.id === flujoCajaState.selectIdRegla) {
                return obj;
              }
            });
            dispatch(onChangeEditListaRegla({ indice: index, edita: oObject }));
            onLimpiarParam();
          } else {
            mensajeMoneda();
          }
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Tiene campos obligatorios',
          showConfirmButton: false,
          timer: 2200,
        });
      }
    }
  };

  const mensajeMoneda = () => {
    switch (flujoCajaState.selectTipoRegla[0].value) {
      case 'Monto mínimo':
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title:
            'La moneda ' +
            flujoCajaState.selectMoneda.label +
            ' ha sido seleccionada anteriomente.',
          showConfirmButton: false,
          timer: 2200,
        });
        break;
      case 'Aplazar':
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Ya existe un Aplazamiento',
          showConfirmButton: false,
          timer: 2200,
        });
        break;
      case 'No Solucionar con':
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Ya existe una Regla con la Solución',
          showConfirmButton: false,
          timer: 2200,
        });
        break;
      case 'Priorizacion Moneda':
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Ya existe una Regla de Priorizacion Moneda',
          showConfirmButton: false,
          timer: 2200,
        });
        break;
      default:
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title:
            'La moneda ' +
            flujoCajaState.selectMoneda.label +
            ' ha sido seleccionada anteriomente.',
          showConfirmButton: false,
          timer: 2200,
        });
    }
  };

  const objectRegla = () => {
    let oObject = {};
    let uId = uuidv4();
    switch (flujoCajaState.selectTipoRegla[0].value) {
      case 'Monto mínimo':
        oObject = {
          id: uId,
          regla: flujoCajaState.selectTipoRegla[0].value,
          tipo: 'Monto mínimo',
          detalle: flujoCajaState.aMonto + ' ' + flujoCajaState.selectMoneda[0].label,
          valor: flujoCajaState.aMonto,
          moneda: flujoCajaState.selectMoneda,
          estado: 0,
          valores: [],
        };
        break;
      case 'Aplazar':
        oObject = {
          id: uId,
          regla: flujoCajaState.selectTipoRegla[0].value,
          tipo: 'Aplazar',
          valor: flujoCajaState.selectObligatorio[0].value,
          detalle: flujoCajaState.selectObligatorio[0].value,
          moneda: null,
          estado: 0,
          valores: [],
        };
        break;
      case 'Aplazar con Moneda':
        oObject = {
          id: uId,
          regla: flujoCajaState.selectTipoRegla[0].value,
          tipo: 'Aplazar',
          valor: flujoCajaState.selectObligatorio[0].value,
          detalle:
            flujoCajaState.selectObligatorio[0].value + ' ' + flujoCajaState.selectMoneda[0].label,
          moneda: flujoCajaState.selectMoneda,
          estado: 0,
          valores: [],
        };
        break;
      case 'No aplazar a':
        var data = [];
        flujoCajaState.selectValor.forEach((ele) => data.push(ele.value));
        oObject = {
          id: uId,
          regla: flujoCajaState.selectTipoRegla[0].value,
          tipo: 'No aplazar a',
          valor: flujoCajaState.selectCaracteristica.value,
          detalle: flujoCajaState.selectCaracteristica.value,
          moneda: null,
          estado: 0,
          valores: data,
        };
        break;
      case 'No Solucionar con':
        var data = [];
        oObject = {
          id: uId,
          regla: flujoCajaState.selectTipoRegla[0].value,
          tipo: 'No Solucionar con',
          valor: flujoCajaState.selectSolucionRegla[0].value,
          detalle: flujoCajaState.selectSolucionRegla[0].value,
          moneda: null,
          estado: 0,
          valores: [],
        };
        break;
      case 'Priorizacion Moneda':
        var data = [];
        oObject = {
          id: uId,
          regla: flujoCajaState.selectTipoRegla[0].value,
          tipo: 'Priorizacion Moneda',
          valor: '',
          detalle: '',
          moneda: null,
          estado: 0,
          valores: flujoCajaState.aListaPriorizacionMoneda,
        };
        break;
      default:
        oObject = {};
    }
    return oObject;
  };

  const onLimpiarParam = () => {
    dispatch(onLimpiarParamRegla());
  };

  const onChangeTipoRestriccion = async (oEvent) => {
    if (oEvent) {
      dispatch(onChangeTipoRestriccionDetalleFLujoCaja({ selectTipoRegla: [oEvent] }));
    }
    if (flujoCajaState.selectIdRegla) {
      dispatch(onChangeIdRegla({ selectIdRegla: '' }));
    }
  };

  const handleChangeSolucionRegla = async (oEvent) => {
    var oParam = {};
    oParam.selectSolucionRegla = [];
    if (oEvent) {
      let obj = {
        ...oEvent,
        ...{ value: oEvent.value, label: oEvent.label },
      };
      oParam.selectSolucionRegla.push(obj);
    }
    dispatch(onChangeSolucionRegla(oParam));
  };

  const handleChangeTipoRestriccionObligatorio = async (oEvent) => {
    var oParam = {};
    oParam.selectObligatorio = [];
    if (oEvent) {
      let obj = {
        ...oEvent,
        ...{ value: oEvent.value, label: oEvent.label },
      };
      oParam.selectObligatorio.push(obj);
    }
    dispatch(onChangeTipoObligatorio(oParam));
  };

  const handleChangeAgente = (oEvent) => {
    let selectAgente = [];
    if (oEvent != null) {
      selectAgente = [oEvent]; //result;
    }
    var oParam = { selectAgente: selectAgente };

    let dataAgente = [];
    var id = 0;
    if (flujoCajaState.aListaSeleccionAgente.length) {
      id = flujoCajaState.aListaSeleccionAgente.length + 1;
    } else id = 1;
    var agente = selectAgente[0].label;
    dataAgente = { id, agente };

    dispatch(onChangeAgente({ aListaSeleccionAgente: dataAgente }));
    dispatch(onChangeAgenteOr(oParam));
  };

  const handleChangeAgenteT = (oldId, newId) => {
    let selectAgente = arrayMove(flujoCajaState.aListaSeleccionAgente, oldId, newId);
    var oParam = { aListaSeleccionAgente: selectAgente };
    dispatch(onChangeAgenteOr(oParam));
  };

  const handleChangeSponsor = (oEvent) => {
    let selectMoneda = [];
    if (oEvent != null) {
      selectMoneda = [oEvent]; //result;
    }
    var oParam = { selectSponsor: selectMoneda };

    dispatch(onChangeSponsor(oParam));
  };

  const handleChangeNecesidad = (oEvent) => {
    let selectMoneda = [];
    if (oEvent != null) {
      selectMoneda = [oEvent]; //result;
    }
    var oParam = { selectNecesidad: selectMoneda };
    dispatch(onChangeSponsor(oParam));
  };

  const AgregarMonedaPriorizacion = () => {
    let dataMoneda = [];
    var id = 0;
    if (flujoCajaState.aListaPriorizacionMoneda.length) {
      id =
        flujoCajaState.aListaPriorizacionMoneda[flujoCajaState.aListaPriorizacionMoneda.length - 1]
          .id + 1;
    } else id = 1;
    var necesidad = flujoCajaState.selectNecesidad[0].label;
    var sponsor = flujoCajaState.selectSponsor[0].label;
    dataMoneda = { id, sponsor, necesidad };

    if (sponsor && necesidad) {
      if (sponsor != necesidad) {
        var encontrado = flujoCajaState.aListaPriorizacionMoneda.filter(function (item, index) {
          if (item.sponsor === sponsor && item.necesidad === necesidad) {
            return item;
          }
        });
        if (encontrado.length === 0) {
          dispatch(onChangeListaPriorizacionMoneda({ aListaPriorizacionMoneda: dataMoneda }));
          dispatch(onLimpiarMonedaReglaPriorizacion());
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Ya existe ese juego de monedas Priorización',
            showConfirmButton: false,
            timer: 2200,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Las monedas de Priorización no pueden ser identicas',
          showConfirmButton: false,
          timer: 2200,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Debe llenar los campos de Sponsor y Necesidad',
        showConfirmButton: false,
        timer: 2200,
      });
    }
  };

  const handleChangePriorizacionMonedaT = (oldId, newId) => {
    let selectMoneda = arrayMove(flujoCajaState.aListaPriorizacionMoneda, oldId, newId);
    var oParam = { aListaPriorizacionMoneda: selectMoneda };
    dispatch(onChangeListaPriorizacionMonedaOr(oParam));
  };

  const handleChangeMonto = (values, sourceInfo) => {
    var oParam = {};
    oParam.aMonto = '';
    if (values) {
      oParam.aMonto = values.value;
    }
    dispatch(onChangeTipoMonto(oParam));
  };
  const handleChangeMonedaReglasVY = (oEvent) => {
    let selectMoneda = [];
    if (oEvent != null) {
      selectMoneda = [oEvent]; //result;
    }
    var oParam = { selectMoneda: selectMoneda };
    var valid = true;
    for (let index = 0; index < flujoCajaState.aRestriccionesDetalleFlujoCaja.length; index++) {
      const element = flujoCajaState.aRestriccionesDetalleFlujoCaja[index];
      if (element.selectMoneda.value === oEvent.value) {
        valid = false;
        break;
      }
    }
    if (valid) {
      dispatch(onChangeTipoMoneda(oParam));
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'La moneda ' + oEvent.label + ' ha sido seleccionada anteriomente.',
        showConfirmButton: false,
        timer: 2200,
      });
    }
  };

  const handleChangeMonedaReglas = (oEvent, index) => {
    let selectMoneda = [];
    let aIDPosicion = [];
    const selectionRestriccionesModel = [...props.selectionRestriccionesModel];
    if (oEvent != null) {
      selectMoneda = oEvent; //result;
    }
    var oParam = { selectMoneda: selectMoneda, index: index };
    // dispatch(changeRowsMonedaRestriccionDetalleFLujoCaja(oParam))
    var posi = index;
    var valid = true;
    for (let index = 0; index < flujoCajaState.aRestriccionesDetalleFlujoCaja.length; index++) {
      const element = flujoCajaState.aRestriccionesDetalleFlujoCaja[index];
      if (posi !== index) {
        if (element.selectMoneda.value === oEvent.value) {
          valid = false;
          break;
        }
      }
    }
    if (valid) {
      // dispatch(changeRowsMonedaRestriccionDetalleFLujoCaja(oParam))
      selectionRestriccionesModel.map((item, index) => {
        if (index === oParam.index) {
          item.selectMoneda = oParam.selectMoneda;
        }
        return { ...item };
      });
      props.setSelectionRestriccionesModel(selectionRestriccionesModel);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'La moneda ' + oEvent.label + ' ha sido seleccionada anteriomente.',
        showConfirmButton: false,
        timer: 2200,
      });
    }
  };

  const handleChangeCaracteristica = async (oEvent) => {
    let selectCaracteristica;
    let aNombreCarac = [];
    let aIDPosicion = [];
    if (oEvent != null) {
      selectCaracteristica = [oEvent]; //result;
    } else {
      dispatch(onChangeCaracteristica([]));
    }
    if (selectCaracteristica) {
      await obtenerValores(selectCaracteristica);
      dispatch(onChangeCaracteristica(selectCaracteristica));
    }
  };
  const obtenerValores = async (selectCaracteristica) => {
    var response = await callEndpoint(
      obtenerInfoFiltrosFlujoCajaValoresReglas({
        aNombreCarac: [selectCaracteristica[0].value],
      })
    );
    if (response.data.cabecera.statusCode != 200) {
    } else {
      const opt = [];
      let listaValorPlano = [];
      response.data.respuesta.aListaCaracPos.forEach((element) => {
        var oCaracCabecera = { ...element };
        oCaracCabecera.label = element.NombreInstancia;
        oCaracCabecera.value = element.NombreInstancia;
        opt.push(oCaracCabecera);
      });
      var oParam = { listaValor: opt };
      dispatch(onSelectValorRegla(oParam));
    }
  };
  const handleChangeValorMultiCheckbox = async (value, oEvent) => {
    let selectValor = [];
    let options = flujoCajaState.aListaValorRegla;
    if (value != null) {
      selectValor = value; //result;
    }
    var oParam = { selectValor: selectValor };
    if (oEvent.action === 'select-option' && oEvent.option.value === '*') {
      // this.setState(this.options);
      oParam.selectValor = [initiallAllPosition, ...options];
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option' && oEvent.option.value === '*') {
      // this.setState([]);
      oParam.selectValor = [];
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option') {
      oParam.selectValor = selectValor.filter((o) => o.value !== '*');
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (value.length === options.length - 1) {
      oParam.selectValor = value; //options
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else {
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    }
    dispatch(onChangeValorRegla(oParam));
  };

  const onClickDeletePriorizacionMoneda = async (row) => {
    const lista = flujoCajaState.aListaPriorizacionMoneda.filter(
      (item) => item.id != row.values.id
    );
    dispatch(onChangeListaPriorizacionMonedaOr({ aListaPriorizacionMoneda: lista }));
  };

  const coSelectTipoRegla = (selectionRestriccionesModelA) => {
    var formulario = <></>;
    if (selectionRestriccionesModelA.length > 0) {
      let tipoFormulario = selectionRestriccionesModelA[0].value;
      switch (tipoFormulario) {
        case 'Aplazar':
          formulario = (
            <RowFiltros style={{ justifyContent: 'start' }}>
              <ColFlex>
                <LabelForm width={'60px'} title={'Obligatorio:'}></LabelForm>
                <SelectForm
                  placeholder="Seleccione"
                  isClearable={true}
                  isSearchable={true}
                  bValid={true}
                  name="sFuncion"
                  options={flujoCajaState.aListaObligatorio}
                  handleChange={(evnt, v) => handleChangeTipoRestriccionObligatorio(v)}
                  value={flujoCajaState.selectObligatorio}
                  isMulti={false}
                  width={'220px'}
                />
              </ColFlex>
            </RowFiltros>
          );
          break;
        case 'Aplazar con Moneda':
          formulario = (
            <RowFiltros style={{ justifyContent: 'start' }}>
              <ColFlex>
                <LabelForm width={'60px'} title={'Obligatorio:'}></LabelForm>
                <SelectForm
                  placeholder="Seleccione"
                  isClearable={true}
                  isSearchable={true}
                  bValid={true}
                  name="sFuncion"
                  options={flujoCajaState.aListaObligatorio}
                  handleChange={(evnt, v) => handleChangeTipoRestriccionObligatorio(v)}
                  value={flujoCajaState.selectObligatorio}
                  isMulti={false}
                  width={'220px'}
                />
              </ColFlex>
              <ColFlex>
                <LabelForm width={'60px'} title={'Moneda:'}></LabelForm>
                <SelectForm
                  bValid={true}
                  value={flujoCajaState.selectMoneda}
                  handleChange={(event, v) => handleChangeMonedaReglasVY(v)}
                  options={flujoCajaState.aMonedasCrearFlujoCaja}
                  placeholder={'Seleccione'}
                  width={'220px'}
                ></SelectForm>
              </ColFlex>
            </RowFiltros>
          );
          break;
        case 'Monto mínimo':
          formulario = (
            <RowFiltros style={{ justifyContent: 'start' }}>
              <ColFlex>
                <LabelForm width={'60px'} title={'Monto:'}></LabelForm>
                <InputNumberFormat
                  // onKeyDown={handleKeyDown}
                  placeholder="Ingrese..."
                  value={flujoCajaState.aMonto}
                  allowNegative={false}
                  thousandSeparator={','}
                  decimalSeparator={'.'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  inputmode="numeric"
                  onValueChange={(values, sourceInfo) => handleChangeMonto(values, sourceInfo)}
                  style={{ width: '220px' }}
                />
              </ColFlex>
              <ColFlex>
                <LabelForm width={'60px'} title={'Moneda:'}></LabelForm>
                <SelectForm
                  bValid={true}
                  value={flujoCajaState.selectMoneda}
                  handleChange={(event, v) => handleChangeMonedaReglasVY(v)}
                  options={flujoCajaState.aMonedasCrearFlujoCaja}
                  placeholder={'Seleccione'}
                  width={'220px'}
                ></SelectForm>
              </ColFlex>
            </RowFiltros>
          );
          break;
        case 'No aplazar a':
          formulario = (
            <RowFiltros style={{ justifyContent: 'start' }}>
              <ColFlex>
                <LabelForm width={'60px'} title={'Característica:'}></LabelForm>
                <SelectForm
                  placeholder="Seleccione Característica"
                  isClearable={true}
                  isSearchable={true}
                  bValid={true}
                  name="sFuncion"
                  options={flujoCajaState.aListaCaracteristica}
                  handleChange={(evnt, v) => handleChangeCaracteristica(v)}
                  value={flujoCajaState.selectCaracteristica}
                  isMulti={false}
                  width={'300px'}
                />
              </ColFlex>
              <ColFlex>
                <LabelForm width={'60px'} title={'Valor:'}></LabelForm>
                <MultiSelectCheckboxes
                  options={[initiallAllPosition, ...flujoCajaState.aListaValorRegla]}
                  // placeholderButtonLabel="Seleccione Posición"
                  getDropdownButtonLabel={getDropdownButtonLabel}
                  selectedOptions={flujoCajaState.selectValor}
                  onChange={(value, oEvent) => handleChangeValorMultiCheckbox(value, oEvent)}
                  placeholderButtonLabel={'Valores'}
                  width={'220px'}
                />
              </ColFlex>
            </RowFiltros>
          );
          break;
        case 'No Solucionar con':
          formulario = (
            <RowFiltros style={{ justifyContent: 'start' }}>
              <ColFlex>
                <LabelForm width={'60px'} title={'Solución:'}></LabelForm>
                <SelectForm
                  placeholder="Seleccione"
                  isClearable={true}
                  isSearchable={true}
                  bValid={true}
                  name="sFuncion"
                  options={flujoCajaState.aListaSolucionesRegla}
                  handleChange={(evnt, v) => handleChangeSolucionRegla(v)}
                  value={flujoCajaState.selectSolucionRegla}
                  isMulti={false}
                  width={'220px'}
                />
              </ColFlex>
            </RowFiltros>
          );
          break;
        case 'Priorizacion Moneda':
          formulario = (
            <>
              <RowForm>
                <ColForm xs={20} sm={16} md={11} lg={11} xl={11}>
                  <RowForm>
                    <LabelForm width={'60px'} title={'Sponsor:'}></LabelForm>
                    <SelectForm
                      bValid={true}
                      value={flujoCajaState.selectSponsor}
                      handleChange={(event, v) => handleChangeSponsor(v)}
                      options={flujoCajaState.aMonedasCrearFlujoCaja}
                      placeholder={'Seleccione'}
                      width={'220px'}
                    ></SelectForm>
                  </RowForm>
                </ColForm>
                <ColForm xs={20} sm={16} md={11} lg={11} xl={11}>
                  <LabelForm width={'60px'} title={'Necesidad:'}></LabelForm>
                  <SelectForm
                    bValid={true}
                    value={flujoCajaState.selectNecesidad}
                    handleChange={(event, v) => handleChangeNecesidad(v)}
                    options={flujoCajaState.aMonedasCrearFlujoCaja}
                    placeholder={'Seleccione'}
                    width={'220px'}
                  ></SelectForm>
                </ColForm>
                <ColForm xs={20} sm={16} md={11} lg={1} xl={1} style={{ alignSelf: 'flex-end' }}>
                  <ButtonForm
                    action={(e) => AgregarMonedaPriorizacion()}
                    icon={<FaPlus />}
                    isOnlyIcon={true}
                    isAction={true}
                    typeColor="primary"
                  />
                </ColForm>
              </RowForm>
              <RowForm>
                <ColFlex>
                  <Table
                    columns={columnsPM}
                    data={flujoCajaState.aListaPriorizacionMoneda}
                    setData={handleChangePriorizacionMonedaT}
                  />
                </ColFlex>
              </RowForm>
            </>
          );
          break;
        case 'Priorizacion Agente':
          formulario = (
            <RowFlex>
              <RowFiltros style={{ justifyContent: 'start' }}>
                <ColFlex>
                  <LabelForm width={'60px'} title={'Agente:'}></LabelForm>
                  <SelectForm
                    placeholder="Seleccione"
                    disableClearable={true}
                    isSearchable={true}
                    bValid={true}
                    name="sFuncion"
                    options={flujoCajaState.aListaAgente}
                    handleChange={(evnt, v) => handleChangeAgente(v)}
                    value={flujoCajaState.selectAgente}
                    isMulti={false}
                    width={'220px'}
                  />
                </ColFlex>
              </RowFiltros>
              <RowFiltros style={{ justifyContent: 'start' }}>
                <ColFlex>
                  <Table
                    columns={columnsPA}
                    data={flujoCajaState.aListaSeleccionAgente}
                    setData={handleChangeAgenteT}
                  />
                </ColFlex>
              </RowFiltros>
            </RowFlex>
          );
          break;
        default:
          <></>;
      }
    }
    return formulario;
  };

  const onSelectVersionFlujoCaja = async (oSelect) => {
    let oParam = {
      selectVersionRestriccionesDetalleFlujoCaja: [],
    };
    if (oSelect) {
      oParam.selectVersionRestriccionesDetalleFlujoCaja = [oSelect];
    }
    if (oSelect.id != null) {
      //consultar Servicio
      const responseListarEtiquetas = await callEndpoint(
        obtenerDetalleVersionRestriccionesFC({
          oVersionRegla: {
            ID: oSelect.id,
          },
        })
      );

      if (responseListarEtiquetas.data) {
        // oParam.selectVersionFiltroDetalleFlujoCaja = responseListarEtiquetas.data.aFiltrosDetalleFlujoCaja;

        dispatch(onChangeListaReglaS(responseListarEtiquetas.data.respuesta.aListaRegla));
      } else {
        // props.setSelectionFiltrosModel([]);
      }
    } else {
      // props.setSelectionFiltrosModel([]);
    }

    dispatch(onChangeVersionRestriccionesDetalleFlujoCaja(oParam));
  };

  const onChangeInputVersion = (oEvent) => {
    let oParam = {
      selectVersionRestriccionesDetalleFlujoCaja: [],
    };
    if (oEvent) {
      oParam.selectVersionRestriccionesDetalleFlujoCaja = [
        {
          id: null,
          nombre: oEvent,
        },
      ];
    }
    dispatch(onChangeVersionRestriccionesDetalleFlujoCaja(oParam));
  };
  const onConfirmDeleteVersion = async (oEliminar) => {
    var respEliminarVersionRestriccionesFCXUsuario = await callEndpoint(
      eliminarVersionRestriccionesFC({
        IDVersion: oEliminar.id,
      })
    );
    dispatch(onChangeListaReglaS([]));
    await props.loadRestriccionesVersionFCXUsuario();
  };

  const onValidarDeleteVersion = async (oEliminar) => {
    var validacion = await callEndpoint(
      validarVersionesRestriccionesFCxUsuario({
        oVersionRegla: {
          ID: oEliminar.id,
        },
      })
    );
    return validacion.data.respuesta.validador;
  };
  const onOpenDeleteVersion = async (item) => {
    let oEliminar = item;
    const validado = await onValidarDeleteVersion(oEliminar);
    if (validado) {
      Swal.fire({
        title: 'Desea eliminar la version seleccionada',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await onConfirmDeleteVersion(oEliminar);
        }
      });
    } else {
      Swal.fire({
        title: 'No se puede eliminar una versión que es compartida contigo',
        icon: 'warning',
        confirmButtonColor: theme.colors.primary,
        confirmButtonText: 'Confirmar',
      });
    }
  };
  const onOpenGuardarVersion = async (oEvent, finalArrayEliminar) => {
    if (flujoCajaState.aListaRegla.length > 0) {
      var updateVersion = true;
      var validado = true;
      if (flujoCajaState.selectVersionRestriccionesDetalleFlujoCaja[0].id === null) {
        updateVersion = false;
      } else {
        validado = await onValidarDeleteVersion(
          flujoCajaState.selectVersionRestriccionesDetalleFlujoCaja[0]
        );
      }
      if (validado) {
        Swal.fire({
          title: !updateVersion ? '¿Desea guardar la versión?' : '¿Desea sobreescribir la versión?',
          html: `Desea compartir la versión?:  <input id="compartido" style="width:18px;height:18px" type='checkbox' ${
            flujoCajaState.selectVersionRestriccionesDetalleFlujoCaja[0].compartido === 1
              ? 'checked'
              : ''
          }/>`,
          icon: 'warning',
          showCancelButton: true,
          preConfirm: () => {
            var compartido;
            if (Swal.getPopup().querySelector('#compartido:checked')) {
              compartido = 1;
            } else compartido = 0;

            return { compartido: compartido };
          },
          confirmButtonColor: theme.colors.primary,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (!updateVersion) {
              // return;
              var oParam = {
                Compartido: result.value.compartido,
                NombreVersion: flujoCajaState.selectVersionRestriccionesDetalleFlujoCaja[0].nombre,
                aListaRegla: flujoCajaState.aListaRegla,
              };

              var responseCrearVersionRestriccionesFlujoCaja = await callEndpoint(
                guardarVersionRestriccionesFC(oParam)
              );
              if (responseCrearVersionRestriccionesFlujoCaja === null) {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Ocurrió un error en el servicio.',
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                if (responseCrearVersionRestriccionesFlujoCaja.data.cabecera.statusCode === 200) {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: responseCrearVersionRestriccionesFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  dispatch(
                    updateIdSelectListaVersionRestriccionesFlujoCaja({
                      id: responseCrearVersionRestriccionesFlujoCaja.data.respuesta.ID,
                    })
                  );
                  await props.loadRestriccionesVersionFCXUsuario();
                } else {
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: responseCrearVersionRestriccionesFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                }
              }
            } else {
              var oParamUpdateVersionRestriccionesFlujoCaja = {
                Compartido: result.value.compartido,
                oVersionRegla: {
                  ID: flujoCajaState.selectVersionRestriccionesDetalleFlujoCaja[0].id,
                },
                aListaRegla: flujoCajaState.aListaRegla,
              };
              var responseUpdateVersionRestriccionesFlujoCaja = await callEndpoint(
                modificarVersionRestriccionesFC(oParamUpdateVersionRestriccionesFlujoCaja)
              );
              if (responseUpdateVersionRestriccionesFlujoCaja === null) {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Ocurrió un error en el servicio.',
                  showConfirmButton: false,
                  timer: 2000,
                });
                await props.loadRestriccionesVersionFCXUsuario();
              } else {
                if (responseUpdateVersionRestriccionesFlujoCaja.data.cabecera.statusCode === 200) {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: responseUpdateVersionRestriccionesFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  await props.loadRestriccionesVersionFCXUsuario();
                } else {
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: responseUpdateVersionRestriccionesFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                }
              }
            }
          }
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Esta version no se puede editar',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Por favor, debe llenar las Reglas y asignar un nombre.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  return (
    <ModalComponent
      backdrop="static"
      keyboard={false}
      show={flujoCajaState.modalOpenRestriccionesDetalleFlujoCaja}
      onHide={onCloseModal}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Reglas</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <ColFlex>
          <SubTitle subtitle="Criterios de Restricción" />
          <Card>
            <ContentForm>
              <RowFiltros style={{ justifyContent: 'start' }}>
                <ColFlex>
                  <LabelForm width={'60px'} title={'Version:'}></LabelForm>
                  <InputTextAutocompleteNewUpdDel
                    data={flujoCajaState.listaVersionRestriccionesDetalleFlujoCaja}
                    label="languages"
                    pholder="Buscar Versión..."
                    onSelected={(evnt) => onSelectVersionFlujoCaja(evnt)}
                    width={'220px'}
                    value={
                      flujoCajaState.selectVersionRestriccionesDetalleFlujoCaja.length === 0
                        ? ''
                        : flujoCajaState.selectVersionRestriccionesDetalleFlujoCaja[0].nombre
                    }
                    oValue={flujoCajaState.selectVersionRestriccionesDetalleFlujoCaja}
                    text_align={'start'}
                    onChange={(evnt) => onChangeInputVersion(evnt)}
                    onDelete={onOpenDeleteVersion}
                  />
                </ColFlex>
                <RowFlex style={{ marginTop: 'auto', justifyContent: 'end', width: '100%' }}>
                  <ButtonForm
                    action={(e) => onOpenGuardarVersion(e, props)}
                    name="Guardar versión"
                    isAction={true}
                    typeColor="primary"
                  />
                </RowFlex>
              </RowFiltros>
              <RowFiltros style={{ justifyContent: 'start' }}>
                <ColFlex>
                  <LabelForm width={'60px'} title={'Tipo:'}></LabelForm>
                  <SelectForm
                    placeholder="Seleccione"
                    isClearable={true}
                    isSearchable={true}
                    bValid={true}
                    name="sFuncion"
                    options={flujoCajaState.aListaTipoReglas}
                    handleChange={(evnt, v) => onChangeTipoRestriccion(v)}
                    value={flujoCajaState.selectTipoRegla}
                    isMulti={false}
                    width={'220px'}
                  />
                </ColFlex>
                <RowFlex style={{ marginTop: 'auto', justifyContent: 'end', width: '100%' }}>
                  <ButtonForm
                    action={agregarRestriccion}
                    name={!flujoCajaState.selectIdRegla ? 'Agregar Regla' : 'Editar Regla'}
                    isAction={true}
                    typeColor="primary"
                  />
                </RowFlex>
              </RowFiltros>
              <ColFlex>{coSelectTipoRegla(flujoCajaState.selectTipoRegla)}</ColFlex>
            </ContentForm>
          </Card>
          <SubTitle subtitle="Lista de Criterios" />
          <Card>
            <ContentForm>
              <ColFlex>
                <RowFlex>
                  <div style={{ marginLeft: 'auto' }}>
                    <ButtonForm
                      style={{ 'margin-left': 'auto' }}
                      action={onLimpiarReglas}
                      name={'Eliminar todos'}
                      isAction={false}
                      typeColor="primary"
                    />
                  </div>
                </RowFlex>
              </ColFlex>

              <TableGrid
                columns={columns}
                rows={flujoCajaState.aListaRegla}
                pageSize={15}
                getRowId={(row) => row.id}
              />
            </ContentForm>
          </Card>
        </ColFlex>
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <ButtonForm action={onCloseModal} name="Cancelar" isAction={true} typeColor="warning" />
        <ButtonForm
          action={(e) => onAplicarRestricciones(e, props)}
          name="Aplicar"
          isAction={true}
          typeColor="secondary"
        />
      </ModalComponent.Footer>
    </ModalComponent>
  );
};

const RowFiltros = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 45px;
  margin: 12px 0px 12px 0px;
  align-items: center;
  justify-content: center;
`;
const ModalComponent = styled(Modal)`
  .modal-dialog {
    max-width: 1200px !important;
    max-width: 700px !important;
  }
`;
const InputNumberFormat = styled(NumericFormat)`
  border: 1px solid #ced4da;
  transition: width 0.5s;
  height: 40px;
  font-family: ${theme.font.family};
  font-size: 12px;
  margin-left: 0px;
  padding: ${({ active }) => (true ? '0 10px' : '0px')};
  opacity: ${({ active }) => (true ? '1' : '0')};
  width: ${({ width }) => (width ? width : '320px')};
  background-color: #f6f6f6;
  border-radius: 8px;
  &:focus {
    outline: none !important;
    border-color: ${({ validate }) =>
      !validate ? '#ced4da' : validate === true ? '#ced4da' : 'red'};
  }
  background: #f6f6f6;
  border-radius: 8px;
  border-color: ${({ validate }) => (!validate ? 'none' : validate === true ? 'none' : 'red')};
  transition: ${({ validate }) =>
    !validate ? 'none' : validate === true ? 'none' : 'border-color 0.5s ease-out'};
`;
const ContentForm = styled.div`
  margin: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
const Card = styled.div`
  background-color: ${({ isEmpty }) => (isEmpty ? '#FAFAFA' : 'white')};
  height: 100%;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  overflow: inherit;
  margin: 8px;
  max-width: 90vw;
  .sc-cKkZIC .ciLVik {
    height: 50px !important;
  }
`;
