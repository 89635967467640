import { ButtonForm, PBR } from '../../../../../components';
import { CheckboxStyle, RowButton } from '../../../../../styles';
import { permisos } from '../../../../../common';
import { FaDatabase, FaMinus, FaPenToSquare } from 'react-icons/fa6';

export const columns = (findCampo, handleSelectRow, handleOpenSheetHomo, handleOpenModal) => [
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <CheckboxStyle
        checked={findCampo(params.row)}
        onChange={() => handleSelectRow(params.row)}
        size="small"
        disabled={params.row.idTipoCampo === 1 ? true : false}
      />
    ),
  },
  {
    field: 'nombreCampo',
    headerName: 'Campo',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'tipoDato',
    headerName: 'Tipo Dato',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    headerName: '',
    width: 125,
    renderCell: (params) => (
      <>
        {params.row.idTipoCampo === 1 ? (
          <RowButton>
            <ButtonForm
              action={(oEvent) => handleOpenSheetHomo(oEvent, params.row)}
              icon={<FaDatabase />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
            />
            <PBR permiso={[permisos.EDITAR_CAMPOS]}>
              <ButtonForm
                action={(oEvent) => handleOpenModal(oEvent, params.row)}
                icon={<FaPenToSquare />}
                isAction={false}
                isOnlyIcon={true}
                typeColor="primary"
              />
            </PBR>
          </RowButton>
        ) : (
          <RowButton>
            <ButtonForm
              action={(oEvent) => handleOpenSheetHomo(oEvent, params.row)}
              icon={<FaDatabase />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
            />
            <PBR permiso={[permisos.EDITAR_CAMPOS]}>
              <ButtonForm
                action={(oEvent) => handleOpenModal(oEvent, params.row)}
                icon={<FaPenToSquare />}
                isAction={false}
                isOnlyIcon={true}
                typeColor="primary"
              />
            </PBR>
          </RowButton>
        )}
      </>
    ),
  },
];

export const columnsHomo = (handlePutValueSheet, eliminarCampoHomo) => [
  {
    field: 'NombreCampoHomo',
    headerName: 'Valor Homologado',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'TipoSistema',
    headerName: 'Sistema',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'NombreCampoBase',
    headerName: 'Valor Sistema',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    headerName: '',
    width: 75,
    renderCell: (params) => (
      <RowButton>
        <ButtonForm
          action={(oEvent) => handlePutValueSheet(oEvent, params.row)}
          icon={<FaPenToSquare />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="primary"
        />
        <ButtonForm
          action={(oEvent) => eliminarCampoHomo(oEvent, params.row)}
          icon={<FaMinus />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="primary"
        />
      </RowButton>
    ),
  },
];
