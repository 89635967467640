export const items = (onLogout) => [
  {
    label: 'Ver Perfil',
    key: '1',
    //   onClick: onClickVerPerfil,
  },
  {
    type: 'divider',
  },
  {
    label: 'Cerrar Sesión',
    key: '2',
    onClick: onLogout,
  },
];
