import React from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import styled from 'styled-components';
import { theme } from '../../../common';
export const LoadingForm = () => {
  return (
    <CenterDiv>
      <FadeLoader color={theme.colors.tertiary} />
    </CenterDiv>
  );
};

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
`;
