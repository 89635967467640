import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonForm,
  ColumnSelector,
  Input,
  InputNumberFormat,
  LabelForm,
  LoadingSpinner,
  ModalConfirmation,
  MultiSelectCheckboxes,
  SelectForm,
  SubTitle,
  TabBarCreacion,
  TableGrid,
} from '../../../../components';
import { useFetchAndLoad } from '../../../../common/hooks';
import {
  changeDescripcionAP,
  changeVigenciaAP,
  limpiarCamposAP,
  selectedEstadoAP,
  selectedMonedaAP,
  setListaAgenteAP,
  setListaEstadoAP,
  setListaMonedaAP,
  setListaTiempoAP,
  selectedTipoFomularioAP,
  changeMontoMaximoAP,
  selectedTiempoPeriodoMaximoAP,
  changePeriodoMaximoAP,
  setValidCamposAP,
  changeTeaAP,
  setRegManualesAP,
  setSelectRegManualesAP,
  addFiltroMultiplesTableAP,
  deleteFiltroMultiplesTableAP,
  deleteAllFiltroMultiplesTableAP,
  setColumnsFiltroMultiplesTableAP,
  onChangeRowFiltroMultipleColumnaTableAP,
  onChangeRowFiltroMultipleOperadorTableAP,
  onChangeColumnsFiltroMultiplesTableAP,
  onChangeRowFiltroMultipleValorTableAP,
  onChangeRowCondicionoMultipleOperadorTableAP,
  onChangeRowMultiInputValueSingleEntryMultipleOperadorTableAP,
  addNewEntryRowMultiInputValueNultriEntryMultipleOperadorTableAP,
  onSetRowMultiInputValueNultriEntryMultipleOperadorTableAP,
  onSetFiltroMultiplesTableAP,
  onChangeRowFiltroMultipleFieldValorTableAP,
  setSelectColumnasDinamicaTablaAP,
  onSetListaFiltrosMultiplesTableAppliedAP,
  onSetMonedaSolucionTableAP,
  onOpenModalAsignarTEAMultipleSolAP,
  onChangeRowsMonedaSolucionTableAP,
  onChangeAplicarAllRegAP,
  onSetMonedaSolucionDisponiblesTableAP,
  changePeriodoMinimoAP,
  selectedTiempoPeriodoMinimoAP,
  onChangeRowMonedaSolucionTableAP,
  onAddRowMonedaSolucionTableAP,
  onDeleteRowMultiMonedaMultiTableAP,
  onChangeRowInputTeaSolucionTableAP,
} from '../../../../redux/states';
import {
  obtenerAgentes,
  obtenerCamposHomologados,
  obtenerConceptoPaginado,
  obtenerListadoMoneda,
  obtenerPosicionPaginado,
  obtenerRegistrosManualesMultiple,
  obtenerValoresPaginado,
  registrarSolucion,
} from '../../../../services';
import {
  createAgenteSelectAdapter,
  createCamposHomologadosColumnasAdapter,
  createEstadoSolucionSelectAdapter,
  createMonedaAbreviaturaAdapter,
  createRegistroManualAdapter,
  createTiempoPeriodoAdapter,
} from '../../../../adapters';
import {
  EstadoSolucionesData,
  Selected,
  TipoFormularioAplazamiento,
  paths,
  swalAlertConfirm,
  swalAlertInformation,
  theme,
} from '../../../../common';
import { dateTo_dd_mm_yyyy, exportToExcel } from '../../../../common';
import Swal from 'sweetalert2';
import { TiempoPeriodosData } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import CustomFilterMixTable from '../../../../components/CustomFilterMixTable/CustomFilterMixTable';
import debounce from 'lodash/debounce';
import { AddDeleteTableRowsCaracteristica } from '../components/AddDeleteTableRowsCaracteristica';
import { ModalAsignarTEAMultiple } from '../components/ModalAsignarTEAMultiple';
import {
  CheckboxStyle,
  ColFlex,
  ColForm,
  Content,
  ContentButton,
  ContentForm,
  ContentOption,
  DatePickerForm,
  RowAccion,
  RowDoubleInput,
  RowFlex,
  RowFooterButton,
  RowForm,
} from '../../../../styles';
import { v4 as uuidv4 } from 'uuid';
import { FaMinus, FaPlus } from 'react-icons/fa6';

export const CrearAplazamiento = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const aplazamientoState = useSelector((store) => store.aplazamiento);
  const personalizacionState = useSelector((store) => store.personalizacion);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [tabProceso, setTabProceso] = useState(0);
  const [modalFiltrosMultiples, setModalFiltrosMultiples] = useState(false);
  const [selectMonedasSolucionTEA, setSelectMonedasSolucionTEA] = useState([]);
  const [selectColumn, setSelectColumn] = useState([]);
  const [columnSelector, setColumnSelector] = useState([]);

  useEffect(() => {
    (async () => {
      await loadAgente();
      await loadMoneda();
      await loadEstado();
      await loadTiempoPeriodo();
      await loadCamposHomologados();
    })();
  }, []);

  const mapColumnsDownloadExcel = () => {
    let obj = {};
    aplazamientoState.listaColumnasDinamicaTablaAP.forEach((element) => {
      obj[element.field] = element.headerName;
    });

    return obj;
  };

  const initiallAllPosition = {
    value: '*',
    label: 'Todos',
  };

  const initiallAllColumnas = {
    value: '*',
    label: 'Todos',
  };

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === '*')) {
      return `${placeholderButtonLabel}: Todos`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  const handleChangeDescripcion = (oEvent) => {
    var descripcion = oEvent.target.value;
    var validate = null;
    if (descripcion.length > 0) {
      validate = true;
    }
    var oParam = {
      descripcion: descripcion,
      bValidDescripcion: validate,
    };
    dispatch(changeDescripcionAP(oParam));
  };

  const handleChangePeriodoMin = (values, sourceInfo) => {
    // var monto = oEvent.target.value;
    var periodoMinimo = values.value;
    var validate = null;
    if (periodoMinimo.length > 0) {
      validate = true;
    }
    var oParam = {
      periodoMinimo: periodoMinimo,
      bValidPeriodoMinimo: validate,
    };
    dispatch(changePeriodoMinimoAP(oParam));
  };

  const handleChangePeriodoMax = (values, sourceInfo) => {
    // var monto = oEvent.target.value;
    var periodoMaximo = values.value;
    var validate = null;
    if (periodoMaximo.length > 0) {
      validate = true;
    }
    var oParam = {
      periodoMaximo: periodoMaximo,
      bValidPeriodoMaximo: validate,
    };
    dispatch(changePeriodoMaximoAP(oParam));
  };

  const handleChangeMontoMaximo = (values, sourceInfo) => {
    // var monto = oEvent.target.value;
    var montoMaximo = values.value;
    var validate = null;
    if (montoMaximo.length > 0) {
      validate = true;
    }
    var oParam = {
      montoMaximo: montoMaximo,
      bValidMontoMaximo: validate,
    };
    dispatch(changeMontoMaximoAP(oParam));
  };

  const handleChangeMoneda = async (oEvent) => {
    var oParam = {};
    oParam.bValidMoneda = null;
    oParam.selectMoneda = [];
    oParam.selectMultiMonedaConversion = [];
    oParam.listMonedaSolucionTeaAP = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectMoneda.push(obj);
      oParam.bValidMoneda = true;
      dispatch(selectedMonedaAP(oParam));

      if (aplazamientoState.selectTipoFormulario[0].Cod === 'Tipo_3') {
        let listMonedaSolucionTeaAP = [];
        let listaMonedaSolucionDisponiblesTableAP = aplazamientoState.listaMoneda.filter(function (
          item,
          index
        ) {
          return item.value !== oParam.selectMoneda[0].value;
        });
        for (let index = 0; index < aplazamientoState.listMonedaSolucionTeaAP.length; index++) {
          const element = aplazamientoState.listMonedaSolucionTeaAP[index];
          if (element.selectMonedaSolucion.length > 0) {
            if (element.selectMonedaSolucion[0].value !== oParam.selectMoneda[0].value) {
              listMonedaSolucionTeaAP.push(element);
            }
          } else {
            listMonedaSolucionTeaAP.push(element);
          }
        }
        dispatch(onSetMonedaSolucionTableAP({ listMonedaSolucionTeaAP: listMonedaSolucionTeaAP }));
        dispatch(
          onSetMonedaSolucionDisponiblesTableAP({
            listaMonedaSolucionDisponiblesTableAP: listaMonedaSolucionDisponiblesTableAP,
          })
        );
      }
    } else {
      if (
        aplazamientoState.regManualesAP.length > 0 ||
        aplazamientoState.selectRegManualesAP.length > 0
      ) {
        let result = await Swal.fire({
          title:
            'Usted ya ha aplicado una búsqueda previamente. Al cambiar de moneda se limpiará la seleccion de registros en el detalle de aplazamiento',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: theme.colors.primary,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
        });
        if (result.isConfirmed) {
          oParam.regManualesAP = [];
          oParam.selectRegManualesAP = [];
          oParam.listaFiltrosMultiplesTableAP = [];
          // oParam.listaColumnasMultiplestableAP = [];
        }
      } else {
        dispatch(selectedMonedaAP(oParam));
      }
    }
  };

  const handleChangeTiempoPeriodoMin = (oEvent) => {
    var oParam = {};
    oParam.bValidSelectTiempoPeriodoMinimo = null;
    oParam.selectTiempoPeriodoMinimo = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTiempoPeriodoMinimo.push(obj);
      oParam.bValidSelectTiempoPeriodoMinimo = true;
    }
    dispatch(selectedTiempoPeriodoMinimoAP(oParam));
  };

  const handleChangeTiempoPeriodoMax = (oEvent) => {
    var oParam = {};
    oParam.bValidSelectTiempoPeriodoMaximo = null;
    oParam.selectTiempoPeriodoMaximo = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTiempoPeriodoMaximo.push(obj);
      oParam.bValidSelectTiempoPeriodoMaximo = true;
    }
    dispatch(selectedTiempoPeriodoMaximoAP(oParam));
  };

  const handleChangeTipoFormulario = (oEvent) => {
    var oParam = {};
    oParam.bValidselectTipoFormulario = null;
    oParam.selectTipoFormulario = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTipoFormulario.push(obj);
      oParam.bValidselectTipoFormulario = true;
    }
    dispatch(selectedTipoFomularioAP(oParam));
  };
  const limpiarForm = () => {
    dispatch(limpiarCamposAP());
  };
  const limpiarFormTabDetalle = () => {
    dispatch(setSelectRegManualesAP([]));
  };
  const handleChangeEstado = (oEvent) => {
    var oParam = {};
    oParam.bValidEstado = null;
    oParam.selectEstado = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectEstado.push(obj);
      oParam.bValidEstado = true;
    }
    dispatch(selectedEstadoAP(oParam));
  };
  const camposObligatorios = () => {
    var oParam = {
      valid: false,
      oRegistrarSolucion: {},
      mensaje: '',
    };
    if (aplazamientoState.selectTipoFormulario.length > 0) {
      let selectTipoFormulario = aplazamientoState.selectTipoFormulario[0];
      let tipoFormulario = selectTipoFormulario.value;
      var oParamValidCampos = {};
      if (tipoFormulario === 'Tipo_1') {
        if (aplazamientoState.descripcion.length === 0) {
          oParamValidCampos.bValidDescripcion = false;
        }
        // if (
        //   aplazamientoState.selectAgente.length === 0) {
        //   oParamValidCampos.bValidAgente = false;
        // }
        if (aplazamientoState.dDateRange[0] === null || aplazamientoState.dDateRange[1] === null) {
          oParamValidCampos.bValidFecha = false;
        }
        if (aplazamientoState.selectMoneda.length === 0) {
          oParamValidCampos.bValidMoneda = false;
        }
        if (aplazamientoState.montoMaximo.length === 0) {
          oParamValidCampos.bValidMontoMaximo = false;
        }
        if (aplazamientoState.tea.length === 0) {
          oParamValidCampos.bValidTea = false;
        }
        if (aplazamientoState.periodoMinimo.length === 0) {
          oParamValidCampos.bValidPeriodoMinimo = false;
        }
        if (aplazamientoState.selectTiempoPeriodoMinimo.length === 0) {
          oParamValidCampos.bValidSelectTiempoPeriodoMinimo = false;
        }
        if (aplazamientoState.periodoMaximo.length === 0) {
          oParamValidCampos.bValidPeriodoMaximo = false;
        }
        if (aplazamientoState.selectTiempoPeriodoMaximo.length === 0) {
          oParamValidCampos.bValidSelectTiempoPeriodoMaximo = false;
        }
        if (aplazamientoState.selectEstado.length === 0) {
          oParamValidCampos.bValidEstado = false;
        }
        if (
          aplazamientoState.descripcion.length != 0 &&
          // aplazamientoState.selectAgente.length != 0 &&
          aplazamientoState.selectMoneda.length != 0 &&
          aplazamientoState.montoMaximo.length != 0 &&
          aplazamientoState.tea.length != 0 &&
          aplazamientoState.periodoMaximo.length != 0 &&
          aplazamientoState.selectTiempoPeriodoMaximo.length != 0 &&
          aplazamientoState.periodoMinimo.length != 0 &&
          aplazamientoState.selectTiempoPeriodoMinimo.length != 0 &&
          aplazamientoState.selectEstado.length != 0 &&
          aplazamientoState.dDateRange[0] != null &&
          aplazamientoState.dDateRange[1] != null
        ) {
          if (parseFloat(aplazamientoState.montoMaximo) === 0) {
            oParam.mensaje = 'El importe máximo debe ser mayor a 0';
            return oParam;
          }
          // else if (parseFloat(aplazamientoState.tea) === 0) {
          //   oParam.mensaje = 'La TEA debe ser mayor a 0';
          //   return oParam;
          // }
          else {
            oParam.valid = true;
            //Agregamos campos obligatorios
            oParam.oRegistrarSolucion.COD_TIPO_FORMULARIO = 'Tipo_1';
            oParam.oRegistrarSolucion.COD_SOLUCION = 'AP';
            oParam.oRegistrarSolucion.SOL_NOMBRESOLUCION = aplazamientoState.descripcion;
            oParam.oRegistrarSolucion.SOL_IDAGENTE = 1; //aplazamientoState.selectAgente[0].value;

            oParam.oRegistrarSolucion.SOL_AGENTE = 'NO AGENTE'; //aplazamientoState.selectAgente[0].label;
            oParam.oRegistrarSolucion.SOL_MONEDA = aplazamientoState.selectMoneda[0].value;
            oParam.oRegistrarSolucion.SOL_IMPORTEINICIAL = aplazamientoState.montoMaximo;
            oParam.oRegistrarSolucion.SOL_MONEDA = aplazamientoState.selectMoneda[0].value;
            oParam.oRegistrarSolucion.SOL_PERIODOSESPERAP = aplazamientoState.periodoMinimo;
            oParam.oRegistrarSolucion.SOL_PERIODOSESPERAP_PERIODO =
              aplazamientoState.selectTiempoPeriodoMinimo[0].value;
            oParam.oRegistrarSolucion.SOL_PERIODOSMAX = aplazamientoState.periodoMaximo;
            oParam.oRegistrarSolucion.SOL_PERIODOSMAX_PERIODO =
              aplazamientoState.selectTiempoPeriodoMaximo[0].value;
            //oParam.oRegistrarSolucion.SOL_MONTOCUOTAP = parseFloat(aplazamientoState.moraFijaRetraso);
            oParam.oRegistrarSolucion.IDESTADO = aplazamientoState.selectEstado[0].value;
            // oParam.oRegistrarSolucion.SOL_PERIODOSENTREPAGO = parseFloat(aplazamientoState.periodoEntreMora);
            // oParam.oRegistrarSolucion.SOL_PERIODOSENTREPAGO_PERIODO = aplazamientoState.selectTiempoPeriodoEntreMora[0].value;
            oParam.oRegistrarSolucion.SOL_PERIODOINICIOP = dateTo_dd_mm_yyyy(
              aplazamientoState.dDateRange[0],
              '.'
            );
            oParam.oRegistrarSolucion.SOL_PERIODOFINP = dateTo_dd_mm_yyyy(
              aplazamientoState.dDateRange[1],
              '.'
            );
            oParam.oRegistrarSolucion['SOL_TEAP'] = parseFloat(aplazamientoState.tea);
            if (aplazamientoState.apariciones) {
              oParam.oRegistrarSolucion.SOL_APARICIONES = aplazamientoState.apariciones;
            }
          }
        } else {
          dispatch(setValidCamposAP(oParamValidCampos));
          oParam.mensaje = 'Debe llenar todos los campos en (*).';
          return oParam;
        }
        //campos opcionales
        if (aplazamientoState.apariciones.length !== 0) {
          oParam.oRegistrarSolucion['SOL_APARICIONES'] = parseFloat(aplazamientoState.apariciones);
        }
      } else if (tipoFormulario === 'Tipo_2') {
        if (aplazamientoState.descripcion.length === 0) {
          oParamValidCampos.bValidDescripcion = false;
        }
        // if (
        //   aplazamientoState.selectAgente.length === 0) {
        //   oParamValidCampos.bValidAgente = false;
        // }
        if (aplazamientoState.dDateRange[0] === null || aplazamientoState.dDateRange[1] === null) {
          oParamValidCampos.bValidFecha = false;
        }
        if (aplazamientoState.selectMoneda.length === 0) {
          oParamValidCampos.bValidMoneda = false;
        }
        // if (aplazamientoState.montoMaximo.length === 0) {
        //   oParamValidCampos.bValidMontoMaximo = false;
        // }
        if (aplazamientoState.tea.length === 0) {
          oParamValidCampos.bValidTea = false;
        }
        if (aplazamientoState.periodoMaximo.length === 0) {
          oParamValidCampos.bValidPeriodoMaximo = false;
        }
        if (aplazamientoState.selectTiempoPeriodoMaximo.length === 0) {
          oParamValidCampos.bValidSelectTiempoPeriodoMaximo = false;
        }
        if (aplazamientoState.selectEstado.length === 0) {
          oParamValidCampos.bValidEstado = false;
        }
        if (
          aplazamientoState.descripcion.length != 0 &&
          // aplazamientoState.selectAgente.length != 0 &&
          aplazamientoState.selectMoneda.length != 0 &&
          // aplazamientoState.montoMaximo.length != 0 &&
          aplazamientoState.tea.length != 0 &&
          aplazamientoState.periodoMaximo.length != 0 &&
          aplazamientoState.selectTiempoPeriodoMaximo.length != 0 &&
          aplazamientoState.selectEstado.length != 0 &&
          aplazamientoState.dDateRange[0] != null &&
          aplazamientoState.dDateRange[1] != null
        ) {
          oParam.valid = true;
          //Agregamos campos obligatorios
          oParam.oRegistrarSolucion.COD_TIPO_FORMULARIO = 'Tipo_2';
          oParam.oRegistrarSolucion.COD_SOLUCION = 'AP';
          oParam.oRegistrarSolucion.SOL_NOMBRESOLUCION = aplazamientoState.descripcion;
          oParam.oRegistrarSolucion.SOL_IDAGENTE = 1; //aplazamientoState.selectAgente[0].value;

          oParam.oRegistrarSolucion.SOL_AGENTE = 'NO AGENTE'; //aplazamientoState.selectAgente[0].label;
          oParam.oRegistrarSolucion.SOL_MONEDA = aplazamientoState.selectMoneda[0].value;
          oParam.oRegistrarSolucion.SOL_IMPORTEINICIAL = 999999999; //aplazamientoState.montoMaximo;
          oParam.oRegistrarSolucion.SOL_MONEDA = aplazamientoState.selectMoneda[0].value;
          oParam.oRegistrarSolucion.SOL_PERIODOSESPERAP = aplazamientoState.periodoMinimo;
          oParam.oRegistrarSolucion.SOL_PERIODOSESPERAP_PERIODO =
            aplazamientoState.selectTiempoPeriodoMinimo[0].value;
          oParam.oRegistrarSolucion.SOL_PERIODOSMAX = aplazamientoState.periodoMaximo;
          oParam.oRegistrarSolucion.SOL_PERIODOSMAX_PERIODO =
            aplazamientoState.selectTiempoPeriodoMaximo[0].value;
          //oParam.oRegistrarSolucion.SOL_MONTOCUOTAP = parseFloat(aplazamientoState.moraFijaRetraso);
          oParam.oRegistrarSolucion.IDESTADO = aplazamientoState.selectEstado[0].value;
          // oParam.oRegistrarSolucion.SOL_PERIODOSENTREPAGO = parseFloat(aplazamientoState.periodoEntreMora);
          // oParam.oRegistrarSolucion.SOL_PERIODOSENTREPAGO_PERIODO = aplazamientoState.selectTiempoPeriodoEntreMora[0].value;
          oParam.oRegistrarSolucion.SOL_PERIODOINICIOP = dateTo_dd_mm_yyyy(
            aplazamientoState.dDateRange[0],
            '.'
          );
          oParam.oRegistrarSolucion.SOL_PERIODOFINP = dateTo_dd_mm_yyyy(
            aplazamientoState.dDateRange[1],
            '.'
          );
          oParam.oRegistrarSolucion['SOL_TEAP'] = parseFloat(aplazamientoState.tea);
          // if (aplazamientoState.apariciones) {
          //   oParam.oRegistrarSolucion.SOL_APARICIONES = aplazamientoState.apariciones;
          // }
          oParam.oRegistrarSolucion.SOL_APARICIONES = 9999;
        } else {
          dispatch(setValidCamposAP(oParamValidCampos));
          oParam.mensaje = 'Debe llenar todos los campos en (*).';
          return oParam;
        }
        //campos opcionales
        if (aplazamientoState.apariciones.length !== 0) {
          oParam.oRegistrarSolucion['SOL_APARICIONES'] = parseFloat(aplazamientoState.apariciones);
        }
      } else if (tipoFormulario === 'Tipo_3') {
        if (aplazamientoState.descripcion.length === 0) {
          oParamValidCampos.bValidDescripcion = false;
        }
        // if (
        //   aplazamientoState.selectAgente.length === 0) {
        //   oParamValidCampos.bValidAgente = false;
        // }
        if (aplazamientoState.dDateRange[0] === null || aplazamientoState.dDateRange[1] === null) {
          oParamValidCampos.bValidFecha = false;
        }
        if (aplazamientoState.selectMoneda.length === 0) {
          oParamValidCampos.bValidMoneda = false;
        }
        if (aplazamientoState.montoMaximo.length === 0) {
          oParamValidCampos.bValidMontoMaximo = false;
        }
        if (aplazamientoState.tea.length === 0) {
          oParamValidCampos.bValidTea = false;
        }
        if (aplazamientoState.periodoMaximo.length === 0) {
          oParamValidCampos.bValidPeriodoMaximo = false;
        }
        if (aplazamientoState.selectTiempoPeriodoMaximo.length === 0) {
          oParamValidCampos.bValidSelectTiempoPeriodoMaximo = false;
        }
        if (aplazamientoState.selectEstado.length === 0) {
          oParamValidCampos.bValidEstado = false;
        }
        if (
          aplazamientoState.descripcion.length != 0 &&
          // aplazamientoState.selectAgente.length != 0 &&
          aplazamientoState.selectMoneda.length != 0 &&
          aplazamientoState.montoMaximo.length != 0 &&
          aplazamientoState.tea.length != 0 &&
          aplazamientoState.periodoMaximo.length != 0 &&
          aplazamientoState.selectTiempoPeriodoMaximo.length != 0 &&
          aplazamientoState.selectEstado.length != 0 &&
          aplazamientoState.dDateRange[0] != null &&
          aplazamientoState.dDateRange[1] != null
        ) {
          oParam.valid = true;
          //Agregamos campos obligatorios
          oParam.oRegistrarSolucion.COD_TIPO_FORMULARIO = 'Tipo_3';
          oParam.oRegistrarSolucion.COD_SOLUCION = 'AP';
          oParam.oRegistrarSolucion.SOL_NOMBRESOLUCION = aplazamientoState.descripcion;
          oParam.oRegistrarSolucion.SOL_IDAGENTE = 1; //aplazamientoState.selectAgente[0].value;

          oParam.oRegistrarSolucion.SOL_AGENTE = 'NO AGENTE'; //aplazamientoState.selectAgente[0].label;
          oParam.oRegistrarSolucion.SOL_MONEDA = aplazamientoState.selectMoneda[0].value;
          oParam.oRegistrarSolucion.SOL_IMPORTEINICIAL = aplazamientoState.montoMaximo;
          oParam.oRegistrarSolucion.SOL_MONEDA = aplazamientoState.selectMoneda[0].value;
          oParam.oRegistrarSolucion.SOL_PERIODOSESPERAP = aplazamientoState.periodoMinimo;
          oParam.oRegistrarSolucion.SOL_PERIODOSESPERAP_PERIODO =
            aplazamientoState.selectTiempoPeriodoMinimo[0].value;
          oParam.oRegistrarSolucion.SOL_PERIODOSMAX = aplazamientoState.periodoMaximo;
          oParam.oRegistrarSolucion.SOL_PERIODOSMAX_PERIODO =
            aplazamientoState.selectTiempoPeriodoMaximo[0].value;
          //oParam.oRegistrarSolucion.SOL_MONTOCUOTAP = parseFloat(aplazamientoState.moraFijaRetraso);
          oParam.oRegistrarSolucion.IDESTADO = aplazamientoState.selectEstado[0].value;
          // oParam.oRegistrarSolucion.SOL_PERIODOSENTREPAGO = parseFloat(aplazamientoState.periodoEntreMora);
          // oParam.oRegistrarSolucion.SOL_PERIODOSENTREPAGO_PERIODO = aplazamientoState.selectTiempoPeriodoEntreMora[0].value;
          oParam.oRegistrarSolucion.SOL_PERIODOINICIOP = dateTo_dd_mm_yyyy(
            aplazamientoState.dDateRange[0],
            '.'
          );
          oParam.oRegistrarSolucion.SOL_PERIODOFINP = dateTo_dd_mm_yyyy(
            aplazamientoState.dDateRange[1],
            '.'
          );
          oParam.oRegistrarSolucion['SOL_TEAP'] = parseFloat(aplazamientoState.tea);
          // if (aplazamientoState.apariciones) {
          //   oParam.oRegistrarSolucion.SOL_APARICIONES = aplazamientoState.apariciones;
          // }
          oParam.oRegistrarSolucion.SOL_APARICIONES = 9999;
        } else {
          dispatch(setValidCamposAP(oParamValidCampos));
          oParam.mensaje = 'Debe llenar todos los campos en (*).';
          return oParam;
        }
        //campos opcionales
        if (aplazamientoState.apariciones.length !== 0) {
          oParam.oRegistrarSolucion['SOL_APARICIONES'] = parseFloat(aplazamientoState.apariciones);
        }
      }
    } else {
      oParam.mensaje = 'Debe seleccionar un tipo de formulario.';
    }
    return oParam;
  };
  const limpiarCampos = () => {
    dispatch(limpiarCamposAP());
  };
  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };
  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };
  const onConfirmarConcepto = () => {
    setShowModalCancelar(false);
    // setButtonAction(1);
    // setTabProceso(0);
    limpiarCampos();
    navigate(paths.SOLUTION.DELAY.BASE);
  };

  const onPressGrabar = async () => {
    // return;
    if (aplazamientoState.selectRegManualesAP.length === 0 && !aplazamientoState.aplicarAllReg) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Debe seleccionar por lo menos un registro para aplazar.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    let { valid, oRegistrarSolucion, mensaje } = camposObligatorios();
    if (valid) {
      var oParam = {
        oRegistrarSolucion: oRegistrarSolucion,
        aRegManuales: aplazamientoState.selectRegManualesAP,
        aTeaMultiMoneda: [],
        listaFiltrosMultiplesAppliedTableAP: aplazamientoState.listaFiltrosMultiplesAppliedTableAP,
      };
      oRegistrarSolucion.AUTOMATICO = aplazamientoState.aplicarAllReg === true ? 1 : 0;

      let aListaRegManuales = [];
      if (oRegistrarSolucion.COD_TIPO_FORMULARIO === 'Tipo_3') {
        if (aplazamientoState.aplicarAllReg) {
          aListaRegManuales = [...aListaRegManuales, ...aplazamientoState.regManualesAP];
          oParam.aRegManuales = aListaRegManuales.map(function (item, index) {
            return item.IDRegistro;
          });
        } else {
          aplazamientoState.selectRegManualesAP.forEach((element, index) => {
            let aEncontrado = aplazamientoState.regManualesAP.filter(function (item, index) {
              return item.IDRegistro === element;
            });
            if (aEncontrado.length > 0) {
              aListaRegManuales = [...aListaRegManuales, ...aEncontrado];
            }
          });
        }

        oParam.aTeaMultiMoneda.push({
          SOL_MONEDA: oRegistrarSolucion.SOL_MONEDA,
          bPrincipal: true,
          aRegManuales: [],
          SOL_TEAP: oRegistrarSolucion.SOL_TEAP,
        });

        if (aplazamientoState.listMonedaSolucionTeaAP.length > 0) {
          for (let index = 0; index < aplazamientoState.listMonedaSolucionTeaAP.length; index++) {
            const element = aplazamientoState.listMonedaSolucionTeaAP[index];
            let aRegManuales = aListaRegManuales.filter(function (item, index) {
              return item['CodigoMonedaPago'] === element.selectMonedaSolucion[0].value; //element.codMoneda;
            });

            oParam.aTeaMultiMoneda.push({
              SOL_MONEDA: element.selectMonedaSolucion[0].value,
              bPrincipal: false,
              aRegManuales: aRegManuales.map(function (item, index) {
                return item.IDRegistro;
              }),
              SOL_TEAP: parseFloat(element.tea),
            });
          }
        }
      }
      //
      // return;
      const responseRegistrarSol = await callEndpoint(registrarSolucion(oParam));
      if (responseRegistrarSol === null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (responseRegistrarSol.data) {
          if (responseRegistrarSol.data.cabecera.statusCode === 200) {
            // props.setButtonAction(0);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Solucion de Aplazamiento registrada correctamente.',
              showConfirmButton: false,
              timer: 2000,
            });
            // dispatch(loadPrestamo());
            // await props.loadPrestamo();
            limpiarCampos();
            navigate(paths.SOLUTION.DELAY.BASE);
          } else if (responseRegistrarSol.data.cabecera.statusCode === 400) {
            Swal.fire({
              position: 'center',
              icon: 'info',
              title: responseRegistrarSol.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseRegistrarSol.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: mensaje,
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  const loadAgente = async () => {
    const responseObtAgentes = await callEndpoint(obtenerAgentes());
    let aAgentes = [];
    if (responseObtAgentes != null) {
      if (responseObtAgentes.data) {
        let { aListaAgentes } = responseObtAgentes.data.respuesta;
        aListaAgentes.forEach((element) => {
          aAgentes.push(createAgenteSelectAdapter(element));
        });
      }
    }
    dispatch(setListaAgenteAP({ listaAgente: aAgentes }));
  };

  const loadMoneda = async () => {
    const responseObtMonedas = await callEndpoint(obtenerListadoMoneda());
    let aMonedas = [];
    if (responseObtMonedas != null) {
      if (responseObtMonedas.data) {
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'texto_moneda'
        )[0].DatoPersonalizacion;
        let { aListaMoneda } = responseObtMonedas.data.respuesta;
        aListaMoneda.forEach((element) => {
          let obj = createMonedaAbreviaturaAdapter(element, tipoMoneda);
          obj.seleccionada = false;
          aMonedas.push(obj);
        });
      }
    }
    dispatch(setListaMonedaAP({ listaMoneda: aMonedas }));
  };

  const loadEstado = async () => {
    let aEstadoSol = [];
    EstadoSolucionesData.forEach((element) => {
      aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
    });
    dispatch(setListaEstadoAP({ listaEstado: aEstadoSol }));
  };

  const loadTiempoPeriodo = async () => {
    let aTiempoPeriodo = [];
    TiempoPeriodosData.forEach((element) => {
      aTiempoPeriodo.push(createTiempoPeriodoAdapter(element));
    });
    dispatch(setListaTiempoAP({ listaTiempo: aTiempoPeriodo }));
  };

  const loadCamposHomologados = async () => {
    const responseListaCamposHomologados = await callEndpoint(obtenerCamposHomologados());
    var listaColumnasMultiplestableAP = [];
    var listaColumnasDinamicaTablaAP = [];
    var selectColumnasDinamicaTablaAP = [];
    if (responseListaCamposHomologados.data) {
      // selectColumnasDinamicaTablaAP.push(initiallAllColumnas);

      for (
        let index = 0;
        index < responseListaCamposHomologados.data.respuesta.aListaCamposHomologados.length;
        index++
      ) {
        const element =
          responseListaCamposHomologados.data.respuesta.aListaCamposHomologados[index];
        let obj = createCamposHomologadosColumnasAdapter({ ...element });
        listaColumnasMultiplestableAP.push(obj);

        listaColumnasDinamicaTablaAP.push({
          //   field: obj.value,
          //   headerName: obj.label,
          //   // flex: 1,
          //   width: 250,
          //   tipo: 'String',
          //   renderCell: (params) => <>{params.value}</>,
          //   seleccionada: true,
          //   obligatorio: obj.obligatorio,
          // });

          field: obj.value,
          headerName: obj.label,
          // flex: 1,
          width: 250,
          tipo: 'String',
          renderCell: (params) => <>{params.value}</>,
          seleccionado: obj.obligatorio === 1 ? Selected.TRUE : Selected.FALSE,
          columna: obj.value,
          parsed: obj.label,
          restricted: obj.obligatorio === 1 ? Selected.TRUE : Selected.FALSE,
        });
        // if (obj.obligatorio === 1) {
        //   selectColumnasDinamicaTablaAP.push({
        //     value: obj.value,
        //     label: obj.value,
        //     obligatorio: obj.obligatorio,
        //   });
        // }
      }
    }
    setColumnSelector(listaColumnasDinamicaTablaAP);

    dispatch(
      setColumnsFiltroMultiplesTableAP({
        // listaColumnasMultiplestableAP: listaColumnasMultiplestableAP,
        // listaColumnasDinamicaTablaAP: listaColumnasDinamicaTablaAP,
        // selectColumnasDinamicaTablaAP: selectColumnasDinamicaTablaAP,

        listaColumnasMultiplesGeneraltableAP: listaColumnasMultiplestableAP.filter(function (
          item,
          index
        ) {
          return item.value !== 'MonedaPago';
        }),
      })
    );
  };

  const handleChangeFecha = (oEvent) => {
    var oParam = {
      dFechaInicio: oEvent[0],
      dFechaFin: oEvent[1],
    };
    var sFechaInicio = '';
    var sFechaFinal = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';
    if (oParam.dFechaInicio != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      var iDia = oParam.dFechaInicio.getDate();
      var iMes = oParam.dFechaInicio.getMonth() + 1;
      var iAnio = oParam.dFechaInicio.getFullYear();
      if (oParam.dFechaInicio.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
    }
    if (oParam.dFechaFin != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaFin.getDate();
      let iMes = oParam.dFechaFin.getMonth() + 1;
      let iAnio = oParam.dFechaFin.getFullYear();
      if (oParam.dFechaFin.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaFinal = sDia + '/' + sMes + '/' + sAnio;
    }
    // oParam.sFechaInicio = sFechaInicio;
    // oParam.sFechaFinal = sFechaFinal;
    oParam.dDateRange = [oEvent[0], oEvent[1]];
    if (oParam.dFechaFin != null && oParam.dFechaInicio != null) {
      oParam.bValidFecha = null;
    }
    dispatch(changeVigenciaAP(oParam));
  };

  const handleChangeTea = (values, sourceInfo) => {
    //var tea = oEvent.target.value;
    var tea = values.value;
    var validate = null;
    if (tea.length > 0) {
      validate = true;
    }
    var oParam = {
      tea: tea,
      bValidTea: validate,
    };
    dispatch(changeTeaAP(oParam));
  };

  const handleChangeColumnasMultiCheckbox = async (value, oEvent, index) => {
    let selectColumnasDinamicaTablaAP = [];
    let aIDPosicion = [];

    let options = [
      ...aplazamientoState.listaColumnasDinamicaTablaAP.map(function (item, index) {
        let { field, headerName } = { ...item };
        return {
          value: field,
          label: headerName,
        };
      }),
    ];
    const selectionFiltrosModel = [];

    if (value != null) {
      selectColumnasDinamicaTablaAP = value; //result;
    }
    var oParam = { selectColumnasDinamicaTablaAP: selectColumnasDinamicaTablaAP, index: index };
    if (oEvent.action === 'select-option' && oEvent.option.value === '*') {
      oParam.selectColumnasDinamicaTablaAP = [initiallAllPosition, ...options];
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option' && oEvent.option.value === '*') {
      oParam.selectColumnasDinamicaTablaAP = [];
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option') {
      oParam.selectColumnasDinamicaTablaAP = selectColumnasDinamicaTablaAP.filter(
        (o) => o.value !== '*'
      );
      //  dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    } else if (value.length === options.length) {
      // oParam.selectMultiMonedaConversion = options;
      oParam.selectColumnasDinamicaTablaAP = [initiallAllPosition, ...options];
      // dispatch(onChangeRowFPosicionFiltroDetalleFlujoCaja(oParam))
    }

    if (oParam.selectColumnasDinamicaTablaAP.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Usted no puede ocultar todas las columnas a la vez. Debe visualizar 1 como mínimo.',
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }

    dispatch(setSelectColumnasDinamicaTablaAP(oParam));
    //props.setSelectionFiltrosModel(selectionFiltrosModel);
  };

  const obligatoriedadCampos = (selectTipoFormulario) => {
    let formulario = <></>;
    if (selectTipoFormulario.length > 0) {
      let tipoFormulario = selectTipoFormulario[0].value;
      switch (tipoFormulario) {
        case 'Tipo_1':
          formulario = (
            <>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Descripción (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={aplazamientoState.descripcion}
                    onChange={(evnt) => handleChangeDescripcion(evnt)}
                    validate={aplazamientoState.bValidDescripcion}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia (*):'}></LabelForm>
                  <DatePickerForm
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={aplazamientoState.dDateRange[0]}
                    endDate={aplazamientoState.dDateRange[1]}
                    onChange={(evnt) => handleChangeFecha(evnt)}
                    isClearable={true}
                    placeholderText="Ingrese"
                    locale="es"
                    showMonthDropdown
                    showYearDropdown
                    portalId="root-portal"
                    validate={aplazamientoState.bValidFecha}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Estado (*):'}></LabelForm>
                  <SelectForm
                    bValid={aplazamientoState.bValidEstado}
                    value={aplazamientoState.selectEstado}
                    handleChange={(e, v) => handleChangeEstado(v)}
                    options={aplazamientoState.listaEstado}
                    placeholder={'Seleccione Estado'}
                  ></SelectForm>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={aplazamientoState.bValidMoneda}
                    value={aplazamientoState.selectMoneda}
                    handleChange={(e, v) => handleChangeMoneda(v)}
                    options={aplazamientoState.listaMoneda}
                    placeholder={'Seleccione Moneda'}
                  ></SelectForm>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Importe máximo (*):'}></LabelForm>
                  <InputNumberFormat
                    validate={aplazamientoState.bValidMontoMaximo}
                    value={aplazamientoState.montoMaximo}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeMontoMaximo(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Plazo mínimo de aviso (*):'}></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      validate={aplazamientoState.bValidPeriodoMinimo}
                      value={aplazamientoState.periodoMinimo}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeriodoMin(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={aplazamientoState.bValidSelectTiempoPeriodoMinimo}
                      value={aplazamientoState.selectTiempoPeriodoMinimo}
                      handleChange={(e, v) => handleChangeTiempoPeriodoMin(v)}
                      options={aplazamientoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    obligatorio={true}
                    title={'Plazo máximo de aplazamiento (*):'}
                  ></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      validate={aplazamientoState.bValidPeriodoMaximo}
                      value={aplazamientoState.periodoMaximo}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeriodoMax(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={aplazamientoState.bValidSelectTiempoPeriodoMaximo}
                      value={aplazamientoState.selectTiempoPeriodoMaximo}
                      handleChange={(e, v) => handleChangeTiempoPeriodoMax(v)}
                      options={aplazamientoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'TEA (%) (*):'}></LabelForm>
                  <InputNumberFormat
                    value={aplazamientoState.tea}
                    validate={aplazamientoState.bValidTea}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeTea(values, sourceInfo)}
                    min={0}
                    max={100.0}
                  />
                </RowForm>
              </ColForm>
              {/* Campos no Obligatorios */}
              {/*
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm   title={'Apariciones :'}></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={aplazamientoState.apariciones}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeApariciones(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
            */}
            </>
          );
          break;
        case 'Tipo_2':
          formulario = (
            <>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Descripción (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={aplazamientoState.descripcion}
                    onChange={(evnt) => handleChangeDescripcion(evnt)}
                    validate={aplazamientoState.bValidDescripcion}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia (*):'}></LabelForm>
                  <DatePickerForm
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={aplazamientoState.dDateRange[0]}
                    endDate={aplazamientoState.dDateRange[1]}
                    onChange={(evnt) => handleChangeFecha(evnt)}
                    isClearable={true}
                    placeholderText="Ingrese"
                    locale="es"
                    showMonthDropdown
                    showYearDropdown
                    portalId="root-portal"
                    validate={aplazamientoState.bValidFecha}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Estado (*):'}></LabelForm>
                  <SelectForm
                    bValid={aplazamientoState.bValidEstado}
                    value={aplazamientoState.selectEstado}
                    handleChange={(e, v) => handleChangeEstado(v)}
                    options={aplazamientoState.listaEstado}
                    placeholder={'Seleccione Estado'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={aplazamientoState.bValidMoneda}
                    value={aplazamientoState.selectMoneda}
                    handleChange={(e, v) => handleChangeMoneda(v)}
                    options={aplazamientoState.listaMoneda}
                    placeholder={'Seleccione Moneda'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'TEA (%) (*):'}></LabelForm>
                  <InputNumberFormat
                    value={aplazamientoState.tea}
                    validate={aplazamientoState.bValidTea}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeTea(values, sourceInfo)}
                    min={0}
                    max={100.0}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Plazo mínimo de aviso (*):'}></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      validate={aplazamientoState.bValidPeriodoMinimo}
                      value={aplazamientoState.periodoMinimo}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeriodoMin(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={aplazamientoState.bValidSelectTiempoPeriodoMinimo}
                      value={aplazamientoState.selectTiempoPeriodoMinimo}
                      handleChange={(e, v) => handleChangeTiempoPeriodoMin(v)}
                      options={aplazamientoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    obligatorio={true}
                    title={'Plazo máximo de aplazamiento (*):'}
                  ></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      validate={aplazamientoState.bValidPeriodoMaximo}
                      value={aplazamientoState.periodoMaximo}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeriodoMax(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={aplazamientoState.bValidSelectTiempoPeriodoMaximo}
                      value={aplazamientoState.selectTiempoPeriodoMaximo}
                      handleChange={(e, v) => handleChangeTiempoPeriodoMax(v)}
                      options={aplazamientoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>

              {/* Campos no Obligatorios */}
              {/* <RowFlex>
                <LabelForm   title={'Apariciones :'}></LabelForm>
                <InputNumberFormat
                  min={1}
                  value={aplazamientoState.apariciones}
                  allowNegative={false}
                  fixedDecimalScale={false}
                  decimalScale={0}
                  onValueChange={(values, sourceInfo) =>
                    handleChangeApariciones(values, sourceInfo)
                  }
                />
              <RowFlex> */}
            </>
          );

          break;
        case 'Tipo_3':
          formulario = (
            <>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Descripción (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={aplazamientoState.descripcion}
                    onChange={(evnt) => handleChangeDescripcion(evnt)}
                    validate={aplazamientoState.bValidDescripcion}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia (*):'}></LabelForm>
                  <DatePickerForm
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={aplazamientoState.dDateRange[0]}
                    endDate={aplazamientoState.dDateRange[1]}
                    onChange={(evnt) => handleChangeFecha(evnt)}
                    isClearable={true}
                    placeholderText="Ingrese"
                    locale="es"
                    showMonthDropdown
                    showYearDropdown
                    portalId="root-portal"
                    validate={aplazamientoState.bValidFecha}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Estado (*):'}></LabelForm>
                  <SelectForm
                    bValid={aplazamientoState.bValidEstado}
                    value={aplazamientoState.selectEstado}
                    handleChange={(e, v) => handleChangeEstado(v)}
                    options={aplazamientoState.listaEstado}
                    placeholder={'Seleccione Estado'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={aplazamientoState.bValidMoneda}
                    value={aplazamientoState.selectMoneda}
                    handleChange={(e, v) => handleChangeMoneda(v)}
                    options={aplazamientoState.listaMoneda}
                    placeholder={'Seleccione Moneda'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Importe máximo (*):'}></LabelForm>
                  <InputNumberFormat
                    validate={aplazamientoState.bValidMontoMaximo}
                    value={aplazamientoState.montoMaximo}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeMontoMaximo(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Plazo mínimo de aviso (*):'}></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      validate={aplazamientoState.bValidPeriodoMinimo}
                      value={aplazamientoState.periodoMinimo}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeriodoMin(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={aplazamientoState.bValidSelectTiempoPeriodoMinimo}
                      value={aplazamientoState.selectTiempoPeriodoMinimo}
                      handleChange={(e, v) => handleChangeTiempoPeriodoMin(v)}
                      options={aplazamientoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    obligatorio={true}
                    title={'Plazo máximo de aplazamiento (*):'}
                  ></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      validate={aplazamientoState.bValidPeriodoMaximo}
                      value={aplazamientoState.periodoMaximo}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeriodoMax(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={aplazamientoState.bValidSelectTiempoPeriodoMaximo}
                      value={aplazamientoState.selectTiempoPeriodoMaximo}
                      handleChange={(e, v) => handleChangeTiempoPeriodoMax(v)}
                      options={aplazamientoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'TEA (%) (*):'}></LabelForm>
                  <InputNumberFormat
                    value={aplazamientoState.tea}
                    validate={aplazamientoState.bValidTea}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeTea(values, sourceInfo)}
                    min={0}
                    max={100.0}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <RowDoubleInput>
                  <SubTitle width={'80%'} subtitle="2. Registros de TEA por moneda de Solución." />
                  <ButtonForm
                    action={(e) => onClearTableMonedasSolucion()}
                    width={'18%'}
                    name="Limpiar Tabla"
                    isAction={true}
                    typeColor="primary"
                  />
                </RowDoubleInput>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <TableGrid
                  columns={[
                    {
                      field: 'selectMonedaSolucion',
                      headerName: 'Moneda',
                      flex: 1,
                      renderCell: (params) => (
                        <SelectForm
                          bValid={true}
                          value={params.value}
                          handleChange={(event, v) => handleChangeMonedaSolucion(v, params.id)}
                          options={aplazamientoState.listaMonedaSolucionDisponiblesTableAP}
                          placeholder={'Seleccione Moneda'}
                        />
                      ),
                    },
                    {
                      field: 'tea',
                      headerName: 'TEA %',
                      flex: 1,
                      renderCell: (params) => (
                        <InputNumberFormat
                          value={params.value}
                          validate={params.row.bValidTea}
                          allowNegative={false}
                          fixedDecimalScale={true}
                          decimalScale={3}
                          onValueChange={(values, sourceInfo) =>
                            handleChangeInputTeaMultiMoneda(values, params.id)
                          }
                          min={0}
                          max={100.0}
                        />
                      ),
                    },
                    {
                      field: 'edit',
                      disableColumnMenu: true,
                      disableFilters: true,
                      sortable: false,
                      headerAlign: 'center',
                      align: 'center',
                      headerName: '',
                      width: 50,
                      renderCell: (params) => (
                        <ButtonForm
                          icon={<FaMinus />}
                          action={() => deleteTableRows(params.row, params.id)}
                          isAction={false}
                          isOnlyIcon={true}
                          typeColor="primary"
                        />
                      ),
                      renderHeader: () => (
                        <ButtonForm
                          action={addTableRows}
                          isOnlyIcon={true}
                          icon={<FaPlus />}
                          isAction={false}
                          noBackground={true}
                          typeColor="primary"
                        />
                      ),
                      // <>{params.value}</>,
                    },
                  ]}
                  rows={aplazamientoState.listMonedaSolucionTeaAP}
                  getRowId={(row) => row.idRow}
                />
                {/* <AddDeleteTableRowsCaracteristica /> */}
              </ColForm>
            </>
          );

          break;
        default:
          <></>;
      }
    }
    return formulario;
  };

  const deleteTableRows = async (data, id) => {
    let { isConfirmed } = await swalAlertConfirm({
      title: '¿Estas seguro que desea eliminar la moneda de Solución?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.colors.primary,
      cancelButtonColor: theme.colors.warning,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    });
    if (isConfirmed) {
      dispatch(
        onDeleteRowMultiMonedaMultiTableAP({
          id: id,
          selectCaracteristica: data.selectCaracteristica,
          data: data,
        })
      );
    }
  };

  const handleChangeMonedaSolucion = async (oEvent, id) => {
    var oParam = {};
    oParam.bValidMonedaSolucion = null;
    oParam.selectMonedaSolucion = [];
    oParam.id = id;
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectMonedaSolucion.push(obj);
      oParam.bValidMonedaSolucion = true;
    }
    if (oParam.selectMonedaSolucion.length > 0) {
      let aEncontradoRepetido = aplazamientoState.listMonedaSolucionTeaAP
        .filter(function (item, index) {
          return item.selectMonedaSolucion.length > 0;
        })
        .filter(function (item2, index2) {
          return item2.selectMonedaSolucion[0].value === oParam.selectMonedaSolucion[0].value;
        });
      if (aEncontradoRepetido.length > 0) {
        let result = await swalAlertInformation({
          position: 'center',
          icon: 'warning',
          title:
            'La moneda ' + oParam.selectMonedaSolucion[0].label + ' ha seleccionada previamente.',
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
    }
    dispatch(onChangeRowMonedaSolucionTableAP(oParam));
  };

  const handleChangeInputTeaMultiMoneda = (event, id) => {
    // const rowsInput = [...rowsData];
    let bValidTea = null;
    let tea = event.value;
    if (!event.floatValue) {
      bValidTea = false;
    } else {
      bValidTea = true;
    }

    dispatch(onChangeRowInputTeaSolucionTableAP({ id: id, tea: tea, bValidTea: bValidTea }));
  };

  const onClearTableMonedasSolucion = async () => {
    dispatch(onSetMonedaSolucionTableAP({ listMonedaSolucionTeaAP: [] }));
  };

  const onTabProcesoGeneralAP = () => {
    setTabProceso(0);
  };

  const onAsignarTEAMultiple = async (formCampos) => {
    //selectMonedasSolucionTEA;
    let oParam = {
      selectMonedasSolucionTEA: selectMonedasSolucionTEA,
      tea: formCampos.tea,
      bValidTea: formCampos.bValidTea,
    };
    dispatch(onChangeRowsMonedaSolucionTableAP(oParam));
    dispatch(onOpenModalAsignarTEAMultipleSolAP({ modalOpenTEAMultipleSolAP: false }));
  };

  const onAddFiltro = () => {
    const { listaFiltrosMultiplesTableAP } = aplazamientoState;

    let valid = true;

    listaFiltrosMultiplesTableAP.forEach(function (element, index) {
      if (element.selectColumna.length === 0) {
        valid = false;
        return;
      }
    });

    if (!valid && listaFiltrosMultiplesTableAP.length > 0) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Debe llenar todos los campos si desea agregar un nuevo filtro.',
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }

    let aColumnasDisponibles = aplazamientoState.listaColumnasMultiplestableAP.filter(function (
      item,
      index
    ) {
      return item.seleccionada === 0;
    });

    if (aColumnasDisponibles.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title:
          'No se pueden agregar más filtros debido a que ya no existen columnas disponibles. Usted ya ha escogido todas.',
        showConfirmButton: false,
        timer: 4500,
      });
      return;
    }

    // return;

    let newEntry = {
      selectCondicion: [],
      selectColumna: [],
      selectOperador: [],
      valueFields: [],
      status: 'C',
      newValue: '',

      aListaValores: [],
      CantidadReg: 0,
      pagina: 1,
      paginaciones: 0,
      paginacion: 0,
    };
    dispatch(addFiltroMultiplesTableAP(newEntry));
  };

  const onDeleteFiltro = async (item, index) => {
    const posi = index;
    const result = await Swal.fire({
      title: '¿Estas seguro que desea eliminar el filtro seleccionado?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.colors.primary,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    });

    if (result.isConfirmed) {
      // dispatch(clearFiltroMultiplesTableAP());

      dispatch(
        deleteFiltroMultiplesTableAP({
          index: posi,
          listaFiltrosMultiplesTableAP: aplazamientoState.listaFiltrosMultiplesTableAP,
          oSelectColumna: item.selectColumna,
        })
      );
    }
  };

  const onDeleteValor = async (item, index) => {
    const posi = index;
    const itemSeleccionado = item;
    const result = await Swal.fire({
      title: '¿Estas seguro que desea eliminar el valor seleccionado?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.colors.primary,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    });

    if (result.isConfirmed) {
      // dispatch(clearFiltroMultiplesTableAP());
      dispatch(
        onSetRowMultiInputValueNultriEntryMultipleOperadorTableAP({
          index: posi,
          valueFields: aplazamientoState.listaFiltrosMultiplesTableAP[index].valueFields.filter(
            function (item, index) {
              return item !== itemSeleccionado;
            }
          ),
        })
      );
    }
  };

  const onLimpiarFiltros = async () => {
    const { listaFiltrosMultiplesTableAP } = aplazamientoState;
    // await onBuscarDetalleXMoneda();
    // if (listaFiltrosMultiplesTableAP.length === 0) {
    //   Swal.fire({
    //     position: 'center',
    //     icon: 'warning',
    //     title: 'No existen filtros a limpiar.',
    //     showConfirmButton: false,
    //     timer: 3500,
    //   });
    //   return;
    // }

    if (listaFiltrosMultiplesTableAP.length > 0) {
      const result = await Swal.fire({
        title: '¿Estas seguro que desea eliminar todos los filtros?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      });

      if (result.isConfirmed) {
        dispatch(deleteAllFiltroMultiplesTableAP());
        // await onBuscarDetalleXMoneda();
      }
    }

    let obj = {
      campo: 'MonedaPago',
      idTipoCampo: 1,
      tipoCampo: 'String',
      operador: {
        idOperador: 3,
        nombre: 'Múltiple',
      },
      fields: [],
    };
    let fieldsMoneda = [];
    let valuesAND = [];
    aplazamientoState.selectMoneda.forEach((element) => {
      fieldsMoneda.push({ valor: element.value });
    });
    // aplazamientoState.selectMultiMonedaConversion
    //   .filter(function (item, index) {
    //     return item.value !== '*';
    //   })
    aplazamientoState.listMonedaSolucionTeaAP.forEach((element) => {
      fieldsMoneda.push({ valor: element.selectMonedaSolucion[0].value });
    });
    if (fieldsMoneda.length > 0) {
      obj.fields = fieldsMoneda;
      valuesAND.push(obj);
    }

    var oParam = {
      // selectMoneda: aplazamientoState.selectMoneda,
      filtros: [
        {
          idTipoCondicion: 1,
          idTipoSolucion: 'AP',
          condicion: 'AND',
          values: valuesAND,
        },

        {
          idTipoCondicion: 2,
          idTipoSolucion: 'AP',
          condicion: 'OR',
          values: [],
        },
      ],
    };
    var regManualesAP = [];
    // dispatch(setRegManualesAP({ regManualesAP: regManualesAP }));
    dispatch(setSelectRegManualesAP([]));

    const responseRegistrarSol = await callEndpoint(obtenerRegistrosManualesMultiple(oParam));

    if (responseRegistrarSol === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseRegistrarSol.data) {
        if (responseRegistrarSol.data.cabecera.statusCode === 200) {
          // props.setButtonAction(0);

          if (responseRegistrarSol.data) {
            if (responseRegistrarSol.data.respuesta.aListaRegistrosAgrupado.length === 0) {
              dispatch(setRegManualesAP({ regManualesAP: regManualesAP }));
              Swal.fire({
                position: 'center',
                icon: 'info',
                title: responseRegistrarSol.data.cabecera.descripcion,
                showConfirmButton: false,
                timer: 2000,
              });
              return;
            } else {
              for (
                let index = 0;
                index < responseRegistrarSol.data.respuesta.aListaRegistrosAgrupado.length;
                index++
              ) {
                const element = responseRegistrarSol.data.respuesta.aListaRegistrosAgrupado[index];
                let obj = createRegistroManualAdapter(element);
                let nombre = '';
                obj.Items.forEach((element2) => {
                  // obj[element.Caracteristica] = "";
                  obj[element2.Caracteristica] = element2.Valor;
                });
                var aEncontradoLlave = obj.Items.filter(function (item) {
                  return item.Llave === 'X' && item.Valor !== '';
                });
                if (aEncontradoLlave.length > 0) {
                  obj['Nombre'] = aEncontradoLlave[0].Valor;
                }
                regManualesAP.push(obj);
              }

              dispatch(setRegManualesAP({ regManualesAP: regManualesAP }));

              // setTabProceso(1);

              // Swal.fire({
              //   position: 'center',
              //   icon: 'success',
              //   title: responseRegistrarSol.data.cabecera.descripcion,
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
            }
          }
        } else if (responseRegistrarSol.data.cabecera.statusCode === 400) {
          Swal.fire({
            position: 'center',
            icon: 'info',
            title: responseRegistrarSol.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRegistrarSol.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onTabDetalelAP = async () => {
    let { valid, oRegistrarSolucion, mensaje } = camposObligatorios();
    if (valid) {
      await onBuscarDetalleXMoneda();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: mensaje,
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  const handleChangeColumna = (oEvent, item, index) => {
    if (oEvent != null && item.selectColumna.length === 0) {
      //agregar SeletColumna
      dispatch(
        onChangeColumnsFiltroMultiplesTableAP({
          action: 'ADD',
          oSelectColumna: oEvent,
        })
      );
    } else if (oEvent != null && item.selectColumna.length > 0) {
      dispatch(
        onChangeColumnsFiltroMultiplesTableAP({
          action: 'REMOVE',
          oSelectColumna: item.selectColumna[0],
        })
      );
      dispatch(
        onChangeColumnsFiltroMultiplesTableAP({
          action: 'ADD',
          oSelectColumna: oEvent,
        })
      );
    } else {
      //Limpiar con lo qe habia en selectColumna
      dispatch(
        onChangeColumnsFiltroMultiplesTableAP({
          action: 'REMOVE',
          oSelectColumna: item.selectColumna[0],
        })
      );
    }
    dispatch(
      onChangeRowFiltroMultipleColumnaTableAP({
        oSelectColumna: oEvent,
        index: index,
      })
    );
  };

  const handleChangeOperador = (oEvent, item, index) => {
    dispatch(
      onChangeRowFiltroMultipleOperadorTableAP({
        oSelectOperador: oEvent,
        index: index,
      })
    );
  };

  const fetchDataPaginate = async (oEvent, item, index) => {
    if (item.selectOperador[0].value === 3) {
      if (item.pagina < item.paginaciones) {
        let service;
        if (item.selectColumna[0].value === 'NombreConcepto') {
          service = obtenerConceptoPaginado({
            pagina: item.pagina + 1,
            DescPosicion: item.selectColumna[0].value,
            valor: item.newValue,
            tipoConcepto: ['Egreso'],
          });
        } else if (item.selectColumna[0].value === 'NombrePosicion') {
          service = obtenerPosicionPaginado({
            pagina: item.pagina + 1,
            DescPosicion: item.selectColumna[0].value,
            valor: item.newValue,
            tipoConcepto: ['Egreso'],
          });
        } else {
          service = obtenerValoresPaginado({
            pagina: item.pagina + 1,
            DescPosicion: item.selectColumna[0].value,
            valor: item.newValue,
          });
        }

        const responseRegistrarSol = await callEndpoint(
          service
          // obtenerValoresPaginado({
          //   pagina: item.pagina + 1,
          //   DescPosicion: item.selectColumna[0].value,
          //   valor: item.newValue,
          // })
        );

        dispatch(
          onChangeRowFiltroMultipleFieldValorTableAP({
            // valueFields: item.valueFields[0],
            index: index,

            aListaValores: [
              ...item.aListaValores,
              ...responseRegistrarSol.data.respuesta.aListaValores,
            ],
            CantidadReg: responseRegistrarSol.data.respuesta.CantidadReg,
            pagina: responseRegistrarSol.data.respuesta.pagina,
            paginaciones: responseRegistrarSol.data.respuesta.paginaciones,
            paginacion: responseRegistrarSol.data.respuesta.paginacion,
          })
        );
      }
    } else {
      if (item.pagina < item.paginaciones) {
        // const responseRegistrarSol = await callEndpoint(
        //   obtenerValoresPaginado({
        //     pagina: item.pagina + 1,
        //     DescPosicion: item.selectColumna[0].value,
        //     valor: item.valueFields[0],
        //   })
        // );
        let service;
        if (item.selectColumna[0].value === 'NombreConcepto') {
          service = obtenerConceptoPaginado({
            pagina: item.pagina + 1,
            DescPosicion: item.selectColumna[0].value,
            valor: item.valueFields[0],
            tipoConcepto: ['Egreso'],
          });
        } else if (item.selectColumna[0].value === 'NombrePosicion') {
          service = obtenerPosicionPaginado({
            pagina: item.pagina + 1,
            DescPosicion: item.selectColumna[0].value,
            valor: item.valueFields[0],
            tipoConcepto: ['Egreso'],
          });
        } else {
          service = obtenerValoresPaginado({
            pagina: item.pagina + 1,
            DescPosicion: item.selectColumna[0].value,
            valor: item.valueFields[0],
          });
        }

        const responseRegistrarSol = await callEndpoint(
          service
          // obtenerValoresPaginado({
          //   pagina: item.pagina + 1,
          //   DescPosicion: item.selectColumna[0].value,
          //   valor: item.newValue,
          // })
        );

        dispatch(
          onChangeRowFiltroMultipleValorTableAP({
            valueFields: item.valueFields[0],
            index: index,

            aListaValores: [
              ...item.aListaValores,
              ...responseRegistrarSol.data.respuesta.aListaValores,
            ],
            CantidadReg: responseRegistrarSol.data.respuesta.CantidadReg,
            pagina: responseRegistrarSol.data.respuesta.pagina,
            paginaciones: responseRegistrarSol.data.respuesta.paginaciones,
            paginacion: responseRegistrarSol.data.respuesta.paginacion,
          })
        );
      }
    }
  };

  const sendBackendRequest = useCallback(async (oParam, index, oEvent) => {
    let service;
    if (oEvent.target.value.length > 0) {
      if (oParam.item.selectColumna[0].value === 'NombreConcepto') {
        service = obtenerConceptoPaginado({
          pagina: 1,
          DescPosicion: oParam.item.selectColumna[0].value,
          valor: oEvent.target.value,
          tipoConcepto: ['Egreso'],
        });
      } else if (oParam.item.selectColumna[0].value === 'NombrePosicion') {
        service = obtenerPosicionPaginado({
          pagina: 1,
          DescPosicion: oParam.item.selectColumna[0].value,
          valor: oEvent.target.value,
          tipoConcepto: ['Egreso'],
        });
      } else {
        service = obtenerValoresPaginado({
          pagina: 1,
          DescPosicion: oParam.item.selectColumna[0].value,
          valor: oEvent.target.value,
        });
      }
      const responseRegistrarSol = await callEndpoint(service);

      dispatch(
        onChangeRowFiltroMultipleFieldValorTableAP({
          valueFields: [oEvent.target.value],
          index: index,

          aListaValores: responseRegistrarSol.data.respuesta.aListaValores,
          CantidadReg: responseRegistrarSol.data.respuesta.CantidadReg,
          pagina: 2,
          paginaciones: responseRegistrarSol.data.respuesta.paginaciones,
          paginacion: responseRegistrarSol.data.respuesta.paginacion,
        })
      );
    } else {
      dispatch(
        onChangeRowFiltroMultipleFieldValorTableAP({
          valueFields: [],
          index: index,

          aListaValores: [],
          CantidadReg: null,
          pagina: 1,
          paginaciones: null,
          paginacion: null,
        })
      );
    }
  }, []);

  const sendBackendRequest2 = useCallback(async (oParam, index, oEvent) => {
    let service;

    if (oEvent.target.value.length > 0) {
      if (oParam.item.selectColumna[0].value === 'NombreConcepto') {
        service = obtenerConceptoPaginado({
          pagina: 1,
          DescPosicion: oParam.item.selectColumna[0].value,
          valor: oEvent.target.value,
          tipoConcepto: ['Egreso'],
        });
      } else if (oParam.item.selectColumna[0].value === 'NombrePosicion') {
        service = obtenerPosicionPaginado({
          pagina: 1,
          DescPosicion: oParam.item.selectColumna[0].value,
          valor: oEvent.target.value,
          tipoConcepto: ['Egreso'],
        });
      } else {
        service = obtenerValoresPaginado({
          pagina: 1,
          DescPosicion: oParam.item.selectColumna[0].value,
          valor: oEvent.target.value,
        });
      }

      const responseRegistrarSol = await callEndpoint(service);

      dispatch(
        onChangeRowFiltroMultipleValorTableAP({
          valueFields: [oEvent.target.value],
          index: index,

          aListaValores: responseRegistrarSol.data.respuesta.aListaValores,
          CantidadReg: responseRegistrarSol.data.respuesta.CantidadReg,
          pagina: 2,
          paginaciones: responseRegistrarSol.data.respuesta.paginaciones,
          paginacion: responseRegistrarSol.data.respuesta.paginacion,
        })
      );
    } else {
      dispatch(
        onChangeRowFiltroMultipleValorTableAP({
          valueFields: [],
          index: index,

          aListaValores: [],
          CantidadReg: null,
          pagina: 1,
          paginaciones: null,
          paginacion: null,
        })
      );
    }
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);

  const debouncedSendRequest2 = useMemo(() => {
    return debounce(sendBackendRequest2, 500);
  }, [sendBackendRequest2]);

  const handleChangeInput = async (oEvent, item, index) => {
    var oParam = { item: item, value: oEvent.target.value };

    if (oParam.item.selectOperador[0].value === 3 || oParam.item.selectOperador[0].value === 10) {
      dispatch(
        onChangeRowMultiInputValueSingleEntryMultipleOperadorTableAP({
          newValue: oParam.value,
          index: index,

          aListaValores: [],
          CantidadReg: null,
          pagina: 1,
          paginaciones: null,
          paginacion: null,
        })
      );

      if (oParam.value) {
        debouncedSendRequest(oParam, index, oEvent);
        // const getData = setTimeout(async () => {
        //   let service;
        //   if (oParam.item.selectColumna[0].value === 'NombreConcepto') {
        //     service = obtenerConceptoPaginado({
        //       pagina: 1,
        //       DescPosicion: oParam.item.selectColumna[0].value,
        //       valor: oParam.value,
        //     });
        //   } else if (oParam.item.selectColumna[0].value === 'NombrePosicion') {
        //     service = obtenerPosicionPaginado({
        //       pagina: 1,
        //       DescPosicion: oParam.item.selectColumna[0].value,
        //       valor: oParam.value,
        //     });
        //   } else {
        //     service = obtenerValoresPaginado({
        //       pagina: 1,
        //       DescPosicion: oParam.item.selectColumna[0].value,
        //       valor: oParam.value,
        //     });
        //   }
        //   const responseRegistrarSol = await callEndpoint(
        //     service
        //   );

        //   if (oParam.value) {
        //     dispatch(
        //       onChangeRowFiltroMultipleFieldValorTableAP({
        //         index: index,

        //         aListaValores: responseRegistrarSol.data.respuesta.aListaValores,
        //         CantidadReg: responseRegistrarSol.data.respuesta.CantidadReg,
        //         pagina: 2,
        //         paginaciones: responseRegistrarSol.data.respuesta.paginaciones,
        //         paginacion: responseRegistrarSol.data.respuesta.paginacion,
        //       })
        //     );
        //   } else {
        //     dispatch(
        //       onChangeRowFiltroMultipleFieldValorTableAP({
        //         index: index,

        //         aListaValores: [],
        //         CantidadReg: responseRegistrarSol.data.respuesta.CantidadReg,
        //         pagina: 1,
        //         paginaciones: responseRegistrarSol.data.respuesta.paginaciones,
        //         paginacion: responseRegistrarSol.data.respuesta.paginacion,
        //       })
        //     );
        //   }
        // }, 800);

        // return () => clearTimeout(getData);
      } else {
        dispatch(
          onChangeRowFiltroMultipleValorTableAP({
            valueFields: [],
            index: index,

            aListaValores: [],
            CantidadReg: null,
            pagina: 1,
            paginaciones: null,
            paginacion: null,
          })
        );
      }
    } else {
      dispatch(
        onChangeRowFiltroMultipleValorTableAP({
          valueFields: oParam.value ? [oParam.value] : [],
          index: index,

          aListaValores: [],
          CantidadReg: null,
          pagina: 1,
          paginaciones: null,
          paginacion: null,
        })
      );

      if (oParam.value.length > 0) {
        debouncedSendRequest2(oParam, index, oEvent);
        // const getData = setTimeout(async () => {
        //   let service;
        //   if (oParam.item.selectColumna[0].value === 'NombreConcepto') {
        //     service = obtenerConceptoPaginado({
        //       pagina: 1,
        //       DescPosicion: oParam.item.selectColumna[0].value,
        //       valor: oParam.value,
        //     });
        //   } else if (oParam.item.selectColumna[0].value === 'NombrePosicion') {
        //     service = obtenerPosicionPaginado({
        //       pagina: 1,
        //       DescPosicion: oParam.item.selectColumna[0].value,
        //       valor: oParam.value,
        //     });
        //   } else {
        //     service = obtenerValoresPaginado({
        //       pagina: 1,
        //       DescPosicion: oParam.item.selectColumna[0].value,
        //       valor: oParam.value,
        //     });
        //   }

        //   const responseRegistrarSol = await callEndpoint(service);

        //   if (oParam.value) {
        //     dispatch(
        //       onChangeRowFiltroMultipleValorTableAP({
        //         valueFields: [oParam.value],
        //         index: index,

        //         aListaValores: responseRegistrarSol.data.respuesta.aListaValores,
        //         CantidadReg: responseRegistrarSol.data.respuesta.CantidadReg,
        //         pagina: 2,
        //         paginaciones: responseRegistrarSol.data.respuesta.paginaciones,
        //         paginacion: responseRegistrarSol.data.respuesta.paginacion,
        //       })
        //     );
        //   } else {
        //     dispatch(
        //       onChangeRowFiltroMultipleValorTableAP({
        //         valueFields: [],
        //         index: index,

        //         aListaValores: [],
        //         CantidadReg: responseRegistrarSol.data.respuesta.CantidadReg,
        //         pagina: 1,
        //         paginaciones: responseRegistrarSol.data.respuesta.paginaciones,
        //         paginacion: responseRegistrarSol.data.respuesta.paginacion,
        //       })
        //     );
        //   }
        // }, 300);

        // return () => clearTimeout(getData);
      } else {
        dispatch(
          onChangeRowFiltroMultipleValorTableAP({
            valueFields: [],
            index: index,

            aListaValores: [],
            CantidadReg: null,
            pagina: 1,
            paginaciones: null,
            paginacion: null,
          })
        );
      }
    }
  };

  const onSelectValue = async (oParam, index) => {
    // var oParam = { item: item, value: oEvent.target.value };

    dispatch(
      onChangeRowFiltroMultipleValorTableAP({
        valueFields: oParam.value ? [oParam.value] : [],
        index: index,

        aListaValores: [],
        CantidadReg: null,
        pagina: 1,
        paginaciones: null,
        paginacion: null,
      })
    );
  };

  const onMultiSelectValue = async (oParam, index) => {
    // var oParam = { item: item, value: oEvent.target.value };

    // dispatch(
    //   onChangeRowFiltroMultipleValorTableAP({
    //     valueFields: oParam.value ? [oParam.value] : [],
    //     index: index,

    //     aListaValores: [],
    //     CantidadReg: null,
    //     pagina: 1,
    //     paginaciones: null,
    //     paginacion: null,
    //   })
    // );

    dispatch(
      addNewEntryRowMultiInputValueNultriEntryMultipleOperadorTableAP({
        newValue: oParam.value,
        index: index,

        aListaValores: [],
        CantidadReg: null,
        pagina: 1,
        paginaciones: null,
        paginacion: null,
      })
    );
  };

  const handleChangeMultiInput = (oEvent, item, index) => {
    //

    // if (oEvent.length > 0) {
    dispatch(
      onChangeRowMultiInputValueSingleEntryMultipleOperadorTableAP({
        newValue: oEvent,
        index: index,
      })
    );
    // }
  };

  const handleCreateMultiInput = (oEvent, item, index) => {};

  const handleKeyDownMultiInput = (oEvent, item, index) => {
    // eslint-disable-next-line default-case
    //

    switch (oEvent.key) {
      case 'Enter':
      case 'Tab':
        let valor = aplazamientoState.listaFiltrosMultiplesTableAP[index].newValue;
        let isRango = item.selectOperador[0].value === 10 ? true : false;

        let isFecha = item.selectColumna[0].idTipo === 3 ? true : false;
        if (isFecha) {
          var date_regex = /^(0[1-9]|1[0-2])\.(0[1-9]|1\d|2\d|3[01])\.(19|20)\d{2}$/;
          if (!date_regex.test(valor)) {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'La fecha a ingresar no tiene el formato DD.MM.YYYY.',
              showConfirmButton: false,
              timer: 2000,
            });
            return false;
          }

          // if (moment(valor, 'DD.MM.YYYY', true).validate()) {
          //   Swal.fire({
          //     position: 'center',
          //     icon: 'warning',
          //     title: 'La fecha a ingresar no tiene el formato DD/MM/YYYY.',
          //     showConfirmButton: false,
          //     timer: 2000,
          //   });
          //   return;
          // }
        }

        if (valor.trim().length === 0) {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'El valor a agregar no puede estar vacio.',
            showConfirmButton: false,
            timer: 2000,
          });
          return;
        }

        if (isRango) {
          if (item.valueFields.length === 2) {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'No se puede agregar más de dos valores.',
              showConfirmButton: false,
              timer: 2000,
            });
            oEvent.preventDefault();
            return;
          }
        }

        // setValue((prev) => [...prev, createOption(valor)]);
        // setInputValue('');
        let encontrado = aplazamientoState.listaFiltrosMultiplesTableAP[index].valueFields.filter(
          function (item, index) {
            return item.value === valor;
          }
        );
        if (encontrado.length === 0) {
          // dispatch(
          //   addNewEntryRowMultiInputValueNultriEntryMultipleOperadorTableAP({
          //     newValue: createOption(valor),
          //     index: index,
          //   })
          // );
          dispatch(
            addNewEntryRowMultiInputValueNultriEntryMultipleOperadorTableAP({
              newValue: valor,
              index: index,

              aListaValores: [],
              CantidadReg: null,
              pagina: 1,
              paginaciones: null,
              paginacion: null,
            })
          );
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'El valor ya ha sido agregado.',
            showConfirmButton: false,
            timer: 2000,
          });
          return;
        }

        oEvent.preventDefault();
    }
  };

  const handleChangeCondicion = (oEvent, item, index) => {
    dispatch(
      onChangeRowCondicionoMultipleOperadorTableAP({
        oSelectCondicion: oEvent,
        index: index,
      })
    );
  };

  const validarFiltrosMultiples = () => {
    const { listaFiltrosMultiplesTableAP } = aplazamientoState;
    var aNoValidos = listaFiltrosMultiplesTableAP.filter(function (item, index) {
      return (
        item.selectCondicion.length === 0 ||
        item.selectColumna.length === 0 ||
        item.selectOperador.length === 0 ||
        item.valueFields.length === 0
      );
    });
    // if (aNoValidos.length === listaFiltrosMultiplesTableAP.length) {
    //   return false;
    // } else if (aNoValidos.length === 0) {
    //   return true;
    // }

    return aNoValidos.length > 0 ? false : true;
  };

  const onBuscarDetalleXMoneda = async () => {
    // if (aplazamientoState.listaFiltrosMultiplesTableAP.length > 0) {

    //let valid = validarFiltrosMultiples();
    let valid = true;

    let newList = [];
    let deleteNewList = [];

    // if (valid) {
    aplazamientoState.listaFiltrosMultiplesTableAP.forEach((element) => {
      let obj = { ...element };
      if (obj.status !== 'E') {
        obj.status = 'A';
        newList.push(obj);
      } else {
        deleteNewList.push(obj);
      }
    });

    dispatch(
      onSetListaFiltrosMultiplesTableAppliedAP({
        listaFiltrosMultiplesAppliedTableAP: aplazamientoState.listaFiltrosMultiplesTableAP.filter(
          function (item, index) {
            return item.status !== 'E';
          }
        ),
        // listaColumnasMultiplesAppliedtableAP: aplazamientoState.listaColumnasMultiplestableAP,
      })
    );
    setModalFiltrosMultiples(false);

    let valuesAND = [];
    let filterAND = aplazamientoState.listaFiltrosMultiplesTableAP.filter(function (item, index) {
      if (item.selectCondicion.length > 0) {
        return item.selectCondicion[0].value === 1 && item.status != 'E';
      }
    });

    for (let index = 0; index < filterAND.length; index++) {
      const element = filterAND[index];
      let fields = element.valueFields.map(function (item, index) {
        return { valor: item };
      });
      let obj = {
        campo: element.selectColumna[0].value,
        idTipoCampo: element.selectColumna[0].idTipo,
        tipoCampo: element.selectColumna[0].tipo,
        operador: {
          idOperador: element.selectOperador[0].value,
          nombre: element.selectOperador[0].label,
        },
        fields: fields,
      };
      valuesAND.push(obj);
    }

    let obj = {
      campo: 'MonedaPago',
      idTipoCampo: 1,
      tipoCampo: 'String',
      operador: {
        idOperador: 3,
        nombre: 'Múltiple',
      },
      fields: [],
    };
    let fieldsMoneda = [];
    aplazamientoState.selectMoneda.forEach((element) => {
      fieldsMoneda.push({ valor: element.value });
    });
    // aplazamientoState.selectMultiMonedaConversion
    //   .filter(function (item, index) {
    //     return item.value !== '*';
    //   })

    aplazamientoState.listMonedaSolucionTeaAP.forEach((element) => {
      fieldsMoneda.push({ valor: element.selectMonedaSolucion[0].value });
    });
    if (fieldsMoneda.length > 0) {
      obj.fields = fieldsMoneda;
      valuesAND.push(obj);
    }

    let valuesOR = [];
    let filterOR = aplazamientoState.listaFiltrosMultiplesTableAP.filter(function (item, index) {
      if (item.selectCondicion.length > 0) {
        return item.selectCondicion[0].value === 2 && item.status != 'E';
      }
    });

    for (let index = 0; index < filterOR.length; index++) {
      const element = filterOR[index];
      let fields = element.valueFields.map(function (item, index) {
        return { valor: item };
      });
      let obj = {
        campo: element.selectColumna[0].value,
        idTipoCampo: element.selectColumna[0].idTipo,
        tipoCampo: element.selectColumna[0].tipo,
        operador: {
          idOperador: element.selectOperador[0].value,
          nombre: element.selectOperador[0].label,
        },
        fields: fields,
      };
      valuesOR.push(obj);
    }

    var oParam = {
      // selectMoneda: aplazamientoState.selectMoneda,
      filtros: [
        {
          idTipoCondicion: 1,
          idTipoSolucion: 'AP',
          condicion: 'AND',
          values: valuesAND,
        },

        {
          idTipoCondicion: 2,
          idTipoSolucion: 'AP',
          condicion: 'OR',
          values: valuesOR,
        },
      ],
    };

    var regManualesAP = [];
    // dispatch(setRegManualesAP({ regManualesAP: regManualesAP }));
    // dispatch(setSelectRegManualesAP([]));
    const responseRegistrarSol = await callEndpoint(obtenerRegistrosManualesMultiple(oParam));

    if (responseRegistrarSol === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseRegistrarSol.data) {
        if (responseRegistrarSol.data.cabecera.statusCode === 200) {
          // props.setButtonAction(0);

          if (responseRegistrarSol.data) {
            if (responseRegistrarSol.data.respuesta.aListaRegistrosAgrupado.length === 0) {
              dispatch(setRegManualesAP({ regManualesAP: regManualesAP }));
              Swal.fire({
                position: 'center',
                icon: 'info',
                title: responseRegistrarSol.data.cabecera.descripcion,
                showConfirmButton: false,
                timer: 2000,
              });
              return;
            } else {
              for (
                let index = 0;
                index < responseRegistrarSol.data.respuesta.aListaRegistrosAgrupado.length;
                index++
              ) {
                const element = responseRegistrarSol.data.respuesta.aListaRegistrosAgrupado[index];
                let obj = createRegistroManualAdapter(element);
                let nombre = '';
                obj.Items.forEach((element2) => {
                  // obj[element.Caracteristica] = "";
                  obj[element2.Caracteristica] = element2.Valor;
                  obj['Codigo' + element2.Caracteristica] = element2.CodValor;
                });
                var aEncontradoLlave = obj.Items.filter(function (item) {
                  return item.Llave === 'X' && item.Valor !== '';
                });
                if (aEncontradoLlave.length > 0) {
                  obj['Nombre'] = aEncontradoLlave[0].Valor;
                }
                regManualesAP.push(obj);
              }

              dispatch(setRegManualesAP({ regManualesAP: regManualesAP }));

              setTabProceso(1);

              // Swal.fire({
              //   position: 'center',
              //   icon: 'success',
              //   title: responseRegistrarSol.data.cabecera.descripcion,
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
            }
          }
        } else if (responseRegistrarSol.data.cabecera.statusCode === 400) {
          Swal.fire({
            position: 'center',
            icon: 'info',
            title: responseRegistrarSol.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRegistrarSol.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }

    // } else {
    //   Swal.fire({
    //     position: 'center',
    //     icon: 'warning',
    //     title: 'No se pueden aplicar Filtros vaciós.',
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }
  };

  const onAplicarFiltrosMultiples = async () => {
    // if (aplazamientoState.listaFiltrosMultiplesTableAP.length > 0) {

    //let valid = validarFiltrosMultiples();
    let valid = true;

    var aNoValidos = aplazamientoState.listaFiltrosMultiplesTableAP.filter(function (item, index) {
      return (
        item.selectCondicion.length === 0 ||
        item.selectColumna.length === 0 ||
        item.selectOperador.length === 0 ||
        item.valueFields.length === 0
      );
    });

    if (aNoValidos.length > 0 || aplazamientoState.listaFiltrosMultiplesTableAP.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Se deben llenar todos los campos antes de aplicar los filtros.',
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }

    let newList = [];
    let deleteNewList = [];

    // if (valid) {
    aplazamientoState.listaFiltrosMultiplesTableAP.forEach((element) => {
      let obj = { ...element };
      if (obj.status !== 'E') {
        obj.status = 'A';
        newList.push(obj);
      } else {
        deleteNewList.push(obj);
      }
    });

    // let msg = 'No se han agregado filtros.';
    // Swal.fire({
    //   position: 'center',
    //   icon: 'error',
    //   title: 'Ocurrió un error en el servicio.',
    //   showConfirmButton: false,
    //   timer: 2000,
    // });
    dispatch(
      onSetListaFiltrosMultiplesTableAppliedAP({
        listaFiltrosMultiplesAppliedTableAP: aplazamientoState.listaFiltrosMultiplesTableAP.filter(
          function (item, index) {
            return item.status !== 'E';
          }
        ),
        // listaColumnasMultiplesAppliedtableAP: aplazamientoState.listaColumnasMultiplestableAP,
      })
    );
    setModalFiltrosMultiples(false);

    let valuesAND = [];
    let filterAND = aplazamientoState.listaFiltrosMultiplesTableAP.filter(function (item, index) {
      if (item.selectCondicion.length > 0) {
        return item.selectCondicion[0].value === 1 && item.status != 'E';
      }
    });

    for (let index = 0; index < filterAND.length; index++) {
      const element = filterAND[index];
      let fields = element.valueFields.map(function (item, index) {
        return { valor: item };
      });
      let obj = {
        campo: element.selectColumna[0].value,
        idTipoCampo: element.selectColumna[0].idTipo,
        tipoCampo: element.selectColumna[0].tipo,
        operador: {
          idOperador: element.selectOperador[0].value,
          nombre: element.selectOperador[0].label,
        },
        fields: fields,
      };
      valuesAND.push(obj);
    }

    let obj = {
      campo: 'MonedaPago',
      idTipoCampo: 1,
      tipoCampo: 'String',
      operador: {
        idOperador: 3,
        nombre: 'Múltiple',
      },
      fields: [],
    };
    let fieldsMoneda = [];
    aplazamientoState.selectMoneda.forEach((element) => {
      fieldsMoneda.push({ valor: element.value });
    });
    // aplazamientoState.selectMultiMonedaConversion
    //   .filter(function (item, index) {
    //     return item.value !== '*';
    //   })
    aplazamientoState.listMonedaSolucionTeaAP.forEach((element) => {
      fieldsMoneda.push({ valor: element.selectMonedaSolucion[0].value });
    });
    if (fieldsMoneda.length > 0) {
      obj.fields = fieldsMoneda;
      valuesAND.push(obj);
    }

    let valuesOR = [];
    let filterOR = aplazamientoState.listaFiltrosMultiplesTableAP.filter(function (item, index) {
      if (item.selectCondicion.length > 0) {
        return item.selectCondicion[0].value === 2 && item.status != 'E';
      }
    });

    for (let index = 0; index < filterOR.length; index++) {
      const element = filterOR[index];
      let fields = element.valueFields.map(function (item, index) {
        return { valor: item };
      });
      let obj = {
        campo: element.selectColumna[0].value,
        idTipoCampo: element.selectColumna[0].idTipo,
        tipoCampo: element.selectColumna[0].tipo,
        operador: {
          idOperador: element.selectOperador[0].value,
          nombre: element.selectOperador[0].label,
        },
        fields: fields,
      };
      valuesOR.push(obj);
    }

    var oParam = {
      // selectMoneda: aplazamientoState.selectMoneda,
      filtros: [
        {
          idTipoCondicion: 1,
          idTipoSolucion: 'AP',
          condicion: 'AND',
          values: valuesAND,
        },

        {
          idTipoCondicion: 2,
          idTipoSolucion: 'AP',
          condicion: 'OR',
          values: valuesOR,
        },
      ],
    };

    var regManualesAP = [];
    // dispatch(setRegManualesAP({ regManualesAP: regManualesAP }));
    dispatch(setSelectRegManualesAP([]));
    const responseRegistrarSol = await callEndpoint(obtenerRegistrosManualesMultiple(oParam));

    if (responseRegistrarSol === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseRegistrarSol.data) {
        if (responseRegistrarSol.data.cabecera.statusCode === 200) {
          // props.setButtonAction(0);

          if (responseRegistrarSol.data) {
            if (responseRegistrarSol.data.respuesta.aListaRegistrosAgrupado.length === 0) {
              dispatch(setRegManualesAP({ regManualesAP: regManualesAP }));
              Swal.fire({
                position: 'center',
                icon: 'info',
                title: responseRegistrarSol.data.cabecera.descripcion,
                showConfirmButton: false,
                timer: 2000,
              });
              return;
            } else {
              for (
                let index = 0;
                index < responseRegistrarSol.data.respuesta.aListaRegistrosAgrupado.length;
                index++
              ) {
                const element = responseRegistrarSol.data.respuesta.aListaRegistrosAgrupado[index];
                let obj = createRegistroManualAdapter(element);
                let nombre = '';
                obj.Items.forEach((element2) => {
                  // obj[element.Caracteristica] = "";
                  obj[element2.Caracteristica] = element2.Valor;
                });
                var aEncontradoLlave = obj.Items.filter(function (item) {
                  return item.Llave === 'X' && item.Valor !== '';
                });
                if (aEncontradoLlave.length > 0) {
                  obj['Nombre'] = aEncontradoLlave[0].Valor;
                }
                regManualesAP.push(obj);
              }

              dispatch(setRegManualesAP({ regManualesAP: regManualesAP }));
              dispatch(setSelectRegManualesAP([]));
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: responseRegistrarSol.data.cabecera.descripcion,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        } else if (responseRegistrarSol.data.cabecera.statusCode === 400) {
          Swal.fire({
            position: 'center',
            icon: 'info',
            title: responseRegistrarSol.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRegistrarSol.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }

    // } else {
    //   Swal.fire({
    //     position: 'center',
    //     icon: 'warning',
    //     title: 'No se pueden aplicar Filtros vaciós.',
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }
  };

  const changeMultiInput = (oEvent, item, index) => {
    dispatch(
      onSetRowMultiInputValueNultriEntryMultipleOperadorTableAP({
        valueFields: oEvent,
        index: index,
      })
    );
  };

  const onCancelModal = (oEvent) => {
    const listaFiltrosMultiplesTableAP = [...aplazamientoState.listaFiltrosMultiplesTableAP];
    var aEncontradoNoAplicados = listaFiltrosMultiplesTableAP.filter(function (item, index) {
      return item.status !== 'A' && item.selectColumna > 0;
    });

    var aEncontradoAplicados = listaFiltrosMultiplesTableAP.filter(function (item, index) {
      return item.status === 'A';
    });

    dispatch(
      onSetFiltroMultiplesTableAP({
        listaFiltrosMultiplesTableAP: aEncontradoAplicados,
        aEncontradoNoAplicados: aEncontradoNoAplicados,
      })
    );
    setModalFiltrosMultiples(false);
  };

  const onOpenModalFiltrosMultiples = () => {
    const { listaColumnasMultiplesGeneraltableAP, listaFiltrosMultiplesAppliedTableAP } =
      aplazamientoState;
    let newLista = [];

    for (let index = 0; index < listaColumnasMultiplesGeneraltableAP.length; index++) {
      var obj = { ...listaColumnasMultiplesGeneraltableAP[index] };

      var encontrado = listaFiltrosMultiplesAppliedTableAP
        .filter(function (item2, index2) {
          return item2.selectColumna.length > 0;
        })
        .filter(function (item3, index3) {
          return item3.selectColumna[0].value === this.value;
        }, obj);

      if (encontrado.length > 0) {
        obj.seleccionada = 1;
      }
      newLista.push(obj);
    }

    dispatch(
      setColumnsFiltroMultiplesTableAP({
        listaFiltrosMultiplesTableAP: aplazamientoState.listaFiltrosMultiplesAppliedTableAP,
        listaColumnasMultiplestableAP: newLista,
      })
    );

    setModalFiltrosMultiples(true);
  };

  const onCheckAplicarAll = () => {
    dispatch(setSelectRegManualesAP([]));
    dispatch(onChangeAplicarAllRegAP());
  };

  const addTableRows = () => {
    var uuid = uuidv4();
    const rowsInput = {
      idRow: uuid,
      selectMonedaSolucion: [],
      tea: '',
      bValidTea: null,
    };
    dispatch(onAddRowMonedaSolucionTableAP({ newItem: rowsInput }));
  };

  return (
    <Content>
      {
        <TabBarCreacion
          onClickTab1={onTabProcesoGeneralAP}
          tabProceso={tabProceso}
          labelTab1={'Registro'}
          onClickTab2={onTabDetalelAP}
          labelTab2={'Detalle'}
        />
      }
      {tabProceso === 0 ? (
        <Content>
          <ColFlex>
            <ContentForm>
              <SubTitle subtitle="1. Registrar datos generales de la solución." />
              <RowForm>
                <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                  <RowForm>
                    <LabelForm obligatorio={true} title={'Tipo Fomulario (*):'}></LabelForm>
                    <SelectForm
                      isNoClearable={true}
                      bValid={aplazamientoState.bValidselectTipoFormulario}
                      value={aplazamientoState.selectTipoFormulario}
                      handleChange={(e, v) => handleChangeTipoFormulario(v)}
                      options={TipoFormularioAplazamiento}
                      placeholder={'Seleccione Tipo Formulario'}
                    />
                  </RowForm>
                </ColForm>
                {obligatoriedadCampos(aplazamientoState.selectTipoFormulario)}
              </RowForm>

              <RowFooterButton>
                <ButtonForm
                  action={(e) => onPressCancelar()}
                  name="Cancelar"
                  isAction={true}
                  typeColor="warning"
                />
                <ButtonForm
                  action={(e) => limpiarForm(e)}
                  name="Limpiar"
                  isAction={true}
                  typeColor="primary"
                />
                <ButtonForm
                  action={(e) => onTabDetalelAP()}
                  name="Siguiente"
                  isAction={true}
                  typeColor="secondary"
                />
              </RowFooterButton>
            </ContentForm>
          </ColFlex>
        </Content>
      ) : (
        <Content>
          <ContentOption>
            <RowFlex>
              <LabelForm width={'100px'} obligatorio={true} title={'Automático :'}></LabelForm>
              <CheckboxStyle
                sx={{ alignSelf: 'center' }}
                checked={aplazamientoState.aplicarAllReg}
                onChange={() => onCheckAplicarAll()}
              />

              <ContentButton>
                <RowAccion>
                  <ColumnSelector
                    allColumns={columnSelector}
                    setColumn={setSelectColumn}
                    setAllColumn={setColumnSelector}
                  />
                  {/* <MultiSelectCheckboxes
                    options={
                      aplazamientoState.listaColumnasDinamicaTablaAP.length > 0
                        ? [
                            initiallAllColumnas,
                            ...aplazamientoState.listaColumnasDinamicaTablaAP.map(function (
                              item,
                              index
                            ) {
                              let { field, headerName } = { ...item };
                              return {
                                value: field,
                                label: headerName,
                              };
                            }),
                          ]
                        : []
                    }
                    // placeholderButtonLabel="Seleccione Posición"
                    getDropdownButtonLabel={getDropdownButtonLabel}
                    selectedOptions={aplazamientoState.selectColumnasDinamicaTablaAP}
                    onChange={(value, oEvent) => handleChangeColumnasMultiCheckbox(value, oEvent)}
                    placeholderButtonLabel={'Columnas'}
                    width={'250px'}
                  /> */}

                  {columnSelector.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      <ButtonForm
                        action={(e) => onOpenModalFiltrosMultiples()}
                        name="Filtros"
                        isAction={true}
                        typeColor="primary"
                      />
                      <ButtonForm
                        action={(e) =>
                          exportToExcel(
                            aplazamientoState.regManualesAP,
                            'Registro Aplazados',
                            mapColumnsDownloadExcel()
                          )
                        }
                        name="Exportar"
                        isAction={true}
                        typeColor="secondary"
                      />
                    </>
                  )}
                </RowAccion>
              </ContentButton>
            </RowFlex>
          </ContentOption>
          <ColFlex>
            <TableGrid
              columns={
                selectColumn.length > 0
                  ? [...selectColumn.filter((r) => r.seleccionado === Selected.TRUE)]
                  : [...columnSelector.filter((r) => r.seleccionado === Selected.TRUE)]
              }
              rows={aplazamientoState.regManualesAP}
              pageSize={15}
              getRowId={(row) => row.IDRegistro}
              checkboxSelection={!aplazamientoState.aplicarAllReg}
              onSelectionModelChange={(oEvent) => {
                dispatch(setSelectRegManualesAP(oEvent));
              }}
              selectionModel={aplazamientoState.selectRegManualesAP}
            />
            {/* {aplazamientoState.selectColumnasDinamicaTablaAP.length === 0 ? (
              <></>
            ) : ( */}
            <RowFooterButton>
              <ButtonForm
                action={(e) => onPressCancelar()}
                name="Cancelar"
                isAction={true}
                typeColor="warning"
              />
              <ButtonForm
                action={(e) => limpiarFormTabDetalle(e)}
                name="Limpiar"
                isAction={true}
                typeColor="primary"
              />
              <ButtonForm
                action={(e) => onPressGrabar()}
                name="Grabar"
                isAction={true}
                typeColor="secondary"
              />
            </RowFooterButton>
            {/* )} */}
          </ColFlex>

          <CustomFilterMixTable
            columns={aplazamientoState.listaColumnasMultiplestableAP.filter(function (item, index) {
              return item.seleccionada === 0;
            })}
            onCloseModal={() => setModalFiltrosMultiples(false)}
            onCancelModal={(oEvent) => onCancelModal()}
            openModal={modalFiltrosMultiples}
            filas={aplazamientoState.listaFiltrosMultiplesTableAP.filter(function (item, index) {
              return item.status === 'C' || item.status === 'A';
            })}
            onAddFiltro={onAddFiltro}
            onDeleteFiltro={onDeleteFiltro}
            onDeleteValor={onDeleteValor}
            onLimpiarFiltros={onLimpiarFiltros}
            handleChangeCondicion={handleChangeCondicion}
            handleChangeColumna={handleChangeColumna}
            handleChangeOperador={handleChangeOperador}
            changeMultiInput={changeMultiInput}
            onSelectValue={onSelectValue}
            onMultiSelectValue={onMultiSelectValue}
            handleChangeInput={handleChangeInput}
            handleChangeMultiInput={handleChangeMultiInput}
            handleKeyDownMultiInput={handleKeyDownMultiInput}
            handleCreateMultiInput={handleCreateMultiInput}
            listaOperaciones={aplazamientoState.listaTipoOperadorMultiplestableAP}
            onAplicarFiltrosMultiples={onAplicarFiltrosMultiples}
            fetchDataPaginate={fetchDataPaginate}
          ></CustomFilterMixTable>
        </Content>
      )}
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación del aplazamiento?"
        action={(e) => onConfirmarConcepto(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
      <ModalAsignarTEAMultiple onAsignarTEAMultiple={onAsignarTEAMultiple} />
      {loading === true ? <LoadingSpinner /> : <></>}
    </Content>
  );
};
