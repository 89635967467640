import React from 'react';
import styled from 'styled-components';
import { TableGridCaracteristica } from '../TableGridCaracteristica';
import { ButtonForm } from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  onAddRowMonedaSolucionTableAP,
  onChangeRowInputTeaSolucionTableAP,
  onChangeRowMonedaSolucionTableAP,
  onDeleteRowMultiMonedaMultiTableAP,
  onSetMonedaSolucionTableAP,
} from '../../../../../redux/states';
import { theme } from '../../../../../common';
import { swalAlertConfirm, swalAlertInformation } from '../../../../../common';
import { RowFlex } from '../../../../../styles';
import { v4 as uuidv4 } from 'uuid';
import { FaPlus } from 'react-icons/fa6';

export const AddDeleteTableRowsCaracteristica = ({
  rowsData,
  setRowsData,
  initiallAllPosition,
  getDropdownButtonLabel,
}) => {
  const dispatch = useDispatch();
  const aplazamientoState = useSelector((store) => store.aplazamiento);
  const addTableRows = () => {
    var uuid = uuidv4();
    const rowsInput = {
      idRow: uuid,
      selectMonedaSolucion: [],
      tea: '',
      bValidTea: null,
    };
    dispatch(onAddRowMonedaSolucionTableAP({ newItem: rowsInput }));
  };
  const deleteTableRows = async (data, index) => {
    let { isConfirmed } = await swalAlertConfirm({
      title: '¿Estas seguro que desea eliminar la moneda de Solución?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.colors.primary,
      cancelButtonColor: theme.colors.warning,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    });
    if (isConfirmed) {
      dispatch(
        onDeleteRowMultiMonedaMultiTableAP({
          index: index,
          selectCaracteristica: data.selectCaracteristica,
          data: data,
        })
      );
    }
  };

  const handleChangeInputTeaMultiMoneda = (event, data, index) => {
    // const rowsInput = [...rowsData];
    let bValidTea = null;
    let tea = event.value;
    if (!event.floatValue) {
      bValidTea = false;
    } else {
      bValidTea = true;
    }

    dispatch(onChangeRowInputTeaSolucionTableAP({ index: index, tea: tea, bValidTea: bValidTea }));
  };

  const handleChangeMonedaSolucion = async (oEvent, data, index) => {
    var oParam = {};
    oParam.bValidMonedaSolucion = null;
    oParam.selectMonedaSolucion = [];
    oParam.index = index;
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectMonedaSolucion.push(obj);
      oParam.bValidMonedaSolucion = true;
    }
    if (oParam.selectMonedaSolucion.length > 0) {
      let aEncontradoRepetido = aplazamientoState.listMonedaSolucionTeaAP
        .filter(function (item, index) {
          return item.selectMonedaSolucion.length > 0;
        })
        .filter(function (item2, index2) {
          return item2.selectMonedaSolucion[0].value === oParam.selectMonedaSolucion[0].value;
        });
      if (aEncontradoRepetido.length > 0) {
        let result = await swalAlertInformation({
          position: 'center',
          icon: 'warning',
          title:
            'La moneda ' + oParam.selectMonedaSolucion[0].label + ' ha seleccionada previamente.',
          showConfirmButton: false,
          timer: 2500,
        });

        // Swal.fire({
        //   position: 'center',
        //   icon: 'warning',
        //   title:
        //     'La función :' +
        //     conceptoState.selectFuncion[0].label +
        //     ' no posee características registradas.',
        //   showConfirmButton: false,
        //   timer: 2000,
        // });

        return;
      }
    }
    dispatch(onChangeRowMonedaSolucionTableAP(oParam));
    // dispatch(selectedMonedaAP(oParam));
  };

  const onClearTableMonedasSolucion = async () => {
    dispatch(onSetMonedaSolucionTableAP({ listMonedaSolucionTeaAP: [] }));
  };

  return (
    <Styles>
      <RowFlex
        style={{
          alignItems: 'end',
          justifyContent: 'end',
        }}
      >
        <ButtonForm
          action={(e) => onClearTableMonedasSolucion()}
          name="Limpiar Tabla"
          isAction={false}
          typeColor="warning"
        />
      </RowFlex>
      <table border="0" cellspacing="0">
        <thead>
          <tr>
            <th> Moneda </th>
            <th> TEA (%) </th>
            <th>
              {/* <ButtonTable
                icon={<FaPlus />}
                backgroundButton={${theme.colors.primary}}
                colorText={'white'}
                backgroundHoverButton={'white'}
                action={addTableRows}
                isAction={true}
              /> */}
              <ButtonForm
                action={addTableRows}
                isOnlyIcon={true}
                icon={<FaPlus />}
                isAction={true}
                typeColor="primary"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <TableGridCaracteristica
            initiallAllPosition={initiallAllPosition}
            rowsData={aplazamientoState.listMonedaSolucionTeaAP}
            deleteTableRows={deleteTableRows}
            handleChangeInputTeaMultiMoneda={handleChangeInputTeaMultiMoneda}
            handleChangeMonedaSolucion={handleChangeMonedaSolucion}
          />
        </tbody>
      </table>
    </Styles>
  );
};
const Styles = styled.div`
  /* width: fit-content; */
  overflow: auto;
  max-height: 50vh;
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  table th,
  tfoot td {
    background-color: #f8fafd !important;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: #677788;
    text-transform: 'uppercase';
    font-weight: '600 !important';
    font-family: "'DM SANS' !important";
    padding-left: 5px;
  }
  th,
  td {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  table tbody {
    font-family: "'DM SANS' !important";
  }

  width: 100%;
  max-width: 600px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;
