import React, { useState, useRef } from 'react';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import { Popconfirm, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import 'react-sortable-tree/style.css';
import { addNodeUnderParent } from 'react-sortable-tree';
import { removeNodeAtPath, getNodeAtPath } from 'react-sortable-tree';

import { useDispatch, useSelector } from 'react-redux';
import { HeaderCard, Button, ButtonForm } from '../../../../components';
import { onSetDataModalAgregarEstructura } from '../../../../redux/states';
import { v4 as uuidv4 } from 'uuid';
import { ButtonStandar, CardTree, CardTreeBody, IconButton, RowFlex } from '../../../../styles';
import { theme } from '../../../../common';
import { ModalAgregarNodo, ModalEditarNodo } from './components';
import { initialAgregarNodo } from './misc';
import {
  FaAngleDown,
  FaEllipsisVertical,
  FaPenToSquare,
  FaPlus,
  FaTrashCan,
} from 'react-icons/fa6';

export const CardEgreso = (props) => {
  const dispatch = useDispatch();
  const ref3 = useRef(null);
  const open = () => props.setExpandedEgreso(!props.expandedEgreso);
  const [formAgregarNodo, setFormAgregarNodo] = useState({});
  const [formEditarNodo, setFormEditarNodo] = useState({});
  const [newRowInfoTree, setNewRowInfoTree] = useState({});
  const estructuraState = useSelector((store) => store.estructura);
  const [openModalAgregarE, setOpenModalAgregarE] = useState(false);
  const [openModalEditarE, setOpenModalEditarE] = useState(false);

  const handleTreeOnChange = (treeData) => {
    props.setTreeState({ ...props.treeState, treeData });
  };

  const alertNodeInfo = ({ node, path, treeIndex }) => {
    setNewRowInfoTree({
      node: node,
      path: path,
      treeIndex: treeIndex,
      titleActual: node.title,
    });
    var oFormEditarNodo = {
      iId: node.iId,
      sCadena: node.sCadena,
      nombreNodo: node.title,
      iIdConceptoNodo: node.iIdConceptoNodo,
      sConceptoNodo: node.sConceptoNodo,
      error: false,
      isDisabled: false,
      nodeInfo: {
        node: node,
        path: path,
        treeIndex: treeIndex,
        titleActual: node.title,
      },
    };
    if (node.iIdConceptoNodo && path.length > 1) {
      oFormEditarNodo.isDisabled = true;
    }
    setFormEditarNodo(oFormEditarNodo);
    dispatch(
      onSetDataModalAgregarEstructura({
        aListaConceptosXtipoEstructura: estructuraState.aListaConceptosXtipoEgreso,
      })
    );
    setOpenModalEditarE(true);
  };

  const addNewNode = (bEsCabecera, rowInfo) => {
    setNewRowInfoTree({
      rowInfo: rowInfo,
    });
    var oFormAgregarNodo = { ...initialAgregarNodo };
    var uuid = uuidv4();
    if (!bEsCabecera && rowInfo.node.iIdConceptoNodo) {
      oFormAgregarNodo.iId = uuid;
      oFormAgregarNodo.sCadena = rowInfo.node.sCadena + '/' + uuid;
      oFormAgregarNodo.isDisabled = true;
      oFormAgregarNodo.iIdConceptoNodo = rowInfo.node.iIdConceptoNodo;
      oFormAgregarNodo.sConceptoNodo = rowInfo.node.sConceptoNodo;
    } else {
      oFormAgregarNodo.iId = uuid;
      if (rowInfo) {
        oFormAgregarNodo.sCadena = rowInfo.node.sCadena + '/' + uuid;
        oFormAgregarNodo.disabledMirror = false;
      } else {
        oFormAgregarNodo.sCadena = uuid;
        oFormAgregarNodo.disabledMirror = true;
      }
    }
    setFormAgregarNodo(oFormAgregarNodo);
    setOpenModalAgregarE(true);
    dispatch(
      onSetDataModalAgregarEstructura({
        aListaConceptosXtipoEstructura: estructuraState.aListaConceptosXtipoEgreso,
      })
    );
  };

  const obtenerPosicionesRecursivo = (aListaHijos, aListaPosiciones) => {
    for (let index = 0; index < aListaHijos.length; index++) {
      const element = aListaHijos[index];
      if (element.tipo === 'P') {
        aListaPosiciones.push(element);
      } else {
        if (element.children === null) {
          obtenerPosicionesRecursivo([], aListaPosiciones);
        } else {
          obtenerPosicionesRecursivo(element.children, aListaPosiciones);
        }
      }
    }
  };

  const removeNode = (rowInfo) => {
    const { path } = rowInfo;
    let getNodeKey = ({ treeIndex }) => 1;
    const parentNode = getNodeAtPath({
      treeData: props.treeState.treeData,
      path: [1],
      getNodeKey,
    });
    const newTree = removeNodeAtPath({
      treeData: props.treeState.treeData,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });
    props.setTreeState({
      ...props.treeState,
      treeData: newTree,
    });
    if (rowInfo.node.children.length > 0) {
      var aIDConceptoSUB = [];
      var aListaPosicionesXeliminar = rowInfo.node.children.filter(function (item, index) {
        return 'P' === item.tipo;
      });
      var aListaHijo = rowInfo.node.children.filter(function (item, index) {
        return 'C' === item.tipo;
      });
      if (aListaHijo.length > 0) {
        obtenerPosicionesRecursivo(aListaHijo, aListaPosicionesXeliminar);
      }
      aListaPosicionesXeliminar.forEach((element) => {
        aIDConceptoSUB.push(element.iIDConceptoSUB);
      });
      var aListaFinal = props.selectPosicionAsignada.filter(function (item, index) {
        return !aIDConceptoSUB.includes(item.iIDConceptoSUB);
      });
      props.setSelectPosicionAsignada(aListaFinal);
    }
  };

  const removeNodePosicion = (rowInfo) => {
    const { path } = rowInfo;
    let getNodeKey = ({ treeIndex }) => 1;
    const parentNode = getNodeAtPath({
      treeData: props.treeState.treeData,
      path: [1],
      getNodeKey,
    });
    const newTree = removeNodeAtPath({
      treeData: props.treeState.treeData,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });
    props.setTreeState({
      ...props.treeState,
      treeData: newTree,
    });
    var aListaFinal = props.selectPosicionAsignada.filter(function (item, index) {
      return rowInfo.node.iIDConceptoSUB !== item.iIDConceptoSUB;
    });
    props.setSelectPosicionAsignada(aListaFinal);
    // }
    // }
  };

  const canDragCardEgreso = ({ node, nextParent }) => {
    return !node.noDragging;
  };

  const canDragDropEgreso = ({ nextParent, node }) => {
    if (node.tipo === 'P') {
      Swal.fire({
        icon: 'warning',
        title: 'Ups!',
        text: 'No es posible mover posiciones anidadas a una carpeta.',
      });
      return null;
    } else {
      return !nextParent || !nextParent.noChildren;
    }
  };

  return (
    <>
      <CardTree>
        <RowFlex>
          <div class="container_card">
            <HeaderCard title="Egresos : " color={true} />
            {props.expandedEgreso ? (
              <ButtonForm
                action={(e) => addNewNode(true, null)}
                icon={<FaPlus />}
                isAction={false}
                isOnlyIcon={true}
                typeColor="primary"
                size={'16px'}
              />
            ) : (
              <></>
            )}
            <ButtonForm
              action={(e) => open(e)}
              icon={<FaAngleDown />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
              size={'16px'}
              style={{ marginLeft: 'auto' }}
            />
          </div>
        </RowFlex>
        <CardTreeBody className={props.expandedEgreso ? 'show' : ''} ref={ref3}>
          <SortableTree
            theme={FileExplorerTheme}
            treeData={props.treeState.treeData}
            onChange={handleTreeOnChange}
            onMoveNode={({ node, treeIndex, path }) =>
              global.console.debug('node:', node, 'treeIndex:', treeIndex, 'path:', path)
            }
            searchQuery={props.treeState.searchString}
            searchFocusOffset={props.treeState.searchFocusIndex}
            canDrag={canDragCardEgreso}
            canDrop={canDragDropEgreso}
            searchFinishCallback={(matches) =>
              props.setTreeState({
                ...props.treeState,
                searchFoundCount: matches.length,
                searchFocusIndex:
                  matches.length > 0 ? props.treeState.searchFocusIndex % matches.length : 0,
              })
            }
            rowHeight={52}
            isVirtualized={false}
            style={{ borderRadius: '50px', width: '100%' }}
            generateNodeProps={(rowInfo) => {
              const { node, lowerSiblingCounts } = rowInfo;

              if (node.tipo === 'P') {
                // if (node.children === undefined) {
                if (false) {
                  return {
                    buttons: [],
                    style: { borderRadius: `10px`, width: '' },
                  };
                } else {
                  return {
                    buttons: [
                      <>
                        <Popconfirm
                          title="¿Deseas eliminar la posición?"
                          okText="Si"
                          cancelText="No"
                          onConfirm={() => removeNodePosicion(rowInfo)}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          okButtonProps={{
                            style: {
                              backgroundColor: theme.colors.primary,
                              borderColor: theme.colors.primary,
                            },
                          }}
                        >
                          <ButtonStandar
                            icon={<FaTrashCan />}
                            isAction={false}
                            isOnlyIcon={true}
                            typeColor="primary"
                          >
                            <IconButton isAction={false} typeColor="primary">
                              <FaTrashCan />
                            </IconButton>
                          </ButtonStandar>
                        </Popconfirm>
                      </>,
                    ],
                    style: { borderRadius: `10px`, width: '' },
                  };
                }
              } else {
                return {
                  buttons: [
                    <Popover
                      content={
                        <>
                          <Popconfirm
                            title="¿Deseas eliminar la carpeta?"
                            okText="Si"
                            cancelText="No"
                            onConfirm={() => removeNode(rowInfo)}
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            okButtonProps={{
                              style: {
                                backgroundColor: theme.colors.primary,
                                borderColor: theme.colors.primary,
                              },
                            }}
                          >
                            <ButtonStandar
                              icon={<FaTrashCan />}
                              isAction={false}
                              isOnlyIcon={true}
                              typeColor="primary"
                              style={{ padding: '6px' }}
                            >
                              <IconButton isAction={false} typeColor="primary">
                                <FaTrashCan />
                              </IconButton>
                            </ButtonStandar>
                          </Popconfirm>
                          <ButtonForm
                            action={() => alertNodeInfo(rowInfo)}
                            icon={<FaPenToSquare />}
                            isAction={false}
                            isOnlyIcon={true}
                            typeColor="primary"
                            style={{ padding: '6px' }}
                          />
                          <ButtonForm
                            action={() => addNewNode(false, rowInfo)}
                            icon={<FaPlus />}
                            isAction={false}
                            isOnlyIcon={true}
                            typeColor="primary"
                            style={{ padding: '6px' }}
                          />
                        </>
                      }
                      trigger="click"
                    >
                      <ButtonStandar
                        icon={<FaEllipsisVertical />}
                        isAction={false}
                        isOnlyIcon={true}
                        typeColor="primary"
                      >
                        <IconButton isAction={false} typeColor="primary">
                          <FaEllipsisVertical />
                        </IconButton>
                      </ButtonStandar>
                    </Popover>,
                  ],
                  style: { borderRadius: `10px`, width: '' },
                };
              }
            }}
          />
        </CardTreeBody>
      </CardTree>

      <ModalAgregarNodo
        open={openModalAgregarE}
        setOpen={setOpenModalAgregarE}
        formAgregarNodo={formAgregarNodo}
        setFormAgregarNodo={setFormAgregarNodo}
        newRowInfoTree={newRowInfoTree}
        treeState={props.treeState}
        setTreeState={props.setTreeState}
        tipoEstructura={'Egreso'}
      />

      <ModalEditarNodo
        open={openModalEditarE}
        setOpen={setOpenModalEditarE}
        newRowInfoTree={newRowInfoTree}
        treeState={props.treeState}
        setTreeState={props.setTreeState}
        formEditarNodo={formEditarNodo}
        setFormEditarNodo={setFormEditarNodo}
      />
    </>
  );
};
