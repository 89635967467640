import styled from 'styled-components';
import { theme } from '../common';

export const ButtonDobleIcon = styled.button`
  z-index: 2;
  background-color: ${theme.colors.white};
  border-color: ${theme.colors.line};
  cursor: pointer;
  padding: 5px 5px;
  border-radius: 0.25rem;
  outline: none;
  font-size: 12px;
  font-weight: 400;
  font-family: ${theme.font.family};
  border: 0.0625rem solid transparent;
  box-shadow: 0 0 2px #999;
  color: ${({ show }) => (show === true ? theme.colors.primary : theme.colors.text)};
  div {
    width: 100px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 0.0625rem solid rgb(140 152 164 / 25%);
    color: ${theme.colors.primary};
    font-weight: 500 !important;
    box-shadow: 0 3px 6px -2px rgb(140 152 164 / 25%);
  }
`;

export const IconButton = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed !important' : 'pointer')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-size: ${({ size }) => (size ? size : '14px')};
  color: ${({ disabled, isOnlyIcon, isAction, typeColor, noBackground }) =>
    disabled
      ? isOnlyIcon && !isAction
        ? 'gray'
        : theme.colors.white
      : !isAction
      ? noBackground
        ? theme.colors.white
        : typeColor
        ? typeColor === 'secondary'
          ? theme.colors.secondary
          : typeColor === 'tertiary'
          ? theme.colors.tertiary
          : typeColor === 'warning'
          ? theme.colors.warning
          : typeColor === 'text'
          ? theme.colors.text
          : theme.colors.primary
        : theme.colors.primary
      : theme.colors.white};

  transition: all 0.3s ease-in-out;
  &.rotateimg180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out; /* added */
  }
`;
export const ButtonStandar = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed !important' : 'pointer')};
  gap: 10px;
  z-index: 2;
  outline: 0;
  font-weight: 400;
  border-radius: 0.25rem;
  font-family: ${theme.font.family};
  font-size: ${({ size }) => (size ? size : '12px')};
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  box-shadow: ${({ isOnlyIcon, isAction, noBackground }) =>
    (isOnlyIcon && !isAction) || noBackground ? 'none' : '0 0 2px #999'};
  padding: ${({ isOnlyIcon, isAction, icon, name, noBackground }) =>
    name
      ? icon
        ? '5px 15px 5px 15px'
        : '5px 15px 5px 15px'
      : icon
      ? isOnlyIcon && !isAction
        ? '0px 3px'
        : '5px 10px 5px 10px'
      : '5px 10px 5px 10px'};
  background: ${({ isOnlyIcon, isAction, disabled, typeColor, noBackground }) =>
    disabled
      ? isOnlyIcon && !isAction
        ? 'none'
        : 'gray'
      : noBackground
      ? 'none'
      : isOnlyIcon && !isAction
      ? 'none'
      : isAction
      ? typeColor
        ? typeColor === 'secondary'
          ? theme.colors.secondary
          : typeColor === 'tertiary'
          ? theme.colors.tertiary
          : typeColor === 'warning'
          ? theme.colors.warning
          : theme.colors.primary
        : theme.colors.primary
      : theme.colors.white};
  border-style: ${({ isOnlyIcon, isAction, noBackground }) =>
    (isOnlyIcon && !isAction) || noBackground ? 'none' : 'solid'};
  border-color: ${({ isOnlyIcon, disabled, typeColor, isAction, noBackground }) =>
    disabled
      ? isOnlyIcon && !isAction
        ? 'none'
        : 'gray'
      : noBackground
      ? 'none'
      : isOnlyIcon && !isAction
      ? 'none'
      : typeColor
      ? typeColor === 'secondary'
        ? theme.colors.secondary
        : typeColor === 'tertiary'
        ? theme.colors.tertiary
        : typeColor === 'warning'
        ? theme.colors.warning
        : theme.colors.primary
      : theme.colors.primary};

  &:hover {
    transform: ${({ isAction, noBackground }) =>
      noBackground && isAction ? ' scale(1.09)' : 'scale(1)'};
    background: ${({ disabled, isOnlyIcon, isAction, noBackground }) =>
      disabled
        ? isOnlyIcon
          ? 'none'
          : 'gray'
        : noBackground
        ? 'none'
        : isOnlyIcon && !isAction
        ? 'none'
        : theme.colors.white};
    ${IconButton} {
      color: ${({ disabled, isOnlyIcon, isAction, typeColor, noBackground }) =>
        disabled
          ? isOnlyIcon && !isAction
            ? 'gray'
            : theme.colors.white
          : noBackground && !isAction
          ? theme.colors.white
          : isOnlyIcon && !isAction
          ? 'gray'
          : typeColor
          ? typeColor === 'secondary'
            ? theme.colors.secondary
            : typeColor === 'tertiary'
            ? theme.colors.tertiary
            : typeColor === 'warning'
            ? theme.colors.warning
            : theme.colors.primary
          : theme.colors.primary};
    }
  }
`;
