import React, { useEffect, useState } from 'react';
import {
  SearchInputText,
  ModalConfirmation,
  LoadingSpinner,
  TableGrid,
  ButtonAccion,
  ButtonForm,
} from '../../../../components';
import {
  obtenerSoluciones,
  eliminarSoluciones,
  obtenerInfoSolucion,
  obtenerAgentes,
  obtenerListadoMoneda,
} from '../../../../services';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListaSolTipoCambio,
  filterListarSolTipoCambio,
  limpiarEditCamposSOLTC,
  limpiarCamposSOLTC,
} from '../../../../redux/states';
import { updateTitle } from '../../../../redux/states';
import {
  createAgenteSelectAdapter,
  createEstadoSolucionSelectAdapter,
  createMonedaAbreviaturaAdapter,
  createTiempoPeriodoAdapter,
} from '../../../../adapters';
import Swal from 'sweetalert2';
import { buildPath, EstadoSolucionesData, paths } from '../../../../common';
import { TiempoPeriodosData } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import { createSolTipoCambioAdapter } from '../../../../adapters/createSolTipoCambio.adapter';
import {
  CheckboxStyle,
  ColFlex,
  Content,
  ContentButton,
  ContentOption,
  RowAccion,
  RowFlex,
} from '../../../../styles';
import { Dropdown } from 'antd';
import { items } from './misc';
import { FaPenToSquare } from 'react-icons/fa6';

export const ListarTipoCambio = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [selectedSolTipoCambio, setSelectedSolTipoCambio] = useState([]);
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const solTipoCambioState = useSelector((store) => store.solTipoCambio);
  const personalizacionState = useSelector((store) => store.personalizacion);

  useEffect(() => {
    dispatch(updateTitle({ title: 'Listar Sol. Tipo de Cambio' }));
    (async () => {
      await loadSolTipoCambio();
    })();
  }, []);

  const loadSolTipoCambio = async () => {
    const responseListaSolTipoCambio = await callEndpoint(
      obtenerSoluciones({
        oTipoSolucion: ['TC'],
      })
    );

    var listaSolTipoCambio = [];
    if (responseListaSolTipoCambio != null) {
      if (responseListaSolTipoCambio.data) {
        if (responseListaSolTipoCambio.data.cabecera.statusCode === 200) {
          for (
            let index = 0;
            index < responseListaSolTipoCambio.data.respuesta.aListaSolucion.length;
            index++
          ) {
            const element = responseListaSolTipoCambio.data.respuesta.aListaSolucion[index];
            var obj = createSolTipoCambioAdapter(element);
            listaSolTipoCambio.push(obj);
          }
        }
      }
    }
    dispatch(setListaSolTipoCambio({ listaSolTipoCambio: listaSolTipoCambio }));
  };

  const findSolTipoCambio = (person) => {
    return !!selectedSolTipoCambio.find((p) => p.idSolTipoCambio === person.idSolTipoCambio);
  };

  const filterConcepto = (person) => {
    return selectedSolTipoCambio.filter((p) => p.idSolTipoCambio !== person.idSolTipoCambio);
  };

  const handleChange = (person) => {
    const filteredPeople = findSolTipoCambio(person)
      ? filterConcepto(person)
      : [...selectedSolTipoCambio, person];
    setSelectedSolTipoCambio(filteredPeople);
  };

  const onClickEditarConcepto = async (row) => {
    dispatch(limpiarEditCamposSOLTC());
    const responseObtAgentes = await callEndpoint(obtenerAgentes());
    let aAgentes = [];
    if (responseObtAgentes != null) {
      if (responseObtAgentes.data) {
        let { aListaAgentes } = responseObtAgentes.data.respuesta;
        aListaAgentes.forEach((element) => {
          aAgentes.push(createAgenteSelectAdapter(element));
        });
      }
    }
    const responseObtMonedas = await callEndpoint(obtenerListadoMoneda());
    let aMonedas = [];
    if (responseObtMonedas != null) {
      if (responseObtMonedas.data) {
        let { aListaMoneda } = responseObtMonedas.data.respuesta;
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'texto_moneda'
        )[0].DatoPersonalizacion;
        aListaMoneda.forEach((element) => {
          aMonedas.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
        });
      }
    }
    let aEstadoSol = [];
    EstadoSolucionesData.forEach((element) => {
      aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
    });
    let aTiempoPeriodo = [];
    TiempoPeriodosData.forEach((element) => {
      aTiempoPeriodo.push(createTiempoPeriodoAdapter(element));
    });
    const responseInfoSolucion = await callEndpoint(
      obtenerInfoSolucion({
        oSolucion: {
          IDSolucionBase: row.idSolTipoCambio,
        },
      })
    );

    if (responseInfoSolucion != null) {
      if (responseInfoSolucion.data) {
        // var oSolucion = responseInfoSolucion.data.respuesta.aInfoSolucion[0];

        navigate(buildPath(paths.SOLUTION.EXCHANGE_RATE.EDIT, { id: row.idSolTipoCambio }));
      } else {
      }
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <CheckboxStyle
              size="small"
              checked={findSolTipoCambio(params.row)}
              onChange={() => handleChange(params.row)}
            />
          }
        </>
      ),
    },
    {
      field: 'codigo',
      headerName: 'Código',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'CXV',
      headerName: 'Tipo',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'descripcion',
      headerName: 'Descripción',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'agente',
      headerName: 'Agente',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'fechaVigencia',
      headerName: 'Fecha de Vigencia',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <ButtonForm
              action={(oEvent) => onClickEditarConcepto(params.row)}
              icon={<FaPenToSquare />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
            />
          }
        </>
      ),
    },
  ];

  const onAgregarConcepto = () => {
    dispatch(limpiarCamposSOLTC());
    navigate(paths.SOLUTION.EXCHANGE_RATE.CREATE);
    dispatch(updateTitle({ title: 'Crear Solución Tipo de cambio' }));
  };

  const onClickEliminarConcepto = () => {
    if (selectedSolTipoCambio.length > 0) {
      setShowModalEliminar(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarSolTipoCambio(oEvent.target.value));
    } else {
      dispatch(filterListarSolTipoCambio(''));
    }
  };

  const onCloseModalEliminar = () => {
    setShowModalEliminar(false);
  };

  const onConfirmarEliminar = async () => {
    var aListaSolucionEliminar = [];
    selectedSolTipoCambio.forEach((element) => {
      var o = {};
      o.idSolucion = element.idSolTipoCambio;
      aListaSolucionEliminar.push(o);
    });
    const responseListaSolTipoCambio = await callEndpoint(
      eliminarSoluciones({ aListaSolucionEliminar: aListaSolucionEliminar })
    );
    setShowModalEliminar(false);
    if (responseListaSolTipoCambio === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseListaSolTipoCambio.data.cabecera.statusCode === 200) {
        loadSolTipoCambio();
        setSelectedSolTipoCambio([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseListaSolTipoCambio.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseListaSolTipoCambio.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return (
    <Content>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              placeholder={'Buscar solTipoCambio...'}
              filter={solTipoCambioState.searchtext}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Dropdown trigger={['click']} menu={{ items: items(onClickEliminarConcepto) }}>
                  <ButtonAccion />
                </Dropdown>

                <ButtonForm action={onAgregarConcepto} name="Agregar" isAction={true} />
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        <TableGrid
          columns={columns}
          rows={
            solTipoCambioState.searchtext.length === 0
              ? solTipoCambioState.listaSolTipoCambio
              : solTipoCambioState.filterListaSolTipoCambio
          }
          pageSize={15}
          getRowId={(row) => row.idSolTipoCambio}
        />
      </ColFlex>

      <ModalConfirmation
        showModal={showModalEliminar}
        closeModal={(e) => onCloseModalEliminar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </Content>
  );
};
