import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword, sendForgotPasswordCode } from '../../services';
import { changeDataChangePassword, changeUserName, limpiarCamposLogin } from '../../redux/states';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import logoPanel from '../../assets/images/logo-login.png';
import {
  BackgroundLogin,
  ButtonLogin,
  ButtonReSend,
  ColForm,
  ContainerInputLogin,
  ContainLogin,
  FormLogin,
  ImgLogin,
  InputLogin,
  LabelLogin,
  ModalLogin,
  RowForm,
} from '../../styles';
import { Popover } from 'antd';
import {
  paths,
  tieneCaracterEspecial,
  tieneMasDeOchoCaracteres,
  tieneMayuscula,
  tieneMinuscula,
  tieneNumero,
} from '../../common';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export const RecoveryPassword = () => {
  const loginState = useSelector((store) => store.login);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCon, setShowPasswordCon] = useState(false);
  const [showFormPassword, setShowFormPassword] = useState(false);

  const handlechangeUserName = (oEvent) => {
    let userName = oEvent.target.value;
    let oParam = {
      userNameRP: userName,
    };
    dispatch(changeUserName(oParam));
  };

  const handlechangeCode = (oEvent) => {
    let code = oEvent.target.value;
    let oParam = {
      code: code,
    };
    dispatch(changeUserName(oParam));
  };

  const handlechangePassword = (oEvent) => {
    let password = oEvent.target.value;
    let oParam = {
      passwordCP: password,
    };
    if (validarPassword(oEvent.target.value)) {
      oParam.bValidPasswordCP = true;
    } else {
      oParam.bValidPasswordCP = false;
    }
    dispatch(changeDataChangePassword(oParam));
  };

  const validarPassword = (v) => {
    let oParam = {};
    let validPasswordMust = [];

    validPasswordMust.push(tieneNumero(v));
    validPasswordMust.push(tieneMayuscula(v));
    validPasswordMust.push(tieneMinuscula(v));
    validPasswordMust.push(tieneCaracterEspecial(v));
    validPasswordMust.push(tieneMasDeOchoCaracteres(v));

    oParam.bValidPasswordMustCP = validPasswordMust;

    dispatch(changeDataChangePassword(oParam));

    return !validPasswordMust.includes(false);
  };

  const handlechangePasswordConfirm = (oEvent) => {
    let password = oEvent.target.value;
    let oParam = {
      passwordCofirmCP: password,
    };
    if (oEvent.target.value === loginState.passwordCP) {
      oParam.bValidPasswordConfirmCP = true;
    } else {
      oParam.bValidPasswordConfirmCP = false;
    }
    dispatch(changeDataChangePassword(oParam));
  };

  const passwordTooltip = () => {
    return (
      <>
        <li style={loginState.bValidPasswordMustCP[0] ? { color: 'green' } : {}}>
          Tener un número
        </li>
        <li style={loginState.bValidPasswordMustCP[1] ? { color: 'green' } : {}}>
          Tener un carácter en mayúscula
        </li>
        <li style={loginState.bValidPasswordMustCP[2] ? { color: 'green' } : {}}>
          Tener un carácter en minúscula
        </li>
        <li style={loginState.bValidPasswordMustCP[3] ? { color: 'green' } : {}}>
          Tener un carácter especial
        </li>
        <li style={loginState.bValidPasswordMustCP[4] ? { color: 'green' } : {}}>
          Tener mínimo 8 caracteres
        </li>
      </>
    );
  };

  const funcionRecovery = async (event) => {
    event.preventDefault();

    const response = await sendForgotPasswordCode(loginState.userNameRP);
    if (response.statusCode === 200) {
      setShowFormPassword(true);
    } else if (response.statusCode === 400) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Error al querer hacer el envio de correo.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const funcionRecoveryPas = async (event) => {
    event.preventDefault();
    const response = await resetPassword(
      loginState.userNameRP,
      loginState.code,
      loginState.passwordCP
    );
    if (response.statusCode === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Se hizo el cambio de contraseña correctamente.',
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(limpiarCamposLogin());
      setTimeout(function () {
        navigate(paths.LOGIN.BASE);
      }, 4000);
    } else if (response.statusCode === 400) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Error al querer hacer el cambio de contraseña.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <BackgroundLogin>
      <ContainLogin>
        <ModalLogin className="row no-gutter">
          <RowForm style={{ alignSelf: 'flex-start' }}>
            <ColForm xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '15px 0px ' }}>
              <ImgLogin src={logoPanel} alt="Italian Trulli" />
            </ColForm>
            <ColForm xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
              <h2>Recuperar contraseña</h2>
            </ColForm>
            <FormLogin
              onSubmit={funcionRecovery}
              style={showFormPassword ? { display: 'none' } : {}}
            >
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <LabelLogin>Correo</LabelLogin>
                <ContainerInputLogin>
                  <InputLogin
                    placeholder="Ingrese correo"
                    type={'text'}
                    value={loginState.userNameRP}
                    onChange={(oEvent) => handlechangeUserName(oEvent)}
                  ></InputLogin>
                </ContainerInputLogin>
              </ColForm>
              <ColForm
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginTop: '20px', padding: '5px 0px ' }}
              >
                <ButtonLogin type="Submit">Enviar Correo</ButtonLogin>
              </ColForm>
            </FormLogin>

            <FormLogin
              onSubmit={funcionRecoveryPas}
              style={!showFormPassword ? { display: 'none' } : {}}
            >
              <RowForm>
                <ColForm xs={11} sm={11} md={11} lg={11} xl={11} style={{ textAlign: 'center' }}>
                  <h6>Se envio el codigo de verificación</h6>
                </ColForm>
                <ColForm xs={11} sm={11} md={11} lg={11} xl={11}>
                  <ButtonReSend type="button" onClick={funcionRecovery}>
                    Reenviar Correo
                  </ButtonReSend>
                </ColForm>
              </RowForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <LabelLogin>Codigo</LabelLogin>
                <ContainerInputLogin>
                  <InputLogin
                    placeholder="Ingrese codigo"
                    type={'text'}
                    value={loginState.code}
                    onChange={(oEvent) => handlechangeCode(oEvent)}
                  ></InputLogin>
                </ContainerInputLogin>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <LabelLogin>Nueva Contraseña</LabelLogin>
                <ContainerInputLogin>
                  <Popover
                    content={passwordTooltip}
                    title={'Contraseña debe contener'}
                    trigger="click"
                  >
                    <InputLogin
                      placeholder="Ingrese nueva contraseña"
                      type={showPassword ? 'text' : 'password'}
                      value={loginState.passwordCP}
                      onChange={(oEvent) => handlechangePassword(oEvent)}
                      validate={loginState.bValidPasswordCP}
                    />
                  </Popover>
                  {!showPassword ? (
                    <AiOutlineEye
                      className="login"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="login"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </ContainerInputLogin>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <LabelLogin>Confirmar Contraseña</LabelLogin>
                <ContainerInputLogin>
                  <InputLogin
                    placeholder="Confirmar contraseña"
                    type={showPasswordCon ? 'text' : 'password'}
                    value={loginState.passwordCofirmCP}
                    onChange={(oEvent) => handlechangePasswordConfirm(oEvent)}
                    validate={loginState.bValidPasswordConfirmCP}
                  />
                  {!showPasswordCon ? (
                    <AiOutlineEye
                      className="login"
                      onClick={() => setShowPasswordCon(!showPasswordCon)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="login"
                      onClick={() => setShowPasswordCon(!showPasswordCon)}
                    />
                  )}
                </ContainerInputLogin>
              </ColForm>
              <ColForm
                style={{ marginTop: '20px', padding: '5px 0px ' }}
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <ButtonLogin type="submit">Cambiar contraseña</ButtonLogin>
              </ColForm>
            </FormLogin>
          </RowForm>
        </ModalLogin>
      </ContainLogin>
    </BackgroundLogin>
  );
};
