import React from 'react';
import { Autocomplete, Chip, TextField, styled } from '@mui/material';
import { theme } from '../../common';
import { ContentWrapForm } from '../../styles';
import { Clear } from '@mui/icons-material';
import { ButtonForm } from '../button';
import { FaTrashCan } from 'react-icons/fa6';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const SelectForm = (props) => {
  const ComponentProps = {
    paper: {
      sx: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP },
    },
  };

  const ListboxProps = {
    sx: {
      '&.MuiAutocomplete-listbox': {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        '&.Mui-focused': {
          backgroundColor: theme.colors.primary,
        },
        '.MuiAutocomplete-option[aria-selected="true"] ': {
          backgroundColor: theme.colors.primary,
          color: theme.colors.white,
          '&:hover': {
            backgroundColor: theme.colors.secondary,
          },
        },
        '.MuiAutocomplete-option.Mui-focused ': {
          backgroundColor: theme.colors.tertiary,
          color: theme.colors.white,
          '& div': {
            color: theme.colors.white,
          },
        },
        '& li': {
          fontSize: theme.font.size,
          fontFamily: theme.font.family,
        },
      },
    },
  };
  const onDelete = (e, option) => {
    props.handleDelete(e, option);
  };

  return (
    <SelectAutoCompleteForm
      style={props.style}
      disabled={props.isDisabled}
      limitTags={2}
      multiple={props.isMultiple}
      disableCloseOnSelect={props.isMultiple}
      width={props.width}
      validate={props.validate}
      options={props.options}
      onChange={props.handleChange}
      getOptionLabel={(option) => option.label}
      disableClearable={props.disableClearable}
      value={
        props.value && !props.isMultiple
          ? props.value.length === 0
            ? null
            : props.value[0]
          : props.value
      }
      componentsProps={ComponentProps}
      ListboxProps={ListboxProps}
      renderInput={(params) => {
        return (
          <TextFieldForm {...params} styleText={props.styleText} placeholder={props.placeholder} />
        );
      }}
      renderTags={(tagValue, getTagProps) => {
        return (
          <ContentWrapForm>
            {tagValue.map((option, index) => (
              <ChipForm deleteIcon={<Clear />} {...getTagProps({ index })} label={option.label} />
            ))}
          </ContentWrapForm>
        );
      }}
      isOptionEqualToValue={(option, newValue) => {
        return option.value === newValue.value;
      }}
      renderOption={
        props.buttonDelete
          ? (props, option) => {
              return (
                <li style={{ display: 'block' }} {...props}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '20px',
                    }}
                  >
                    <div>{option.label}</div>
                    <div>
                      <ButtonForm
                        icon={<FaTrashCan />}
                        isAction={false}
                        isOnlyIcon={true}
                        type={'primary'}
                        action={(e) => {
                          e.stopPropagation();
                          onDelete(e, option);
                        }}
                      />
                    </div>
                  </div>
                </li>
              );
            }
          : (props, option) => {
              return (
                <li {...props} {...option}>
                  {option.label}
                </li>
              );
            }
      }
    />
  );
};

export const SelectAutoCompleteForm = styled(Autocomplete)`
  min-height: 32px !important;
  height: ${({ multiple }) => (multiple ? '32px' : 'auto')};
  width: ${({ width }) => (width ? width : '98%')};
  overflow: hidden;
  border-radius: 2px;
  font-family: ${theme.font.family};
  font-size: ${theme.font.size};
  align-content: center;
  .MuiInputBase-root {
    min-height: 32px !important;
    height: 32px;
    padding: 0px 5px;
    &.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline,
    .Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-radius: 2px;
      border-width: 2px;
      border-color: ${({ validate }) =>
        !validate
          ? `${theme.colors.line} !important`
          : validate === true
          ? `${theme.colors.line} !important`
          : `${theme.colors.warning} !important`};
      transition: ${({ validate }) =>
        !validate
          ? theme.colors.line
          : validate === true
          ? theme.colors.line
          : 'border-color 0.5s ease-out'};
      &:focus {
        outline: none !important;
        border-color: ${({ validate }) =>
          !validate
            ? theme.colors.line
            : validate === true
            ? theme.colors.line
            : theme.colors.warning};
      }
    }
  }
  &:hover,
  &:focus,
  &:hover .MuiOutlinedInput-notchedOutline,
  &.Mui-focused .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.colors.line} !important;
    border-radius: 2px;
  }
`;

export const TextFieldForm = styled(TextField)`
  min-height: 32px !important;
  height: ${({ multiple }) => (multiple ? '32px' : 'auto')};
  border-color: ${theme.colors.line};
  font-family: ${theme.font.family};
  font-size: ${theme.font.size};
  align-content: center;
  .MuiInputBase-input {
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
    color: ${({ styleText }) => (styleText ? styleText : '')};
    &::placeholder {
      color: ${theme.colors.text};
      opacity: 1;
    }
  }
`;
const ChipForm = styled(Chip)`
  border: 1px solid ${theme.colors.line};
  background: ${theme.colors.white};
  border-radius: 2px;
  height: 25px;
  font-family: ${theme.font.family};
  font-size: ${theme.font.size};
  .MuiChip-deleteIcon {
    font-size: 16px;
    color: ${theme.colors.text};
  }
`;
