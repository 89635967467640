import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

const regionAWS = process.env.REACT_APP_AWS_REGION;
const accessKeyIdS3AWS = process.env.REACT_APP_ACCESS_KEY_ID_S3;
const secretKeyS3AWS = process.env.REACT_APP_SECRET_KEY_S3;
const bucketS3AWS = process.env.REACT_APP_BUCKET_S3;

export const obtenerUrlImg = async (url) => {
  const client = new S3Client({
    region: regionAWS,
    credentials: {
      accessKeyId: accessKeyIdS3AWS,
      secretAccessKey: secretKeyS3AWS,
    },
  });

  const command = new GetObjectCommand({
    Bucket: bucketS3AWS,
    Key: url,
  });

  let urlDoc = await getSignedUrl(client, command, { expiresIn: 3600 });
  return urlDoc;
};

export const obtenerFile = async (url) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const urlPreFirmada = async (fileName, fileType) => {
  const s3Client = new S3Client({
    region: regionAWS,
    credentials: {
      accessKeyId: accessKeyIdS3AWS,
      secretAccessKey: secretKeyS3AWS,
    },
  });

  const folderPath = 'carga-masiva/'; // Si `folder` está presente, añade el prefijo con "/"
  const fileKey = `${folderPath}${fileName}`;
  const command = new PutObjectCommand({
    Bucket: bucketS3AWS,
    Key: fileKey,
    ContentType: fileType,
  });

  try {
    const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 }); // URL válida por 1 hora
    return url;
  } catch (error) {
    console.error('Error al generar la URL prefirmada:', error);
    return 'No se pudo generar la URL prefirmada';
    // res.status(500).json({ error: 'No se pudo generar la URL prefirmada' });
  }
};
