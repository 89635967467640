import React, { Component, useEffect, useRef, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Modal } from 'antd';
import { ButtonForm } from '../button';
import { SwitchStyle } from '../../styles';
import { Selected, theme } from '../../common';

const SortableItem = SortableElement(({ column, onChange }) => {
  return (
    <li
      style={{
        display: 'flex',
        gap: 20,
        marginBottom: '1rem',
        cursor: 'grab',
        zIndex: 100000,
      }}
    >
      <SwitchStyle
        checked={column.seleccionado === Selected.TRUE}
        onChange={() => onChange(column)}
        style={{ pointerEvents: column.restricted ? 'none' : '' }}
      />
      <span style={{ fontWeight: '700', fontFamily: theme.font.family }}>{column.parsed}</span>
    </li>
  );
});
class ListColumn extends Component {
  render() {
    const { items, onChange } = this.props;

    return (
      <ul style={{ width: '100%', padding: 0, margin: 0, height: '60vh', overflow: 'hidden auto' }}>
        {items.map((column, index) => (
          <SortableItem key={column.columna} column={column} onChange={onChange} index={index} />
        ))}
      </ul>
    );
  }
}

const SortableList = SortableContainer(ListColumn);

export const ColumnSelector = (props) => {
  const [showModal, setModal] = useState(false);
  const [initialColumns, setInitialColumns] = useState([]);
  const [allColumnsState, setAllColumnsState] = useState([]);
  const [verifiedAll, setVerifiedAll] = useState(false);
  const listRef = useRef();

  useEffect(() => {
    if (showModal) {
      setInitialColumns([...props.allColumns]);
      setAllColumnsState([...props.allColumns]);
      setVerifiedAll(
        props.allColumns.filter((r) => r.seleccionado === Selected.FALSE).length > 0 ? false : true
      );
    }
  }, [showModal]);

  function arrayMoveMutable(array, fromIndex, toIndex) {
    const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

    if (startIndex >= 0 && startIndex < array.length) {
      const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

      const [item] = array.splice(fromIndex, 1);
      array.splice(endIndex, 0, item);
    }
  }

  function arrayMoveImmutable(array, fromIndex, toIndex) {
    const newArray = [...array];
    arrayMoveMutable(newArray, fromIndex, toIndex);
    return newArray;
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllColumnsState((items) => {
      return arrayMoveImmutable(items, oldIndex, newIndex).map((column, index) => ({
        ...column,
        orden: index + 1,
      }));
    });
  };

  const closeModal = () => {
    setModal(false);
    setAllColumnsState(initialColumns);
  };

  const onChangeSelected = (column) => {
    const newColumns = [...allColumnsState];
    const updated = newColumns.find(({ columna }) => columna === column.columna);
    const verified =
      newColumns.filter((r) => r.seleccionado === Selected.FALSE).length > 0 ? false : true;
    if (!updated) {
      newColumns.push({ ...column, seleccionado: Selected.TRUE });
    } else {
      updated.seleccionado =
        updated.seleccionado === Selected.TRUE ? Selected.FALSE : Selected.TRUE;
    }
    setAllColumnsState(newColumns);
    setVerifiedAll(
      newColumns.filter((r) => r.seleccionado === Selected.FALSE).length > 0 ? false : true
    );
  };

  const onSelectedAll = () => {
    const newColumns = [];
    const verified =
      allColumnsState.filter((r) => r.seleccionado === Selected.FALSE).length > 0 ? false : true;

    allColumnsState.map((r) => {
      if (!r.restricted) {
        if (!verified) {
          newColumns.push({ ...r, seleccionado: Selected.TRUE });
        } else newColumns.push({ ...r, seleccionado: Selected.FALSE });
      } else {
        newColumns.push({ ...r });
      }
      return r;
    });

    setAllColumnsState(newColumns);
    setVerifiedAll(!verified);
  };

  const onSaveColumns = () => {
    const columnsToSave = allColumnsState.map((column) => {
      const { parsed, ...rest } = column;
      return rest;
    });
    props.setColumn(columnsToSave);
    props.setAllColumn(allColumnsState);
    setModal(false);
  };

  return (
    <>
      <Modal
        open={showModal}
        closable={false}
        width={310}
        footer={
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <ButtonForm
              action={onSelectedAll}
              name={verifiedAll ? 'Limpiar' : 'Todos'}
              isAction={true}
              typeColor="tertiary"
            />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <ButtonForm
                action={onSaveColumns}
                name="Guardar"
                isAction={true}
                typeColor="primary"
              />
              <ButtonForm
                action={closeModal}
                name="Cerrar"
                isAction={false}
                typeColor="warning"
                style={{ marginLeft: '5px' }}
              />
            </div>
          </div>
        }
        title="Ver Columnas"
        style={{ '& .ant-modal-body': { overflow: 'hidden' } }}
      >
        <SortableList
          getRef={listRef}
          onChange={onChangeSelected}
          items={allColumnsState}
          onSortEnd={onSortEnd}
        />
      </Modal>
      <ButtonForm
        action={() => setModal(true)}
        icon="filter"
        name="Ver Columnas"
        isOnlyIcon={false}
        isAction={true}
        typeColor="secondary"
      />
    </>
  );
};
