import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { ContextMenuTrigger, ContextMenu, ContextMenuItem, Submenu } from 'rctx-contextmenu';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingSpinner, ModalConfirmation } from '../../../../../../components';
import {
  addListaSucesosEliminados,
  addListaSucesosModificados,
  onOpenModalMoverFechaM,
  onOpenModalRegM,
  updateListaSucesosModificados,
} from '../../../../../../redux/states';
import {
  listarConceptos,
  obtenerCaracteristicasXPosicion,
  obtenerConceptosxFlujoCaja,
  obtenerListadoEtiquetas,
  obtenerListadoMoneda,
  obtenerListadoPosicionesxFlujoCaja,
  obtenerPosicionXConcepto,
  obtenerRegistrosManualesUno,
} from '../../../../../../services';
import {
  createConceptoPosicionAdapter,
  createMonedaAbreviaturaAdapter,
  createRegistroManualEtiquetaAdapter,
} from '../../../../../../adapters';
import collect from 'collect.js';
import {
  formatString_change_separator,
  formatString_YYYYMMDD_TO_DDMMYYYY,
  formatStringToDate,
  useFetchAndLoad,
} from '../../../../../../common';
import { Tooltip } from 'antd';
import {
  Content,
  NumberTable,
  NumberTotalTable,
  TableContain,
  TableFC,
} from '../../../../../../styles';
import { ModalMoverFecha, ModalRegM } from './components';
import cloneDeep from 'lodash/cloneDeep';

export const TableDetalleFlujoCaja = React.memo(function TableDetalleFlujoCaja(props) {
  const dispatch = useDispatch();
  const personalizacionState = useSelector((store) => store.personalizacion);
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const authState = useSelector((store) => store.auth);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [editTable, setEditTable] = useState(false);
  const { oFlujoCajaAgregar } = flujoCajaState;
  const [objDrag, setObjDrag] = useState({});
  const [signoAllow, setSignoAllow] = useState(true);
  const [modalEliminarRegistro, setModalEliminarRegistro] = useState(false);

  useEffect(() => {
    if (
      personalizacionState.listaPersonalizada.filter((v) => v.DescPersonalizacion === 'Formato')
        .length > 0
    ) {
      setSignoAllow(
        personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'Formato'
        )[0].DatoPersonalizacion === 1
          ? true
          : false
      );
    }
  }, []);

  const handleClickCollapse = (
    oItem,
    aEncontrado2,
    expandir,
    padre,
    aColumnaHijos,
    setSelectionCollapseModel,
    selectionCollapseModel
  ) => {
    //Caso 1. Cuando comprimes el padre de todos
    if (padre) {
      if (!expandir) {
        setSelectionCollapseModel([]);
      } else {
        setSelectionCollapseModel([...selectionCollapseModel, oItem, ...aEncontrado2]);
      }
    } else {
      const selectionCollapseModelCopy = [...selectionCollapseModel];
      if (expandir) {
        let aEncontrado = selectionCollapseModelCopy.filter(function (item, index) {
          return item.Id === oItem.Id;
        });
        if (aEncontrado.length > 0) {
          aEncontrado[0]['collapse'] = false;
          aEncontrado[0]['collapseIcon'] = true;
        }

        setSelectionCollapseModel([...selectionCollapseModelCopy, ...[oItem]]);
      } else {
        let listaFinal = cloneDeep(selectionCollapseModelCopy);

        listaFinal = listaFinal.filter(function (item, index) {
          return oItem.Id !== item.Id;
        });

        setSelectionCollapseModel(listaFinal);
      }
    }
  };

  const handleClick = (oEvent, item, index) => {
    const rowsInput = [...props.finalArray];
    const oItem = rowsInput[index];
    let expandir;
    oItem['collapseIcon'] = !oItem['collapseIcon'];
    if (oItem['collapseIcon'] === false) {
      expandir = false;
    } else {
      expandir = true;
    }
    var aEncontrado = rowsInput.filter((element) => {
      return element.Id.includes(oItem.Id + '-') && element.Id !== oItem.Id;
    });
    var aEncontrado2 = rowsInput.filter((element) => {
      return (
        element.Id.includes(oItem.Id + '-') &&
        element.Id !== oItem.Id &&
        oItem.Id.split('-').length + 1 === element.Id.split('-').length
      );
    });
    var aColumnaHijos = rowsInput.filter((element) => {
      return element.Id.includes(oItem.Id + '-') && element.Id !== oItem.Id;
    });
    if (aEncontrado.length > 0) {
      aEncontrado2.forEach((element) => {
        element.collapse = !element.collapse;
        if (element.collapse) {
          element['collapseIcon'] = true;
        } else {
          element['collapseIcon'] = false;
        }
        var aEncontrado = rowsInput.filter((elemen2) => {
          return elemen2.Id.includes(element.Id + '-') && elemen2.Id !== element.Id;
        });
        aEncontrado.forEach((element) => {
          element.collapse = true;
          element['collapseIcon'] = false;
        });
      });
      //Caso 1. Cuando comprimes el padre de todos
      let setSelectionCollapseModel;
      let selectionCollapseModel;
      let padre = false;
      if (oItem.Id.includes('S')) {
        padre = oItem.Id === 'S' ? true : false;
      } else if (oItem.Id.includes('I')) {
        padre = oItem.Id === 'I' ? true : false;
      } else if (oItem.Id.includes('E')) {
        padre = oItem.Id === 'E' ? true : false;
      }
      // setSelectionCollapseModel = props.setSelectionCollapseModel;
      // selectionCollapseModel = props.selectionCollapseModel;
      // handleClickCollapse(
      //   oItem,
      //   aEncontrado2,
      //   expandir,
      //   padre,
      //   aColumnaHijos,
      //   setSelectionCollapseModel,
      //   selectionCollapseModel
      // );

      let listaSelectionCollapseModelEgreso = [];
      listaSelectionCollapseModelEgreso = [
        // ...registroUltimosNiveles,
        ...rowsInput.filter(function (item, index) {
          return item.collapse === false && item.collapseIcon === true && item.Id !== oItem.Id;
        }),
        ...rowsInput.filter(function (item, index) {
          return item.collapseIcon === false && item.collapse === false && item.Id !== oItem.Id;
        }),
        // ...[oElemento],
        // ...listaSelectionCollapseModelEgreso,
      ];

      listaSelectionCollapseModelEgreso = [
        ...listaSelectionCollapseModelEgreso
          .filter(function (item, index) {
            return item.Id.includes('S');
          })
          .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
        ...listaSelectionCollapseModelEgreso
          .filter(function (item, index) {
            return item.Id.includes('I');
          })
          .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
        ...listaSelectionCollapseModelEgreso
          .filter(function (item, index) {
            return item.Id.includes('E');
          })
          .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
      ];

      props.setSelectionCollapseModel(listaSelectionCollapseModelEgreso);
    }
    props.setFinalArray(rowsInput);
  };

  const onDragStart = (e, item2, index, oItem, indexPadre) => {
    props.setShowGrafico(false);
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData('text/plain', item2.Valor);
    setObjDrag({
      item2,
      indexPartida: index,
      oItem,
      indexPadre,
      e,
    });
  };

  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  const obtenerCabecera = (nuevoId, array) => {
    var aId = nuevoId.split('-');
    if (aId.length >= 2) {
      var sId = '';
      for (let index = 0; index < aId.length; index++) {
        const element = aId[index];
        if (index < aId.length - 1) {
          if (index === 0) {
            sId = element;
          } else {
            sId = sId + '-' + element;
          }
        }
      }
      array.push(sId);
      return obtenerCabecera(sId, array);
    }
    return array;
  };

  const obtenerHijos = (rowsInput, nuevoId) => {
    var aEncontrado = rowsInput.filter((element) => {
      return element.Id.includes(nuevoId) && element.Id !== nuevoId;
    });
    return aEncontrado;
  };

  const handleChangeInputMonto = (values, sourceInfo, item2, indexLlegada, oItem, indexPadre) => {
    const { formattedValue, value, floatValue } = values;
    const { event, source } = sourceInfo;
    var valorIngreso = floatValue;
    if (valorIngreso === undefined) {
      valorIngreso = 0;
    }
    if (false) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Solo se permite ingresar campos númericos.',
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      if (true) {
        const data2 = objDrag;
        const rowsInput = [...props.finalArray];
        // let quiero = flujoCajaState.aListaTC.filter(function (item, index) {
        //   return item.iIDTipoMoneda === flujoCajaState.selectMonedaTableFCInicial[0].value;
        // })[0].fImporte;
        // let tengo = flujoCajaState.aListaTC.filter(function (item, index) {
        //   return item.iIDTipoMoneda === flujoCajaState.selectMonedaTableFC[0].value; //flujoCajaState.selectPeridiocidadTableFC[0].value
        // })[0].fImporte;
        // let factorMonedaTableTC = quiero / tengo;

        let findFactorTC = flujoCajaState.aListaTC.filter(function (item, index) {
          return (
            item.MonedaBase === flujoCajaState.selectMonedaTableFC[0].sAbreviatura &&
            item.NombreMoneda === flujoCajaState.selectMonedaTableFCInicial[0].sAbreviatura
          );
        });
        let factorMonedaTableTC = 0;
        if (findFactorTC.length > 0) {
          factorMonedaTableTC = findFactorTC[0].Factor * findFactorTC[0].Importe;
        } else {
          let findFactorTCRe = flujoCajaState.aListaTC.filter(function (item, index) {
            return (
              item.NombreMoneda === flujoCajaState.selectMonedaTableFC[0].sAbreviatura &&
              item.MonedaBase === flujoCajaState.selectMonedaTableFCInicial[0].sAbreviatura
            );
          });
          factorMonedaTableTC =
            findFactorTCRe[0].Factor / (findFactorTCRe[0].Factor * findFactorTCRe[0].Importe);
        }

        rowsInput[indexPadre].Items[indexLlegada - 1].Valor = valorIngreso; //* factorMonedaTableTC; //parseFloat(valorIngreso) * factorMonedaTableTC;
        let finalArraySinFiltro = [];
        props.finalArraySinFiltro.forEach((element) => {
          var y = {};
          y = { ...element };
          finalArraySinFiltro.push(y);
        });

        let array = actualizarFinalArraySinFiltro(
          finalArraySinFiltro,
          rowsInput[indexPadre],
          rowsInput[indexPadre].Id_Partida3,
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor,
          rowsInput[indexPadre].sFecha,
          indexLlegada,
          indexLlegada
        );
        props.setFinalArraySinFiltro(array);
        var resultHijos = obtenerHijos(rowsInput, rowsInput[indexPadre].Id);
        for (let w = 0; w < resultHijos.length; w++) {
          const linea = resultHijos[w];
          linea.Items[indexLlegada - 1].Valor =
            linea.Items[indexLlegada - 1].Valor + linea.Items[objDrag.indexPartida - 1].Valor;
          linea.Items[objDrag.indexPartida - 1].Valor = 0;
        }
        var result = obtenerCabecera(rowsInput[indexPadre].Id, []);
        for (let w = 0; w < result.length; w++) {
          const id = result[w];
          var aEncontrado = rowsInput.filter((element) => {
            return (
              element.Id.includes(id) &&
              element.Id !== id &&
              !(element.EsGrupo === 1 && element.EsRaiz === 0)
            );
          });
          var aCabecera = rowsInput.filter((element) => {
            return element.Id === id;
          });

          for (let i = 0; i < oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length; i++) {
            var acumulador = 0;
            for (let j = 0; j < aEncontrado.length; j++) {
              const element2 = aEncontrado[j].Items[i];
              acumulador = acumulador + element2.Valor;
            }
            if (aCabecera.length > 0) {
              aCabecera[0].Items[i].Valor = acumulador;
            }
          }
        }
        var detalleFlujoCajaCabeceras = oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
        var objecto = {};
        objecto.EsRaiz = 1;
        objecto.Id = 'rTotal';
        objecto.sNombre = 'Total';
        objecto.Items = [];
        var objectoParcial = {};
        objectoParcial.EsRaiz = 1;
        objectoParcial.Id = 'rTotalParcial';
        objectoParcial.sNombre = 'Total Periodo';
        objectoParcial.Items = [];
        var aEncontradoGruposRaiz = rowsInput.filter((element) => {
          return element.EsGrupo === 1 && element.EsRaiz === 1;
        });
        for (let index = 0; index < oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length; index++) {
          var fAcumulado = 0;
          var item = {
            Id: objecto.Id + '-' + index,
            Id_Movimiento: null,
            EsRaiz: 1,
            EsGrupo: 1,
            Valor: 0,
          };
          for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
            const element = aEncontradoGruposRaiz[w];
            if (element.Posicion === 'Egresos') {
              fAcumulado = fAcumulado - element.Items[index].Valor;
            } else {
              fAcumulado = fAcumulado + element.Items[index].Valor;
            }
          }
          item.Valor = fAcumulado;
          objecto.Items.push(item);
          objectoParcial.Items.push({ ...item });
        }
        props.setFinalArray(rowsInput);
        props.setFinalArrayTotalParciales(objectoParcial);
        for (let index = 0; index < objecto.Items.length; index++) {
          const element = objecto.Items[index - 1];
          if (!element) {
            objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
          } else {
            objecto.Items[index].Valor =
              objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
          }
        }
        props.setFinalArrayTotal(objecto);
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Solo se permite movimientos horizontales.',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
  };

  const obtenerFecha = (e, item2, indexLlegada, oItem, indexPadre, rowsInput) => {
    return oFlujoCajaAgregar.detalleFlujoCajaCabeceras[indexLlegada - 1].sFechaMax;
  };

  const obtenerFechaMinCabecera = (e, item2, indexLlegada, oItem, indexPadre, rowsInput) => {
    return oFlujoCajaAgregar.detalleFlujoCajaCabeceras[indexLlegada - 1].sFechaMin;
  };

  const buscarTodosRegNoManuales = (resultHijos, indexPartida, indexLlegada) => {
    let resultHijosNoManuales = resultHijos.filter((element) => {
      return element.IdentificadorCPR === 'R' && element.EsManual === 0;
    });
    let resultHijosManuales = resultHijos.filter((element) => {
      return element.IdentificadorCPR === 'R' && element.EsManual === 1;
    });
    var contarCeros = 0;
    resultHijosManuales.forEach((element) => {
      if (element.Items[indexPartida - 1].Valor === 0) {
        contarCeros = contarCeros + 1;
      }
    });
    if (
      resultHijosNoManuales.length > 0 &&
      resultHijosManuales.length === contarCeros &&
      contarCeros !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const realizarRecalculo = (rowsInputSaldo, rowsInput) => {
    rowsInputSaldo.forEach((element) => {
      element.iFila = element.Id.split('-').length + 1;
    });
    var aFilterAcumuladores = rowsInputSaldo.filter((element) => {
      return (
        element.IdentificadorCPR === 'C' ||
        element.IdentificadorCPR === 'P' ||
        element.IdentificadorCPR === 'D'
      );
    });
    if (aFilterAcumuladores.length > 0) {
      aFilterAcumuladores.sort((a, b) => (a.iFila < b.iFila ? 1 : -1));
      aFilterAcumuladores.forEach((obj) => {
        var aEncontrado = rowsInputSaldo.filter((element) => {
          return (
            element.Id.includes(obj.Id + '-') &&
            element.Id !== obj.Id &&
            obj.Id.split('-').length + 1 === element.Id.split('-').length
          );
        });
        if (aEncontrado.length > 0) {
          var aEncontradoPadre = rowsInput.filter((element) => {
            return element.Id === obj.Id;
          });
          for (let i = 0; i < oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length; i++) {
            var acumulador2 = 0;
            for (let j = 0; j < aEncontrado.length; j++) {
              const element2 = aEncontrado[j].Items[i];
              acumulador2 = acumulador2 + element2.Valor;
            }
            aEncontradoPadre[0].Items[i].Valor = acumulador2;
          }
        }
      });
    }
  };

  const calcularTotalYsubTotal = () => {
    const rowsInput = [...props.finalArray];
    var detalleFlujoCajaCabeceras = oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
    var objecto = {};
    objecto.EsRaiz = 1;
    objecto.Id = 'Total Acumulado';
    objecto.sNombre = 'Total Acumulado';
    objecto.Items = [];
    var objectoParcial = {};
    objectoParcial.EsRaiz = 1;
    objectoParcial.Id = 'Total Periodo';
    objectoParcial.sNombre = 'Total Periodo';
    objectoParcial.Items = [];
    var aEncontradoGruposRaiz = rowsInput.filter((element) => {
      return element.EsGrupo === 1 && element.EsRaiz === 1;
    });
    for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
      var fAcumulado = 0;
      var item = {
        Id: objecto.Id + '-' + index,
        Id_Movimiento: null,
        EsRaiz: 1,
        EsGrupo: 1,
        Valor: 0,
      };
      for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
        const element = aEncontradoGruposRaiz[w];
        // if (element.Posicion === 'Egreso') {
        //   fAcumulado = fAcumulado - element.Items[index].Valor;
        // } else {
        //   fAcumulado = fAcumulado + element.Items[index].Valor;
        // }
        fAcumulado = fAcumulado + element.Items[index].Valor;
      }
      item.Valor = fAcumulado;
      objecto.Items.push(item);
      objectoParcial.Items.push({ ...item });
    }
    props.setFinalArrayTotalParciales(objectoParcial);

    for (let index = 0; index < objecto.Items.length; index++) {
      const element = objecto.Items[index - 1];
      if (!element) {
        objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
      } else {
        objecto.Items[index].Valor = objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
      }
    }
    props.setFinalArrayTotal(objecto);
  };

  const actualizarFinalArraySinFiltro = (
    array,
    rowsInput,
    Id_Partida3,
    Valor,
    sFechaMin,
    indexPartida,
    indexLlegada
  ) => {
    var partidaDesde =
      flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras[indexPartida - 1].desde;
    var partidaHasta =
      flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras[indexPartida - 1].hasta;
    var aEncontrado = array.filter(function (item, index) {
      return item.Id_Partida3 === Id_Partida3;
    });
    aEncontrado[0].sFecha = sFechaMin;
    for (let index = partidaDesde; index < partidaHasta; index++) {
      aEncontrado[0].Items[index].Valor = 0;
    }
    for (let index = 0; index < aEncontrado[0].Items.length; index++) {
      aEncontrado[0].Items[index].EsGrupo = 0;
      aEncontrado[0].Items[index].EsManual = 0; //1
      aEncontrado[0].Items[index].EsRaiz = 0;
      aEncontrado[0].Items[index].EsSplit = 1;
      aEncontrado[0].Items[index].bEditable = false;
    }
    let indexCabFind = -1;
    let desdeCabActual =
      flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras[indexLlegada - 1].desde;
    // let quiero = flujoCajaState.aListaTC.filter(function (item, index) {
    //   return item.iIDTipoMoneda === flujoCajaState.selectMonedaTableFCInicial[0].value;
    // })[0].fImporte;
    // let tengo = flujoCajaState.aListaTC.filter(function (item, index) {
    //   return item.iIDTipoMoneda === flujoCajaState.selectMonedaTableFC[0].value; //flujoCajaState.selectPeridiocidadTableFC[0].value
    // })[0].fImporte;
    // let factorMonedaTableTC = quiero / tengo;
    // let factorMonedaTableTC = 1;
    let findFactorTC = flujoCajaState.aListaTC.filter(function (item, index) {
      return (
        item.MonedaBase === flujoCajaState.selectMonedaTableFC[0].sAbreviatura &&
        item.NombreMoneda === flujoCajaState.selectMonedaTableFCInicial[0].sAbreviatura
      );
    });
    let factorMonedaTableTC = 0;
    if (findFactorTC.length > 0) {
      factorMonedaTableTC = findFactorTC[0].Factor * findFactorTC[0].Importe;
    } else {
      let findFactorTCRe = flujoCajaState.aListaTC.filter(function (item, index) {
        return (
          item.NombreMoneda === flujoCajaState.selectMonedaTableFC[0].sAbreviatura &&
          item.MonedaBase === flujoCajaState.selectMonedaTableFCInicial[0].sAbreviatura
        );
      });
      factorMonedaTableTC =
        findFactorTCRe[0].Factor / (findFactorTCRe[0].Factor * findFactorTCRe[0].Importe);
    }

    aEncontrado[0].Items[desdeCabActual].Valor = Valor * factorMonedaTableTC;
    const {
      EsGrupo: EsGrupoLlegada,
      EsManual: EsManualLlegada,
      EsRaiz: EsRaizLlegada,
      EsSplit: EsSplitLlegada,
      bEditable: bEditableLlegada,
    } = rowsInput.Items[indexLlegada - 1];
    aEncontrado[0].Items[desdeCabActual].EsGrupo = EsGrupoLlegada;
    aEncontrado[0].Items[desdeCabActual].EsManual = EsManualLlegada;
    aEncontrado[0].Items[desdeCabActual].EsRaiz = EsRaizLlegada;
    aEncontrado[0].Items[desdeCabActual].EsSplit = EsSplitLlegada;
    aEncontrado[0].Items[desdeCabActual].bEditable = false; //bEditableLlegada
    return array;
  };

  const onDropLeft = (e, item2, indexLlegada, oItem, indexPadre) => {
    e.preventDefault();
    props.setShowGrafico(false);

    if (indexLlegada !== objDrag.indexPartida) {
      var rowsInput = [];
      var finalArray = [];
      var rowsInputSinFiltro = [];
      var finalArraySinFiltro = [];

      props.finalArray.forEach((element) => {
        var o = {};
        o = { ...element };
        var y = {};
        y = { ...element };
        rowsInput.push(o);
        finalArray.push(y);
      });
      props.finalArraySinFiltro.forEach((element) => {
        var y = {};
        y = { ...element };
        finalArraySinFiltro.push(y);
      });
      var sFechaMax = obtenerFecha(e, item2, indexLlegada, oItem, indexPadre, rowsInput);
      var sFechaMin = obtenerFechaMinCabecera(e, item2, indexLlegada, oItem, indexPadre, rowsInput);
      // if (
      //   rowsInput[indexPadre].IdentificadorCPR === "R" &&
      //   rowsInput[indexPadre].EsManual === 0
      // ) {
      //   Swal.fire({
      //     position: "center",
      //     icon: "error",
      //     title: "Registro no ingresado manualmente.",
      //     showConfirmButton: false,
      //     timer: 3000,
      //   });
      //   return;
      // }
      var resultHijos = obtenerHijos(rowsInput, rowsInput[indexPadre].Id);
      // var todosRegNoManuales = buscarTodosRegNoManuales(
      //   resultHijos,
      //   objDrag.indexPartida,
      //   indexLlegada
      // );
      // if (todosRegNoManuales) {
      //   Swal.fire({
      //     position: "center",
      //     icon: "error",
      //     title: "Todos los registros no han sido ingresado manualmente.",
      //     showConfirmButton: false,
      //     timer: 3500,
      //   });
      //   return;
      // }
      let resultHijosManuales = resultHijos.filter((element) => {
        return element.IdentificadorCPR === 'R'; //&& element.EsManual === 1;
      });
      var resultHijosNoManuales = resultHijos.filter((element) => {
        return element.IdentificadorCPR === 'R' && element.EsManual === 0;
      });
      var acumulador = 0;
      resultHijosManuales.forEach((element) => {
        acumulador = acumulador + element.Items[objDrag.indexPartida - 1].Valor;
      });
      if (
        !(
          (rowsInput[indexPadre].IdentificadorCPR === 'R') //&& rowsInput[indexPadre].EsManual === 1
        )
      ) {
        if (acumulador === 0) {
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor =
            rowsInput[indexPadre].Items[indexLlegada - 1].Valor +
            rowsInput[indexPadre].Items[objDrag.indexPartida - 1].Valor;
          rowsInput[indexPadre].Items[objDrag.indexPartida - 1].Valor = 0;
        } else {
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor =
            rowsInput[indexPadre].Items[indexLlegada - 1].Valor + acumulador;
          rowsInput[indexPadre].Items[objDrag.indexPartida - 1].Valor =
            rowsInput[indexPadre].Items[objDrag.indexPartida - 1].Valor - acumulador;
        }
      } else {
        const {
          EsGrupo: EsGrupoLlegada,
          EsManual: EsManualLlegada,
          EsRaiz: EsRaizLlegada,
          EsSplit: EsSplitLlegada,
          bEditable: bEditableLlegada,
        } = rowsInput[indexPadre].Items[indexLlegada - 1];
        const {
          EsGrupo: EsGrupoPartida,
          EsManual: EsManualPartida,
          EsRaiz: EsRaizPartida,
          EsSplit: EsSplitPartida,
          bEditable: bEditablePartida,
        } = rowsInput[indexPadre].Items[objDrag.indexPartida - 1];
        rowsInput[indexPadre].Items[indexLlegada - 1].Valor =
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor +
          rowsInput[indexPadre].Items[objDrag.indexPartida - 1].Valor;
        rowsInput[indexPadre].Items[objDrag.indexPartida - 1].Valor = 0;
        rowsInput[indexPadre].Items[indexLlegada - 1].EsGrupo = EsGrupoPartida;
        rowsInput[indexPadre].Items[indexLlegada - 1].EsManual = EsManualPartida;
        rowsInput[indexPadre].Items[indexLlegada - 1].EsRaiz = EsRaizPartida;
        rowsInput[indexPadre].Items[indexLlegada - 1].EsSplit = EsSplitPartida;
        rowsInput[indexPadre].Items[indexLlegada - 1].bEditable = bEditablePartida;
        rowsInput[indexPadre].Items[objDrag.indexPartida - 1].EsGrupo = EsGrupoLlegada;
        rowsInput[indexPadre].Items[objDrag.indexPartida - 1].EsManual = EsManualLlegada;
        rowsInput[indexPadre].Items[objDrag.indexPartida - 1].EsRaiz = EsRaizLlegada;
        rowsInput[indexPadre].Items[objDrag.indexPartida - 1].EsSplit = EsSplitLlegada;
        rowsInput[indexPadre].Items[objDrag.indexPartida - 1].bEditable = bEditableLlegada;
        rowsInput[indexPadre].sAccion = 'M';
        rowsInput[indexPadre].sFecha = sFechaMin; //sFechaMax;
        rowsInput[indexPadre].posiCabecera = indexLlegada - 1;
        let array = actualizarFinalArraySinFiltro(
          finalArraySinFiltro,
          rowsInput[indexPadre],
          rowsInput[indexPadre].Id_Partida3,
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor,
          sFechaMin,
          objDrag.indexPartida,
          indexLlegada
        );
        props.setFinalArraySinFiltro(array);
        addUpdatedSucesModificados(
          rowsInput[indexPadre].Id_Partida3,
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor,
          sFechaMax
        );
      }
      var aRegistrosEncontrados = resultHijos.filter((element) => {
        return element.IdentificadorCPR === 'P';
      });
      aRegistrosEncontrados.forEach((element) => {
        element.sFecha = sFechaMin; //sFechaMax;
      });
      let array = finalArraySinFiltro;
      for (let w = 0; w < resultHijos.length; w++) {
        const linea = resultHijos[w];
        if (linea.IdentificadorCPR === 'R' && linea.Items[objDrag.indexPartida - 1].Valor != 0) {
          //&& linea.EsManual === 1) {
          linea.Items[indexLlegada - 1].Valor =
            linea.Items[indexLlegada - 1].Valor + linea.Items[objDrag.indexPartida - 1].Valor;
          linea.Items[objDrag.indexPartida - 1].Valor = 0;
          linea.sFecha = sFechaMin; //sFechaMax;
          linea.sAccion = 'M';
          linea.posiCabecera = indexLlegada - 1;
          let {
            EsGrupo: EsGrupoLlegada,
            EsManual: EsManualLlegada,
            EsRaiz: EsRaizLlegada,
            EsSplit: EsSplitLlegada,
            bEditable: bEditableLlegada,
          } = linea.Items[indexLlegada - 1];
          let {
            EsGrupo: EsGrupoPartida,
            EsManual: EsManualPartida,
            EsRaiz: EsRaizPartida,
            EsSplit: EsSplitPartida,
            bEditable: bEditablePartida,
          } = linea.Items[objDrag.indexPartida - 1];
          linea.Items[indexLlegada - 1].EsGrupo = EsGrupoPartida;
          linea.Items[indexLlegada - 1].EsManual = EsManualPartida;
          linea.Items[indexLlegada - 1].EsRaiz = EsRaizPartida;
          linea.Items[indexLlegada - 1].EsSplit = EsSplitPartida;
          linea.Items[indexLlegada - 1].bEditable = bEditablePartida;
          linea.Items[objDrag.indexPartida - 1].EsGrupo = EsGrupoLlegada;
          linea.Items[objDrag.indexPartida - 1].EsManual = EsManualLlegada;
          linea.Items[objDrag.indexPartida - 1].EsRaiz = EsRaizLlegada;
          linea.Items[objDrag.indexPartida - 1].EsSplit = EsSplitLlegada;
          linea.Items[objDrag.indexPartida - 1].bEditable = bEditableLlegada;
          array = actualizarFinalArraySinFiltro(
            array,
            linea,
            linea.Id_Partida3,
            linea.Items[indexLlegada - 1].Valor,
            sFechaMin,
            objDrag.indexPartida,
            indexLlegada
          );
          addUpdatedSucesModificados(
            linea.Id_Partida3,
            linea.Items[indexLlegada - 1].Valor,
            sFechaMax
          );
        } else if (linea.IdentificadorCPR === 'P' || linea.IdentificadorCPR === 'C') {
        }
      }
      if (resultHijos.length > 0) {
        props.setFinalArraySinFiltro(array);
      }
      //----
      const cabeceraPadre = rowsInput[indexPadre].Id.split('-')[0];
      switch (cabeceraPadre) {
        case 'S':
          const rowsInputSaldo = finalArray.filter((element) => {
            return element.Id.includes(cabeceraPadre);
          });
          realizarRecalculo(rowsInputSaldo, rowsInput);
          break;
        case 'I':
          const rowsInputIngreso = finalArray.filter((element) => {
            return element.Id.includes(cabeceraPadre);
          });
          realizarRecalculo(rowsInputIngreso, rowsInput);
          break;
        case 'E':
          const rowsInputEgreso = finalArray.filter((element) => {
            return element.Id.includes(cabeceraPadre);
          });
          realizarRecalculo(rowsInputEgreso, rowsInput);
          break;
        default:
          break;
      }
      var result = obtenerCabecera(rowsInput[indexPadre].Id, []);
      for (let w = 0; w < result.length; w++) {
        const id = result[w];
        var aEncontrado = rowsInput.filter((element) => {
          return (
            element.Id.includes(id) &&
            element.Id !== id &&
            !(element.EsGrupo === 1 && element.EsRaiz === 0)
          );
        });
        var aCabecera = rowsInput.filter((element) => {
          return element.Id === id;
        });
        for (let i = 0; i < oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length; i++) {
          var acumulador = 0;
          for (let j = 0; j < aEncontrado.length; j++) {
            const element2 = aEncontrado[j].Items[i];
            acumulador = acumulador + element2.Valor;
          }
          if (aCabecera.length > 0) {
            aCabecera[0].Items[i].Valor = acumulador;
          }
        }
      }
      props.setFinalArray(rowsInput);
      var detalleFlujoCajaCabeceras = oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
      var objecto = {};
      objecto.EsRaiz = 1;
      objecto.Id = 'Total Acumulado';
      objecto.sNombre = 'Total Acumulado';
      objecto.Items = [];
      var objectoParcial = {};
      objectoParcial.EsRaiz = 1;
      objectoParcial.Id = 'Total Periodo';
      objectoParcial.sNombre = 'Total Periodo';
      objectoParcial.Items = [];
      var aEncontradoGruposRaiz = rowsInput.filter((element) => {
        return element.EsGrupo === 1 && element.EsRaiz === 1;
      });
      for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
        var fAcumulado = 0;
        var item = {
          Id: objecto.Id + '-' + index,
          Id_Movimiento: null,
          EsRaiz: 1,
          EsGrupo: 1,
          Valor: 0,
        };

        for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
          const element = aEncontradoGruposRaiz[w];
          // if (element.Posicion === 'Egreso') {
          //   fAcumulado = fAcumulado - element.Items[index].Valor;
          // } else {
          //   fAcumulado = fAcumulado + element.Items[index].Valor;
          // }
          fAcumulado = fAcumulado + element.Items[index].Valor;
        }
        item.Valor = fAcumulado;
        objecto.Items.push(item);
        objectoParcial.Items.push({ ...item });
      }
      props.setFinalArrayTotalParciales(objectoParcial);
      for (let index = 0; index < objecto.Items.length; index++) {
        const element = objecto.Items[index - 1];
        if (!element) {
          objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
        } else {
          objecto.Items[index].Valor = objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
        }
      }
      props.setFinalArrayTotal(objecto);
      calcularTotalYsubTotal();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Solo se permite movimientos horizontales.',
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  const onAgregarRegistroPosi = async (e) => {
    let aLista = [];
    let filtro = [];
    var objSeleccion = props.objRightPosicionClick;
    var formAgregarRegM = { ...props.formAgregarRegM };
    // const responseListarConceptos = await callEndpoint(listarConceptos());
    const responseListarConceptos = await callEndpoint(
      obtenerConceptosxFlujoCaja({
        oIDEstructura: {
          IDEst: flujoCajaState.iIdEstructura,
        },
      })
    );
    let listaConceptos = [];
    for (
      let index = 0;
      index < responseListarConceptos.data.respuesta.aListaConceptos.length;
      index++
    ) {
      const element = responseListarConceptos.data.respuesta.aListaConceptos[index];
      listaConceptos.push(createConceptoPosicionAdapter(element));
    }
    formAgregarRegM.listaConceptos = listaConceptos;
    let listaObtenerPosicionxConcepto = [];
    // let { data } = await callEndpoint(
    //   obtenerPosicionXConcepto({
    //     oIDConcepto: {
    //       IDCon: objSeleccion.oItem.Id_Partida1,
    //     },
    //   })
    // );
    let { data } = await callEndpoint(
      obtenerListadoPosicionesxFlujoCaja({
        oIDEstructura: {
          IDEst: flujoCajaState.iIdEstructura,
          IDCon: objSeleccion.oItem.Id_Partida1,
        },
      })
    );
    data.respuesta.aListaPosiciones.forEach((element) => {
      let oPosicion = {};
      oPosicion.value = element.IdPosicion;
      oPosicion.label = element.NombrePosicion;
      listaObtenerPosicionxConcepto.push(oPosicion);
    });
    formAgregarRegM.listaPosicionManual = listaObtenerPosicionxConcepto;
    formAgregarRegM.selectConcepto = [
      {
        value: objSeleccion.oItem.Id_Partida1,
        label: objSeleccion.oItem.sNombre_Partida1,
      },
    ];
    formAgregarRegM.selectPosicion = [
      {
        value: objSeleccion.oItem.Id_Partida2,
        label: objSeleccion.oItem.sNombre_Partida2,
      },
    ];
    const responseCaracteristicasXPosicion = await callEndpoint(
      obtenerCaracteristicasXPosicion({
        oIDPosicion: {
          iIDPos: objSeleccion.oItem.Id_Partida2,
        },
      })
    );
    responseCaracteristicasXPosicion.data.respuesta.aListaAgrupadaCaracPos[0].Items.forEach(
      function (item, index) {
        let o = { ...item };
        o.label = item.NombreCampo;
        o.valor = '';
        o.validate = null;
        aLista.push(o);
        filtro = aLista.filter(
          (item) =>
            // item.NombreCampo !== "Cliente" &&
            item.NombreCampo !== 'Importe' &&
            item.NombreCampo !== 'FechaVcto' &&
            item.NombreCampo !== 'MonedaImporte' &&
            item.NombreCampo !== 'MonedaPago'
        );
      }
    );
    formAgregarRegM.rowsKeyData = filtro;
    const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());
    var listarEtiquetas = [];
    for (
      let index = 0;
      index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
      index++
    ) {
      const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
      var obj = createRegistroManualEtiquetaAdapter(element);
      listarEtiquetas.push(obj);
    }
    formAgregarRegM.listarEtiquetasManual = listarEtiquetas;
    const responseCurrencyList = await callEndpoint(obtenerListadoMoneda());
    let currencyListManual = [];
    if (responseCurrencyList != null) {
      if (responseCurrencyList.data) {
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'texto_moneda'
        )[0].DatoPersonalizacion;
        let { aListaMoneda } = responseCurrencyList.data.respuesta;
        for (let index = 0; index < aListaMoneda.length; index++) {
          const element = aListaMoneda[index];
          currencyListManual.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
        }
      }
    }
    formAgregarRegM.currencyListManual = currencyListManual;
    props.setFormAgregarRegM({ ...props.formAgregarRegM, ...formAgregarRegM });
    dispatch(onOpenModalRegM(true));
  };

  const onAgregarRegistro = async (e) => {
    if (props.objRightPosicionClick.item.IdentificadorCPR === 'R') {
      /* Registro */

      let filtro = [];
      var objSeleccion = props.objRightPosicionClick;
      var formAgregarRegM = { ...props.formAgregarRegM };
      const responseObtenerRegistrosManuales = await callEndpoint(
        obtenerRegistrosManualesUno({
          RegistroManualConsulta: {
            IDRegistro: objSeleccion.oItem.Id_Partida3,
          },
        })
      );
      var objManualInfo =
        responseObtenerRegistrosManuales.data.respuesta.aListaRegistrosAgrupado[0];
      const responseListarConceptos = await callEndpoint(listarConceptos());
      let listaConceptos = [];
      for (
        let index = 0;
        index < responseListarConceptos.data.respuesta.aListaConceptos.length;
        index++
      ) {
        const element = responseListarConceptos.data.respuesta.aListaConceptos[index];
        listaConceptos.push(createConceptoPosicionAdapter(element));
      }
      formAgregarRegM.listaConceptos = listaConceptos;
      let listaObtenerPosicionxConcepto = [];
      let { data } = await callEndpoint(
        obtenerPosicionXConcepto({
          oIDConcepto: {
            IDCon: objManualInfo.IDConcepto,
          },
        })
      );
      data.respuesta.aListaPosiciones.forEach((element) => {
        let oPosicion = {};
        oPosicion.value = element.IdPosicion;
        oPosicion.label = element.NombrePosicion;
        listaObtenerPosicionxConcepto.push(oPosicion);
      });
      formAgregarRegM.listaPosicionManual = listaObtenerPosicionxConcepto;
      formAgregarRegM.selectConcepto = [
        { value: objManualInfo.IDConcepto, label: objManualInfo.NombreConcepto },
      ];
      formAgregarRegM.selectPosicion = [
        { value: objManualInfo.IDPosicion, label: objManualInfo.NombrePosicion },
      ];
      var aItems = [];
      filtro = objManualInfo.Items.filter(
        (item) =>
          // item.Caracteristica !== "Cliente" &&
          item.Caracteristica !== 'Importe' &&
          item.Caracteristica !== 'FechaVcto' &&
          item.Caracteristica !== 'MonedaImporte' &&
          item.Caracteristica !== 'MonedaPago'
      );
      let aImporteEncontrado = objManualInfo.Items.filter(
        (item) => item.Caracteristica === 'Importe'
      );
      if (aImporteEncontrado.length > 0) {
        formAgregarRegM.fMonto = aImporteEncontrado[0].Valor;
      }
      let aFechaVcto = objManualInfo.Items.filter((item) => item.Caracteristica === 'FechaVcto');
      if (aFechaVcto.length > 0) {
        formAgregarRegM.dateRegistroM = formatString_change_separator(
          aFechaVcto[0].Valor,
          '/',
          '.'
        );
      }
      let aMonedaImporte = objManualInfo.Items.filter(
        (item) => item.Caracteristica === 'MonedaImporte'
      );
      if (aMonedaImporte.length > 0) {
        formAgregarRegM.selectMoneda = [
          {
            value: aMonedaImporte[0].Valor,
            label: aMonedaImporte[0].ValorLabel,
          },
        ];
        formAgregarRegM.bValidMoneda = true;
      }
      let aMonedaPago = objManualInfo.Items.filter((item) => item.Caracteristica === 'MonedaPago');
      if (aMonedaPago.length > 0) {
        formAgregarRegM.selectMonedaPago = [
          {
            value: aMonedaPago[0].Valor,
            label: aMonedaPago[0].ValorLabel,
          },
        ];
        formAgregarRegM.bValidMonedaPago = true;
      }
      filtro.forEach((element) => {
        let obj = { ...element };
        //
        obj.label = element.Caracteristica;
        obj.valor = element.Valor;
        obj.NombreCampo = element.Caracteristica;
        aItems.push(obj);
      });
      formAgregarRegM.rowsKeyData = aItems;
      const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());
      var listarEtiquetas = [];
      for (
        let index = 0;
        index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
        index++
      ) {
        const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
        var obj = createRegistroManualEtiquetaAdapter(element);
        listarEtiquetas.push(obj);
      }
      formAgregarRegM.listarEtiquetasManual = listarEtiquetas;
      const responseCurrencyList = await callEndpoint(obtenerListadoMoneda());
      let currencyListManual = [];
      if (responseCurrencyList != null) {
        if (responseCurrencyList.data) {
          let tipoMoneda = personalizacionState.listaPersonalizada.filter(
            (v) => v.DescPersonalizacion === 'texto_moneda'
          )[0].DatoPersonalizacion;
          let { aListaMoneda } = responseCurrencyList.data.respuesta;
          for (let index = 0; index < aListaMoneda.length; index++) {
            const element = aListaMoneda[index];
            currencyListManual.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
          }
        }
      }
      formAgregarRegM.currencyListManual = currencyListManual;
      formAgregarRegM.selectListEtiquetaManual = [];
      formAgregarRegM.dateFechaVigenciaRegistroM = formatString_change_separator(
        objManualInfo.FechaVigenciaRegistroM,
        '/',
        '.'
      );
      objManualInfo.aListaEtiquetas.forEach((element) => {
        var aEncontrado = listarEtiquetas.filter((item) => {
          return item.IDEtiqueta === parseInt(element.IDEtiqueta, 10);
        });
        if (aEncontrado.length > 0) {
          formAgregarRegM.selectListEtiquetaManual.push(aEncontrado[0]);
        }
      });
      props.setFormAgregarRegM({ ...props.formAgregarRegM, ...formAgregarRegM });

      /* Registro */
    } else if (props.objRightPosicionClick.item.IdentificadorCPR === 'D') {
      /* Desdoble */

      let aLista = [];
      let filtro = [];
      var objSeleccion = props.objRightPosicionClick;
      var formAgregarRegM = { ...props.formAgregarRegM };
      // const responseListarConceptos = await callEndpoint(listarConceptos());
      const responseListarConceptos = await callEndpoint(
        obtenerConceptosxFlujoCaja({
          oIDEstructura: {
            IDEst: flujoCajaState.iIdEstructura,
          },
        })
      );
      let listaConceptos = [];
      for (
        let index = 0;
        index < responseListarConceptos.data.respuesta.aListaConceptos.length;
        index++
      ) {
        const element = responseListarConceptos.data.respuesta.aListaConceptos[index];
        listaConceptos.push(createConceptoPosicionAdapter(element));
      }
      formAgregarRegM.listaConceptos = listaConceptos;
      let listaObtenerPosicionxConcepto = [];
      // let { data } = await callEndpoint(
      //   obtenerPosicionXConcepto({
      //     oIDConcepto: {
      //       IDCon: objSeleccion.oItem.Id_Partida1,
      //     },
      //   })
      // );
      let { data } = await callEndpoint(
        obtenerListadoPosicionesxFlujoCaja({
          oIDEstructura: {
            IDEst: flujoCajaState.iIdEstructura,
            IDCon: objSeleccion.oItem.Id_Partida1,
          },
        })
      );

      data.respuesta.aListaPosiciones.forEach((element) => {
        let oPosicion = {};
        oPosicion.value = element.iIdPosicion;
        oPosicion.label = element.NombrePosicion;
        listaObtenerPosicionxConcepto.push(oPosicion);
      });
      formAgregarRegM.listaPosicionManual = listaObtenerPosicionxConcepto;

      // formAgregarRegM.selectConcepto = [
      //   {
      //     value: objSeleccion.oItem.Id_Partida1,
      //     label: objSeleccion.oItem.sNombre_Partida1,
      //   },
      // ];

      formAgregarRegM.selectConcepto = formAgregarRegM.listaConceptos.filter(function (
        item,
        index
      ) {
        return item.value === objSeleccion.oItem.Id_Partida1;
      });

      // formAgregarRegM.selectPosicion = [
      //   {
      //     value: objSeleccion.oItem.Id_Partida2,
      //     label: objSeleccion.oItem.sNombre_Partida2,
      //   },
      // ];

      formAgregarRegM.selectPosicion = formAgregarRegM.listaPosicionManual.filter(function (
        item,
        index
      ) {
        return item.value === objSeleccion.oItem.Id_Partida2;
      });

      const responseCaracteristicasXPosicion = await callEndpoint(
        obtenerCaracteristicasXPosicion({
          oIDPosicion: {
            iIDPos: objSeleccion.oItem.Id_Partida2,
          },
        })
      );
      responseCaracteristicasXPosicion.data.respuesta.aListaAgrupadaCaracPos[0].Items.forEach(
        function (item, index) {
          let o = { ...item };
          o.label = item.NombreCampo;
          o.valor = '';
          o.validate = null;
          aLista.push(o);
          filtro = aLista.filter(
            (item) =>
              // item.NombreCampo !== "Cliente" &&
              item.NombreCampo !== 'Importe' &&
              item.NombreCampo !== 'FechaVcto' &&
              item.NombreCampo !== 'MonedaImporte' &&
              item.NombreCampo !== 'MonedaPago'
          );
        }
      );
      formAgregarRegM.rowsKeyData = filtro;
      const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());

      var listarEtiquetas = [];
      for (
        let index = 0;
        index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
        index++
      ) {
        const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
        var obj = createRegistroManualEtiquetaAdapter(element);
        listarEtiquetas.push(obj);
      }
      formAgregarRegM.listarEtiquetasManual = listarEtiquetas;
      const responseCurrencyList = await callEndpoint(obtenerListadoMoneda());
      let currencyListManual = [];
      if (responseCurrencyList != null) {
        if (responseCurrencyList.data) {
          let tipoMoneda = personalizacionState.listaPersonalizada.filter(
            (v) => v.DescPersonalizacion === 'texto_moneda'
          )[0].DatoPersonalizacion;
          let { aListaMoneda } = responseCurrencyList.data.respuesta;
          for (let index = 0; index < aListaMoneda.length; index++) {
            const element = aListaMoneda[index];
            currencyListManual.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
          }
        }
      }
      formAgregarRegM.currencyListManual = currencyListManual;

      let aId = objSeleccion.item.Id.split('-');
      let sId = '';

      for (let index = 0; index < aId.length - 1; index++) {
        const element = aId[index];
        if (index === 0) {
          sId = element;
        } else {
          sId = sId + '-' + element;
        }
      }

      let aEncontrado = props.selectedDesdobles.filter(function (item, index) {
        if (sId === item.oPadre.Id) {
          return item;
        }
      });
      let sNombreCampo = aEncontrado[0].oDesdoble.sNombreCampo;

      formAgregarRegM.rowsKeyData.map(function (item, index) {
        if (item.NombreCampo === sNombreCampo) {
          item.valor = objSeleccion.item.Posicion;
        }
      });
      //formAgregarRegM.rowsKeyData

      props.setFormAgregarRegM({ ...props.formAgregarRegM, ...formAgregarRegM });

      /* Desdoble */
    } else if (props.objRightPosicionClick.item.IdentificadorCPR === 'P') {
      /* Posicion */

      let aLista = [];
      let filtro = [];
      var objSeleccion = props.objRightPosicionClick;
      var formAgregarRegM = { ...props.formAgregarRegM };
      // const responseListarConceptos = await callEndpoint(listarConceptos());
      const responseListarConceptos = await callEndpoint(
        obtenerConceptosxFlujoCaja({
          oIDEstructura: {
            IDEst: flujoCajaState.iIdEstructura,
          },
        })
      );
      let listaConceptos = [];
      for (
        let index = 0;
        index < responseListarConceptos.data.respuesta.aListaConceptos.length;
        index++
      ) {
        const element = responseListarConceptos.data.respuesta.aListaConceptos[index];
        listaConceptos.push(createConceptoPosicionAdapter(element));
      }
      formAgregarRegM.listaConceptos = listaConceptos;
      let listaObtenerPosicionxConcepto = [];
      // let { data } = await callEndpoint(
      //   obtenerPosicionXConcepto({
      //     oIDConcepto: {
      //       IDCon: objSeleccion.oItem.Id_Partida1,
      //     },
      //   })
      // );
      let { data } = await callEndpoint(
        obtenerListadoPosicionesxFlujoCaja({
          oIDEstructura: {
            IDEst: flujoCajaState.iIdEstructura,
            IDCon: objSeleccion.oItem.Id_Partida1,
          },
        })
      );
      data.respuesta.aListaPosiciones.forEach((element) => {
        let oPosicion = {};
        oPosicion.value = element.IdPosicion;
        oPosicion.label = element.NombrePosicion;
        listaObtenerPosicionxConcepto.push(oPosicion);
      });
      formAgregarRegM.listaPosicionManual = listaObtenerPosicionxConcepto;
      formAgregarRegM.selectConcepto = [
        {
          value: objSeleccion.oItem.Id_Partida1,
          label: objSeleccion.oItem.sNombre_Partida1,
        },
      ];
      formAgregarRegM.selectPosicion = [
        {
          value: objSeleccion.oItem.Id_Partida2,
          label: objSeleccion.oItem.sNombre_Partida2,
        },
      ];
      const responseCaracteristicasXPosicion = await callEndpoint(
        obtenerCaracteristicasXPosicion({
          oIDPosicion: {
            iIDPos: objSeleccion.oItem.Id_Partida2,
          },
        })
      );
      responseCaracteristicasXPosicion.data.respuesta.aListaAgrupadaCaracPos[0].Items.forEach(
        function (item, index) {
          let o = { ...item };
          o.label = item.NombreCampo;
          o.valor = '';
          o.validate = null;
          aLista.push(o);
          filtro = aLista.filter(
            (item) =>
              // item.NombreCampo !== "Cliente" &&
              item.NombreCampo !== 'Importe' &&
              item.NombreCampo !== 'FechaVcto' &&
              item.NombreCampo !== 'MonedaImporte' &&
              item.NombreCampo !== 'MonedaPago'
          );
        }
      );
      formAgregarRegM.rowsKeyData = filtro;
      const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());
      var listarEtiquetas = [];
      for (
        let index = 0;
        index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
        index++
      ) {
        const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
        var obj = createRegistroManualEtiquetaAdapter(element);
        listarEtiquetas.push(obj);
      }
      formAgregarRegM.listarEtiquetasManual = listarEtiquetas;
      const responseCurrencyList = await callEndpoint(obtenerListadoMoneda());
      let currencyListManual = [];
      if (responseCurrencyList != null) {
        if (responseCurrencyList.data) {
          let tipoMoneda = personalizacionState.listaPersonalizada.filter(
            (v) => v.DescPersonalizacion === 'texto_moneda'
          )[0].DatoPersonalizacion;
          let { aListaMoneda } = responseCurrencyList.data.respuesta;
          for (let index = 0; index < aListaMoneda.length; index++) {
            const element = aListaMoneda[index];
            currencyListManual.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
          }
        }
      }
      formAgregarRegM.currencyListManual = currencyListManual;
      props.setFormAgregarRegM({ ...props.formAgregarRegM, ...formAgregarRegM });

      /* Posicion */
    }
    dispatch(onOpenModalRegM(true));
  };

  const onEliminarRegistro = (e) => {
    setModalEliminarRegistro(true);
  };

  const onModalMoverFechaM = (e) => {
    dispatch(onOpenModalMoverFechaM(true));
  };

  const onConfirmarEliminarRegistros = async (e) => {
    props.setShowGrafico(false);

    setModalEliminarRegistro(false);

    var Id = props.objRightClick.oItem.Id;
    var Id_Partida3 = props.objRightClick.oItem.Id_Partida3;
    const data2 = objDrag;
    const finalArray = cloneDeep(props.finalArray); //[...props.finalArray];
    const finalArraySinFiltro = cloneDeep(props.finalArraySinFiltro); // [...props.finalArraySinFiltro];
    let newFinalArray;

    let newFinalArraySinFiltro;
    if (Id_Partida3) {
      newFinalArray = finalArray.filter(function (item, index) {
        return item.Id_Partida3 !== Id_Partida3;
      });

      newFinalArraySinFiltro = finalArraySinFiltro.filter(function (item, index) {
        return item.Id_Partida3 !== Id_Partida3;
      });
    } else {
      newFinalArray = finalArray.filter(function (item, index) {
        return item.Id !== Id;
      });

      newFinalArraySinFiltro = finalArraySinFiltro.filter(function (item, index) {
        return item.Id !== Id;
      });
    }
    props.setFinalArray(newFinalArray);

    let listaF = [];
    listaF = [
      ...newFinalArray.filter(function (item, index) {
        return item.collapse === false && item.collapseIcon === true;
      }),
      ...newFinalArray.filter(function (item, index) {
        return item.collapseIcon === false && item.collapse === false;
      }),
    ].sort((a, b) => (a.Id > b.Id ? 1 : -1));
    const aEncontrado = listaF.filter(function (item, index) {
      return item.Id.includes(props.objRightClick.oItem.Id.split('-').slice(0, -1).join('-') + '-');
    });
    if (aEncontrado.length === 0) {
      listaF = listaF.filter(function (item, index) {
        return item.Id !== props.objRightClick.oItem.Id.split('-').slice(0, -1).join('-');
      });
    }
    props.setSelectionCollapseModel(listaF);
    props.setFinalArraySinFiltro(newFinalArraySinFiltro);
    const selectedDesdobles = [...props.selectedDesdobles];
    props.setSelectedDesdobles([]);
    props.setSelectedDesdobles(selectedDesdobles);
    dispatch(
      addListaSucesosEliminados({
        newItem: {
          IDRegistro: Id_Partida3,
          IDUsuario: authState.user.uid,
        },
      })
    );
  };

  const onEditarFila = (e) => {
    const rowsInput = [...props.finalArray];
    rowsInput[props.objRightClick.indexPadre].Items.forEach((oItem) => {
      if (
        oItem.EsRaiz === 0 &&
        oItem.EsGrupo === 0 &&
        oItem.EsManual === 1 &&
        oItem.EsSplit === 0
      ) {
        oItem.bEditable = true;
      }
    });
    props.setFinalArray(rowsInput);
    return;
  };

  const findPadreXCaracteristica = (person) => {
    return !!props.selectedDesdobles.find((p) => p.oPadre.Id === person.oPadre.Id);
  };

  const filterPadreXCaracteristica = (person) => {
    return props.selectedDesdobles.filter((p) => p.oPadre.Id !== person.oPadre.Id);
  };

  const realizarCollapse = (person, setSelectionCollapseModel, selectionCollapseModel) => {
    const selectionCollapseModelInicial = [...selectionCollapseModel];
    let aEncontrado = selectionCollapseModelInicial.filter((element) => {
      return person.oPadre.Id === element.Id;
    });
    if (aEncontrado.length > 0) {
      aEncontrado[0]['collapse'] = false;
      aEncontrado[0]['collapseIcon'] = false;
    }
    setSelectionCollapseModel(selectionCollapseModelInicial);
  };

  const handleChangeCaracteristica = (person) => {
    const filteredPeople = findPadreXCaracteristica(person)
      ? filterPadreXCaracteristica(person)
      : [...props.selectedDesdobles, person];
    //     return (
    //       person.oPadre.Id === element.Id
    //     );
    //   });
    //   if(aEncontrado.length > 0){
    //     aEncontrado[0]["collapse"] = false;
    //     aEncontrado[0]["collapseIcon"] = false;
    //   }

    // let setSelectionCollapseModel;
    // let selectionCollapseModel;
    // let { oPadre } = person;
    // if (oPadre.Id.includes('S')) {
    // } else if (oPadre.Id.includes('I')) {
    // } else if (oPadre.Id.includes('E')) {
    //   setSelectionCollapseModel = props.setSelectionCollapseModel;
    //   selectionCollapseModel = props.selectionCollapseModel;
    // }
    // realizarCollapse(person, setSelectionCollapseModel, selectionCollapseModel);

    props.setSelectedDesdobles(filteredPeople);

    const oItem = person.oPadre;
    let listaSelectionCollapseModelEgreso = []; //cloneDeep(props.selectionCollapseModel);
    const oElemento = cloneDeep(person.oPadre);
    // oElemento.collapse = false;
    // oElemento.collapseIcon = false;

    listaSelectionCollapseModelEgreso = [
      // ...registroUltimosNiveles,
      ...props.finalArray.filter(function (item, index) {
        return item.collapse === false && item.collapseIcon === true && item.Id !== oItem.Id;
      }),
      ...props.finalArray.filter(function (item, index) {
        return item.collapseIcon === false && item.collapse === false && item.Id !== oItem.Id;
      }),
      ...[oElemento],
      // ...listaSelectionCollapseModelEgreso,
    ];

    listaSelectionCollapseModelEgreso = listaSelectionCollapseModelEgreso.filter(function (
      item,
      index
    ) {
      return !(
        item.Id.includes(oItem.Id) &&
        item.Id != oItem.Id &&
        item.Id.split('-').length - oItem.Id.split('-').length === 1
      );
    });

    listaSelectionCollapseModelEgreso = listaSelectionCollapseModelEgreso.filter(function (
      item,
      index
    ) {
      return !(item.Id.includes(oItem.Id) && item.Id != oItem.Id);
    });

    listaSelectionCollapseModelEgreso = [
      ...listaSelectionCollapseModelEgreso
        .filter(function (item, index) {
          return item.Id.includes('S');
        })
        .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
      ...listaSelectionCollapseModelEgreso
        .filter(function (item, index) {
          return item.Id.includes('I');
        })
        .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
      ...listaSelectionCollapseModelEgreso
        .filter(function (item, index) {
          return item.Id.includes('E');
        })
        .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
    ];
    props.setSelectionCollapseModel(listaSelectionCollapseModelEgreso);
  };

  const onSelectCaracteristicas = (e, item) => {
    // return;

    var aListaRows = [];
    var indexFind = -1;
    props.selectedDesdobles.find(function (obj, i) {
      if (obj.oPadre.Id === props.objRightPosicionClick.oItem.Id) {
        indexFind = i;
        return i;
      }
    });
    if (indexFind > -1) {
      props.selectedDesdobles.splice(indexFind, 1);
      var aFinalArray = [];
      for (let index = 0; index < props.finalArray.length; index++) {
        const element = { ...props.finalArray[index] };
        aFinalArray.push(element);
      }
      var Id = props.objRightPosicionClick.oItem.Id;
      var aHijosDesdobles = obtenerHijos(props.finalArray, Id);
      var aHijosInicialesSinDesdobles = obtenerHijos(props.finalArraySinFiltro, Id);
      if (aHijosDesdobles.length > aHijosInicialesSinDesdobles.length) {
        var indexFind = -1;
        aFinalArray.find(function (item, i) {
          if (item.Id === Id) {
            indexFind = i;
            return i;
          }
        });
        aFinalArray.splice(indexFind + 1, aHijosDesdobles.length);
        for (let index = 0; index < aHijosInicialesSinDesdobles.length; index++) {
          const element = aHijosInicialesSinDesdobles[index];
          indexFind = indexFind + 1;
          aFinalArray.splice(indexFind, 0, element);
        }
        aListaRows = aFinalArray;
        props.setFinalArray(aFinalArray);
      }
    } else {
      aListaRows = props.finalArray;
    }
    var oItem = props.objRightPosicionClick.oItem;

    handleChangeCaracteristica({
      oPadre: oItem,
      oDesdoble: item,
      collapse: true,
      collapseIcon: true,
    });
  };

  const onRealizarDesdoblesAutomatico = (finalArraySinFiltro) => {
    // var aFinalArray = [...finalArraySinFiltro];

    const aFinalArray = finalArraySinFiltro.map((object) => ({ ...object }));
    let rows = finalArraySinFiltro.map((object) => ({ ...object })); //[...finalArraySinFiltro];
    // let rows = [...aFinalArray];
    ///
    //Proceso 1.
    var aIdExcluir = [];
    var aHijosFinal = [];
    var aAgrupadoXDesdobleXhijo = [];
    for (let index = 0; index < props.selectedDesdobles.length; index++) {
      const oPadreDesdoble = props.selectedDesdobles[index];
      var aAgrupadoDatos = [];
      var oItem = oPadreDesdoble.oPadre;
      // props.finalArray.forEach((element) => {
      var aHijos = [...obtenerHijos(aFinalArray, oItem.Id)];
      var helper = {};
      aHijos.forEach((element) => {
        var aEncontrado = element.datos_reg.filter((element2) => {
          return element2.sNombreCampo === oPadreDesdoble.oDesdoble.sNombreCampo;
        });
        if (aEncontrado.length > 0) {
          var key =
            // aEncontrado[0].iIdCaracteristica +
            // "_" +
            aEncontrado[0].sNombreCampo + '_' + aEncontrado[0].sDatoPosicion;
          if (!helper[key]) {
            //inicializa
            helper[key] = {
              // iIdCaracteristica: aEncontrado[0].iIdCaracteristica,
              sNombreCampo: aEncontrado[0].sNombreCampo,
              sDatoPosicion: aEncontrado[0].sDatoPosicion,
              datos: [element],
            };
            aAgrupadoDatos.push(helper[key]);
          } else {
            helper[key].datos.push(element);
          }
          aHijosFinal.push(element);
        }
      });
      aHijos = aHijosFinal;
      //
      aAgrupadoDatos.forEach((element) => {
        let datos = element.datos;
        datos.forEach((element2) => {
          aIdExcluir.push(element2.Id);
        });
      });
      ////
      // var indexFind = -1;
      // rows.find(function (item, i) {
      //   if (item.Id === oItem.Id) {
      //     indexFind = i;
      //     return i;
      //   }
      // });
      var obj = { ...oPadreDesdoble, ...{ aAgrupadoDatos: aAgrupadoDatos } };
      aAgrupadoXDesdobleXhijo.push(obj);
    }
    if (props.selectedDesdobles.length > 0) {
      var colectData = collect;
      rows = colectData(rows).whereNotIn('Id', aIdExcluir).all();
    }
    //
    // for (let indexDesdoble = 0; indexDesdoble < props.selectedDesdobles.length; indexDesdoble++) {
    // var indexAcum= 0 ;
    // const oPadreDesdoble = props.selectedDesdobles[indexDesdoble];
    // var IdDesdoble = oPadreDesdoble.oPadre.Id + "-"+(index+1);
    ////
    // var indexFind = -1;
    // rows.find(function (item, i) {
    //   if (item.Id === oItem.Id) {
    //     indexFind = i;
    //     return i;
    //   }
    // });
    //
    for (let j = 0; j < aAgrupadoXDesdobleXhijo.length; j++) {
      var indexAcum = 0;
      const element = aAgrupadoXDesdobleXhijo[j];
      var indexFind = -1;
      rows.find(function (item, i) {
        if (item.Id === element.oPadre.Id) {
          indexFind = i;
          return i;
        }
      });
      var oItem = element.oPadre;
      var aAgrupado = element.aAgrupadoDatos;
      aAgrupado = aAgrupado.sort((a, b) => (a.sDatoPosicion > b.sDatoPosicion ? 1 : -1));
      for (let p = 0; p < aAgrupado.length; p++) {
        indexAcum = indexAcum + 1;
        var IdDesdoble = element.oPadre.Id + '-' + indexAcum;
        ////----
        const aDatos = [...aAgrupado[p].datos];
        //var IdDesdoble = oItem.Id + "-" + (p + 1);
        for (let index = 0; index < aDatos.length; index++) {
          const element = aDatos[index];
          // var Id = element.Id.replace(oItem.Id, IdDesdoble);
          // element.Id = Id;
          var aId = element.Id.split('-');
          element.Id = IdDesdoble + '-' + aId[aId.length - 1]; //IdDesdoble + aId[aId.length-1];
          for (let w = 0; w < element.Items.length; w++) {
            const element2 = element.Items[w];
            var Id = element.Id + '-' + (w + 1);
            element2.Id = Id;
          }
        }
        var Items = [];
        for (let f = 0; f < oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length; f++) {
          var oCabecera = {
            Id: IdDesdoble,
            Id2: IdDesdoble,
            IdPadre: 'S-2-8',
            IdPadre2: 'S-2-8',
            IdHijo: 'S-2-8',
            IdHijo2: 'S-2-8',
            EsRaiz: 0,
            EsGrupo: 1,
            EsManual: 0,
            Posicion: aAgrupado[p].sDatoPosicion,
            Valor: 0,
          };
          var acumulador = 0;
          for (let j = 0; j < aDatos.length; j++) {
            const element = aDatos[j].Items[f].Valor;
            acumulador = acumulador + element;
          }
          oCabecera.Valor = acumulador;
          Items.push(oCabecera);
        }
        ////TEMPORAL GUILLE
        //if (p === 0) {
        indexFind = indexFind + 1;
        rows.splice(indexFind, 0, {
          EsGrupo: 1,
          EsManual: 0,
          EsRaiz: 0,
          EsSplit: 0,
          Id: IdDesdoble,
          Id2: IdDesdoble,
          IdPadre: IdDesdoble,
          IdPadre2: IdDesdoble,
          Id_Partida1: oItem.Id_Partida1,
          Id_Partida2: oItem.Id_Partida2,
          Id_Partida3: oItem.Id_Partida3,
          IdentificadorCPR: 'D',
          Items: Items,
          LlaveCPR: 'D-' + (p + 1),
          Posicion: aAgrupado[p].sDatoPosicion,
          bEditable: 'NULL',
          datos_carpeta: [],
          datos_posic: [],
          datos_reg: [],
          sNombre_Partida1: oItem.sNombre_Partida1,
          sNombre_Partida2: oItem.sNombre_Partida2,
        });
        // var indexAdd = 0
        for (let indexAdd = 0; indexAdd < aDatos.length; indexAdd++) {
          const element = aDatos[indexAdd];
          indexFind = indexFind + 1;
          rows.splice(indexFind, 0, element);
        }
      }
      // rows = collect(rows).unique('Id').all();
      ///----
      //  }
    }
    // const unique = collect(rows).unique('Id').all();
    const rowsInput = [...rows];
    const rowsInputSaldo = [...rows].filter((element) => {
      return element.Id.includes('S');
    });
    const rowsInputIngreso = [...rows].filter((element) => {
      return element.Id.includes('I');
    });
    const rowsInputEgreso = [...rows].filter((element) => {
      return element.Id.includes('E');
    });
    realizarRecalculo(rowsInputSaldo, rowsInput);
    realizarRecalculo(rowsInputIngreso, rowsInput);
    realizarRecalculo(rowsInputEgreso, rowsInput);
    // setFinalArray(lista);

    // props.setFinalArray(rows);
    // props.setFinalArray(rows);
    return rows;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onPressGuardarEdicionTabla();
    }
  };

  const limpiarCollapse = (oItem, setSelectionCollapseModel, selectionCollapseModel) => {
    let listaSelectionCollapseModel = [];
    const selectionCollapseModelInicial = [...selectionCollapseModel];
    // var aEnContradoXEliminar = selectionCollapseModel.filter((element) => {
    // return (
    //   element.Id.includes(oItem.Id+ "-") &&
    //   element.Id !== oItem.Id &&
    //   oItem.Id.split("-").length + 1 === element.Id.split("-").length
    // );
    // });
    var aEnContradoXEliminar = selectionCollapseModel.filter((element) => {
      return element.Id.includes(oItem.Id + '-') && element.Id !== oItem.Id;
    });
    for (let index = 0; index < selectionCollapseModelInicial.length; index++) {
      const elementCollapseInicial = selectionCollapseModelInicial[index];
      if (oItem.Id === elementCollapseInicial.Id) {
        elementCollapseInicial['collapse'] = false;
        elementCollapseInicial['collapseIcon'] = false;
      }
      let aEncontrado = aEnContradoXEliminar.filter((element) => {
        return elementCollapseInicial.Id === element.Id;
      });
      if (aEncontrado.length === 0) {
        listaSelectionCollapseModel.push(elementCollapseInicial);
      }
    }
    setSelectionCollapseModel(listaSelectionCollapseModel);
  };

  const onSelectLimpiarDesdoble = (e, oItem) => {
    var indexFind = -1;
    const selectedDesdobles = [...props.selectedDesdobles];
    props.selectedDesdobles.find(function (item, i) {
      if (item.oPadre.Id === oItem.Id) {
        indexFind = i;
        return i;
      }
    });
    if (indexFind > -1) {
      let objEliminar = selectedDesdobles.splice(indexFind, 1);
      //     return (
      //       element.Id.includes(oItem.Id+ "-") &&
      //       element.Id !== oItem.Id &&
      //       oItem.Id.split("-").length + 1 === element.Id.split("-").length
      //     );
      // })
      //
      //     if(oItem.Id === elementCollapseInicial.Id){
      //       elementCollapseInicial["collapse"] = false;
      //       elementCollapseInicial["collapseIcon"] = false;
      //     }
      //     let aEncontrado = aEnContradoXEliminar.filter((element) => {
      //       return (
      //         elementCollapseInicial.Id === element.Id
      //       );
      //     });
      //     if(aEncontrado.length === 0){
      //     }
      //   }
      let setSelectionCollapseModel;
      let selectionCollapseModel;
      // if (oItem.Id.includes('S')) {
      // } else if (oItem.Id.includes('I')) {
      // } else if (oItem.Id.includes('E')) {
      //   setSelectionCollapseModel = props.setSelectionCollapseModel;
      //   selectionCollapseModel = props.selectionCollapseModel;
      // }
      // limpiarCollapse(oItem, setSelectionCollapseModel, selectionCollapseModel);

      const registroUltimosNiveles = props.finalArray.filter(function (item, index) {
        return item.collapse === true && item.collapseIcon === false;
      });

      let listaSelectionCollapseModelEgreso = []; //cloneDeep(props.selectionCollapseModel);
      const oElemento = cloneDeep(oItem);
      // oElemento.collapse = false;
      // oElemento.collapseIcon = false;

      listaSelectionCollapseModelEgreso = [
        // ...registroUltimosNiveles,
        ...props.finalArray.filter(function (item, index) {
          return item.collapse === false && item.collapseIcon === true && item.Id !== oItem.Id;
        }),
        ...props.finalArray.filter(function (item, index) {
          return item.collapseIcon === false && item.collapse === false && item.Id !== oItem.Id;
        }),
        ...[oElemento],
        // ...listaSelectionCollapseModelEgreso,
      ];

      listaSelectionCollapseModelEgreso = listaSelectionCollapseModelEgreso.filter(function (
        item,
        index
      ) {
        return !(
          item.Id.includes(oItem.Id) &&
          item.Id != oItem.Id &&
          item.Id.split('-').length - oItem.Id.split('-').length === 1
        );
      });

      listaSelectionCollapseModelEgreso = listaSelectionCollapseModelEgreso.filter(function (
        item,
        index
      ) {
        return !(item.Id.includes(oItem.Id) && item.Id != oItem.Id);
      });

      listaSelectionCollapseModelEgreso = [
        ...listaSelectionCollapseModelEgreso
          .filter(function (item, index) {
            return item.Id.includes('S');
          })
          .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
        ...listaSelectionCollapseModelEgreso
          .filter(function (item, index) {
            return item.Id.includes('I');
          })
          .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
        ...listaSelectionCollapseModelEgreso
          .filter(function (item, index) {
            return item.Id.includes('E');
          })
          .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
      ];

      props.setSelectionCollapseModel(listaSelectionCollapseModelEgreso);
      props.setSelectedDesdobles(selectedDesdobles);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se ha seleccionado ningún desdoble.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const onPressGuardarEdicionTabla = () => {
    const rowsInput = [...props.finalArray];
    rowsInput[props.objRightClick.indexPadre].Items.forEach((element) => {
      if (element.Id === props.objRightClick.item2.Id) {
        element.bEditable = false;
      }
    });
    // rowsInput[props.objRightClick.indexPadre].sAccion = "M"
    let { detalleFlujoCajaCabeceras } = flujoCajaState.oFlujoCajaAgregar;
    let { indexCabecera } = props.objRightClick;
    rowsInput[props.objRightClick.indexPadre].Items[indexCabecera].sAccion = 'M';
    var oActualizar = {
      sCabecera: detalleFlujoCajaCabeceras[indexCabecera].sCabecera,
      sFechaMin: detalleFlujoCajaCabeceras[indexCabecera].sFechaMin,
      sFechaMax: detalleFlujoCajaCabeceras[indexCabecera].sFechaMax,
    };

    addUpdatedSucesModificados(
      rowsInput[props.objRightClick.indexPadre].Id_Partida3,
      props.objRightClick.item2.Valor,
      rowsInput[props.objRightClick.indexPadre].sFecha
    );
    // oActualizar = {...oActualizar,...rowsInput[props.objRightClick.indexPadre].Items[indexCabecera]}
    props.setFinalArray(rowsInput);
    props.setEditTable(!editTable);
  };

  const addUpdatedSucesModificados = (IdRegistro, valor, fecha) => {
    props.setShowGrafico(false);
    var oParam = {
      IDUsuario: authState.user.uid,
      IDRegistro: IdRegistro,
      MonedaImporte: valor,
      Fecha: formatString_YYYYMMDD_TO_DDMMYYYY(fecha, '-', '/'),
      Moneda: flujoCajaState.selectMonedaTableFC[0].sAbreviatura,
    };
    var aEncontrado = flujoCajaState.listaSucesosModificados.filter((element) => {
      return element.IDRegistro === IdRegistro;
    });
    if (aEncontrado.length === 0) {
      dispatch(addListaSucesosModificados({ newItem: oParam }));
    } else {
      dispatch(updateListaSucesosModificados(oParam));
    }
  };

  const mostrarOpcionesRightClick = () => {
    let content = <></>;

    if (!props.objRightPosicionClick) {
      return content;
    }

    if (props.objRightPosicionClick.oItem.IdentificadorCPR === 'P') {
      content = (
        <ContextMenu id="my-context-menu-3">
          <ContextMenuItem onClick={(e) => onAgregarRegistro(e)}>Agregar registro</ContextMenuItem>
          <Submenu title="Características">
            {props.objRightPosicionClick.aCaracteristicas.map((item, index) => (
              <ContextMenuItem onClick={(e) => onSelectCaracteristicas(e, item)}>
                {item.sNombreCampo}
              </ContextMenuItem>
            ))}
          </Submenu>
          <ContextMenuItem
            onClick={(e) => onSelectLimpiarDesdoble(e, props.objRightPosicionClick.oItem)}
          >
            Limpiar Desdoble
          </ContextMenuItem>
        </ContextMenu>
      );
      return content;
    }
    if (props.objRightPosicionClick.oItem.IdentificadorCPR === 'D') {
      content = (
        <ContextMenu id="my-context-menu-3">
          <ContextMenuItem onClick={(e) => onAgregarRegistro(e)}>Agregar registro</ContextMenuItem>
        </ContextMenu>
      );
      return content;
    }

    if (props.objRightPosicionClick.oItem.IdentificadorCPR === 'R') {
      content = (
        <ContextMenu id="my-context-menu-3">
          <ContextMenuItem onClick={(e) => onAgregarRegistro(e)}>Agregar registro</ContextMenuItem>
        </ContextMenu>
      );
      return content;
    }
  };

  return (
    <Content>
      {loading ? <LoadingSpinner /> : <></>}
      <TableContain>
        <TableFC>
          <thead style={{ fontWeight: 'bold', textAlign: 'center' }}>
            <tr>
              <td style={{ width: '120px' }}> Posición \ Periodo </td>

              {oFlujoCajaAgregar.detalleFlujoCajaCabeceras.map((item, index) => {
                let tit = `${formatStringToDate(item.sFechaMin)} al ${formatStringToDate(
                  item.sFechaMax
                )}`;
                if (item.sCabecera.includes('Sem')) {
                  return (
                    <Tooltip title={tit}>
                      <td>{item.sCabecera}</td>
                    </Tooltip>
                  );
                } else {
                  return <td>{item.sCabecera}</td>;
                }
              })}
            </tr>
          </thead>
          <tbody id="table_body">
            {props.finalArray.map((item, index) => {
              console.log(item);
              var oItem = item;
              var indexPadre = index;
              var espacio = 0;
              if (oItem.Id.split('-').length > 1) {
                espacio = oItem.Id.split('-').length;
              }
              // console.log(oItem);
              var sangria = '';
              for (var i = 0; i < espacio; i++) {
                sangria += '&nbsp;&nbsp;';
              }
              var aEncontrado = props.finalArray.filter((element) => {
                return element.Id.includes(oItem.Id) && element.Id !== oItem.Id && oItem.collapse;
              });
              return (
                <tr id={'f-' + item.Id}>
                  {item.EsRaiz === 1 && item.EsGrupo === 1 ? (
                    <td
                      id={item.Id}
                      data-raiz={item.EsRaiz}
                      data-grupo={item.EsGrupo}
                      style={{
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      <>
                        <span dangerouslySetInnerHTML={{ __html: sangria }} />
                      </>
                      <a
                        href="#"
                        data-id={'f-' + item.Id}
                        className={'show-row'}
                        onClick={(event) => handleClick(event, item, index)}
                      >
                        {/* [ - ]{" "} */}
                        {item.collapseIcon ? '[ - ] ' : '[ + ] '}
                      </a>
                      {item.Posicion}
                    </td>
                  ) : item.EsRaiz === 0 && item.EsGrupo === 1 ? (
                    item.collapse ? (
                      <></>
                    ) : (
                      <td id={item.Id} data-raiz={item.EsRaiz} data-grupo={item.EsGrupo}>
                        <ContextMenuTrigger id="my-context-menu-3">
                          <div
                            onContextMenu={(e) => {
                              props.setObjRightPosicionClick(null);

                              /* INICIO */
                              // props.setObjRightPosicionClick(null);
                              if (oItem.IdentificadorCPR === 'P') {
                                let caracEncontradas = oItem.datos_posic;
                                ////TEMPORAL GUILLE
                                props.setObjRightPosicionClick({
                                  oEvent: e,
                                  item: item,
                                  indexPadre: indexPadre,
                                  posi: index + 1,
                                  oItem: oItem,
                                  aCaracteristicas: caracEncontradas,
                                });
                              } else if (oItem.IdentificadorCPR === 'D') {
                                props.setObjRightPosicionClick({
                                  oEvent: e,
                                  item: item,
                                  indexPadre: indexPadre,
                                  posi: index + 1,
                                  oItem: oItem,
                                });
                              } else {
                                props.setObjRightPosicionClick({
                                  oEvent: e,
                                  item: item,
                                  indexPadre: indexPadre,
                                  posi: index + 1,
                                  oItem: oItem,
                                  aCaracteristicas: [],
                                });
                              }

                              /* FIN */
                              return;
                              props.setObjRightPosicionClick(null);
                              if (oItem.IdentificadorCPR === 'P') {
                                let caracEncontradas = oItem.datos_posic;
                                if (caracEncontradas.length === 0 && oItem.Id_Partida2 !== 0) {
                                  Swal.fire({
                                    position: 'center',
                                    icon: 'warning',
                                    title: 'No se encontraron características asociadas.',
                                    showConfirmButton: false,
                                    timer: 2000,
                                  });
                                } else if (caracEncontradas.length > 0) {
                                  props.setObjRightPosicionClick({
                                    oEvent: e,
                                    item: item,
                                    indexPadre: indexPadre,
                                    posi: index + 1,
                                    oItem: oItem,
                                    aCaracteristicas: caracEncontradas,
                                  });
                                }
                              } else {
                                props.setObjRightPosicionClick({
                                  oEvent: e,
                                  item: item,
                                  indexPadre: indexPadre,
                                  posi: index + 1,
                                  oItem: oItem,
                                  aCaracteristicas: [],
                                });
                              }
                            }}
                          >
                            <>
                              <span dangerouslySetInnerHTML={{ __html: sangria }} />
                            </>
                            <a
                              href="#"
                              data-id={'f-' + item.Id}
                              className={'show-row'}
                              onClick={(event) => handleClick(event, item, index)}
                            >
                              {/* [ - ] */}
                              {item.collapseIcon ? '[ - ] ' : '[ + ] '}
                            </a>
                            {item.Posicion}
                          </div>
                        </ContextMenuTrigger>
                      </td>
                    )
                  ) : item.collapse ? (
                    <></>
                  ) : (
                    <td id={item.Id} data-raiz={item.EsRaiz} data-grupo={item.EsGrupo}>
                      <ContextMenuTrigger id="my-context-menu-3">
                        <div
                          onContextMenu={(e) => {
                            props.setObjRightPosicionClick({
                              oEvent: e,
                              item: item,
                              indexPadre: indexPadre,
                              posi: index + 1,
                              oItem: oItem,
                            });
                          }}
                        >
                          <>
                            <span dangerouslySetInnerHTML={{ __html: sangria }} />
                          </>
                          {item.Posicion}
                        </div>
                      </ContextMenuTrigger>
                    </td>
                  )}

                  {item.collapse ? (
                    <></>
                  ) : (
                    oItem.Items.map((item2, index) => (
                      <td
                        className={`contenidoTable ${
                          oItem.IdentificadorCPR !== 'R'
                            ? ' otros '
                            : item2.EsGrupo === 0 &&
                              item2.EsManual === 1 &&
                              item2.EsRaiz === 0 &&
                              item2.EsSplit === 0
                            ? ' manual '
                            : item2.EsGrupo === 0 &&
                              item2.EsManual === 0 &&
                              item2.EsRaiz === 0 &&
                              item2.EsSplit === 0
                            ? ' noManual '
                            : ''
                        }
                    `}
                      >
                        {item2.EsGrupo === 0 &&
                        item2.EsManual === 1 &&
                        item2.EsRaiz === 0 &&
                        item2.EsSplit === 0 &&
                        item2.bEditable === true ? (
                          <div>
                            <NumberTable
                              onKeyDown={handleKeyDown}
                              value={item2.Valor}
                              thousandSeparator={','}
                              allowNegative={true}
                              decimalSeparator={'.'}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              inputmode="numeric"
                              onValueChange={(values, sourceInfo) =>
                                handleChangeInputMonto(
                                  values,
                                  sourceInfo,
                                  item2,
                                  index + 1,
                                  oItem,
                                  indexPadre
                                )
                              }
                            />
                          </div>
                        ) : item2.Valor !== 0 ? (
                          <ContextMenuTrigger id="my-context-menu-1">
                            <div
                              onDragOver={(e) => allowDrop(e)}
                              onDrop={(e) => onDropLeft(e, item2, index + 1, oItem, indexPadre)}
                              id={item2.Id}
                              data-pos={oItem.Id}
                              data-raiz={item2.EsRaiz}
                              data-grupo={item2.EsGrupo}
                              data-col={index + 1}
                              data-val={item2.Valor}
                              data-mid={item2.Id_Movimiento}
                              className={`redips-drag`}
                              onContextMenu={(e) => {
                                props.setObjRightClick({
                                  oEvent: e,
                                  item2: item2,
                                  indexPadre: indexPadre,
                                  posi: index + 1,
                                  indexCabecera: index,
                                  oItem: oItem,
                                });
                              }}
                            >
                              <NumberTable
                                onDragStart={(e) =>
                                  onDragStart(e, item2, index + 1, oItem, indexPadre)
                                }
                                draggable="true"
                                value={item2.Valor}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                allowNegative={signoAllow}
                                fixedDecimalScale={true}
                                decimalScale={2}
                              />
                            </div>
                          </ContextMenuTrigger>
                        ) : (
                          <div
                            onDrop={(e) => onDropLeft(e, item2, index + 1, oItem, indexPadre)}
                            onDragOver={(e) => allowDrop(e)}
                            onContextMenu={(e) => {
                              props.setObjRightClick({
                                oEvent: e,
                                item2: item2,
                                indexPadre: indexPadre,
                                posi: index + 1,
                                oItem: oItem,
                              });
                            }}
                          >
                            <NumberTable
                              style={{ display: 'block', width: '24px', height: '24px' }}
                              value={''}
                              allowNegative={signoAllow}
                              displayType={'text'}
                              thousandSeparator={','}
                              decimalSeparator={'.'}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          </div>
                        )}
                      </td>
                    ))
                  )}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td className={'redips-mark-Totales'}>{props.finalArrayTotalParciales.sNombre}</td>
              {props.finalArrayTotalParciales.Items != null ? (
                props.finalArrayTotalParciales.Items.map((item2, index2) => (
                  <td className="totales">
                    <div id={`t${index2 + 1}${item2.Id}`} data-pos={item2.Id}>
                      <NumberTotalTable
                        isNegative={item2.Valor < 0 ? true : false}
                        value={item2.Valor}
                        allowNegative={true}
                        displayType={'text'}
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </div>
                  </td>
                ))
              ) : (
                <></>
              )}
            </tr>
            <tr>
              <td className={'redips-mark-Totales'}>{props.finalArrayTotal.sNombre}</td>
              {props.finalArrayTotal.Items != null ? (
                props.finalArrayTotal.Items.map((item2, index2) => (
                  <td className="totales">
                    <div id={`t${index2 + 1}${item2.Id}`} data-pos={item2.Id}>
                      <NumberTotalTable
                        isNegative={item2.Valor < 0 ? true : false}
                        value={item2.Valor}
                        allowNegative={true}
                        displayType={'text'}
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </div>
                  </td>
                ))
              ) : (
                <></>
              )}
            </tr>
          </tfoot>
        </TableFC>
        {!props.objRightClick.oItem ? (
          <></>
        ) : props.objRightClick.oItem.EsRaiz === 0 &&
          props.objRightClick.oItem.EsGrupo === 0 &&
          props.objRightClick.oItem.EsManual === 1 ? (
          <ContextMenu id="my-context-menu-1">
            <ContextMenuItem onClick={(e) => onModalMoverFechaM(e)}>Mover a</ContextMenuItem>
            <ContextMenuItem onClick={(e) => onEliminarRegistro(e)}>Eliminar fila</ContextMenuItem>
            <ContextMenuItem onClick={(e) => onEditarFila(e)}>Editar fila</ContextMenuItem>
          </ContextMenu>
        ) : (
          <ContextMenu id="my-context-menu-1">
            <ContextMenuItem onClick={(e) => onModalMoverFechaM(e)}>Mover a</ContextMenuItem>
          </ContextMenu>
        )}
        {mostrarOpcionesRightClick()}
        <ModalConfirmation
          showModal={modalEliminarRegistro}
          closeModal={(e) => setModalEliminarRegistro(false)}
          title="¿Confirmación?"
          subtitle="¿Esta seguro que desea eliminar el registro?"
          action={(e) => onConfirmarEliminarRegistros(e)}
          labelButtonCancel="Cancelar"
          labelButtonConfirm="Aceptar"
        />
        <ModalRegM
          finalArray={props.finalArray}
          setFinalArray={props.setFinalArray}
          //GNM
          finalArraySinFiltro={props.finalArraySinFiltro}
          setFinalArraySinFiltro={props.setFinalArraySinFiltro}
          finalArrayTotal={props.finalArrayTotal}
          setFinalArrayTotal={props.setFinalArrayTotal}
          finalArrayTotalParciales={props.finalArrayTotalParciales}
          setFinalArrayTotalParciales={props.setFinalArrayTotalParciales}
          objRightPosicionClick={props.objRightPosicionClick}
          setObjRightPosicionClick={props.setObjRightPosicionClick}
          realizarRecalculo={realizarRecalculo}
          calcularTotalYsubTotal={props.calcularTotalYsubTotal}
          setFormAgregarRegM={props.setFormAgregarRegM}
          formAgregarRegM={props.formAgregarRegM}
          limpiarFormAgregarRegM={props.limpiarFormAgregarRegM}
          onRealizarDesdoblesAutomatico={onRealizarDesdoblesAutomatico}
          //prueba
          selectionCollapseModel={props.selectionCollapseModel}
          setSelectionCollapseModel={props.setSelectionCollapseModel}
          realizarRecalculoMejorado={props.realizarRecalculoMejorado}
          collapseChild={props.collapseChild}
          selectedDesdobles={props.selectedDesdobles}
        />
        <ModalMoverFecha
          setShowGrafico={props.setShowGrafico}
          finalArray={props.finalArray}
          setFinalArray={props.setFinalArray}
          //GNM
          finalArraySinFiltro={props.finalArraySinFiltro}
          setFinalArraySinFiltro={props.setFinalArraySinFiltro}
          finalArrayTotal={props.finalArrayTotal}
          setFinalArrayTotal={props.setFinalArrayTotal}
          finalArrayTotalParciales={props.finalArrayTotalParciales}
          setFinalArrayTotalParciales={props.setFinalArrayTotalParciales}
          objRightClick={props.objRightClick}
          objRightPosicionClick={props.objRightPosicionClick}
          setObjRightPosicionClick={props.setObjRightPosicionClick}
          obtenerHijos={obtenerHijos}
          buscarTodosRegNoManuales={buscarTodosRegNoManuales}
          obtenerFecha={obtenerFecha}
          realizarRecalculo={realizarRecalculo}
          calcularTotalYsubTotal={calcularTotalYsubTotal}
          addUpdatedSucesModificados={addUpdatedSucesModificados}
          actualizarFinalArraySinFiltro={actualizarFinalArraySinFiltro}
        />
      </TableContain>
    </Content>
  );
});
