import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { theme } from '../common';
import { TimePicker } from 'antd';

export const DatePickerForm = styled(DatePicker)`
  /* width: ${({ width }) => (width ? width : '320px')}; */
  width: ${({ width }) => (width ? width : '98%')};
  display: flex;
  align-items: center;
  border: 2px solid ${theme.colors.line};
  transition: width 0.5s;
  height: 32px;
  z-index: 1300; // antes era 1100
  font-family: ${theme.font.family};
  font-size: 12px;
  margin-left: 0px;
  text-align: ${({ alignText }) => (alignText ? alignText : 'justify')};
  padding: ${({ active }) => (true ? '0 10px' : '0px')};
  opacity: ${({ active }) => (true ? '1' : '0')};
  &:focus {
    outline: none !important;
    border-color: ${({ validate }) =>
      !validate ? theme.colors.line : validate === true ? theme.colors.line : theme.colors.warning};
  }
  background: #fff;
  border-radius: 2px;
  border-color: ${({ validate }) =>
    !validate ? theme.colors.line : validate === true ? 'none' : theme.colors.warning};
  transition: ${({ validate }) =>
    !validate ? 'none' : validate === true ? 'none' : 'border-color 0.5s ease-out'};
`;

export const TimePickerForm = styled(TimePicker)`
  /* width: ${({ width }) => (width ? width : '320px')}; */
  width: ${({ width }) => (width ? width : '98%')};
  display: flex;
  align-items: center;
  border: 2px solid ${theme.colors.line};
  height: 32px;
  font-family: ${theme.font.family};
  font-size: 12px;
  text-align: ${({ alignText }) => (alignText ? alignText : 'justify')};
  box-shadow: none;
  &:focus {
    outline: none !important;
    border-color: ${({ validate }) =>
      !validate
        ? theme.colors.primary
        : validate === true
        ? theme.colors.line
        : theme.colors.warning};
    box-shadow: none;
  }
  &:hover {
    outline: none !important;
    border-color: ${({ validate }) =>
      !validate
        ? theme.colors.primary
        : validate === true
        ? theme.colors.line
        : theme.colors.warning};
  }
  background: #fff;
  border-radius: 2px;
  border-color: ${({ validate }) =>
    !validate ? theme.colors.line : validate === true ? 'none' : theme.colors.warning};
`;
