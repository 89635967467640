import { FaPenToSquare } from 'react-icons/fa6';
import { permisos } from '../../../../../common';
import { ButtonForm, PBR } from '../../../../../components';
import { CheckboxStyle } from '../../../../../styles';

export const columns = (findConcepto, handleChange, onClickEditarConcepto) => [
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <>
        {
          <CheckboxStyle
            checked={findConcepto(params.row)}
            onChange={() => handleChange(params.row)}
            size="small"
          />
        }
      </>
    ),
  },
  {
    field: 'sNombreAgrupador',
    headerName: 'Nombre',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'sTipoConcepto',
    headerName: 'Tipo',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'sReal_Proyectado',
    headerName: 'Real/Proyectado',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'edit',

    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <PBR permiso={[permisos.EDITAR_CONCEPTO]}>
        <ButtonForm
          action={(e) => onClickEditarConcepto(params.row)}
          icon={<FaPenToSquare />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="primary"
        />
      </PBR>
    ),
  },
];
