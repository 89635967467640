import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../../../../../common/hooks';
import Swal from 'sweetalert2';
import collect from 'collect.js';
import {
  Button,
  ButtonForm,
  CustomDateRangePicker,
  SearchInputText,
  SelectForm,
  TableGrid,
} from '../../../../../../components';
import { obtenerSoluciones } from '../../../../../../services';
import {
  createSolucionModalFlujoCajaAdapter,
  createTipoSolucionAdapter,
} from '../../../../../../adapters';
import {
  onChangeSearchModalSolSearchFlujoCaja,
  onChangeVigenciaSolFlujoCaja,
  onOpenModalSolucionesDetalleFlujoCaja,
  onSelectTipoEstadoSoluFlujocaja,
  onSelectTipoSoluFlujocaja,
  onSetFilterListaModalSolFlujoCaja,
  onSetListaModalSolFlujoCaja,
  onSetListaTipoSolucionesFlujoCaja,
  onSetSelectSolucionesModalFlujoCaja,
} from '../../../../../../redux/states';
import { TipoData, TipoSolucionesData } from '../../../../../../common';
import { dateTo_yyyy_mm_dd } from '../../../../../../common';
import { Checkbox } from '@mui/material';
import { RowFlex } from '../../../../../../styles';
export const ModalSoluciones = (props) => {
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const [selectionModel, setSelectionModel] = React.useState([]);
  // const loadSoluciones = async () => {
  //   const responseListaLineaCredito = await callEndpoint(obtenerSoluciones({
  //     "oTipoSolucion": ["LC"]
  // }));
  //   var listaModalSolFlujoCaja = [];
  //   if(responseListaLineaCredito!=null){
  //     if(responseListaLineaCredito.data){
  //       if (responseListaLineaCredito.data.cabecera.statusCode === 200) {
  //         for (
  //           let index = 0;
  //           index < responseListaLineaCredito.data.respuesta.aListaSolucion.length;
  //           index++
  //         ) {
  //           const element =
  //             responseListaLineaCredito.data.respuesta.aListaSolucion[index];
  //           var obj = createSolucionModalFlujoCajaAdapter(element);
  //           listaModalSolFlujoCaja.push(obj);
  //         }
  //       }
  //     }
  //   }
  //    dispatch(onSetListaModalSolFlujoCaja({"listaModalSolFlujoCaja" : listaModalSolFlujoCaja}));
  //    dispatch(onSetFilterListaModalSolFlujoCaja( { "filterListaModalSolFlujoCaja" : listaModalSolFlujoCaja}))
  // };
  useEffect(() => {
    (async () => {
      // await loadSoluciones();
      var tipoSolucionesFlujoCaja = [];

      TipoSolucionesData.forEach((element) => {
        tipoSolucionesFlujoCaja.push(createTipoSolucionAdapter(element));
      });
      dispatch(
        onSetListaTipoSolucionesFlujoCaja({ tipoSolucionesFlujoCaja: tipoSolucionesFlujoCaja })
      );
    })();
  }, []);
  const findConcepto = (person) => {
    return !!selectionModel.find((p) => p.idSolucionBase === person.idSolucionBase);
  };
  const filterConcepto = (person) => {
    return selectionModel.filter((p) => p.idSolucionBase !== person.idSolucionBase);
  };
  const handleChange = (person) => {
    const filteredPeople = findConcepto(person)
      ? filterConcepto(person)
      : [...selectionModel, person];
    setSelectionModel(filteredPeople);
  };
  const columns = [
    // {
    //   field: "actions",
    //   type: "actions",
    //   sortable: false,
    //   headerName: "",
    //   width: 50,
    //   renderCell: (params) => (
    //     <>
    //       {
    //         <Checkbox
    //           size="small"
    //           // color="warning"
    //           checked={findConcepto(params.row)}
    //           onChange={() => handleChange(params.row)}
    //         />
    //       }
    //     </>
    //   ),
    // },
    {
      field: 'codigo',
      headerName: 'Código',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'nombre',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'descripcion',
      headerName: 'Descripción',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'fechaVigencia',
      headerName: 'Fecha de Vigencia',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
  ];
  const onCloseModal = (e) => {
    dispatch(onOpenModalSolucionesDetalleFlujoCaja({ modalSolucionesFlujoCaja: false }));
  };
  const handleChangeMultiKey = (event) => {
    // setValue(event);
    dispatch(
      onSelectTipoSoluFlujocaja({
        selectTipoSoluFlujocaja: event,
      })
    );
  };
  const handleChangeMultiKeyEstado = (event) => {
    // setValue(event);
    dispatch(
      onSelectTipoEstadoSoluFlujocaja({
        selectTipoEstadoSolFlujoCaja: event,
      })
    );
  };
  useEffect(() => {
    let lista = [...flujoCajaState.listaModalSolFlujoCaja];

    if (flujoCajaState.selectTipoSoluFlujocaja.length > 0) {
      lista = lista.filter(function (item) {
        for (var i = 0; i < flujoCajaState.selectTipoSoluFlujocaja.length; i++) {
          if (item['tipo'] === flujoCajaState.selectTipoSoluFlujocaja[i].value) {
            return true;
          }
        }
        return false;
      });
    }
    if (flujoCajaState.selectTipoEstadoSolFlujoCaja.length > 0) {
      lista = lista.filter(function (item) {
        for (var i = 0; i < flujoCajaState.selectTipoEstadoSolFlujoCaja.length; i++) {
          if (item['idEstado'] === flujoCajaState.selectTipoEstadoSolFlujoCaja[i].value) {
            return true;
          }
        }
        return false;
      });
    }
    if (flujoCajaState.searchModalSolSearchFlujoCaja.length > 0) {
      lista = lista.filter(function (item) {
        return (
          item.codigo
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.nombre
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.descripcion
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.fechaVigencia
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase())
        );
      });
    }
    if (flujoCajaState.searchModalSolSearchFlujoCaja.length > 0) {
      lista = lista.filter(function (item) {
        return (
          item.codigo
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.nombre
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.descripcion
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.fechaVigencia
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase())
        );
      });
    }
    var func = dateTo_yyyy_mm_dd;
    if (
      flujoCajaState.dDateVigenciaSoluFlujoCaja[0] !== null &&
      flujoCajaState.dDateVigenciaSoluFlujoCaja[1] != null
    ) {
      lista = lista.filter(function (item) {
        return (
          item.fechaInicioFormat >= func(flujoCajaState.dDateVigenciaSoluFlujoCaja[0], '-') &&
          item.fechaInicioFormat <= func(flujoCajaState.dDateVigenciaSoluFlujoCaja[1], '-')
        );
      });
    }
    //  if(flujoCajaState.dDateVigenciaSoluFlujoCaja[1] !== null){
    //   lista = lista.filter(
    //    function (item) {
    //      return (
    //       item.fechaFinFormat <= dateTo_yyyy_mm_dd(flujoCajaState.dDateVigenciaSoluFlujoCaja[1],"-")
    //      );
    //    }
    //  );
    // }
    // if( flujoCajaState.selectTipoSoluFlujocaja.length>0 || flujoCajaState.searchModalSolSearchFlujoCaja.length>0||
    //   flujoCajaState.dDateVigenciaSoluFlujoCaja[0] != null || flujoCajaState.dDateVigenciaSoluFlujoCaja[1] !=null
    //   ){
    //     dispatch(onSetFilterListaModalSolFlujoCaja( { "filterListaModalSolFlujoCaja" : lista}))
    // }else{
    //   dispatch(onSetFilterListaModalSolFlujoCaja( { "filterListaModalSolFlujoCaja" : []}))
    // }

    dispatch(onSetFilterListaModalSolFlujoCaja({ filterListaModalSolFlujoCaja: lista }));
    // setSelectionModel(flujoCajaState.selectSolucionesModalFlujoCaja)
    // dispatch(onSetSelectSolucionesModalFlujoCaja({"selectSolucionesModalFlujoCaja" : selectionModel}))
    // setSelectionModel(
    //   flujoCajaState.filterListaModalSolFlujoCaja.filter((r) =>  flujoCajaState.selectSolucionesModalFlujoCaja.filter(element => element === r.idSolucionBase).length>0   ).map((r) => r.idSolucionBase)
    // if(selectionModel.length === 0){
    // setSelectionModel(
    //   flujoCajaState.listaModalSolFlujoCaja.filter((r) =>  flujoCajaState.selectSolucionesModalFlujoCaja.filter(element => element === r.idSolucionBase).length>0   ).map((r) => r.idSolucionBase)
    // )
    // }
    // )
  }, [
    flujoCajaState.selectTipoSoluFlujocaja,
    flujoCajaState.searchModalSolSearchFlujoCaja,
    flujoCajaState.dDateVigenciaSoluFlujoCaja,
    flujoCajaState.selectTipoEstadoSolFlujoCaja,
  ]);
  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      // dispatch(filterListarRegistrosM(oEvent.target.value));
      // dispatch(changeSearchTextManual(oEvent.target.value));
      dispatch(
        onChangeSearchModalSolSearchFlujoCaja({
          searchModalSolSearchFlujoCaja: oEvent.target.value,
        })
      );
    } else {
      // dispatch(filterListarRegistrosM(""));
      // dispatch(changeSearchTextManual(""));
      dispatch(onChangeSearchModalSolSearchFlujoCaja(''));
    }
  };
  const handleChangeFechaCrearFlujoCaja = (oEvent) => {
    var oParam = {
      dFechaInicio: oEvent[0],
      dFechaFin: oEvent[1],
    };
    var sFechaInicio = '';
    var sFechaFinal = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';
    if (oParam.dFechaInicio != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      var iDia = oParam.dFechaInicio.getDate();
      var iMes = oParam.dFechaInicio.getMonth() + 1;
      var iAnio = oParam.dFechaInicio.getFullYear();
      if (oParam.dFechaInicio.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
    }
    if (oParam.dFechaFin != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaFin.getDate();
      let iMes = oParam.dFechaFin.getMonth() + 1;
      let iAnio = oParam.dFechaFin.getFullYear();
      if (oParam.dFechaFin.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaFinal = sDia + '/' + sMes + '/' + sAnio;
    }
    oParam.sFechaInicio = sFechaInicio;
    oParam.sFechaFinal = sFechaFinal;
    dispatch(
      onChangeVigenciaSolFlujoCaja({
        dDateVigenciaSoluFlujoCaja: [oParam.dFechaInicio, oParam.dFechaFin],
        bValidDateVigenciaSoluFlujoCaja: true,
      })
    ); //oParam));
  };
  const onAplicarSoluciones = (e) => {
    dispatch(
      onSetSelectSolucionesModalFlujoCaja({ selectSolucionesModalFlujoCaja: selectionModel })
    );
    dispatch(onOpenModalSolucionesDetalleFlujoCaja({ modalSolucionesFlujoCaja: false }));
  };
  const limpiarSeleccion = (e) => {
    // dispatch(onSetSelectSolucionesModalFlujoCaja({"selectSolucionesModalFlujoCaja" : []}))
    setSelectionModel([]);
    // dispatch(onOpenModalSolucionesDetalleFlujoCaja({"modalSolucionesFlujoCaja" : false}))
  };
  const onSeleccionarTodos = (e) => {
    dispatch(
      onSetSelectSolucionesModalFlujoCaja({
        selectSolucionesModalFlujoCaja: flujoCajaState.listaModalSolFlujoCaja,
      })
    );
    setSelectionModel(flujoCajaState.listaModalSolFlujoCaja);
  };
  return (
    <ModalComponent
      backdrop="static"
      keyboard={false}
      show={flujoCajaState.modalSolucionesFlujoCaja}
      onHide={onCloseModal}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Soluciones</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <RowFlex>
          <SelectForm
            width={'300px'}
            bValid={true}
            value={flujoCajaState.selectTipoSoluFlujocaja}
            handleChange={(evnt) => handleChangeMultiKey(evnt)}
            options={flujoCajaState.tipoSolucionesFlujoCaja}
            placeholder="Seleccione Tipo Sol."
            isMulti={true}
          />
          <CustomDateRangePicker
            width="150px"
            dateFormat="dd/MM/yyyy"
            selectsRange={true}
            startDate={flujoCajaState.dDateVigenciaSoluFlujoCaja[0]}
            endDate={flujoCajaState.dDateVigenciaSoluFlujoCaja[1]}
            onChange={(evnt) => handleChangeFechaCrearFlujoCaja(evnt)}
            isClearable={true}
            placeholderText="Ingrese"
            locale="es"
            showMonthDropdown
            showYearDropdown
            portalId="root-portal"
            validate={flujoCajaState.bValidDateVigenciaSoluFlujoCaja}
          />
          <SearchInputText
            width={'200px'}
            placeholder={'Buscar...'}
            onChange={(e) => onChangeSearch(e)}
            filter={flujoCajaState.searchModalSolSearchFlujoCaja}
          />
          <SelectForm
            width={'280px'}
            bValid={true}
            value={flujoCajaState.selectTipoEstadoSolFlujoCaja}
            handleChange={(evnt, v) => handleChangeMultiKeyEstado(v)}
            options={flujoCajaState.listaTipoEstadoSolFlujoCaja}
            placeholder="Seleccione Estado"
            isMulti={true}
          ></SelectForm>
        </RowFlex>
        <TableGrid
          columns={columns}
          rows={flujoCajaState.filterListaModalSolFlujoCaja}
          pageSize={15}
          getRowId={(row) => row.idSolucionBase}
        />
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <ButtonForm
          action={onCloseModal}
          name="CanCancelarcelar"
          isAction={false}
          typeColor="warning"
        />
      </ModalComponent.Footer>
    </ModalComponent>
  );
};
const RowFiltros = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 45px;
  margin: 12px 0px 12px 0px;
  align-items: center;
  justify-content: center;
`;
const RowFiltrosTitulos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 45px;
  margin: 12px 0px 12px 0px;
  align-items: center;
  justify-content: center;
`;
const ModalComponent = styled(Modal)`
  .modal-dialog {
    max-width: 1200px !important;
  }
`;
const RowCabecera = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 10px;
  margin: 12px 10px 10px 0px;
  align-items: flex-start;
  justify-content: start;
`;
