import React, { useState } from 'react';
import { Modal, Upload } from 'antd';
import { ButtonForm, Input, LabelForm, SelectForm } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { changeModalDocumento, changeModalSocio } from '../../../../redux/states';
import { ColForm, DatePickerForm, RowForm, Title } from '../../../../styles';
import { listaTipoDeDocumentos } from '../../../../common';

export const ModalDocumento = (props) => {
  const socioState = useSelector((store) => store.socio);
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [multipleFiles, setMultipleFiles] = useState(true);
  const dispatch = useDispatch();

  const changeTipoDocumento = (event) => {
    let valor = { tTipo: [event] };
    let modal = socioState.oModalDocumento;
    modal = { ...modal, ...valor };
    dispatch(changeModalDocumento({ oModalDocumento: modal }));
  };

  const changeNombreDocumento = (event) => {
    let valor = { tNombre: event.target.value };
    let modal = socioState.oModalDocumento;
    modal = { ...modal, ...valor };
    dispatch(changeModalDocumento({ oModalDocumento: modal }));
  };

  const changeDescripcionDocumento = (event) => {
    let valor = { tDescripcion: event.target.value };
    let modal = socioState.oModalDocumento;
    modal = { ...modal, ...valor };
    dispatch(changeModalDocumento({ oModalDocumento: modal }));
  };

  const changeFechaDocumento = (event) => {
    let valor = { tFecha: event };
    let modal = socioState.oModalDocumento;

    var sFechaInicio = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';

    if (valor.tFecha != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = valor.tFecha.getDate();
      let iMes = valor.tFecha.getMonth() + 1;
      let iAnio = valor.tFecha.getFullYear();
      if (valor.tFecha.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
      valor.tFecha = sFechaInicio;
    }
    modal = { ...modal, ...valor };
    dispatch(changeModalDocumento({ oModalDocumento: modal }));
  };

  const changeDocumento = (info) => {
    switch (info.file.status) {
      case 'uploading':
        onFileUploading(info);
        break;
      case 'done':
        onFileDone(info);
        break;
      case 'removed':
        onFileRemove(info);
        break;
    }

    // let valor = { tLogo: event.target.value };
    // let modal = socioState.oModal;
    // modal = { ...modal, ...valor };
    // dispatch(changeModalDocumento({ oModal: modal }));
  };

  const onFileUploading = (info) => {
    let files = [...socioState.oModalDocumento.tDocumento];
    if (multipleFiles) files.push(info.file);
    else files = [info.file];
    let valor = { tDocumento: files };
    let modal = socioState.oModalDocumento;
    modal = { ...modal, ...valor };
    dispatch(changeModalDocumento({ oModalDocumento: modal }));
  };

  const onFileDone = (info) => {
    let files = [...socioState.oModalDocumento.tDocumento];
    let find;
    if (!multipleFiles) files = [info.file];
    else {
      files = files.filter((r) => r.uid != info.file.uid);
      files.push(info.file);
    }

    let valor = { tDocumento: files };
    let modal = socioState.oModalDocumento;
    modal = { ...modal, ...valor };
    dispatch(changeModalDocumento({ oModalDocumento: modal }));
  };

  const onFileRemove = (info) => {
    if (multipleFiles) {
      const filtered = socioState.oModalDocumento.tDocumento.filter((x) => x.uid != info.file.uid);
      let valor = { tDocumento: filtered };
      let modal = socioState.oModalDocumento;
      modal = { ...modal, ...valor };
      dispatch(changeModalDocumento({ oModalDocumento: modal }));
    } else {
      let valor = { tDocumento: [] };
      let modal = socioState.oModalDocumento;
      modal = { ...modal, ...valor };
      dispatch(changeModalDocumento({ oModalDocumento: modal }));
    }
  };

  const dummyRequest = (option) => {
    setTimeout(() => {
      option.onSuccess('ok');
    }, 0);
  };

  return (
    <>
      <Modal
        open={props.open}
        title={
          <Title>{socioState.oModalDocumento.nId ? 'Guardar Documento' : 'Crear Documento'}</Title>
        }
        closable={false}
        footer={[
          <ButtonForm
            action={(oEvent) => props.onPressClose(oEvent)}
            name={'Cerrar'}
            isAction={true}
            typeColor="warning"
          />,
          <ButtonForm
            style={{ marginLeft: '5px' }}
            action={(oEvent) => props.onPressSave(oEvent)}
            name={'Guardar'}
            isAction={true}
            typeColor="secondary"
          />,
        ]}
      >
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Nombre (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={socioState.oModalDocumento.tNombre}
              onChange={(evnt) => changeNombreDocumento(evnt)}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Tipo (*): '}></LabelForm>
            <SelectForm
              value={socioState.oModalDocumento.tTipo}
              handleChange={(e, v) => changeTipoDocumento(v)}
              options={listaTipoDeDocumentos}
              placeholder={'Seleccione Tipo'}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Descripción (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={socioState.oModalDocumento.tDescripcion}
              onChange={(evnt) => changeDescripcionDocumento(evnt)}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Fecha de vigencia (*): '}></LabelForm>
            <DatePickerForm
              dateFormat="dd/MM/yyyy"
              selectsRange={false}
              value={socioState.oModalDocumento.tFecha}
              onChange={(evnt) => changeFechaDocumento(evnt)}
              isClearable={true}
              placeholderText="Ingrese"
              locale="es"
              showMonthDropdown
              showYearDropdown
              portalId="root-portal"
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <LabelForm obligatorio={true} title={'Documento (*): '}></LabelForm>
          <Upload.Dragger
            // accept=".xlsx, .xls" // TODO: segementar en una propiedad
            style={{ width: '98%' }}
            fileList={socioState.oModalDocumento.tDocumento}
            onChange={changeDocumento}
            customRequest={dummyRequest}
            multiple={multipleFiles}
            // disabled={socioState.oModalDocumento.nId ? true : false}
          >
            <p className="ant-upload-text" style={{ width: '98%' }}>
              Click o arrastra un archivo a esta zona para subirlo
            </p>
          </Upload.Dragger>
        </ColForm>
      </Modal>
    </>
  );
};
