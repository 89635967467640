import {
  FaBell,
  FaBriefcase,
  FaChartPie,
  FaDiagramProject,
  FaIndustry,
  FaPalette,
  FaRocket,
  FaShieldHalved,
  FaTable,
} from 'react-icons/fa6';
import { paths, permisos } from './index';
import { buildPath } from '../index';

export const menuItems = (onClick, onContext) => {
  return [
    {
      key: paths.HOME.DASHBOARD,
      label: 'Dashboard',
      title: 'Dashboard',
      icon: <FaChartPie />,
      onClick: (e) => {
        onClick(paths.HOME.DASHBOARD);
      },
      onContextMenu: (e) => {
        onContext(paths.HOME.DASHBOARD);
      },
      permiso: [permisos.VER_DASHBOARD],
    },
    {
      key: paths.SECURITY.BASE,
      label: 'Seguridad',
      icon: <FaShieldHalved />,
      permiso: [permisos.VER_USUARIO, permisos.VER_ROL],
      children: [
        {
          key: paths.SECURITY.USER,
          label: 'Usuario',
          title: 'Usuario',
          onClick: (e) => {
            onClick(paths.SECURITY.USER);
          },
          onContextMenu: (e) => {
            onContext(paths.SECURITY.USER);
          },
          permiso: [permisos.VER_USUARIO],
        },
        {
          key: paths.SECURITY.ROLE,
          label: 'Rol',
          title: 'Rol',
          onClick: (e) => {
            onClick(paths.SECURITY.ROLE);
          },
          onContextMenu: (e) => {
            onContext(paths.SECURITY.ROLE);
          },
          permiso: [permisos.VER_ROL],
        },
      ],
    },
    {
      key: paths.AUTOMATION.BASE,
      label: 'Automatización',
      title: 'Automatización',
      icon: <FaDiagramProject />,
      onClick: (e) => {
        onClick(paths.AUTOMATION.BASE);
      },
      onContextMenu: (e) => {
        onContext(paths.AUTOMATION.BASE);
      },
      permiso: [permisos.VER_AUTOMATIZACION],
    },
    {
      key: paths.PERSONALIZATION,
      label: 'Personalización',
      title: 'Personalización',
      icon: <FaPalette />,
      onClick: (e) => {
        onClick(paths.PERSONALIZATION);
      },
      onContextMenu: (e) => {
        onContext(paths.PERSONALIZATION);
      },
      permiso: [permisos.VER_PERSONALIZACION],
    },
    {
      key: paths.CONFIGURE.BASE,
      label: 'Configuración',
      icon: <FaTable />,
      permiso: [
        permisos.VER_SISTEMAS,
        permisos.VER_CAMPOS,
        permisos.VER_FUNCION,
        permisos.VER_CONCEPTO,
        permisos.VER_POSICION,
        permisos.VER_ESTRUCTURA,
        permisos.VER_TIPOCAMBIO,
        permisos.VER_DERIVACION,
      ],
      children: [
        {
          key: paths.CONFIGURE.SOCIETY.BASE,
          label: 'Sociedad',
          title: 'Sociedad',
          onClick: (e) => {
            onClick(paths.CONFIGURE.SOCIETY.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.SOCIETY.BASE);
          },
          permiso: [],
        },
        {
          key: paths.CONFIGURE.CALENDAR.BASE,
          label: 'Calendario',
          title: 'Calendario',
          onClick: (e) => {
            onClick(paths.CONFIGURE.CALENDAR.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.CALENDAR.BASE);
          },
          permiso: [],
        },
        {
          key: paths.CONFIGURE.SYSTEM.BASE,
          label: 'Sistemas',
          title: 'Sistemas',
          onClick: (e) => {
            onClick(paths.CONFIGURE.SYSTEM.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.SYSTEM.BASE);
          },
          permiso: [permisos.VER_SISTEMAS],
        },
        {
          key: paths.CONFIGURE.FIELD,
          label: 'Campos',
          title: 'Campos',
          onClick: (e) => {
            onClick(paths.CONFIGURE.FIELD);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.FIELD);
          },
          permiso: [permisos.VER_CAMPOS],
        },
        {
          key: paths.CONFIGURE.FUNCTION.BASE,
          label: 'Funciones',
          title: 'Funciones',
          onClick: (e) => {
            onClick(paths.CONFIGURE.FUNCTION.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.FUNCTION.BASE);
          },
          permiso: [permisos.VER_FUNCION],
        },
        {
          key: paths.CONFIGURE.CONCEPT.BASE,
          label: 'Concepto',
          title: 'Concepto',
          onClick: (e) => {
            onClick(paths.CONFIGURE.CONCEPT.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.CONCEPT.BASE);
          },
          permiso: [permisos.VER_CONCEPTO],
        },
        {
          key: paths.CONFIGURE.POSITION.MASTER,
          label: 'Posicion master',
          title: 'Posicion master',
          onClick: (e) => {
            onClick(paths.CONFIGURE.POSITION.MASTER);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.POSITION.MASTER);
          },
          permiso: [],
        },
        {
          key: paths.CONFIGURE.POSITION.BASE,
          label: 'Posicion',
          title: 'Posicion',
          onClick: (e) => {
            onClick(paths.CONFIGURE.POSITION.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.POSITION.BASE);
          },
          permiso: [permisos.VER_POSICION],
        },
        {
          key: paths.CONFIGURE.STRUCTURE.BASE,
          label: 'Estructura',
          title: 'Estructura',
          onClick: (e) => {
            onClick(paths.CONFIGURE.STRUCTURE.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.STRUCTURE.BASE);
          },
          permiso: [permisos.VER_ESTRUCTURA],
        },
        {
          key: paths.CONFIGURE.EXCHANGE_RATE,
          label: 'Tipo de cambio',
          title: 'Tipo de cambio',
          onClick: (e) => {
            onClick(paths.CONFIGURE.EXCHANGE_RATE);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.EXCHANGE_RATE);
          },
          permiso: [permisos.VER_TIPOCAMBIO],
        },
        {
          key: paths.CONFIGURE.DERIVATION.BASE,
          label: 'Derivada',
          title: 'Derivada',
          onClick: (e) => {
            onClick(paths.CONFIGURE.DERIVATION.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.CONFIGURE.DERIVATION.BASE);
          },
          permiso: [permisos.VER_DERIVACION],
        },
      ],
    },
    {
      key: paths.BUSINESS_PARTNER.BASE,
      label: 'Socios de Negocio',
      icon: <FaBriefcase />,
      permiso: [permisos.VER_SOCIOS, permisos.VER_DOCUMENTOS],
      children: [
        {
          key: paths.BUSINESS_PARTNER.PARTNERS,
          label: 'Socios',
          title: 'Socios',
          onClick: (e) => {
            onClick(paths.BUSINESS_PARTNER.PARTNERS);
          },
          onContextMenu: (e) => {
            onContext(paths.BUSINESS_PARTNER.PARTNERS);
          },
          permiso: [permisos.VER_SOCIOS],
        },
        {
          key: paths.BUSINESS_PARTNER.DOCUMENTS,
          label: 'Documentos',
          title: 'Documentos',
          onClick: (e) => {
            onClick(paths.BUSINESS_PARTNER.DOCUMENTS);
          },
          onContextMenu: (e) => {
            onContext(paths.BUSINESS_PARTNER.DOCUMENTS);
          },
          permiso: [permisos.VER_DOCUMENTOS],
        },
      ],
    },
    {
      key: paths.SOLUTION.BASE,
      label: 'Soluciones',
      icon: <FaIndustry />,
      permiso: [permisos.VER_SOLUCIONES],
      children: [
        {
          key: paths.SOLUTION.CREDIT_LINE.BASE,
          label: 'Líneas de Crédito',
          title: 'Líneas de Crédito',
          onClick: (e) => {
            onClick(paths.SOLUTION.CREDIT_LINE.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.SOLUTION.CREDIT_LINE.BASE);
          },
          permiso: [],
        },
        {
          key: paths.SOLUTION.LOAN.BASE,
          label: 'Préstamos',
          title: 'Préstamos',
          onClick: (e) => {
            onClick(paths.SOLUTION.LOAN.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.SOLUTION.LOAN.BASE);
          },
          permiso: [],
        },
        {
          key: paths.SOLUTION.DELAY.BASE,
          label: 'Aplazamiento',
          title: 'Aplazamiento',
          onClick: (e) => {
            onClick(paths.SOLUTION.DELAY.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.SOLUTION.DELAY.BASE);
          },
          permiso: [],
        },
        {
          key: paths.SOLUTION.ADVANCE.BASE,
          label: 'Adelantos',
          title: 'Adelantos',
          onClick: (e) => {
            onClick(paths.SOLUTION.ADVANCE.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.SOLUTION.ADVANCE.BASE);
          },
          permiso: [],
        },
        {
          key: paths.SOLUTION.FACTORING.BASE,
          label: 'Factoring',
          title: 'Factoring',
          onClick: (e) => {
            onClick(paths.SOLUTION.FACTORING.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.SOLUTION.FACTORING.BASE);
          },
          permiso: [],
        },
        {
          key: paths.SOLUTION.DEPOSIT.BASE,
          label: 'Deposito',
          title: 'Deposito',
          onClick: (e) => {
            onClick(paths.SOLUTION.DEPOSIT.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.SOLUTION.DEPOSIT.BASE);
          },
        },
        {
          key: paths.SOLUTION.EXCHANGE_RATE.BASE,
          label: 'Tipo de cambio',
          title: 'Tipo de cambio',
          onClick: (e) => {
            onClick(paths.SOLUTION.EXCHANGE_RATE.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.SOLUTION.EXCHANGE_RATE.BASE);
          },
        },
      ],
    },
    {
      key: paths.CASH_FLOW.BASE,
      label: 'Flujo de Caja',
      icon: <FaRocket />,
      permiso: [
        permisos.VER_FLUJO_CAJA,
        permisos.VER_REGISTRO_FUENTE,
        permisos.VER_REGISTRO_PROPIO,
        permisos.VER_GESTION,
      ],
      children: [
        {
          key: paths.CASH_FLOW.SOURCE_RECORD.BASE,
          label: 'Registro Fuente',
          title: 'Registro Fuente',
          onClick: (e) => {
            onClick(paths.CASH_FLOW.SOURCE_RECORD.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.CASH_FLOW.SOURCE_RECORD.BASE);
          },
          permiso: [permisos.VER_REGISTRO_FUENTE],
        },
        {
          key: buildPath(paths.CASH_FLOW.OWN_RECORD.LIST, { tab: 'Listado_Propio' }),
          label: 'Registro Propio',
          title: 'Registro Propio',
          onClick: (e) => {
            onClick(buildPath(paths.CASH_FLOW.OWN_RECORD.LIST, { tab: 'Listado_Propio' }));
          },
          onContextMenu: (e) => {
            onContext(buildPath(paths.CASH_FLOW.OWN_RECORD.LIST, { tab: 'Listado_Propio' }));
          },
          permiso: [permisos.VER_REGISTRO_PROPIO],
        },
        {
          key: paths.CASH_FLOW.REPORT.BASE,
          label: 'Reporte',
          title: 'Reporte',
          onClick: (e) => {
            onClick(paths.CASH_FLOW.REPORT.BASE);
          },
          onContextMenu: (e) => {
            onContext(paths.CASH_FLOW.REPORT.BASE);
          },
          permiso: [permisos.VER_FLUJO_CAJA],
        },
        {
          key: paths.MANAGEMENT,
          label: 'Gestión',
          title: 'Gestión',
          onClick: (e) => {
            onClick(paths.MANAGEMENT);
          },
          onContextMenu: (e) => {
            onContext(paths.MANAGEMENT);
          },
          permiso: [permisos.VER_GESTION],
        },
      ],
    },
    {
      key: paths.NOTIFICATION,
      label: 'Notificaciones',
      title: 'Notificaciones',
      icon: <FaBell />,
      onClick: (e) => {
        onClick(paths.NOTIFICATION);
      },
      onContextMenu: (e) => {
        onContext(paths.NOTIFICATION);
      },
      permiso: [permisos.VER_NOTIFICACIONES],
    },
  ];
};
