import React, { useState, useEffect } from 'react';
import {
  SearchInputText,
  LoadingSpinner,
  ModalConfirmation,
  TableGrid,
  ColumnSelector,
  SelectForm,
  LabelForm,
  ButtonAccion,
} from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  listarRegistrosF,
  eliminarRegistroM,
  obtenerRegistrosManualesUno,
  obtenerCaracPosTotal,
  cargaMasivaPythonF,
  urlPreFirmada,
  cargaMasivaPythonExcel,
} from '../../../../services';
import { createCaracteristicaTabla, createRegistroManualAdapter } from '../../../../adapters';
import Swal from 'sweetalert2';
import {
  exportToExcelRegManual,
  exportToExcelRegistroManual,
  Selected,
  useFetchAndLoad,
  listaUniverso,
  formatString_MMDDYYYY_TO_DDMMYYYY,
  excelDateToJSDate,
  paths,
  buildPath,
} from '../../../../common';
import {
  clearListaRF,
  setFiltroListaRF,
  setListaIDRF,
  setListaRF,
  setListarIDRF,
  setOpenModalsRF,
  updateTitle,
} from '../../../../redux/states';
import { read, utils } from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { ColFlex, ContentButton, ContentOption, RowAccion, RowFlex } from './../../../../styles';
import { Dropdown } from 'antd';
import { ModalImport } from './components';
import { AccionColumna, columns, items } from './misc';
import { forEach } from 'lodash';

export const ListarRegistroFuente = (props) => {
  const registroFState = useSelector((store) => store.registroFuente);
  const authState = useSelector((store) => store.auth);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [selectedRegistroF, setSelectedRegistroF] = useState([]);
  const [universo, setUniverso] = useState([listaUniverso[0]]);
  const [columnSelector, setColumnSelector] = useState([]);
  const [selectColumn, setSelectColumn] = useState([]);

  const [searchBool, setSearchBool] = useState(false);
  const [listaIDRF, setListaIDRF] = useState([]);
  const titleExport = 'PlantillaRegManual';
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Listar registro fuentes' }));
    (async () => {
      await loadCaracteristicaColumna();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await loadListaRF(0);
    })();
  }, [
    registroFState.searchText,
    registroFState.searchArray,
    registroFState.page,
    registroFState.pageSize,
    universo,
  ]);

  const loadListaRF = async (importado) => {
    let inicioLista = registroFState.page * registroFState.pageSize;
    let finLista = (registroFState.page + 1) * registroFState.pageSize;
    let listID = importado === 1 ? [] : !searchBool ? listaIDRF.slice(inicioLista, finLista) : [];
    const responseListaRF = await callEndpoint(
      listarRegistrosF({
        IDUsuario: authState.user.uid,
        IDUniverso: universo[0].value,
        Inicio: inicioLista,
        Fin: finLista,
        Filtro: registroFState.searchText,
        Buscador: registroFState.searchArray,
        Etiqueta: [],
        IDs: listID,
      })
    );

    var listaRF = [],
      listaID = [],
      total = 0;

    if (responseListaRF.data) {
      for (
        let index = 0;
        index < responseListaRF.data.respuesta.aListaRegistrosAgrupado.length;
        index++
      ) {
        const element = responseListaRF.data.respuesta.aListaRegistrosAgrupado[index];
        let obj = createRegistroManualAdapter(element);
        obj.Items.forEach((element2) => {
          obj[element2.Caracteristica] = element2.Valor;
        });
        var aEncontradoLlave = obj.Items.filter(function (item) {
          return item.Llave === 'X' && item.Valor !== '';
        });
        if (aEncontradoLlave.length > 0) {
          obj['Nombre'] = aEncontradoLlave[0].Valor;
        }
        listaRF.push(obj);
      }
      total = responseListaRF.data.respuesta.totalReg;
      listaID = responseListaRF.data.respuesta.totalIDs;
    }

    dispatch(setListaRF({ lista: listaRF }));
    if (listID.length === 0) {
      dispatch(setListarIDRF({ rowCount: total, listaIDRF: listaID }));
      setListaIDRF(listaID);
    }
  };

  const loadCaracteristicaColumna = async () => {
    const caracteristica = [];
    var respuestaCaracteristica = await obtenerCaracPosTotal().call;

    respuestaCaracteristica.data.respuesta.aCaracPosSinRep.map((r) => {
      if (r != 'MonedaImporte' && r != 'Importe' && r != 'FechaVcto') {
        caracteristica.push(createCaracteristicaTabla(r));
      }
    });
    setColumnSelector([...columns, ...caracteristica]);
  };

  const onCloseModalEliminarRF = () => {
    let oParam = {};
    oParam.openModalEliminarRF = false;
    dispatch(setOpenModalsRF(oParam));
  };

  const onConfirmarEliminarRF = async () => {
    var alistaRFEliminar = [];
    selectedRegistroF.forEach((element) => {
      var o = {};
      o.IDRegistro = element;
      alistaRFEliminar.push(o);
    });
    const responseEliminarRegistroM = await callEndpoint(
      eliminarRegistroM({ RegistroManualElim: alistaRFEliminar })
    );
    onCloseModalEliminarRF();
    if (responseEliminarRegistroM === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseEliminarRegistroM.data.cabecera.statusCode === 200) {
        loadListaRF(0);
        setSelectedRegistroF([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseEliminarRegistroM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseEliminarRegistroM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onOpenModalImportRF = () => {
    let oParam = {};
    oParam.openModalImportRF = true;
    dispatch(setOpenModalsRF(oParam));
  };

  const onCloseModalImportRF = (e) => {
    let oParam = {};
    oParam.openModalImportRF = false;
    dispatch(setOpenModalsRF(oParam));
  };

  const onConfirmarImportRF = async (file) => {
    const files = file;
    if (files.length > 0) {
      const file = files[0];
      const url = await urlPreFirmada(file.name, file.type);
      const uploadResponse = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
      var responseEjecutarCargaMasiva = await callEndpoint(
        cargaMasivaPythonExcel({
          excelFile: `carga-masiva/${file.name}`,
          cargatipo: 'F',
        })
      );

      if (responseEjecutarCargaMasiva === null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (responseEjecutarCargaMasiva.data) {
          if (responseEjecutarCargaMasiva.data.cabecera.statusCode === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: responseEjecutarCargaMasiva.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            onCloseModalImportRF();
            setTimeout(() => {
              loadListaRF(1);
            }, 2000);
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseEjecutarCargaMasiva.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    }
  };

  const onEditRF = async (row) => {
    var oParam = {
      RegistroManualConsulta: {
        IDRegistro: row.IDRegistro,
      },
    };

    const responseObtRgManualUno = await callEndpoint(obtenerRegistrosManualesUno(oParam));

    if (responseObtRgManualUno === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseObtRgManualUno.data) {
        if (responseObtRgManualUno.data.cabecera.statusCode === 200) {
          var oInfoRegManualUno = responseObtRgManualUno.data.respuesta.aListaRegistrosAgrupado;
          if (oInfoRegManualUno.length > 0) {
            navigate(buildPath(paths.CASH_FLOW.SOURCE_RECORD.EDIT, { id: row.IDRegistro }));
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'No se encontró información.',
              showConfirmButton: false,
              timer: 4000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseObtRgManualUno.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onChangeUniverso = (event) => {
    setUniverso([event]);
  };

  const onChangePagination = async (e, dato) => {
    let oParam = {};
    setSearchBool(false);
    oParam.searchBool = false;
    switch (dato) {
      case 'page':
        oParam.page = e;
        break;
      case 'pageSize':
        oParam.pageSize = e;
        break;
      default:
        break;
    }
    await dispatch(setFiltroListaRF(oParam));
  };

  const onChangeFiltros = async (e, dato) => {
    let oParam = {};
    oParam.page = 0;
    setSearchBool(true);

    oParam.searchBool = true;
    switch (dato) {
      case 'array':
        oParam.searchArray = e.items;
        break;
      case 'texto':
        oParam.searchText = e.target.value;
        break;
      default:
        break;
    }
    await dispatch(setFiltroListaRF(oParam));
  };

  return (
    <ColFlex>
      <ContentOption>
        <RowFlex>
          <SearchInputText
            width="250px"
            placeholder={'Buscar Registros Fuentes...'}
            onChange={(e) => onChangeFiltros(e, 'texto')}
            filter={registroFState.searchText}
            style={{ marginRight: '9px' }}
          />
          <LabelForm width={'80px'} title={'Universo : '}></LabelForm>
          <SelectForm
            width={'150px'}
            bValid={true}
            disableClearable={true}
            value={universo}
            handleChange={(evnt, v) => onChangeUniverso(v)}
            options={listaUniverso}
            placeholder="Seleccione Universo"
          />
          <ContentButton>
            <RowAccion>
              <ColumnSelector
                allColumns={
                  columnSelector.filter((r) => r.parsed === 'Acciones').length > 0
                    ? columnSelector
                    : [...columnSelector, ...AccionColumna(onEditRF)]
                }
                setColumn={setSelectColumn}
                setAllColumn={setColumnSelector}
              />
              <Dropdown
                trigger={['click']}
                menu={{
                  items: items(
                    exportToExcelRegistroManual,
                    registroFState.listaRF,
                    onOpenModalImportRF
                  ),
                }}
              >
                <ButtonAccion />
              </Dropdown>
            </RowAccion>
          </ContentButton>
        </RowFlex>
      </ContentOption>
      <TableGrid
        columns={
          selectColumn.length > 0
            ? [...selectColumn.filter((r) => r.seleccionado === Selected.TRUE)]
            : [
                ...columns.filter((r) => r.seleccionado === Selected.TRUE),
                ...AccionColumna(onEditRF),
              ]
        }
        rows={registroFState.listaRF}
        getRowId={(row) => row.IDRegistro}
        checkboxSelection={true}
        onSelectionModelChange={(oEvent) => {
          setSelectedRegistroF(oEvent);
        }}
        selectionModel={selectedRegistroF}
        // paginacion
        onPageSizeChange={(e) => onChangePagination(e, 'pageSize')}
        pageSize={registroFState.pageSize}
        rowCount={registroFState.rowCount}
        pagination
        rowsPerPageOptions={[15, 25, 50, 100]}
        paginationMode="Server"
        onPageChange={(e) => onChangePagination(e, 'page')}
        page={registroFState.page}
        loading={loading}
        // filtro
        filterMode="server"
        onFilterModelChange={(e) => onChangeFiltros(e, 'array')}
      />

      <ModalConfirmation
        showModal={registroFState.openModalEliminarRF}
        closeModal={(e) => onCloseModalEliminarRF()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminarRF(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      <ModalImport
        isOpen={registroFState.openModalImportRF}
        loading={false}
        descargarPlantilla={(e) => exportToExcelRegManual(titleExport)}
        handleClose={onCloseModalImportRF}
        handleOk={onConfirmarImportRF}
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
