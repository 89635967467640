import styled from 'styled-components';
import { theme } from '../common';

export const BackgroundLogin = styled.div`
  background: linear-gradient(
    150deg,
    ${theme.colors.secondary} 21%,
    ${theme.colors.tertiary} 50%,
    ${theme.colors.primary} 86%
  );
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  padding: 32px;
  width: 100%;
`;
export const ContainLogin = styled.div`
  @media (max-width: 1400px) {
    width: 35vw;
  }
  @media (max-width: 1300px) {
    width: 40vw;
  }
  @media (max-width: 1200px) {
    width: 45vw;
  }
  @media (max-width: 100px) {
    width: 50vw;
  }
  @media (max-width: 850px) {
    width: 55vw;
  }
  @media (max-width: 750px) {
    width: 60vw;
  }
  @media (max-width: 650px) {
    width: 80vw;
  }
  width: 25vw;
`;

export const ModalLogin = styled.div`
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 15px;
  background-color: ${theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  /* @media (max-height: 800px) {
    height: 80vh;
  }
  @media (max-height: 700px) {
    height: 95vh;
  }
  @media (max-width: 768px) {
    } */
  height: auto;
  max-height: 90vh;
`;

export const FormLogin = styled.form`
  width: 100%;
`;
export const LabelLogin = styled.label`
  font-family: ${theme.font.family};
  line-height: 1.5;
  font-weight: 500;
  font-size: 18px;
  display: block;
`;
export const InputLogin = styled.input`
  border: 0.5px solid ${theme.colors.line};
  background-color: ${theme.colors.white};
  outline: none !important;
  padding: 16.5px 14px;
  border-radius: 2px;
  width: 100%;
  &:focus {
    border: 0.5px solid ${theme.colors.line};
  }
`;
export const ContainerInputLogin = styled.div`
  position: relative;
  input[type='password'],
  input[type='text'] {
    width: 100%;
    padding: 12px 36px 12px 12px;
    box-sizing: border-box;
  }
`;

export const ImgLogin = styled.img`
  width: 100%;
`;
export const ButtonLogin = styled.button`
  background-color: ${theme.colors.tertiary};
  font-family: ${theme.font.family};
  color: ${theme.colors.white};
  padding: 10px 22px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 20px;
  min-width: 64px;
  border: 0px;
  width: 100%;
  &:hover {
    background-color: ${theme.colors.primary};
  }
`;

export const ButtonReSend = styled.button`
  background-color: ${theme.colors.tertiary};
  font-family: ${theme.font.family};
  color: ${theme.colors.white};
  padding: 10px 22px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  min-width: 64px;
  border: 0px;
  width: 100%;
  &:hover {
    background-color: ${theme.colors.primary};
  }
`;
