import React from 'react';
import { theme } from '../../common';
import { Select } from 'antd';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa6';

export const SelectFormNew = (props) => {
  return (
    <SelectAntForm
      mode={props.isMultiple ? 'multiple' : ''}
      placeholder={props.placeholder}
      defaultValue={props.value}
      onChange={props.onChange}
      options={props.options}
      validate={props.validate}
      menuItemSelectedIcon={<FaCheck />}
      multiple={props.isMultiple}
      {...props}
    />
  );
};

const SelectAntForm = styled(Select)`
  align-content: center;
  min-height: 32px !important;
  font-size: ${theme.font.size};
  font-family: ${theme.font.family};
  /* width: ${({ width }) => (width ? width : '98%')}; */
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ multiple }) => (multiple ? 'auto' : '32px')};

  &:hover,
  &:hover .ant-select-selector,
  &.ant-select-open .ant-select-selector {
    border-color: ${({ validate }) =>
      !validate
        ? `${theme.colors.line} !important`
        : validate === true
        ? `${theme.colors.line} !important`
        : `${theme.colors.warning} !important`};
  }

  .ant-select-selector {
    border-color: ${({ validate }) =>
      !validate
        ? `${theme.colors.line} !important`
        : validate === true
        ? `${theme.colors.line} !important`
        : `${theme.colors.warning} !important`};
    border-radius: 2px;
    box-shadow: none !important;
  }

  .ant-select-selection-overflow-item {
    span.ant-select-selection-item {
      border: 1px solid ${theme.colors.line};
      background-color: ${theme.colors.white};
      border-radius: 2px;
    }
  }
`;
