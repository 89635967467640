import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonForm,
  Input,
  InputNumberFormat,
  LabelForm,
  ModalConfirmation,
  SelectForm,
  SubTitle,
} from '../../../../components';
import { useFetchAndLoad, useFormLineaCredito } from '../../../../common/hooks';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  changeAparicionesLC,
  changeDescripcionLC,
  changeFrecMaxLC,
  changeMaximoLC,
  changeMinimoLC,
  changeMontoCuotaLC,
  changeMontoDesembolsoLC,
  changeMontoLC,
  changePeriodoCutoaLC,
  changePeriodoGraciaLC,
  changeTeaLC,
  changeVigenciaLC,
  limpiarCamposLC,
  limpiarFormLC,
  selectedAgenteLC,
  selectedEstadoLC,
  selectedMonedaLC,
  selectedTiempoFrecMaxLC,
  selectedTiempoPeriodoCuotaLC,
  selectedTiempoPeriodoGraciaLC,
  selectedTipoFomularioLC,
  setListaAgente,
  setListaEstadoLC,
  setListaLineaCredito,
  setListaMonedaLC,
  setListaTiempoLC,
  setValidCampos,
  updateTitle,
} from '../../../../redux/states';
import {
  obtenerAgentes,
  obtenerListadoMoneda,
  obtenerListarLineaCredito,
  registrarSolucion,
} from '../../../../services';
import {
  createAgenteAdapter,
  createAgenteSelectAdapter,
  createAgenteSelectLCAdapter,
  createEstadoSolucionSelectAdapter,
  createMonedaAbreviaturaAdapter,
  createTiempoPeriodoAdapter,
} from '../../../../adapters';
import { EstadoSolucionesData, paths } from '../../../../common';
import { dateTo_dd_mm_yyyy } from '../../../../common';
import Swal from 'sweetalert2';
import { createLineaCreditoAdapter } from '../../../../adapters/createLineaCredito.adapter';
import { TiempoPeriodosData } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import { TipoFormularioLineaCredito } from '../../../../common';
import {
  ColFlex,
  ColForm,
  Column,
  Content,
  ContentForm,
  DatePickerForm,
  RowDoubleInput,
  RowFooterButton,
  RowForm,
} from '../../../../styles';
import { Select } from 'antd';
export const CrearLineaCredito = (props) => {
  const personalizacionState = useSelector((store) => store.personalizacion);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const lineaCreditoState = useSelector((store) => store.lineaCredito);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const handleChangeDescripcion = (oEvent) => {
    var descripcion = oEvent.target.value;
    var validate = null;
    if (descripcion.length > 0) {
      validate = true;
    }
    var oParam = {
      descripcion: descripcion,
      bValidDescripcion: validate,
    };
    dispatch(changeDescripcionLC(oParam));
  };
  const handleChangeAgente = (oEvent) => {
    var oParam = {};
    oParam.bValidAgente = null;
    oParam.selectAgente = [];
    if (oEvent) {
      let obj = {
        ...oEvent,
        ...{ value: oEvent.value, label: oEvent.label },
      };
      oParam.selectAgente.push(obj);
      oParam.bValidAgente = true;
    }
    dispatch(selectedAgenteLC(oParam));
  };
  const handleChangeMonto = (values, sourceInfo) => {
    // var monto = oEvent.target.value;
    var monto = values.value;
    var validate = null;
    if (monto.length > 0) {
      validate = true;
    }
    var oParam = {
      monto: monto,
      bValidMonto: validate,
    };
    dispatch(changeMontoLC(oParam));
  };
  const handleChangeMontoCuota = (values, sourceInfo) => {
    // var montoCuota = oEvent.target.value;
    var montoCuota = values.value;
    // var tea = lineaCreditoState.tea;
    var validate = null;
    if (montoCuota.length > 0) {
      validate = true;
      // tea  = ""
    }
    var oParam = {
      montoCuota: montoCuota,
      bValidMontoCuota: validate,
      // tea : tea
    };
    dispatch(changeMontoCuotaLC(oParam));
  };
  const handleChangeMontoDesembolso = (values, sourceInfo) => {
    // var montoDes = oEvent.target.value;
    var montoDes = values.value;
    var validate = null;
    if (montoDes.length > 0) {
      validate = true;
    }
    var oParam = {
      montoDes: montoDes,
      bValidMontoDes: validate,
    };
    dispatch(changeMontoDesembolsoLC(oParam));
  };
  const handleChangeMoneda = (oEvent) => {
    var oParam = {};
    oParam.bValidMoneda = null;
    oParam.selectMoneda = [];
    if (oEvent) {
      let obj = {
        ...oEvent,
        ...{ value: oEvent.value, label: oEvent.label },
      };
      oParam.selectMoneda.push(obj);
      oParam.bValidMoneda = true;
    }
    dispatch(selectedMonedaLC(oParam));
  };
  const handleChangeTiempoPeGracia = (oEvent) => {
    var oParam = {};
    oParam.bValidTiempoPeGracia = null;
    oParam.selectTiempoPeGracia = [];
    if (oEvent) {
      let obj = {
        ...oEvent,
        ...{ value: oEvent.value, label: oEvent.label },
      };
      oParam.selectTiempoPeGracia.push(obj);
      oParam.bValidTiempoPeGracia = true;
    }
    dispatch(selectedTiempoPeriodoGraciaLC(oParam));
  };
  const handleChangeTiempoPeCuotas = (oEvent) => {
    var oParam = {};
    oParam.bValidTiempoPeCuotas = null;
    oParam.selectTiempoPeCuotas = [];
    if (oEvent) {
      let obj = {
        ...oEvent,
        ...{ value: oEvent.value, label: oEvent.label },
      };
      oParam.selectTiempoPeCuotas.push(obj);
      oParam.bValidTiempoPeCuotas = true;
    }
    dispatch(selectedTiempoPeriodoCuotaLC(oParam));
  };
  const handleChangeTiempoFrecMax = (oEvent) => {
    var oParam = {};
    oParam.bValidTiempoFrecMax = null;
    oParam.selectTiempoFrecMax = [];
    if (oEvent) {
      let obj = {
        ...oEvent,
        ...{ value: oEvent.value, label: oEvent.label },
      };
      oParam.selectTiempoFrecMax.push(obj);
      oParam.bValidTiempoFrecMax = true;
    }
    dispatch(selectedTiempoFrecMaxLC(oParam));
  };
  const handleChangePeridoGracia = (values, sourceInfo) => {
    //var peGracia = oEvent.target.value;
    var peGracia = values.value;
    var validate = null;
    if (peGracia.length > 0) {
      validate = true;
    }
    var oParam = {
      peGracia: peGracia,
      bValidPeGracia: validate,
    };
    dispatch(changePeriodoGraciaLC(oParam));
  };
  const handleChangePeridoEntreCuotas = (values, sourceInfo) => {
    //var peCuotas = oEvent.target.value;
    var peCuotas = values.value;
    var validate = null;
    if (peCuotas.length > 0) {
      validate = true;
    }
    var oParam = {
      peCuotas: peCuotas,
      bValidCuotas: validate,
    };
    dispatch(changePeriodoCutoaLC(oParam));
  };
  const handleChangeTea = (values, sourceInfo) => {
    //var tea = oEvent.target.value;
    var tea = values.value;
    var validate = null;
    if (tea.length > 0) {
      validate = true;
    }
    var oParam = {
      tea: tea,
      bValidTea: validate,
    };
    dispatch(changeTeaLC(oParam));
  };
  const handleChangeFrecMax = (values, sourceInfo) => {
    //var frecMax = oEvent.target.value;
    var frecMax = values.value;
    var validate = null;
    if (frecMax.length > 0) {
      validate = true;
    }
    var oParam = {
      frecMax: frecMax,
      bValidFrecMax: validate,
    };
    dispatch(changeFrecMaxLC(oParam));
  };
  const handleChangeApariciones = (values, sourceInfo) => {
    //var apariciones = oEvent.target.value;
    var apariciones = values.value;
    var validate = null;
    if (apariciones.length > 0) {
      validate = true;
    }
    var oParam = {
      apariciones: apariciones,
      bValidApariciones: validate,
    };
    dispatch(changeAparicionesLC(oParam));
  };
  const handleChangeMinimo = (values, sourceInfo) => {
    // var minimo = oEvent.target.value;
    var minimo = values.value;
    var validate = null;
    if (minimo.length > 0) {
      validate = true;
    }
    var oParam = {
      minimo: minimo,
      bValidMinimo: validate,
    };
    dispatch(changeMinimoLC(oParam));
  };
  const handleChangeTipoFormulario = (oEvent) => {
    var oParam = {};
    oParam.bValidselectTipoFormulario = null;
    oParam.selectTipoFormulario = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTipoFormulario.push(obj);
      oParam.bValidselectTipoFormulario = true;
      if (lineaCreditoState.selectTipoFormulario.length > 0) {
        var initialTypeForm = lineaCreditoState.selectTipoFormulario[0].value;
        var finalTypeForm = oParam.selectTipoFormulario[0].value;
        oParam.montoCuota = '';
        oParam.tea = '';
        oParam.bValidMontoCuota = null;
        // if(initialTypeForm === "Tipo_1" && finalTypeForm === "Tipo_2"){
        //   oParam.tea = "";
        //   oParam.montoCuota = "";
        //   oParam.bValidTea  = null;
        // }else if(initialTypeForm === "Tipo_2" && finalTypeForm === "Tipo_1"){
        //   oParam.montoCuota = "";
        //   oParam.tea = "";
        //   oParam.bValidMontoCuota = null;
        // }
      }
    } else {
      dispatch(limpiarFormLC());
    }

    dispatch(selectedTipoFomularioLC(oParam));
  };
  const limpiarForm = () => {
    dispatch(limpiarFormLC());
  };
  const handleChangeEstado = (oEvent) => {
    var oParam = {};
    oParam.bValidEstado = null;
    oParam.selectEstado = [];
    if (oEvent) {
      let obj = {
        ...oEvent,
        ...{ value: oEvent.value, label: oEvent.label },
      };
      oParam.selectEstado.push(obj);
      oParam.bValidEstado = true;
    }
    dispatch(selectedEstadoLC(oParam));
  };
  const camposObligatorios = () => {
    var oParan = {
      valid: false,
      oRegistrarSolucion: {},
      mensaje: '',
    };
    var oParamValidCampos = {};
    if (lineaCreditoState.selectTipoFormulario.length > 0) {
      var selectTipoFormulario = lineaCreditoState.selectTipoFormulario[0];
      let tipoFormulario = selectTipoFormulario.value;
      if (tipoFormulario === 'Tipo_1') {
        if (lineaCreditoState.descripcion.length === 0) {
          oParamValidCampos.bValidDescripcion = false;
        }
        if (lineaCreditoState.selectAgente.length === 0) {
          oParamValidCampos.bValidAgente = false;
        }
        if (lineaCreditoState.dDateRange[0] === null || lineaCreditoState.dDateRange[1] === null) {
          oParamValidCampos.bValidFecha = false;
        }
        // if (lineaCreditoState.peGracia.length === 0) {
        // oParamValidCampos.bValidPeGracia = false;
        // }
        // if (lineaCreditoState.selectTiempoPeGracia.length === 0) {
        //   oParamValidCampos.bValidTiempoPeGracia = false;
        // }
        if (lineaCreditoState.monto.length === 0) {
          oParamValidCampos.bValidMonto = false;
        }
        if (lineaCreditoState.frecMax.length === 0) {
          oParamValidCampos.bValidFrecMax = false;
        }
        if (lineaCreditoState.selectTiempoFrecMax.length === 0) {
          oParamValidCampos.bValidTiempoFrecMax = false;
        }
        if (lineaCreditoState.minimo.length === 0) {
          oParamValidCampos.bValidMinimo = false;
        }
        if (lineaCreditoState.apariciones.length === 0) {
          oParamValidCampos.bValidApariciones = false;
        }
        if (lineaCreditoState.selectMoneda.length === 0) {
          oParamValidCampos.bValidMoneda = false;
        }
        if (lineaCreditoState.selectEstado.length === 0) {
          oParamValidCampos.bValidEstado = false;
        }
        if (lineaCreditoState.peCuotas.length === 0) {
          oParamValidCampos.bValidCuotas = false;
        }
        if (lineaCreditoState.selectTiempoPeCuotas.length === 0) {
          oParamValidCampos.bValidTiempoPeCuotas = false;
        }
        if (lineaCreditoState.tea.length === 0) {
          oParamValidCampos.bValidTea = false;
        }

        //Campos obligatorios
        if (
          lineaCreditoState.descripcion.length != 0 &&
          lineaCreditoState.selectAgente.length != 0 &&
          lineaCreditoState.dDateRange[0] != null &&
          lineaCreditoState.dDateRange[1] != null &&
          // lineaCreditoState.peGracia.length != 0 &&
          // lineaCreditoState.selectTiempoPeGracia.length != 0 &&
          lineaCreditoState.monto.length != 0 &&
          lineaCreditoState.frecMax.length != 0 &&
          lineaCreditoState.selectTiempoFrecMax.length != 0 &&
          lineaCreditoState.minimo.length != 0 &&
          lineaCreditoState.apariciones.length != 0 &&
          lineaCreditoState.selectMoneda.length != 0 &&
          lineaCreditoState.selectEstado.length != 0 &&
          lineaCreditoState.peCuotas.length != 0 &&
          lineaCreditoState.selectTiempoPeCuotas.length != 0 &&
          lineaCreditoState.tea.length != 0
        ) {
          if (parseFloat(lineaCreditoState.tea) === 0) {
            oParan.mensaje = 'La TEA(%) debe ser mayo a 0';
            return oParan;
          } else if (parseFloat(lineaCreditoState.minimo) >= parseFloat(lineaCreditoState.monto)) {
            oParan.mensaje = 'El mínimo debe ser menor al monto.';
            return oParan;
          } else if (
            parseFloat(lineaCreditoState.montoDes.length === 0 ? 0 : lineaCreditoState.montoDes) >=
            parseFloat(lineaCreditoState.monto)
            // (parseFloat(lineaCreditoState.monto)>parseFloat(lineaCreditoState.montoDes.length===0?0:lineaCreditoState.montoDes))
          ) {
            oParan.mensaje = 'El costo fijo de desembolso debe ser menor al monto.';
            return oParan;
          } else {
            oParan.valid = true;
            oParan.oRegistrarSolucion['COD_TIPO_FORMULARIO'] = selectTipoFormulario.value;
            oParan.oRegistrarSolucion['TIPO_FORMULARIO'] = selectTipoFormulario.label;
            oParan.oRegistrarSolucion['COD_SOLUCION'] = 'LC';
            oParan.oRegistrarSolucion['SOL_NOMBRESOLUCION'] = lineaCreditoState.descripcion;
            oParan.oRegistrarSolucion['SOL_IDAGENTE'] = parseFloat(
              lineaCreditoState.selectAgente[0].value
            );
            oParan.oRegistrarSolucion['SOL_AGENTE'] = lineaCreditoState.selectAgente[0].label;
            oParan.oRegistrarSolucion['SOL_PERIODOINICIOP'] = dateTo_dd_mm_yyyy(
              lineaCreditoState.dDateRange[0],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_PERIODOFINP'] = dateTo_dd_mm_yyyy(
              lineaCreditoState.dDateRange[1],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_DESFASECUOTAP'] = 0; // parseFloat(lineaCreditoState.peGracia);
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIAL'] = parseFloat(lineaCreditoState.monto);
            oParan.oRegistrarSolucion['SOL_FRECUENCIA'] = parseFloat(lineaCreditoState.frecMax);
            oParan.oRegistrarSolucion['SOL_FRECUENCIA_PERIODO'] =
              lineaCreditoState.selectTiempoFrecMax[0].value;
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIALMIN'] = parseFloat(
              lineaCreditoState.minimo
            );
            oParan.oRegistrarSolucion['SOL_APARICIONES'] = parseFloat(
              lineaCreditoState.apariciones
            );
            oParan.oRegistrarSolucion['SOL_MONEDA'] = lineaCreditoState.selectMoneda[0].value;
            oParan.oRegistrarSolucion['IDESTADO'] = parseFloat(
              lineaCreditoState.selectEstado[0].value
            );
            oParan.oRegistrarSolucion['SOL_PERIODOSENTREPAGO'] = parseFloat(
              lineaCreditoState.peCuotas
            );
            oParan.oRegistrarSolucion['SOL_PERIODOSENTREPAGO_PERIODO'] =
              lineaCreditoState.selectTiempoPeCuotas[0].value;
            oParan.oRegistrarSolucion['SOL_TEAP'] = lineaCreditoState.tea;
          }
        } else {
          dispatch(setValidCampos(oParamValidCampos));
          oParan.mensaje = 'Debe llenar todos los campos en (*).';
          return oParan;
        }
        //campos opcionales
        if (lineaCreditoState.montoDes.length !== 0) {
          oParan.oRegistrarSolucion['SOL_PAGODESEMBOLSO'] = parseFloat(lineaCreditoState.montoDes);
        }
      } else if (tipoFormulario === 'Tipo_2') {
        if (lineaCreditoState.descripcion.length === 0) {
          oParamValidCampos.bValidDescripcion = false;
        }
        if (lineaCreditoState.selectAgente.length === 0) {
          oParamValidCampos.bValidAgente = false;
        }
        if (lineaCreditoState.dDateRange[0] === null || lineaCreditoState.dDateRange[1] === null) {
          oParamValidCampos.bValidFecha = false;
        }
        // if (lineaCreditoState.peGracia.length === 0) {
        // oParamValidCampos.bValidPeGracia = false;
        // }
        // if (lineaCreditoState.selectTiempoPeGracia.length === 0) {
        //   oParamValidCampos.bValidTiempoPeGracia = false;
        // }
        if (lineaCreditoState.monto.length === 0) {
          oParamValidCampos.bValidMonto = false;
        }
        if (lineaCreditoState.montoCuota.length === 0) {
          oParamValidCampos.bValidMontoCuota = false;
        }
        if (lineaCreditoState.frecMax.length === 0) {
          oParamValidCampos.bValidFrecMax = false;
        }
        if (lineaCreditoState.selectTiempoFrecMax.length === 0) {
          oParamValidCampos.bValidTiempoFrecMax = false;
        }
        if (lineaCreditoState.minimo.length === 0) {
          oParamValidCampos.bValidMinimo = false;
        }
        if (lineaCreditoState.apariciones.length === 0) {
          oParamValidCampos.bValidApariciones = false;
        }
        if (lineaCreditoState.selectMoneda.length === 0) {
          oParamValidCampos.bValidMoneda = false;
        }
        if (lineaCreditoState.selectEstado.length === 0) {
          oParamValidCampos.bValidEstado = false;
        }
        if (lineaCreditoState.peCuotas.length === 0) {
          oParamValidCampos.bValidCuotas = false;
        }
        if (lineaCreditoState.selectTiempoPeCuotas.length === 0) {
          oParamValidCampos.bValidTiempoPeCuotas = false;
        }

        //Campos obligatorios
        if (
          lineaCreditoState.descripcion.length != 0 &&
          lineaCreditoState.selectAgente.length != 0 &&
          lineaCreditoState.dDateRange[0] != null &&
          lineaCreditoState.dDateRange[1] != null &&
          // lineaCreditoState.peGracia.length != 0 &&
          // lineaCreditoState.selectTiempoPeGracia.length != 0 &&
          lineaCreditoState.monto.length != 0 &&
          lineaCreditoState.montoCuota.length != 0 &&
          lineaCreditoState.frecMax.length != 0 &&
          lineaCreditoState.selectTiempoFrecMax.length != 0 &&
          lineaCreditoState.minimo.length != 0 &&
          lineaCreditoState.apariciones.length != 0 &&
          lineaCreditoState.selectMoneda.length != 0 &&
          lineaCreditoState.selectEstado.length != 0 &&
          lineaCreditoState.peCuotas.length != 0 &&
          lineaCreditoState.selectTiempoPeCuotas.length != 0
        ) {
          if (parseFloat(lineaCreditoState.monto) < parseFloat(lineaCreditoState.minimo)) {
            oParan.mensaje = 'El monto debe ser mayor o igual al monto mínimo.';
            return oParan;
          } else if (parseFloat(lineaCreditoState.minimo) >= parseFloat(lineaCreditoState.monto)) {
            oParan.mensaje = 'El mínimo debe ser menor al monto.';
            return oParan;
          } else if (
            parseFloat(lineaCreditoState.montoDes.length === 0 ? 0 : lineaCreditoState.montoDes) >=
            parseFloat(lineaCreditoState.monto)
          ) {
            oParan.mensaje = 'El monto debe ser mayor al costo fijo de desembolso.';
            return oParan;
          } else {
            oParan.valid = true;
            oParan.oRegistrarSolucion['COD_TIPO_FORMULARIO'] = selectTipoFormulario.value;
            oParan.oRegistrarSolucion['TIPO_FORMULARIO'] = selectTipoFormulario.label;
            oParan.oRegistrarSolucion['COD_SOLUCION'] = 'LC';
            oParan.oRegistrarSolucion['SOL_NOMBRESOLUCION'] = lineaCreditoState.descripcion;
            oParan.oRegistrarSolucion['SOL_IDAGENTE'] = parseFloat(
              lineaCreditoState.selectAgente[0].value
            );
            oParan.oRegistrarSolucion['SOL_AGENTE'] = lineaCreditoState.selectAgente[0].label;
            oParan.oRegistrarSolucion['SOL_PERIODOINICIOP'] = dateTo_dd_mm_yyyy(
              lineaCreditoState.dDateRange[0],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_PERIODOFINP'] = dateTo_dd_mm_yyyy(
              lineaCreditoState.dDateRange[1],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_DESFASECUOTAP'] = 0; //parseFloat(lineaCreditoState.peGracia);
            // oParan.oRegistrarSolucion["SOL_DESFASECUOTAP_PERIODO"] = lineaCreditoState.selectTiempoPeGracia[0].value;
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIAL'] = parseFloat(lineaCreditoState.monto);
            oParan.oRegistrarSolucion['SOL_MONTOCUOTAP'] = parseFloat(lineaCreditoState.montoCuota);
            oParan.oRegistrarSolucion['SOL_FRECUENCIA'] = parseFloat(lineaCreditoState.frecMax);
            oParan.oRegistrarSolucion['SOL_FRECUENCIA_PERIODO'] =
              lineaCreditoState.selectTiempoFrecMax[0].value;
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIALMIN'] = parseFloat(
              lineaCreditoState.minimo
            );
            oParan.oRegistrarSolucion['SOL_APARICIONES'] = parseFloat(
              lineaCreditoState.apariciones
            );
            oParan.oRegistrarSolucion['SOL_MONEDA'] = lineaCreditoState.selectMoneda[0].value;
            oParan.oRegistrarSolucion['IDESTADO'] = parseFloat(
              lineaCreditoState.selectEstado[0].value
            );
            oParan.oRegistrarSolucion['SOL_PERIODOSENTREPAGO'] = parseFloat(
              lineaCreditoState.peCuotas
            );
            oParan.oRegistrarSolucion['SOL_PERIODOSENTREPAGO_PERIODO'] =
              lineaCreditoState.selectTiempoPeCuotas[0].value;
          }
        } else {
          dispatch(setValidCampos(oParamValidCampos));
          oParan.mensaje = 'Debe llenar todos los campos en (*).';
          return oParan;
        }
        //campos opcionales
        if (lineaCreditoState.montoDes.length !== 0) {
          oParan.oRegistrarSolucion['SOL_PAGODESEMBOLSO'] = parseFloat(lineaCreditoState.montoDes);
        }
      } else if (tipoFormulario === 'Tipo_3') {
        // oParamValidCampos.bValidAgente = true;
        //  oParamValidCampos.bValidFecha= true;
        //  oParamValidCampos.bValidMoneda= true;
        //  oParamValidCampos.bValidCuotas= true;
        // oParamValidCampos.bValidPeGracia = true;
        oParamValidCampos.bValidTiempoPeCuotas = true;
        oParamValidCampos.bValidFrecMax = true;
        oParamValidCampos.bValidTiempoFrecMax = true;
        oParamValidCampos.bValidApariciones = true;
        //  oParamValidCampos.bValidEstado= true;
        if (lineaCreditoState.descripcion.length === 0) {
          oParamValidCampos.bValidDescripcion = false;
        }
        if (lineaCreditoState.selectAgente.length === 0) {
          oParamValidCampos.bValidAgente = false;
        }
        if (lineaCreditoState.dDateRange[0] === null || lineaCreditoState.dDateRange[1] === null) {
          oParamValidCampos.bValidFecha = false;
        }
        // if (lineaCreditoState.peGracia.length === 0) {
        //   oParamValidCampos.bValidPeGracia = false;
        // }
        // if (lineaCreditoState.selectTiempoPeGracia.length === 0) {
        //   oParamValidCampos.bValidTiempoPeGracia = false;
        // }
        if (lineaCreditoState.monto.length === 0) {
          oParamValidCampos.bValidMonto = false;
        }
        // if (lineaCreditoState.frecMax.length === 0) {
        //   oParamValidCampos.bValidFrecMax = false;
        // }
        // if (lineaCreditoState.selectTiempoFrecMax.length === 0) {
        //   oParamValidCampos.bValidTiempoFrecMax = false;
        // }
        if (lineaCreditoState.minimo.length === 0) {
          oParamValidCampos.bValidMinimo = false;
        }
        // if (lineaCreditoState.apariciones.length === 0) {
        //   oParamValidCampos.bValidApariciones = false;
        // }
        if (lineaCreditoState.selectMoneda.length === 0) {
          oParamValidCampos.bValidMoneda = false;
        }
        if (lineaCreditoState.selectEstado.length === 0) {
          oParamValidCampos.bValidEstado = false;
        }
        if (lineaCreditoState.peCuotas.length === 0) {
          oParamValidCampos.bValidCuotas = false;
        }
        // if (lineaCreditoState.selectTiempoPeCuotas.length === 0) {
        //   oParamValidCampos.bValidTiempoPeCuotas = false;
        // }
        if (lineaCreditoState.tea.length === 0) {
          oParamValidCampos.bValidTea = false;
        }
        //Campos obligatorios

        if (
          lineaCreditoState.descripcion.length != 0 &&
          lineaCreditoState.selectAgente.length != 0 &&
          lineaCreditoState.dDateRange[0] != null &&
          lineaCreditoState.dDateRange[1] != null &&
          // lineaCreditoState.peGracia.length != 0 &&
          // lineaCreditoState.selectTiempoPeGracia.length != 0 &&
          lineaCreditoState.monto.length != 0 &&
          // lineaCreditoState.frecMax.length != 0 &&
          // lineaCreditoState.selectTiempoFrecMax.length != 0 &&
          // lineaCreditoState.minimo.length != 0 &&
          // lineaCreditoState.apariciones.length != 0 &&
          lineaCreditoState.selectMoneda.length != 0 &&
          lineaCreditoState.selectEstado.length != 0 &&
          lineaCreditoState.peCuotas.length != 0 &&
          lineaCreditoState.selectTiempoPeCuotas.length != 0 &&
          lineaCreditoState.tea.length != 0
        ) {
          if (parseFloat(lineaCreditoState.tea) === 0) {
            oParan.mensaje = 'La TEA(%) debe ser mayo a 0';
            return oParan;
          } else if (parseFloat(lineaCreditoState.minimo) >= parseFloat(lineaCreditoState.monto)) {
            oParan.mensaje = 'El mínimo debe ser menor al monto.';
            return oParan;
          } else if (
            parseFloat(lineaCreditoState.montoDes.length === 0 ? 0 : lineaCreditoState.montoDes) >=
            parseFloat(lineaCreditoState.monto)
            // (parseFloat(lineaCreditoState.monto)>parseFloat(lineaCreditoState.montoDes.length===0?0:lineaCreditoState.montoDes))
          ) {
            oParan.mensaje = 'El costo fijo de desembolso debe ser menor al monto.';
            return oParan;
          } else {
            oParan.valid = true;
            oParan.oRegistrarSolucion['COD_TIPO_FORMULARIO'] = selectTipoFormulario.value;
            oParan.oRegistrarSolucion['TIPO_FORMULARIO'] = selectTipoFormulario.label;
            oParan.oRegistrarSolucion['COD_SOLUCION'] = 'LC';
            oParan.oRegistrarSolucion['SOL_NOMBRESOLUCION'] = lineaCreditoState.descripcion;
            oParan.oRegistrarSolucion['SOL_IDAGENTE'] = parseFloat(
              lineaCreditoState.selectAgente[0].value
            );
            oParan.oRegistrarSolucion['SOL_AGENTE'] = lineaCreditoState.selectAgente[0].label;
            oParan.oRegistrarSolucion['SOL_PERIODOINICIOP'] = dateTo_dd_mm_yyyy(
              lineaCreditoState.dDateRange[0],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_PERIODOFINP'] = dateTo_dd_mm_yyyy(
              lineaCreditoState.dDateRange[1],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_DESFASECUOTAP'] = 0; //parseFloat(lineaCreditoState.peGracia);
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIAL'] = parseFloat(lineaCreditoState.monto);
            oParan.oRegistrarSolucion['SOL_FRECUENCIA'] = 30; //parseFloat(lineaCreditoState.frecMax);
            oParan.oRegistrarSolucion['SOL_FRECUENCIA_PERIODO'] = 'D'; //lineaCreditoState.selectTiempoFrecMax[0].value;
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIALMIN'] = 0; //parseFloat(lineaCreditoState.minimo);
            oParan.oRegistrarSolucion['SOL_APARICIONES'] = 9999; //parseFloat(lineaCreditoState.apariciones);
            oParan.oRegistrarSolucion['SOL_MONEDA'] = lineaCreditoState.selectMoneda[0].value;
            oParan.oRegistrarSolucion['IDESTADO'] = parseFloat(
              lineaCreditoState.selectEstado[0].value
            );
            oParan.oRegistrarSolucion['SOL_PERIODOSENTREPAGO'] = parseFloat(
              lineaCreditoState.peCuotas
            );
            oParan.oRegistrarSolucion['SOL_PERIODOSENTREPAGO_PERIODO'] =
              lineaCreditoState.selectTiempoPeCuotas[0].value;
            oParan.oRegistrarSolucion['SOL_TEAP'] = lineaCreditoState.tea;
            dispatch(setValidCampos(oParamValidCampos));
          }
        } else {
          dispatch(setValidCampos(oParamValidCampos));
          oParan.mensaje = 'Debe llenar todos los campos en (*).';
          return oParan;
        }
        //campos opcionales
        if (lineaCreditoState.montoDes.length !== 0) {
          oParan.oRegistrarSolucion['SOL_PAGODESEMBOLSO'] = parseFloat(lineaCreditoState.montoDes);
        }
      }
    }
    return oParan;
  };
  const limpiarCampos = () => {
    dispatch(limpiarCamposLC());
    // dispatch(updateTitle({ title: "Linea de Crédito" }));
  };
  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };
  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };
  const onConfirmarConcepto = () => {
    setShowModalCancelar(false);
    // setButtonAction(1);
    // setTabProceso(0);
    limpiarCampos();
    navigate(paths.SOLUTION.CREDIT_LINE.BASE);
  };
  const onPressGrabar = async () => {
    let { valid, oRegistrarSolucion, mensaje } = camposObligatorios();
    if (valid) {
      var oParam = {
        oRegistrarSolucion: oRegistrarSolucion,
        aRegManuales: [],
        aTeaMultiMoneda: [],
        listaFiltrosMultiplesAppliedTableAP: [],
      };
      const responseRegistrarSol = await callEndpoint(registrarSolucion(oParam));
      if (responseRegistrarSol === null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (responseRegistrarSol.data) {
          if (responseRegistrarSol.data.cabecera.statusCode === 200) {
            // props.setButtonAction(0);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: responseRegistrarSol.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            // await props.loadLineaCredito();
            navigate(paths.SOLUTION.CREDIT_LINE.BASE);
          } else if (responseRegistrarSol.data.cabecera.statusCode === 400) {
            Swal.fire({
              position: 'center',
              icon: 'info',
              title: responseRegistrarSol.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseRegistrarSol.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: mensaje,
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };
  const loadAgente = async () => {
    const responseObtAgentes = await callEndpoint(obtenerAgentes());
    let aAgentes = [];
    if (responseObtAgentes != null) {
      if (responseObtAgentes.data) {
        let { aListaAgentes } = responseObtAgentes.data.respuesta;
        aListaAgentes.forEach((element) => {
          aAgentes.push(createAgenteSelectAdapter(element));
        });
      }
    }
    dispatch(setListaAgente({ listaAgente: aAgentes }));
  };
  const loadMoneda = async () => {
    const responseObtMonedas = await callEndpoint(obtenerListadoMoneda());
    let aMonedas = [];
    if (responseObtMonedas != null) {
      if (responseObtMonedas.data) {
        let { aListaMoneda } = responseObtMonedas.data.respuesta;
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'texto_moneda'
        )[0].DatoPersonalizacion;
        aListaMoneda.forEach((element) => {
          aMonedas.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
        });
      }
    }
    dispatch(setListaMonedaLC({ listaMoneda: aMonedas }));
  };
  const loadEstado = async () => {
    let aEstadoSol = [];
    EstadoSolucionesData.forEach((element) => {
      aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
    });
    dispatch(setListaEstadoLC({ listaEstado: aEstadoSol }));
  };
  const loadTiempoPeriodo = async () => {
    let aTiempoPeriodo = [];
    TiempoPeriodosData.forEach((element) => {
      aTiempoPeriodo.push(createTiempoPeriodoAdapter(element));
    });
    dispatch(setListaTiempoLC({ listaTiempo: aTiempoPeriodo }));
  };
  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: 'Crear Línea de Crédito' }));
      await loadAgente();
      await loadMoneda();
      await loadEstado();
      await loadTiempoPeriodo();
    })();
  }, []);
  const handleChangeFecha = (oEvent) => {
    var oParam = {
      dFechaInicio: oEvent[0],
      dFechaFin: oEvent[1],
    };
    var sFechaInicio = '';
    var sFechaFinal = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';
    if (oParam.dFechaInicio != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      var iDia = oParam.dFechaInicio.getDate();
      var iMes = oParam.dFechaInicio.getMonth() + 1;
      var iAnio = oParam.dFechaInicio.getFullYear();
      if (oParam.dFechaInicio.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
    }
    if (oParam.dFechaFin != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaFin.getDate();
      let iMes = oParam.dFechaFin.getMonth() + 1;
      let iAnio = oParam.dFechaFin.getFullYear();
      if (oParam.dFechaFin.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaFinal = sDia + '/' + sMes + '/' + sAnio;
    }
    // oParam.sFechaInicio = sFechaInicio;
    // oParam.sFechaFinal = sFechaFinal;
    oParam.dDateRange = [oEvent[0], oEvent[1]];
    if (oParam.dFechaFin != null && oParam.dFechaInicio != null) {
      oParam.bValidFecha = null;
    }
    dispatch(changeVigenciaLC(oParam));
  };
  const obligatoriedadCampos = (selectTipoFormulario) => {
    var formulario = <></>;
    if (selectTipoFormulario.length > 0) {
      let tipoFormulario = selectTipoFormulario[0].value;
      switch (tipoFormulario) {
        case 'Tipo_1':
          formulario = (
            <>
              {/* campos obligatorios */}
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Descripción (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={lineaCreditoState.descripcion}
                    onChange={(evnt) => handleChangeDescripcion(evnt)}
                    validate={lineaCreditoState.bValidDescripcion}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Socio de negocio (*):'}></LabelForm>
                  <SelectForm
                    bValid={lineaCreditoState.bValidAgente}
                    value={lineaCreditoState.selectAgente}
                    handleChange={(e, v) => handleChangeAgente(v)}
                    options={lineaCreditoState.listaAgente}
                    placeholder={'Seleccione Socio'}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia (*):'}></LabelForm>
                  <DatePickerForm
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={lineaCreditoState.dDateRange[0]}
                    endDate={lineaCreditoState.dDateRange[1]}
                    onChange={(evnt) => handleChangeFecha(evnt)}
                    isClearable={true}
                    placeholderText="Ingrese"
                    locale="es"
                    showMonthDropdown
                    showYearDropdown
                    portalId="root-portal"
                    validate={lineaCreditoState.bValidFecha}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={lineaCreditoState.bValidMoneda}
                    value={lineaCreditoState.selectMoneda}
                    handleChange={(e, v) => handleChangeMoneda(v)}
                    options={lineaCreditoState.listaMoneda}
                    placeholder={'Seleccione Moneda'}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Importe (*):'}></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={lineaCreditoState.monto}
                    validate={lineaCreditoState.bValidMonto}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeMonto(values, sourceInfo)}
                  />
                </RowForm>
              </ColForm>

              {/* <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    
                    
                    obligatorio={true}
                    title={'Cuotas de Gracia (*):'}
                  ></LabelForm>
                  <InputNumberFormat
                    min={0}
                    width={'320px'}
                    value={lineaCreditoState.peGracia}
                    validate={lineaCreditoState.bValidPeGracia}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) =>
                      handleChangePeridoGracia(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm> */}

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Ciclo de intereses (*):'}></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      value={lineaCreditoState.peCuotas}
                      validate={lineaCreditoState.bValidCuotas}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeridoEntreCuotas(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={lineaCreditoState.bValidTiempoPeCuotas}
                      value={lineaCreditoState.selectTiempoPeCuotas}
                      handleChange={(e, v) => handleChangeTiempoPeCuotas(v)}
                      options={lineaCreditoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'TEA (%) (*):'}></LabelForm>
                  <InputNumberFormat
                    value={lineaCreditoState.tea}
                    validate={lineaCreditoState.bValidTea}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeTea(values, sourceInfo)}
                    max={100.0}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    obligatorio={true}
                    title={'Periodos mínimos entre desembolsos (*):'}
                  ></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      value={lineaCreditoState.frecMax}
                      validate={lineaCreditoState.bValidFrecMax}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangeFrecMax(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={lineaCreditoState.bValidTiempoFrecMax}
                      value={lineaCreditoState.selectTiempoFrecMax}
                      handleChange={(e, v) => handleChangeTiempoFrecMax(v)}
                      options={lineaCreditoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    obligatorio={true}
                    title={'Cantidad máxima de desembolsos (*):'}
                  ></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={lineaCreditoState.apariciones}
                    validate={lineaCreditoState.bValidApariciones}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeApariciones(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    obligatorio={true}
                    title={'Importe mínimo de desembolso (*):'}
                  ></LabelForm>
                  <InputNumberFormat
                    value={lineaCreditoState.minimo}
                    validate={lineaCreditoState.bValidMinimo}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeMinimo(values, sourceInfo)}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Estado (*):'}></LabelForm>
                  <SelectForm
                    bValid={lineaCreditoState.bValidEstado}
                    value={lineaCreditoState.selectEstado}
                    handleChange={(e, v) => handleChangeEstado(v)}
                    options={lineaCreditoState.listaEstado}
                    placeholder={'Seleccione Estado'}
                  />
                </RowForm>
              </ColForm>

              {/* campos no requeridos */}
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm title={'Costo fijo de desembolso:'}></LabelForm>
                  <InputNumberFormat
                    value={lineaCreditoState.montoDes}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeMontoDesembolso(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
            </>
          );
          break;
        case 'Tipo_2':
          formulario = (
            <>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Descripción (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={lineaCreditoState.descripcion}
                    onChange={(evnt) => handleChangeDescripcion(evnt)}
                    validate={lineaCreditoState.bValidDescripcion}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Socio de negocio (*):'}></LabelForm>
                  <SelectForm
                    bValid={lineaCreditoState.bValidAgente}
                    value={lineaCreditoState.selectAgente}
                    handleChange={(e, v) => handleChangeAgente(v)}
                    options={lineaCreditoState.listaAgente}
                    placeholder={'Seleccione Socio'}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia (*):'}></LabelForm>
                  <DatePickerForm
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={lineaCreditoState.dDateRange[0]}
                    endDate={lineaCreditoState.dDateRange[1]}
                    onChange={(evnt) => handleChangeFecha(evnt)}
                    isClearable={true}
                    placeholderText="Ingrese"
                    locale="es"
                    showMonthDropdown
                    showYearDropdown
                    portalId="root-portal"
                    validate={lineaCreditoState.bValidFecha}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={lineaCreditoState.bValidMoneda}
                    value={lineaCreditoState.selectMoneda}
                    handleChange={(e, v) => handleChangeMoneda(v)}
                    options={lineaCreditoState.listaMoneda}
                    placeholder={'Seleccione Moneda'}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Importe (*):'}></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={lineaCreditoState.monto}
                    validate={lineaCreditoState.bValidMonto}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeMonto(values, sourceInfo)}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Importe Cuota (*):'}></LabelForm>
                  <InputNumberFormat
                    value={lineaCreditoState.montoCuota}
                    validate={lineaCreditoState.bValidMontoCuota}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeMontoCuota(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Cuotas de Gracia (*):'}></LabelForm>
                  <InputNumberFormat
                    min={0}
                    value={lineaCreditoState.peGracia}
                    validate={lineaCreditoState.bValidPeGracia}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) =>
                      handleChangePeridoGracia(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Ciclo de intereses (*):'}></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      value={lineaCreditoState.peCuotas}
                      validate={lineaCreditoState.bValidCuotas}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeridoEntreCuotas(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={lineaCreditoState.bValidTiempoPeCuotas}
                      value={lineaCreditoState.selectTiempoPeCuotas}
                      handleChange={(e, v) => handleChangeTiempoPeCuotas(v)}
                      options={lineaCreditoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    obligatorio={true}
                    title={'Periodos mínimos entre desembolsos (*):'}
                  ></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      value={lineaCreditoState.frecMax}
                      validate={lineaCreditoState.bValidFrecMax}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangeFrecMax(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={lineaCreditoState.bValidTiempoFrecMax}
                      value={lineaCreditoState.selectTiempoFrecMax}
                      handleChange={(e, v) => handleChangeTiempoFrecMax(v)}
                      options={lineaCreditoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    obligatorio={true}
                    title={'Cantidad máxima de desembolsos (*):'}
                  ></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={lineaCreditoState.apariciones}
                    validate={lineaCreditoState.bValidApariciones}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeApariciones(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    obligatorio={true}
                    title={'Importe mínimo de desembolso (*):'}
                  ></LabelForm>
                  <InputNumberFormat
                    value={lineaCreditoState.minimo}
                    validate={lineaCreditoState.bValidMinimo}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeMinimo(values, sourceInfo)}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Estado (*):'}></LabelForm>
                  <SelectForm
                    bValid={lineaCreditoState.bValidEstado}
                    value={lineaCreditoState.selectEstado}
                    handleChange={(e, v) => handleChangeEstado(v)}
                    options={lineaCreditoState.listaEstado}
                    placeholder={'Seleccione Estado'}
                  />
                </RowForm>
              </ColForm>

              {/* campos no requeridos */}
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm title={'Costo fijo de desembolso:'}></LabelForm>
                  <InputNumberFormat
                    value={lineaCreditoState.montoDes}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeMontoDesembolso(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
            </>
          );
          break;
        case 'Tipo_3':
          formulario = (
            <>
              {/* campos obligatorios */}
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Descripción (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={lineaCreditoState.descripcion}
                    onChange={(evnt) => handleChangeDescripcion(evnt)}
                    validate={lineaCreditoState.bValidDescripcion}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Socio de negocio (*):'}></LabelForm>
                  <SelectForm
                    bValid={lineaCreditoState.bValidAgente}
                    value={lineaCreditoState.selectAgente}
                    handleChange={(e, v) => handleChangeAgente(v)}
                    options={lineaCreditoState.listaAgente}
                    placeholder={'Seleccione Socio'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia (*):'}></LabelForm>
                  <DatePickerForm
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={lineaCreditoState.dDateRange[0]}
                    endDate={lineaCreditoState.dDateRange[1]}
                    onChange={(evnt) => handleChangeFecha(evnt)}
                    isClearable={true}
                    placeholderText="Ingrese"
                    locale="es"
                    showMonthDropdown
                    showYearDropdown
                    portalId="root-portal"
                    validate={lineaCreditoState.bValidFecha}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={lineaCreditoState.bValidMoneda}
                    value={lineaCreditoState.selectMoneda}
                    handleChange={(e, v) => handleChangeMoneda(v)}
                    options={lineaCreditoState.listaMoneda}
                    placeholder={'Seleccione Moneda'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Importe (*):'}></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={lineaCreditoState.monto}
                    validate={lineaCreditoState.bValidMonto}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeMonto(values, sourceInfo)}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Ciclo de intereses (*):'}></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      value={lineaCreditoState.peCuotas}
                      validate={lineaCreditoState.bValidCuotas}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeridoEntreCuotas(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={lineaCreditoState.bValidTiempoPeCuotas}
                      value={lineaCreditoState.selectTiempoPeCuotas}
                      handleChange={(e, v) => handleChangeTiempoPeCuotas(v)}
                      options={lineaCreditoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'TEA (%) (*):'}></LabelForm>
                  <InputNumberFormat
                    value={lineaCreditoState.tea}
                    validate={lineaCreditoState.bValidTea}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeTea(values, sourceInfo)}
                    max={100.0}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Estado (*):'}></LabelForm>
                  <SelectForm
                    bValid={lineaCreditoState.bValidEstado}
                    value={lineaCreditoState.selectEstado}
                    handleChange={(e, v) => handleChangeEstado(v)}
                    options={lineaCreditoState.listaEstado}
                    placeholder={'Seleccione Estado'}
                  />
                </RowForm>
              </ColForm>

              {/* campos no requeridos */}
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm title={'Costo fijo de desembolso:'}></LabelForm>
                  <InputNumberFormat
                    value={lineaCreditoState.montoDes}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeMontoDesembolso(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
            </>
          );
          break;
        default:
          <></>;
      }
    }
    return formulario;
  };
  return (
    <Content>
      <ColFlex>
        <SubTitle subtitle="1. Registrar datos generales de la solución." />
        <ContentForm>
          <RowForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Tipo Fomulario (*):'}></LabelForm>
                <SelectForm
                  isNoClearable={true}
                  bValid={lineaCreditoState.bValidselectTipoFormulario}
                  value={lineaCreditoState.selectTipoFormulario}
                  handleChange={(e, v) => handleChangeTipoFormulario(v)}
                  options={TipoFormularioLineaCredito}
                  placeholder={'Seleccione Tipo Formulario'}
                />
              </RowForm>
            </ColForm>
            {obligatoriedadCampos(lineaCreditoState.selectTipoFormulario)}
          </RowForm>
          <RowFooterButton>
            <ButtonForm
              action={(e) => onPressCancelar()}
              name="Cancelar"
              isAction={true}
              typeColor="warning"
            />
            <ButtonForm
              action={(e) => limpiarForm(e)}
              name="Limpiar"
              isAction={true}
              typeColor="primary"
            />
            <ButtonForm
              action={(e) => onPressGrabar()}
              name="Grabar"
              isAction={true}
              typeColor="secondary"
            />
          </RowFooterButton>
        </ContentForm>
      </ColFlex>
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación de la línea de crédito?"
        action={(e) => onConfirmarConcepto(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </Content>
  );
};
