import React from 'react';
import Chart from 'react-apexcharts';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { LoadingForm, SelectForm } from '../../../components';
import { theme, truncar } from '../../../common';
import { CardDash, RowForm, Title } from '../../../styles';
import { useSelector } from 'react-redux';

export const MonthlyEarnings = (props) => {
  const dashboardState = useSelector((store) => store.dashboard);
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [labelData, setLabelData] = React.useState([]);
  const [selectedPosicion, setSelectedPosicion] = React.useState([]);
  const [listaPosicion, setListaPosicion] = React.useState([]);

  useEffect(() => {
    let listaPosicion = [];
    if (props.data.length > 0) {
      props.data.map((a) => {
        listaPosicion.push({ value: a.Posicion, label: a.Posicion });
      });
      const listaPosicionSD = quitarDuplicados(listaPosicion);
      setListaPosicion(listaPosicionSD);
      setSelectedPosicion([listaPosicionSD[0]]);
    }
  }, [props.data]);

  useEffect(() => {
    if (selectedPosicion.length > 0) {
      obtenerAcumulado();
    }
  }, [selectedPosicion]);

  function quitarDuplicados(array) {
    // Objeto para rastrear objetos únicos
    let uniqueObjects = {};

    // Nuevo array para almacenar objetos únicos
    let uniqueArray = [];

    // Itera sobre cada objeto en el array original
    array.forEach((obj) => {
      // Convierte cada objeto a una cadena para usarlo como clave
      let key = JSON.stringify(obj);

      // Verifica si el objeto ya está en el objeto de rastreo de objetos únicos
      if (!uniqueObjects[key]) {
        // Agrega el objeto al objeto de rastreo y al nuevo array
        uniqueObjects[key] = true;
        uniqueArray.push(obj);
      }
    });

    return uniqueArray;
  }

  const obtenerAcumulado = () => {
    let listaDataTrans = [],
      listaData = [];
    let valuePosicion = '';

    props.data.map((a) => {
      if (a.Posicion === selectedPosicion[0].value) {
        let dato = a.Items.filter((a) => a.Caracteristica === 'Importe');
        let dato1 = a.Items.filter((a) => a.Caracteristica === 'FechaVcto');
        listaDataTrans.push({
          posicion: a.Posicion,
          concepto: a.Concepto,
          fecha: new Date(
            dato1[0].Valor.substring(6, 10),
            dato1[0].Valor.substring(3, 5) - 1,
            dato1[0].Valor.substring(0, 2)
          ).toLocaleDateString('en-GB'),
          importe: truncar(parseFloat(dato[0].Valor), 2),
        });
      }
    });

    listaDataTrans.sort(function (a, b) {
      a = a.fecha.split('/').reverse().join('');
      b = b.fecha.split('/').reverse().join('');
      return a > b ? 1 : a < b ? -1 : 0;
    });

    listaData = listaDataTrans.reduce((acumulador, valorActual) => {
      const elementoYaExiste = acumulador.find((elemento) => elemento.fecha === valorActual.fecha);
      if (elementoYaExiste) {
        return acumulador.map((elemento) => {
          if (elemento.fecha === valorActual.fecha) {
            let suma = elemento.importe + valorActual.importe;
            let resultadoRedondeado = Math.round(suma * 100) / 100;
            return {
              ...elemento,
              importe: resultadoRedondeado,
            };
          }

          return elemento;
        });
      }

      return [...acumulador, valorActual];
    }, []);

    const listaDato = [],
      listaLabel = [];
    const suma = listaData.reduce((valorAnterior, valorActual) => {
      let sumatoria = valorAnterior + valorActual.importe;
      return Math.round(sumatoria * 100) / 100;
    }, 0);

    let sumaDato = 0;
    listaData.map((b) => {
      if (b.fecha != 'Invalid Date') {
        sumaDato = sumaDato + b.importe;
        let resultadoRedondeado = Math.round(sumaDato * 100) / 100;
        if (sumaDato < 0) {
          listaDato.push(resultadoRedondeado * -1);
        } else {
          listaDato.push(resultadoRedondeado);
        }
        listaLabel.push(b.fecha);
      }
    });
    if (listaDato.length === 1) {
      listaDato.push(listaDato[0]);
      listaLabel.push(listaLabel[0]);
    }
    setTotal(suma);
    setData(listaDato);
    setLabelData(listaLabel);
  };

  // chart
  const optionscolumnchart = {
    chart: {
      type: 'area',
      foreColor: '#adb0bb',
      fontFamily: theme.font.family,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      group: 'sparklines',
    },
    colors: theme.colorsDashboard,
    labels: labelData,
    xaxis: {
      categories: labelData,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    markers: {
      size: 0,
    },
    yaxis: {
      show: false,
      labels: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
    },
  };
  const seriescolumnchart = [
    {
      name: 'Serie',
      data: data,
    },
  ];

  const handleChangeSelect = (v) => {
    setSelectedPosicion([v]);
  };

  return (
    <CardDash
      size={'medium'}
      title={
        <RowForm>
          <Title>{props.titulo}</Title>
          <SelectForm
            style={{ marginLeft: 'auto' }}
            width={'54%'}
            value={selectedPosicion}
            handleChange={(evnt, v) => handleChangeSelect(v)}
            validate={true}
            options={listaPosicion}
            placeholder={'Seleccione'}
            disableClearable={true}
          />
        </RowForm>
      }
    >
      {props.loading ? (
        <LoadingForm />
      ) : (
        <>
          <Typography
            variant="h3"
            fontWeight="700"
            style={{
              overflowX: 'auto',
              height: '75px',
              fontSize: '2rem',
              fontFamily: theme.font.family,
            }}
          >
            {new Intl.NumberFormat('en-US').format(total)}&nbsp;{dashboardState.moneda}
          </Typography>
          <Chart options={optionscolumnchart} series={seriescolumnchart} type="area" height={30} />
        </>
      )}
    </CardDash>
  );
};
