import React, { useEffect } from 'react';
import {
  SearchInputText,
  LoadingSpinner,
  TableGrid,
  ButtonForm,
  PBR,
  ButtonAccion,
} from '../../../../components';
import {
  crearSociedad,
  editarSocidad,
  eliminarSociedad,
  listarSociedad,
} from '../../../../services';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCleanModalSociedad,
  setListarSociedad,
  setModalSociedad,
  setSearchTextSociedad,
  setSelectedSociedad,
} from '../../../../redux/states';
import { updateTitle } from '../../../../redux/states';
import Swal from 'sweetalert2';
import { ModalSociedadCE } from './components';
import { ContentButton, ContentOption, RowAccion, RowFlex, ColFlex } from '../../../../styles';
import { Dropdown } from 'antd';
import { items } from './misc/menuAccion';
import { columns } from './misc';

export const ListarSociedad = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const sociedadState = useSelector((store) => store.sociedad);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Listar Sociedad' }));
    (async () => {
      loadSociedad();
    })();
  }, []);

  const loadSociedad = async () => {
    const responseListaCampos = await callEndpoint(listarSociedad());
    var listaCampos = [];
    for (let index = 0; index < responseListaCampos.data.respuesta.listSociedad.length; index++) {
      const element = responseListaCampos.data.respuesta.listSociedad[index];
      listaCampos.push(element);
    }
    dispatch(setListarSociedad(listaCampos));
  };

  const handleOpenModal = async (e, data) => {
    if (data) {
      let obj = {
        idSociedad: data.IDSociedad,
        sociedad: data.Sociedad,
        idTax: data.IDTAXRUC,
        nombreComercial: data.NombreComercial,
        direccionLegal: data.DireccionLegal,
        urbanizacion: data.Urbanizacion,
        distrito: data.DistritoCiudad,
        departamento: data.Departamento,
        pais: data.Pais,
      };
      dispatch(setModalSociedad(obj));
      setOpenModal(true);
    } else setOpenModal(true);
  };

  const handleCloseModal = () => {
    limpiarModal();
    setOpenModal(false);
  };

  const validarFormulario = () => {
    let validarDatos = false;
    if (sociedadState.sociedad) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }

    return validarDatos;
  };

  const handleGuardar = async () => {
    var oCabecera = {};
    let validarDatos = false;
    validarDatos = await validarFormulario();
    if (validarDatos) {
      if (sociedadState.idSociedad) {
        oCabecera.IDSociedad = sociedadState.idSociedad;
        oCabecera.Sociedad = sociedadState.sociedad;
        oCabecera.IDTAXRUC = sociedadState.idTax;
        oCabecera.NombreComercial = sociedadState.nombreComercial;
        oCabecera.DireccionLegal = sociedadState.direccionLegal;
        oCabecera.Urbanizacion = sociedadState.urbanizacion;
        oCabecera.DistritoCiudad = sociedadState.distrito;
        oCabecera.Departamento = sociedadState.departamento;
        oCabecera.Pais = sociedadState.pais;
        const responseRegistrar = await callEndpoint(editarSocidad(oCabecera));
        setOpenModal(false);
        if (responseRegistrar.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseRegistrar.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          loadSociedad();
          limpiarModal();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRegistrar.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        let filterSociedad = sociedadState.listaSociedad.filter((item, index) => {
          return item.Sociedad === sociedadState.sociedad;
        });
        if (filterSociedad.length === 0) {
          oCabecera.Sociedad = sociedadState.sociedad;
          oCabecera.IDTAXRUC = sociedadState.idTax;
          oCabecera.NombreComercial = sociedadState.nombreComercial;
          oCabecera.DireccionLegal = sociedadState.direccionLegal;
          oCabecera.Urbanizacion = sociedadState.urbanizacion;
          oCabecera.DistritoCiudad = sociedadState.distrito;
          oCabecera.Departamento = sociedadState.departamento;
          oCabecera.Pais = sociedadState.pais;

          const responseEditar = await callEndpoint(crearSociedad(oCabecera));
          setOpenModal(false);
          if (responseEditar.data.cabecera.statusCode === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: responseEditar.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            loadSociedad();
            limpiarModal();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseEditar.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'La sociedad ya existe',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Se tienen que llenar todos los campos',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const limpiarModal = () => {
    dispatch(setCleanModalSociedad());
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(setSearchTextSociedad(oEvent.target.value));
    } else {
      dispatch(setSearchTextSociedad(''));
    }
  };

  const onEliminarSociedad = async () => {
    var obj = [];

    sociedadState.selectSociedad.map((r) => {
      obj.push(r);
    });

    const responseEliminar = await callEndpoint(eliminarSociedad({ IDSociedad: obj }));
    if (responseEliminar.data.cabecera.statusCode === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: responseEliminar.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
      loadSociedad();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: responseEliminar.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <ColFlex>
      <ContentOption>
        <RowFlex>
          <SearchInputText
            width="350px"
            placeholder={'Buscar Sociedad...'}
            filter={sociedadState.searchtext}
            onChange={(e) => onChangeSearch(e)}
          />
          <ContentButton>
            <RowAccion>
              <Dropdown trigger={['click']} menu={{ items: items(onEliminarSociedad) }}>
                <ButtonAccion />
              </Dropdown>
              <PBR permiso={[]}>
                <ButtonForm
                  action={handleOpenModal}
                  name="Agregar"
                  isAction={true}
                  typeColor="primary"
                />
              </PBR>
            </RowAccion>
          </ContentButton>
        </RowFlex>
      </ContentOption>
      <TableGrid
        columns={columns(handleOpenModal)}
        rows={
          sociedadState.searchtext.length === 0
            ? sociedadState.listaSociedad
            : sociedadState.filterListaSociedad
        }
        pageSize={15}
        getRowId={(row) => row.IDSociedad}
        checkboxSelection={true}
        isRowSelectable={(params) => !params.row.Verificado}
        onSelectionModelChange={(oEvent) => {
          dispatch(setSelectedSociedad(oEvent));
        }}
        selectionModel={sociedadState.selectSociedad}
      />
      <ModalSociedadCE
        open={openModal}
        handleClose={handleCloseModal}
        handleGuardar={handleGuardar}
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
