import React from 'react';
import { ButtonStandar, IconButton } from '../../../styles';
export const ButtonForm = (props) => {
  return (
    <ButtonStandar
      width={props.width}
      height={props.height}
      onClick={props.action ? props.action : undefined}
      isAction={props.isAction}
      isOnlyIcon={props.isOnlyIcon}
      noBackground={props.noBackground}
      typeColor={props.typeColor}
      name={props.name}
      icon={props.icon}
      style={props.style}
      disabled={props.disabled}
      size={props.size}
      {...props}
    >
      <IconButton
        disabled={props.disabled}
        isAction={props.isAction}
        isOnlyIcon={props.isOnlyIcon}
        noBackground={props.noBackground}
        typeColor={props.typeColor}
        size={props.size}
        className={props.className ? props.className : props.rotateIcon ? 'rotateimg180' : ''}
      >
        {props.icon ? props.icon : <></>}
        {props.name ? <span>{props.name}</span> : <></>}
      </IconButton>
    </ButtonStandar>
  );
};
