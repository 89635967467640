import { ButtonForm, Input, SelectForm } from '../../../../../../components';
import { RowButton } from '../../../../../../styles';
import { FaMinus, FaPlus } from 'react-icons/fa6';
export const columns = (
  handleChangeTipoSistema,
  handleChangeValor,
  deleteTableRows,
  addTableRows,
  campoState,
  sistemaState
) => [
  {
    field: 'selectTipoSistema',
    headerName: 'Sistema',
    flex: 1,
    renderCell: (params) => (
      <>
        <SelectForm
          width={'200px'}
          validate={params.bValidTipoSistema}
          value={params.value}
          handleChange={(e, v) => handleChangeTipoSistema(params.id, v)}
          options={campoState.listaSistemas}
          placeholder={'Seleccione Tipo Sistema'}
          dataOption={sistemaState.listaTipoSistemas}
        />
      </>
    ),
  },
  {
    field: 'valor',
    headerName: 'Valor',
    flex: 1,
    align: 'left',
    renderCell: (params) => (
      <>
        <Input
          width={'200px'}
          placeholder="Ingrese..."
          value={params.value}
          onChange={(e) => handleChangeValor(params.id, e)}
          validate={params.bValidValor}
        />
      </>
    ),
  },
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <RowButton>
        <ButtonForm
          action={() => deleteTableRows(params.id)}
          icon={<FaMinus />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="primary"
        />
      </RowButton>
    ),
    renderHeader: (params) => (
      <RowButton>
        <ButtonForm
          action={addTableRows}
          icon={<FaPlus />}
          isAction={false}
          isOnlyIcon={true}
          noBackground={true}
          type="white"
        />
      </RowButton>
    ),
  },
];
