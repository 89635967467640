export const createDetalleVersionFlujoCajaAdapter = (oParam) => ({
  sNombreVersion: oParam.sNombreVersion,
  iIdEstructuraBase: oParam.iIdEstructuraBase,
  sEstructuraBase: oParam.sEstructuraBase,
  iProyeccion: oParam.iProyeccion,
  iIdtiempoProyeccion: oParam.iIdtiempoProyeccion,
  sTiempoProyeccion: oParam.sTiempoProyeccion,
  sFechaInicio: oParam.sFechaInicio,
  sFechaFin: oParam.sFechaFin,
  iIdPeridiocidad: oParam.iIdPeridiocidad, //oParam.CodigoPeriodo,
  sCodFechaPeriodo: oParam.CodigoPeriodo,
  sPeridiocidad: oParam.sPeridiocidad,
  iIdMoneda: oParam.iIdMoneda,
  sMoneda: oParam.sMoneda,
  sAbreviatura: oParam.sAbreviatura,
  ListaEtiquetas: oParam.ListaEtiquetas,
  ...oParam,
});
