import Calendar from 'react-calendar';
import styled from 'styled-components';
import { theme } from '../common';

export const StyledCalendar = styled(Calendar)`
  max-width: 100%;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.line};
  font-family: ${theme.font.family};
  line-height: 1.125em;
  border-radius: 5px;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  /* &.react-calendar--doubleView {
    width: 700px;
  } */
  /*
  &.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;

    > * {
      width: 50%;
      margin: 0.5em;
    }
  } */

  button {
    margin: 0;
    border: 0;
    outline: none;
    &:enabled:hover {
      cursor: pointer;
    }
  }

  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      background: none;
      border-radius: 5px;
      &:disabled {
        background-color: #f0f0f0;
      }
      &:enabled:hover,
      &:enabled:focus {
        background-color: #e6e6e6;
      }
    }
    .react-calendar__navigation__arrow {
      font-size: 30px;
    }
    .react-calendar__navigation__label {
      font-size: 20px;
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;

    &__weekday {
      padding: 0.5em;
      abbr {
        text-decoration: underline !important;
      }
    }
  }

  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: bold;
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${theme.colors.error};
  }

  .react-calendar__month-view__days__day--neighboringMonth,
  .react-calendar__decade-view__years__year--neighboringDecade,
  .react-calendar__century-view__decades__decade--neighboringCentury {
    color: ${theme.colors.textDisabled};
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    font-size: 0.9em;
    border-radius: 5px;
    border: 1px solid ${theme.colors.white};

    &:disabled {
      background-color: ${theme.colors.white};
      color: ${theme.colors.textDisabled};
    }

    &:enabled:hover {
      background-color: ${theme.colors.secondary};
      color: ${theme.colors.white};
    }

    &--now {
      background: ${theme.colors.tertiary};
      color: ${theme.colors.white};

      &:enabled:hover,
      &:enabled:focus {
        background-color: ${theme.colors.secondary};
      }
    }

    &--hasActive {
      background: ${theme.colors.primary};
      color: ${theme.colors.white};

      &:enabled:hover,
      &:enabled:focus {
        background: ${theme.colors.quinary};
        color: ${theme.colors.white};
      }
    }

    &.selected-date {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      border-radius: 5px;
      border: 1px solid ${theme.colors.white};

      &:hover {
        background-color: ${theme.colors.quinary};
      }
    }
  }

  &.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: ${theme.colors.white};
  }
`;
