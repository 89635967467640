import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { onOpenModalAgregarNombreFlujoCaja } from '../../../../../../redux/states';
import { ButtonForm, Input } from '../../../../../../components';
import { ColFlex, RowFlex } from '../../../../../../styles';

export const ModalAgregarNombre = (props) => {
  const dispatch = useDispatch();
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  // const { loading, callEndpoint } = useFetchAndLoad();
  const [formAregarCampos, setFormAregarCampos] = useState({
    nombre: '',
    bValidNombre: null,
    descripcion: '',
    bValidDescripcion: null,
  });
  const { modalOpenAgregarNombreFlujoCaja } = flujoCajaState;
  const onCloseModal = (e) => {
    setFormAregarCampos({
      nombre: '',
      bValidNombre: null,
      descripcion: '',
      bValidDescripcion: null,
    });
    dispatch(onOpenModalAgregarNombreFlujoCaja({ modalOpenAgregarNombreFlujoCaja: false }));
  };
  const handleChangeNombre = (oEvent) => {
    var nombre = oEvent.target.value;
    var validate = null;
    if (nombre.length > 0) {
      validate = true;
    }
    var oParam = {
      nombre: nombre,
      bValidNombre: validate,
    };
    // dispatch(changeNombreConcepto(oParam));
    setFormAregarCampos({ ...formAregarCampos, ...oParam });
  };
  const onGuardarFC = async () => {
    await props.onRegistrarFlujoCaja(formAregarCampos);
  };
  const handleChangeDescripcion = (oEvent) => {
    var descripcion = oEvent.target.value;
    var validate = null;
    if (descripcion.length > 0) {
      validate = true;
    }
    var oParam = {
      descripcion: descripcion,
      bValidDescripcion: validate,
    };
    // dispatch(changeNombreConcepto(oParam));
    setFormAregarCampos({ ...formAregarCampos, ...oParam });
  };
  return (
    <ModalComponent
      backdrop="static"
      keyboard={false}
      show={modalOpenAgregarNombreFlujoCaja}
      onHide={onCloseModal}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Agregar Campos Flujo Caja</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <ColFlex>
          <RowFlex>
            <ContainerLabel>
              <Label>Nombre :</Label>
            </ContainerLabel>
            <Container>
              <Input
                placeholder="Ingrese..."
                value={formAregarCampos.nombre}
                onChange={(evnt) => handleChangeNombre(evnt)}
                validate={formAregarCampos.bValidNombre}
              />
            </Container>
          </RowFlex>
          <RowFlex>
            <ContainerLabel>
              <Label>Descripción :</Label>
            </ContainerLabel>
            <Container>
              <Input
                placeholder="Ingrese..."
                value={formAregarCampos.descripcion}
                onChange={(evnt) => handleChangeDescripcion(evnt)}
                validate={formAregarCampos.bValidDescripcion}
              />
            </Container>
          </RowFlex>
        </ColFlex>
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <ButtonForm
          action={(e) => onGuardarFC()}
          name="Guardar"
          isAction={true}
          typeColor="primary"
        />
        <ButtonForm
          action={(e) => onCloseModal()}
          name="Cancelar"
          isAction={false}
          typeColor="warning"
        />
      </ModalComponent.Footer>
    </ModalComponent>
  );
};
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
const Container = styled.div`
  width: 320px;
`;
const ContainerLabel = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
`;
const Label = styled.span``;
const ModalComponent = styled(Modal)`
  /* z-index:99999; */
`;
