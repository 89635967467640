import React from 'react';
import { ButtonForm, LoadingSpinner, SearchInputText, TableGrid } from '../../../components';
import { ColFlex, ContentButton, ContentOption, RowAccion, RowFlex } from '../../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../../common/hooks';
import {
  changeSearchUsuario,
  onSetModalDatos,
  onSetModalUsuario,
  setCleanModalRol,
  setCleanModalUsuario,
  setListarRol,
  setListarUsuario,
  setListarUsuarioRol,
  updateTitle,
} from '../../../redux/states';
import {
  cambiarPassUsu,
  cargaFotoUusuario,
  changePasswordC,
  checkUserExists,
  crearUsuario,
  crearUsuarioRol,
  deleteUser,
  editarUsuario,
  eliminarUsuario,
  listarSociedad,
  listarUsuario,
  obtenerDetalleUsuario,
  obtenerListaRol,
  obtenerUrlImg,
  signUp,
} from '../../../services';
import { useEffect } from 'react';
import { ModalRol, ModalUsuario } from './components';
import { columns } from './misc';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

export const SeguridadUsuario = () => {
  const usuarioState = useSelector((store) => store.usuario);
  const authState = useSelector((store) => store.auth);
  const [openModalRol, setOpenModalRol] = React.useState(false);
  const [openModalUsuario, setOpenModalUsuario] = React.useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let { t } = useTranslation();

  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: t('usuario.titulo') }));
      loadUsuario();
    })();
  }, []);

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(changeSearchUsuario(oEvent.target.value));
    } else {
      dispatch(changeSearchUsuario(''));
    }
  };

  const loadUsuario = async () => {
    const responseListaUsuario = await callEndpoint(listarUsuario());
    var listaUsuario = [];
    for (let index = 0; index < responseListaUsuario.data.listadousuarios.length; index++) {
      const element = responseListaUsuario.data.listadousuarios[index];
      listaUsuario.push(element);
    }
    dispatch(setListarUsuario(listaUsuario));
    await loadSociedad();
  };

  const loadRol = async () => {
    const responseListaRol = await callEndpoint(obtenerListaRol());
    var listaRol = [];
    for (let index = 0; index < responseListaRol.data.respuesta.aLista.length; index++) {
      const element = responseListaRol.data.respuesta.aLista[index];
      element.value = responseListaRol.data.respuesta.aLista[index].RolID;
      element.label = responseListaRol.data.respuesta.aLista[index].Descripcion;
      listaRol.push(element);
    }
    dispatch(setListarRol(listaRol));
  };

  const loadSociedad = async () => {
    const responseListaCampos = await callEndpoint(listarSociedad());
    var listaSociedad = [];
    for (let index = 0; index < responseListaCampos.data.respuesta.listSociedad.length; index++) {
      const element = responseListaCampos.data.respuesta.listSociedad[index];
      element.value = element.IDSociedad;
      element.label = element.Sociedad;
      listaSociedad.push(element);
    }
    dispatch(onSetModalUsuario({ listaSociedad }));
  };

  const loadDetalleUsuario = async (id) => {
    const responseListaUsuario = await callEndpoint(obtenerDetalleUsuario({ id: id }));
    var listaUsuario = [];
    for (let index = 0; index < responseListaUsuario.data.respuesta.aLista.length; index++) {
      const element = responseListaUsuario.data.respuesta.aLista[index];
      listaUsuario.push(element);
    }
    dispatch(setListarUsuarioRol(listaUsuario));
  };

  const handleOpenModalRol = async (e, data) => {
    await loadRol();
    dispatch(onSetModalDatos({ usuarioRolId: data.IDUsuario, usuarioRolNombre: data.Usuario }));
    await loadDetalleUsuario(data.IDUsuario);
    setOpenModalRol(true);
  };

  const handleOpenModalUsuario = async (e, data) => {
    setOpenModalUsuario(true);
  };

  const handleOpenModalUsuarioEdit = async (e, datos) => {
    let filterSociedad = usuarioState.listaSociedad.filter((r) => datos.sociedad.includes(r.value));

    if (datos.Foto.length > 0) {
      const splitFoto = datos.Foto.split('/');
      const name = splitFoto[splitFoto.length - 1];
      const res = await obtenerUrlImg(datos.Foto);
      const response = await fetch(res, {
        mode: 'no-cors',
      });
      const data = await response.blob();
      let foto = new File([data], name, {
        type: data.type,
      });
      dispatch(
        onSetModalUsuario({
          idUsuario: datos.IDUsuario,
          email: datos.Correo,
          bValidEmail: true,
          username: datos.Usuario,
          bValidUsername: true,
          sociedad: filterSociedad,
          foto: [foto],
        })
      );
    } else {
      dispatch(
        onSetModalUsuario({
          idUsuario: datos.IDUsuario,
          email: datos.Correo,
          bValidEmail: true,
          username: datos.Usuario,
          bValidUsername: true,
          sociedad: filterSociedad,
          foto: [],
        })
      );
    }
    setOpenModalUsuario(true);
  };

  const handleDeleteUsuario = async (e, data) => {
    Swal.fire({
      title: `Seguro que desea eliminar a ${data.Correo}`,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: true,
      allowEscapeKey: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // const responseDeleteCognito = await deleteUser(data.Correo);
        const responseDeleteCognito = true;
        if (responseDeleteCognito) {
          const responseUsuarioDelete = await callEndpoint(eliminarUsuario({ email: data.Correo }));

          if (responseUsuarioDelete.data.cabecera.statusCode === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: responseUsuarioDelete.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            loadUsuario();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseUsuarioDelete.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      }
    });
  };

  const handleCloseModalRol = () => {
    limpiarModalRol();
    setOpenModalRol(false);
  };

  const handleCloseModalUsuario = () => {
    limpiarModalUsuario();
    setOpenModalUsuario(false);
  };

  const limpiarModalUsuario = () => {
    dispatch(setCleanModalUsuario());
  };

  const limpiarModalRol = () => {
    dispatch(setCleanModalRol());
  };

  const validarModalRol = () => {
    let validarDatos = false;
    if (usuarioState.usuarioRolId) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (usuarioState.listaUsuarioRol.length > 0) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    return validarDatos;
  };

  const handleGuardarModalRol = async () => {
    let validarDatos = false;
    validarDatos = await validarModalRol();
    if (validarDatos) {
      var listaRol = usuarioState.listaUsuarioRol.filter((val) => val.estado !== 1);
      let obj = {
        usuarioId: usuarioState.usuarioRolId,
        listaRolAdd: listaRol,
        listaRolDel: usuarioState.listaUsuarioRolDel,
      };
      const responseUsuarioRol = await callEndpoint(crearUsuarioRol(obj));

      if (responseUsuarioRol.data.cabecera.statusCode === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseUsuarioRol.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
        setOpenModalRol(false);
        loadUsuario();
        limpiarModalRol();
      } else {
        limpiarModalRol();
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseUsuarioRol.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Se tienen que llenar todos los campos',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const validarCrearUsuario = () => {
    let validarDatos = false;
    if (usuarioState.bValidEmail) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (usuarioState.bValidUsername) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }

    return validarDatos;
  };

  const validarEditarUsuario = () => {
    let validarDatos = false;
    if (usuarioState.bValidEmail) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (usuarioState.bValidUsername) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }

    return validarDatos;
  };

  const validarCambioPass = () => {
    let validarDatos = false;
    if (!usuarioState.bValidPasswordChange) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (usuarioState.bValidOldPassword) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }

    if (usuarioState.bValidPassword) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (usuarioState.bValidPasswordConfirm) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }

    return validarDatos;
  };

  const handleGuardarModalUsuario = async () => {
    let listaIDSociedad = [];
    usuarioState.sociedad.map((r) => listaIDSociedad.push(r.value));
    if (usuarioState.idUsuario === 0) {
      let validarDatos = false;
      validarDatos = await validarCrearUsuario();
      if (validarDatos) {
        let obj = {
          email: usuarioState.email,
          usuario: usuarioState.username,
          sociedad: listaIDSociedad,
        };
        const responseValidarUsuarioCognito = await checkUserExists(usuarioState.email);

        if (responseValidarUsuarioCognito) {
          const responseCrearUsuarioCognito = await signUp(
            usuarioState.email,
            usuarioState.password,
            'grupo abd'
          );

          if (
            responseCrearUsuarioCognito.statusCode === 200 ||
            responseCrearUsuarioCognito.statusCode === 400
          ) {
            const responseCrearUsuario = await callEndpoint(crearUsuario(obj));

            if (responseCrearUsuario.data) {
              if (responseCrearUsuario.status === 200) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Se creo correctamente el usuario',
                  showConfirmButton: false,
                  timer: 2000,
                });
                if (usuarioState.editFoto === 1) {
                  const formData = new FormData();
                  formData.append('file', usuarioState.foto[0].originFileObj);
                  formData.append('IDUsuario', responseCrearUsuario.data.usuario.IDUsuario);
                  const responseCargarFoto = await callEndpoint(cargaFotoUusuario(formData));
                }
                setOpenModalUsuario(false);
                loadUsuario();
                limpiarModalUsuario();
              } else {
                limpiarModalUsuario();
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: responseCrearUsuario.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            } else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: responseCrearUsuario.response.data.msg,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: responseCrearUsuarioCognito.message,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Lo sentimos, por favor intente más tarde',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Se tienen que llenar todos los campos',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      let validarDatos = validarEditarUsuario();
      if (validarDatos) {
        let obj = {
          email: usuarioState.email,
          usuario: usuarioState.username,
          sociedad: listaIDSociedad,
        };
        const responseEditarUsuario = await callEndpoint(editarUsuario(obj));
        if (responseEditarUsuario.data) {
          if (responseEditarUsuario.status === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Se edito correctamente el usuario',
              showConfirmButton: false,
              timer: 2000,
            });

            if (usuarioState.idUsuario === authState.user.uid) {
              let validarCambio = validarCambioPass();
              if (validarCambio) {
                const accessToken = window.localStorage.getItem('auth-token');
                const responseChangeCognito = await changePasswordC(
                  accessToken,
                  usuarioState.oldPassword,
                  usuarioState.password
                );
                let objPass = {
                  email: usuarioState.email,
                  password: usuarioState.password,
                };
                const responseChangeBD = await callEndpoint(cambiarPassUsu(objPass));
              }
            }
            if (usuarioState.editFoto === 1) {
              const formData = new FormData();
              formData.append('file', usuarioState.foto[0].originFileObj);
              formData.append('IDUsuario', usuarioState.idUsuario);
              const responseCargarFoto = await callEndpoint(cargaFotoUusuario(formData));
            }
            setOpenModalUsuario(false);
            loadUsuario();
            limpiarModalUsuario();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseEditarUsuario.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: responseEditarUsuario.response.data.msg,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    }
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              placeholder={t('usuario.buscadorPl')}
              filter={usuarioState.searchtextHomologacion}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <ButtonForm
                  action={handleOpenModalUsuario}
                  name={t('usuario.botonAgregar')}
                  isAction={true}
                  typeColor="primary"
                />
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        <TableGrid
          columns={columns(
            handleOpenModalRol,
            handleOpenModalUsuarioEdit,
            handleDeleteUsuario,
            t,
            authState.user.uid
          )}
          rows={
            usuarioState.searchtextHomologacion.length === 0
              ? usuarioState.listaUsuario
              : usuarioState.filterListaUsuario
          }
          pageSize={15}
          getRowId={(row) => row.IDUsuario}
        />
        <ModalRol
          open={openModalRol}
          handleCloseModal={handleCloseModalRol}
          handleGuardarModal={handleGuardarModalRol}
        />
        <ModalUsuario
          open={openModalUsuario}
          handleCloseModal={handleCloseModalUsuario}
          handleGuardarModal={handleGuardarModalUsuario}
        />
      </ColFlex>
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
