import React, { useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonForm, LabelForm, SelectForm } from '../../../../../../components';
import { Tooltip } from 'antd';
import {
  changeSelectListaSoluciones,
  onGenerateDataLineChartFC,
  onSetListaSoluciones,
} from '../../../../../../redux/states';
import { Workbook } from 'exceljs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useCallback } from 'react';

import { JsoToExcelJsFC, hexToRgba, theme } from '../../../../../../common';
import { obtenerPaletaColoresXSolucion } from '../../../../../../services';
import { useFetchAndLoad } from '../../../../../../common/hooks';
import Swal from 'sweetalert2';
import { ModalDetalleCombinacionSolucion, TableDetalleFlujoCajaSolucion } from './components';
import { v4 as uuidv4 } from 'uuid';
import { ColFlex, ColForm, RowDoubleInput, RowForm } from '../../../../../../styles';
import { FaChartLine, FaDownload, FaFileExcel, FaNoteSticky } from 'react-icons/fa6';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TooltipChart,
  Legend,
  Filler
);

export const Solucion = (props) => {
  const dispatch = useDispatch();
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [plugins, setPlugins] = useState([]);
  const [options, setOptions] = useState(null);

  const [listaDetalleCombinacionSolucion, setListaDetalleCombinacionSolucion] = useState([]);
  const [modalDetalleCombinacionSolucion, setModalDetalleCombinacionSolucion] = useState(false);

  const ref = useRef(null);

  const onClose = () => {
    setModalDetalleCombinacionSolucion(false);
  };

  const onSelectSolucion = (oEvent) => {
    var oParam = [];
    if (oEvent) {
      oParam.push(oEvent);
    } else {
      dispatch(
        onSetListaSoluciones({
          selectListaAgrupadaXSoluciones: [],
        })
      );
    }
    dispatch(changeSelectListaSoluciones({ selectListaSoluciones: oParam }));
    //     Limpiar Grafico
    dispatch(
      onGenerateDataLineChartFC({
        labelsChartFC: [],
        datasetsChartFC: [],
      })
    );
  };

  const downloadImage = useCallback(() => {
    const link = document.createElement('a');
    link.download = 'Competencias_de_FC.png';
    link.href = ref.current.toBase64Image();
    link.click();
  }, []);

  const onExcelSol = async (finalArray, finalArrayTotal, finalArrayTotalParciales, soluciones) => {
    let now = new Date();

    let NombreArchivo =
      'FS_' +
      flujoCajaState.sVersion +
      '_' +
      String(now.getDate()).padStart(2, '0') +
      '-' +
      String(now.getMonth() + 1).padStart(2, '0') +
      '-' +
      now.getFullYear();

    let wb = await JsoToExcelJsFC(NombreArchivo, 'FlujoCajaSolucion', 3, [
      {
        data: flujoCajaState.detalleFlujoCajaCabecerasSolucion,
        value: 'sCabecera',
        validar: flujoCajaState.selectPeridiocidadTableFC[0].label,
        primerDato: 'Posición / Periodo',
      },
      {
        dataCase: 1,
        data: finalArray,
        value: 'Posicion',
        arrData: 'Items',
        arrValue: 'Valor',
        needId: true,
        idValue: 'Id',
      },
      {
        dataCase: 1,
        data: soluciones,
        value: 'Posicion',
        arrData: 'Items',
        arrValue: 'Valor',
        dobleArray: true,
      },
      {
        dataCase: 2,
        data: finalArrayTotalParciales,
        value: 'Id',
        arrData: 'Items',
        arrValue: 'Valor',
        dobleArray: false,
      },
      {
        dataCase: 2,
        data: finalArrayTotal,
        value: 'Id',
        arrData: 'Items',
        arrValue: 'Valor',
        dobleArray: false,
      },
      {
        dataCase: 3,
        row: 2,
        arrValue: [
          { cell: 1, value: 'Costo Total:' },
          {
            cell: 2,
            value: flujoCajaState.selectListaAgrupadaXSoluciones[0].CostoTotal.toString(),
          },
          { cell: 3, value: flujoCajaState.selectMonedaTableSolucionFC[0].label.toString() },
        ],
      },
    ]);
  };

  const onExcelLog = async (logArray) => {
    const wb = new Workbook();
    wb.creator = 'zCFlow';
    let head = [];
    let wbs = wb.addWorksheet('Log');
    wbs.getColumn(1).width = 10;
    wbs.getColumn(2).width = 100;
    wbs.addRow('');
    wbs.addRow('');

    head.push('ID');
    head.push('Detalle');
    wbs.addRow(head);

    logArray.map((col) => {
      let data = [];
      data.push(col.ID);
      data.push(col.Detalle);
      wbs.addRow(data);
    });

    const firstRow = wbs.getRow(3);
    firstRow.alignment = { horizontal: 'center', vertical: 'middle' };
    wbs.eachRow((row) => {
      if (row.number <= 3) row.font = { name: 'Arial' };
      for (let o = 1; o <= 2; o++) {
        const cell = row.getCell(o);
        if (cell.row === '3') {
          cell.fill = {
            type: 'pattern',
            pattern: 'darkVertical',
            fgColor: { argb: 'FFADD8E6' },
            bgColor: { argb: 'FFADD8E6' },
          };
        } else {
          if (cell.row > '2') {
            cell.fill = {
              type: 'pattern',
              pattern: 'darkVertical',
              fgColor: { argb: 'fff4f1e6' },
              bgColor: { argb: 'fff4f1e6' },
            };
            cell.font = {
              bold: true,
            };
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          }
        }
      }
    });

    const uint8Array = await wb.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `LogSoluciones.xlsx`;
    a.click();
    a.remove();
  };

  const generarGrafico = async (
    finalArray,
    finalArrayTotal,
    finalArrayTotalParciales,
    soluciones
  ) => {
    var responseObtenerPaletaColoresXSolucion = await callEndpoint(
      obtenerPaletaColoresXSolucion({})
    );
    const labels = flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras.map(function (
      item,
      index
    ) {
      return item.sCabecera;
    });

    let datasets = [];

    let obj = {
      label: 'Flujo Caja Inicial',
      data: props.finalArrayTotal.Items.map(function (item, index) {
        return item.Valor;
      }),
      pointStyle: 'circle',
      // pointRadius: 1,
      pointRadius: [1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      fill: true,
      borderColor: theme.colorsDashboard[3],
      backgroundColor: theme.colorsDashboardRGBA[3],
    };

    datasets.push(obj);
    const { aListaAgrupadaXSoluciones } = flujoCajaState;

    let detalle = aListaAgrupadaXSoluciones.filter(function (item, index) {
      return item.IdSolucion === flujoCajaState.selectListaSoluciones[0].value;
    });

    detalle.forEach((element, index) => {
      let objSol = {
        label: 'Solución ' + element.IdSolucion,
        data: flujoCajaState.detalleListaAgrupadaXSolucionesTotal.Items.map(function (item) {
          return item.Valor;
        }),
        pointStyle: 'circle',
        pointRadius: 1,
        fill: true,
        borderColor: theme.colorsDashboard[4],
        backgroundColor: theme.colorsDashboardRGBA[4],
      };

      datasets.push(objSol);
    });

    let listaSolEntrada = ['TC', 'AP', 'PR', 'LC'];
    var aListaFinalEntrada = [];
    var helperEntrada = {};
    var aListaFinalSalida = [];
    var helperSalida = {};

    detalle.forEach((element, index) => {
      element.aListaSoluciones.forEach((element2) => {
        const CodigoSolucion = element2.Items[0].CodigoSolucion;
        const HexColor =
          responseObtenerPaletaColoresXSolucion.data.respuesta.aRegPaletaC_X_Solucion.filter(
            function (item, index) {
              return item.CodTipoSolucion === CodigoSolucion;
            }
          )[0].HexColor;

        let encontradoEntrada = listaSolEntrada.filter(function (item3, index3) {
          return item3 === CodigoSolucion;
        });
        let objSol = {
          label: element2.Posicion,
          data: element2.Items.map(function (item2, index2) {
            if (encontradoEntrada.length > 0) {
              var key = index2 + '_' + encontradoEntrada[0];
              if (item2.Valor > 0) {
                if (!helperEntrada[key]) {
                  helperEntrada[key] = {
                    indexVertical: index2,
                    CodigoSolucion: encontradoEntrada[0],
                    Items: [item2],
                  };
                  aListaFinalEntrada.push(helperEntrada[key]);
                } else {
                  helperEntrada[key].Items.push(item2);
                }

                return item2.Valor;
              }
            } else {
              if (item2.Valor < 0) {
                if (!helperSalida[key]) {
                  helperSalida[key] = {
                    indexVertical: index2,
                    CodigoSolucion: item2.CodigoSolucion,
                    Items: [item2],
                  };
                  aListaFinalSalida.push(helperSalida[key]);
                } else {
                  helperSalida[key].Items.push(item2);
                }

                return item2.Valor;
              }
            }
          }),
          pointStyle: 'circle',
          pointRadius: 2,
          fill: false,
        };

        let borderColor = hexToRgba(HexColor, 1);
        let backgroundColor = hexToRgba(HexColor, 0.5);
        objSol.borderColor = borderColor;
        objSol.backgroundColor = backgroundColor;
        // datasets.push(objSol);
      });
    });

    const data = {
      labelsChartFC: labels,
      datasetsChartFC: datasets,
    };

    setPlugins([
      {
        id: 'tooltipLine',
        afterDraw: (chart) => {
          if (chart.tooltip.opacity === 1) {
            const { ctx } = chart;
            const { caretX } = chart.tooltip;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;

            ctx.save();
            ctx.setLineDash([3, 3]);
            ctx.beginPath();
            ctx.moveTo(caretX, topY - 5);
            ctx.lineTo(caretX, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'grey';
            ctx.stroke();
            ctx.restore();
          }
        },
      },
      {
        id: 'arbitratyLinePostivie',

        beforeDatasetsDraw(chart, args, pluginOptions) {
          if (aListaFinalEntrada.length > 0) {
            const {
              ctx,
              chartArea: { top, bottom, left, right, width, height },
              scales: { x, y },
            } = chart;
            ctx.save();
            ctx.beginPath();
            ctx.lineWidth = 2.0;
            ctx.strokeStyle = 'red';

            for (let index = 0; index < aListaFinalEntrada.length; index++) {
              const element = aListaFinalEntrada[index];
              ctx.moveTo(x.getPixelForValue(element.indexVertical), top);
              ctx.lineTo(x.getPixelForValue(element.indexVertical), bottom);
            }

            ctx.stroke();
            ctx.restore();
          }
        },
      },

      {
        id: 'arbitratyLineNegative',

        beforeDatasetsDraw(chart, args, pluginOptions) {
          if (aListaFinalSalida.length > 0) {
            const {
              ctx,
              chartArea: { top, bottom, left, right, width, height },
              scales: { x, y },
            } = chart;

            ctx.save();
            ctx.beginPath();
            ctx.lineWidth = 2.0;
            ctx.strokeStyle = 'purple';

            for (let index = 0; index < aListaFinalSalida.length; index++) {
              const element = aListaFinalSalida[index];
              ctx.moveTo(x.getPixelForValue(element.indexVertical), top);
              ctx.lineTo(x.getPixelForValue(element.indexVertical), bottom);
            }

            ctx.stroke();
            ctx.restore();
          }
        },
      },
    ]);

    setOptions({
      responsive: true,
      tension: 0.4,
      interaction: {
        mode: 'index',
      },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: (ctx) => 'Competencias de Flujo de Caja',
        },
        tooltip: {
          usePointStyle: true,
        },
      },
      onClick: (e, element) => {
        if (element.length > 0) {
          let encontradoEntrada = aListaFinalEntrada.filter(function (item, index) {
            return item.indexVertical === element[0].index;
          });

          let encontradoSalida = aListaFinalSalida.filter(function (item, index) {
            return item.indexVertical === element[0].index;
          });

          let lista = [...encontradoEntrada, ...encontradoSalida];
          if (lista.length === 0) {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'No se ha registrado ingreso ni salida de soluciones.',
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            let listaDet = [];
            for (let index = 0; index < lista.length; index++) {
              const element = lista[index];
              for (let index2 = 0; index2 < element.Items.length; index2++) {
                const element2 = element.Items[index2];
                let obj = {};
                obj.idCombinacionSolucion = uuidv4();
                obj.tipoSolucion = element.CodigoSolucion;
                obj.descripcion = element2.Posicion;
                obj.importe = element2.Valor;
                listaDet.push(obj);
              }
            }
            setListaDetalleCombinacionSolucion(listaDet);

            setModalDetalleCombinacionSolucion(true);
          }
        }
      },
    });
    dispatch(onGenerateDataLineChartFC(data));
  };

  return (
    <ColFlex>
      <RowForm style={{ marginBottom: '5px' }}>
        <ColForm xs={20} sm={11} md={11} lg={8} xl={5}>
          <RowForm>
            <LabelForm title={'Solución : '}></LabelForm>
            <SelectForm
              bValid={true}
              value={flujoCajaState.selectListaSoluciones}
              handleChange={(e, v) => onSelectSolucion(v)}
              options={flujoCajaState.aListaSoluciones}
              placeholder={'Seleccione una Solución'}
            />
          </RowForm>
        </ColForm>
        {flujoCajaState.selectListaAgrupadaXSoluciones.length > 0 ? (
          <>
            <ColForm style={{ alignSelf: 'center' }}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Ganancia : '}></LabelForm>
                <RowDoubleInput>
                  <NumericFormat
                    value={
                      flujoCajaState.selectListaAgrupadaXSoluciones[0].CostoTotal_P *
                      flujoCajaState.selectMonedaTableSolucionFC[0].factorMultiplicador
                    }
                    allowNegative={false}
                    displayType={'text'}
                    thousandSeparator={','}
                    decimalSeparator={'.'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                  &nbsp; {flujoCajaState.selectMonedaTableSolucionFC[0].sAbreviatura}
                </RowDoubleInput>
              </RowForm>
            </ColForm>
            <ColForm style={{ alignSelf: 'center' }}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Costo : '}></LabelForm>
                <RowDoubleInput>
                  <NumericFormat
                    value={
                      flujoCajaState.selectListaAgrupadaXSoluciones[0].CostoTotal_N *
                      flujoCajaState.selectMonedaTableSolucionFC[0].factorMultiplicador
                    }
                    allowNegative={false}
                    displayType={'text'}
                    thousandSeparator={','}
                    decimalSeparator={'.'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                  &nbsp; {flujoCajaState.selectMonedaTableSolucionFC[0].sAbreviatura}
                </RowDoubleInput>
              </RowForm>
            </ColForm>
            <ColForm style={{ alignSelf: 'center' }}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Total : '}></LabelForm>
                <RowDoubleInput>
                  <NumericFormat
                    value={
                      flujoCajaState.selectListaAgrupadaXSoluciones[0].CostoTotal *
                      flujoCajaState.selectMonedaTableSolucionFC[0].factorMultiplicador *
                      -1
                    }
                    allowNegative={true}
                    displayType={'text'}
                    thousandSeparator={','}
                    decimalSeparator={'.'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                  &nbsp; {flujoCajaState.selectMonedaTableSolucionFC[0].sAbreviatura}
                </RowDoubleInput>
              </RowForm>
            </ColForm>
            <ColForm xs={10} sm={10} md={10} lg={10} xl={10} style={{ marginLeft: 'auto' }}>
              <RowForm style={{ justifyContent: 'flex-end' }}>
                <Tooltip title="Descargar Excel">
                  <>
                    <ButtonForm
                      action={(e) =>
                        onExcelSol(
                          flujoCajaState.detalleListaAgrupadaXSoluciones,
                          flujoCajaState.detalleListaAgrupadaXSolucionesTotal,
                          flujoCajaState.detalleListaAgrupadaXSolucionesParcial,
                          flujoCajaState.aListaSolucionesSumarizada
                        )
                      }
                      icon={<FaFileExcel />}
                      isAction={true}
                      isOnlyIcon={true}
                      typeColor="secondary"
                      style={{ marginRight: '2px' }}
                    />
                  </>
                </Tooltip>
                <Tooltip title="Generar Gráfico">
                  <>
                    <ButtonForm
                      action={(e) =>
                        generarGrafico(
                          flujoCajaState.detalleListaAgrupadaXSoluciones,
                          flujoCajaState.detalleListaAgrupadaXSolucionesTotal,
                          flujoCajaState.detalleListaAgrupadaXSolucionesParcial,
                          flujoCajaState.aListaSolucionesSumarizada
                        )
                      }
                      icon={<FaChartLine />}
                      isAction={true}
                      isOnlyIcon={true}
                      typeColor="secondary"
                      style={{ marginRight: '2px' }}
                    />
                  </>
                </Tooltip>
                <Tooltip title="Descargar Gráfico">
                  <>
                    <ButtonForm
                      action={(e) => downloadImage()}
                      icon={<FaDownload />}
                      isAction={true}
                      isOnlyIcon={true}
                      typeColor="secondary"
                      style={{ marginRight: '2px' }}
                    />
                  </>
                </Tooltip>
                <Tooltip title="Descargar Log">
                  <>
                    <ButtonForm
                      action={(e) => onExcelLog(flujoCajaState.logSolucion)}
                      icon={<FaNoteSticky />}
                      isAction={true}
                      isOnlyIcon={true}
                      typeColor="secondary"
                      style={{ marginRight: '2px' }}
                    />
                  </>
                </Tooltip>
              </RowForm>
            </ColForm>
          </>
        ) : (
          <></>
        )}
      </RowForm>
      <TableDetalleFlujoCajaSolucion
        finalArray={props.finalArray}
        setFinalArray={props.setFinalArray}
        finalArrayTotal={props.finalArrayTotal}
        setFinalArrayTotal={props.setFinalArrayTotal}
        finalArrayTotalParciales={props.finalArrayTotalParciales}
        setFinalArrayTotalParciales={props.setFinalArrayTotalParciales}
        //------------------DATOS QUE SIRVEN
        objRightPosicionClick={props.objRightPosicionClick}
        setObjRightPosicionClick={props.setObjRightPosicionClick}
        selectedDesdobles={props.selectedDesdobles}
        setSelectedDesdobles={props.setSelectedDesdobles}
      />
      {flujoCajaState.labelsChartFC.length > 0 && flujoCajaState.datasetsChartFC.length > 0 ? (
        <>
          <Line
            ref={ref}
            options={options}
            data={{
              labels: flujoCajaState.labelsChartFC,
              datasets: flujoCajaState.datasetsChartFC,
            }}
            plugins={plugins}
          />
          <ModalDetalleCombinacionSolucion
            listaCombinacionSoluciones={listaDetalleCombinacionSolucion}
            modalDetalleCombinacionSolucion={modalDetalleCombinacionSolucion}
            onClose={onClose}
          />
        </>
      ) : (
        <></>
      )}
    </ColFlex>
  );
};
