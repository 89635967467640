import React, { useEffect, useState } from 'react';
import { CardSaldo } from './CardSaldo';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../../../common/hooks';
import {
  actualizarEstructuraSecundaria,
  listarConceptos,
  obtenerListaConceptosXtipoConcepto,
  obtenerListadoSubAgrupadorCrearEstructura,
  registrarEstructura,
} from '../../../../services';
import { Input, LabelForm, ModalConfirmation, Button, ButtonForm } from '../../../../components';
import {
  onChangeDetalleCrearEstructura,
  onChangeNombreCrearEstructura,
  onChangeTipoEstructuraCrearEstructura,
  onLimpiarCamposCrearEstructura,
  onSetConceptosXtipoEgresoCrearEstructura,
  onSetConceptosXtipoIngresoCrearEstructura,
  onSetConceptosXtipoSaldoCrearEstructura,
  onSetEstructuraPrincipal,
  onSetListaConceptosEstructura,
  onSetValidDetalleCrearEstructura,
  onSetValidNombreCrearEstructura,
  updateTitle,
} from '../../../../redux/states';
import { CardEgreso } from './CardEgreso';
import { CardIngreso } from './CardIngreso';
import collect from 'collect.js';
import { useNavigate } from 'react-router-dom';
import {
  ColForm,
  Content,
  ContentRow,
  RowFooterButton,
  ColFlexForm,
  RowForm,
} from '../../../../styles';
import { paths } from '../../../../common';

export const CrearEstructura = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const estructuraState = useSelector((store) => store.estructura);
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [expandedSaldo, setExpandedSaldo] = useState(false);
  const [expandedIngreso, setExpandedIngreso] = useState(false);
  const [expandedEgreso, setExpandedEgreso] = useState(false);
  const [treeState, setTreeState] = useState(estructuraState.oSaldo);
  const [treeStateIngreso, setTreeStateIngreso] = useState(estructuraState.oIngreso);
  const [treeStateEgreso, setTreeStateEgreso] = useState(estructuraState.oEgreso);
  const [showModalEstructuraPrincipal, setShowModalEstructuraPrincipal] = useState(false);
  const [selectTipoConcepto, setSelectTipoConcepto] = useState([]);
  const [selectConcepto, setSelectConcepto] = useState([]);
  const [selectPosicion, setSelectPosicion] = useState([]);
  const [selectPosicionAsignada, setSelectPosicionAsignada] = useState(
    estructuraState.selectPosicionAsignada
  );
  const [foundSubAgrupadorCrearEstructura, setFoundSubAgrupadorCrearEstructura] = useState([]);
  const { oConceptosCrearEstructura, oEstructuraPrincial } = estructuraState;

  useEffect(() => {
    dispatch(updateTitle({ title: 'Crear Estructura' }));
  }, []);

  useEffect(() => {
    (async () => {
      var aListaConceptosXtipoSaldo = [];
      var aListaConceptosXtipoIngreso = [];
      var aListaConceptosXtipoEgreso = [];
      var { data } = await callEndpoint(listarConceptos());
      data.respuesta.aListaConceptos.forEach(function (item, index) {
        let obj = {};
        obj.value = item.iIDAgrupador;
        obj.label = item.sNombreAgrupador;
        switch (item.sTipoConcepto) {
          case 'Saldo':
            aListaConceptosXtipoSaldo.push(obj);
            break;
          case 'Ingreso':
            aListaConceptosXtipoIngreso.push(obj);
            break;
          case 'Egreso':
            aListaConceptosXtipoEgreso.push(obj);
            break;
          default:
            break;
        }
      });
      dispatch(onSetConceptosXtipoSaldoCrearEstructura(aListaConceptosXtipoSaldo));
      dispatch(onSetConceptosXtipoIngresoCrearEstructura(aListaConceptosXtipoIngreso));
      dispatch(onSetConceptosXtipoEgresoCrearEstructura(aListaConceptosXtipoEgreso));
    })();
  }, []);

  useEffect(() => {
    // var results = [];
    // if (globalFilterPosiciones) {
    //   if (globalFilterPosiciones.length > 0) {
    //     results = listadoSubAgrupadorCrearEstructura.filter((caracFunc) => {
    //       return caracFunc.sNombreSubAgrupador
    //         .toLowerCase()
    //         .includes(globalFilterPosiciones.toLowerCase());
    //     });
    //   } else {
    //     results = listadoSubAgrupadorCrearEstructura;
    //   }
    // } else {
    //   results = listadoSubAgrupadorCrearEstructura;
    // }
    // var lista = [];
    // selectPosicionAsignada.forEach((element) => {
    //   lista.push(element.iIDConceptoSUB);
    // });
    // var encontrado = results.filter((p) => !lista.includes(p.iIDConceptoSUB));
    // setFoundSubAgrupadorCrearEstructura(encontrado);
    if (selectTipoConcepto.length > 0 && selectConcepto.length === 0) {
      obtenerConceptosXtipoConcepto(selectTipoConcepto[0]);
    } else if (selectTipoConcepto.length > 0 && selectConcepto.length > 0) {
      onSelectConcepto(selectConcepto[0]);
    }
  }, [selectPosicionAsignada]);

  const onPressLimpiarCamposCrearEstructura = () => {
    dispatch(onLimpiarCamposCrearEstructura());
    setTreeState([]);
    setTreeStateIngreso([]);
    setTreeStateEgreso([]);
    setExpandedSaldo(false);
    setExpandedIngreso(false);
    setExpandedEgreso(false);
  };

  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };

  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };

  const onConfirmarConcepto = () => {
    setShowModalCancelar(false);
    // setButtonAction(1);
    // setTabProceso(0);
    onPressLimpiarCamposCrearEstructura();
    navigate(paths.CONFIGURE.STRUCTURE.BASE);
  };

  const onPressGrabar = async () => {
    var bTipoEstructura = true;
    if (oConceptosCrearEstructura.sNombre === '') {
      dispatch(onSetValidNombreCrearEstructura(false));
    }
    if (oConceptosCrearEstructura.sDetalle === '') {
      dispatch(onSetValidDetalleCrearEstructura(false));
    }
    if (oConceptosCrearEstructura.sNombre !== '' && oConceptosCrearEstructura.sDetalle !== '') {
      var oParam = {};
      oParam.oCabeceraEstructura = {
        sNombre: oConceptosCrearEstructura.sNombre,
        sDetalle: oConceptosCrearEstructura.sDetalle,
        iEsPrincipal: oConceptosCrearEstructura.bPrincipal === true ? 1 : 0,
      };
      oParam.aDetalleSaldos = treeState.treeData ? treeState.treeData : [];
      oParam.aDetalleIngreso = treeStateIngreso.treeData ? treeStateIngreso.treeData : [];
      oParam.aDetalleEgreso = treeStateEgreso.treeData ? treeStateEgreso.treeData : [];
      // if (
      //   oParam.aDetalleSaldos.length === 0 &&
      //   oParam.aDetalleIngreso.length === 0 &&
      //   oParam.aDetalleEgreso.length === 0
      // ) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "Ups!",
      //     text: "Se debe completar Saldos, ingresos o egresos.",
      //   });
      // } else {
      var oResponseService = {};
      // try {
      // var { data } = await callEndpoint(registrarEstructura(oParam));
      var respuesta = await callEndpoint(registrarEstructura(oParam));
      if (respuesta.data) {
        var { data } = respuesta;
        if (data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: respuesta.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          navigate(paths.CONFIGURE.STRUCTURE.BASE);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: respuesta.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Ups!',
        text: 'Por favor, completar todos los campos del formulario.',
      });
    }
  };

  const handleChangeNombre = (oEvent) => {
    var sNombre = oEvent.target.value;
    var validate = null;
    if (sNombre.length > 0) {
      validate = true;
    }
    var oParam = {
      sNombre: sNombre,
      validate: validate,
    };
    dispatch(onChangeNombreCrearEstructura(oParam));
  };

  const handleChangeDetalle = (oEvent) => {
    var sDetalle = oEvent.target.value;
    var validate = null;
    if (sDetalle.length > 0) {
      validate = true;
    }
    var oParam = {
      sDetalle: sDetalle,
      validate: validate,
    };
    dispatch(onChangeDetalleCrearEstructura(oParam));
  };

  const obtenerConceptosXtipoConcepto = async (value) => {
    var sTipo;
    let find = [];
    setSelectConcepto([]);
    if (value) {
      setSelectTipoConcepto([value]);
      sTipo = value.label;
    } else {
      setSelectTipoConcepto([]);
      setSelectPosicion([]);
      setFoundSubAgrupadorCrearEstructura([]);
    }
    var oParamConsultarSubAgrupador = {
      sTipo: value === null ? null : value.label,
      iIDCabecera: selectConcepto.length === 0 ? null : selectConcepto[0].value,
    };
    var listaObtenerConceptosXtipo = [];
    var { data } = await callEndpoint(obtenerListaConceptosXtipoConcepto({ sTipo: sTipo }));
    data.respuesta.aListaConceptoXTipo.forEach((element) => {
      var oConcepto = {};
      oConcepto.value = element.IDAgrupador;
      oConcepto.label = element.NombreAgrupador;
      listaObtenerConceptosXtipo.push(oConcepto);
    });
    dispatch(onSetListaConceptosEstructura(listaObtenerConceptosXtipo));
    var aListaSubAgrupadores = [];
    var responseObtListadoSubAgrup = await callEndpoint(
      obtenerListadoSubAgrupadorCrearEstructura(oParamConsultarSubAgrupador)
    );
    if (responseObtListadoSubAgrup != null) {
      if (responseObtListadoSubAgrup.data.cabecera.statusCode === 200) {
        responseObtListadoSubAgrup.data.respuesta.aListaSubAgrupador.forEach(function (
          item,
          index
        ) {
          let obj = {};
          obj.iIDConceptoSUB = item.iIDConceptoSUB;
          obj.iIDCabecera = item.iIDCabecera;
          obj.sNombreAgrupador = item.sNombreAgrupador;
          obj.sNombreSubAgrupador = item.sNombreSubAgrupador;
          obj.sTipoConcepto = item.sTipoConcepto;
          obj.isChecked = false;
          aListaSubAgrupadores.push(obj);
        });
        if (responseObtListadoSubAgrup.data.respuesta.aListaSubAgrupador.length > 0) {
          var aLista = [];
          selectPosicionAsignada.forEach((element) => {
            aLista.push(element.iIDConceptoSUB);
          });
          var collectData = collect;
          var collection = collectData(aListaSubAgrupadores);
          aListaSubAgrupadores = collection.whereNotIn('iIDConceptoSUB', aLista).all();
        }
      }
    }
    setFoundSubAgrupadorCrearEstructura(aListaSubAgrupadores);
  };

  const onSelectConcepto = async (value) => {
    let find = [];
    if (value) {
      setSelectConcepto([value]);
    } else {
      setSelectConcepto([]);
      // obtenerConceptosXtipoConcepto([])
      setFoundSubAgrupadorCrearEstructura([]);
    }
    //Limpiamos las selecciones.
    setSelectPosicion([]);
    var oParamConsultarSubAgrupador = {
      sTipo: selectTipoConcepto.length === 0 ? null : selectTipoConcepto[0].label,
      iIDCabecera: value === null ? null : value.value, //selectConcepto.length === 0 ? null : selectConcepto[0].value,
    };
    var aListaSubAgrupadores = [];
    var { data } = await callEndpoint(
      obtenerListadoSubAgrupadorCrearEstructura(oParamConsultarSubAgrupador)
    );
    data.respuesta.aListaSubAgrupador.forEach(function (item, index) {
      let obj = {};
      obj.iIDConceptoSUB = item.iIDConceptoSUB;
      obj.iIDCabecera = item.iIDCabecera;
      obj.sNombreAgrupador = item.sNombreAgrupador;
      obj.sNombreSubAgrupador = item.sNombreSubAgrupador;
      obj.sTipoConcepto = item.sTipoConcepto;
      obj.isChecked = false;
      aListaSubAgrupadores.push(obj);
    });
    if (aListaSubAgrupadores.length > 0) {
      var aLista = [];
      selectPosicionAsignada.forEach((element) => {
        aLista.push(element.iIDConceptoSUB);
      });
      var collectData = collect;
      var collection = collectData(aListaSubAgrupadores);
      aListaSubAgrupadores = collection.whereNotIn('iIDConceptoSUB', aLista).all();
    }
    setFoundSubAgrupadorCrearEstructura(aListaSubAgrupadores);
  };

  const onConfirmarActualizarPrincipal = async () => {
    setShowModalEstructuraPrincipal(false);
    dispatch(onChangeTipoEstructuraCrearEstructura(true));
    await callEndpoint(
      actualizarEstructuraSecundaria({
        oEstructura: {
          iIDEstructura: oEstructuraPrincial.iIDEstructura,
        },
      })
    );
    dispatch(onSetEstructuraPrincipal(null));
  };

  const onCloseModalEstructuraPrincipal = () => {
    setShowModalEstructuraPrincipal(false);
  };

  const obtenerPosicionesRecursivo = (aListaHijos, aListaPosiciones) => {
    for (let index = 0; index < aListaHijos.length; index++) {
      const element = aListaHijos[index];
      if (element.tipo === 'P') {
        aListaPosiciones.push(element);
      } else {
        obtenerPosicionesRecursivo(element.children, aListaPosiciones);
      }
    }
  };

  const obtenerRealcionCarpetasRecursivo = (oCabecera, aListaHijos, aListaCarpetas) => {
    for (let index = 0; index < aListaHijos.length; index++) {
      const element = aListaHijos[index];
      if (element.tipo === 'C') {
        if (oCabecera === null) {
          var obj = { ...element };
          oCabecera = obj;
          oCabecera.children = oCabecera.children.filter(function (item, index) {
            return item.tipo === 'C';
          });
        } else {
          if (element.children) {
            aListaCarpetas.push(oCabecera);
          }
        }
        obtenerRealcionCarpetasRecursivo(oCabecera, element.children, aListaCarpetas);
      }
    }
  };

  return (
    <Content>
      <ContentRow>
        <Content>
          <ColFlexForm>
            <RowForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={6}>
                <RowForm>
                  <LabelForm title={'Nombre :'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={oConceptosCrearEstructura.sNombre}
                    onChange={(evnt) => handleChangeNombre(evnt)}
                    validate={oConceptosCrearEstructura.bValidNombre}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={6}>
                <RowForm>
                  <LabelForm title={'Detalle :'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={oConceptosCrearEstructura.sDetalle}
                    onChange={(evnt) => handleChangeDetalle(evnt)}
                    validate={oConceptosCrearEstructura.bValidDetalle}
                  />
                </RowForm>
              </ColForm>
            </RowForm>
            <RowForm style={{ alignItems: 'flex-start' }}>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={8} style={{ marginRight: 0 }}>
                <CardSaldo
                  expandedSaldo={expandedSaldo}
                  setExpandedSaldo={setExpandedSaldo}
                  treeState={treeState}
                  setTreeState={setTreeState}
                  selectTipoConcepto={selectTipoConcepto}
                  selectConcepto={selectConcepto}
                  obtenerConceptosXtipoConcepto={obtenerConceptosXtipoConcepto}
                  onSelectConcepto={onSelectConcepto}
                  selectPosicion={selectPosicion}
                  setSelectPosicion={setSelectPosicion}
                  foundSubAgrupadorCrearEstructura={foundSubAgrupadorCrearEstructura}
                  setFoundSubAgrupadorCrearEstructura={setFoundSubAgrupadorCrearEstructura}
                  selectPosicionAsignada={selectPosicionAsignada}
                  setSelectPosicionAsignada={setSelectPosicionAsignada}
                />
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={8} style={{ marginRight: 0 }}>
                <CardIngreso
                  expandedIngreso={expandedIngreso}
                  setExpandedIngreso={setExpandedIngreso}
                  treeState={treeStateIngreso}
                  setTreeState={setTreeStateIngreso}
                  selectTipoConcepto={selectTipoConcepto}
                  selectConcepto={selectConcepto}
                  selectPosicion={selectPosicion}
                  setSelectPosicion={setSelectPosicion}
                  foundSubAgrupadorCrearEstructura={foundSubAgrupadorCrearEstructura}
                  setFoundSubAgrupadorCrearEstructura={setFoundSubAgrupadorCrearEstructura}
                  selectPosicionAsignada={selectPosicionAsignada}
                  setSelectPosicionAsignada={setSelectPosicionAsignada}
                />
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={8} style={{ marginRight: 0 }}>
                <CardEgreso
                  expandedEgreso={expandedEgreso}
                  setExpandedEgreso={setExpandedEgreso}
                  treeState={treeStateEgreso}
                  setTreeState={setTreeStateEgreso}
                  selectTipoConcepto={selectTipoConcepto}
                  selectConcepto={selectConcepto}
                  selectPosicion={selectPosicion}
                  setSelectPosicion={setSelectPosicion}
                  foundSubAgrupadorCrearEstructura={foundSubAgrupadorCrearEstructura}
                  setFoundSubAgrupadorCrearEstructura={setFoundSubAgrupadorCrearEstructura}
                  selectPosicionAsignada={selectPosicionAsignada}
                  setSelectPosicionAsignada={setSelectPosicionAsignada}
                />
              </ColForm>
            </RowForm>
            <RowFooterButton>
              <ButtonForm
                action={(e) => onPressCancelar()}
                name="Cancelar"
                isAction={true}
                typeColor="warning"
              />
              <ButtonForm
                action={(e) => onPressLimpiarCamposCrearEstructura(e)}
                name="Limpiar"
                isAction={true}
                typeColor="primary"
              />
              <ButtonForm
                action={(e) => onPressGrabar(e)}
                name="Grabar"
                isAction={true}
                typeColor="secondary"
              />
            </RowFooterButton>
          </ColFlexForm>
        </Content>
      </ContentRow>

      <ModalConfirmation
        showModal={showModalEstructuraPrincipal}
        closeModal={onCloseModalEstructuraPrincipal}
        title="¿Confirmación?"
        subtitle={
          oEstructuraPrincial === null
            ? ''
            : '¿Usted cuenta con una estructura principal : ' +
              oEstructuraPrincial.sNombreEstructura +
              ' , desea sobrecribirlar por la actual ?'
        }
        action={onConfirmarActualizarPrincipal}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación de la estructura?"
        action={(e) => onConfirmarConcepto(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </Content>
  );
};
