import {
  AdminCreateUserCommand,
  AdminDeleteUserCommand,
  AdminGetUserCommand,
  AdminUpdateUserAttributesCommand,
  ChangePasswordCommand,
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommand,
  ForgotPasswordCommand,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

const regionAWS = process.env.REACT_APP_AWS_REGION;
const accessKeyIdAWS = process.env.REACT_APP_ACCESS_KEY_ID;
const secretAccessKeyAWS = process.env.REACT_APP_SECRET_KEY;
const UserPoolIdAWS = process.env.REACT_APP_COG_POOL_ID;
const clientIdAWS = process.env.REACT_APP_CLIENT_ID;
//  SERVICIO COGNITO

export const checkUserExists = async (username) => {
  const client = new CognitoIdentityProviderClient({
    region: regionAWS,
    credentials: {
      accessKeyId: accessKeyIdAWS,
      secretAccessKey: secretAccessKeyAWS,
    },
  });

  const input = {
    UserPoolId: UserPoolIdAWS, // Reemplaza con tu User Pool ID
    Username: username, // Nombre de usuario a verificar
  };

  const command = new AdminGetUserCommand(input);

  try {
    const response = await client.send(command);
    console.log('El usuario existe. Estado del usuario:', response.UserStatus);
    return response.UserStatus; // Devuelve el estado del usuario si existe
  } catch (error) {
    if (error.name === 'UserNotFoundException') {
      return 'NO EXISTE';
    } else {
      console.error('Error al obtener el usuario:', error);
      throw error; // Otros errores
    }
  }
};

export const signUp = async (email, validar, tenant) => {
  // Configuración del cliente de Cognito
  const client = new CognitoIdentityProviderClient({
    region: regionAWS,
    credentials: {
      accessKeyId: accessKeyIdAWS,
      secretAccessKey: secretAccessKeyAWS,
    },
  });

  try {
    const params = {
      UserPoolId: UserPoolIdAWS, // ID del grupo de usuarios de Cognito
      Username: email, // Nombre de usuario del nuevo usuario
      UserAttributes: [
        {
          Name: 'email',
          Value: email,
        },
        { Name: 'email_verified', Value: 'true' },
      ],
      ClientMetadata: {
        aplicacion: 'empresa',
        tenant: tenant,
      },
      // TemporaryPassword: password, // Contraseña temporal
      // MessageAction: 'RESEND', // Reenviar contraseña
      // MessageAction: 'SUPPRESS', // Evitar que se envíe un correo electrónico automático al usuario
      DesiredDeliveryMediums: ['EMAIL'],
    };
    if (validar === 'FORCE_CHANGE_PASSWORD') {
      params.MessageAction = 'RESEND';
    }

    const command = new AdminCreateUserCommand(params);
    const response = await client.send(command);

    if (response) {
      enviarCorreoDeVerificacion(email);
      return {
        statusCode: response.$metadata.httpStatusCode,
        result: response,
      };
    }
  } catch (err) {
    return { statusCode: err.$response.statusCode, message: err.message };
  }
};

export const deleteUser = async (username) => {
  const client = new CognitoIdentityProviderClient({
    region: regionAWS,
    credentials: {
      accessKeyId: accessKeyIdAWS,
      secretAccessKey: secretAccessKeyAWS,
    },
  });

  const input = {
    UserPoolId: UserPoolIdAWS, // Reemplaza con tu User Pool ID
    Username: username, // Nombre de usuario a verificar
  };
  const command = new AdminDeleteUserCommand(input);

  try {
    await client.send(command);
    return true; // Devuelve el estado del usuario si existe
  } catch (error) {
    return false; // Devuelve el estado del usuario si existe
    // throw error; // Otros errores
  }
};

const enviarCorreoDeVerificacion = async (username) => {
  const client = new CognitoIdentityProviderClient({
    region: regionAWS,
    credentials: {
      accessKeyId: accessKeyIdAWS,
      secretAccessKey: secretAccessKeyAWS,
    },
  });

  const params = {
    UserPoolId: UserPoolIdAWS,
    Username: username,
    UserAttributes: [
      {
        Name: 'email_verified',
        Value: 'true',
      },
    ],
  };

  try {
    const command = new AdminUpdateUserAttributesCommand(params);
    const response = await client.send(command);
    console.log('Correo de verificación enviado:', response);
  } catch (err) {
    console.error('Error al enviar el correo de verificación:', err);
  }
};

export const signIn = async (username, password, tenant) => {
  // Configuración del cliente de Cognito
  const client = new CognitoIdentityProviderClient({
    region: regionAWS,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: regionAWS },
      identityPoolId: UserPoolIdAWS,
    }),
  });

  try {
    let params = {
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId: clientIdAWS,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
      ClientMetadata: {
        aplicacion: 'empresa',
        tenant: tenant,
      },
    };

    // Realiza la solicitud para iniciar sesión
    const command = new InitiateAuthCommand(params);
    const response = await client.send(command);

    if (response.AuthenticationResult) {
      return {
        statusCode: response.$metadata.httpStatusCode,
        name: 'Authentication',
        result: response.AuthenticationResult,
      };
    } else {
      return {
        statusCode: response.$metadata.httpStatusCode,
        name: response.ChallengeName,
        result: response.Session,
      };
    }
  } catch (err) {
    const pattern = /^PreAuthentication failed with error\s*/;
    let message = err.message.replace(pattern, '').trim();
    return {
      statusCode: err.$response.statusCode,
      message: message,
    };
  }
};

export const newPassword = async (username, newPassword, session) => {
  const client = new CognitoIdentityProviderClient({
    region: regionAWS,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: regionAWS },
      identityPoolId: UserPoolIdAWS,
    }),
  });

  try {
    const params = {
      ChallengeName: 'NEW_PASSWORD_REQUIRED',
      ClientId: clientIdAWS, // Reemplaza con tu Client ID
      ChallengeResponses: {
        USERNAME: username,
        NEW_PASSWORD: newPassword,
      },
      Session: session,
    };

    const command = new RespondToAuthChallengeCommand(params);
    const response = await client.send(command);

    return {
      statusCode: response.$metadata.httpStatusCode,
      result: response.AuthenticationResult,
    };
  } catch (err) {
    return { statusCode: err.$response.statusCode, message: err.message };
  }
};

export const refreshSignIn = async (token) => {
  // Configuración del cliente de Cognito
  const client = new CognitoIdentityProviderClient({
    region: regionAWS,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: regionAWS },
      identityPoolId: UserPoolIdAWS,
    }),
  });

  try {
    let params = {
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      ClientId: clientIdAWS,
      AuthParameters: {
        REFRESH_TOKEN: token,
      },
    };
    // Realiza la solicitud para iniciar sesión
    const command = new InitiateAuthCommand(params);
    const response = await client.send(command);

    return { statusCode: response.$metadata.httpStatusCode, result: response.AuthenticationResult };
  } catch (err) {
    return { statusCode: err.$response.statusCode, message: err.message };
  }
};

export const changePasswordC = async (accessToken, previousPassword, proposedPassword) => {
  const client = new CognitoIdentityProviderClient({
    region: regionAWS,
    credentials: {
      accessKeyId: accessKeyIdAWS,
      secretAccessKey: secretAccessKeyAWS,
    },
  });

  try {
    let params = {
      AccessToken: accessToken,
      PreviousPassword: previousPassword,
      ProposedPassword: proposedPassword,
    };
    // Realiza la solicitud para iniciar sesión
    const command = new ChangePasswordCommand(params);
    const response = await client.send(command);

    return { statusCode: response.$metadata.httpStatusCode, result: response };
  } catch (err) {
    return { statusCode: err.$response.statusCode, message: err.message };
  }
};

export const sendForgotPasswordCode = async (username) => {
  try {
    const client = new CognitoIdentityProviderClient({
      region: regionAWS, // Cambia esto a la región de tu User Pool
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: regionAWS },
        identityPoolId: UserPoolIdAWS,
      }),
    });

    const command = new ForgotPasswordCommand({
      ClientId: clientIdAWS, // ID del cliente de tu User Pool
      Username: username,
    });

    const response = await client.send(command);
    return { statusCode: response.$metadata.httpStatusCode, result: response };
  } catch (error) {
    return { statusCode: error.$response.statusCode, message: error.message };
  }
};

export const resetPassword = async (username, code, newPassword) => {
  try {
    const client = new CognitoIdentityProviderClient({
      region: regionAWS, // Cambia esto a la región de tu User Pool
    });
    const command = new ConfirmForgotPasswordCommand({
      ClientId: clientIdAWS, // ID del cliente de tu User Pool
      Username: username,
      ConfirmationCode: code,
      Password: newPassword,
    });

    const response = await client.send(command);
    return { statusCode: response.$metadata.httpStatusCode, result: response };
  } catch (error) {
    return { statusCode: error.$response.statusCode, message: error.message };
  }
};
//  SERVICIO COGNITO
