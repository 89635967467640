import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../../../../../../common/hooks';
import {
  onAddFiltroDetalleFlujoCaja,
  addListaSucesosModificados,
  onDeleteFiltroDetalleFlujoCaja,
  onLimpiarCamposCrearFlujoCaja,
  onLimpiarFiltroDetalleFlujoCaja,
  onOpenModalCancelarCrearFlujoCaja,
  onOpenModalFiltrosDetalleFlujoCaja,
  onOpenModalMoverFechaM,
} from '../../../../../../../redux/states';
import { ButtonForm, Input, LabelForm, SubTitle } from '../../../../../../../components';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { onlyNumbers, formatString_DDMMYYYY_TO_YYYYMMDD } from '../../../../../../../common';
import { obtenerCaracteristicasXPosicion, registrarRegistroM } from '../../../../../../../services';
import Swal from 'sweetalert2';
import {
  ColFlex,
  ContentForm,
  DatePickerForm,
  RowContent,
  RowFlex,
} from '../../../../../../../styles';
export const ModalMoverFecha = (props) => {
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [rowsKeyData, setRowsKeyData] = useState([]);
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const { oFlujoCajaAgregar, dDateRange } = flujoCajaState;
  var initialFormMoverFechaM = {
    dateMoverFechaM: null,
  };
  const [formMoverFechaM, setFormMoverFechaM] = useState(initialFormMoverFechaM);
  const handleChangeFechaRegistroM = (oEvent) => {
    const formatDate = moment(oEvent[0]).format('DD/MM/YYYY');
    const dateFormat = formatDate.replaceAll('/', '.');
    let oParam = {
      dateMoverFechaM: dateFormat,
    };
    setFormMoverFechaM({ ...formMoverFechaM, ...oParam });
  };
  const onCloseModal = (e) => {
    // openModalFiltrosDetalleFlujoCaja(false);
    dispatch(onOpenModalMoverFechaM(false));
  };
  useEffect(() => {
    (async () => {
      if (!flujoCajaState.modalOpenModalMoverFechaM) {
        setFormMoverFechaM(initialFormMoverFechaM);
      }
    })();
  }, [flujoCajaState.modalOpenModalMoverFechaM]);
  const agregarFiltro = (oEvent) => {
    var oParam = {
      iIdPosicion: 0,
      sPosicion: '',
      iIdCaracteristica: 0,
      sCaracteristica: '',
      listaValor: [
        {
          iIdValor: 0,
          sValor: '',
        },
      ],
    };
    // addFiltroDetalleFlujoCaja(oParam)
    dispatch(onAddFiltroDetalleFlujoCaja(oParam));
  };
  const onPressGrabar = async () => {
    if (formMoverFechaM.dateMoverFechaM !== '') {
      const rowsInput = [...props.finalArray];
      var detalleFlujoCajaCabeceras = flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
      var aItems = [];
      var sFecha = formatString_DDMMYYYY_TO_YYYYMMDD(formMoverFechaM.dateMoverFechaM, '.');
      var indexLlegada = -1;
      for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
        const element = detalleFlujoCajaCabeceras[index];
        if (element.sFechaMin <= sFecha && sFecha <= element.sFechaMax) {
          indexLlegada = index + 1;
        }
      }
      let indexFind = -1;
      props.objRightClick.oItem.Items.find(function (item, i) {
        if (item.Id === props.objRightClick.item2.Id) {
          indexFind = i;
          return i;
        }
      });
      var indexPartida = indexFind + 1;
      if (indexPartida === indexLlegada) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'No se puede elegir la misma fecha destino.',
          showConfirmButton: false,
          timer: 3500,
        });
        return;
      }
      let finalArraySinFiltro = [];
      props.finalArraySinFiltro.forEach((element) => {
        var y = {};
        y = { ...element };
        finalArraySinFiltro.push(y);
      });
      var indexPadre = props.objRightClick.indexPadre;
      var oItem = null;
      var sFechaMax = props.obtenerFecha(null, null, indexLlegada, oItem, indexPadre, rowsInput);
      var resultHijos = props.obtenerHijos(rowsInput, rowsInput[indexPadre].Id);
      var todosRegNoManuales = props.buscarTodosRegNoManuales(
        resultHijos,
        indexPartida,
        indexLlegada
      );
      if (todosRegNoManuales) {
        // Swal.fire({
        //   position: "center",
        //   icon: 'error',
        //   title: 'Todos los registros no han sido ingresado manualmente.',
        //   showConfirmButton: false,
        //   timer: 3500,
        // });
        // return;
      }
      let resultHijosManuales = resultHijos.filter((element) => {
        return element.IdentificadorCPR === 'R'; //&& element.EsManual === 1
      });
      // var resultHijosNoManuales = resultHijos.filter((element) => {
      //   return element.IdentificadorCPR === "R" && element.EsManual === 0
      // });
      var acumulador = 0;
      resultHijosManuales.forEach((element) => {
        acumulador = acumulador + element.Items[indexPartida - 1].Valor;
      });
      // if( !(rowsInput[indexPadre].IdentificadorCPR === "R" && rowsInput[indexPadre].EsManual === 1) ){
      if (!(rowsInput[indexPadre].IdentificadorCPR === 'R')) {
        if (acumulador === 0) {
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor =
            rowsInput[indexPadre].Items[indexLlegada - 1].Valor +
            rowsInput[indexPadre].Items[indexPartida - 1].Valor;
          rowsInput[indexPadre].Items[indexPartida - 1].Valor = 0;
        } else {
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor =
            rowsInput[indexPadre].Items[indexLlegada - 1].Valor + acumulador;
          rowsInput[indexPadre].Items[indexPartida - 1].Valor =
            rowsInput[indexPadre].Items[indexPartida - 1].Valor - acumulador;
        }
      } else {
        rowsInput[indexPadre].Items[indexLlegada - 1].Valor =
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor +
          rowsInput[indexPadre].Items[indexPartida - 1].Valor;
        rowsInput[indexPadre].Items[indexPartida - 1].Valor = 0;
        let {
          EsGrupo: EsGrupoLlegada,
          EsManual: EsManualLlegada,
          EsRaiz: EsRaizLlegada,
          EsSplit: EsSplitLlegada,
          bEditable: bEditableLlegada,
        } = rowsInput[indexPadre].Items[indexLlegada - 1];
        let {
          EsGrupo: EsGrupoPartida,
          EsManual: EsManualPartida,
          EsRaiz: EsRaizPartida,
          EsSplit: EsSplitPartida,
          bEditable: bEditablePartida,
        } = rowsInput[indexPadre].Items[indexPartida - 1];
        rowsInput[indexPadre].Items[indexLlegada - 1].EsGrupo = EsGrupoPartida;
        rowsInput[indexPadre].Items[indexLlegada - 1].EsManual = EsManualPartida;
        rowsInput[indexPadre].Items[indexLlegada - 1].EsRaiz = EsRaizPartida;
        rowsInput[indexPadre].Items[indexLlegada - 1].EsSplit = EsSplitPartida;
        rowsInput[indexPadre].Items[indexLlegada - 1].bEditable = bEditablePartida;
        rowsInput[indexPadre].Items[indexPartida - 1].EsGrupo = EsGrupoLlegada;
        rowsInput[indexPadre].Items[indexPartida - 1].EsManual = EsManualLlegada;
        rowsInput[indexPadre].Items[indexPartida - 1].EsRaiz = EsRaizLlegada;
        rowsInput[indexPadre].Items[indexPartida - 1].EsSplit = EsSplitLlegada;
        rowsInput[indexPadre].Items[indexPartida - 1].bEditable = bEditableLlegada;
        let array = props.actualizarFinalArraySinFiltro(
          finalArraySinFiltro,
          rowsInput[indexPadre],
          rowsInput[indexPadre].Id_Partida3,
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor,
          sFecha,
          indexPartida,
          indexLlegada
        );
        props.setFinalArraySinFiltro(array);
        props.addUpdatedSucesModificados(
          rowsInput[indexPadre].Id_Partida3,
          rowsInput[indexPadre].Items[indexLlegada - 1].Valor,
          sFechaMax
        );
      }
      rowsInput[indexPadre].sAccion = 'M';
      rowsInput[indexPadre].sFecha = sFecha;
      rowsInput[indexPadre].posiCabecera = indexLlegada - 1;
      var aRegistrosEncontrados = resultHijos.filter((element) => {
        return element.IdentificadorCPR === 'P';
      });
      aRegistrosEncontrados.forEach((element) => {
        element.sFecha = sFecha;
      });
      let array = finalArraySinFiltro;
      for (let w = 0; w < resultHijos.length; w++) {
        const linea = resultHijos[w];
        // if( (linea.IdentificadorCPR === "R" && linea.EsManual === 1) ){
        if (linea.IdentificadorCPR === 'R' && linea.Items[indexPartida - 1].Valor != 0) {
          linea.Items[indexLlegada - 1].Valor =
            linea.Items[indexLlegada - 1].Valor + linea.Items[indexPartida - 1].Valor;
          linea.Items[indexPartida - 1].Valor = 0;
          linea.sFecha = sFecha;
          linea.sAccion = 'M';
          linea.posiCabecera = indexLlegada - 1;
          let {
            EsGrupo: EsGrupoLlegada,
            EsManual: EsManualLlegada,
            EsRaiz: EsRaizLlegada,
            EsSplit: EsSplitLlegada,
            bEditable: bEditableLlegada,
          } = linea.Items[indexLlegada - 1];
          let {
            EsGrupo: EsGrupoPartida,
            EsManual: EsManualPartida,
            EsRaiz: EsRaizPartida,
            EsSplit: EsSplitPartida,
            bEditable: bEditablePartida,
          } = linea.Items[indexPartida - 1];
          linea.Items[indexLlegada - 1].EsGrupo = EsGrupoPartida;
          linea.Items[indexLlegada - 1].EsManual = EsManualPartida;
          linea.Items[indexLlegada - 1].EsRaiz = EsRaizPartida;
          linea.Items[indexLlegada - 1].EsSplit = EsSplitPartida;
          linea.Items[indexLlegada - 1].bEditable = bEditablePartida;
          linea.Items[indexPartida - 1].EsGrupo = EsGrupoLlegada;
          linea.Items[indexPartida - 1].EsManual = EsManualLlegada;
          linea.Items[indexPartida - 1].EsRaiz = EsRaizLlegada;
          linea.Items[indexPartida - 1].EsSplit = EsSplitLlegada;
          linea.Items[indexPartida - 1].bEditable = bEditableLlegada;
          array = props.actualizarFinalArraySinFiltro(
            array,
            linea,
            linea.Id_Partida3,
            linea.Items[indexLlegada - 1].Valor,
            sFecha,
            indexPartida,
            indexLlegada
          );
          props.addUpdatedSucesModificados(
            linea.Id_Partida3,
            linea.Items[indexLlegada - 1].Valor,
            sFecha
          );
        }
      }
      const rowsInputSaldo = [...props.finalArray].filter((element) => {
        return element.Id.includes('S');
      });
      const rowsInputIngreso = [...props.finalArray].filter((element) => {
        return element.Id.includes('I');
      });
      const rowsInputEgreso = [...props.finalArray].filter((element) => {
        return element.Id.includes('E');
      });
      props.realizarRecalculo(rowsInputSaldo, rowsInput);
      props.realizarRecalculo(rowsInputIngreso, rowsInput);
      props.realizarRecalculo(rowsInputEgreso, rowsInput);
      props.calcularTotalYsubTotal();
      onCloseModal();
      props.setShowGrafico(false);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Seleccione la fecha a que desea mover.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  const handleChangeValor = (index, event) => {
    let sValor = event.target.value;
    let validate = null;
    if (sValor.length > 0) {
      validate = true;
    }
    let oParam = {
      sValor: sValor,
      validate: validate,
      rows: rowsKeyData,
      index: index,
      setRowsKeyData: setRowsKeyData,
    };
    const rows = [...rowsKeyData];
    rows[index]['valor'] = sValor;
    rows[index]['validate'] = validate;
    setRowsKeyData(rows);
  };
  return (
    <ModalComponent
      backdrop="static"
      keyboard={false}
      show={flujoCajaState.modalOpenModalMoverFechaM}
      onHide={onCloseModal}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Mover Fecha</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <RowContent>
          <ColFlex>
            <ContentForm>
              <RowFlex>
                <LabelForm title="Fecha Destino:" weight="bold"></LabelForm>
                <DatePickerForm
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  isClearable={true}
                  placeholderText="Ingrese"
                  locale="es"
                  showMonthDropdown
                  showYearDropdown
                  portalId="root-portal"
                  value={formMoverFechaM.dateMoverFechaM}
                  minDate={dDateRange[0]}
                  maxDate={dDateRange[1]}
                  onChange={(evnt) => handleChangeFechaRegistroM(evnt)}
                />
              </RowFlex>
            </ContentForm>
          </ColFlex>
        </RowContent>
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <ButtonForm
          action={(e) => onPressGrabar()}
          name="Aplicar"
          isAction={true}
          typeColor="primary"
        />
        <ButtonForm
          action={(e) => onCloseModal()}
          name="Cancelar"
          isAction={false}
          typeColor="warning"
        />
      </ModalComponent.Footer>
    </ModalComponent>
  );
};
const ModalComponent = styled(Modal)`
  /* z-index:99999; */
  .modal-dialog {
    max-width: 500px !important;
    /* max-width : 50vw !important;  */
    /* overflow: auto; */
  }
  .modal-body {
    &:first-child {
      justify-content: center !important;
    }
  }
`;
