import React, { useEffect, useState } from 'react';
import { Avatar, Button, Dropdown, Layout } from 'antd';
import { ColumnConfigure, RowUser, SubTitleUser, TitlePage, TitleUser } from '../../../styles';
import logoCollapsed from '../../../assets/images/icoZCF2.png';
import { useAuth, paths } from '../../../common';
import { useNavigate } from 'react-router-dom';
import { items } from './misc';
import { BsPerson } from 'react-icons/bs';
import { obtenerUrlImg } from '../../../services';

const { Header } = Layout;

const MainHeader = (props) => {
  const navigate = useNavigate();
  const { user, title, logout } = useAuth();
  const [fotoUsuario, setFotoUsuario] = useState('');

  useEffect(() => {
    if (user.foto && user.foto !== '') {
      (async () => {
        const foto = await obtenerUrlImg(user.foto);
        setFotoUsuario(foto);
      })();
    }
  }, [user]);

  const onLogout = () => {
    logout();
    navigate(paths.LOGIN);
  };

  return (
    <Header
      style={{
        height: '50px',
        padding: 0,
        background: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '50px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
          <Button
            type="text"
            onClick={() => props.setCollapsed(!props.collapsed)}
            style={{
              fontSize: '16px',
              width: 50,
              height: 50,
            }}
          >
            <img src={logoCollapsed} alt="Logo zCFlow" style={{ height: '60%' }}></img>
          </Button>
          {/* <TitlePage>{navBarState.title}</TitlePage> */}
          <TitlePage>{title}</TitlePage>
        </div>

        <ColumnConfigure>
          <RowUser>
            <TitleUser>BIENVENID@ {user.name.toUpperCase()}</TitleUser>
            <SubTitleUser> {user.tenant.toUpperCase()}</SubTitleUser>
          </RowUser>
          <Dropdown trigger={['click']} menu={{ items: items(onLogout) }}>
            {fotoUsuario === '' ? (
              <Avatar
                icon={<BsPerson />}
                style={{ width: '40px', height: '40px', cursor: 'pointer' }}
              />
            ) : (
              <Avatar
                src={fotoUsuario}
                style={{ width: '40px', height: '40px', cursor: 'pointer' }}
              />
            )}
          </Dropdown>
          {/* Avatar alineado a la derecha */}
        </ColumnConfigure>
      </div>
    </Header>
  );
};
export default MainHeader;
