import React, { useEffect, useState } from 'react';
import { ColForm, Content, RowFooterButton, RowForm, StyledCalendar } from '../../../styles';
import { columns } from './misc';
import { v4 as uuidv4 } from 'uuid';
import { ButtonForm, CheckBoxForm, LabelForm, SelectForm, TableGrid } from '../../../components';
import { guardarFechas, listarSociedad, obtenerFechas } from '../../../services';
import { useFetchAndLoad } from '../../../common';
import Swal from 'sweetalert2';

export const Calendario = () => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDateArra, setSelectedDatesArra] = useState([]);
  const [selectSociedad, setSelectSociedad] = useState([]);
  const [selectSabado, setSelectSabado] = useState(false);
  const [selectDomingo, setSelectDomingo] = useState(false);
  const [listaFecha, setListaFecha] = useState([]);
  const [listaSociedad, setListaSociedad] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();

  useEffect(() => {
    (async () => {
      await loadFecha('');
      await loadSociedad();
    })();
  }, []);

  const loadFecha = async (sociedad) => {
    const responseFecha = await callEndpoint(obtenerFechas());
    let listaFilterDate = [],
      listaFechas = [];
    for (let index = 0; index < responseFecha.data.respuesta.listFecha.length; index++) {
      const element = responseFecha.data.respuesta.listFecha[index];
      listaFechas.push(element);
    }
    setListaFecha(listaFechas);
    let listaFechaFilter = listaFechas.filter((r) => r.Sociedad === sociedad);
    listaFechaFilter.map((r) => listaFilterDate.push(r.Fecha));
    setSelectedDatesArra(listaFechaFilter);
    setSelectedDates(listaFilterDate);
  };

  const loadSociedad = async () => {
    const responseListaCampos = await callEndpoint(listarSociedad());
    var listaCampos = [];
    for (let index = 0; index < responseListaCampos.data.respuesta.listSociedad.length; index++) {
      const element = responseListaCampos.data.respuesta.listSociedad[index];
      element.value = element.IDSociedad;
      element.label = element.Sociedad;
      listaCampos.push(element);
    }
    setListaSociedad(listaCampos);
  };

  const toggleDate = (date) => {
    const formattedDate = date.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    let uid = uuidv4();

    setSelectedDates((prevDates) => {
      if (prevDates.includes(formattedDate)) {
        return prevDates.filter((d) => d !== formattedDate); // Quita la fecha si ya está seleccionada
      } else {
        return [...prevDates, formattedDate]; // Añade la fecha si no está seleccionada
      }
    });
    setSelectedDatesArra((prevDates) => {
      if (prevDates.some((obj) => obj.Fecha === formattedDate)) {
        return prevDates.filter((d) => d.Fecha !== formattedDate); // Quita la fecha si ya está seleccionada
      } else {
        return [...prevDates, { FechaID: uid, Fecha: formattedDate }]; // Añade la fecha si no está seleccionada
      }
    });
  };

  const isDateSelected = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    return selectedDates.includes(formattedDate);
  };

  const tileClassName = ({ date, view }) => {
    // Añade una clase CSS para las fechas seleccionadas
    if (view === 'month' && isDateSelected(date)) {
      return 'selected-date';
    }
    return null;
  };

  const onPressGrabar = async () => {
    let listaFecha = [];
    let sociedad = '';

    selectedDateArra.map((r) => {
      listaFecha.push(r.Fecha);
    });
    if (selectSociedad.length > 0) {
      sociedad = selectSociedad[0].label;
    }
    let obj = {
      Sociedad: sociedad,
      Fechas: listaFecha,
    };
    const responseGuardarFecha = await callEndpoint(guardarFechas(obj));
    if (responseGuardarFecha.data.cabecera.statusCode === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: responseGuardarFecha.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
      if (selectSociedad.length > 0) {
        loadFecha(selectSociedad[0].label);
      } else loadFecha('');
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: responseGuardarFecha.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleChangeSociedad = (e) => {
    if (e) {
      setSelectSociedad([e]);
      let listaFilterDate = [];
      let listaFechaFilter = listaFecha.filter((r) => r.Sociedad === e.label);
      listaFechaFilter.map((r) => listaFilterDate.push(r.Fecha));
      setSelectedDatesArra(listaFechaFilter);
      setSelectedDates(listaFilterDate);
    } else {
      setSelectSociedad([]);
      let listaFilterDate = [];
      let listaFechaFilter = listaFecha.filter((r) => r.Sociedad === '');
      listaFechaFilter.map((r) => listaFilterDate.push(r.Fecha));
      setSelectedDatesArra(listaFechaFilter);
      setSelectedDates(listaFilterDate);
    }
  };

  const getSaturdaysAfter12Months = () => {
    const today = new Date();

    // Avanzar 12 meses desde la fecha actual
    // const startDate = new Date(today.getFullYear(), today.getMonth() + 12, 1);

    // Crear un array para guardar las fechas de sábados
    const saturdays = [];

    // Iterar desde el inicio de la fecha (12 meses adelante) hasta el fin del año siguiente
    let currentDate = new Date(today);

    while (currentDate.getFullYear() <= today.getFullYear() + 1) {
      // Verificar si es sábado (día 6 en JavaScript)
      if (currentDate.getDay() === 6) {
        // Formatear la fecha como yyyy-MM-DD
        const formattedDate = currentDate.toISOString().split('T')[0];
        saturdays.push(formattedDate);
      }

      // Incrementar en un día
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return saturdays;
  };

  const handleChangeSabado = () => {
    const sabados = getSaturdaysAfter12Months(); // Obtener sábados una vez

    if (!selectSabado) {
      // Si está en true, guardar todos los sábados
      setSelectedDates((prevDates) => {
        const updatedDates = new Set(prevDates); // Crear un Set para evitar duplicados
        sabados.forEach((formattedDate) => updatedDates.add(formattedDate));
        return Array.from(updatedDates); // Retornar como array
      });

      setSelectedDatesArra((prevDates) => {
        const updatedDatesArray = [...prevDates]; // Copiar el arreglo actual
        sabados.forEach((formattedDate) => {
          const exists = updatedDatesArray.some((obj) => obj.Fecha === formattedDate);
          if (!exists) {
            const uid = crypto.randomUUID(); // Generar un UID único
            updatedDatesArray.push({ FechaID: uid, Fecha: formattedDate });
          }
        });
        return updatedDatesArray;
      });
    } else {
      // Si está en false, eliminar todos los sábados
      setSelectedDates((prevDates) => prevDates.filter((date) => !sabados.includes(date)));

      setSelectedDatesArra((prevDates) => prevDates.filter((obj) => !sabados.includes(obj.Fecha)));
    }

    // Cambiar estado de selectSabado
    setSelectSabado((prevState) => !prevState);
  };

  const getSundaysAfter12Months = () => {
    const today = new Date();

    // Avanzar 12 meses desde la fecha actual
    // const startDate = new Date(today.getFullYear(), today.getMonth() + 12, 1);

    // Crear un array para guardar las fechas de domingos
    const sundays = [];

    // Iterar desde el inicio de la fecha (12 meses adelante) hasta el fin del año siguiente
    let currentDate = new Date(today);

    while (currentDate.getFullYear() <= today.getFullYear() + 1) {
      // Verificar si es domingo (día 0 en JavaScript)
      if (currentDate.getDay() === 0) {
        // Formatear la fecha como yyyy-MM-DD
        const formattedDate = currentDate.toISOString().split('T')[0];
        sundays.push(formattedDate);
      }

      // Incrementar en un día
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return sundays;
  };

  const handleChangeDomingo = () => {
    const domingos = getSundaysAfter12Months(); // Obtener domingos una vez

    if (!selectDomingo) {
      // Si está en true, guardar todos los domingos
      setSelectedDates((prevDates) => {
        const updatedDates = new Set(prevDates); // Crear un Set para evitar duplicados
        domingos.forEach((formattedDate) => updatedDates.add(formattedDate));
        return Array.from(updatedDates); // Retornar como array
      });

      setSelectedDatesArra((prevDates) => {
        const updatedDatesArray = [...prevDates]; // Copiar el arreglo actual
        domingos.forEach((formattedDate) => {
          const exists = updatedDatesArray.some((obj) => obj.Fecha === formattedDate);
          if (!exists) {
            const uid = crypto.randomUUID(); // Generar un UID único
            updatedDatesArray.push({ FechaID: uid, Fecha: formattedDate });
          }
        });
        return updatedDatesArray;
      });
    } else {
      // Si está en false, eliminar todos los domingos
      setSelectedDates((prevDates) => prevDates.filter((date) => !domingos.includes(date)));

      setSelectedDatesArra((prevDates) => prevDates.filter((obj) => !domingos.includes(obj.Fecha)));
    }

    // Cambiar estado de selectDomingo
    setSelectDomingo((prevState) => !prevState);
  };

  return (
    <Content>
      <RowForm gutter={[8, 8]}>
        <ColForm xs={12} sm={12} md={12} lg={6} xl={6}>
          <RowForm>
            <LabelForm title={'Sociedad: '}></LabelForm>
            <SelectForm
              value={selectSociedad}
              handleChange={(e, v) => handleChangeSociedad(v)}
              options={listaSociedad}
              placeholder={'Seleccione Sociedad'}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={6} sm={6} md={6} lg={2} xl={2}>
          <RowForm>
            <LabelForm title={'Todos los sabados:'}></LabelForm>
            <CheckBoxForm checked={selectSabado} onChange={handleChangeSabado} />
          </RowForm>
        </ColForm>
        <ColForm xs={6} sm={6} md={6} lg={2} xl={2}>
          <RowForm>
            <LabelForm title={'Todos los domingos:'}></LabelForm>
            <CheckBoxForm checked={selectDomingo} onChange={handleChangeDomingo} />
          </RowForm>
        </ColForm>
      </RowForm>
      <RowForm gutter={[8, 8]}>
        <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
          <StyledCalendar onClickDay={toggleDate} tileClassName={tileClassName} />
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={12} xl={12}>
          <TableGrid
            columns={columns}
            rows={selectedDateArra}
            pageSize={10}
            getRowId={(row) => row.FechaID}
          />
        </ColForm>
      </RowForm>
      <RowFooterButton>
        <ButtonForm
          action={(e) => onPressGrabar()}
          name="Grabar"
          isAction={true}
          typeColor="secondary"
        />
      </RowFooterButton>
    </Content>
  );
};
