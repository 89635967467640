import React from 'react';

import styled from 'styled-components';
export const SelectOptionActionButton = (props) => (
  <ButtonStandar onClick={props.action} isAction={props.isAction}>
    {props.icon ? <IconButton>{props.icon}</IconButton> : <></>}
  </ButtonStandar>
);
const ButtonStandar = styled.button`
  font-family: UbuntuBold;
  font-size: 12px;
  background: transparent;
  padding: 6px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  gap: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    transform: scale(1.09);
  }
`;
const IconButton = styled.div`
  align-items: center;
`;
