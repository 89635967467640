import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createEtiquetaMAdapter } from '../../../../../../adapters';
import {
  ButtonForm,
  LoadingSpinner,
  ModalConfirmation,
  TableGrid,
} from '../../../../../../components';
import { useFetchAndLoad } from '../../../../../../common/hooks';
import {
  changeRowsKeyValueDataEtiquetas,
  changeRowsKeyValueInputDescripcionDataEtiquetas,
  changeRowsKeyValueInputNombreDataEtiquetas,
  onAddRowtableEtiquetas,
  onDeleteRowTableEtiquetas,
  setLimpiarListarEtiquetasEliminadasManual,
  setListaEtiquetas,
  setModalOpenEliminarEtiqueta,
  setModalOpenGuardarEtiqueta,
  setSelectedEtiqueta,
} from '../../../../../../redux/states';
import {
  eliminarEtiquetaMasivo,
  modificarEtiqueta,
  obtenerListadoEtiquetas,
  registrarEtiqueta,
} from '../../../../../../services';
import { RowButton } from '../../../../../../styles';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { FaMinus, FaPenToSquare, FaPlus, FaRegFloppyDisk } from 'react-icons/fa6';

export const ListarEtiqueta = () => {
  const dispatch = useDispatch();
  const manualState = useSelector((store) => store.registroManual);
  const { loading, callEndpoint } = useFetchAndLoad();

  useEffect(() => {
    (async () => {
      loadEtiquetasM();
    })();
  }, []);

  const loadEtiquetasM = async () => {
    const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());
    var listarEtiquetas = [];
    for (
      let index = 0;
      index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
      index++
    ) {
      const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
      var obj = createEtiquetaMAdapter(element);
      listarEtiquetas.push(obj);
    }
    let objEtiqueta = {
      listarEtiquetas: listarEtiquetas,
    };
    dispatch(setListaEtiquetas(objEtiqueta));
  };

  const addTableRows = () => {
    var uuid = uuidv4();
    dispatch(
      onAddRowtableEtiquetas({
        newItem: {
          IDEtiqueta: uuid,
          NombreEtiqueta: '',
          isDisabled: false,
          DescripcionEtiqueta: '',
          create: 0,
        },
      })
    );
  };

  const deleteTableRows = (id) => {
    dispatch(onDeleteRowTableEtiquetas(id));
  };
  const validarNombre = (NombreEtiqueta) => {
    return manualState.listarEtiquetas.filter(
      (p) => p.NombreEtiqueta.trim() === NombreEtiqueta.trim()
    );
  };

  const editTableRow = (id) => {
    const element = manualState.listarEtiquetas.filter((r) => r.IDEtiqueta === id);
    if (!element[0].isDisabled) {
      var aEncontrado = validarNombre(element[0].NombreEtiqueta);
      if (aEncontrado.length > 0) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title:
            'Existen un registro en la tabla de etiquetas que posee el mismo nombre. Por favor, cambiarlo.',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        dispatch(changeRowsKeyValueDataEtiquetas(id));
      }
    } else {
      dispatch(changeRowsKeyValueDataEtiquetas(id));
    }
  };
  const saveRowItem = async (e, id) => {
    dispatch(setSelectedEtiqueta({ selectEtiqueta: id }));
    dispatch(setModalOpenGuardarEtiqueta({ modalOpenGuardarEtiqueta: true }));
  };

  const changeNombreRow = (e, id) => {
    dispatch(
      changeRowsKeyValueInputNombreDataEtiquetas({ id: id, NombreEtiqueta: e.target.value })
    );
  };
  const changeDescripcionRow = (e, id) => {
    dispatch(
      changeRowsKeyValueInputDescripcionDataEtiquetas({
        id: id,
        DescripcionEtiqueta: e.target.value,
      })
    );
  };

  const onPressEliminar = async (data) => {
    // dispatch(setModalOpenEliminarEtiqueta({modalOpenEliminarEtiqueta : false}))
    // const data = [...manualState.listarEtiquetas][manualState.selectEtiqueta];
    if (data.length > 0) {
      var oParam = {
        sAccion: 'D',
        IDEtiqueta: manualState.listarEtiquetasEliminadas.map(function (item, index) {
          return item.IDEtiqueta;
        }),
      };
      const responseEliminarEtiquetaMasivo = await callEndpoint(eliminarEtiquetaMasivo(oParam));
      if (responseEliminarEtiquetaMasivo === null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (responseEliminarEtiquetaMasivo.data.cabecera.statusCode === 200) {
          dispatch(
            setLimpiarListarEtiquetasEliminadasManual({
              listarEtiquetasEliminadas: [],
            })
          );
          dispatch(setModalOpenEliminarEtiqueta({ modalOpenGuardarMultiEtiqueta: false }));
          dispatch(setSelectedEtiqueta({ selectEtiqueta: null }));
          loadEtiquetasM();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseEliminarEtiquetaMasivo.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseEliminarEtiquetaMasivo.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    }
  };

  const onPressGuardar = async (e) => {
    const data = manualState.listarEtiquetas.filter(
      (r) => r.IDEtiqueta === manualState.selectEtiqueta
    );
    dispatch(setSelectedEtiqueta({ selectEtiqueta: null }));
    dispatch(setModalOpenGuardarEtiqueta({ modalOpenGuardarEtiqueta: false }));
    if (data[0].create != 0) {
      var oParam = {
        sAccion: 'U',
        IDEtiqueta: data[0].IDEtiqueta,
        NombreEtiqueta: data[0].NombreEtiqueta,
        DescripcionEtiqueta: data[0].DescripcionEtiqueta,
      };
      const modificarEtiquetaResponse = await callEndpoint(modificarEtiqueta(oParam));
      if (modificarEtiquetaResponse === null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (modificarEtiquetaResponse.data.cabecera.statusCode === 200) {
          loadEtiquetasM();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: modificarEtiquetaResponse.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: modificarEtiquetaResponse.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      var oParam =
        // {
        //   "sAccion" : "C",
        //    "IDEtiqueta": data.IDEtiqueta,
        //             "NombreEtiqueta": data.NombreEtiqueta,
        //             "DescripcionEtiqueta": data.DescripcionEtiqueta
        // }
        {
          RegistroEtiqueta: {
            sNombreEtiqueta: data[0].NombreEtiqueta,
            sDescripcionEtiqueta: data[0].DescripcionEtiqueta,
          },
        };
      const registrarEtiquetaResponse = await callEndpoint(registrarEtiqueta(oParam));
      if (registrarEtiquetaResponse === null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (registrarEtiquetaResponse.data.cabecera.statusCode === 200) {
          loadEtiquetasM();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: registrarEtiquetaResponse.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: registrarEtiquetaResponse.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    }
    // dispatch(setSelectedEtiqueta({selectEtiqueta:null}))
    // dispatch(setModalOpenGuardarEtiqueta({modalOpenGuardarEtiqueta : false}))
  };

  const validarRowsEditables = () => {
    let rowsEditable = false;
    return manualState.listarEtiquetasEliminadas.length > 0 || rowsEditable;
  };

  const columns = [
    {
      field: 'NombreEtiqueta',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.isDisabled ? (
            <span>{params.value}</span>
          ) : (
            <input value={params.value} onChange={(e) => changeNombreRow(e, params.id)}></input>
          )}
        </>
      ),
    },
    {
      field: 'DescripcionEtiqueta',
      headerName: 'Descripción',
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.isDisabled ? (
            <span>{params.value}</span>
          ) : (
            <input
              value={params.value}
              onChange={(e) => changeDescripcionRow(e, params.id)}
            ></input>
          )}
        </>
      ),
    },

    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderHeader: (params) => (
        <>
          {validarRowsEditables() ? (
            <RowButton>
              <ButtonForm
                action={addTableRows}
                icon={<FaPlus />}
                noBackground={true}
                isAction={false}
                isOnlyIcon={true}
                typeColor="secondary"
              />
              <ButtonForm
                action={(e) => {
                  dispatch(setModalOpenEliminarEtiqueta({ modalOpenGuardarMultiEtiqueta: true }));
                }}
                icon={<FaRegFloppyDisk />}
                noBackground={true}
                isAction={false}
                isOnlyIcon={true}
                typeColor="secondary"
              />
            </RowButton>
          ) : (
            <RowButton>
              <ButtonForm
                action={addTableRows}
                noBackground={true}
                icon={<FaPlus />}
                isAction={false}
                isOnlyIcon={true}
                typeColor="secondary"
              />
            </RowButton>
          )}
        </>
      ),
      renderCell: (params) => (
        <>
          {!params.row.isDisabled ? (
            <RowButton>
              <ButtonForm
                action={() => editTableRow(params.id)}
                icon={<FaPenToSquare />}
                isAction={false}
                isOnlyIcon={true}
                typeColor="primary"
              />
              <ButtonForm
                action={() => deleteTableRows(params.id)}
                icon={<FaMinus />}
                isAction={false}
                isOnlyIcon={true}
                typeColor="primary"
              />
              <ButtonForm
                icon={<FaRegFloppyDisk />}
                action={(e) => saveRowItem(e, params.id)}
                isAction={false}
                isOnlyIcon={true}
                typeColor="primary"
              />
            </RowButton>
          ) : (
            <RowButton>
              <ButtonForm
                action={() => editTableRow(params.id)}
                icon={<FaPenToSquare />}
                isAction={false}
                isOnlyIcon={true}
                typeColor="primary"
              />
              <ButtonForm
                action={() => deleteTableRows(params.id)}
                icon={<FaMinus />}
                isAction={false}
                isOnlyIcon={true}
                typeColor="primary"
              />
            </RowButton>
          )}
        </>
      ),
    },
  ];

  return loading === true ? (
    <LoadingSpinner />
  ) : (
    <>
      {/* <AddDeleteTableRowsEtiqueta
        loadEtiquetasM={loadEtiquetasM}
        // rowsData={manualState.listarEtiquetas}
        // setRowsData={setRowsData}
      /> */}

      <TableGrid
        columns={columns}
        rows={manualState.listarEtiquetas}
        pageSize={15}
        getRowId={(row) => row.IDEtiqueta}
        sx={{
          '@media (min-width:1250px)': {
            width: '45%',
          },
        }}
      />

      <ModalConfirmation
        showModal={manualState.modalOpenGuardarEtiqueta}
        closeModal={(e) =>
          dispatch(setModalOpenEliminarEtiqueta({ modalOpenGuardarEtiqueta: false }))
        }
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea guardar la etiqueta?"
        action={(e) => onPressGuardar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      <ModalConfirmation
        showModal={manualState.modalOpenGuardarMultiEtiqueta}
        closeModal={(e) =>
          dispatch(setModalOpenEliminarEtiqueta({ modalOpenGuardarMultiEtiqueta: false }))
        }
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea guardar las etiquetas?"
        action={(e) => {
          onPressEliminar(manualState.listarEtiquetasEliminadas);
        }}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
    </>
  );
};
