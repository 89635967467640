import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Input,
  InputNumberFormat,
  LabelForm,
  SubTitle,
  LoadingSpinner,
  ModalConfirmation,
  Button,
  SelectForm,
  ButtonForm,
} from '../../../../components';
import { useFetchAndLoad } from '../../../../common/hooks';
import 'react-datepicker/dist/react-datepicker.css';
import {
  changeAparicionesPR,
  changeDescripcionPR,
  changeFrecMaxPR,
  changeMinimoPR,
  changeCantCuotaPR,
  changeMontoDesembolsoPR,
  changeMontoPR,
  changePeriodoCutoaPR,
  changePeriodoGraciaPR,
  changeTeaPR,
  changeVigenciaPR,
  limpiarCamposPR,
  selectedAgentePR,
  selectedEstadoPR,
  selectedMonedaPR,
  selectedTiempoFrecMaxPR,
  selectedTiempoPeriodoCuotaPR,
  selectedTiempoPeriodoGraciaPR,
  setListaAgentePR,
  setListaEstadoPR,
  setListaMonedaPR,
  updateTitle,
  setDataInfoPR,
  setValidCamposPR,
  limpiarFormPR,
  selectedTipoFomularioPR,
} from '../../../../redux/states';
import {
  editarSolucion,
  obtenerAgentes,
  obtenerInfoSolucion,
  obtenerListadoMoneda,
  simularPrestamo,
} from '../../../../services';
import {
  createAgenteSelectAdapter,
  createEstadoSolucionSelectAdapter,
  createMonedaAbreviaturaAdapter,
  createTiempoPeriodoAdapter,
  simulacionPrestamoAdapter,
} from '../../../../adapters';
import {
  EstadoSolucionesData,
  TipoFormularioPrestamo,
  listaTipoPagoPrestamo,
  paths,
} from '../../../../common';
import { dateTo_dd_mm_yyyy, stringFechaToDate } from '../../../../common';
import Swal from 'sweetalert2';
import { TiempoPeriodosData } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  ColFlex,
  ColForm,
  Content,
  ContentForm,
  DatePickerForm,
  RowDoubleInput,
  RowFooterButton,
  RowForm,
} from '../../../../styles';
import { ModalSimulacion } from './components';

export const EditarPrestamo = (props) => {
  const personalizacionState = useSelector((store) => store.personalizacion);
  const authState = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const prestamoState = useSelector((store) => store.prestamo);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [loadingDetalle, setLoadingDetalle] = useState(true);
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [openSimulacion, setOpenSimulacion] = useState(false);
  const [listaSimulacion, setListaSimulacion] = useState([]);

  useEffect(() => {
    if (personalizacionState.listaPersonalizada.length > 0 && authState.user.uid != '') {
      (async () => {
        dispatch(updateTitle({ title: 'Editar Préstamo' }));
        onObtInfoPrestamo();
      })();
    }
  }, [personalizacionState.listaPersonalizada, authState.user]);

  const onObtInfoPrestamo = async (row) => {
    const responseInfoSolucion = await callEndpoint(
      obtenerInfoSolucion({
        oSolucion: {
          IDSolucionBase: id,
        },
      })
    );
    if (responseInfoSolucion != null) {
      if (responseInfoSolucion.data) {
        dispatch(updateTitle({ title: 'Editar Préstamo' }));
        //  setButtonAction(2);
        var oSolucion = responseInfoSolucion.data.respuesta.aInfoSolucion[0];
        if (oSolucion) {
          const responseObtAgentes = await callEndpoint(obtenerAgentes());
          let aAgentes = [];
          if (responseObtAgentes != null) {
            if (responseObtAgentes.data) {
              let { aListaAgentes } = responseObtAgentes.data.respuesta;
              aListaAgentes.forEach((element) => {
                aAgentes.push(createAgenteSelectAdapter(element));
              });
            }
          }
          const responseObtMonedas = await callEndpoint(obtenerListadoMoneda());
          let aMonedas = [];
          if (responseObtMonedas != null) {
            if (responseObtMonedas.data) {
              let { aListaMoneda } = responseObtMonedas.data.respuesta;
              let tipoMoneda = personalizacionState.listaPersonalizada.filter(
                (v) => v.DescPersonalizacion === 'texto_moneda'
              )[0].DatoPersonalizacion;
              aListaMoneda.forEach((element) => {
                aMonedas.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
              });
            }
          }
          let aEstadoSol = [];
          EstadoSolucionesData.forEach((element) => {
            aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
          });
          let aTiempoPeriodo = [];
          TiempoPeriodosData.forEach((element) => {
            aTiempoPeriodo.push(createTiempoPeriodoAdapter(element));
          });
          var oParam = {
            idSolucion: id,
            selectTipoFormulario: [
              {
                value: oSolucion.COD_TIPO_FORMULARIO,
                label: oSolucion.TIPO_FORMULARIO,
              },
            ],
            descripcion: oSolucion.SOL_NOMBRESOLUCION,
            bValidDescripcion: true,
            selectAgente: [
              {
                value: oSolucion.SOL_IDAGENTE,
                label: oSolucion.SOL_AGENTE,
              },
            ],
            bValidAgente: true,
            listaAgente: aAgentes,
            dDateRange: [
              stringFechaToDate(oSolucion.SOL_PERIODOINICIOP, '.', '-'),
              stringFechaToDate(oSolucion.SOL_PERIODOFINP, '.', '-'),
            ],
            bValidFecha: true,
            monto: oSolucion.SOL_IMPORTEINICIAL.toString(),
            bValidMonto: true,
            cantCuota: oSolucion.SOL_CANTIDADCUOTASP.toString(),
            bValidCantCuota: true,
            montoDes: oSolucion.SOL_PAGODESEMBOLSO.toString(),
            bValidMontoDes: true,
            selectMoneda: [
              {
                value: oSolucion.SOL_MONEDA,
                label: oSolucion.SOL_NOMBRE_MONEDA,
              },
            ],
            bValidMoneda: true,
            listaMoneda: aMonedas,
            peGracia: oSolucion.SOL_DESFASECUOTAP,
            bValidPeGracia: true,
            bValidTiempoPeGracia: true,
            //  selectTiempoPeGracia :[
            //    {
            //      value :  oSolucion.SOL_DESFASECUOTAP_PERIODO,
            //      label : ""
            //    }
            //  ],
            peCuotas: oSolucion.SOL_PERIODOSENTREPAGO,
            bValidCuotas: true,
            bValidTiempoPeCuotas: true,
            selectTiempoPeCuotas: [
              {
                value: oSolucion.SOL_PERIODOSENTREPAGO_PERIODO,
                label: '',
              },
            ],
            tea: oSolucion.SOL_TEAP.toString(),
            bValidTea: true,
            frecMax: oSolucion.SOL_FRECUENCIA.toString(),
            bValidFrecMax: true,
            bValidTiempoFrecMax: true,
            selectTiempoFrecMax: [
              {
                value: oSolucion.SOL_FRECUENCIA_PERIODO,
                label: '',
              },
            ],
            selectFormaPago: listaTipoPagoPrestamo.filter((r) => r.value === oSolucion.TIPO_PAGO),
            apariciones: oSolucion.SOL_APARICIONES.toString(),
            bValidApariciones: true,
            minimo: oSolucion.SOL_IMPORTEINICIALMIN.toString(),
            bValidMinimo: true,
            // maximo : "",
            // bValidMaximo : null,
            selectEstado: [
              {
                value: oSolucion.IDESTADO,
                label: oSolucion.DESCESTADO,
              },
            ],
            bValidEstado: true,
            listaEstado: aEstadoSol,
            listaTiempo: aTiempoPeriodo,
          };
          //  if(oParam.selectTiempoPeGracia.length>0){
          //    let valor = oParam.selectTiempoPeGracia[0].value;
          //    if(valor === "D"){
          //      oParam.selectTiempoPeGracia[0].label = "Días";
          //    }else if(valor === "S"){
          //      oParam.selectTiempoPeGracia[0].label = "Semana";
          //    }else if(valor === "M"){
          //      oParam.selectTiempoPeGracia[0].label = "Mensual";
          //    }
          //  }
          if (oParam.selectTiempoPeCuotas.length > 0) {
            let valor = oParam.selectTiempoPeCuotas[0].value;
            if (valor === 'D') {
              oParam.selectTiempoPeCuotas[0].label = 'Días';
            } else if (valor === 'S') {
              oParam.selectTiempoPeCuotas[0].label = 'Semana';
            } else if (valor === 'M') {
              oParam.selectTiempoPeCuotas[0].label = 'Mensual';
            }
          }
          if (oParam.selectTiempoFrecMax.length > 0) {
            let valor = oParam.selectTiempoFrecMax[0].value;
            if (valor === 'D') {
              oParam.selectTiempoFrecMax[0].label = 'Días';
            } else if (valor === 'S') {
              oParam.selectTiempoFrecMax[0].label = 'Semana';
            } else if (valor === 'M') {
              oParam.selectTiempoFrecMax[0].label = 'Mensual';
            }
          }
          dispatch(setDataInfoPR(oParam));
        }
      }
    }
    setLoadingDetalle(false);
  };

  const handleChangeDescripcion = (oEvent) => {
    var descripcion = oEvent.target.value;
    var validate = null;
    if (descripcion.length > 0) {
      validate = true;
    }
    var oParam = {
      descripcion: descripcion,
      bValidDescripcion: validate,
    };
    dispatch(changeDescripcionPR(oParam));
  };

  const handleChangeAgente = (oEvent) => {
    var oParam = {};
    oParam.bValidAgente = null;
    oParam.selectAgente = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectAgente.push(obj);
      oParam.bValidAgente = true;
    }
    dispatch(selectedAgentePR(oParam));
  };

  const handleChangeMonto = (values, sourceInfo) => {
    // var monto = oEvent.target.value;
    var monto = values.value;
    var validate = null;
    if (monto.length > 0) {
      validate = true;
    }
    var oParam = {
      monto: monto,
      bValidMonto: validate,
    };
    dispatch(changeMontoPR(oParam));
  };

  const handleChangeCantCuota = (values, sourceInfo) => {
    // var cantCuota = oEvent.target.value;
    var cantCuota = values.value;
    // var tea = prestamoState.tea;
    var validate = null;
    if (cantCuota.length > 0) {
      validate = true;
      // tea  = ""
    }
    var oParam = {
      cantCuota: cantCuota,
      bValidCantCuota: validate,
      // tea : tea
    };
    dispatch(changeCantCuotaPR(oParam));
  };

  const handleChangeMontoDesembolso = (values, sourceInfo) => {
    // var montoDes = oEvent.target.value;
    var montoDes = values.value;
    var validate = null;
    if (montoDes.length > 0) {
      validate = true;
    }
    var oParam = {
      montoDes: montoDes,
      bValidMontoDes: validate,
    };
    dispatch(changeMontoDesembolsoPR(oParam));
  };

  const handleChangeMoneda = (oEvent) => {
    var oParam = {};
    oParam.bValidMoneda = null;
    oParam.selectMoneda = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectMoneda.push(obj);
      oParam.bValidMoneda = true;
    }
    dispatch(selectedMonedaPR(oParam));
  };

  const handleChangeTiempoPeGracia = (oEvent) => {
    var oParam = {};
    oParam.bValidTiempoPeGracia = null;
    oParam.selectTiempoPeGracia = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTiempoPeGracia.push(obj);
      oParam.bValidTiempoPeGracia = true;
    }
    dispatch(selectedTiempoPeriodoGraciaPR(oParam));
  };

  const handleChangeTiempoPeCuotas = (oEvent) => {
    var oParam = {};
    oParam.bValidTiempoPeCuotas = null;
    oParam.selectTiempoPeCuotas = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTiempoPeCuotas.push(obj);
      oParam.bValidTiempoPeCuotas = true;
    }
    dispatch(selectedTiempoPeriodoCuotaPR(oParam));
  };

  const handleChangeTiempoFrecMax = (oEvent) => {
    var oParam = {};
    oParam.bValidTiempoFrecMax = null;
    oParam.selectTiempoFrecMax = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTiempoFrecMax.push(obj);
      oParam.bValidTiempoFrecMax = true;
    }
    dispatch(selectedTiempoFrecMaxPR(oParam));
  };

  const handleChangePeridoGracia = (values, sourceInfo) => {
    //var peGracia = oEvent.target.value;
    var peGracia = values.value;
    var validate = null;
    if (peGracia.length > 0) {
      validate = true;
    }
    var oParam = {
      peGracia: peGracia,
      bValidPeGracia: validate,
    };
    dispatch(changePeriodoGraciaPR(oParam));
  };

  const handleChangePeridoEntreCuotas = (values, sourceInfo) => {
    //var peCuotas = oEvent.target.value;
    var peCuotas = values.value;
    var validate = null;
    if (peCuotas.length > 0) {
      validate = true;
    }
    var oParam = {
      peCuotas: peCuotas,
      bValidCuotas: validate,
    };
    dispatch(changePeriodoCutoaPR(oParam));
  };

  const handleChangeTea = (values, sourceInfo) => {
    //var tea = oEvent.target.value;
    var tea = values.value;
    var validate = null;
    if (tea.length > 0) {
      validate = true;
    }
    var oParam = {
      tea: tea,
      bValidTea: validate,
    };
    dispatch(changeTeaPR(oParam));
  };

  const handleChangeFrecMax = (values, sourceInfo) => {
    //var frecMax = oEvent.target.value;
    var frecMax = values.value;
    var validate = null;
    if (frecMax.length > 0) {
      validate = true;
    }
    var oParam = {
      frecMax: frecMax,
      bValidFrecMax: validate,
    };
    dispatch(changeFrecMaxPR(oParam));
  };

  const handleChangeApariciones = (values, sourceInfo) => {
    //var apariciones = oEvent.target.value;
    var apariciones = values.value;
    var validate = null;
    if (apariciones.length > 0) {
      validate = true;
    }
    var oParam = {
      apariciones: apariciones,
      bValidApariciones: validate,
    };
    dispatch(changeAparicionesPR(oParam));
  };

  const handleChangeMinimo = (values, sourceInfo) => {
    // var minimo = oEvent.target.value;
    var minimo = values.value;
    var validate = null;
    if (minimo.length > 0) {
      validate = true;
    }
    var oParam = {
      minimo: minimo,
      bValidMinimo: validate,
    };
    dispatch(changeMinimoPR(oParam));
  };

  const handleChangeTipoFormulario = (oEvent) => {
    var oParam = {};
    oParam.bValidselectTipoFormulario = null;
    oParam.selectTipoFormulario = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTipoFormulario.push(obj);
      oParam.bValidselectTipoFormulario = true;
    } else {
      dispatch(limpiarFormPR());
    }
    dispatch(selectedTipoFomularioPR(oParam));
  };

  const limpiarForm = () => {
    dispatch(limpiarFormPR());
  };

  const handleChangeEstado = (oEvent) => {
    var oParam = {};
    oParam.bValidEstado = null;
    oParam.selectEstado = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectEstado.push(obj);
      oParam.bValidEstado = true;
    }
    dispatch(selectedEstadoPR(oParam));
  };

  const handleChangeFormaPago = (oEvent) => {
    var oParam = {};
    oParam.bValidFormaPago = null;

    oParam.selectFormaPago = [];
    if (oEvent) {
      let obj = {
        ...oEvent,
        ...{ value: oEvent.value, label: oEvent.label },
      };
      oParam.selectFormaPago.push(obj);
      oParam.bValidFormaPago = true;
    }
    dispatch(selectedEstadoPR(oParam));
  };

  const camposObligatorios = () => {
    var oParan = {
      valid: false,
      oRegistrarSolucion: {},
      mensaje: '',
    };
    var oParamValidCampos = {};
    if (prestamoState.selectTipoFormulario.length > 0) {
      var selectTipoFormulario = prestamoState.selectTipoFormulario[0];
      let tipoFormulario = selectTipoFormulario.value;
      if (tipoFormulario === 'Tipo_1') {
        if (prestamoState.descripcion.length === 0) {
          oParamValidCampos.bValidDescripcion = false;
        }
        if (prestamoState.selectAgente.length === 0) {
          oParamValidCampos.bValidAgente = false;
        }
        if (prestamoState.dDateRange[0] === null || prestamoState.dDateRange[1] === null) {
          oParamValidCampos.bValidFecha = false;
        }
        if (prestamoState.selectMoneda.length === 0) {
          oParamValidCampos.bValidMoneda = false;
        }
        if (prestamoState.monto.length === 0) {
          oParamValidCampos.bValidMonto = false;
        }
        if (prestamoState.cantCuota.length === 0) {
          oParamValidCampos.bValidCantCuota = false;
        }
        // if (prestamoState.peGracia.length === 0) {
        //   oParamValidCampos.bValidPeGracia = false;
        // }
        // if(prestamoState.selectTiempoPeGracia.length ==0  ){
        //   oParamValidCampos.bValidTiempoPeGracia = false;
        // }
        if (prestamoState.peCuotas.length === 0) {
          oParamValidCampos.bValidCuotas = false;
        }
        if (prestamoState.selectTiempoPeCuotas.length === 0) {
          oParamValidCampos.bValidTiempoPeCuotas = false;
        }
        if (prestamoState.tea.length === 0) {
          oParamValidCampos.bValidTea = false;
        }
        if (prestamoState.frecMax.length === 0) {
          oParamValidCampos.bValidFrecMax = false;
        }
        if (prestamoState.selectTiempoFrecMax.length === 0) {
          oParamValidCampos.bValidTiempoFrecMax = false;
        }
        if (prestamoState.apariciones.length === 0) {
          oParamValidCampos.bValidApariciones = false;
        }
        if (prestamoState.minimo.length === 0) {
          oParamValidCampos.bValidMinimo = false;
        }
        if (prestamoState.selectEstado.length === 0) {
          oParamValidCampos.bValidEstado = false;
        }
        if (prestamoState.selectFormaPago.length === 0) {
          oParamValidCampos.bValidFormaPago = false;
        }
        //Campos obligatorios
        if (
          prestamoState.descripcion.length !== 0 &&
          prestamoState.selectAgente.length !== 0 &&
          prestamoState.dDateRange[0] != null &&
          prestamoState.dDateRange[1] != null &&
          prestamoState.selectMoneda.length !== 0 &&
          prestamoState.monto.length !== 0 &&
          prestamoState.cantCuota.length !== 0 &&
          // prestamoState.peGracia.length !== 0 &&
          // prestamoState.selectTiempoPeGracia.length !==0&&
          prestamoState.peCuotas.length !== 0 &&
          prestamoState.selectTiempoPeCuotas.length !== 0 &&
          prestamoState.tea.length !== 0 &&
          prestamoState.frecMax.length !== 0 &&
          prestamoState.selectTiempoFrecMax.length !== 0 &&
          prestamoState.apariciones.length !== 0 &&
          prestamoState.minimo.length !== 0 &&
          prestamoState.selectEstado.length !== 0 &&
          prestamoState.selectFormaPago.length !== 0
        ) {
          if (
            parseFloat(prestamoState.montoDes.length === 0 ? 0 : prestamoState.montoDes) >=
            parseFloat(prestamoState.monto)
          ) {
            oParan.mensaje = 'El costo fijo de desembolso debe ser menor al monto.';
            return oParan;
          } else if (parseFloat(prestamoState.minimo) >= parseFloat(prestamoState.monto)) {
            oParan.mensaje = 'El mínimo debe ser menor al monto.';
            return oParan;
          } else if (parseFloat(prestamoState.tea) === 0) {
            oParan.mensaje = 'La TEA(%) debe ser mayo a 0';
            return oParan;
          } else {
            oParan.valid = true;
            oParan.oRegistrarSolucion['COD_TIPO_FORMULARIO'] = selectTipoFormulario.value;
            oParan.oRegistrarSolucion['TIPO_FORMULARIO'] = selectTipoFormulario.label;
            oParan.oRegistrarSolucion['COD_SOLUCION'] = 'PR';
            oParan.oRegistrarSolucion['SOL_NOMBRESOLUCION'] = prestamoState.descripcion;
            oParan.oRegistrarSolucion['SOL_IDAGENTE'] = parseFloat(
              prestamoState.selectAgente[0].value
            );
            oParan.oRegistrarSolucion['SOL_AGENTE'] = prestamoState.selectAgente[0].label;
            oParan.oRegistrarSolucion['SOL_PERIODOINICIOP'] = dateTo_dd_mm_yyyy(
              prestamoState.dDateRange[0],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_PERIODOFINP'] = dateTo_dd_mm_yyyy(
              prestamoState.dDateRange[1],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_DESFASECUOTAP'] = 0; //parseFloat(prestamoState.peGracia);
            // oParan.oRegistrarSolucion["SOL_DESFASECUOTAP_PERIODO"] = prestamoState.selectTiempoPeGracia[0].value;
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIAL'] = parseFloat(prestamoState.monto);
            oParan.oRegistrarSolucion['SOL_FRECUENCIA'] = parseFloat(prestamoState.frecMax);
            oParan.oRegistrarSolucion['SOL_FRECUENCIA_PERIODO'] =
              prestamoState.selectTiempoFrecMax[0].value;
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIALMIN'] = parseFloat(prestamoState.minimo);
            oParan.oRegistrarSolucion['SOL_APARICIONES'] = parseFloat(prestamoState.apariciones);
            oParan.oRegistrarSolucion['SOL_MONEDA'] = prestamoState.selectMoneda[0].value;
            oParan.oRegistrarSolucion['IDESTADO'] = parseFloat(prestamoState.selectEstado[0].value);
            oParan.oRegistrarSolucion['SOL_PERIODOSENTREPAGO'] = parseFloat(prestamoState.peCuotas);
            oParan.oRegistrarSolucion['SOL_PERIODOSENTREPAGO_PERIODO'] =
              prestamoState.selectTiempoPeCuotas[0].value;
            oParan.oRegistrarSolucion['SOL_TEAP'] = parseFloat(prestamoState.tea);
            oParan.oRegistrarSolucion['SOL_CANTIDADCUOTASP'] = parseFloat(prestamoState.cantCuota);
            oParan.oRegistrarSolucion['TIPO_PAGO'] = parseFloat(
              prestamoState.selectFormaPago[0].value
            );
          }
        } else {
          dispatch(setValidCamposPR(oParamValidCampos));
          oParan.mensaje = 'Debe llenar todos los campos en (*).';
          return oParan;
        }
        //campos opcionales
        if (prestamoState.montoDes.length !== 0) {
          oParan.oRegistrarSolucion['SOL_PAGODESEMBOLSO'] = parseFloat(prestamoState.montoDes);
        }
      } else if (tipoFormulario === 'Tipo_2') {
        if (prestamoState.descripcion.length === 0) {
          oParamValidCampos.bValidDescripcion = false;
        }
        if (prestamoState.selectAgente.length === 0) {
          oParamValidCampos.bValidAgente = false;
        }
        if (prestamoState.dDateRange[0] === null || prestamoState.dDateRange[1] === null) {
          oParamValidCampos.bValidFecha = false;
        }
        if (prestamoState.selectMoneda.length === 0) {
          oParamValidCampos.bValidMoneda = false;
        }
        if (prestamoState.monto.length === 0) {
          oParamValidCampos.bValidMonto = false;
        }
        if (prestamoState.cantCuota.length === 0) {
          oParamValidCampos.bValidCantCuota = false;
        }
        // if (prestamoState.peGracia.length === 0) {
        //   oParamValidCampos.bValidPeGracia = false;
        // }
        // if (prestamoState.selectTiempoPeGracia.length === 0) {
        //   oParamValidCampos.bValidTiempoPeGracia = false;
        // }
        if (prestamoState.peCuotas.length === 0) {
          oParamValidCampos.bValidCuotas = false;
        }
        if (prestamoState.selectTiempoPeCuotas.length === 0) {
          oParamValidCampos.bValidTiempoPeCuotas = false;
        }
        if (prestamoState.tea.length === 0) {
          oParamValidCampos.bValidTea = false;
        }
        // if (prestamoState.frecMax.length === 0) {
        //   oParamValidCampos.bValidFrecMax = false;
        // }
        // if (prestamoState.selectTiempoFrecMax.length === 0) {
        //   oParamValidCampos.bValidTiempoFrecMax = false;
        // }
        // if (prestamoState.apariciones.length === 0) {
        //   oParamValidCampos.bValidApariciones = false;
        // }
        // if (prestamoState.minimo.length === 0) {
        //   oParamValidCampos.bValidMinimo = false;
        // }
        if (prestamoState.selectEstado.length === 0) {
          oParamValidCampos.bValidEstado = false;
        }
        if (prestamoState.selectFormaPago.length === 0) {
          oParamValidCampos.bValidFormaPago = false;
        }
        //Campos obligatorios
        if (
          prestamoState.descripcion.length !== 0 &&
          prestamoState.selectAgente.length !== 0 &&
          prestamoState.dDateRange[0] != null &&
          prestamoState.dDateRange[1] != null &&
          prestamoState.selectMoneda.length !== 0 &&
          prestamoState.monto.length !== 0 &&
          prestamoState.cantCuota.length !== 0 &&
          // prestamoState.peGracia.length !== 0 &&
          // prestamoState.selectTiempoPeGracia.length !== 0 &&
          prestamoState.peCuotas.length !== 0 &&
          prestamoState.selectTiempoPeCuotas.length !== 0 &&
          prestamoState.tea.length !== 0 &&
          // prestamoState.frecMax.length !== 0 &&
          // prestamoState.selectTiempoFrecMax.length !== 0 &&
          // prestamoState.apariciones.length !== 0 &&
          // prestamoState.minimo.length !== 0 &&
          prestamoState.selectEstado.length !== 0 &&
          prestamoState.selectFormaPago.length !== 0
        ) {
          oParamValidCampos.bValidPeGracia = true;
          oParamValidCampos.bValidFrecMax = true;
          oParamValidCampos.bValidTiempoFrecMax = true;
          oParamValidCampos.bValidApariciones = true;
          oParamValidCampos.bValidMinimo = true;
          if (parseFloat(prestamoState.montoDes) >= parseFloat(prestamoState.monto)) {
            oParan.mensaje = 'El costo fijo de desembolso debe ser menor al monto.';
            return oParan;
          } else if (parseFloat(prestamoState.minimo) >= parseFloat(prestamoState.monto)) {
            oParan.mensaje = 'El mínimo debe ser menor al monto.';
            return oParan;
          } else if (parseFloat(prestamoState.tea) === 0) {
            oParan.mensaje = 'La TEA(%) debe ser mayo a 0';
            return oParan;
          } else {
            oParan.valid = true;
            oParan.oRegistrarSolucion['COD_TIPO_FORMULARIO'] = selectTipoFormulario.value;
            oParan.oRegistrarSolucion['TIPO_FORMULARIO'] = selectTipoFormulario.label;
            oParan.oRegistrarSolucion['COD_SOLUCION'] = 'PR';
            oParan.oRegistrarSolucion['SOL_NOMBRESOLUCION'] = prestamoState.descripcion;
            oParan.oRegistrarSolucion['SOL_IDAGENTE'] = parseFloat(
              prestamoState.selectAgente[0].value
            );
            oParan.oRegistrarSolucion['SOL_AGENTE'] = prestamoState.selectAgente[0].label;
            oParan.oRegistrarSolucion['SOL_PERIODOINICIOP'] = dateTo_dd_mm_yyyy(
              prestamoState.dDateRange[0],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_PERIODOFINP'] = dateTo_dd_mm_yyyy(
              prestamoState.dDateRange[1],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_DESFASECUOTAP'] = 0; //parseFloat(prestamoState.peGracia);
            // oParan.oRegistrarSolucion["SOL_DESFASECUOTAP_PERIODO"] = prestamoState.selectTiempoPeGracia[0].value;
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIAL'] = parseFloat(prestamoState.monto);
            oParan.oRegistrarSolucion['SOL_FRECUENCIA'] = 30; //parseFloat(prestamoState.frecMax);
            oParan.oRegistrarSolucion['SOL_FRECUENCIA_PERIODO'] = 'D'; //prestamoState.selectTiempoFrecMax[0].value;
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIALMIN'] = 0; //parseFloat(prestamoState.minimo);
            oParan.oRegistrarSolucion['SOL_APARICIONES'] = 9999; //parseFloat(prestamoState.apariciones);
            oParan.oRegistrarSolucion['SOL_MONEDA'] = prestamoState.selectMoneda[0].value;
            oParan.oRegistrarSolucion['IDESTADO'] = parseFloat(prestamoState.selectEstado[0].value);
            oParan.oRegistrarSolucion['SOL_PERIODOSENTREPAGO'] = parseFloat(prestamoState.peCuotas);
            oParan.oRegistrarSolucion['SOL_PERIODOSENTREPAGO_PERIODO'] =
              prestamoState.selectTiempoPeCuotas[0].value;
            oParan.oRegistrarSolucion['SOL_TEAP'] = parseFloat(prestamoState.tea);
            oParan.oRegistrarSolucion['SOL_CANTIDADCUOTASP'] = parseFloat(prestamoState.cantCuota);
            oParan.oRegistrarSolucion['TIPO_PAGO'] = parseFloat(
              prestamoState.selectFormaPago[0].value
            );
            dispatch(setValidCamposPR(oParamValidCampos));
          }
        } else {
          dispatch(setValidCamposPR(oParamValidCampos));
          oParan.mensaje = 'Debe llenar todos los campos en (*).';
          return oParan;
        }
        //campos opcionales
        if (prestamoState.montoDes.length !== 0) {
          oParan.oRegistrarSolucion['SOL_PAGODESEMBOLSO'] = parseFloat(prestamoState.montoDes);
        }
      }
    }
    return oParan;
  };

  const limpiarCampos = () => {
    dispatch(limpiarCamposPR());
  };

  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };

  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };

  const onConfirmarCancelarPrestamo = () => {
    setShowModalCancelar(false);
    // setButtonAction(1);
    // setTabProceso(0);
    limpiarCampos();
    navigate(paths.SOLUTION.LOAN.BASE);
  };

  const onPressGrabar = async () => {
    let { valid, oRegistrarSolucion, mensaje } = camposObligatorios();
    if (valid) {
      var oParam = {
        oActualizarSolucion: oRegistrarSolucion,
        aRegManuales: [],
        aTeaMultiMoneda: [],
        listaFiltrosMultiplesAppliedTableAP: [],
      };
      oParam.oActualizarSolucion.ID_SOLUCION = prestamoState.idSolucion;
      const editarSolucionResp = await callEndpoint(editarSolucion(oParam));
      if (editarSolucionResp === null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (editarSolucionResp.data) {
          if (editarSolucionResp.data.cabecera.statusCode === 200) {
            // props.setButtonAction(0);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: editarSolucionResp.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            navigate(paths.SOLUTION.LOAN.BASE);
            // await props.loadPrestamo();
          } else if (editarSolucionResp.data.cabecera.statusCode === 400) {
            Swal.fire({
              position: 'center',
              icon: 'info',
              title: editarSolucionResp.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: editarSolucionResp.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: mensaje,
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  const loadAgente = async () => {
    const responseObtAgentes = await callEndpoint(obtenerAgentes());
    let aAgentes = [];
    if (responseObtAgentes != null) {
      if (responseObtAgentes.data) {
        let { aListaAgentes } = responseObtAgentes.data.respuesta;
        aListaAgentes.forEach((element) => {
          aAgentes.push(createAgenteSelectAdapter(element));
        });
      }
    }
    dispatch(setListaAgentePR({ listaAgente: aAgentes }));
  };

  const loadMoneda = async () => {
    const responseObtMonedas = await callEndpoint(obtenerListadoMoneda());
    let aMonedas = [];
    if (responseObtMonedas != null) {
      if (responseObtMonedas.data) {
        let { aListaMoneda } = responseObtMonedas.data.respuesta;
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'texto_moneda'
        )[0].DatoPersonalizacion;
        aListaMoneda.forEach((element) => {
          aMonedas.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
        });
      }
    }
    dispatch(setListaMonedaPR({ listaMoneda: aMonedas }));
  };

  const loadEstado = async () => {
    let aEstadoSol = [];
    EstadoSolucionesData.forEach((element) => {
      aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
    });
    dispatch(setListaEstadoPR({ listaEstado: aEstadoSol }));
  };

  const handleChangeFecha = (oEvent) => {
    var oParam = {
      dFechaInicio: oEvent[0],
      dFechaFin: oEvent[1],
    };
    var sFechaInicio = '';
    var sFechaFinal = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';
    if (oParam.dFechaInicio != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      var iDia = oParam.dFechaInicio.getDate();
      var iMes = oParam.dFechaInicio.getMonth() + 1;
      var iAnio = oParam.dFechaInicio.getFullYear();
      if (oParam.dFechaInicio.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
    }
    if (oParam.dFechaFin != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaFin.getDate();
      let iMes = oParam.dFechaFin.getMonth() + 1;
      let iAnio = oParam.dFechaFin.getFullYear();
      if (oParam.dFechaFin.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaFinal = sDia + '/' + sMes + '/' + sAnio;
    }
    // oParam.sFechaInicio = sFechaInicio;
    // oParam.sFechaFinal = sFechaFinal;
    oParam.dDateRange = [oEvent[0], oEvent[1]];
    dispatch(changeVigenciaPR(oParam));
  };

  const obligatoriedadCampos = (selectTipoFormulario) => {
    var formulario = <></>;
    if (selectTipoFormulario.length > 0) {
      let tipoFormulario = selectTipoFormulario[0].value;
      switch (tipoFormulario) {
        case 'Tipo_1':
          formulario = (
            <>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Descripción (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={prestamoState.descripcion}
                    onChange={(evnt) => handleChangeDescripcion(evnt)}
                    validate={prestamoState.bValidDescripcion}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Socio de negocio (*):'}></LabelForm>
                  <SelectForm
                    bValid={prestamoState.bValidAgente}
                    value={prestamoState.selectAgente}
                    handleChange={(e, v) => handleChangeAgente(v)}
                    options={prestamoState.listaAgente}
                    placeholder={'Seleccione Socio'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia (*):'}></LabelForm>
                  <DatePickerForm
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={prestamoState.dDateRange[0]}
                    endDate={prestamoState.dDateRange[1]}
                    onChange={(evnt) => handleChangeFecha(evnt)}
                    isClearable={true}
                    placeholderText="Ingrese"
                    locale="es"
                    showMonthDropdown
                    showYearDropdown
                    portalId="root-portal"
                    validate={prestamoState.bValidFecha}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={prestamoState.bValidMoneda}
                    value={prestamoState.selectMoneda}
                    handleChange={(e, v) => handleChangeMoneda(v)}
                    options={prestamoState.listaMoneda}
                    placeholder={'Seleccione Moneda'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Importe (*):'}></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={prestamoState.monto}
                    validate={prestamoState.bValidMonto}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeMonto(values, sourceInfo)}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Cantidad de cuotas (*):'}></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={prestamoState.cantCuota}
                    validate={prestamoState.bValidCantCuota}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeCantCuota(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
              {/* <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm
                    
                    obligatorio={true}
                    title={'Cuotas de Gracia (*):'}
                  ></LabelForm>
                  <InputNumberFormat
                    min={0}
                    width={'320px'}
                    value={prestamoState.peGracia}
                    validate={prestamoState.bValidPeGracia}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) =>
                      handleChangePeridoGracia(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm> */}
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Periodo de cuotas (*):'}></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      value={prestamoState.peCuotas}
                      validate={prestamoState.bValidCuotas}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeridoEntreCuotas(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={prestamoState.bValidTiempoPeCuotas}
                      value={prestamoState.selectTiempoPeCuotas}
                      handleChange={(e, v) => handleChangeTiempoPeCuotas(v)}
                      options={prestamoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'TEA (%) (*):'}></LabelForm>
                  <InputNumberFormat
                    value={prestamoState.tea}
                    validate={prestamoState.bValidTea}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeTea(values, sourceInfo)}
                    max={100.0}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Frec. Máxima (*):'}></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      value={prestamoState.frecMax}
                      validate={prestamoState.bValidFrecMax}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangeFrecMax(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={prestamoState.bValidTiempoFrecMax}
                      value={prestamoState.selectTiempoFrecMax}
                      handleChange={(e, v) => handleChangeTiempoFrecMax(v)}
                      options={prestamoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Apariciones (*):'}></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={prestamoState.apariciones}
                    validate={prestamoState.bValidApariciones}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeApariciones(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Mínimo (*):'}></LabelForm>
                  <InputNumberFormat
                    value={prestamoState.minimo}
                    validate={prestamoState.bValidMinimo}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeMinimo(values, sourceInfo)}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Estado (*):'}></LabelForm>
                  <SelectForm
                    bValid={prestamoState.bValidEstado}
                    value={prestamoState.selectEstado}
                    handleChange={(e, v) => handleChangeEstado(v)}
                    options={prestamoState.listaEstado}
                    placeholder={'Seleccione Estado'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Forma de pago (*):'}></LabelForm>
                  <SelectForm
                    bValid={prestamoState.bValidFormaPago}
                    value={prestamoState.selectFormaPago}
                    handleChange={(e, v) => handleChangeFormaPago(v)}
                    options={listaTipoPagoPrestamo}
                    placeholder={'Seleccione Forma de pago'}
                  />
                </RowForm>
              </ColForm>
              {/* Campos no obligatorios */}
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm title={'Costo fijo de desembolso:'}></LabelForm>
                  <InputNumberFormat
                    value={prestamoState.montoDes}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeMontoDesembolso(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
            </>
          );
          break;
        case 'Tipo_2':
          formulario = (
            <>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Descripción (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={prestamoState.descripcion}
                    onChange={(evnt) => handleChangeDescripcion(evnt)}
                    validate={prestamoState.bValidDescripcion}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Socio de negocio (*):'}></LabelForm>
                  <SelectForm
                    bValid={prestamoState.bValidAgente}
                    value={prestamoState.selectAgente}
                    handleChange={(e, v) => handleChangeAgente(v)}
                    options={prestamoState.listaAgente}
                    placeholder={'Seleccione Socio'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia (*):'}></LabelForm>
                  <DatePickerForm
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={prestamoState.dDateRange[0]}
                    endDate={prestamoState.dDateRange[1]}
                    onChange={(evnt) => handleChangeFecha(evnt)}
                    isClearable={true}
                    placeholderText="Ingrese"
                    locale="es"
                    showMonthDropdown
                    showYearDropdown
                    portalId="root-portal"
                    validate={prestamoState.bValidFecha}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={prestamoState.bValidMoneda}
                    value={prestamoState.selectMoneda}
                    handleChange={(e, v) => handleChangeMoneda(v)}
                    options={prestamoState.listaMoneda}
                    placeholder={'Seleccione Moneda'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Importe (*):'}></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={prestamoState.monto}
                    validate={prestamoState.bValidMonto}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeMonto(values, sourceInfo)}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Cantidad de cuotas (*):'}></LabelForm>
                  <InputNumberFormat
                    min={1}
                    value={prestamoState.cantCuota}
                    validate={prestamoState.bValidCantCuota}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeCantCuota(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Periodo de cuotas (*):'}></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      min={1}
                      width={'37%'}
                      value={prestamoState.peCuotas}
                      validate={prestamoState.bValidCuotas}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangePeridoEntreCuotas(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={prestamoState.bValidTiempoPeCuotas}
                      value={prestamoState.selectTiempoPeCuotas}
                      handleChange={(e, v) => handleChangeTiempoPeCuotas(v)}
                      options={prestamoState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'TEA (%) (*):'}></LabelForm>
                  <InputNumberFormat
                    // min={1.0}
                    value={prestamoState.tea}
                    validate={prestamoState.bValidTea}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeTea(values, sourceInfo)}
                    max={100.0}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Estado (*):'}></LabelForm>
                  <SelectForm
                    bValid={prestamoState.bValidEstado}
                    value={prestamoState.selectEstado}
                    handleChange={(e, v) => handleChangeEstado(v)}
                    options={prestamoState.listaEstado}
                    placeholder={'Seleccione Estado'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Forma de pago (*):'}></LabelForm>
                  <SelectForm
                    bValid={prestamoState.bValidFormaPago}
                    value={prestamoState.selectFormaPago}
                    handleChange={(e, v) => handleChangeFormaPago(v)}
                    options={listaTipoPagoPrestamo}
                    placeholder={'Seleccione Forma de pago'}
                  />
                </RowForm>
              </ColForm>
              {/* Campos no obligatorios */}
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm title={'Costo fijo de desembolso:'}></LabelForm>
                  <InputNumberFormat
                    value={prestamoState.montoDes}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeMontoDesembolso(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
            </>
          );
          break;
        default:
          <>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Descripción :'}></LabelForm>
                <Input
                  placeholder="Ingrese..."
                  value={prestamoState.descripcion}
                  onChange={(evnt) => handleChangeDescripcion(evnt)}
                  validate={prestamoState.bValidDescripcion}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Socio de negocio:'}></LabelForm>
                <SelectForm
                  bValid={prestamoState.bValidAgente}
                  value={prestamoState.selectAgente}
                  handleChange={(e, v) => handleChangeAgente(v)}
                  options={prestamoState.listaAgente}
                  placeholder={'Seleccione Socio'}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Vigencia :'}></LabelForm>
                <DatePickerForm
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={prestamoState.dDateRange[0]}
                  endDate={prestamoState.dDateRange[1]}
                  onChange={(evnt) => handleChangeFecha(evnt)}
                  isClearable={true}
                  placeholderText="Ingrese"
                  locale="es"
                  showMonthDropdown
                  showYearDropdown
                  portalId="root-portal"
                  validate={prestamoState.bValidFecha}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Moneda :'}></LabelForm>
                <SelectForm
                  bValid={prestamoState.bValidMoneda}
                  value={prestamoState.selectMoneda}
                  handleChange={(e, v) => handleChangeMoneda(v)}
                  options={prestamoState.listaMoneda}
                  placeholder={'Seleccione Moneda'}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Importe :'}></LabelForm>
                <InputNumberFormat
                  value={prestamoState.monto}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  decimalScale={3}
                  onValueChange={(values, sourceInfo) => handleChangeMonto(values, sourceInfo)}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Cantidad de cuotas :'}></LabelForm>
                <InputNumberFormat
                  value={prestamoState.cantCuota}
                  allowNegative={false}
                  fixedDecimalScale={false}
                  decimalScale={0}
                  onValueChange={(values, sourceInfo) => handleChangeCantCuota(values, sourceInfo)}
                />
              </RowForm>
            </ColForm>

            {/* <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm  title={'Cuotas de Gracia :'}></LabelForm>
                <InputNumberFormat
                  width={'319px'}
                  value={prestamoState.peGracia}
                  allowNegative={false}
                  fixedDecimalScale={false}
                  decimalScale={0}
                  onValueChange={(values, sourceInfo) =>
                    handleChangePeridoGracia(values, sourceInfo)
                  }
                />
              </RowForm>
            </ColForm> */}
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Periodo de cuotas :'}></LabelForm>
                <RowDoubleInput>
                  <InputNumberFormat
                    width={'37%'}
                    value={prestamoState.peCuotas}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) =>
                      handleChangePeridoEntreCuotas(values, sourceInfo)
                    }
                  />
                  <SelectForm
                    width={'60%'}
                    bValid={prestamoState.bValidTiempoPeCuotas}
                    value={prestamoState.selectTiempoPeCuotas}
                    handleChange={(e, v) => handleChangeTiempoPeCuotas(v)}
                    options={prestamoState.listaTiempo}
                    placeholder={'Seleccione Tiempo'}
                  />
                </RowDoubleInput>
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'TEA (%):'}></LabelForm>
                <InputNumberFormat
                  value={prestamoState.tea}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  decimalScale={3}
                  onValueChange={(values, sourceInfo) => handleChangeTea(values, sourceInfo)}
                  max={100.0}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Frec. Máxima :'}></LabelForm>
                <RowDoubleInput>
                  <InputNumberFormat
                    width={'37%'}
                    value={prestamoState.frecMax}
                    allowNegative={false}
                    fixedDecimalScale={false}
                    decimalScale={0}
                    onValueChange={(values, sourceInfo) => handleChangeFrecMax(values, sourceInfo)}
                  />
                  <SelectForm
                    width={'60%'}
                    bValid={prestamoState.bValidTiempoFrecMax}
                    value={prestamoState.selectTiempoFrecMax}
                    handleChange={(e, v) => handleChangeTiempoFrecMax(v)}
                    options={prestamoState.listaTiempo}
                    placeholder={'Seleccione Tiempo'}
                  />
                </RowDoubleInput>
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Apariciones :'}></LabelForm>
                <InputNumberFormat
                  value={prestamoState.apariciones}
                  allowNegative={false}
                  fixedDecimalScale={false}
                  decimalScale={0}
                  onValueChange={(values, sourceInfo) =>
                    handleChangeApariciones(values, sourceInfo)
                  }
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Mínimo :'}></LabelForm>
                <InputNumberFormat
                  value={prestamoState.minimo}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  decimalScale={3}
                  onValueChange={(values, sourceInfo) => handleChangeMinimo(values, sourceInfo)}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Estado :'}></LabelForm>
                <SelectForm
                  bValid={prestamoState.bValidEstado}
                  value={prestamoState.selectEstado}
                  handleChange={(e, v) => handleChangeEstado(v)}
                  options={prestamoState.listaEstado}
                  placeholder={'Seleccione Estado'}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Forma de pago (*):'}></LabelForm>
                <SelectForm
                  bValid={prestamoState.bValidFormaPago}
                  value={prestamoState.selectFormaPago}
                  handleChange={(e, v) => handleChangeFormaPago(v)}
                  options={listaTipoPagoPrestamo}
                  placeholder={'Seleccione Forma de pago'}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Costo fijo de desembolso:'}></LabelForm>
                <InputNumberFormat
                  value={prestamoState.montoDes}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  decimalScale={3}
                  onValueChange={(values, sourceInfo) =>
                    handleChangeMontoDesembolso(values, sourceInfo)
                  }
                />
              </RowForm>
            </ColForm>
          </>;
      }
    }
    return formulario;
  };

  const onPressSimular = async () => {
    const id = prestamoState.idSolucion;

    const responseSimularPrestamo = await callEndpoint(
      simularPrestamo({
        IDSolucion: [id],
      })
    );
    if (responseSimularPrestamo.data) {
      let lista = [];
      responseSimularPrestamo.data.respuesta.aSimulacion.map((col) => {
        lista.push(simulacionPrestamoAdapter(col));
      });
      setListaSimulacion(lista);
      setOpenSimulacion(true);
    }
  };
  const handleCloseSimulacion = () => {
    setOpenSimulacion(false);
  };

  return loadingDetalle === true ? (
    <LoadingSpinner />
  ) : prestamoState.idSolucion === null ? (
    <ColFlex>No se encontró información</ColFlex>
  ) : (
    <Content>
      <ColFlex>
        <SubTitle subtitle="1. Registrar datos generales de la solución." />
        <ContentForm>
          <RowForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Tipo Fomulario (*):'}></LabelForm>
                <SelectForm
                  isNoClearable={true}
                  bValid={prestamoState.bValidselectTipoFormulario}
                  value={prestamoState.selectTipoFormulario}
                  handleChange={(e, v) => handleChangeTipoFormulario(v)}
                  options={TipoFormularioPrestamo}
                  placeholder={'Seleccione Tipo Formulario'}
                />
              </RowForm>
            </ColForm>
            {obligatoriedadCampos(prestamoState.selectTipoFormulario)}
          </RowForm>
          <RowFooterButton>
            <ButtonForm
              action={(e) => onPressCancelar()}
              name="Cancelar"
              isAction={true}
              typeColor="warning"
            />
            <ButtonForm
              action={(e) => limpiarForm()}
              name="Limpiar"
              isAction={true}
              typeColor="primary"
            />
            <ButtonForm
              action={(e) => onPressSimular()}
              name="Simular Costo"
              isAction={true}
              typeColor="primary"
            />
            <ButtonForm
              action={(e) => onPressGrabar()}
              name="Grabar"
              isAction={true}
              typeColor="secondary"
            />
          </RowFooterButton>
        </ContentForm>
      </ColFlex>
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la edición del préstamo?"
        action={(e) => onConfirmarCancelarPrestamo(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
      <ModalSimulacion
        open={openSimulacion}
        closeModal={handleCloseSimulacion}
        lista={listaSimulacion}
      />
    </Content>
  );
};
