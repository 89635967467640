import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../../../../../../common/hooks';
import {
  onDeleteFiltroDetalleFlujoCaja,
  onLimpiarFiltroDetalleFlujoCaja,
  onOpenModalCancelarCrearFlujoCaja,
  onOpenModalRegM,
  onSetAplicarBusquedaFlujoCaja,
} from '../../../../../../../redux/states';
import {
  ButtonForm,
  Input,
  InputNumberFormat,
  LabelForm,
  SelectForm,
  SubTitle,
} from '../../../../../../../components';
import moment from 'moment';
import {
  formatString_DDMMYYYY_TO_YYYYMMDD,
  theme,
  numbersWithComa,
} from '../../../../../../../common';
import {
  obtenerCaracteristicasXPosicion,
  obtenerPosicion,
  obtenerPosicionXConcepto,
  registrarEtiqueta,
  registrarRegistroM,
} from '../../../../../../../services';
import Swal from 'sweetalert2';
import {
  ColFlex,
  ContentForm,
  DatePickerForm,
  ModalComponent,
  RowContent,
  RowFlex,
} from '../../../../../../../styles';
import { cloneDeep } from 'lodash';

export const ModalRegM = (props) => {
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [rowsKeyData, setRowsKeyData] = useState([]);
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const authState = useSelector((store) => store.auth);
  const { oFlujoCajaAgregar, dDateRange } = flujoCajaState;

  const handleChangeFechaRegistroM = (oEvent) => {
    const formatDate = moment(oEvent[0]).format('DD/MM/YYYY');
    const dateFormat = formatDate.replaceAll('/', '.');
    let oParam = {
      dateRegistroM: dateFormat,
    };
    props.setFormAgregarRegM({ ...props.formAgregarRegM, ...oParam });
  };

  const handleChangeFechaVigenciaRegistroM = (oEvent) => {
    const formatDate = moment(oEvent[0]).format('DD/MM/YYYY');
    const dateFormat = formatDate.replaceAll('/', '.');
    let oParam = {
      dateFechaVigenciaRegistroM: dateFormat,
    };
    props.setFormAgregarRegM({ ...props.formAgregarRegM, ...oParam });
  };

  const handleChangeMonto = (oEvent) => {
    let monto = oEvent.value;
    let validate = null;
    if (monto.length > 0) {
      validate = true;
    }
    let oParam = {
      fMonto: monto,
      bValidMonto: validate,
    };
    props.setFormAgregarRegM({ ...props.formAgregarRegM, ...oParam });
  };

  const onCloseModal = (e) => {
    // openModalFiltrosDetalleFlujoCaja(false);
    dispatch(onOpenModalRegM(false));
    props.limpiarFormAgregarRegM();
  };

  const limpiarCampos = () => {
    // props.setFormAgregarRegM(initialFormAgregarRegM)
    let rows = [...rowsKeyData];
    rows.forEach((element) => {
      element.valor = '';
      element.validate = null;
    });
    setRowsKeyData(rows);
    props.limpiarFormAgregarRegM();
  };

  const validarCaracteristicasKeyVacias = async () => {
    var aEncontradoKeyVacio = rowsKeyData.filter((element) => {
      return element.LD === 'L' && element.valor === '';
    });
    if (aEncontradoKeyVacio.length > 0) {
      Swal.fire({
        title:
          'La característica ' +
          aEncontradoKeyVacio[0].NombreCampo +
          ' se le asignará el valor de la posición seleccionada.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await registrarRegistroManual();
        }
      });
    } else {
      await registrarRegistroManual();
    }
  };

  const onPressGrabar = async () => {
    if (
      props.formAgregarRegM.fMonto !== '' &&
      props.formAgregarRegM.dateRegistroM !== '' &&
      props.formAgregarRegM.dateFechaVigenciaRegistroM !== '' &&
      props.formAgregarRegM.selectMoneda.length > 0 &&
      props.formAgregarRegM.selectMonedaPago.length > 0
    ) {
      if (props.formAgregarRegM.selectListEtiquetaManual.length === 0) {
        Swal.fire({
          title: `No se ha seleccionado ninguna etiqueta. Se le asignará al grupo "Sin Etiquetas" `,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: theme.colors.primary,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await validarCaracteristicasKeyVacias();
          }
        });
      } else {
        await validarCaracteristicasKeyVacias();
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Registrar los campos obligatorios.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const registrarRegistroManual = async () => {
    let aDatosKey = [];
    let NombreReg = '';
    const rowsKeyData = [...props.formAgregarRegM.rowsKeyData];
    rowsKeyData.forEach(function (item, index) {
      let o = {};
      o.Valor = item.valor;
      o.Caracteristica = item.NombreCampo;
      if (item.valor.length === 0 && item.LD === 'L') {
        o.Valor = props.formAgregarRegM.selectPosicion[0].label;
        // NombreReg = o.Valor;
      }
      aDatosKey.push(o);
    });

    const responseObtenerPosicion = await callEndpoint(
      obtenerPosicion({
        oIDPosicion: {
          IDPos: props.formAgregarRegM.selectPosicion[0].value,
        },
      })
    );
    const nombreLlave =
      responseObtenerPosicion.data.respuesta.aListaCabeceraAgrupado[0].oLlave[0].label;

    var aEncontradoLlave = rowsKeyData.filter((e) => e.NombreCampo === nombreLlave);
    if (aEncontradoLlave.length > 0) {
      NombreReg = aEncontradoLlave[0].valor;
    } else {
      NombreReg = responseObtenerPosicion.data.respuesta.aListaCabeceraAgrupado[0].Items[0].valor;
    }
    // let fCliente = {};
    // fCliente.Valor = props.formAgregarRegM.fCliente;
    // fCliente.Caracteristica = "Cliente";
    // aDatosKey.push(fCliente);

    let fMonto = {};
    fMonto.Valor = props.formAgregarRegM.fMonto;
    fMonto.Caracteristica = 'Importe';
    aDatosKey.push(fMonto);
    let dateRegistroM = {};
    dateRegistroM.Valor = props.formAgregarRegM.dateRegistroM;
    dateRegistroM.Caracteristica = 'FechaVcto';
    aDatosKey.push(dateRegistroM);
    let fMoneda = {};
    fMoneda.Valor = props.formAgregarRegM.selectMoneda[0].value; //props.formAgregarRegM.fMoneda;
    fMoneda.Caracteristica = 'MonedaImporte';
    aDatosKey.push(fMoneda);
    let fMonedaPago = {};
    fMonedaPago.Valor = props.formAgregarRegM.selectMonedaPago[0].value; //props.formAgregarRegM.fMonedaPago;
    fMonedaPago.Caracteristica = 'MonedaPago';
    aDatosKey.push(fMonedaPago);
    let RegistroManualCreac = {
      IDConcepto: props.formAgregarRegM.selectConcepto[0].value, //props.objRightPosicionClick.oItem.Id_Partida3,
      IDPosicion: props.formAgregarRegM.selectPosicion[0].value, //props.objRightPosicionClick.oItem.Id_Partida2,//props.formAgregarRegM.selectPosicion[0].value,
      FechaVigenciaRegistroM: props.formAgregarRegM.dateFechaVigenciaRegistroM,
      Items: aDatosKey,
      Etiquetas: props.formAgregarRegM.selectListEtiquetaManual,
      idusuario: authState.user.uid,
    };
    let oParam = {
      RegistroManualCreac,
    };
    var datos_reg = [];
    aDatosKey.forEach((element, index) => {
      var objInicial = {
        iIdCaracteristica: index,
        iIdInstancia: index,
        sNombreCampo: element.Caracteristica,
        sDatoPosicion: element.Valor,
      };
      datos_reg.push(objInicial);
    });

    const responseRegistrarRegistroM = await callEndpoint(registrarRegistroM(oParam));
    if (responseRegistrarRegistroM.data.cabecera.statusCode === 200) {
      const { IDRegistro } = responseRegistrarRegistroM.data.respuesta;

      // let quiero = flujoCajaState.aListaTC.filter(function (item, index) {
      //   return item.iIDTipoMoneda === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial;
      // })[0].fImporte;
      // let tengo = flujoCajaState.aListaTC.filter(function (item, index) {
      //   return item.sNombreMoneda === props.formAgregarRegM.selectMoneda[0].value; //flujoCajaState.selectMonedaTableFC[0].iIDTipoMonedaInicial
      // })[0].fImporte;
      // let factorMonedaTableTC = quiero / tengo;

      let findFactorTC = flujoCajaState.aListaTC.filter(function (item, index) {
        return (
          item.MonedaBase === props.formAgregarRegM.selectMoneda[0].value &&
          item.NombreMoneda === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial
        );
      });
      let factorMonedaTableTC = 0;
      if (findFactorTC.length > 0) {
        factorMonedaTableTC = findFactorTC[0].Factor * findFactorTC[0].Importe;
      } else {
        let findFactorTCRe = flujoCajaState.aListaTC.filter(function (item, index) {
          return (
            item.NombreMoneda === props.formAgregarRegM.selectMoneda[0].value &&
            item.MonedaBase === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial
          );
        });
        factorMonedaTableTC =
          findFactorTCRe[0].Factor / (findFactorTCRe[0].Factor * findFactorTCRe[0].Importe);
      }

      const valorXFactor = props.formAgregarRegM.fMonto * factorMonedaTableTC;
      // const valorXFactor = props.formAgregarRegM.fMonto;

      var aFinalArray = [];
      var finalArraySinFiltro = [];
      [...props.finalArray].forEach((element) => {
        var obj = { ...element };
        obj.Items = [];
        element.Items.forEach((element2) => {
          obj.Items.push({ ...element2 });
        });
        aFinalArray.push(obj);
      });
      props.finalArraySinFiltro.forEach((element) => {
        var obj = { ...element };
        obj.Items = [];
        element.Items.forEach((element2) => {
          obj.Items.push({ ...element2 });
        });
        finalArraySinFiltro.push(obj);
      });
      //let Id = props.finalArray.filter((e) => e.Id_Partida2 === 11 && e.IdentificadorCPR === "P")[0].Id;//props.objRightPosicionClick.oItem.Id;
      let Id = props.finalArray.filter(
        (e) =>
          e.Id_Partida2 === props.formAgregarRegM.selectPosicion[0].value &&
          e.IdentificadorCPR === 'P'
      )[0].Id; //props.objRightPosicionClick.oItem.Id;
      var objRightPosicionClick = props.finalArray.filter(
        (e) =>
          e.Id_Partida2 === props.formAgregarRegM.selectPosicion[0].value &&
          e.IdentificadorCPR === 'P'
      )[0];
      var iIdFindPosi;
      var aEncontrado = props.finalArray.filter((element) => {
        return (
          element.Id.includes(Id) &&
          element.Id !== Id &&
          Id.split('-').length + 1 === element.Id.split('-').length
        );
      });
      var aEncontradoHijos = props.finalArray.filter((element) => {
        return element.Id.includes(Id) && element.Id !== Id;
      });
      var sId = '';
      var maxId;
      var sIdSinFiltro = '';
      var maxIdSinFiltro;
      if (aEncontrado.length > 0) {
        var IdEncontrado = aEncontrado.sort((a, b) => (b.Id_Partida3 > a.Id_Partida3 ? 1 : -1))[0]
          .Id;

        var aId = IdEncontrado.split('-');
        maxId = parseInt(aId[aId.length - 1], 10) + 1;
        for (let index = 0; index < aId.length; index++) {
          const element = aId[index];
          if (index === 0) {
            sId = element;
          } else {
            if (index + 1 < aId.length) {
              sId = sId + '-' + element;
            }
          }
        }
        sId = sId + '-' + maxId.toString();
      } else {
        maxId = 1;
        sId = Id + '-1';
      }
      iIdFindPosi = Id;
      var indexFind = -1;
      var indexFindSinFiltro = -1;
      //  props.finalArray.find(function(item, i){
      aFinalArray.find(function (item, i) {
        if (item.Id === iIdFindPosi) {
          indexFind = i;
          return i;
        }
      });
      finalArraySinFiltro.find(function (item, i) {
        if (item.Id === iIdFindPosi) {
          indexFindSinFiltro = i;
          return i;
        }
      });
      var aEncontradoHijosSinFiltro = props.finalArraySinFiltro.filter((element) => {
        return element.Id.includes(Id) && element.Id !== Id;
      });
      if (aEncontradoHijosSinFiltro.length > 0) {
        var IdEncontradoSinFiltro = aEncontradoHijosSinFiltro.sort((a, b) =>
          b.Id_Partida3 > a.Id_Partida3 ? 1 : -1
        )[0].Id;
        var aIdSinFiltro = IdEncontradoSinFiltro.split('-');
        maxIdSinFiltro = parseInt(aIdSinFiltro[aIdSinFiltro.length - 1], 10) + 1;
        for (let index = 0; index < aIdSinFiltro.length; index++) {
          const element = aIdSinFiltro[index];
          if (index === 0) {
            sIdSinFiltro = element;
          } else {
            if (index + 1 < aIdSinFiltro.length) {
              sIdSinFiltro = sIdSinFiltro + '-' + element;
            }
          }
        }
        sIdSinFiltro = sIdSinFiltro + '-' + maxIdSinFiltro.toString();
      } else {
        maxIdSinFiltro = 1;
        sIdSinFiltro = Id + '-1';
      }
      var detalleFlujoCajaCabeceras = flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
      var aItems = [];

      var sFecha = formatString_DDMMYYYY_TO_YYYYMMDD(props.formAgregarRegM.dateRegistroM, '.');

      for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
        const element = detalleFlujoCajaCabeceras[index];
        let obj = {
          EsRaiz: 0,
          EsGrupo: 0,
          EsManual: 0,
          EsSplit: 0,
          Id: sId,
          Id2: sId,
          IdPadre: Id,
          IdPadre2: Id,
          IdHijo: sId,
          IdHijo2: sId,
          Id_Partida1: props.formAgregarRegM.selectConcepto[0].value, //objRightPosicionClick.Id_Partida3,//props.objRightPosicionClick.oItem.Id_Partida3,
          Id_Partida2: props.formAgregarRegM.selectPosicion[0].value, //objRightPosicionClick.Id_Partida2,//props.objRightPosicionClick.oItem.Id_Partida2,
          Id_Partida3: IDRegistro,
          Posicion: NombreReg, //props.formAgregarRegM.fCliente,
          bEditable: null,
          sAccion: 'N',
          Valor: 0,
        };
        if (element.sFechaMin <= sFecha && sFecha <= element.sFechaMax) {
          obj.EsManual = 1;
          obj.Valor = parseFloat(valorXFactor);
        }
        aItems.push(obj);
      }

      var oCabecera = {
        Id: sId,
        Id2: sId,
        IdPadre: Id,
        IdPadre2: Id,
        IdHijo: sId,
        IdHijo2: sId,
        EsRaiz: 0,
        EsGrupo: 0,
        EsManual: 1,
        EsSplit: 1,
        Posicion: NombreReg, //props.formAgregarRegM.fCliente,
        bEditable: false,
        Items: aItems,
        IdentificadorCPR: 'R',
        datos_posic: [],
        datos_reg: [],
        datos_carpeta: [],
        sFecha: sFecha,
      };
      aFinalArray.splice(indexFind + aEncontradoHijos.length + 1, 0, oCabecera);
      props.setFinalArray(aFinalArray);
      var aItemsSinFiltro = [];
      for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
        const element = detalleFlujoCajaCabeceras[index];
        let obj = {
          EsRaiz: 0,
          EsGrupo: 0,
          EsManual: 0,
          EsSplit: 0,
          Id: sIdSinFiltro,
          Id2: sIdSinFiltro,
          IdPadre: Id,
          IdPadre2: Id,
          IdHijo: sIdSinFiltro,
          IdHijo2: sIdSinFiltro,
          Id_Partida1: props.formAgregarRegM.selectConcepto[0].value, //objRightPosicionClick.Id_Partida3,//props.objRightPosicionClick.oItem.Id_Partida3,
          Id_Partida2: props.formAgregarRegM.selectPosicion[0].value, //objRightPosicionClick.Id_Partida2,//props.objRightPosicionClick.oItem.Id_Partida2,
          Id_Partida3: IDRegistro,
          Posicion: NombreReg, //props.formAgregarRegM.fCliente,
          sFecha: sFecha,
          bEditable: null,
          sAccion: 'C',
          Valor: 0,
        };
        if (element.sFechaMin <= sFecha && sFecha <= element.sFechaMax) {
          obj.EsManual = 1;
          obj.Valor = parseFloat(valorXFactor);
        }
        aItemsSinFiltro.push(obj);
      }
      var oCabecera = {
        Id: sIdSinFiltro,
        Id2: sIdSinFiltro,
        IdPadre: Id,
        IdPadre2: Id,
        IdHijo: sIdSinFiltro,
        IdHijo2: sIdSinFiltro,
        EsRaiz: 0,
        EsGrupo: 0,
        EsManual: 1,
        EsSplit: 1,
        Posicion: NombreReg, //props.formAgregarRegM.fCliente,
        bEditable: false,
        Id_Partida1: props.formAgregarRegM.selectConcepto[0].value, //objRightPosicionClick.Id_Partida3,//props.objRightPosicionClick.oItem.Id_Partida3,
        Id_Partida2: props.formAgregarRegM.selectPosicion[0].value, //objRightPosicionClick.Id_Partida2,//props.objRightPosicionClick.oItem.Id_Partida2,
        Id_Partida3: IDRegistro,
        Items: aItemsSinFiltro,
        IdentificadorCPR: 'R',
        datos_posic: [],
        sFecha: sFecha,

        // "datos_reg": [],
        //   "datos_reg":  [
        //     {
        //         "iIdCaracteristica": 6047,
        //         "iIdInstancia": 295305,
        //         "sNombreCampo": "NumDocFiscal",
        //         "sDatoPosicion": "33725606427.5"
        //     },
        //     {
        //         "iIdCaracteristica": 6048,
        //         "iIdInstancia": 295316,
        //         "sNombreCampo": "TipoRiesgo",
        //         "sDatoPosicion": "RIESGO 1"
        //     },
        //     {
        //         "iIdCaracteristica": 6049,
        //         "iIdInstancia": 295320,
        //         "sNombreCampo": "Oficina",
        //         "sDatoPosicion": "OF - Central"
        //     },
        //     {
        //         "iIdCaracteristica": 6050,
        //         "iIdInstancia": 295324,
        //         "sNombreCampo": "MonedaPago",
        //         "sDatoPosicion": "USD"
        //     },
        //     {
        //         "iIdCaracteristica": 6051,
        //         "iIdInstancia": 295324,
        //         "sNombreCampo": "MonedaImporte",
        //         "sDatoPosicion": "USD"
        //     },
        //     {
        //         "iIdCaracteristica": 6056,
        //         "iIdInstancia": 295340,
        //         "sNombreCampo": "CuentaCont",
        //         "sDatoPosicion": "CUENTA A10"
        //     },
        //     {
        //         "iIdCaracteristica": 6057,
        //         "iIdInstancia": 295348,
        //         "sNombreCampo": "Cliente",
        //         "sDatoPosicion": "CT177"
        //     }
        // ],
        datos_reg: datos_reg,
        datos_carpeta: [],
      };
      finalArraySinFiltro.splice(
        indexFindSinFiltro + aEncontradoHijosSinFiltro.length + 1,
        0,
        oCabecera
      );
      props.setFinalArraySinFiltro(finalArraySinFiltro);
      //
      var rowsInputSaldo = [...finalArraySinFiltro].filter((element) => {
        return element.Id.includes(Id.split('-')[0]);
      });
      // const rowsInput = [...props.finalArray];
      props.realizarRecalculo(rowsInputSaldo, aFinalArray);
      var detalleFlujoCajaCabeceras = oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
      var aTotales = [];
      var objecto = {};
      objecto.EsRaiz = 1;
      objecto.Id = 'Total Acumulado';
      objecto.sNombre = 'Total Acumulado';
      objecto.Items = [];
      var objectoParcial = {};
      objectoParcial.EsRaiz = 1;
      objectoParcial.Id = 'Total Periodo';
      objectoParcial.sNombre = 'Total Periodo';
      objectoParcial.Items = [];
      var aEncontradoGruposRaiz = aFinalArray.filter((element) => {
        return element.EsGrupo === 1 && element.EsRaiz === 1;
      });
      for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
        const element = detalleFlujoCajaCabeceras[index];
        var fAcumulado = 0;
        var item = {
          Id: objecto.Id + '-' + index,
          Id_Movimiento: null,
          EsRaiz: 1,
          EsGrupo: 1,
          Valor: 0,
        };
        for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
          const element = aEncontradoGruposRaiz[w];
          if (element.Posicion === 'Egreso') {
            fAcumulado = fAcumulado - element.Items[index].Valor;
          } else {
            fAcumulado = fAcumulado + element.Items[index].Valor;
          }
        }
        item.Valor = fAcumulado;
        objecto.Items.push(item);
        objectoParcial.Items.push({ ...item });
        //
      }
      props.setFinalArrayTotalParciales(objectoParcial);
      for (let index = 0; index < objecto.Items.length; index++) {
        const element = objecto.Items[index - 1];
        if (!element) {
          objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
        } else {
          objecto.Items[index].Valor = objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
        }
      }

      props.setFinalArrayTotal(objecto);
      dispatch(onOpenModalRegM(false));
      limpiarCampos();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Registro propio registrado',
        showConfirmButton: false,
        timer: 2000,
      });
      //Benji Mete Gol
      //limpiarCampos();
      var lista = [];
      const finalArraySinFiltroCopy = finalArraySinFiltro.map((object) => ({ ...object })); //[...props.finalArraySinFiltro];
      const selectedFilters = {
        posiciones: [],
        caracteristicas: [],
        valores: [],
      };
      flujoCajaState.aFiltrosDetalleFlujoCaja.forEach((element) => {
        element.selectPosicion.forEach((element2) => {
          selectedFilters.posiciones.push(element2.IdPosicion);
        });
        // element.selectCaracteristica.forEach(element2 => {
        selectedFilters.caracteristicas.push(element.selectCaracteristica);
        //  });
        element.selectValor.forEach((element2) => {
          selectedFilters.valores.push(element2);
        });
      });
      const { posiciones, valores, caracteristicas } = selectedFilters;
      if (posiciones.length === 0 && valores.length === 0 && caracteristicas.length === 0) {
        // Swal.fire({
        //   position: "center",
        //   icon: "warning",
        //   title: "No se encuentran Filtros aplicados.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
        lista = finalArraySinFiltroCopy;
      } else {
        // const filteredProducts = finalArraySinFiltro.filter(({ Id_Partida2, color }) => (
        //   posiciones.includes(Id_Partida2)
        //  ));
        // props.onRealizarDesdoblesAutomatico(filteredProducts);
        // var colectData = collect;
        finalArraySinFiltroCopy.forEach((element) => {
          if (element.IdentificadorCPR === 'C') {
            //
            element.Items.forEach((element2) => {
              element2.Valor = 0;
            });
            lista.push(element);
            // return;
          } else if (element.IdentificadorCPR === 'P') {
            // if (posiciones.includes(element.Id_Partida2)) {
            //   element.Items.forEach(element2 => {
            //     element2.Valor = 0;
            //   });
            //   lista.push(element)
            // }
            lista.push(element);
          } else if (element.IdentificadorCPR === 'D') {
            // if (posiciones.includes(element.Id_Partida2)) {
            //   element.Items.forEach(element2 => {
            //     element2.Valor = 0;
            //   });
            //   lista.push(element)
            // }
            lista.push(element);
          } else if (element.IdentificadorCPR === 'R') {
            let datos_reg = [];
            //
            if (posiciones.includes(element.Id_Partida2)) {
              // var test = colectData( element.datos_reg).whereIn('price', caracteristicas);
              var Id_Partida2 = element.Id_Partida2;
              let datos_reg = element.datos_reg;
              var no_error = false;
              //
              for (let index = 0; index < datos_reg.length; index++) {
                const element = datos_reg[index];
                var aEncontrado = selectedFilters.valores.filter(
                  (o) => o.NombreInstancia === element.sDatoPosicion && Id_Partida2 === o.IdPosicion
                );
                if (aEncontrado.length > 0) {
                  no_error = true;
                  break;
                }
              }
              if (no_error) {
                lista.push(element);
              }
            } else {
              lista.push(element);
            }
          }
        });
      }

      // await props.onRealizarDesdoblesAutomatico(lista);
      let result = await props.onRealizarDesdoblesAutomatico(lista);

      //----probando

      var aListaFinal = [];
      // for (let index = 0; index < resultCaceberas.length; index++) {
      for (var index2 = 0; index2 < result.length; index2++) {
        const element2 = { ...result[index2] };
        const elementCopia = { ...result[index2] };
        // for (let index2 = 0; index2 < result.length; index2++) {
        elementCopia.Items = [];
        if (element2.IdentificadorCPR === 'R') {
          var indexEncontrado = -1;
          element2.Items.find(function (item, i) {
            if (item.EsSplit === 0) {
              indexEncontrado = i;
              return i;
            }
          });
        }
        for (var index = 0; index < oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length; index++) {
          const element = { ...oFlujoCajaAgregar.detalleFlujoCajaCabeceras[index] };
          var acumulador = 0;
          //
          let { Id, EsGrupo, EsManual, EsRaiz, EsSplit, bEditable, Posicion } = element2;
          var obj = {};
          obj['Id'] = Id + '-' + (index + 1);
          obj['Id2'] = Id + '-' + (index + 1);
          obj['IdPadre'] = Id;
          obj['IdPadre2'] = Id;
          obj['IdHijo'] = Id + '-' + (index + 1);
          obj['IdHijo2'] = Id + '-' + (index + 1);
          obj['IdRegistro'] = index + 1;
          obj['sAccion'] = 'C';
          obj['EsRaiz'] = EsRaiz;
          obj['EsGrupo'] = EsGrupo;
          obj['EsManual'] = EsManual;
          obj['EsSplit'] = EsSplit;
          obj['bEditable'] = bEditable;
          obj['Posicion'] = Posicion;
          obj['Valor'] = 0;
          try {
            if (element2.IdentificadorCPR === 'R') {
              // if(flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo === "D"){
              if (flujoCajaState.selectPeridiocidadTableFC[0].value === 1) {
                const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = element2.Items[index];
                obj = {
                  ...obj,
                  ...{
                    EsGrupo: EsGrupo,
                    EsManual: EsManual,
                    EsRaiz: EsRaiz,
                    EsSplit: EsSplit,
                    bEditable: bEditable,
                  },
                };
                obj.Valor =
                  element2.Items[index].Valor * flujoCajaState.factorMonedaTableTC[0].valor;
              } else {
                if (element.desde <= indexEncontrado && indexEncontrado <= element.hasta) {
                  var aEncontrado = element2.Items.filter(function (item) {
                    return item.EsSplit === 0;
                  });
                  if (aEncontrado.length > 0) {
                    const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = aEncontrado[0];
                    obj = {
                      ...obj,
                      ...{
                        EsGrupo: EsGrupo,
                        EsManual: EsManual,
                        EsRaiz: EsRaiz,
                        EsSplit: EsSplit,
                        bEditable: bEditable,
                      },
                    };
                    obj.Valor = aEncontrado[0].Valor * flujoCajaState.factorMonedaTableTC[0].valor; //flujoCajaState.selectMonedaTableFC[0].factor
                  }
                }
              }
            }
          } catch (error) {}
          elementCopia.Items.push(obj);
        }
        aListaFinal.push(elementCopia);
      }
      // setFinalArray(aListaFinal)
      //  }else{
      //   aListaFinal = result;
      //  }
      var rowsInput = [];
      cloneDeep(aListaFinal).forEach((element) => {
        var obj = { ...element };
        obj.Items = [];
        element.Items.forEach((element2) => {
          obj.Items.push({ ...element2 });
        });
        rowsInput.push(obj);
      });
      var rowsInputSaldo = cloneDeep(rowsInput).filter((element) => {
        return element.Id.includes('S');
      });
      var rowsInputIngreso = cloneDeep(rowsInput).filter((element) => {
        return element.Id.includes('I');
      });
      var rowsInputEgreso = cloneDeep(rowsInput).filter((element) => {
        return element.Id.includes('E');
      });
      // realizarRecalculo(rowsInputSaldo, cloneDeep(rowsInput), cloneDeep(resultCaceberas));
      // realizarRecalculo(rowsInputIngreso, cloneDeep(rowsInput), cloneDeep(resultCaceberas));
      // realizarRecalculo(rowsInputEgreso, cloneDeep(rowsInput), cloneDeep(resultCaceberas));

      var rowsInputResponse = props.realizarRecalculoMejorado(
        ['S', 'I', 'E'],
        cloneDeep(rowsInput),
        cloneDeep(oFlujoCajaAgregar.detalleFlujoCajaCabeceras)
      );

      var aListaSplitAgrupadaSaldo = rowsInputResponse.filter((element) => {
        return element.Id.includes('S');
      });
      let collapseSaldo = props.collapseChild('S', aListaSplitAgrupadaSaldo);
      var aListaSplitAgrupadaIngreso = rowsInputResponse.filter((element) => {
        return element.Id.includes('I');
      });
      let collapseIngreso = props.collapseChild('I', aListaSplitAgrupadaIngreso);
      var aListaSplitAgrupadaEgreso = rowsInputResponse.filter((element) => {
        return element.Id.includes('E');
      });
      let collapseEgreso = props.collapseChild('E', aListaSplitAgrupadaEgreso);
      let aListaSplitAgrupada = [
        ...aListaSplitAgrupadaSaldo,
        ...aListaSplitAgrupadaIngreso,
        ...aListaSplitAgrupadaEgreso,
      ];
      let calcularTotalYsubTotalResponse = props.calcularTotalYsubTotal(
        aListaSplitAgrupada,
        oFlujoCajaAgregar.detalleFlujoCajaCabeceras
      );

      // realizarRecalculoSubTotal_Total(aListaSplitAgrupada, resultCaceberas);

      let listaCollapse = [];

      if (aListaSplitAgrupada.length > 0 && flujoCajaState.aplicarBusqueda === null) {
        // setSelectionCollapseModel(collapseEgreso);

        props.setSelectionCollapseModel(
          aListaSplitAgrupada.filter(function (item, index) {
            return item.collapse === false && item.collapseIcon === true;
          })
        );
        dispatch(onSetAplicarBusquedaFlujoCaja({ aplicarBusqueda: true }));
        // listaCollapse = [...collapseSaldo, ...collapseIngreso, ...collapseEgreso];
        listaCollapse = [...collapseEgreso];
      }

      if (flujoCajaState.aplicarBusqueda) {
        // let selectCollapseSaldo = verificarPosicionesColapsadas(aListaSplitAgrupada);
        listaCollapse = [...props.selectionCollapseModel];
        listaCollapse = cloneDeep(listaCollapse);

        const aPosicionesAbiertas = listaCollapse.filter(function (item, index) {
          return (
            item.IdentificadorCPR === 'P' && item.collapse === false && item.collapseIcon === true
          );
        });

        let listaNivelAbrir = [];
        for (let index = 0; index < aPosicionesAbiertas.length; index++) {
          const oItem = aPosicionesAbiertas[index];

          const aListaSplitAgrupadaClone = cloneDeep(aListaSplitAgrupada);
          var aEncontrado = aListaSplitAgrupadaClone.filter(function (item, index) {
            return (
              item.Id.includes(oItem.Id) &&
              item.Id != oItem.Id &&
              item.Id.split('-').length - oItem.Id.split('-').length === 1
            );
          });

          if (aEncontrado.length > 0) {
            for (let index = 0; index < aEncontrado.length; index++) {
              const element = aEncontrado[index];
              element.collapse = false;
              element.collapseIcon = true;
              listaNivelAbrir.push(element);
            }
          }

          var aEncontradoHijos = aListaSplitAgrupadaClone.filter(function (item, index) {
            return item.Id.indexOf(oItem.Id + '-') !== -1;
          });

          if (aEncontradoHijos.length > 0) {
            for (let iHijos = 0; iHijos < aEncontradoHijos.length; iHijos++) {
              const elementHijo = aEncontradoHijos[iHijos];
              elementHijo.collapse = false;
              elementHijo.collapseIcon = true;
              listaNivelAbrir.push(elementHijo);
            }
          }
        }

        listaCollapse = [...listaCollapse, ...listaNivelAbrir];

        listaCollapse = [
          ...listaCollapse
            .filter(function (item, index) {
              return item.Id.includes('S');
            })
            .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
          ...listaCollapse
            .filter(function (item, index) {
              return item.Id.includes('I');
            })
            .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
          ...listaCollapse
            .filter(function (item, index) {
              return item.Id.includes('E');
            })
            .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
        ];

        for (let index = 0; index < listaCollapse.length; index++) {
          const element = listaCollapse[index];
          var aEncontrado = aListaSplitAgrupada.filter(function (item, index) {
            return item.Id === element.Id;
          });

          if (aEncontrado.length > 0) {
            aEncontrado[0].collapse = element.collapse;

            aEncontrado[0].collapseIcon = element.collapseIcon;
          }
        }
      }

      if (props.selectedDesdobles.length > 0) {
        const prueba = (indice, colapseItem, lista) => {
          let aEncontrados = lista.filter((element) => {
            return (
              element.Id.includes(colapseItem.Id.substr(0, indice + 1)) &&
              indice >= element.Id.lastIndexOf('-')
            );
          });

          for (let i = 0; i < aEncontrados.length; i++) {
            const aCaractF = props.selectedDesdobles.filter((r) => r.collapse === true);
            if (aCaractF.length > 0) {
              let caractFilter = aCaractF[0].oDesdoble.iIdCaracteristica;
              let pLista = listaCollapse.filter(
                (r) =>
                  r.Id.includes(aEncontrados[i]['Id'] + '-') &&
                  r.collapse === true &&
                  r.datos_reg.filter((el) => {
                    return (
                      el.iIdCaracteristica === caractFilter &&
                      el.sDatoPosicion ===
                        aEncontrados[i]['datos_reg'].filter((el1) => {
                          return el1.iIdCaracteristica === caractFilter;
                        }).sDatoPosicion
                    );
                  })
              );
              let pLista1 = lista.filter(
                (r) =>
                  r.Id.includes(aEncontrados[i]['Id'] + '-') &&
                  r.collapse === true &&
                  r.datos_reg.filter((el) => {
                    return (
                      el.iIdCaracteristica === caractFilter &&
                      el.sDatoPosicion ===
                        aEncontrados[i]['datos_reg'].filter((el1) => {
                          return el1.iIdCaracteristica === caractFilter;
                        }).sDatoPosicion
                    );
                  })
              );
              if (pLista.length >= 1 && pLista1.length >= 1) {
                aEncontrados[i]['collapse'] = true;
                aEncontrados[i]['collapseIcon'] = false;
              } else {
                if (aEncontrados[i]['EsGrupo'] === 0) {
                  aEncontrados[i]['collapse'] = false;
                  aEncontrados[i]['collapseIcon'] = true;
                }
                //            else {
                //   aEncontrados[i]['collapse'] = false;
                //   aEncontrados[i]['collapseIcon'] = false;
                // }
              }
            }
          }
          if (colapseItem.Id.substr(0, indice).lastIndexOf('-') > 0) {
            lista = prueba(colapseItem.Id.substr(0, indice).lastIndexOf('-'), colapseItem, lista);
          }
          return lista;
        };
        //
        // let pLista = [];
        // for (let index = 0; index < listaCollapse.length; index++) {
        //   const colapseItem = listaCollapse[index];
        //   let aEncontrado = aListaSplitAgrupada.filter((element) => {
        //     return element.Id === colapseItem.Id;
        //   });
        //   if (aEncontrado.length > 0) {
        //     aEncontrado[0]['collapse'] = colapseItem['collapse'];
        //     aEncontrado[0]['collapseIcon'] = colapseItem['collapseIcon'];

        //     if (aListaSplitAgrupada.filter((r) => r.Id.includes(colapseItem.Id)).length === 1) {
        //       pLista.push(colapseItem);

        //       // let aEncontrados = aListaSplitAgrupada.filter((element) => {
        //       //   return element.Id.includes(
        //       //     colapseItem.Id.substr(0, colapseItem.Id.lastIndexOf('-') + 1)
        //       //   );
        //       // });
        //       // for (let i = 0; i < aEncontrados.length; i++) {
        //       //   aEncontrados[i]['collapse'] = colapseItem['collapse'];
        //       //   aEncontrados[i]['collapseIcon'] = colapseItem['collapseIcon'];
        //       // }
        //     }
        //   }
        // }
        // for (let i = 0; i < pLista.length; i++) {
        //   //  let listada= prueba(colapseItem.Id.lastIndexOf('-'), colapseItem, aListaSplitAgrupada);
        //   let listada = prueba(pLista[i].Id.lastIndexOf('-'), pLista[i], aListaSplitAgrupada);
        // }

        props.setFinalArray(aListaSplitAgrupada);
        //   if(selectedDesdobles.length>0 && flujoCajaState.aplicarBusqueda==null){
        //
        //    var aEncontrado =  aListaSplitAgrupada.filter(function(item,index){
        //       return item.collapse === false
        //   })
        //   setExpandedRow(aEncontrado)
        //   }
        // if(selectedDesdobles.length>0){
        //   onSetAplicarBusquedaFlujoCaja({aplicarBusqueda : true})
        // }
        console.log(aListaSplitAgrupada);
      }
    }
  };

  const handleChangeValor = (index, event) => {
    const rowsKeyData = [...props.formAgregarRegM.rowsKeyData];
    let sValor = event.target.value;
    let validate = null;
    if (sValor.length > 0) {
      validate = true;
    }
    let oParam = {
      sValor: sValor,
      validate: validate,
      rows: rowsKeyData,
      index: index,
      setRowsKeyData: setRowsKeyData,
    };
    const rows = [...rowsKeyData];
    rows[index]['valor'] = sValor;
    rows[index]['validate'] = validate;
    setRowsKeyData(rows);
  };

  const getPosicionXConcepto = async (oEvent) => {
    if (oEvent) {
      // dispatch(setSelectConceptoManual([oEvent]));
      let oParam = {
        selectConcepto: [oEvent],
        fMonedaPago: '',
        bMonedaPago: null,
        fCliente: '',
        bValidPosicion: null,
        fMonto: '',
        bValidMonto: null,
        fMoneda: '',
        bValidMoneda: null,
        dateRegistroM: null,
      };
      // props.setFormAgregarRegM({...props.formAgregarRegM,...oParam})
      let listaObtenerPosicionxConcepto = [];
      let { data } = await callEndpoint(
        obtenerPosicionXConcepto({
          oIDConcepto: {
            IDCon: oEvent.value,
          },
        })
      );
      data.respuesta.aListaPosiciones.forEach((element) => {
        let oPosicion = {};
        oPosicion.value = element.IdPosicion;
        oPosicion.label = element.NombrePosicion;
        listaObtenerPosicionxConcepto.push(oPosicion);
      });
      // let oParam2 = {
      oParam.listaPosicionManual = listaObtenerPosicionxConcepto; //[oEvent],
      oParam.selectPosicion = [];
      // };
      props.setFormAgregarRegM({ ...props.formAgregarRegM, ...oParam });
      // dispatch(onSetListaPosicionManual(listaObtenerPosicionxConcepto));
    } else {
      // dispatch(onSetListaPosicionManual([]));
      // dispatch(setSelectConceptoManual([]));
      // dispatch(setSelectPosicionManual([]));
      let oParam3 = {
        listaPosicionManual: [],
        selectConcepto: [],
        selectPosicion: [],
        rowsKeyData: [],
        fMonedaPago: '',
        bMonedaPago: null,
        fCliente: '',
        bValidPosicion: null,
        fMonto: '',
        bValidMonto: null,
        fMoneda: '',
        bValidMoneda: null,
        dateRegistroM: null,
      };
      props.setFormAgregarRegM({ ...props.formAgregarRegM, ...oParam3 });
    }
  };

  const changePosicionCaracteristica = async (oEvent) => {
    let aLista = [];
    let filtro = [];
    if (oEvent) {
      let oParam = {
        selectPosicion: [oEvent],
      };
      // dispatch(setSelectPosicionManual([oEvent]));
      const responseCaracteristicasXPosicion = await callEndpoint(
        obtenerCaracteristicasXPosicion({
          oIDPosicion: {
            iIDPos: oEvent.value,
          },
        })
      );
      responseCaracteristicasXPosicion.data.respuesta.aListaAgrupadaCaracPos[0].Items.forEach(
        function (item, index) {
          let o = { ...item };
          o.label = item.NombreCampo;
          o.valor = '';
          o.validate = null;
          aLista.push(o);
          filtro = aLista.filter(
            (item) =>
              // item.NombreCampo !== "Cliente" &&
              item.NombreCampo !== 'Importe' &&
              item.NombreCampo !== 'FechaVcto' &&
              item.NombreCampo !== 'MonedaImporte' &&
              item.NombreCampo !== 'MonedaPago'
          );
        }
      );
      oParam.rowsKeyData = filtro;
      // setRowsKeyData(filtro);
      props.setFormAgregarRegM({ ...props.formAgregarRegM, ...oParam });
    }
  };

  const handleChangeMultiKey = (event) => {
    var oParam = {
      selectListEtiquetaManual: event,
    };
    props.setFormAgregarRegM({ ...props.formAgregarRegM, ...oParam });
    // const rowsInput = [...rowsData];
    // rowsInput[index]["aSeleccionKey"] = event;
    // setRowsData(rowsInput);
  };

  const handleChangeSelectMoneda = (oEvent) => {
    let selectMoneda = [];
    let validate = null;
    if (oEvent) {
      validate = true;
      selectMoneda.push(oEvent);
    }
    let oParam = {
      selectMoneda: selectMoneda,
      bValidMoneda: validate,
    };
    props.setFormAgregarRegM({ ...props.formAgregarRegM, ...oParam });
  };

  const handleChangeSelectMonedaPago = (oEvent) => {
    let selectMonedaPago = [];
    let validate = null;
    if (oEvent) {
      validate = true;
      selectMonedaPago.push(oEvent);
    }
    let oParam = {
      selectMonedaPago: selectMonedaPago,
      bValidMonedaPago: validate,
    };
    props.setFormAgregarRegM({ ...props.formAgregarRegM, ...oParam });
  };

  return (
    <ModalComponent
      backdrop="static"
      keyboard={false}
      show={flujoCajaState.modalOpenModalRegM}
      onHide={onCloseModal}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Registro propio</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <RowContent>
          <ColFlex>
            <SubTitle subtitle="* Registrar campos obligatorios." weight="bold" />
            <ContentForm>
              <RowFlex>
                <LabelForm title="Concepto:"></LabelForm>
                <SelectForm
                  width={'320px'}
                  placeholder={'Concepto'}
                  bValid={true}
                  options={props.formAgregarRegM.listaConceptos}
                  value={props.formAgregarRegM.selectConcepto}
                  handleChange={(e, v) => getPosicionXConcepto(v)}
                ></SelectForm>
              </RowFlex>
              <RowFlex>
                <LabelForm title="Posición:"></LabelForm>
                <SelectForm
                  width={'320px'}
                  bValid={true}
                  placeholder={'Posición'}
                  options={props.formAgregarRegM.listaPosicionManual}
                  value={props.formAgregarRegM.selectPosicion}
                  handleChange={(e, v) => changePosicionCaracteristica(v)}
                  isDisabled={props.formAgregarRegM.selectConcepto.length === 0 ? true : false}
                ></SelectForm>
              </RowFlex>
              <RowFlex>
                <LabelForm title="* Fecha de Vigencia:" weight="bold"></LabelForm>
                <DatePickerForm
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  isClearable={true}
                  placeholderText="Ingrese"
                  locale="es"
                  showMonthDropdown
                  showYearDropdown
                  portalId="root-portal"
                  value={props.formAgregarRegM.dateFechaVigenciaRegistroM}
                  onChange={(evnt) => handleChangeFechaVigenciaRegistroM(evnt)}
                  minDate={new Date()}
                />
              </RowFlex>

              <RowFlex>
                <LabelForm title="* Monto:" weight="bold"></LabelForm>
                <InputNumberFormat
                  allowNegative={true}
                  placeholder="Ingrese..."
                  value={props.formAgregarRegM.fMonto}
                  validate={props.formAgregarRegM.bValidMonto}
                  onKeyPress={(evnt) => numbersWithComa(evnt)}
                  onValueChange={(evnt) => handleChangeMonto(evnt)}
                />
              </RowFlex>
              <RowFlex>
                <LabelForm title="* Moneda Importe:" weight="bold"></LabelForm>
                <SelectForm
                  width={'320px'}
                  bValid={props.formAgregarRegM.bValidMoneda}
                  value={props.formAgregarRegM.selectMoneda}
                  handleChange={(evnt, v) => handleChangeSelectMoneda(v)}
                  options={props.formAgregarRegM.currencyListManual}
                  placeholder="Seleccione Moneda"
                ></SelectForm>
              </RowFlex>
              <RowFlex>
                <LabelForm title="* Moneda de pago:" weight="bold"></LabelForm>
                <SelectForm
                  width={'320px'}
                  bValid={props.formAgregarRegM.bValidMonedaPago}
                  value={props.formAgregarRegM.selectMonedaPago}
                  handleChange={(evnt, v) => handleChangeSelectMonedaPago(v)}
                  options={props.formAgregarRegM.currencyListManual}
                  placeholder="Seleccione Moneda Pago"
                ></SelectForm>
              </RowFlex>
              <RowFlex>
                <LabelForm title="* Fecha:" weight="bold"></LabelForm>
                <DatePickerForm
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  isClearable={true}
                  placeholderText="Ingrese"
                  locale="es"
                  showMonthDropdown
                  showYearDropdown
                  portalId="root-portal"
                  value={props.formAgregarRegM.dateRegistroM}
                  minDate={dDateRange[0]}
                  maxDate={dDateRange[1]}
                  onChange={(evnt) => handleChangeFechaRegistroM(evnt)}
                />
              </RowFlex>
              <RowFlex>
                <LabelForm title="Etiquetas:"></LabelForm>
                <SelectForm
                  width={'320px'}
                  bValid={props.formAgregarRegM.bValidMonedaPago}
                  value={props.formAgregarRegM.selectListEtiquetaManual}
                  handleChange={(evnt, v) => handleChangeMultiKey(v)}
                  options={props.formAgregarRegM.listarEtiquetasManual}
                  placeholder="Seleccione Etiquetas"
                  isMultiple={true}
                ></SelectForm>
              </RowFlex>
            </ContentForm>
          </ColFlex>
          <ColFlex>
            {props.formAgregarRegM.rowsKeyData.length > 0 ? (
              <>
                <SubTitle subtitle="Característica:" weight="bold" />
              </>
            ) : (
              <></>
            )}
            <ContentForm>
              {props.formAgregarRegM.rowsKeyData.length > 0 ? (
                <>
                  {/* <SubTitle subtitle="Caracteristica:" weight="bold" /> */}
                  {props.formAgregarRegM.rowsKeyData.map((data, index) => {
                    const { label, valor, validate, KeyDet, LD } = data;
                    return (
                      <RowFlex>
                        {LD === 'L' ? (
                          <LabelForm title={'* ' + label + ':'} weight="bold"></LabelForm>
                        ) : (
                          <LabelForm title={label + ':'}></LabelForm>
                        )}
                        {KeyDet === 'KEY' ? (
                          <Input
                            placeholder="Ingrese..."
                            value={valor}
                            onChange={(evnt) => handleChangeValor(index, evnt)}
                            validate={validate}
                            disabled={true}
                          />
                        ) : (
                          <Input
                            placeholder="Ingrese..."
                            value={valor}
                            onChange={(evnt) => handleChangeValor(index, evnt)}
                            validate={validate}
                            disabled={false}
                          />
                        )}
                      </RowFlex>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </ContentForm>
          </ColFlex>
        </RowContent>
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <ButtonForm
          action={(e) => onCloseModal()}
          name="Cancelar"
          isAction={true}
          typeColor="warning"
        />
        <ButtonForm
          action={(e) => limpiarCampos(e)}
          name="Limpiar Campos"
          isAction={true}
          typeColor="primary"
        />
        <ButtonForm
          action={(e) => onPressGrabar()}
          name="Aplicar"
          isAction={true}
          typeColor="secondary"
        />
      </ModalComponent.Footer>
    </ModalComponent>
  );
};
