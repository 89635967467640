import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import {
  listarPosiciones,
  obtenerCaracPosTotal,
  obtenerListadoEtiquetas,
  obtenerListadoMoneda,
  obtenerPlantillaExcelRegManual,
} from '../../services';
import ExcelJS from 'exceljs';
export const exportToExcelRegManual = async (fileName) => {
  try {
    //obtener MOnedas
    var monedas = await obtenerListadoMoneda().call;
    var listaNombreMonedas = [];
    var listaNombreMonedas2 = [];
    var listaPosiciones = [];
    var listaEtiquetas = [];
    var posiciones = await listarPosiciones().call;
    var etiquetas = await obtenerListadoEtiquetas().call;
    var caracteristicas = await obtenerCaracPosTotal().call;
    let workbook = new ExcelJS.Workbook();
    //Creamos la primera Pagina - Moneda
    let worksheetRegManual = workbook.addWorksheet('RegistroManual');
    let worksheetMoneda = workbook.addWorksheet('Moneda', { state: 'hidden' });
    var titleRowMoneda = worksheetMoneda.addRow(['IdMoneda', 'Nombre']);
    titleRowMoneda.font = {
      name: 'Comic Sans MS',
      family: 2,
      size: 12,
      underline: 'none',
      bold: true,
    };
    worksheetMoneda.columns = [{ width: 15 }, { width: 15 }];
    monedas.data.respuesta.aListaMoneda.forEach((element) => {
      worksheetMoneda.addRow([element.iIDTipoMoneda, element.sAbreviatura]);
      listaNombreMonedas.push(element.sAbreviatura);
      listaNombreMonedas2.push(element.sAbreviatura);
    });
    //Creamos la segunda pagina de Posiciones
    let worksheetPosiciones = workbook.addWorksheet('Posiciones', { state: 'hidden' });
    var titleRowPosiciones = worksheetPosiciones.addRow(['IdPosicion', 'Nombre']);
    titleRowPosiciones.font = {
      name: 'Comic Sans MS',
      family: 2,
      size: 12,
      underline: 'none',
      bold: true,
    };
    // worksheetPosiciones.columns = [
    //   { header: 'Id', key: 'id', width: 10 },
    //   { header: 'Name', key: 'name', width: 32, style: { font: { name: 'Arial Black' } } },
    //   { header: 'D.O.B.', key: 'DOB', width: 10, style: { numFmt: 'dd/mm/yyyy' } }
    // ];
    worksheetPosiciones.columns = [{ width: 15 }, { width: 32 }];
    posiciones.data.respuesta.aListaPosiciones.forEach((element) => {
      worksheetPosiciones.addRow([element.iIdPosicion, element.sNombrePosicion]);
      listaPosiciones.push(element.sNombrePosicion);
    });
    //Creamos la Pestaña de Etiquetas
    let worksheetEtiquetas = workbook.addWorksheet('Etiquetas', { state: 'hidden' });
    var titleRowEtiquetas = worksheetEtiquetas.addRow(['IDEtiqueta', 'Nombre']);
    titleRowEtiquetas.font = {
      name: 'Comic Sans MS',
      family: 2,
      size: 12,
      underline: 'none',
      bold: true,
    };
    worksheetEtiquetas.columns = [{ width: 15 }, { width: 32 }];
    etiquetas.data.respuesta.aListaEtiqueta.forEach((element) => {
      worksheetEtiquetas.addRow([element.IDEtiqueta, element.NombreEtiqueta]);
      listaEtiquetas.push(element.NombreEtiqueta);
    });
    //Creamos la pestana Registro manual
    let aCabecera = [
      'Posicion',
      'Importe',
      'Fecha',
      'MonedaImporte',
      'MonedaPago',
      'Vigencia',
      'Etiquetas',
      //,"Oficina"	,"Nombredelfondo"	,"Tipoderiesgo"	,"Empresa"	,"Pais"	,"IDProveedorERP"	,"IDProveedorFiscal"	,"Proveedor",	"DOCFiscal",	"DOCERP",	"CtaCtble",	"AFP"	,"Region"	,"Provincia"	,"Distrito",	"IDClienteERP",	"IDClienteFiscal"	,"Cliente",	"Rubrodelcliente",	"Tipodetributo",	"NumCuenta"	,"Tipodecuenta"
    ];

    aCabecera = [...aCabecera, ...caracteristicas.data.respuesta.aCaracPosSinRep];
    aCabecera = aCabecera.filter((r) => r != 'FechaVcto');
    aCabecera = [...new Set(aCabecera)];
    var titleRowRegManual = worksheetRegManual.addRow(aCabecera);
    titleRowRegManual.font = {
      name: 'Comic Sans MS',
      family: 2,
      size: 12,
      underline: 'none',
      bold: true,
    };
    var aCabeceraStyle = [];
    aCabecera.forEach((element) => {
      aCabeceraStyle.push({ width: 20 });
    });
    worksheetRegManual.columns = aCabeceraStyle;
    var aLista = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    aLista.forEach((element) => {
      worksheetRegManual.getCell(element + 1).fill = {
        type: 'gradient',
        gradient: 'angle',
        degree: 0,
        stops: [
          { position: 0, color: { argb: 'FFEBF1DE' } },
          { position: 0.5, color: { argb: 'FFEBF1DE' } },
          { position: 1, color: { argb: 'FFEBF1DE' } },
        ],
      };
    });
    for (let i = 2; i < 100; i++) {
      worksheetRegManual.getCell('A' + i).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['Posiciones!B2:B' + listaPosiciones.length + 1], //[joineddropdownlistPosiciones]
      };
      //02.02.2022
      worksheetRegManual.getCell('C' + i).numFmt = 'dd/mm/yyyy';

      worksheetRegManual.getCell('D' + i).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['Moneda!$B$2:$B$' + listaNombreMonedas.length + 1], //[joineddropdownlistPosiciones]
      };
      worksheetRegManual.getCell('E' + i).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['Moneda!$B$2:$B$' + listaNombreMonedas.length + 1], //[joineddropdownlistPosiciones]
      };
      // worksheetRegManual.getCell('F' + i).numFmt = '@';
      worksheetRegManual.getCell('F' + i).numFmt = 'dd/mm/yyyy';
      worksheetRegManual.getCell('G' + i).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['Etiquetas!$B$2:$B$' + listaEtiquetas.length + 1], //[joineddropdownlistPosiciones]
      };
    }
    worksheetRegManual.getCell('C1').note = {
      texts: [
        {
          text: 'Considerar que se debe incorporar este registro con formato "FECHA" y teniendo el siguiente orden "DIA/MES/AÑO" o "DD/MM/AAAA".',
        },
      ],
      margins: { insetmode: 'auto' },
      editAs: 'twoCells', // El comentario será visible cuando se pase el cursor
    };
    worksheetRegManual.getCell('F1').note = {
      texts: [
        {
          text: 'Considerar que se debe incorporar este registro con formato "FECHA" y teniendo el siguiente orden "DIA/MES/AÑO" o "DD/MM/AAAA".',
        },
      ],
      margins: { insetmode: 'auto' },
      editAs: 'twoCells', // El comentario será visible cuando se pase el cursor
    };
    worksheetRegManual.getCell('G1').note = {
      texts: [
        {
          text: 'Para agregar mas de una etiqueta se puede hacer con el uso del separador ","',
        },
      ],
      margins: { insetmode: 'auto' },
      editAs: 'twoCells', // El comentario será visible cuando se pase el cursor
    };

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, fileName + '.xlsx');
    });
  } catch (error) {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Occurió un error en la descarga del Excel ' + fileName + '.xlsx',
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
