import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { ButtonForm } from '../index';
export const ModalConfirmation = (props) => {
  return (
    <ModalComponent
      className="modal-confimation"
      backdrop="static"
      keyboard={false}
      show={props.showModal}
      onHide={props.closeModal}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>{props.title}</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>{props.subtitle}</ModalComponent.Body>
      <ModalComponent.Footer>
        <ButtonForm
          name={props.labelButtonCancel}
          action={props.closeModal}
          isAction={true}
          type={'warning'}
        />
        <ButtonForm
          action={props.action}
          isAction={true}
          name={props.labelButtonConfirm}
          type={'primary'}
        />
      </ModalComponent.Footer>
    </ModalComponent>
  );
};
const ModalComponent = styled(Modal)`
  .modal-header {
    border-radius: '20px 20px 20px 20px !important';
  }
  .modal-header .btn-close {
    color: pink;
  }
  .modal-content {
    border-radius: '20px 20px 20px 20px !important';
    box-shadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px !important';
    border: none;
  }
`;
