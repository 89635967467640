import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {
  CrearConcepto,
  CrearEstructura,
  CrearFuncion,
  CrearPocision,
  CrearSistema,
  EditarConcepto,
  EditarEstructura,
  EditarFuncion,
  EditarPosicion,
  EditarSistema,
  ListarCampo,
  ListarConcepto,
  ListarEstructura,
  ListarFuncion,
  ListarPosicion,
  ListarSistema,
  SeguridadAuditoria,
  SeguridadPagos,
  ListarSocio,
  CrearLineaCredito,
  EditarLineaCredito,
  ListarLineaCredito,
  ListarPrestamo,
  CrearPrestamo,
  EditarPrestamo,
  Login,
  ListarAplazamiento,
  CrearAplazamiento,
  EditarAplazamiento,
  ListarFlujoCaja,
  CrearFlujoCaja,
  VisualizarFlujoCaja,
  Notificaciones,
  SeguridadUsuario,
  ListarRegistroFuente,
  TabRegistroPropio,
  CrearRegistroPropio,
  EditarRegistroPropio,
  ListarGestion,
  ListarDepositoPlazo,
  CrearDepositoPlazo,
  EditarDepositoPlazo,
  ListarFactoring,
  CrearFactoring,
  EditarFactoring,
  EditarRegistroFuente,
  CrearTipoCambio,
  EditarTipoCambio,
  ListarTipoCambio,
  Personalizacion,
  ListarTipoCambioConf,
  SeguridadRol,
  ListarAutomatizacion,
  CrearAutomatizacion,
  EditarAutomatizacion,
  Documentos,
  ListarDerivacion,
  CrearDerivacion,
  EditarDerivacion,
  ListarAdelanto,
  RecoveryPassword,
  ChangePassword,
  ListarPosicionMaster,
  SeguridadPerfil,
  CrearFlujoCajaF,
  Home,
  CrearFlujoCajaFNew,
  ListarSociedad,
  DashboardDetalle,
  Calendario,
} from '../scenes';

import { paths } from '../common/constants/paths';
import { PrivateRoute } from '../components';
import { AuthProvider } from '../common';

export const AppRouter = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate replace to={paths.LOGIN.BASE} />} />
          <Route path={paths.LOGIN.BASE} element={<Login />} />
          <Route path={paths.LOGIN.TENANT} element={<Login />} />
          <Route path={paths.LOGIN.RECOVERY_PASSWORD} element={<RecoveryPassword />} />
          <Route path={paths.LOGIN.CHANGE_PASSWORD} element={<ChangePassword />} />
          <Route
            exact
            path={paths.HOME.BASE}
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.HOME.DASHBOARD}
            element={
              <PrivateRoute>
                <DashboardDetalle />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SECURITY.USER}
            element={
              <PrivateRoute>
                <SeguridadUsuario />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SECURITY.ROLE}
            element={
              <PrivateRoute>
                <SeguridadRol />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SECURITY.PROFILE}
            element={
              <PrivateRoute>
                <SeguridadPerfil />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SECURITY.AUDIT}
            element={
              <PrivateRoute>
                <SeguridadAuditoria />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SECURITY.PAYMENT}
            element={
              <PrivateRoute>
                <SeguridadPagos />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.AUTOMATION.BASE}
            element={
              <PrivateRoute>
                <ListarAutomatizacion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.AUTOMATION.CREATE}
            element={
              <PrivateRoute>
                <CrearAutomatizacion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.AUTOMATION.EDIT}
            element={
              <PrivateRoute>
                <EditarAutomatizacion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.PERSONALIZATION}
            element={
              <PrivateRoute>
                <Personalizacion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.SOCIETY.BASE}
            element={
              <PrivateRoute>
                <ListarSociedad />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.CALENDAR.BASE}
            element={
              <PrivateRoute>
                <Calendario />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.SYSTEM.BASE}
            element={
              <PrivateRoute>
                <ListarSistema />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.SYSTEM.CREATE}
            element={
              <PrivateRoute>
                <CrearSistema />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.SYSTEM.EDIT}
            element={
              <PrivateRoute>
                <EditarSistema />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.FIELD}
            element={
              <PrivateRoute>
                <ListarCampo />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.FUNCTION.BASE}
            element={
              <PrivateRoute>
                <ListarFuncion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.FUNCTION.CREATE}
            element={
              <PrivateRoute>
                <CrearFuncion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.FUNCTION.EDIT}
            element={
              <PrivateRoute>
                <EditarFuncion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.CONCEPT.BASE}
            element={
              <PrivateRoute>
                <ListarConcepto />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.CONCEPT.CREATE}
            element={
              <PrivateRoute>
                <CrearConcepto />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.CONCEPT.EDIT}
            element={
              <PrivateRoute>
                <EditarConcepto />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.POSITION.MASTER}
            element={
              <PrivateRoute>
                <ListarPosicionMaster />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.POSITION.BASE}
            element={
              <PrivateRoute>
                <ListarPosicion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.POSITION.CREATE}
            element={
              <PrivateRoute>
                <CrearPocision />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.POSITION.CREATE_BY_ID}
            element={
              <PrivateRoute>
                <CrearPocision />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.POSITION.EDIT}
            element={
              <PrivateRoute>
                <EditarPosicion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.STRUCTURE.BASE}
            element={
              <PrivateRoute>
                <ListarEstructura />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.STRUCTURE.CREATE}
            element={
              <PrivateRoute>
                <CrearEstructura />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.STRUCTURE.EDIT}
            element={
              <PrivateRoute>
                <EditarEstructura />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.EXCHANGE_RATE}
            element={
              <PrivateRoute>
                <ListarTipoCambioConf />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.DERIVATION.BASE}
            element={
              <PrivateRoute>
                <ListarDerivacion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.DERIVATION.CREATE}
            element={
              <PrivateRoute>
                <CrearDerivacion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CONFIGURE.DERIVATION.EDIT}
            element={
              <PrivateRoute>
                <EditarDerivacion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.BUSINESS_PARTNER.PARTNERS}
            element={
              <PrivateRoute>
                <ListarSocio />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.BUSINESS_PARTNER.DOCUMENTS}
            element={
              <PrivateRoute>
                <Documentos />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.CREDIT_LINE.BASE}
            element={
              <PrivateRoute>
                <ListarLineaCredito />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.CREDIT_LINE.CREATE}
            element={
              <PrivateRoute>
                <CrearLineaCredito />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.CREDIT_LINE.EDIT}
            element={
              <PrivateRoute>
                <EditarLineaCredito />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.LOAN.BASE}
            element={
              <PrivateRoute>
                <ListarPrestamo />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.LOAN.CREATE}
            element={
              <PrivateRoute>
                <CrearPrestamo />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.LOAN.EDIT}
            element={
              <PrivateRoute>
                <EditarPrestamo />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.DELAY.BASE}
            element={
              <PrivateRoute>
                <ListarAplazamiento />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.DELAY.CREATE}
            element={
              <PrivateRoute>
                <CrearAplazamiento />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.DELAY.EDIT}
            element={
              <PrivateRoute>
                <EditarAplazamiento />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.ADVANCE}
            element={
              <PrivateRoute>
                <ListarAdelanto />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.FACTORING.BASE}
            element={
              <PrivateRoute>
                <ListarFactoring />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.FACTORING.CREATE}
            element={
              <PrivateRoute>
                <CrearFactoring />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.FACTORING.EDIT}
            element={
              <PrivateRoute>
                <EditarFactoring />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.DEPOSIT.BASE}
            element={
              <PrivateRoute>
                <ListarDepositoPlazo />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.DEPOSIT.CREATE}
            element={
              <PrivateRoute>
                <CrearDepositoPlazo />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.DEPOSIT.EDIT}
            element={
              <PrivateRoute>
                <EditarDepositoPlazo />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.EXCHANGE_RATE.BASE}
            element={
              <PrivateRoute>
                <ListarTipoCambio />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.EXCHANGE_RATE.CREATE}
            element={
              <PrivateRoute>
                <CrearTipoCambio />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.SOLUTION.EXCHANGE_RATE.EDIT}
            element={
              <PrivateRoute>
                <EditarTipoCambio />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CASH_FLOW.SOURCE_RECORD.BASE}
            element={
              <PrivateRoute>
                <ListarRegistroFuente />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CASH_FLOW.SOURCE_RECORD.EDIT}
            element={
              <PrivateRoute>
                <EditarRegistroFuente />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CASH_FLOW.OWN_RECORD.LIST}
            element={
              <PrivateRoute>
                <TabRegistroPropio />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CASH_FLOW.OWN_RECORD.CREATE}
            element={
              <PrivateRoute>
                <CrearRegistroPropio />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CASH_FLOW.OWN_RECORD.CREATE_BY_ID}
            element={
              <PrivateRoute>
                <CrearRegistroPropio />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CASH_FLOW.OWN_RECORD.EDIT}
            element={
              <PrivateRoute>
                <EditarRegistroPropio />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CASH_FLOW.REPORT.BASE}
            element={
              <PrivateRoute>
                <ListarFlujoCaja />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CASH_FLOW.REPORT.CREATE}
            element={
              <PrivateRoute>
                <CrearFlujoCajaF />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CASH_FLOW.REPORT.CREATEF}
            element={
              <PrivateRoute>
                <CrearFlujoCajaFNew />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.CASH_FLOW.REPORT.VIEW}
            element={
              <PrivateRoute>
                <VisualizarFlujoCaja />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.MANAGEMENT}
            element={
              <PrivateRoute>
                <ListarGestion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={paths.NOTIFICATION}
            element={
              <PrivateRoute>
                <Notificaciones />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};
