import React, { useEffect, useState } from 'react';
import { Modal, Upload } from 'antd';
import { ButtonForm, LabelForm, SelectForm } from '../../../../../components';
import { ColForm, RowForm } from '../../../../../styles';
import { DetalleTablaCombFlujoCaja } from './DetalleTablaCombFlujoCaja';
export const ModalDetalleCombFlujoCaja = (props) => {
  const [error, setError] = useState(null);
  const [selectedFileList, setSelectedFileList] = useState([]);

  useEffect(() => {
    if (!props.isOpen) return;

    return () => {
      setError(null);
      setSelectedFileList([]);
    };
  }, [props.isOpen]);

  const handleOnCancel = (e) => {
    props.handleClose(e);
  };

  const onOk = () => {
    if (selectedFileList.length === 0) {
      setError('No has seleccionado ningún archivo');
      return;
    }
    setError(null);
    const finalFiles = [];
    for (const selectedFile of selectedFileList) {
      finalFiles.push(selectedFile.originFileObj);
    }
    props.handleOk(finalFiles);
  };

  const onBuscarDetalle = () => {};

  return (
    <>
      <Modal
        open={props.isOpen}
        title={'Detalle de Combinación de Soluciones'}
        onOk={onOk}
        onCancel={handleOnCancel}
        footer={[
          <ButtonForm
            style={{ marginLeft: '5px' }}
            action={(oEvent) => onOk()}
            name={'Procesar'}
            isAction={true}
            typeColor="primary"
          />,
          <ButtonForm
            action={(oEvent) => handleOnCancel(oEvent)}
            name={'Cerrar'}
            isAction={false}
            typeColor="warning"
          />,
        ]}
      >
        <ColForm
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ textAlign: 'right', marginBottom: '15px' }}
        >
          <RowForm>
            <LabelForm width={'80px'} title={'Solución : '}></LabelForm>
            <SelectForm
              width="190px"
              bValid={true}
              value={[]}
              handleChange={(e, v) => onBuscarDetalle(v)}
              options={[]}
              placeholder={'Seleccione una Solución'}
            />
          </RowForm>

          <DetalleTablaCombFlujoCaja />
        </ColForm>
      </Modal>
    </>
  );
};
