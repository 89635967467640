import React, { useState } from 'react';
import { Modal } from 'antd';
import { addNodeUnderParent } from 'react-sortable-tree';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputText,
  Button,
  SelectForm,
  LabelForm,
  TabBarCreacion,
  SearchInputText,
  ButtonForm,
} from '../../../../../components';
import {
  CardItem,
  CheckboxStyle,
  ColForm,
  RowForm,
  TextCenter,
  TextSpan,
} from '../../../../../styles';
import { obtenerListadoSubAgrupadorCrearEstructura } from '../../../../../services';
import collect from 'collect.js';
import { theme, useFetchAndLoad } from '../../../../../common';
import Swal from 'sweetalert2';

export const ModalAgregarNodo = (props) => {
  const estructuraState = useSelector((store) => store.estructura);
  const { aListaConceptosXtipoEstructura } = estructuraState;
  const { loading, callEndpoint } = useFetchAndLoad();
  const [tabProceso, setTabProceso] = useState(0);
  const [subAgrupadorEstructuraPos, setSubAgrupadorEstructuraPos] = useState([]);
  const [selectPosicion, setSelectPosicion] = useState([]);
  const [globalFilterPosiciones, setGlobalFilterPosiciones] = useState('');

  const onCloseModal = (e) => {
    limpiarCampos();
    props.setOpen(false);
  };

  const onAgregar = (e) => {
    var sNombreNodo = '';
    if (props.formAgregarNodo.nombreNodo === '' && props.formAgregarNodo.iIdConceptoNodo === null) {
      Swal.fire({
        icon: 'warning',
        title: 'Seleccione el concepto o Ingrese el nombre.',
      });

      // props.setFormAgregarNodo({ ...props.formAgregarNodo, error: true });
    } else {
      if (props.formAgregarNodo.nombreNodo === '') {
        sNombreNodo = props.formAgregarNodo.sConceptoNodo;
      } else {
        sNombreNodo = props.formAgregarNodo.nombreNodo;
      }
      const NEW_NODE = {
        iId: props.formAgregarNodo.iId,
        sCadena: props.formAgregarNodo.sCadena,
        title: sNombreNodo,
        iIdConceptoNodo: props.formAgregarNodo.iIdConceptoNodo,
        sConceptoNodo: props.formAgregarNodo.sConceptoNodo,
        tipo: 'C',
        expanded: false,
      };
      const newTree = addNodeUnderParent({
        treeData: props.treeState.treeData,
        newNode: NEW_NODE,
        expandParent: true,
        parentKey: props.newRowInfoTree.rowInfo
          ? props.newRowInfoTree.rowInfo.treeIndex
          : undefined,
        getNodeKey: ({ treeIndex }) => treeIndex,
      });
      props.setTreeState({
        ...props.treeState,
        treeData: newTree.treeData,
      });
      limpiarCampos();
      props.setOpen(false);
    }
  };

  const onAgregarPosicion = (e) => {
    if (selectPosicion.length > 0) {
      Swal.fire({
        title: '¿Estas seguro de agregar las posiciones seleccionadas?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then((result) => {
        if (result.isConfirmed) {
          var aEncontradosMejorado = [];
          if (selectPosicion.length > 0) {
            selectPosicion.forEach((element) => {
              var o = {};
              o = { ...element };
              o.tituloCarpeta = props.newRowInfoTree.rowInfo.node.title;
              aEncontradosMejorado.push(o);
            });
          }
          var aListaPosicionesFinales = [...selectPosicion, ...aEncontradosMejorado];
          var aListaFiltrada = [];
          aListaPosicionesFinales.forEach((element) => {
            var aEncontrado = subAgrupadorEstructuraPos.filter(function (item, index) {
              return item.iIDConceptoSUB === element.iIDConceptoSUB;
            });
            aListaFiltrada.push(aEncontrado[0]);
          });
          var aLista = props.treeState.treeData;
          var treeState = props.treeState;
          var o = {};
          for (var i = 0; i < selectPosicion.length; i++) {
            const NEW_NODE = {
              title: selectPosicion[i].sNombreSubAgrupador,
              iIdConceptoNodo: props.newRowInfoTree.rowInfo.node
                ? props.newRowInfoTree.rowInfo.node.iIdConceptoNodo
                : null,
              sConceptoNodo: props.newRowInfoTree.rowInfo.node
                ? props.newRowInfoTree.rowInfo.node.sConceptoNodo
                : null,
              tipo: 'P',
              expanded: false,
              iIDConceptoSUB: selectPosicion[i].iIDConceptoSUB,
            };
            var newTree = addNodeUnderParent({
              treeData: i === 0 ? aLista : o.treeData, //props.treeState.treeData,
              newNode: NEW_NODE,
              expandParent: true,
              parentKey: props.newRowInfoTree.rowInfo
                ? props.newRowInfoTree.rowInfo.treeIndex
                : undefined,
              getNodeKey: ({ treeIndex }) => treeIndex,
            });
            o = {
              ...treeState,
              treeData: newTree.treeData,
            };
          }
          props.setTreeState({
            ...props.treeState,
            treeData: o.treeData,
          });
          Swal.fire('Éxito!', 'Se agregaron correctamente las posiciones.', 'success');
          setSelectPosicion([]);
          limpiarCampos();
          props.setOpen(false);
        }
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Se debe seleccionar alguna posición!',
      });
    }
  };

  const limpiarCampos = (oEvent) => {
    props.setFormAgregarNodo({});
    setSubAgrupadorEstructuraPos([]);
    setSelectPosicion([]);
    setGlobalFilterPosiciones('');
    setTabProceso(0);
  };

  const handleChangeNombreNodo = (oEvent) => {
    var sNombreNodo = oEvent.target.value;
    props.setFormAgregarNodo({
      ...props.formAgregarNodo,
      nombreNodo: sNombreNodo,
      error: false,
    });
  };

  const handleChangeConceptoXtipo = async (oEvent) => {
    var oParam = {};
    oParam.iIdConceptoNodo = null;
    oParam.sConceptoNodo = '';
    oParam.nombreNodo = '';
    oParam.error = false;
    if (oEvent) {
      oParam.iIdConceptoNodo = oEvent.value;
      oParam.sConceptoNodo = oEvent.label;
      oParam.nombreNodo = oEvent.label;
      props.setFormAgregarNodo({ ...props.formAgregarNodo, ...oParam });
    } else {
      if (props.formAgregarNodo.nombreNodo !== '') {
        oParam.error = false;
      }
      props.setFormAgregarNodo({ ...props.formAgregarNodo, ...oParam });
    }

    var oParamConsultarSubAgrupador = {
      sTipo: props.tipoEstructura,
      iIDCabecera: oEvent === null ? null : oEvent.value, //selectConcepto.length === 0 ? null : selectConcepto[0].value,
    };
    var aListaSubAgrupadores = [];
    var { data } = await callEndpoint(
      obtenerListadoSubAgrupadorCrearEstructura(oParamConsultarSubAgrupador)
    );
    data.respuesta.aListaSubAgrupador.forEach(function (item, index) {
      let obj = {};
      obj.iIDConceptoSUB = item.iIDConceptoSUB;
      obj.iIDCabecera = item.iIDCabecera;
      obj.sNombreAgrupador = item.sNombreAgrupador;
      obj.sNombreSubAgrupador = item.sNombreSubAgrupador;
      obj.sTipoConcepto = item.sTipoConcepto;
      obj.isChecked = false;
      aListaSubAgrupadores.push(obj);
    });
    if (aListaSubAgrupadores.length > 0) {
      var aLista = [];
      estructuraState.selectPosicionAsignada.forEach((element) => {
        aLista.push(element.iIDConceptoSUB);
      });
      var collectData = collect;
      var collection = collectData(aListaSubAgrupadores);
      aListaSubAgrupadores = collection.whereNotIn('iIDConceptoSUB', aLista).all();
    }
    setSubAgrupadorEstructuraPos(aListaSubAgrupadores);
  };

  const ListarSubAgrupador = async (id) => {
    var oParamConsultarSubAgrupador = {
      sTipo: props.tipoEstructura,
      iIDCabecera: id === null ? null : id, //selectConcepto.length === 0 ? null : selectConcepto[0].value,
    };
    var aListaSubAgrupadores = [];
    var { data } = await callEndpoint(
      obtenerListadoSubAgrupadorCrearEstructura(oParamConsultarSubAgrupador)
    );
    data.respuesta.aListaSubAgrupador.forEach(function (item, index) {
      let obj = {};
      obj.iIDConceptoSUB = item.iIDConceptoSUB;
      obj.iIDCabecera = item.iIDCabecera;
      obj.sNombreAgrupador = item.sNombreAgrupador;
      obj.sNombreSubAgrupador = item.sNombreSubAgrupador;
      obj.sTipoConcepto = item.sTipoConcepto;
      obj.isChecked = false;
      aListaSubAgrupadores.push(obj);
    });

    if (aListaSubAgrupadores.length > 0) {
      var aLista = [];
      estructuraState.selectPosicionAsignada.forEach((element) => {
        aLista.push(element.iIDConceptoSUB);
      });
      var collectData = collect;
      var collection = collectData(aListaSubAgrupadores);
      aListaSubAgrupadores = collection.whereNotIn('iIDConceptoSUB', aLista).all();
    }
    setSubAgrupadorEstructuraPos(aListaSubAgrupadores);
  };

  const onTabCarpeta = () => {
    setTabProceso(0);
  };

  const onTabPosicion = () => {
    setTabProceso(1);
    if (props.formAgregarNodo.isDisabled) {
      ListarSubAgrupador(props.formAgregarNodo.iIdConceptoNodo);
    }
  };

  const handleSubAgrupador = (oEvent) => {
    const keyword = oEvent.target.value;
    setGlobalFilterPosiciones(keyword);
  };

  const handleClickChecked = (index, oEvent, subAgrupador) => {
    const isChecked = oEvent.target.checked;
    const rows = [...subAgrupadorEstructuraPos];
    var aLista = [];
    var aListaFiltrada = [];
    estructuraState.listadoSubAgrupadorCrearEstructura.forEach(function (item, index) {
      var o = {};
      o = { ...item };
      if (o.iIDConceptoSUB === subAgrupador.iIDConceptoSUB) {
        o['isChecked'] = isChecked;
      }
      aLista.push(o);
    });
    rows.forEach(function (item, index) {
      var o = {};
      o = { ...item };
      if (o.iIDConceptoSUB === subAgrupador.iIDConceptoSUB) {
        o['isChecked'] = isChecked;
      }
      aListaFiltrada.push(o);
    });
    const filteredPeople = findPosicion(subAgrupador)
      ? filterPosicion(subAgrupador)
      : [...selectPosicion, subAgrupador];
    setSelectPosicion(filteredPeople);
    // updateListadoSubAgrupadorCrearEstructura(aLista);
    setSubAgrupadorEstructuraPos(aListaFiltrada);
  };

  const findPosicion = (person) => {
    return !!selectPosicion.find((p) => p.iIDConceptoSUB === person.iIDConceptoSUB);
  };

  const filterPosicion = (person) => {
    return selectPosicion.filter((p) => p.iIDConceptoSUB !== person.iIDConceptoSUB);
  };

  return (
    <Modal
      open={props.open}
      // title={<Title>Agregar </Title>}
      closable={false}
      footer={[
        <ButtonForm action={onCloseModal} name="Cancelar" isAction={true} typeColor="warning" />,
        <ButtonForm
          style={{ marginLeft: '5px' }}
          action={tabProceso === 0 ? onAgregar : onAgregarPosicion}
          typeColor="secondary"
          isAction={true}
          name="Aceptar"
        />,
      ]}
    >
      <TabBarCreacion
        onClickTab1={onTabCarpeta}
        tabProceso={tabProceso}
        labelTab1={'Agregar Carpeta'}
        onClickTab2={onTabPosicion}
        labelTab2={'Agregar Posición'}
        disabledTab2={props.formAgregarNodo.disabledMirror}
      />
      {tabProceso === 0 ? (
        <>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowForm>
              <LabelForm title={'Concepto :'} />
              <SelectForm
                value={
                  props.formAgregarNodo.iIdConceptoNodo === null
                    ? []
                    : [
                        {
                          value: props.formAgregarNodo.iIdConceptoNodo,
                          label: props.formAgregarNodo.sConceptoNodo,
                        },
                      ]
                }
                handleChange={(e, v) => handleChangeConceptoXtipo(v)}
                options={aListaConceptosXtipoEstructura}
                placeholder={'Seleccione Concepto'}
                isDisabled={props.formAgregarNodo.isDisabled}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowForm>
              <LabelForm title={'Nombre :'} />
              <InputText
                placeholder="Ingrese..."
                value={props.formAgregarNodo.nombreNodo}
                onChange={(evnt) => handleChangeNombreNodo(evnt)}
              />
            </RowForm>
          </ColForm>
        </>
      ) : (
        <>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowForm>
              <LabelForm title={'Concepto :'} />
              <SelectForm
                value={
                  props.formAgregarNodo.iIdConceptoNodo === null
                    ? []
                    : [
                        {
                          value: props.formAgregarNodo.iIdConceptoNodo,
                          label: props.formAgregarNodo.sConceptoNodo,
                        },
                      ]
                }
                handleChange={(e, v) => handleChangeConceptoXtipo(v)}
                options={aListaConceptosXtipoEstructura}
                placeholder={'Seleccione Concepto'}
                isDisabled={props.formAgregarNodo.isDisabled}
              />
            </RowForm>
          </ColForm>

          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowForm>
              <LabelForm title={'Posición :'} />
              <SearchInputText
                width={'98%'}
                placeholder={'Buscar posiciones...'}
                filter={globalFilterPosiciones}
                onChange={(e) => handleSubAgrupador(e)}
              />
            </RowForm>
          </ColForm>
          <ColForm
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ maxHeight: '150px', overflow: 'auto' }}
          >
            {subAgrupadorEstructuraPos.filter((caracFunc) => {
              return caracFunc.sNombreSubAgrupador
                .toLowerCase()
                .includes(!globalFilterPosiciones ? '' : globalFilterPosiciones.toLowerCase());
            }).length === 0 ? (
              <>
                <TextCenter>
                  <TextSpan>No se encontraros registros.</TextSpan>
                </TextCenter>
              </>
            ) : (
              subAgrupadorEstructuraPos
                .filter((caracFunc) => {
                  return caracFunc.sNombreSubAgrupador
                    .toLowerCase()
                    .includes(!globalFilterPosiciones ? '' : globalFilterPosiciones.toLowerCase());
                })
                .map((subAgrupador, index) => (
                  <>
                    <CardItem>
                      <CheckboxStyle
                        onClick={(evnt) => handleClickChecked(index, evnt, subAgrupador)}
                        checked={findPosicion(subAgrupador)}
                        size="small"
                      />
                      <span>{subAgrupador.sNombreSubAgrupador}</span>
                    </CardItem>
                  </>
                ))
            )}
          </ColForm>
        </>
      )}
    </Modal>
  );
};
