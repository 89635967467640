import React, { useEffect, useState } from 'react';
import {
  SearchInputText,
  ModalConfirmation,
  LoadingSpinner,
  TableGrid,
  ButtonAccion,
  ButtonForm,
} from '../../../../components';
import {
  obtenerSoluciones,
  eliminarSoluciones,
  obtenerInfoSolucion,
  simularPrestamo,
} from '../../../../services';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  onOpenModalUploadDataExcelConcepto,
  setUploadDataExcelConcepto,
  setListaPrestamo,
  limpiarCamposPR,
  filterListarPrestamo,
  updateTitle,
} from '../../../../redux/states';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import readXlsxFile from 'read-excel-file';
import { buildPath, MapImportDataConcepto, paths } from '../../../../common';
import { createPrestamoAdapter } from '../../../../adapters/createPrestamo.adapter';
import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  RowAccion,
  RowFlex,
} from '../../../../styles';
import { Dropdown } from 'antd';
import { items } from './misc';
import { FaPenToSquare } from 'react-icons/fa6';

export const ListarPrestamo = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [selectedPrestamo, setSelectedPrestamo] = useState([]);
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  // const [valueFileUploader, setValueFileUploader] = useState("");
  // 0 Sin acción - 1 Agregar - 2 Editar
  const [buttonAction, setButtonAction] = useState(0);
  const [tabProceso, setTabProceso] = useState(0);
  const [filter, setFilter] = useState('');
  const { loading, callEndpoint } = useFetchAndLoad();
  const titleExport = 'ConceptoData';
  const prestamoState = useSelector((store) => store.prestamo);
  const loadPrestamo = async () => {
    const responseListaPrestamo = await callEndpoint(
      obtenerSoluciones({
        oTipoSolucion: ['PR'],
      })
    );
    var listaPrestamo = [];
    if (responseListaPrestamo != null) {
      if (responseListaPrestamo.data) {
        if (responseListaPrestamo.data.cabecera.statusCode === 200) {
          for (
            let index = 0;
            index < responseListaPrestamo.data.respuesta.aListaSolucion.length;
            index++
          ) {
            const element = responseListaPrestamo.data.respuesta.aListaSolucion[index];
            var obj = createPrestamoAdapter(element);
            listaPrestamo.push(obj);
          }
        }
      }
    }
    dispatch(setListaPrestamo({ listaPrestamo: listaPrestamo }));
  };
  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: 'Listar préstamos' }));
      loadPrestamo();
    })();
  }, []);
  const findPrestamo = (person) => {
    return !!selectedPrestamo.find((p) => p.idPrestamo === person.idPrestamo);
  };
  const filterConcepto = (person) => {
    return selectedPrestamo.filter((p) => p.idPrestamo !== person.idPrestamo);
  };
  const handleChange = (person) => {
    const filteredPeople = findPrestamo(person)
      ? filterConcepto(person)
      : [...selectedPrestamo, person];
    setSelectedPrestamo(filteredPeople);
  };
  const onClickEditarPrestamo = async (row) => {
    const responseInfoSolucion = await callEndpoint(
      obtenerInfoSolucion({
        oSolucion: {
          IDSolucionBase: row.idPrestamo,
        },
      })
    );
    if (responseInfoSolucion != null) {
      if (responseInfoSolucion.data) {
        if (responseInfoSolucion.data.cabecera.statusCode === 200) {
          var oSolucion = responseInfoSolucion.data.respuesta.aInfoSolucion[0];
          navigate(buildPath(paths.SOLUTION.LOAN.EDIT, { id: oSolucion.IDSolucionBase }));
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseInfoSolucion.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <CheckboxStyle
              size="small"
              checked={findPrestamo(params.row)}
              onChange={() => handleChange(params.row)}
            />
          }
        </>
      ),
    },
    {
      field: 'codigo',
      headerName: 'Código',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'nombre',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'descripcion',
      headerName: 'Descripción',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'fechaVigencia',
      headerName: 'Fecha de Vigencia',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 75,
      renderCell: (params) => (
        <>
          <ButtonForm
            action={(oEvent) => onClickEditarPrestamo(params.row)}
            icon={<FaPenToSquare />}
            isAction={false}
            isOnlyIcon={true}
            typeColor="primary"
          />
        </>
      ),
    },
  ];
  const mapColumnsDownloadExcel = {
    sNombreAgrupador: 'Nombre',
    sTipoConcepto: 'Tipo',
    sReal_Proyectado: 'Real/Proyectado',
  };
  const onAgregarPrestamo = () => {
    // setButtonAction(1);
    dispatch(limpiarCamposPR());
    navigate(paths.SOLUTION.LOAN.CREATE);

    // dispatch(updateTitle({ title: "Crear Préstamo" }));
  };
  const onClickEliminarPrestamo = () => {
    if (selectedPrestamo.length > 0) {
      setShowModalEliminar(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarPrestamo(oEvent.target.value));
    } else {
      dispatch(filterListarPrestamo(''));
    }
  };
  const onCloseModalEliminar = () => {
    setShowModalEliminar(false);
  };
  const onConfirmarEliminar = async () => {
    var aListaSolucionEliminar = [];
    selectedPrestamo.forEach((element) => {
      var o = {};
      o.idSolucion = element.idPrestamo;
      aListaSolucionEliminar.push(o);
    });
    const responseListaPrestamo = await callEndpoint(
      eliminarSoluciones({ aListaSolucionEliminar: aListaSolucionEliminar })
    );
    setShowModalEliminar(false);
    if (responseListaPrestamo === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseListaPrestamo.data.cabecera.statusCode === 200) {
        loadPrestamo();
        setSelectedPrestamo([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseListaPrestamo.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseListaPrestamo.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };
  const handleChangeFile = async (e) => {
    let response = await readXlsxFile(e.target.files[0]);
    var mapObj = MapImportDataConcepto;
    let formato = true;
    if (response.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se ha cargado información en la importación de data de conceptos.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      let cabecera = response[0];
      for (let index = 0; index < cabecera.length; index++) {
        const element = cabecera[index];
        if (mapObj[element]) {
        } else {
          formato = false;
          break;
        }
      }
    }
    if (!formato) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title:
          'El excel no cumple con el formato de columnas predeterminada.Por favor, descargue la plantilla.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      let uploadDataExcel = [];
      for (let index = 1; index < response.length; index++) {
        const row = response[index];
        const emptyObject = {
          Id: 0,
          FilaAgrupada: 0,
          Nombre: '',
          TipoConcepto: '',
          Real_Proyectado: '',
          IdFuncion: 0,
          NombreCampo: '',
          TipoAtributo: '',
        };
        var obj = { ...emptyObject };
        obj.Id = row[0];
        obj.FilaAgrupada = row[1];
        obj.Nombre = row[2];
        obj.TipoConcepto = row[3];
        obj.Real_Proyectado = row[4];
        obj.IdFuncion = row[5];
        obj.NombreCampo = row[6];
        obj.TipoAtributo = row[7];
        uploadDataExcel.push(obj);
      }
      dispatch(setUploadDataExcelConcepto({ uploadDataExcel: uploadDataExcel }));
      dispatch(onOpenModalUploadDataExcelConcepto({ modalOpenUploadDataExcel: true }));
    }
  };

  return (
    <ColFlex>
      <ContentOption>
        <RowFlex>
          <SearchInputText
            placeholder={'Buscar préstamos...'}
            filter={prestamoState.searchtext}
            onChange={(e) => onChangeSearch(e)}
          />
          <ContentButton>
            <RowAccion>
              <ColFlex>
                <Dropdown trigger={['click']} menu={{ items: items(onClickEliminarPrestamo) }}>
                  <ButtonAccion />
                </Dropdown>
              </ColFlex>

              <ButtonForm
                action={onAgregarPrestamo}
                name="Agregar"
                isAction={true}
                typeColor="primary"
              />
            </RowAccion>
          </ContentButton>
        </RowFlex>
      </ContentOption>
      <TableGrid
        columns={columns}
        rows={
          prestamoState.searchtext.length === 0
            ? prestamoState.listaPrestamo
            : prestamoState.filterListaPrestamo
        }
        pageSize={15}
        getRowId={(row) => row.idPrestamo}
      />
      <ModalConfirmation
        showModal={showModalEliminar}
        closeModal={(e) => onCloseModalEliminar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
