export const cambiosModificadosAdapter = (user, c) => {
  return {
    ...user,
    Fecha: user.Fecha,
    IDRegistro: user.IDRegistro,
    IDUsuario: c != 1 ? user.IDUsuario : 0,
    MonedaImporte: user.MonedaImporte,
    valor: user.valor,
  };
};

export const cambiosEliminadosAdapter = (user, c) => ({
  ...user,
  IDRegistro: user.IDRegistro,
  IDUsuario: c != 1 ? user.IDUsuario : 0,
  valor: user.valor,
});
