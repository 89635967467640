import {
  Input,
  LabelForm,
  SubTitle,
  LoadingSpinner,
  ModalConfirmation,
  ButtonForm,
  SelectForm,
  PBR,
  PBRFunction,
} from '../../../../components';
import {
  createConceptoPosicionAdapter,
  createMonedaAbreviaturaAdapter,
  createRegistroManualEtiquetaAdapter,
} from '../../../../adapters';
import {
  updateTitle,
  setDataEditarRegistroF,
  setSelectConceptoFuente,
  setSelectPosicionFuente,
  limpiarCamposRegistroF,
  setSelectMontoRF,
  setSelectMonedaPagoRF,
  setSelectMonedaRF,
  setSelectFechaRF,
  setSelectFechaVigenciaRF,
  setRowKeyDataValorRF,
  setSelectedAccesoRF,
} from '../../../../redux/states';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  listarConceptos,
  obtenerPosicionXConcepto,
  obtenerCaracteristicasXPosicion,
  modificarRegistrosManuales,
  obtenerListadoMoneda,
  obtenerListadoEtiquetas,
  obtenerRegistrosManualesUno,
} from '../../../../services';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useFetchAndLoad } from '../../../../common/hooks';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  CheckboxStyle,
  ColFlex,
  ColForm,
  Content,
  ContentDouble,
  ContentForm,
  DatePickerForm,
  RowFooterButton,
  RowForm,
} from '../../../../styles';
import { numbersWithComa, paths, permisos, theme } from '../../../../common';

export const EditarRegistroFuente = (props) => {
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [loadingDetalle, setLoadingDetalle] = useState(true);
  const registroFState = useSelector((store) => store.registroFuente);
  const authState = useSelector((store) => store.auth);
  const personalizacionState = useSelector((store) => store.personalizacion);
  const [compartirAvailable, setCompartirAvailable] = useState(
    PBRFunction([permisos.COMPARTIR_REGISTRO])
  );
  const { callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (authState.user.uid && personalizacionState.listaPersonalizada.length > 0) {
      dispatch(updateTitle({ title: 'Editar registro fuente' }));
      (async () => {
        await onEditRF();
      })();
    }
  }, [authState.user, personalizacionState.listaPersonalizada]);

  const onEditRF = async () => {
    let aLista = [];
    let filtroDetalleCaract = [];
    let filtroCabCaract = [];
    var oParam = {
      RegistroManualConsulta: {
        IDRegistro: id,
      },
    };

    const responseObtRgManualUno = await callEndpoint(obtenerRegistrosManualesUno(oParam));

    if (responseObtRgManualUno != null) {
      if (responseObtRgManualUno.data) {
        if (responseObtRgManualUno.data.cabecera.statusCode === 200) {
          var oInfoRegManualUno = responseObtRgManualUno.data.respuesta.aListaRegistrosAgrupado[0];

          const responseListarConceptos = await callEndpoint(listarConceptos());

          let listaConceptos = [];

          for (
            let index = 0;
            index < responseListarConceptos.data.respuesta.aListaConceptos.length;
            index++
          ) {
            const element = responseListarConceptos.data.respuesta.aListaConceptos[index];
            listaConceptos.push(createConceptoPosicionAdapter(element));
          }

          let listaObtenerPosicionxConcepto = [];

          let obtenerPosicionXConceptoResponse = await callEndpoint(
            obtenerPosicionXConcepto({
              oIDConcepto: {
                IDCon: oInfoRegManualUno.IDConcepto,
              },
            })
          );

          obtenerPosicionXConceptoResponse.data.respuesta.aListaPosiciones.forEach((element) => {
            let oPosicion = {};
            oPosicion.value = element.IdPosicion;
            oPosicion.label = element.NombrePosicion;
            listaObtenerPosicionxConcepto.push(oPosicion);
          });

          oInfoRegManualUno.Items.forEach(function (item, index) {
            let o = { ...item };
            o.NombreCampo = item.Caracteristica;
            o.label = item.Caracteristica;
            o.valor = item.Valor;
            o.validate = null;
            aLista.push(o);
            filtroDetalleCaract = aLista.filter(
              (item) =>
                item.Caracteristica !== 'Importe' &&
                item.Caracteristica !== 'FechaVcto' &&
                item.Caracteristica !== 'MonedaImporte' &&
                item.Caracteristica !== 'MonedaPago'
            );
            filtroCabCaract = aLista.filter(
              (item) =>
                item.Caracteristica === 'Importe' ||
                item.Caracteristica === 'FechaVcto' ||
                item.Caracteristica === 'MonedaImporte' ||
                item.Caracteristica === 'MonedaPago'
            );
          });

          const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());

          var listarEtiquetasManual = [];
          for (
            let index = 0;
            index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
            index++
          ) {
            const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
            var obj = createRegistroManualEtiquetaAdapter(element);
            listarEtiquetasManual.push(obj);
          }
          var oParam = {};
          oParam.idRegistro = oInfoRegManualUno.IDRegistro;
          oParam.fCliente = '';
          oParam.fMonto = '';
          oParam.fMoneda = '';
          oParam.fMonedaPago = '';
          oParam.dateRegistroF = '';
          const dateFormat = oInfoRegManualUno.FechaVigenciaRegistroM.replaceAll('/', '.');
          oParam.dateFechaVigenciaRegistroF = dateFormat;
          oParam.fechaRegistro = oInfoRegManualUno.FechaRegistro;
          oParam.dateSelectedRegistroF = '';
          oParam.bValidNombre = true;
          oParam.bValidMonto = true;
          oParam.bValidMoneda = true;
          oParam.bValidMonedaPago = true;
          oParam.selectAccesoRF = !compartirAvailable
            ? oInfoRegManualUno.IDUsuario === 0
              ? true
              : false
            : false;
          oParam.selectConcepto = [
            {
              value: oInfoRegManualUno.IDConcepto,
              label: oInfoRegManualUno.NombreConcepto,
            },
          ];
          oParam.selectPosicion = [
            {
              value: oInfoRegManualUno.IDPosicion,
              label: oInfoRegManualUno.NombrePosicion,
            },
          ];
          oParam.listaConceptos = listaConceptos;

          oParam.rowsKeyData = filtroDetalleCaract; //[];
          oParam.listaPosicionFuente = listaObtenerPosicionxConcepto;
          filtroCabCaract.forEach((element) => {
            let valor = element.valor;
            let valorLabel = element.ValorLabel;
            if (element.Caracteristica === 'Importe') {
              oParam.fMonto = valor;
            } else if (element.Caracteristica === 'FechaVcto') {
              oParam.dateRegistroF = valor;
            } else if (element.Caracteristica === 'MonedaImporte') {
              oParam.fMoneda = valor;
              oParam.selectMoneda = [{ label: valorLabel, value: valor }];
            } else if (element.Caracteristica === 'MonedaPago') {
              oParam.fMonedaPago = valor;
              oParam.selectMonedaPago = [{ label: valorLabel, value: valor }];
            }
          });

          oParam.listarEtiquetasManual = listarEtiquetasManual;
          oParam.selectListEtiquetaManual = [];
          oInfoRegManualUno.aListaEtiquetas.forEach((element) => {
            var aEncontrado = listarEtiquetasManual.filter((item) => {
              return item.IDEtiqueta === parseInt(element.IDEtiqueta, 10);
            });
            if (aEncontrado.length > 0) {
              oParam.selectListEtiquetaManual.push(aEncontrado[0]);
            }
          });
          dispatch(setDataEditarRegistroF(oParam));
          await loadMoneda();
        }
      }
    }
    setLoadingDetalle(false);
  };

  const loadMoneda = async () => {
    const responseCurrencyList = await callEndpoint(obtenerListadoMoneda());
    let listaMoneda = [];
    if (responseCurrencyList != null) {
      if (responseCurrencyList.data) {
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'texto_moneda'
        )[0].DatoPersonalizacion;
        let { aListaMoneda } = responseCurrencyList.data.respuesta;
        for (let index = 0; index < aListaMoneda.length; index++) {
          const element = aListaMoneda[index];
          listaMoneda.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
        }
      }
    }
    dispatch(setDataEditarRegistroF({ listaMoneda: listaMoneda }));
  };

  const onChangeConcepto = async (e, value) => {
    let oParam = {};
    if (value) {
      oParam.selectConcepto = [value];
      oParam.selectPosicion = [];
      oParam.rowsKeyData = [];
      let listaObtenerPosicionxConcepto = [];
      let { data } = await callEndpoint(
        obtenerPosicionXConcepto({
          oIDConcepto: {
            IDCon: value.value,
          },
        })
      );
      data.respuesta.aListaPosiciones.forEach((element) => {
        let oPosicion = {};
        oPosicion.value = element.IdPosicion;
        oPosicion.label = element.NombrePosicion;
        listaObtenerPosicionxConcepto.push(oPosicion);
      });
      oParam.listaPosicionFuente = listaObtenerPosicionxConcepto;
    } else {
      oParam.listaPosicionFuente = [];
      oParam.selectConcepto = [];
      oParam.selectPosicion = [];
      oParam.rowsKeyData = [];
    }
    dispatch(setSelectConceptoFuente(oParam));
  };

  const onChangePosicion = async (e, value) => {
    let aLista = [];
    let filtro = [];
    let oParam = {};

    if (value) {
      oParam.selectPosicion = [value];
      const responseCaracteristicasXPosicion = await callEndpoint(
        obtenerCaracteristicasXPosicion({
          oIDPosicion: {
            iIDPos: value.value,
          },
        })
      );
      responseCaracteristicasXPosicion.data.respuesta.aListaAgrupadaCaracPos[0].Items.forEach(
        function (item, index) {
          let o = { ...item };
          o.label = item.NombreCampo;
          o.valor = '';
          o.validate = null;
          aLista.push(o);
        }
      );
      filtro = aLista.filter(
        (item) =>
          item.NombreCampo !== 'Importe' &&
          item.NombreCampo !== 'FechaVcto' &&
          item.NombreCampo !== 'MonedaImporte' &&
          item.NombreCampo !== 'MonedaPago'
      );
      oParam.rowsKeyData = filtro;
      dispatch(setSelectPosicionFuente(oParam));
    }
  };

  const onChangeMonto = (e) => {
    let monto = e.target.value;
    let validate = null;
    if (monto.length > 0) {
      validate = true;
    }
    let oParam = {
      fMonto: monto,
      bValidMonto: validate,
    };
    dispatch(setSelectMontoRF(oParam));
  };

  const onChangeMoneda = (e, value) => {
    let selectMoneda = [];
    let validate = null;
    if (value) {
      validate = true;
      selectMoneda.push(value);
    }
    let oParam = {
      selectMoneda: selectMoneda,
      bValidMoneda: validate,
    };
    dispatch(setSelectMonedaRF(oParam));
  };

  const onChangeMonedaPago = (e, value) => {
    let selectMonedaPago = [];
    let validate = null;
    if (value) {
      validate = true;
      selectMonedaPago.push(value);
    }
    let oParam = {
      selectMonedaPago: selectMonedaPago,
      bValidMonedaPago: validate,
    };
    dispatch(setSelectMonedaPagoRF(oParam));
  };

  const onChangeFecha = (e) => {
    const formatDate = moment(e[0]).format('DD/MM/YYYY');
    const dateFormat = formatDate.replaceAll('/', '.');
    let oParam = {
      dateRegistroF: dateFormat,
    };
    dispatch(setSelectFechaRF(oParam));
  };

  const onChangeFechaVigencia = (e) => {
    const formatDate = moment(e[0]).format('DD/MM/YYYY');
    const dateFormat = formatDate.replaceAll('/', '.');
    let oParam = {
      dateFechaVigenciaRegistroF: dateFormat,
    };
    dispatch(setSelectFechaVigenciaRF(oParam));
  };

  const onChangeCaracteristicaValor = (index, event) => {
    let sValor = event.target.value;
    let validate = null;
    if (sValor.length > 0) {
      validate = true;
    }
    var oParam = { valor: sValor, validate: validate, index: index };
    dispatch(setRowKeyDataValorRF(oParam));
  };

  const onChangeCompartir = (v) => {
    dispatch(setSelectedAccesoRF({ selectAccesoRF: !registroFState.selectAccesoRF }));
  };

  const onValidarCaracteristicas = async () => {
    var aEncontradoKeyVacio = registroFState.rowsKeyData.filter((element) => {
      return element.LD === 'L' && element.valor === '';
    });
    if (aEncontradoKeyVacio.length > 0) {
      Swal.fire({
        title:
          'La característica ' +
          aEncontradoKeyVacio[0].NombreCampo +
          ' se le asignará el valor de la posición seleccionada.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: '¿Desea Editar el registro?',
            html: !compartirAvailable
              ? `Compartir el registro :  <input id="compartido" style="width:18px;height:18px" type='checkbox' ${
                  registroFState.selectAccesoRF === true ? 'checked' : ''
                }/>`
              : '',
            icon: 'warning',
            showCancelButton: true,
            preConfirm: () => {
              var compartido;
              if (Swal.getPopup().querySelector('#compartido:checked')) {
                compartido = 1;
              } else compartido = 0;

              return { compartido: compartido };
            },
            preDeny: () => {},
            confirmButtonColor: theme.colors.primary,
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
          }).then(async (result) => {
            if (result.isConfirmed) {
              await onRegistrarRF(result.value.compartido);
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: '¿Desea Editar el registro?',
        html: !compartirAvailable
          ? `Compartir el registro :  <input id="compartido" style="width:18px;height:18px" type='checkbox' ${
              registroFState.selectAccesoRF === true ? 'checked' : ''
            }/>`
          : '',
        icon: 'warning',
        showCancelButton: true,
        preConfirm: () => {
          var compartido;
          if (Swal.getPopup().querySelector('#compartido:checked')) {
            compartido = 1;
          } else compartido = 0;

          return { compartido: compartido };
        },
        preDeny: () => {},
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await onRegistrarRF(result.value.compartido);
        }
      });
    }
  };

  const onRegistrarRF = async (acceso) => {
    let aDatosKey = [];
    registroFState.rowsKeyData.forEach(function (item, index) {
      let o = {};
      o.Valor = item.valor;
      o.Caracteristica = item.NombreCampo;
      if (item.valor.length === 0 && item.LD === 'L') {
        o.Valor = registroFState.selectPosicion[0].label;
      }
      aDatosKey.push(o);
    });
    let fMonto = {};
    fMonto.Valor = registroFState.fMonto;
    fMonto.Caracteristica = 'Importe';
    aDatosKey.push(fMonto);
    let dateRegistroF = {};
    dateRegistroF.Valor = registroFState.dateRegistroF;
    dateRegistroF.Caracteristica = 'FechaVcto';
    aDatosKey.push(dateRegistroF);
    let fMoneda = {};
    fMoneda.Valor = registroFState.selectMoneda[0].value;
    fMoneda.Caracteristica = 'MonedaImporte';
    aDatosKey.push(fMoneda);
    let fMonedaPago = {};
    fMonedaPago.Valor = registroFState.selectMonedaPago[0].value;
    fMonedaPago.Caracteristica = 'MonedaPago';
    aDatosKey.push(fMonedaPago);
    let RegistroManualModif = {
      IDConcepto: registroFState.selectConcepto[0].value,
      IDPosicion: registroFState.selectPosicion[0].value,
      IDRegistro: registroFState.idRegistro,
      FechaVigenciaRegistroM: registroFState.dateFechaVigenciaRegistroF,
      Items: aDatosKey,
      //  Etiquetas: registroFState.selectListEtiquetaManual,
      Etiquetas: [],
      idusuario: acceso === 0 ? authState.user.uid : 0,
      Cambios: [
        {
          IDUsuario: acceso === 0 ? authState.user.uid : 0,
          IDRegistro: registroFState.idRegistro,
          MonedaImporte: registroFState.fMonto,
          Fecha: registroFState.dateRegistroF,
        },
      ],
    };
    let oParam = {
      RegistroManualModif,
    };
    const responseRegistrarRegistroF = await callEndpoint(modificarRegistrosManuales(oParam));
    if (responseRegistrarRegistroF === null) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Ocurrió un error de servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseRegistrarRegistroF.data) {
        if (responseRegistrarRegistroF.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Registro propio editado correctamente.',
            showConfirmButton: false,
            timer: 2000,
          });
          limpiarCampos();
          navigate(paths.CASH_FLOW.SOURCE_RECORD.BASE);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRegistrarRegistroF.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error de servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onClose = () => {
    setShowModalCancelar(true);
  };

  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };

  const onConfirmarClose = async () => {
    limpiarCampos();
    setShowModalCancelar(false);
    navigate(paths.CASH_FLOW.SOURCE_RECORD.BASE);
  };

  const onGrabar = async () => {
    if (
      registroFState.fMonto !== '' &&
      registroFState.dateRegistroF !== '' &&
      registroFState.dateFechaVigenciaRegistroF !== '' &&
      registroFState.selectMoneda.length > 0 &&
      registroFState.selectMonedaPago.length > 0
    ) {
      await onValidarCaracteristicas();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Registrar los campos obligatorios.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const limpiarCampos = () => {
    dispatch(limpiarCamposRegistroF());
  };

  return loadingDetalle === true ? (
    <LoadingSpinner />
  ) : registroFState.idRegistro === null ? (
    <Content>No se encontró información</Content>
  ) : (
    <Content style={{ flexDirection: 'row' }}>
      <ContentForm>
        <SubTitle subtitle="1. Registrar datos generales del registro fuente." />
        <RowForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Concepto (*):" obligatorio={true}></LabelForm>
              <SelectForm
                value={registroFState.selectConcepto}
                handleChange={(e, v) => onChangeConcepto(e, v)}
                options={registroFState.listaConceptos}
                placeholder={'Seleccione Concepto'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Posición (*):" obligatorio={true}></LabelForm>

              <SelectForm
                value={registroFState.selectPosicion}
                handleChange={(e, v) => onChangePosicion(e, v)}
                options={registroFState.listaPosicionFuente}
                placeholder={'Seleccione Posición'}
                isDisabled={registroFState.selectConcepto.length === 0 ? true : false}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Fecha de Vigencia (*):" obligatorio={true}></LabelForm>
              <DatePickerForm
                dateFormat="dd/MM/yyyy"
                selectsRange={true}
                isClearable={true}
                placeholderText="Ingrese"
                locale="es"
                showMonthDropdown
                showYearDropdown
                portalId="root-portal"
                value={registroFState.dateFechaVigenciaRegistroF}
                onChange={(evnt) => onChangeFechaVigencia(evnt)}
                minDate={new Date()}
                alignText={'center'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Monto (*):" obligatorio={true}></LabelForm>
              <Input
                placeholder="Ingrese..."
                value={registroFState.fMonto}
                onKeyPress={(evnt) => numbersWithComa(evnt)}
                onChange={(evnt) => onChangeMonto(evnt)}
                validate={registroFState.bValidMonto}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Moneda (*):" obligatorio={true}></LabelForm>

              <SelectForm
                value={registroFState.selectMoneda}
                handleChange={(e, v) => onChangeMoneda(e, v)}
                options={registroFState.listaMoneda}
                placeholder={'Seleccione Moneda'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Moneda de pago (*):" obligatorio={true}></LabelForm>

              <SelectForm
                value={registroFState.selectMonedaPago}
                handleChange={(e, v) => onChangeMonedaPago(e, v)}
                options={registroFState.listaMoneda}
                placeholder={'Seleccione Moneda Pago'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Fecha (*):" obligatorio={true}></LabelForm>
              <DatePickerForm
                dateFormat="dd/MM/yyyy"
                selectsRange={true}
                isClearable={true}
                placeholderText="Ingrese"
                locale="es"
                showMonthDropdown
                showYearDropdown
                portalId="root-portal"
                value={registroFState.dateRegistroF}
                onChange={(evnt) => onChangeFecha(evnt)}
                alignText={'center'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title="Fecha Registro:"></LabelForm>
              <DatePickerForm
                dateFormat="dd/MM/yyyy"
                selectsRange={true}
                isClearable={true}
                placeholderText="Ingrese"
                locale="es"
                showMonthDropdown
                showYearDropdown
                portalId="root-portal"
                value={registroFState.fechaRegistro}
                minDate={new Date()}
                alignText={'center'}
                disabled={true}
              />
            </RowForm>
          </ColForm>
          <PBR permiso={[permisos.COMPARTIR_REGISTRO]}>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title="Compartir registro:"></LabelForm>
                <CheckboxStyle
                  checked={registroFState.selectAccesoRF}
                  onChange={(v) => onChangeCompartir(v)}
                  size="small"
                />
              </RowForm>
            </ColForm>
          </PBR>
        </RowForm>
        <SubTitle subtitle="* Registrar campos obligatorios." obligatorio={true} />
        <RowFooterButton>
          <ButtonForm
            action={(e) => onClose()}
            name="Cancelar"
            isAction={true}
            typeColor="warning"
          />
          <ButtonForm
            action={(e) => limpiarCampos(e)}
            name="Limpiar"
            isAction={true}
            typeColor="primary"
          />
          <ButtonForm
            action={(e) => onGrabar()}
            name="Guardar"
            isAction={true}
            typeColor="secondary"
          />
        </RowFooterButton>
      </ContentForm>
      <ContentDouble>
        {registroFState.rowsKeyData.length > 0 ? (
          <ColFlex
            style={{ marginTop: '5px', rowGap: '5px', marginBottom: '5px', padding: '15px 35px' }}
          >
            <SubTitle subtitle="Caracteristica:" obligatorio={true} />
            <RowForm>
              {registroFState.rowsKeyData.map((data, index) => {
                const { label, valor, validate, LD, KeyDet } = data;
                return (
                  <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                    <RowForm>
                      {LD === 'L' ? (
                        <LabelForm title={label + ' (*):'} obligatorio={true}></LabelForm>
                      ) : (
                        <LabelForm title={label + ':'}></LabelForm>
                      )}
                      {KeyDet === 'KEY' ? (
                        <Input
                          placeholder="Ingrese..."
                          value={valor}
                          onChange={(evnt) => onChangeCaracteristicaValor(index, evnt)}
                          validate={validate}
                          disabled={true}
                        />
                      ) : (
                        <Input
                          placeholder="Ingrese..."
                          value={valor}
                          onChange={(evnt) => onChangeCaracteristicaValor(index, evnt)}
                          validate={validate}
                          disabled={false}
                        />
                      )}
                    </RowForm>
                  </ColForm>
                );
              })}
            </RowForm>
          </ColFlex>
        ) : (
          <></>
        )}
      </ContentDouble>
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la edición del registro fuente?"
        action={(e) => onConfirmarClose(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </Content>
  );
};
