import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonForm,
  ButtonAccion,
  LoadingSpinner,
  SearchInputText,
  TableGrid,
} from '../../../../components';
import { useFetchAndLoad } from '../../../../common/hooks';
import { eliminarDerivada, eliminarJob, obtenerDerivadas, obtenerJob } from '../../../../services';
import { useNavigate } from 'react-router-dom';
import { ColFlex, ContentButton, ContentOption, RowAccion, RowFlex } from '../../../../styles';
import {
  updateTitle,
  setListarAutomatizacion,
  filterListarDerivaciones,
  setListarDerivacion,
} from '../../../../redux/states';
import { columns } from './misc/column';
import { datosDeriva, items } from './misc';
import { Dropdown } from 'antd';
import { buildPath, paths } from '../../../../common';

export const ListarDerivacion = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const derivacionState = useSelector((store) => store.derivacion);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [selectedDerivada, setSelectedDerivada] = useState([]);

  useEffect(() => {
    dispatch(updateTitle({ title: 'Lista de derivaciones' }));
    (async () => {
      loadDerivacion();
    })();
  }, []);

  const loadDerivacion = async () => {
    const responseListarDerivacion = await callEndpoint(obtenerDerivadas());
    var listaDerivaciones = [];
    for (let i = 0; i < responseListarDerivacion.data.respuesta.aListaVersiones.length; i++) {
      const ele = responseListarDerivacion.data.respuesta.aListaVersiones[i];
      listaDerivaciones.push(ele);
    }
    dispatch(setListarDerivacion({ listaDerivaciones: listaDerivaciones }));
  };

  const onEliminar = async () => {
    let ids = [];
    selectedDerivada.map((r) => ids.push(r.IDDerivada));

    var respuesta = await callEndpoint(eliminarDerivada({ oIDDerivada: ids }));
    if (respuesta.data) {
      var { data } = respuesta;
      if (data.cabecera.statusCode === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: respuesta.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
        loadDerivacion();
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: respuesta.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onEditar = async (row) => {
    navigate(buildPath(paths.CONFIGURE.DERIVATION.EDIT, { id: row.IDDerivada }));
  };

  const onAgregarDerivacion = async () => {
    navigate(paths.CONFIGURE.DERIVATION.CREATE);
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarDerivaciones(oEvent.target.value));
    } else {
      dispatch(filterListarDerivaciones(''));
    }
  };

  const findDerivada = (person) => {
    return !!selectedDerivada.find((p) => p.IDDerivada === person.IDDerivada);
  };
  const filterDerivada = (person) => {
    return selectedDerivada.filter((p) => p.IDDerivada !== person.IDDerivada);
  };
  const onCheckRow = (person) => {
    const filteredPeople = findDerivada(person)
      ? filterDerivada(person)
      : [...selectedDerivada, person];
    setSelectedDerivada(filteredPeople);
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={'Buscar Derivacion...'}
              filter={derivacionState.searchtext}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: items(onEliminar),
                  }}
                >
                  <ButtonAccion />
                </Dropdown>
                <ButtonForm
                  action={onAgregarDerivacion}
                  name="Agregar"
                  isAction={true}
                  typeColor="primary"
                />
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        <TableGrid
          columns={columns(onEditar, findDerivada, onCheckRow)}
          rows={
            derivacionState.searchtext.length === 0
              ? derivacionState.listaDerivaciones
              : derivacionState.filterListaDerivaciones
          }
          pageSize={15}
          getRowId={(row) => row.IDDerivada}
        />
      </ColFlex>
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
