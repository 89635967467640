import { FaList, FaMinus, FaPenToSquare, FaTrashCan } from 'react-icons/fa6';
import { ButtonForm } from '../../../../components';
import { RowButton } from '../../../../styles';

export const columns = (
  handleOpenModalRol,
  handleOpenModalUsuarioEdit,
  handleDeleteUsuario,
  t,
  id
) => [
  {
    field: 'Usuario',
    headerName: t('usuario.listaUsuario.1'),
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'Correo',
    headerName: t('usuario.listaUsuario.2'),
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'edit',
    headerName: t('usuario.listaUsuario.3'),
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'left',
    width: 75,
    renderCell: (params) =>
      !params.row.Admin ? (
        <RowButton>
          <ButtonForm
            action={(oEvent) => handleOpenModalRol(oEvent, params.row)}
            icon={<FaList />}
            isAction={false}
            isOnlyIcon={true}
            typeColor="primary"
          />
        </RowButton>
      ) : (
        <></>
      ),
  },
  {
    field: 'edite',
    headerName: '',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    width: 75,
    renderCell: (params) =>
      id != params.id ? (
        !params.row.Admin ? (
          <RowButton>
            <ButtonForm
              action={(oEvent) => handleOpenModalUsuarioEdit(oEvent, params.row)}
              icon={<FaPenToSquare />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
            />
            <ButtonForm
              action={(oEvent) => handleDeleteUsuario(oEvent, params.row)}
              icon={<FaTrashCan />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
            />
          </RowButton>
        ) : (
          <></>
        )
      ) : (
        <RowButton>
          <ButtonForm
            action={(oEvent) => handleOpenModalUsuarioEdit(oEvent, params.row)}
            icon={<FaPenToSquare />}
            isAction={false}
            isOnlyIcon={true}
            typeColor="primary"
          />
        </RowButton>
      ),
  },
];

export const columnsRol = (deleteTableRows, t) => [
  {
    field: 'selectRol',
    headerName: t('usuario.modalRol.listaRol.1'),
    flex: 1,
    renderCell: (params) => <>{params.value[0].label}</>,
  },
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <RowButton>
        <ButtonForm
          action={() => deleteTableRows(params.id)}
          icon={<FaMinus />}
          isAction={false}
          isOnlyIcon={true}
          typeColor="primary"
        />
      </RowButton>
    ),
  },
];
