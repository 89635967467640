import { Menu } from "antd";
import styled from "styled-components";
import { theme } from "../common";

export const SideImage = styled.div`
  height: 40px;
  margin: 5px;
  border-radius: 6px;
  text-align: center;
`;

export const MenuLayout = styled(Menu)`
  overflow-y: auto;
  height: 90%;
  .ant-menu-item {
    color: ${theme.colors.text} !important;
    font-size: 14px !important;
  }
  .ant-menu-item-selected {
    color: ${theme.colors.white} !important;
    background-color: ${theme.colors.primary} !important;
  }
  .ant-menu-item:not(.ant-menu-item-selected):hover {
    color: ${theme.colors.white} !important;
    background-color: ${theme.colors.tertiary} !important;
  }

  /* submenu */
  .ant-menu-submenu {
    color: ${theme.colors.text} !important;
    font-size: 14px !important;
    .ant-menu-title-content:hover {
      background-color: transparent !important;
    }
  }

  .ant-menu-submenu-selected {
    color: ${theme.colors.white} !important;
    font-size: 14px !important;
    background-color: ${theme.colors.primary} !important;
    .ant-menu-submenu-title:hover {
      background-color: transparent !important;
    }
    .ant-menu-submenu-title {
      color: ${theme.colors.white} !important;
    }
  }

  .ant-menu-submenu:not(.ant-menu-submenu-selected):hover {
    background-color: ${theme.colors.tertiary} !important;
    .ant-menu-submenu-title {
      color: ${theme.colors.white} !important;
    }
  }
  .ant-menu-sub.ant-menu-inline {
    background: ${theme.colors.white} !important;
  }
`;

export const TitleUser = styled.h2`
  font-family: ${theme.font.family};
  color: ${theme.colors.primary};
  margin: 0; // Sin margen
  font-size: 16px; // Tamaño de fuente
  line-height: 1.2; // Ajusta el espaciado vertical
  white-space: nowrap; // Evita el desbordamiento a una nueva línea
  overflow: hidden; // Oculta el desbordamiento
  text-overflow: ellipsis;
`;
export const SubTitleUser = styled.h2`
  font-family: ${theme.font.family};
  color: ${theme.colors.primary};
  font-size: 12px; // Tamaño de fuente más pequeño
  font-weight: bold;
  white-space: nowrap; // Evita el desbordamiento a una nueva línea
  overflow: hidden; // Oculta el desbordamiento
  text-overflow: ellipsis; // Muestra puntos suspensivos si es demasiado largo
  max-height: 12px; // Limita la altura del span a 12px
  line-height: 12px; // Alinea verticalmente el texto
`;
export const RowUser = styled.div`
  display: flex;
  flex-direction: column; // Apila SubTitle y span verticalmente
  justify-content: center; // Centra verticalmente el texto
  max-height: 50px; // Limita la altura máxima a 50px
  margin-right: 10px; // Margen derecho para separar el texto del Avatar
  overflow: hidden; // Evita desbordamiento
  text-align: end;
`;
export const ColumnConfigure = styled.div`
  display: flex;
  align-items: center; // Alinea verticalmente el avatar y el texto
  height: 50px; // Limita la altura total
  margin-right: 25px; // Margen derecho para separar el contenido
`;
export const TitlePage = styled.h2`
  margin: 0px 0px 0px;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.5;
  font-family: ${theme.font.family};
  display: block;
  color: ${theme.colors.primary};
  margin-left: 15px;
`;
