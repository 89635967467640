import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonForm } from '../../../../components';
import { RowButton } from '../../../../styles';
import { FaList, FaLock, FaTrashCan } from 'react-icons/fa6';

export const columns = (onOpenDrawerPermiso, onOpenDrawerAcceso, onDeleteRol, t) => {
  return [
    {
      field: 'Descripcion',
      headerName: t('rol.listaRol.1'),
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'permiso',
      headerName: t('rol.listaRol.2'),
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'left',
      width: 50,
      renderCell: (params) => (
        <>
          <RowButton>
            <ButtonForm
              action={(oEvent) => onOpenDrawerPermiso(oEvent, params.row)}
              icon={<FaLock />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
            />
          </RowButton>
        </>
      ),
    },
    {
      field: 'edit',
      headerName: t('rol.listaRol.3'),
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 50,
      renderCell: (params) => (
        <>
          <RowButton>
            <ButtonForm
              action={(oEvent) => onOpenDrawerAcceso(oEvent, params.row)}
              icon={<FaList />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
            />
          </RowButton>
        </>
      ),
    },
    {
      field: 'edite',
      headerName: '',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 50,
      renderCell: (params) => (
        <>
          <RowButton>
            <ButtonForm
              action={(oEvent) => onDeleteRol(oEvent, params.row)}
              icon={<FaTrashCan />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
            />
          </RowButton>
        </>
      ),
    },
  ];
};
