import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  LabelForm,
  SearchInputText,
  TableGrid,
  TitlePage,
  ButtonForm,
} from '../../../../components';
import {
  ColForm,
  ContentOption,
  RowFlex,
  RowFooterButton,
  RowForm,
  Title,
} from '../../../../styles';
import { Drawer, Tree } from 'antd';
import { listaAcceso, listaAccesoPr } from '../../../../common';
import { onSetDrawerAcceso } from '../../../../redux/states';

export const DrawerAcceso = (props) => {
  const rolState = useSelector((store) => store.rol);
  let dispatch = useDispatch();

  // const onChangeSearchAcceso = (oEvent) => {
  //   if (oEvent) {
  //     setSearchtexAcceso(oEvent.target.value);
  //     const filterAcceso = listaAcceso.filter(function (item, index) {
  //       return item.descripcion.toLowerCase().includes(oEvent.target.value.toLowerCase());
  //     });
  //     setFilterAcceso(filterAcceso);
  //   } else {
  //     setSearchtexAcceso('');
  //     setFilterAcceso([]);
  //   }
  // };

  const handleChangeRolAcceso = async (e) => {
    dispatch(onSetDrawerAcceso({ rolAccesoNombre: e.target.value }));
  };

  const onSelectAllAcceso = (oEvent) => {
    let aId = [];
    if (!rolState.isSelectedAllAcceso) {
      aId = listaAcceso.map(function (item, index) {
        return item.codigo;
      });
      dispatch(onSetDrawerAcceso({ isSelectedAllAcceso: true, selectedAcceso: aId }));
    } else {
      dispatch(onSetDrawerAcceso({ isSelectedAllAcceso: false, selectedAcceso: aId }));
    }
  };

  const onClose = () => {
    // setSearchtexAcceso('');
    // setFilterAcceso([]);
    props.onCloseDrawerAcceso();
  };

  return (
    <Drawer
      title={<TitlePage title={`Administador de Accesos`} />}
      placement="right"
      width={'33%'}
      closable={false}
      onClose={onClose}
      open={props.openDrawerAcceso}
      footer={
        <RowFooterButton>
          <ButtonForm
            action={(e) => onClose()}
            name="Cancelar"
            isAction={true}
            typeColor="warning"
          />
          <ButtonForm
            action={(e) => onSelectAllAcceso()}
            name={rolState.isSelectedAllAcceso ? 'Limpiar Todos' : 'Seleccionar Todos'}
            isAction={true}
            typeColor="primary"
          />
          <ButtonForm
            action={(e) => props.onApplyAcceso()}
            name="Aplicar"
            isAction={true}
            typeColor="secondary"
          />
        </RowFooterButton>
      }
    >
      <>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm style={{ marginLeft: '5px', marginBottom: '10px' }}>
            <LabelForm obligatorio={true} title={'Rol (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={rolState.rolAccesoNombre}
              onChange={(evnt) => handleChangeRolAcceso(evnt)}
              validate={true}
              disabled={rolState.rolAccesoId ? true : false}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title>Listado de Accesos : </Title>
        </ColForm>

        {/* <ContentOption>
          <RowFlex>
            <SearchInputText
              placeholder={'Buscar Acceso...'}
              filter={searchtexAcceso}
              onChange={(e) => onChangeSearchAcceso(e)}
            />
          </RowFlex>
        </ContentOption> */}
        {/* <div className="ant-drawer-body" style={{ padding: '0px', height: '82%' }}>
          <TableGrid
            columns={props.columsPermiso}
            rows={searchtexAcceso.length === 0 ? listaAcceso : filterAcceso}
            pageSize={15}
            getRowId={(row) => row.codigo}
          />
        </div> */}
        <div className="ant-drawer-body" style={{ padding: '0px', height: '82%' }}>
          <Tree
            checkable
            treeData={listaAccesoPr}
            showLine={true}
            checkedKeys={rolState.selectedAcceso}
            onCheck={props.handleSelectRow}
          />
        </div>
      </>
    </Drawer>
  );
};
