import { FaPenToSquare } from 'react-icons/fa6';
import { ButtonForm, PBR } from '../../../../../components';
import { RowButton } from '../../../../../styles';

export const columns = (handleOpenModal) => [
  {
    field: 'Sociedad',
    headerName: 'Sociedad',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'IDTAXRUC',
    headerName: 'NIF',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'NombreComercial',
    headerName: 'NombreComercial',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'Pais',
    headerName: 'Pais',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },

  {
    field: 'Verificado',
    headerName: 'Ecosistema Financiero',
    flex: 1,
    // align: 'center',
    // headerAlign: 'center',
    renderCell: (params) => <>{params.value ? 'VINCULADO' : ''}</>,
  },
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <RowButton>
        {params.row.Verificado ? (
          <></>
        ) : (
          <PBR permiso={[]}>
            <ButtonForm
              action={(e) => handleOpenModal(e, params.row)}
              icon={<FaPenToSquare />}
              isAction={false}
              isOnlyIcon={true}
              typeColor="primary"
            />
          </PBR>
        )}
      </RowButton>
    ),
  },
];
