import { ButtonDobleIcon } from '../../../styles';
import { BsChevronDown, BsCodeSlash } from 'react-icons/bs';

export const ButtonAccion = (props) => (
  <ButtonDobleIcon {...props}>
    <div>
      <BsCodeSlash />
      <span>Acciones</span>
      <BsChevronDown />
    </div>
  </ButtonDobleIcon>
);
