import React from 'react';
import { Modal } from 'antd';
import { ButtonForm, Input, LabelForm, SelectForm, TableGrid } from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeRowsChangeValor,
  changeRowsSelectTipoSistema,
  onAddRowtableTipoHomologado,
  onDeleteRowtableTipoHomologado,
} from '../../../../../redux/states';
import Swal from 'sweetalert2';
import { ColForm, RowButton, RowForm, Title } from '../../../../../styles';
import { v4 as uuidv4 } from 'uuid';
import { columns } from './misc';

export const ModalCrearCampo = (props) => {
  const campoState = useSelector((store) => store.campo);
  const sistemaState = useSelector((store) => store.sistema);
  const dispatch = useDispatch();

  const addTableRows = () => {
    var uuid = uuidv4();
    dispatch(
      onAddRowtableTipoHomologado({
        newItem: {
          idDetalle: uuid,
          selectTipoSistema: [],
          bValidTipoSistema: null,
          valor: '',
          bValidValor: null,
          action: 'N',
        },
      })
    );
  };

  const handleChangeTipoSistema = (id, value) => {
    var selectTipoSistema = [];
    if (value) {
      let filterSistema = campoState.listaTipoHomologados.filter((item, index) => {
        if (item.selectTipoSistema.length > 0) {
          return item.selectTipoSistema[0].idTipoSistema === value.idTipoSistema;
        }
      });
      if (filterSistema.length === 0) {
        dispatch(
          changeRowsSelectTipoSistema({
            index: id,
            selectTipoSistema: [value],
          })
        );
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'El Sistema ya existe en la lista',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      dispatch(
        changeRowsSelectTipoSistema({
          index: id,
          selectTipoSistema: [],
        })
      );
    }
  };

  const deleteTableRows = (id) => {
    dispatch(
      onDeleteRowtableTipoHomologado({
        index: id,
      })
    );
  };

  const handleChangeValor = (id, oEvent) => {
    let bValidValor = null;
    let valor = oEvent.target.value;
    if (valor.length === 0) {
      bValidValor = false;
    }
    dispatch(
      changeRowsChangeValor({
        index: id,
        valor: valor,
        bValiNombreValor: bValidValor,
      })
    );
  };

  return (
    <>
      <Modal
        open={props.open}
        title={<Title>{campoState.idCampoFrm ? 'Guardar Campo' : 'Crear Campo'}</Title>}
        closable={false}
        footer={[
          <ButtonForm
            action={(oEvent) => props.handleCloseModal(oEvent)}
            name={'Cerrar'}
            isAction={true}
            typeColor="warning"
          />,
          <ButtonForm
            style={{ marginLeft: '5px' }}
            action={(oEvent) => props.handleGuardarCampo(oEvent)}
            name={'Guardar'}
            isAction={true}
            typeColor="secondary"
          />,
        ]}
      >
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Descripción (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={campoState.nombreCampo}
              onChange={(evnt) => props.handleChangeCampo(evnt)}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Tipo de Dato (*): '}></LabelForm>
            <SelectForm
              validate={campoState.bValidTipoDato}
              value={campoState.selectTipoDato}
              handleChange={(e, v) => props.handleChangeTipoDato(v)}
              options={campoState.listaTipoDato}
              placeholder={'Seleccione Tipo'}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Lista de Homologación de sistemas : '}></LabelForm>

            <TableGrid
              columns={columns(
                handleChangeTipoSistema,
                handleChangeValor,
                deleteTableRows,
                addTableRows,
                campoState,
                sistemaState
              )}
              rows={campoState.listaTipoHomologados}
              pageSize={10}
              getRowId={(row) => row.idDetalle}
            />
          </RowForm>
        </ColForm>
      </Modal>
    </>
  );
};
