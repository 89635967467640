import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { onOpenModalAsignarTEAMultipleSolAP } from '../../../../../redux/states';
import { ButtonForm, Input, InputNumberFormat, LabelForm } from '../../../../../components';
import { ColFlex, RowFlex } from '../../../../../styles';
export const ModalAsignarTEAMultiple = ({ onAsignarTEAMultiple }) => {
  const dispatch = useDispatch();
  const aplazamientoState = useSelector((store) => store.aplazamiento);
  // const { loading, callEndpoint } = useFetchAndLoad();
  const initialForm = {
    tea: '',
    bValidTea: null,
  };
  const [formCampos, setFormCampos] = useState(initialForm);
  const { modalOpenTEAMultipleSolAP } = aplazamientoState;
  const onCloseModal = (e) => {
    setFormCampos(initialForm);
    dispatch(onOpenModalAsignarTEAMultipleSolAP({ modalOpenTEAMultipleSolAP: false }));
  };

  const onGuardarFC = () => {
    onAsignarTEAMultiple(formCampos);
  };

  const handleChangeTea = (values, sourceInfo) => {
    //var tea = oEvent.target.value;
    var tea = values.value;
    var validate = null;
    if (tea.length > 0) {
      validate = true;
    }
    var oParam = {
      tea: tea,
      bValidTea: validate,
    };
    // dispatch(changeTeaAP(oParam));

    setFormCampos({ ...formCampos, ...oParam });
  };

  return (
    <ModalComponent
      backdrop="static"
      keyboard={false}
      show={modalOpenTEAMultipleSolAP}
      onHide={onCloseModal}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Agregar Campos Flujo Caja</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <ColFlex>
          <RowFlex>
            <LabelForm
              width="180px"
              text_align="start"
              weight="bold"
              title={'TEA (%) (*):'}
            ></LabelForm>
            <InputNumberFormat
              value={formCampos.tea}
              validate={formCampos.bValidTea}
              allowNegative={false}
              fixedDecimalScale={true}
              decimalScale={3}
              onValueChange={(values, sourceInfo) => handleChangeTea(values, sourceInfo)}
              min={0}
              max={100.0}
            />
          </RowFlex>
        </ColFlex>
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <ButtonForm
          action={(e) => onGuardarFC()}
          name="Guardar"
          isAction={true}
          typeColor="primary"
        />
        <ButtonForm
          action={(e) => onCloseModal()}
          name="Cancelar"
          isAction={false}
          typeColor="warning"
        />
      </ModalComponent.Footer>
    </ModalComponent>
  );
};
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
const Container = styled.div`
  width: 320px;
`;
const ContainerLabel = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
`;
const Label = styled.span``;
const ModalComponent = styled(Modal)`
  /* z-index:99999; */
`;
