import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import {
  listarPosiciones,
  obtenerCaracPosTotal,
  obtenerListadoEtiquetas,
  obtenerListadoMoneda,
  obtenerPlantillaExcelRegManual,
} from '../../services';
import ExcelJS from 'exceljs';
export const exportToExcelDerivacion = async (fileName, cabeceras) => {
  try {
    let workbook = new ExcelJS.Workbook();
    //Creamos la primera Pagina - Moneda
    let worksheet = workbook.addWorksheet('Derivacion');

    //Creamos la pestana Registro manual
    let aCabecera = [];
    cabeceras.map((r) => aCabecera.push(r.field));

    var titleRowRegManual = worksheet.addRow(aCabecera);
    titleRowRegManual.font = {
      name: 'Comic Sans MS',
      family: 2,
      size: 12,
      underline: 'none',
      bold: true,
    };
    var aCabeceraStyle = [];
    aCabecera.forEach((element) => {
      aCabeceraStyle.push({ width: 20 });
    });

    worksheet.columns = aCabeceraStyle;
    worksheet.eachRow((r) => {
      r.eachCell((c) => {
        worksheet.getCell(c._address).fill = {
          type: 'gradient',
          gradient: 'angle',
          degree: 0,
          stops: [
            { position: 0, color: { argb: 'FFEBF1DE' } },
            { position: 0.5, color: { argb: 'FFEBF1DE' } },
            { position: 1, color: { argb: 'FFEBF1DE' } },
          ],
        };
      });
    });
    // var aLista = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    // aLista.forEach((element) => {
    //   worksheet.getCell(element + 1).fill = {
    //     type: 'gradient',
    //     gradient: 'angle',
    //     degree: 0,
    //     stops: [
    //       { position: 0, color: { argb: 'FFEBF1DE' } },
    //       { position: 0.5, color: { argb: 'FFEBF1DE' } },
    //       { position: 1, color: { argb: 'FFEBF1DE' } },
    //     ],
    //   };
    // });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, fileName + '.xlsx');
    });
  } catch (error) {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Occurió un error en la descarga del Excel ' + fileName + '.xlsx',
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
