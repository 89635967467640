import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useOutsideClick from '../../common/hooks/useOutsideClick';
import { theme } from '../../common';

export const SearchFieldAutocompletePagination = ({
  pholder,
  data,
  onSelected,
  onChange,
  value,
  text_align,
  oValue,
  width,
  onDelete,
  itemFila,
  fetchDataPaginate,
  onKeyDown,
}) => {
  const [suggestions, setSugesstions] = useState([]);
  const [isHideSuggs, setIsHideSuggs] = useState(false);
  const [selectedVal, setSelectedVal] = useState('');

  const ref = useRef();

  useOutsideClick(ref, () => {
    // alert('You clicked outside');

    setIsHideSuggs(true);
  });

  const handler = (e) => {
    let aEncontrado = data.filter((i) =>
      i.nombre.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setSugesstions(
      aEncontrado
      // data.filter((i) => i.nombre.toLowerCase().includes(e.target.value.toLowerCase())),
    );
  };
  const handlerAuto = (e) => {
    if (e) {
      let suggestions = data.filter((i) => i.nombre.toLowerCase().includes(e.toLowerCase()));
      if (suggestions.length > 0) {
        setIsHideSuggs(false);
        setSugesstions(suggestions);
      } else {
        setIsHideSuggs(true);
        setSugesstions([]);
      }
    } else {
      setIsHideSuggs(false);
      setSugesstions(data);
    }
  };

  const handlerAutoMejorado = (oEvent, e) => {
    if (oEvent.type === 'blur') {
      setIsHideSuggs(false);
      return;
    }
    if (e.trim().length > 0) {
      let suggestions = data.filter((i) => i.nombre.toLowerCase().includes(e.toLowerCase()));
      if (suggestions.length > 0) {
        setIsHideSuggs(false);
        setSugesstions(suggestions);
      }
    } else {
      setIsHideSuggs(true);
      setSugesstions([]);
    }
  };

  const handleChange = (event, item, index) => {
    const input = event.target.value;
    setIsHideSuggs(false);
    setSelectedVal(input);
    onChange(event, item, index);
    if (input.length === 0) {
      setIsHideSuggs(false);
      setSugesstions(data);
    }
  };
  const hideSuggs = (value) => {
    onSelected(value);
    setSelectedVal(value);
    setIsHideSuggs(true);
    setSugesstions([]);
  };

  const close = () => {
    setIsHideSuggs(true);
  };

  const onDeleteAndClose = (item) => {
    onDelete(item);
    setSugesstions([]);
    setIsHideSuggs(true);
  };

  const handleScroll = async (oEvent, item, index) => {
    // const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    const bottom =
      oEvent.target.scrollHeight - oEvent.target.scrollTop <
      //e.target.clientHeight + 0.3 * e.target.clientHeight;
      oEvent.target.clientHeight + 50;

    if (bottom) {
      await fetchDataPaginate(oEvent, item, index);
    }
  };

  return (
    <ContentSuggestionAuto>
      <formControlAuto ref={ref}>
        <Input
          placeholder={pholder}
          type="search"
          value={value}
          onChange={(event, item, index) => handleChange(event, item, index)}
          onKeyUp={handler}
          onFocus={(event) => handlerAuto(value)}
          text_align={text_align}
          width={width}
          onKeyDown={onKeyDown}
          // onBlur={close}
          // autoFocus={true}
        />
      </formControlAuto>
      {data.length === 0 ? (
        <></>
      ) : (
        <ContentSuggestions
          className="suggestions"
          isHideSuggs={isHideSuggs}
          text_align={text_align}
          width={width}
          onScroll={(oEvent, item, index) => handleScroll(oEvent, item, index)}
          // style={{ display: isHideSuggs ? "none" : "block" }}
        >
          {data.map((itm, idx) => (
            <div
              key={'' + itm + idx}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{ width: '100%' }}
                onClick={(oEvent) => {
                  const { DatoPosicion, nombre } = itm;
                  onSelected({ value: nombre });
                }}
              >
                {itm.nombre}
              </div>
            </div>
          ))}
        </ContentSuggestions>
      )}
    </ContentSuggestionAuto>
  );
};
const ContentSuggestionAuto = styled.div`
  /* display: block; */
  border-radius: 7px;
  display: flex;
  flex-direction: column;
`;
const formControlAuto = styled.div`
  background-color: #1e1f26 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;
const ContentSuggestions = styled.div`
  div {
    cursor: pointer;
    display: flex;
    padding-left: 5px;
    height: 35px;
    /* justify-content: center; */
    align-items: center;
  }
  margin-top: 41px !important;
  width: ${({ width }) => (width ? width : '320px')};
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  /* background: #23252C; */
  background: white;
  /* height: fit-content; */
  border-radius: 20px 20px 10px 10px !important;
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  text-align: ${({ text_align }) => (text_align ? text_align : 'center')};

  /* margin: 20px 0; */
  position: absolute;
  z-index: 1;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 0 solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.75rem !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  div:hover {
    /* background-color: #dbdbdb !important;
        color: #000000; */
    color: black;
    background-color: rgba(0, 0, 0, 0.03);
  }
  position: absolute;
  margin-top: 50px;
  z-index: 99999 !important;
  /* width : auto; */
  overflow: ${({ isHideSuggs }) => (isHideSuggs ? '' : 'scroll')};
  max-height: ${({ isHideSuggs }) => (isHideSuggs ? '0px' : '200px')};
  display: ${({ isHideSuggs }) => (isHideSuggs ? 'none' : 'block')};
`;
const Input = styled.input`
  border-color: #ced4da !important;
  border-radius: 5px;
  border: 1px solid white;
  transition: width 0.5s;
  height: 40px;
  font-family: ${theme.font.family};
  font-size: 12px;
  margin-left: 0px;
  padding: ${({ active }) => (true ? '0 10px' : '0px')};
  opacity: ${({ active }) => (true ? '1' : '0')};
  width: ${({ width }) => (width ? width : '320px')};
  /* background-color: #f6f6f6; */
  /* border-radius: 8px; */
  &:focus {
    outline: none !important;
    border-color: ${({ validate }) =>
      !validate ? 'rgba(0, 0, 0, 0.23)' : validate === true ? 'rgba(0, 0, 0, 0.23)' : 'red'};
    box-shadow: 0 0 5px rgb(3, 201, 215);
  }
  background: white;
  /* text-align: center; */
  text-align: ${({ text_align }) => (text_align ? text_align : 'center')};
  /* border-radius: 8px; */
  border-color: ${({ validate }) =>
    !validate ? 'rgba(0, 0, 0, 0.23)' : validate === true ? 'rgba(0, 0, 0, 0.23)' : 'red'};
  transition: ${({ validate }) =>
    !validate
      ? 'rgba(0, 0, 0, 0.23)'
      : validate === true
      ? 'rgba(0, 0, 0, 0.23)'
      : 'border-color 0.5s ease-out'};
`;
export default SearchFieldAutocompletePagination;
